import React, {useRef, useState, useCallback, useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import JoditEditor from "jodit-react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as EventsAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "@material-ui/lab";
import { useSnackbar } from 'notistack';
import moment from 'moment';




const ReviewDialog = ({isDialogOpen, handleClose, type, module, id, editData,mainPackId})=>{
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar()
    const [authorName, setAuthorName] = useState('');
    const [rating, setRating] = useState(0);
    const [authorDesc, setAuthorDesc] = useState('');
    const [formError, setFormError] = useState({
        authorName: {msg:'', hasError:false},
        authorDesc: {msg:'', hasError:false},
        authorRating: {msg:'', hasError:false}
    });
    const [selectedDate, setSelectedDate] = useState(new Date);

    const fileRef = useRef();
    
    useEffect(()=>{
        if(type==='edit'){
            setAuthorName(editData.custom_user_name);
            setAuthorDesc(editData.review_comment);
            setRating(editData?.review_star)
            setSelectedDate(editData?.custom_date?moment(editData?.custom_date).format("MMMM YYYY"):moment(editData?.created).format("MMMM YYYY"))
        }
    }, []);
   
    const dateChange = (date) => {
      setSelectedDate(moment(date).format("YYYY-MM"))
        

      };

    const editor = useRef(null);
    const configJoditEditor = {
        readonly: false,
        // limitChars: 400,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        placeholder: "Description...",
    };
    
    const submitReview = ()=>{
        if(!authorName){
            setFormError({
                ...formError,
                authorName:{
                    hasError:true,
                    msg:'Please Enter Author Name'
                }
            });
            return;
        }
        if(!rating){
            setFormError({
                ...formError,
                authorRating:{
                    hasError:true,
                    msg:'Please give rating'
                }
            });
            return;
        }
        if(!authorDesc){
            setFormError({
                ...formError,
                authorDesc:{
                    hasError:true,
                    msg:'Please Enter Comment'
                }
            });
            return;
        }
          

        
        const addData= {
            "request_type":"create_review",
            "main_pack_id": mainPackId,
            "review_star": Number(rating),
            "review_comment": authorDesc,
            "custom_user_name": authorName,
            "custom_date":moment(selectedDate).format("YYYY-MM")
        }
        const editPostData= {
            "request_type":"update_review",
            "pack_id": id,
            "review_id": editData?.review_id,
            "review_star": Number(rating),
            "review_comment": authorDesc,
            "custom_user_name": authorName,
            "custom_date":moment(selectedDate).format("YYYY-MM")
        }
        if(type==='add'){
            if(module == "holiday"){
                dispatch(HolidaysAction.addHolidayPackReview(addData, handleSuccess))
            }else if(module == "corporate"){
                dispatch(CorpListingAction.addCorporatePackReview(addData, handleSuccess))
            }else if(module == "experience"){
                dispatch(experiencesListingAction.addExperiencePackReview(addData, handleSuccess))
            }
            
        } else if (type==='edit'){
            if(module === 'corporate'){
                dispatch(CorpListingAction.editCorporatePackReview(editPostData, handleSuccess))
            }else if(module === 'holiday'){
                dispatch(HolidaysAction.editHolidayPackReview(editPostData, handleSuccess))
            }else if(module === 'experience'){
                dispatch(experiencesListingAction.editExperiencePackReview(editPostData, handleSuccess))
            }
        }
        
    };

    const handleSuccess = (message)=>{
        if(module == "holiday"){
            dispatch(HolidaysAction.getHolidayReviewsList(id))
            dispatch(HolidaysAction.getHolidayPackageInfo(mainPackId))
        }else if(module == "corporate"){
            dispatch(CorpListingAction.getCorporateReviewsList(id))
            dispatch(CorpListingAction.getPackageInfo(mainPackId))
        }else{
            dispatch(experiencesListingAction.getExperienceReviewsList(id))
            dispatch(experiencesListingAction.getPackageInfo(mainPackId))
        }
        enqueueSnackbar(message,{
            variant: "success"
        })
        handleClose();
    };
    
    const resetError = (field)=>{
        setFormError({
            ...formError,
            [field]:{
                msg:'',
                hasError:false
            }
        })
    };
    
    return (
        <div>
            <Dialog open={isDialogOpen} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title" >
                    {type==='add'?'Add new': 'Edit'}  Review
                    
                    <button type="button" onClick={handleClose} className="btn btn-link" style={{position:'absolute', right:'3%'}}>Close</button>
                </DialogTitle>
                <DialogContent>
                    {true?<div>
                    <div className="row">
                        <div className="col-5">

                            <div className="form-group form-custom" >
                                <label for="authorName">Author Name</label>
                                <input
                                    name="authorName"
                                    type="text"
                                    value={authorName}
                                    className="form-control"
                                    onChange={(e)=>{setAuthorName(e.target.value);resetError('authorName')}}
                                />
                                {formError.authorName.hasError && <p style={{ color: "red" }}>{formError.authorName.msg}</p>}
                            </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group form-custom" >
                                    <label for="userImage">Date</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            openTo="year"
                                            views={["year", "month"]}
                                            autoOk={true}
                                            value={selectedDate}
                                            onChange={dateChange}
                                        /></MuiPickersUtilsProvider>
                                    </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group form-custom" >
                                    <label for="userImage">Rating</label>
                                    <div className='row mt-1'>
                                        <Rating
                                            onChange={(e) => setRating(e.target.value)}
                                            name="size-medium"
                                            value={rating}
                                            defaultRating={0}
                                        />
                                    </div>
                                        {formError.authorRating.hasError && <p style={{ color: "red" }}>{formError.authorRating.msg}</p>}
                                </div>
                                
                        </div>
                       
                    </div>
                    <div className="row">
                    <div className="col">
                        <div className="form-group form-custom mt-3">
                            <label for="">Comment </label>
                            
                            <textarea
                                style={{ height: "120px",backgroundColor: "#f9f9f9" }}
                                value={authorDesc}
                                type="text"
                                className="form-control"
                                onChange={(e)=>{setAuthorDesc(e.target.value);resetError('authorDesc')}}
                            />
                            {formError.authorDesc.hasError && <p style={{ color: "red" }}>{formError.authorDesc.msg}</p>}
                        </div>
                        </div>
                    </div>
                    </div>:
                    null
                    }
                </DialogContent>
                <DialogActions>
                    
                        <button onClick={submitReview}  className="btn btn-primary btn-save mr-3">
                            Done 
                        </button>
                    
                    
                </DialogActions>
            </Dialog>
            
        </div>
    );
};

export default ReviewDialog;