import React from 'react';
import '../../assets/css/partials/guestModal.scss'
import PlusIcon from '../../assets/images/plusIcon.png'
import MinusIcon from '../../assets/images/minusIcon.png'
const  GuestModal = ({
    showMyModal,
    adultGuestIDecrementHandler,
    childrensGuestIDecrementHandler,
    adultGuestIncrementHandler,
    childrensGuestIncrementHandler,
    doneClickHandler,
    infantGuestDecrement,
    infantGuestIncrement,
    adultCount,
    childrenCount,
    infantCount
}) => {
    return (
        // <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`} id="guestModal">
        <div className="card-box card-box-index guest-modal" style={{width:"370px", backgroundColor:"#fff", top:"40%", left:"7%", padding:"12px", position:"absolute", zIndex:"99999"}} >
            {/* <div className="row corp-row"> */}
                <div className="col-md-12 corp-content ">
                    <div className="row corp-content-single-row">
                        <div className="col-6 col-md-6">
                            <h6 className="adults">Adults</h6>
                            <p style={{fontSize:10}}>12 Yrs and above</p>
                        </div>
                        <div className="col-md-6 corp-icon">

                            <img alt=""
                                src={MinusIcon}
                                onClick={adultGuestIDecrementHandler}
                            />
                            <span className="count" style={{fontSize:16}}>{adultCount}</span>
                            <img alt=""
                                src={PlusIcon}
                                onClick={adultGuestIncrementHandler}
                            />
                        </div>
                    </div>

                    <div className="row corp-row corp-content-single-row">
                        <div className="col-6 col-md-6">
                            <h6 >Childrens</h6>
                            <p style={{fontSize:10}}> 5-12 Yrs </p>
                        </div>
                        <div className="col-md-6 corp-icon">

                            <img alt=""
                                src={MinusIcon}
                                onClick={childrensGuestIDecrementHandler}
                            />
                            <span className="count" style={{fontSize:16}}>{childrenCount}</span>
                            <img alt=""
                                src={PlusIcon}
                                onClick={childrensGuestIncrementHandler}
                            />
                        </div>
                    </div>

                    <div className="row corp-row corp-content-single-row pb-md-0">
                        <div className="col-6 col-md-6">
                            <h6 >Below 5 yrs</h6>
                            <p className="sub-title">0-5 Yrs of age</p>
                        </div>
                        <div className="col-md-6 corp-icon">

                            <img src={MinusIcon} alt="" 
                            onClick={infantGuestDecrement} 
                            />
                            <span className="count" style={{fontSize:16}}>{infantCount}</span>
                            <img
                                src={PlusIcon} alt=""
                                onClick={infantGuestIncrement}
                            />
                        </div>
                    </div>
                <div style={{marginBottom:"8px", textAlign:"end", marginRight:"20px"}}>
                    <button type="button" className="btn cancel-btn" style={{ background: '#2196F3',width:"118px",color:"#fff" }} onClick={doneClickHandler}>Done</button>
                </div>
                </div>
                {/* <div className="col-md-4">
                    <div className="otp-btn-container">
                        <button type="button" className="btn-common">Done</button>
                    </div>
                    <p className="additional-note">Note: Additional charges for extra adults/childrens</p>
                </div> */}
            </div>
        /* </div> */
    // </div>  

    );
};

export default GuestModal;
