
import { API } from "../constants/api";
import { 
  GET_CORP_SECTION_IMAGES_DATA,
  IMAGE_UPDATE_SECTION,
  CROPPED_IMAGE_FILE,
  SELCTED_IMAGE_URL,
  CONFIRM_UPDATE_BOOL,
  CONFIRM_ADD_BOOL,
  SECTION_PACKAGES,
  IS_CORP_DATA_LOADING
} from './types';

import {
  axiosGetWithToken,
  axiosPut,
  axiosDelete
} from "../services/apiServices";


export const getSectionImageData = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.GET_SECTION_IMAGES_DATA}`,token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CORP_SECTION_IMAGES_DATA,
            payload: res.data.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const deleteSectionImage = (item,handleDeleteSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const sectionImageData = getState().section.corpSectionImageData;
    let resData = sectionImageData.filter(itm => itm.id !== item.id);
    axiosDelete(`${API.DELETE_SECTION_IMAGE}${item.id}`, token)
    .then((res) => {
      if(res.status === 204){
        handleDeleteSuccess();
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
    dispatch({
      type: GET_CORP_SECTION_IMAGES_DATA,
      payload: resData
    });
  };
};

export const updateSection = (item,refreshUpdateSection, id,handleSuccess,handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;        
    axiosPut(`${API.DELETE_SECTION_IMAGE}${id}/`, item, token)
        .then((res) => {
          if(res.data.status_code === 200){
            refreshUpdateSection()
            handleSuccess();
          }
          else if(res.data.status_code === 400){
            handleError(res.data.data.priority);
            refreshUpdateSection()
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
};


export const getSectionPackages = (itemNeedToBeDeleted) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;  
    let id = itemNeedToBeDeleted.id      
    axiosGetWithToken(`${API.GET_SECTION_PACKAGES}${id}/`, token)
        .then((res) => {
          console.log("jhkjhjkhkjhk", res)
          dispatch({
            type:SECTION_PACKAGES,
            payload:res.data.data.total_pack
          })
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
};
export const imageUpdateHandler = (item) => {
  return async (dispatch, getState) => {
    dispatch({
      type: IMAGE_UPDATE_SECTION,
      payload: item
    });
  };
};

export const selectedCroppedFile = (croppedFile) => {
  return async (dispatch) => {
    dispatch({
      type: CROPPED_IMAGE_FILE,
      payload: croppedFile
    });
  };
};

export const selectedImageUrl = (url) => {
  return async (dispatch) => {
    dispatch({
      type: SELCTED_IMAGE_URL,
      payload: url
    });
  };
};

export const handleAddConfirmBool = (val) => {
  return async (dispatch) => {
    dispatch({
      type: CONFIRM_ADD_BOOL,
      payload: val
    });
  };
};

export const handleUpdateConfirmBool = (val) => {
  return async (dispatch) => {
    dispatch({
      type: CONFIRM_UPDATE_BOOL,
      payload: val
    });
  };
};