import React, { useState,useEffect } from "react";
import "../../assets/css/partials/webstories/style.scss";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Spinner } from "react-bootstrap";
import Listing from "./webstoriesListing";
import EditListing from "./editListpage";
import Featured from "./Features";
import editLogo from "../../assets/images/editweb.svg";
import editblackLogo from "../../assets/images/edit.svg";
import plucIcon from "../../assets/images/plusIcon.png";
import DeleteIcon from "../../assets/images/delete.svg";
import Cardmodal from "./cardmodal";
import * as webStoriesAction from"../../actions/webStoriesAction"
import { useSnackbar } from "notistack";

const WebStories = () => {
    const { enqueueSnackbar } = useSnackbar();

    const stotyList = useSelector((state) => state.webStories.webStoriesLists.results );
    const StoryDetails = useSelector((state) => state.webStories.editAbleDetails);
    const paginationDetails = useSelector((state) => state.webStories.webStoriesLists);
    const updatedPayload = useSelector((state) => state.webStories.updatedPayload );
    const search = useSelector((state) => state.holidayListing.holidaylistSearchVal);
    const isListingDataLoading = useSelector((state) => state.holidayListing.isListingDataLoading);
    const addHolidayPackage = useSelector((state) => state.holidayListing.addHolidayPackage);
    const dispatch = useDispatch();
    const[selectStoryDetails,setSelectStoriesDetails]=useState(false)
    const [page, setPage] = useState(1);
    const [modalCard, setModalCard] = useState(false);
    const [title, setTitle] = useState();
    const [titleDescription,setTitleDescription] = useState();
    const [sortOrder, setSortOrder] = useState(1);
    const [indx, setIndx] = useState(0);
    const[imageId,setImageId]=useState()
    const[detailsId,setDetailsId]=useState()
    const[setEdit,setSetEdit]=useState(false)
    const[slideEditDetails,setSlideEditDetils]=useState(false)
    const[sliteIndx,setSlideIndx]=useState()
    const [tags, setTags] = useState("travel");
    const [stories, setStories] = useState([]);
    const [thumbnail, setThumbnail] = useState();
    const[thumpId,setThumbId]=useState()
    const[altText,setAltText]=useState("")
    const[altTextIndexNo,setAltTextIndexNo]=useState()
  
    const handleResetPage = () => {
        setPage(1);
    };

    const handleDeleteStoriesSlide=(elemindex)=>{
        const deletedandUpdatestories=stories.filter((elem,index)=>{
            return index!==elemindex
        })
        setStories(deletedandUpdatestories)
    }

    const handlEditstoriesSlide=(elemindx,elemId)=>{
       
        setSetEdit(true)
       
        setIndx(elemindx)
        setImageId(elemId)
        const deletedandUpdatestories=stories.filter((elem,index)=>{
            return index===elemindx
        })
        setSlideEditDetils(deletedandUpdatestories)
        setModalCard(true)
    }


    const handleAddNew = () => {
        setSetEdit(false)
          // setStories([
        //     ...stories,
        //     {
        //         media_title: "",
        //         media_description: "",
        //         media_caption_placement: "center",
        //         transition_effects: "",
        //         media_url: "",
        //         media_type: "image",
        //         sort_order: "",
        //     },
        // ]);
        
        setModalCard(true);
        dispatch(webStoriesAction.emptyMedia())
    };
    const handlePagination = (event,pageNo) => {
        setPage(pageNo)
        dispatch(webStoriesAction.getWebStoreisLists(pageNo))
        
    };
    const handleEdit = (details) => {
        // dispatch(holidayListingAction.editHolidayListItem(details));
        // dispatch(holidayListingAction.setAddHolidayPackage(false));
    };
    console.log(thumbnail,stories,">>>>>>>");

    const webstoriesPayload={
        "title":title,
        "title_description": titleDescription,
        "tags": tags,
        "thumbnail": thumbnail,
        "stories":stories,
        "alt_text":stories?.[altTextIndexNo]?.alt_text
      
      

    }
    

    // const handleActivepayload = {
    //     id: id,
    //     title: title,
    //     title_description: titleDescription,
    //     tags: tags,
    //     thumbnail: storyImageArray?.[0]?.media_url,
    //     story_detail: storyImageArray,
    //     is_active: false,
    // };

    const refreshReload=()=>{
        setTimeout(() => {
            window.location.reload();
            
        }, 1000);

    }

    const handleAddWebStories = () => {
        if(title.length>70){
            enqueueSnackbar("Title can not be more than 70 characters.", {
                variant: "error",
            });

        }
     
        if(title&&titleDescription&&stories?.length>0&&thumbnail){
        dispatch(webStoriesAction.handleAddNewWebStories(webstoriesPayload,refreshReload));
        enqueueSnackbar("Web-Stories added successfully.", {
            variant: "success",
        });

        }
        else {
            if(!title){
                enqueueSnackbar("Title Field Empty.", {
                    variant: "error",
                });
            
        }
        else if(!titleDescription){
            enqueueSnackbar("Description Field Empty.", {
                variant: "error",
            });
            
        }
        else if(!thumbnail){
        
       
            enqueueSnackbar("Add Atleast One Image / Select Thumbnail .", {
                variant: "error",
            });

          
           
            
        }
        else if(stories?.length<=0){
            enqueueSnackbar("Image Not Added.", {
                variant: "error",
            });
           
        }

        }
     
       
     
    
    };

    const handleStoriesActive=(id,toggle)=>{
        if(toggle){
            dispatch(webStoriesAction.handleUpdatewWebStories({
                id: StoryDetails?.id,
                title: StoryDetails?.title,
                title_description: StoryDetails?.title_description,
                tags: "travel",
                thumbnail: StoryDetails?.thumbnail,
                story_detail: StoryDetails?.story_detail,
                is_active: false,
                alt_text:StoryDetails?.alt_text
            },refreshReload));
            enqueueSnackbar("Story D-activated.", {
                variant: "success",
            });

        }
        else{
            dispatch(webStoriesAction.handleUpdatewWebStories({
                id: StoryDetails?.id,
                title: StoryDetails?.title,
                title_description: StoryDetails?.title_description,
                tags: "travel",
                thumbnail: StoryDetails?.thumbnail,
                story_detail: StoryDetails?.story_detail,
                is_active: true,
                alt_text:StoryDetails?.alt_text
            },refreshReload));
            enqueueSnackbar("Story activated.", {
                variant: "success",
            });
        }
     

     

    }

    const handleThumbnail=(Tindx,Timage)=>{
        setThumbId(Tindx)
        setThumbnail(Timage)
    }
    useEffect(()=>{

        // dispatch(webStoriesAction.getPaginationWebstories(1,handlePagination))
        dispatch(webStoriesAction.getWebStoreisLists(1))
        setSelectStoriesDetails(false)

    },[])
    useEffect(()=>{
       
     
        if(detailsId){
        const fulldetails=stotyList.filter((elem)=>{
            if(elem.id==detailsId){
                return elem

            }
         
        })
        const resultObject = Object.assign({}, ...fulldetails);
       
        dispatch(webStoriesAction.editDetails(resultObject))
     
        setSelectStoriesDetails(true)
    }


    },[detailsId,stories])
    useEffect(()=>{
        setDetailsId(stotyList?.[0]?.id)

    },[stotyList])

    useEffect(()=>{
        if(title?.length>250){
            enqueueSnackbar("Title length exceeding 200 character", {
                variant: "error",
            });

        }
        else if(titleDescription?.length>510){

            enqueueSnackbar("Description length exceeding 500 character", {
                variant: "error",
            });


        }

      

    },[title,titleDescription])
    useEffect(()=>{
        let index = stories.findIndex(obj => obj.media_url == thumbnail);
        setAltTextIndexNo(index)
    },[stories,thumbnail])
  
 
    return (
        <div className="webstories">
            <div className="heading" style={{ marginLeft: "4px" }}>
                Web Stories
            </div>

            <div className="mainContainer">
                <Row
                    style={{
                        marginTop: "32px",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Col
                        style={{ paddingRight: "20px" }}
                        className="gutter-row"
                        xs={24}
                        lg={8}
                    >
                        <Row className="gutter-row">
                         
                            <Listing
                                stotyList={stotyList}
                                handleEdit={handleEdit}
                                handleAddWebStorieshandleAdd={handleAddWebStories}
                                currentPage={page}
                                handleResetPage={handleResetPage}
                                setDetailsId={setDetailsId}
                                setSelectStoriesDetails={setSelectStoriesDetails}
                                handleStoriesActive={handleStoriesActive}
                                setModalCard={ setModalCard}
                            />
                        </Row>
                        {search && isListingDataLoading ? null : (
                            <Row
                                style={{
                                    paddingTop: `${
                                        addHolidayPackage ? "85%" : "48%"
                                    }`,
                                }}
                            >
                                <Pagination

                                    count={paginationDetails?.total_pages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={page}
                                    onChange={(event,value) =>
                                        handlePagination(event,value)
                                    }
                                />
                            </Row>
                        )}
                    </Col>
                    <Col
                        className="gutter-row "
                        style={{
                            paddingRight: "10px",
                            marginRight: "-20px",
                            marginTop: "4px",
                            // background: "white",
                            height: "auto",
                            background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.2})`:"white"
                        }}
                        xs={24}
                        lg={16}
                        id="cards"
                    >
                  
                      {!selectStoryDetails? 
                      <div >
                       <div
                            className="gutter-row"
                            style={{ display: "flex", float: "right" }}
                        >
                           
                            <Featured handleWebStories={handleAddWebStories} modalCard={modalCard}/>
                        </div>
                        

                        <div className="story-title-box" >
                            <div className="story-title-label" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}>
                            <p>Story Title </p>
                                

                                <div className="story-title-input"  style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={modalCard?true:false}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                        style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}
                                       
                                    />
                                    <img
                                        src={editblackLogo}
                                        alt="editlogo"
                                    ></img>
                                </div>
                            </div>
                        </div>
                        <div
                            className="story-title-box"
                            style={{ marginTop: "20px" }}
                        >
                            <div className="story-title-label" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}>
                                 <p>Meta Description </p>
                                <div className="story-title-input" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={modalCard?true:false}
                                        onChange={(e) =>
                                            setTitleDescription(e.target.value)
                                        }
                                        style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}
                                    />
                                    <img
                                        src={editblackLogo}
                                        alt="editlogo"
                                    ></img>
                                </div>
                            </div>
                        </div>

                        <div style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                       
                            <div className="story-card-container">
                        
                            <div className="story-cards" >
                               {stories?.map((elem,elemindex)=>{
                                return(

                                   <>
                                     {elem.media_url?.split(".").pop().toLowerCase() == "mp4" ? 
                                     
                                     <div style={{ position: "relative", display: "inline-block" }}>
                                         <video controls width="210px" height="352" style={{marginTop:"9px",borderColor:"white",borderStyle:"solid",borderRadius:"14px"}}>
                                         <source src={elem.media_url} type="video/mp4" />
                                     </video>
                                     <div className="story-card-edit"  onClick={() =>
                                                 handlEditstoriesSlide(
                                                    elemindex,elem.id
                                                 )
                                             }
                                             style={{
                                                 display: "flex",
                                                 position: "absolute",
                                                
                                                 // zIndex: 1,
                                                
                                                 border: "none",
                                                 
                                                
                                                
                                                 padding: "7px 8px",
                                                 alignItems: "center",
                                                 gap: "10px",
                                                 borderRadius: "10px",
                                                 border: "1px solid #fff",
                                                 background: "rgba(0, 0, 0, 0.6)",
                                                 top: "85%",
                                                 left: "55%",
                                                 fontSize: "24px",
                                                 cursor: "pointer",
                                             }}
                                             >
                                         <img
                                             src={editLogo}
                                             alt="editImage"
                                            
                                         ></img>
                                     </div>
                                     <div className="story-card-delete"   onClick={() =>
                                                 handleDeleteStoriesSlide(
                                                    elemindex,elem.id
                                                 )
                                             }
                                             style={{
                                                 display: "flex",
                                                 position: "absolute",
                                                
                                                 // zIndex: 1,
                                                
                                                 border: "none",
                                                 
                                                
                                                
                                                 padding: "7px 8px",
                                                 alignItems: "center",
                                                 gap: "10px",
                                                 borderRadius: "10px",
                                                 border: "1px solid #fff",
                                                 background: "rgba(0, 0, 0, 0.6)",
                                                 top: "85%",
                                                 left: "75%",
                                                 fontSize: "24px",
                                                 cursor: "pointer",
                                             }}
                                             >
                                         <img
                                             src={DeleteIcon}
                                             alt="deleteIcon"
                                           
                                         ></img>
                                     </div>
                                 </div>
                                         :elem.media_url?
                                        <div className="story-card-image">
                                            

                                            <p className="thumbnail" style={{background:(thumpId==elemindex)?"rgb(24, 160, 251)":"rgba(0, 0, 0, 0.5)",cursor:"pointer"}} onClick={()=>handleThumbnail(elemindex,elem.media_url)}>set as thumbnail</p>

                                          
                                         
                                        <img src={elem.media_url} className="story-card-image-main"></img>
                                        <div className="story-card-edit" onClick={()=>handlEditstoriesSlide(elemindex,elem.id)}>
                                        <img
                                                src={editLogo}
                                                alt="editImage"
                                                
                                            ></img>
                                        </div>
                                        <div className="story-card-delete" onClick={()=>handleDeleteStoriesSlide(elemindex,elem.id)}>
                                        <img
                                                src={DeleteIcon}
                                                alt="deleteIcon"
                                                
                                            ></img>
                                        </div>
                                    </div>:""}
                                    </>
                                 
                                    
                                )
                               })  }
                              

                                    <div className="story-card-add" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.0})`:""}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <img
                                                src={plucIcon}
                                                alt="plusIcon"
                                            ></img>

                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm  mt-2 px-4"
                                                onClick={() => handleAddNew()}
                                            >
                                                Add new
                                            </button>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                            {modalCard ? (
                                <Cardmodal
                                    setModalCard={setModalCard}
                                    stories={stories}
                                    indx={indx}
                                    setIndx={setIndx}
                                    setStories={setStories}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    slideEdit={setEdit?true:false}
                                    setSlideEditDetils={setSlideEditDetils} 
                                    sliteIndx={sliteIndx}
                                    slideEditDetails={slideEditDetails}
                                    imageId={imageId}
                                    
                                />
                            ) :(
                                ""
                            )}
                        </div>
                        </div>:<EditListing detailsId={detailsId} setImageId={setImageId}/>}
                        
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default WebStories;
