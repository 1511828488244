import React, { useState, useEffect } from "react";
import * as webStoriesAction from "../../actions/webStoriesAction";
import Featured from "./Features";
import { useSelector, useDispatch } from "react-redux";
import editLogo from "../../assets/images/editweb.svg";
import editblackLogo from "../../assets/images/edit.svg";
import plucIcon from "../../assets/images/plusIcon.png";
import DeleteIcon from "../../assets/images/delete.svg";
import Cardmodal from "./cardmodal";
import { useSnackbar } from "notistack";


    

const EditListing = ({ detailsId }) => {
    const StoryDetails = useSelector(
        (state) => state.webStories.editAbleDetails
    );
   

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [selectStoryDetails, setSelectStoriesDetails] = useState();
    const [slideEditDetails, setSlideEditDetils] = useState(false);
    const [setEdit, setSetEdit] = useState(false);
    const [title, setTitle] = useState(StoryDetails?.title);
    const [id, setId] = useState();
    const [titleDescription, setTitleDescription] = useState();
    const [storyImageArray, setStoryImageArray] = useState();
    const[visible,setVisible]=useState(true)
    
    const [sliteIndx, setSlideIndx] = useState();
    const [modalCard, setModalCard] = useState(false);
    const [tags, setTags] = useState("travel");
    const [thumbnail, setThumbnail] = useState();
    const [indx, setIndx] = useState();
    const [editIndx, setEditIndx] = useState();
    const [sortOrder, setSortOrder] = useState();
    const[imageId,setImageId]=useState()
    const[slideIds,setSlideIds]=useState([])
     const [isActive,setIsActive]=useState()
     const[altText,setAltText]=useState()


    const updateWebstoriesPayload = {
        id: id,
        title: title,
        title_description: titleDescription,
        tags: tags,
        thumbnail: thumbnail,
        story_detail: storyImageArray,
        is_active: isActive,
        alt_text:altText
    };
    const deleteSlide={
        id:id,
        story_detail:slideIds

    }
   const refreshReload = () => {
        enqueueSnackbar("Web-Stories updated successfully.", {
            variant: "success",
        });
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const handleUpdateWebStories = () => {
        if(title.length>70){
            enqueueSnackbar("Title can not be more than 70 characters.", {
                variant: "error",
            });

        }
         else if(slideIds.length>0&&title&&thumbnail&&titleDescription&&storyImageArray?.length>0){

            dispatch( webStoriesAction.handleUpdatewWebStories( updateWebstoriesPayload))
            setTimeout(() => {
                dispatch(webStoriesAction.deleteStorySlide(id,slideIds,refreshReload))
                
            }, 1000);
           
           

        }
        else{
            if(!title){
                enqueueSnackbar("Title Field Empty.", {
                    variant: "error",
                });
            
        }
        else if(!thumbnail){
        
                enqueueSnackbar("Add Atleast One Image / Select Thumbnail .", {
                variant: "error",
            });
        }
        else if(slideIds.length<0){
        
            enqueueSnackbar("Add Atleast One Image / Select Thumbnail .", {
            variant: "error",
        });
    }
        else if(!titleDescription){
            enqueueSnackbar("Description Field Empty.", {
                variant: "error",
            });
            
        }
        else if(storyImageArray?.length<=0){
            enqueueSnackbar("Add Atleast One Image.", {
                variant: "error",
            });
           
        }
        else{
            dispatch(
                webStoriesAction.handleUpdatewWebStories(
                    updateWebstoriesPayload,
                    refreshReload
                )
            );
          
        }
            
       
    }

    };
  

    const handleUpdateAddNew = () => {
        // const addandUpdateData=[
        //     ...storyImageArray,
        //     {
        //         id:"",
        //         media_title: "",
        //         media_description: "",
        //         media_caption_placement: "center",
        //         transition_effects: "",
        //         media_url: "",
        //         media_type: "image",
        //         sort_order: "",
        //     },
        // ]
        // const arrayOfObjects = Object.values(addandUpdateData);
        // console.log(arrayOfObjects,"arrayOfObjects");
        // setStoryImageArray(arrayOfObjects);
        setSetEdit(false);
        setModalCard(true);
        dispatch(webStoriesAction.emptyMedia())
    };
    const handleDeleteStoriesSlide = (elemID,mediaUrl) => {
        setVisible(false)
        if(thumbnail==mediaUrl){
            setThumbnail("")
        }
        setSlideIds([...slideIds,elemID])
 
        const deletedandUpdatestories = storyImageArray.filter(
            (elem, index) => {
                return elemID !== elem.id;
            }
        );
        
            setStoryImageArray(deletedandUpdatestories);


      
        
    };
    const handlEditstoriesSlide = (elemindex,elemId) => {
        setSetEdit(true);
        setEditIndx(elemindex);
        setImageId(elemId)
        const deletedandUpdatestories = storyImageArray.filter(
            (elem, index) => {
                return index === elemindex;
            }
        );
        setSlideEditDetils(deletedandUpdatestories);
        setModalCard(true);
    };
    const handleThumbnail=(image)=>{
        setThumbnail(image)

    }
    useEffect(()=>{
        if(storyImageArray?.length<=0){
            setThumbnail("")
        }


    },[storyImageArray])

    useEffect(() => {
       
        if (StoryDetails) {
            setModalCard(false)
            setId(StoryDetails?.id);
            setTitle(StoryDetails?.title);
            setTitleDescription(StoryDetails?.title_description);
            setStoryImageArray(StoryDetails?.story_detail);
            setIndx(StoryDetails?.story_detail?.length);
            setSortOrder(StoryDetails?.story_detail?.length);
            setThumbnail(StoryDetails?.thumbnail)
            setIsActive(StoryDetails?.is_active)
            setAltText(StoryDetails?.alt_text)

        }
    }, [StoryDetails]);
    useEffect(()=>{
        if(title?.length>250){
            enqueueSnackbar("Title length exceeding 200 character", {
                variant: "error",
            });

        }
        else if(titleDescription?.length>510){

            enqueueSnackbar("Description length exceeding 500 character", {
                variant: "error",
            });


        }

      

    },[title,titleDescription])
    useEffect(() => {
        
        let timeoutId;
      
        timeoutId = setTimeout(() => {
          setStoryImageArray(storyImageArray);
          setVisible(true)
        }, 100);
      
        return () => {
          clearTimeout(timeoutId);
        };
      }, [storyImageArray]);
      

    return (
        <div style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0.3})`:""}}>
            <div
                className="gutter-row"
                style={{ display: "flex", float: "right"}}
             
            >
                <Featured
                    handleWebStories={handleUpdateWebStories}
                    update={true}
                    modalCard={modalCard}
                />
            </div>
            <div className="story-title-box">
                <div className="story-title-label"style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                    <p>Story Title </p>
                    <div className="story-title-input" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                        <input
                            type="text"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} 
                            disabled={modalCard?true:false}
                        />
                        <img src={editblackLogo} alt="editlogo"></img>
                    </div>
                </div>
            </div>
            <div className="story-title-box" style={{ marginTop: "20px" }}>
                <div className="story-title-label" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                    <p>Meta Description </p>
                    <div className="story-title-input" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                        <input
                            type="text"
                            className="form-control"
                            value={titleDescription}
                            disabled={modalCard?true:false}
                            onChange={(e) =>
                                setTitleDescription(e.target.value)
                            }
                            style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} 

                        />
                        <img src={editblackLogo} alt="editlogo"></img>
                    </div>
                </div>
            </div>

            <div>
                <div className="story-card-container" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                    <div className="story-cards">
                        {visible&&storyImageArray?.map((elem, elemindx) => {

                            return (
                                <>
                                    {elem.media_url
                                        ?.split(".")
                                        .pop()
                                        .toLowerCase() == "mp4" ? (
                                            <div style={{ position: "relative", display: "inline-block",borderRadius:"10px" }}>
                                            <video controls width="210px" height="352" style={{marginTop:"9px",borderColor:"white",borderStyle:"solid",borderRadius:"14px"}}>
                                                <source src={elem.media_url} type="video/mp4" />
                                            </video>
                                            <div className="story-card-edit"  onClick={() =>
                                                        handlEditstoriesSlide(
                                                            elemindx,elem.id
                                                        )
                                                    }
                                                    style={{
                                                        display: "flex",
                                                        position: "absolute",
                                                       
                                                        // zIndex: 1,
                                                       
                                                        border: "none",
                                                        
                                                       
                                                       
                                                        padding: "7px 8px",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        borderRadius: "10px",
                                                        border: "1px solid #fff",
                                                        background: "rgba(0, 0, 0, 0.6)",
                                                        top: "85%",
                                                        left: "55%",
                                                        fontSize: "24px",
                                                        cursor: "pointer",
                                                    }}
                                                    >
                                                <img
                                                    src={editLogo}
                                                    alt="editImage"
                                                   
                                                ></img>
                                            </div>
                                            <div className="story-card-delete"   onClick={() =>
                                                        handleDeleteStoriesSlide(
                                                            elem.id
                                                        )
                                                    }
                                                    style={{
                                                        display: "flex",
                                                        position: "absolute",
                                                       
                                                        // zIndex: 1,
                                                       
                                                        border: "none",
                                                        
                                                       
                                                       
                                                        padding: "7px 8px",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        borderRadius: "10px",
                                                        border: "1px solid #fff",
                                                        background: "rgba(0, 0, 0, 0.6)",
                                                        top: "85%",
                                                        left: "75%",
                                                        fontSize: "24px",
                                                        cursor: "pointer",
                                                    }}
                                                    >
                                                <img
                                                    src={DeleteIcon}
                                                    alt="deleteIcon"
                                                  
                                                ></img>
                                            </div>
                                        </div>
                                    ) : elem.media_url ? (
                                        <div className="story-card-image">
                                             <p className="thumbnail" style={{background:(thumbnail==elem.media_url)?"rgb(24, 160, 251)":"rgba(0, 0, 0, 0.5)",cursor:"pointer"}} onClick={()=>handleThumbnail(elem.media_url)}>set as thumbnail</p>
                                            <img
                                                src={elem.media_url}
                                                className="story-card-image-main"
                                            ></img>
                                            <div className="story-card-edit"  onClick={() =>
                                                        handlEditstoriesSlide(
                                                            elemindx,elem.id
                                                        )
                                                    }>
                                                <img
                                                    src={editLogo}
                                                    alt="editImage"
                                                   
                                                ></img>
                                            </div>
                                            <div className="story-card-delete"   onClick={() =>
                                                        handleDeleteStoriesSlide(
                                                          elem.id,elem.media_url
                                                        )
                                                    }>
                                                <img
                                                    src={DeleteIcon}
                                                    alt="deleteIcon"
                                                  
                                                ></img>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            );
                        })}

                        <div className="story-card-add" style={{background:modalCard?`rgba(${0}, ${0}, ${0}, ${0})`:""}} >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <img src={plucIcon} alt="plusIcon"></img>

                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm  mt-2 px-4"
                                    onClick={() => handleUpdateAddNew()}
                                >
                                    Add new
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {modalCard ? (
                    <Cardmodal
                        setModalCard={setModalCard}
                        stories={storyImageArray}
                        indx={setEdit ? editIndx : indx}
                        setIndx={setIndx}
                        setStories={setStoryImageArray}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        slideEdit={setEdit ? true : false}
                        setSlideEditDetils={setSlideEditDetils}
                        sliteIndx={sliteIndx}
                        slideEditDetails={slideEditDetails}
                        imageId={imageId}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditListing;
