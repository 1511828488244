import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as EventsBookingAction from "../../../actions/eventsAction";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    padding: 6,
    borderRadius: 3,
  },
}));

 const SearchBar = ({ status, onResetPage, setPageChange, searchValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const startDate = useSelector((state) => state.eventsListing.filterStartDate);
  const endDate = useSelector((state) => state.eventsListing.filterEndDate);
  const secId = useSelector((state) => state.eventsListing.getSecId);
  const getSecId = useSelector(state => state?.eventsListing?.getSecId);
  const [filterSecId, setFilterSecId] = useState(secId);
  // const [bookingIndex, setBookingIndex] = useState(0);
  // const initialPackageBookings = bookingData && bookingData[bookingIndex];
  // console.log("initialPackageBookings",initialPackageBookings, initialPackageBookings?.id);
  // const initialPackId = initialPackageBookings?.id
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(EventsBookingAction.searchBooking("", status));
    setFilterSecId(secId);
  }, [startDate, endDate, secId]);

  const handleChange = (event) => {
    // search(value);
    setPageChange(false);
    dispatch(EventsBookingAction.eventBookingSearchValue(event.target.value, status));
    dispatch(EventsBookingAction.searchBooking(event.target.value, status));
    if(event.target.value === ""){
      dispatch(EventsBookingAction.getUpcomingBookingsList(page, getSecId));
      dispatch(EventsBookingAction.getCancelledBookingsList(page, getSecId));
      dispatch(EventsBookingAction.getPastBookingsList(page, getSecId));
      setPageChange(true);
      onResetPage()
    }
  }

  return (
    <TextField
      className={classes.input}
      defaultValue={""}
      size="small"
      value={searchValue}
      placeholder={"Search"}
      fullWidth={true}
      onChange={handleChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default SearchBar;