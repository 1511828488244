import {
  SEARCH_BOOKING_CONFIRMED,
  SEARCH_BOOKING,
  SEARCH_BOOKING_CANCELLED,
  SEARCH_BOOKING_FOLLOWUP,
  SEARCH_BOOKING_REFUNDED,
  BOOKING_FILTER_START_DATE,
  BOOKING_FILTER_END_DATE,
  BOOKINGLIST_ID,
  PAGE_NUM,
  BLOCK_RESTRICTIONS
  
} from "../actions/bookingAction";
import {
  BOOKINGLIST,
  PARTNERBOOKINGLIST,
  BOOKING_REFUND_LIST,
  PARTNER_CONFIRMED_BOOKINGLIST,
  PARTNER_CANCELLED_BOOKINGLIST,
  PARTNER_FOLLOWUPLIST,
  BOOKING_CONFIRMED_LIST,
  BOOKING_CANCELLED_LIST,
  UPDATE_BOOKING_STATUS,
  SEARCH_TEXT_VALUE,
  CORP_CONFIRMED_SEARCH_VALUE,
  CORP_CANCELLED_SEARCH_VALUE,
  CORP_FOLLOWUP_SEARCH_VALUE,
  GET_CORP_BOOKING_STATUS,
  BOOKED_SEARCH_TRUE_VALUE,
  CONFIRMED_SEARCH_TRUE_VALUE,
  CANCELLED_SEARCH_TRUE_VALUE,
  AVAILABILITY_REQUEST_LIST,
  CORP_AVAILABILITY_SEARCH_VALUE,
  AVAILABILITY_PAGE_NUM,
  CORP_REFUNDED_SEARCH_VALUE,
  ADULTS_GUEST,
    CHILDRENS_GUEST,
    INFANTS_GUEST,
    SCHEDULE_MODAL_OPEN_TRUE,
    FOLLOWUP_LIST,
    
} from "../actions/types";

const initialState = {
  bookings: [],
  confirmedBookings: [],
  cancelledBookings: [],
  partnerBookings: [],
  refundBookings:[],
  followupList:[],
  partnerconfirmedBookings: [],
  partnercancelledBookings: [],
  availabilityRequest:[],
  partnerfollowUP:[],
  selectedStatus: undefined,
  followUpSearchText: "",
  filterStartDate: null,
  filterEndDate: null,
  getSecId: null,
  page_num: 1,
  searchValue:"",
  corpRefundedSearchValue:"",
  corpConfirmedSearchValue:"",
  corpCancelledSearchValue:"",
  corpAvailabilitySearchValue:"",
  corpFollowupSearchValue:"",
  getStatus:"requested",
  bookedSearchTrueValue:false,
  confirmedSearchTrueValue:false,
  cancelledSearchTrueValue:false,
  availabilityPage:1,
  blockDateRestrictions:[],
  adultsGuest:0,
    childrenGuest:0,
    infantGuest:0,
    scheduleModalOpenTrue:false,
};

const bookingListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BOOKINGLIST: {
      return {
        ...state,
        bookings: payload,
      };
    }
    case BLOCK_RESTRICTIONS: {
      return {
        ...state,
        blockDateRestrictions: payload,
      };
    }
    case PARTNERBOOKINGLIST : {
      console.log('PARTNERBOOKINGLIST', payload.data)
      return {
        ...state,
        partnerBookings:payload.data
      }
    }
    case PARTNER_CONFIRMED_BOOKINGLIST : {
      return {
        ...state,
        partnerconfirmedBookings:payload.data
      }
    }
    case PARTNER_CANCELLED_BOOKINGLIST : {
      return {
        ...state,
        partnercancelledBookings:payload.data
      }
    }
    case PARTNER_FOLLOWUPLIST : {
      return {
        ...state,
        partnerfollowUP:payload.data
      }
    }
    case AVAILABILITY_REQUEST_LIST: {
      return {
        ...state,
        availabilityRequest: payload,
      };
    }
 
    case SEARCH_BOOKING: {
      return {
        ...state,
        bookings: payload,
      };
    }

    case BOOKING_CONFIRMED_LIST: {
      return {
        ...state,
        confirmedBookings: payload,
      };
    }
    case SEARCH_BOOKING_CONFIRMED: {
      return {
        ...state,
        confirmedBookings: payload,
      };
    }

    case BOOKING_REFUND_LIST: {
      return {
        ...state,
        refundBookings: payload,
      };
    }
    case FOLLOWUP_LIST: {
      return {
        ...state,
        followupList: payload,
      };
    }
    case SEARCH_BOOKING_FOLLOWUP: {
      return {
        ...state,
        followupList: payload,
      };
    }
    case SEARCH_BOOKING_REFUNDED:{
      return {
        ...state,
        refundBookings:payload
      }
    }

  
    case BOOKING_CANCELLED_LIST: {
      return {
        ...state,
        cancelledBookings: payload,
      };
    }
    case SEARCH_BOOKING_CANCELLED: {
      return {
        ...state,
        cancelledBookings: payload,
      };
    }
    case UPDATE_BOOKING_STATUS: {
      return {
        ...state,
        selectedStatus: payload,
      };
    }
    case BOOKING_FILTER_START_DATE: {
      return {
        ...state,
        filterStartDate: payload,
      };
    }
    case BOOKING_FILTER_END_DATE: {
      return {
        ...state,
        filterEndDate: payload,
      };
    }
    case BOOKINGLIST_ID: {
      return {
        ...state,
        getSecId: payload,
      };
    }
    case PAGE_NUM: 
    {
      return {
        ...state,
        page_num: payload,
      };
    }
    case AVAILABILITY_PAGE_NUM: 
    {
      return {
        ...state,
        availabilityPage: payload,
      };
    }
    case SEARCH_TEXT_VALUE: 
    {
      return {
        ...state,
        searchValue: payload,
      };
    }
    case CORP_CONFIRMED_SEARCH_VALUE: 
    {
      return {
        ...state,
        corpConfirmedSearchValue: payload,
      };
    }
    case CORP_REFUNDED_SEARCH_VALUE: 
    {
      return {
        ...state,
        corpRefundedSearchValue: payload,
      };
    }
    case CORP_FOLLOWUP_SEARCH_VALUE: 
    {
      return {
        ...state,
        corpFollowupSearchValue: payload,
      };
    }
    case CORP_CANCELLED_SEARCH_VALUE: 
    {
      return {
        ...state,
        corpCancelledSearchValue: payload,
      };
    }
    case CORP_AVAILABILITY_SEARCH_VALUE: 
    {
      return {
        ...state,
        corpAvailabilitySearchValue: payload,
      };
    }
    case   GET_CORP_BOOKING_STATUS: {
      return {
        ...state,
        getStatus: payload,
      };
    }
    case   BOOKED_SEARCH_TRUE_VALUE: {
      return {
        ...state,
        bookedSearchTrueValue: payload,
      };
    }
    case   CONFIRMED_SEARCH_TRUE_VALUE: {
      return {
        ...state,
        confirmedSearchTrueValue: payload,
      };
    }
    case   CANCELLED_SEARCH_TRUE_VALUE: {
      return {
        ...state,
        cancelledSearchTrueValue: payload,
      };
    }
    case ADULTS_GUEST: {
      return {
        ...state,
        adultsGuest: payload
      }
  }
  case CHILDRENS_GUEST: {
      return {
        ...state,
        childrenGuest: payload
      }
  }
  case INFANTS_GUEST: {
      return {
        ...state,
        infantGuest: payload
      }
  }
  case SCHEDULE_MODAL_OPEN_TRUE: {
    return {
      ...state,
      scheduleModalOpenTrue: payload
    }
}
    default:
      return state;
  }
};

export default bookingListingReducer;
