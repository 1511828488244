import React, { useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { experiencesbookingStatusValues } from '../../../resources/status';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import ConfirmRefundModal from '../../../common/confirmRefundModel';


import {
    getExperienceBookingList,
    experienceBookingsSearch,
    experienceBookingStatusUpdate,
    pageId,
    experienceSearchValue
} from '../../../actions/experienceBookingAction';
import ClosedModal from '../closeRequestModal';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index'
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';
const Bookings = () => {
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const[refundModel,setRefundModel]=useState(false)
    const [moreInfoData, setMoreInfoData] = useState({});
    const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    // const [searchVal, setSearchVal] = useState('');

    const [id, setId] = useState(null);
    const[refundPackId,setRefundPackId]=useState()
    const[iscopr,setIscorp]=useState()
    const searchVal = useSelector((state) => state.experienceBooking.experiencelBookedSearchVal);
    const [pageVal, setPageVal] = useState(1);
    const[status,setStatus]=useState()
    const ffStartDate = useSelector((state) => state.experienceBooking.experienceBookingStartDate);
    const ffEndDate = useSelector((state) => state.experienceBooking.experienceBookingEndDate);
    const experienceBooked = useSelector(state => state.experienceBooking.experienceBooking.results);
    const totapPage = useSelector(state => state.experienceBooking.experienceBooking.total_pages);
    const pageNum = useSelector(state => state.experienceBooking.pageNum);
    const sectionID = useSelector(state => state.experienceBooking.experienceBookingsSecId);
    const experienceBookingNxtPgeId = useSelector(state => state.experienceBooking.experienceBookingNxtPgeId);
    const experienceBookingCurrentPgeId = useSelector(state => state.experienceBooking.experienceBookingCurrentPgeId);
    const dispatch = useDispatch();
    useEffect(()=>{
        const data=experienceBooked?.map((elem)=>{
            return parseInt(elem.amount_paid)
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(totalamount/experienceBooked?.length)

    },[experienceBooked])

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => document.body.style.overflow = 'unset';
    }, [showMoreInfo]);
    

    useEffect(() => {
        if (experienceBookingNxtPgeId === null && experienceBookingCurrentPgeId > 1 && experienceBooked?.length === 0) {
            dispatch(getExperienceBookingList(1, sectionID, "booked"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [experienceBooked, pageVal])

    const handleRefund=(id)=>{
        setRefundModel(true)
        setRefundPackId(id)
        

    }

    const handlePagination = (e, value) => {
        if (searchVal !== "" || ffStartDate !== null && ffEndDate !== null) {
            dispatch(experienceBookingsSearch(value, 'booked', searchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        } else {
            dispatch(getExperienceBookingList(value, sectionID, "booked"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };

    const handleChange = (event) => {
        let input = event.target.value
        dispatch(experienceBookingsSearch(pageNum, 'booked', event.target.value, sectionID, ffStartDate, ffEndDate));
        dispatch(experienceSearchValue(input, "booked"));
        dispatch(pageId(1));
    };

    const refreshExperienceBooking = () => {
        if (searchVal !== '') {
            dispatch(experienceBookingsSearch(pageNum, 'booked', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"))
        } else if (ffStartDate !== null && ffEndDate !== null) {
            dispatch(experienceBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(experienceBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(experienceBookingsSearch(pageNum, 'booked', searchVal, sectionID, ffStartDate, ffEndDate));
        } else {
            dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"))
            dispatch(getExperienceBookingList(pageNum, sectionID, "booked"))
        }
    };

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;
        setStatus(status)
        setId(id)
        setIscorp(corporate)
     if(status === "cancelled")setRequestModal(true);
     else{
        dispatch(experienceBookingStatusUpdate(status, id, corporate, sectionID,null, refreshExperienceBooking))
        dispatch(pageId(pageVal));

     }
        
    };

    const experienceBookingDisplayData = experienceBooked && experienceBooked.map(data => (
        <AdminListing
            packTitle={data.pack_title}
            address={data.address}
            amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                    return scheduleDate
                } else {
                    return `${scheduleDate}, `
                }
            }) :
                //   console.log("data", data)}
                // data.schedule.to === ""? `${data.schedule.from}`: `${data.schedule.from} to ${data.schedule.to}`}
                (typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to !== "" ? `${data.schedule.from} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to === "" ? `${data.schedule.from}` : (data.schedule === null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            messageTitle={data.special_request ? data.special_request : 'No Message'}
            specialRequest={data.special_request ? data.special_request : "N/A"}
            isExperienceBooking={true}
            value={data.booking_status}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusValues={experiencesbookingStatusValues}
            onClick={() => moreInfoHandler(data)}
            id={data.id}
            data={data}
            corporate={data.corporate}
            statusChangeHandler={statusChangeHandler}
            handleRefund={handleRefund}
            status={"booked"}
            voucher_info={data?.voucher_info}
           
        />
    ));

    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
            dispatch(experienceBookingStatusUpdate(status, id, iscopr, sectionID,closeRequestMessage, refreshExperienceBooking))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    }
    

    return (
        <>
            <SearchBar
                searchVal={searchVal}
                handleChange={handleChange}
            />

        
      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
            {experienceBookingDisplayData}
            {showMoreInfo &&
                <CommonMoreInfoModel
                    open={showMoreInfo}
                    modalClose={() => setShowMoreInfo(false)}
                    moreInfoData={moreInfoData}
                    isCancellationMessage={false}
                />
            }

            {refundModel&&<ConfirmRefundModal 
                 open={refundModel}
                 modalClose={() => setRefundModel(false)}
                 section={"experience"}
                 refundPackId={refundPackId}


            />}
            <Pagination
                totalPage={totapPage}
                page={pageNum}
                onChange={(event, value) => handlePagination(event, value)}
            />
                {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }

            
        </>
    );
};

export default Bookings;