import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyFamilyFirstBooking from '../../../../common/myFamilyFirstBookings'
import SearchBar from '../../../../common/commonSearchBar';
import MyFamilyFirstMoreInfoModal from '../../../gifts/myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../../common/pagination';
import * as HoneymoonAction from '../../../../actions/honeymoonAction'
import ClosedModal from '../../closeRequestModal';
import { closeRequestInputVal } from '../../../../actions/holidayRequestAction';

const Confirmed = ({status}) => {
    const dispatch = useDispatch();
    const [ id, setId ] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [ searchVal, setSearchVal ] = useState('');
    const [page, setPage] = useState(1);
    const[statuschange,setStatuschange]=useState()
    const [confirmedMoreInfoModalData, setConfirmedMoreInfoModalData] = useState({});
   const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    const honeymoonConfirmedData = useSelector(state => state.honeymoon?.honeymoonConfirmed?.results);
    const totalPage = useSelector(state => state.honeymoon?.honeymoonConfirmed?.total_pages);
    const START_DATE = useSelector(state => state.honeymoon.startDate);
    const END_DATE = useSelector(state => state.honeymoon.endDate);
    const SEARCH_TERM = useSelector(state => state.honeymoon.searchTerm);
    const PAGE_NO = useSelector(state => state.honeymoon.pageNo);

    const [requestModal, setRequestModal] = useState(false);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);

    useEffect(() => {
       
        let value = [];
        honeymoonConfirmedData?.map((elem) => {
          if (elem.price) {
            value.push(elem.price)
         
          }
        
        },);
        const valueSum=value.reduce((acc,elem)=>{
            return acc+elem
    
        },0)
        setTotalOrderValue(valueSum)
            setAverageOrderValue(Math.round(valueSum/honeymoonConfirmedData?.length));
        
      }, [honeymoonConfirmedData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => document.body.style.overflow = 'unset';
    }, [showModal]);
    useEffect(() => {
        setPage(1)
      }, [status])
    const handleMoreInfoClick = (data) => {
        setShowModal(true);
        setConfirmedMoreInfoModalData(data);
    };

    const requestStatusChange = (event, id,data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;
        setId(id);
        setStatuschange(status)
 if(status === "cancelled")setRequestModal(true);
 
 else{
    dispatch(HoneymoonAction.honeymoonRequestStatusChange(status, id, refereshList))

 }

    };
    const refereshList = () => {
        dispatch(HoneymoonAction.getHoneymoonRequested(1));
        dispatch(HoneymoonAction.getHoneymoonPlanning(1));
        dispatch(HoneymoonAction.getHoneymoonConfirmed(page));
        dispatch(HoneymoonAction.getHoneymoonCancelled(1));
    };

    const handlePagination = (e, value) => {
        setPage(value);
        dispatch(HoneymoonAction.setPageNo(value))
        if (SEARCH_TERM || START_DATE || END_DATE) {
            dispatch(HoneymoonAction.searchHoneymoonRequests(SEARCH_TERM, START_DATE, END_DATE, "confirmed", value));
        } else {
            dispatch(HoneymoonAction.getHoneymoonConfirmed(value));
        }
    };

    const handleChange = (event) => {
        if (event.target.value) {
            dispatch(HoneymoonAction.searchHoneymoonRequests(event.target.value, START_DATE, END_DATE, "confirmed", PAGE_NO));
        } else {
            dispatch(HoneymoonAction.getHoneymoonConfirmed(1));
            setPage(1);
        }
        dispatch(HoneymoonAction.setSearchTerm(event.target.value))
    };

    const closeRequestHandler = () => {
  
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
           else {
          dispatch(HoneymoonAction.honeymoonRequestStatusChange(statuschange, id,closeRequestMessage, refereshList))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };
      
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    const honeymoonConfirmedDisplayData = honeymoonConfirmedData && honeymoonConfirmedData.map((data) => (
        <MyFamilyFirstBooking
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request ? data.specific_request : "N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={requestStatusChange}
            isConfirmed={true}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));

    return (
        <>
            <SearchBar
                searchVal={SEARCH_TERM}
                handleChange={handleChange}
            />
            {honeymoonConfirmedData && honeymoonConfirmedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            } 
     <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
            {honeymoonConfirmedDisplayData}
            <MyFamilyFirstMoreInfoModal
                open={showModal}
                close={() => setShowModal(false)}
                modalData={confirmedMoreInfoModalData}
                section={"Honeymoon Requested"}
                isCancellationMessage={false}
            />
            <Pagination
                totalPage={totalPage}
                page={page}
                onChange={(event, value) => handlePagination(event, value)}
            />
              {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Confirmed;
