import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

export default class SpecificDatePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: []
    };
  }

  handleDayClick(day, { selected }) {
    const selectedDays = this.state.selectedDays.concat();
    // const newDay =
    //   day.getDate() +
    //   "-" +
    //   parseInt(day.getMonth() + 1) +
    //   "-" +
    //   day.getFullYear();
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    const date = moment(day).format('YYYY-MM-DD')
		const today = moment().format("YYYY-MM-DD")
		if(moment(date).isBefore(today)){
      return false
    }else{
      this.setState({ selectedDays }, ()=>{this.props.addSpecificDateArray(this.state.selectedDays);});
    }
  //  console.log(this.state.selectedDays);
  }

  render() {
    
    return (
      <div>
        <DayPicker
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
          disabledDays= {{before: new Date()}}
        />
      </div>
    );
  }
}
