import React from "react";

import { ImCross } from "react-icons/im";
import "../common/addNewSection.scss";
import ImageCropper from "../components/utilities/ImageCropper";
import Modal from "@material-ui/core/Modal";

const AddNewSection = ({
  showMyModal,
  closeHandle,
  heading,
  image,
  labelTextOne,
  btnTextOne,
  btnTextTwo,
  labelTextTwo,
  labelTextThree,
  valueOne,
  onChangeOne,
  valueTwo,
  onChangeTwo,
  valueThree,
  onChangeThree,
  handleSubmit,
  closeModal,
  handleFilePickChange,
  inputFile,
  isUpdateImageSection,
  isAddImageSection,
  checkedValue,
  addCheckValue,
  checkHandler,
  isEventsAddNewSection,
  isEventsUpdateSection,
  isEventSection,
  isImage,
  isPriorityError,
  isSectionNameError,
  isSectionDescError,
  isPriorityEmptyError,
  disabled,
  pointerEvents,
  isHolidayAddNewSection,
  isHolidayUpdateSection,
  isExperienceSection,
  checkBoxdisable,
  isUpdateSec,
  codeRefrence,
  refrenceCodeHandler,
  iscodeError,
  refCodeValue,
  codeLessError,
  codeMoreError,
  codeMatchError,
  descMoreError,
  pointerEventsForRefCode,
  isDescription,
  isDestination,
  imageTitle,
  setImageTitle,
  altText,
  setAltText
}) => {
  return (
    <Modal
      className={`modal-wrapper ${showMyModal ? "show" : "hide"}`}
      open={showMyModal}
      onClose={closeHandle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="card-box card-box-index" style={{ width: "400px" }}>
        <div className="add-new-section-heading">
          <h5>{heading}</h5>
          <span
            onClick={closeHandle}
            style={{
              position: "absolute",
              top: "35px",
              right: "25px",
            }}
          >
            <ImCross />
          </span>
        </div>
        <div className="add-new-section-img-container add-new-img-cropper" >
          <img src={image} alt="secImage" />
          {/* image button with crop option here */}
          <ImageCropper
            fileRef={inputFile}
            onChange={handleFilePickChange}
            cropOptions={{ aspect: 5 / 3, maxZoom: 10 }}
            outputOptions={{ maxWidth: 400, maxHeight: 300 }}
            previewOptions={{ width: 400, height: 400 }}
            buttonStyle={{
              width: 90,
              height: 20,
              backgroundColor: "lightgrey",
              fontSize: 12,
              textTransform: "capitalize",
            }}
            isUpdateImageSection={isUpdateImageSection}
            isAddImageSection={isAddImageSection}
            isEventsAddNewSection={isEventsAddNewSection}
            isEventsUpdateSection={isEventsUpdateSection}
            isHolidayAddNewSection={isHolidayAddNewSection}
            isHolidayUpdateSection={isHolidayUpdateSection}
            isUpdateSec={isUpdateSec}
            imageTitle={imageTitle}
            setImageTitle={setImageTitle}
            altText={altText}
            setAltText={setAltText}
          />
        </div>
        {isImage ? <p style={{ color: "red", marginTop: "7px" }}>Image need to be added</p> : null}
        <form onSubmit={handleSubmit}>
     
        
          <div className="form-group-container">
            {/* <div className="form-content">
              <label>Alt-Text</label>
              <input type="text" value={altText} onChange={(e)=>setAltText(e.target.value)} style={{ pointerEvents: pointerEvents }} />
              {isSectionNameError ? <p style={{ color: "red", marginTop: "7px" }}>Alt-Text must not be empty</p> : ''}
            </div>
    
            <div className="form-content">
              <label>Image-Title</label>
              <input type="text" value={imageTitle} onChange={(e)=>setImageTitle(e.target.value)} style={{ pointerEvents: pointerEvents }} />
              {isSectionNameError ? <p style={{ color: "red", marginTop: "7px" }}>Image-Title must not be empty</p> : ''}
            </div> */}
            <div className="form-content">
              <label>{labelTextOne}</label>
              <input type="text" value={valueOne} onChange={onChangeOne} style={{ pointerEvents: pointerEvents }} />
              {isSectionNameError ? <p style={{ color: "red", marginTop: "7px" }}>Section Name must not be empty</p> : ''}
            </div>
            {isDescription ? <div className="form-content">
              <label>{labelTextThree}</label>
              <textarea type="text" value={valueThree} onChange={onChangeThree}  />
              <span style={{fontSize: "10px", float: "right"}}>{valueThree?.length} char</span>
              {isSectionDescError ? <p style={{ color: "red", marginTop: "7px" }}>Description must not be empty</p> : ''}
              {descMoreError ? <p style={{ color: "red", marginTop: "7px" }}>Description should not be more than 200 characters</p> : ''}
            </div> : ""}
            <div className="form-content">
              <label>{labelTextTwo}</label>
              <input type="number" value={valueTwo} onChange={onChangeTwo} />
              {isPriorityEmptyError ? <p style={{ color: 'red', marginTop: "7px" }}>Priority must not be empty</p> : isPriorityError ? <p style={{ color: 'red', marginTop: "7px" }}> Priority value should not be negative</p> : null}
            </div>
            {!isEventSection && !isExperienceSection && !isDestination && <div class="form-check" style={{ marginTop: "10px" }}>
              <div style={{ display: checkBoxdisable }} id="check-container">
                <input class="form-check-input position-static" onChange={checkHandler} type="checkbox" defaultChecked={checkedValue || addCheckValue} /><span style={{ marginLeft: "10px" }}>Gift Voucher</span>
              </div>
            </div>}
            {!isDestination &&<div className="form-content">
              <label>{codeRefrence}</label>
              <input type="text" value={refCodeValue} onChange={refrenceCodeHandler} style={{ pointerEvents: pointerEventsForRefCode }}
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z]*$/g.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {iscodeError ? 
              <p style={{ color: "red", marginTop: "7px" }}>Please enter code </p> : 
              codeLessError ? <p style={{color:"red"}}> Code shoule be two letters </p> : 
              codeMoreError ? <p style={{color:"red"}}>Code should not be more then two letters</p> : codeMatchError ? <p style={{color:"red"}}>This code is already exist</p>:""}
            </div>}
            <div className="add-new-section-btn-container">
              <button
                type="button"
                className="add-new-section-cancel-btn"
                onClick={closeHandle}
              >
                {btnTextOne}
              </button>
              <button type="submit" className={`add-new-section-done-btn ${disabled ? 'disable-btn' : ''}`} disabled={disabled}>
                {btnTextTwo}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddNewSection;
