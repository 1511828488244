export const holidaysbookingStatusValues = [
    {
        name: 'Booked',
        value: 'booked'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
];
export const holidaysConfirmedStatusValues = [
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
];
export const holidaysConcelledStatusValues = [
    {
        name: 'Cancelled',
        value: 'cancelled'
    },

    {
        name: 'Confirmed',
        value: 'confirmed'
    },
];

export const holidaysRequestStatusValues = [
    {
        name: 'Requested',
        value: 'requested'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
    {
        name: 'Cancelled',
        value: 'closed'
    }
];

export const holidaysRequestFollowUpStatusValues = [
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'closed' 
    }
];
export const holidaysRequestConfirmedStatusValues=[
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'closed'
    },
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },

]
export const holidaysRequestClosedStatusValues = [
    {
        name: 'Cancelled',
        value: 'closed'
    },
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
];

export const myFamilyFirstStatusValues = [
    {
        name: 'Requested',
        value: 'requested'
    },
    {
        name: 'Planning',
        value: 'planning'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
];
export const experiencesbookingStatusValues = [
    {
        name: 'Booked',
        value: 'booked'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
];
export const experiencesConfirmedStatusValues = [
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
];
export const experiencesConcelledStatusValues = [
    {
        name: 'Cancelled',
        value: 'cancelled'
    },

    {
        name: 'Confirmed',
        value: 'confirmed'
    },
];
export const availabilityStatusValue = [
    {
        name: 'Requested',
        value: 'requested'
    },
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
 
    // {
    //     name: 'Booked',
    //     value: 'booked'
    // },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
];

export const followUPStatusValue = [

    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
 
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
];

export const corpAvailabilityStatusValue = [
    {
        name: 'Requested',
        value: 'requested'
    },
    {
        name: 'Follow-Up',
        value: 'follow_up'
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
];
export const paymentStatusValues = [
    {
        name: 'Pending',
        value: 'pending'
    },
    {
        name: 'Booked',
        value: 'booked'
    },
];


  