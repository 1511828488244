import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import EditIcon from "../../assets/images/edit.svg";
import DeleteIcon from "../../assets/images/deleteblack.svg";
import DeleteIconWhite from "../../assets/images/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import AddImage from "./addImage";
import * as popular_destination_action from "../../actions/popularDestinationAction";

const EditDiv = ({ popularlists,locationType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const mediaUrl = useSelector((state) => state.popularDestions.mediaURL?.url);
  const stateAndCountryLists = useSelector(
    (state) => state.popularDestions.stateAndCountryLists
)

  const [editData, setEditData] = useState();
  const [idEdit, setIdEdit] = useState(false);
  const [name, setName] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState(1);
  const [iseditimage, setIseditimage] = useState(false);
  const[isNameChange,setIsNameChange]=useState(false)
  const[isPriorityChange,setIsPriorityChange]=useState(false)
  const[loc_type,setLoc_type]=useState(null)
  const[suggestion,setSuggestions]=useState()
  const[stateLists,setStateLists]=useState()
  const[countryLists,setCountryLists]=useState()
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")

  console.log(suggestion,"suggestion",idEdit);


  const editableData = {
    id: idEdit,
    ...(isNameChange && { name: name }),
    background_image: backgroundImage,
    description: description,
    ...(isPriorityChange&&{priority: priority}),
    location_type:loc_type,
    title_text:imageTitle,
    alt_text:altText
  };
  const [idDelete, setIdDelete] = useState(false);

  const handleEdit = (id) => {
    setIdEdit(id);
    setEditData(
      popularlists?.filter((elem) => {
        if (id == elem.id) {
          return elem;
        }
      })
    );
  };
  const refreshReload=()=>{
    
  
    setTimeout(() => {
        window.location.reload();
        
    }, 1000);

}

  const handleEditcCancel = () => {
    setIdEdit(false);
    setIdDelete(false);
    refreshReload()
    
  };

  const handleDelete = (id) => {
    setIdDelete(id);
  };

const sucessUpdatePopUp=()=>{
  enqueueSnackbar("Updated Successfully", {
    variant: "success",
  });

}
const errorUpdatePopUP=(message)=>{
  enqueueSnackbar(`${message}`, {
    variant: "error",
  });

  setTimeout(() => {
    window.location.reload();
    
}, 1000);



}

  const handleDeleteConfirm = (id) => {
    dispatch(popular_destination_action.handleDeletePopularDestination(id,refreshReload));

    enqueueSnackbar("Deleted Successfully", {
      variant: "success",
    });
    setIdEdit(false);
    setIdDelete(false);
  };

  const imageChange = (id) => {
    setIseditimage(id);
    setBackgroundImage(null)
    dispatch(popular_destination_action.emptyMedia());
    
  };

  const handleUpdate = () => {

     if(!name){
      enqueueSnackbar("Please enter name", {
        variant: "error",
      });
   
  }

  
else if(!priority ){
    enqueueSnackbar("Please add priority", {
      variant: "error",
    });
 
}
 else if(!editableData?.background_image ){
      enqueueSnackbar("Please add image", {
        variant: "error",
      });
   
  }
  else{
    dispatch(popular_destination_action.handleUpdatePopularDestination(editableData,refreshReload,sucessUpdatePopUp,errorUpdatePopUP));
    setIdEdit("")
   

  }
  };
  const handleEditName=(value)=>{
    setIsNameChange(true)
    setName(value)
    if(locationType=="state"){
      const filteredSuggestions = stateLists?.filter(suggestion =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
      
  
    
        setSuggestions(filteredSuggestions);

    }
    else{
      const filteredSuggestions = countryLists?.filter(suggestion =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
  
    
        setSuggestions(filteredSuggestions);

    }
 
  }
  const handleEditPriority=(value)=>{
    setIsPriorityChange(true)
    setPriority(value)
  }
  const handleSuggestionClick = (value) => {
    setName(value);
    setSuggestions([]);
  };

  useEffect(() => {

    setName(editData?.[0].name);
    setDescription(editData?.[0].description);
    setPriority(editData?.[0].priority);
    setBackgroundImage(editData?.[0].background_image);
    setPriority(editData?.[0].priority);
    setLoc_type(editData?.[0].location_type)
    setAltText(editData?.[0].alt_text)
    setImageTitle(editData?.[0].title_text)
    
  }, [editData]);

  useEffect(() => {
    setBackgroundImage(mediaUrl);
  }, [mediaUrl]);
  useEffect(()=>{
      
      
    if (stateAndCountryLists?.data?.countries_list_for_corporate &&
      stateAndCountryLists?.data?.countries_list_for_holiday&&
      stateAndCountryLists?.data?.countries_list_for_experience&&
      stateAndCountryLists?.data?.states_list_for_corporate &&
      stateAndCountryLists?.data?.states_list_for_holiday &&
      stateAndCountryLists?.data?.states_list_for_experience) 
      {
    
            const all_country_lists = [
            ...stateAndCountryLists.data.countries_list_for_corporate?.map((elem)=>elem.location),
            ...stateAndCountryLists.data.countries_list_for_holiday?.map((elem)=>elem.location),
            ...stateAndCountryLists.data.countries_list_for_experience?.map((elem)=>elem.location)
            ];
        
            const all_state_lists = [
            ...stateAndCountryLists.data.states_list_for_corporate?.map((elem)=>elem.location),
            ...stateAndCountryLists.data.states_list_for_holiday?.map((elem)=>elem.location),
            ...stateAndCountryLists.data.states_list_for_experience?.map((elem)=>elem.location)
            ];

            function removeDuplicatesManually(array) {
                let uniqueArray = [];
                
                for (let i = 0; i < array.length; i++) {
                    let isDuplicate = false;
                    
                    // Check if the current element is already in the uniqueArray
                    for (let j = 0; j < uniqueArray.length; j++) {
                        if (array[i] === uniqueArray[j]) {
                            isDuplicate = true;
                            break;
                        }
                    }
                    
                    // If the element is not a duplicate, add it to uniqueArray
                    if (!isDuplicate) {
                        uniqueArray.push(array[i]);
                    }
                }
                
                return uniqueArray;
            }
            const filterd_duplicacy_all_state_lists=removeDuplicatesManually(all_state_lists)
            const filterd_duplicacy_all_country_lists=removeDuplicatesManually(all_country_lists)
  
            setStateLists(filterd_duplicacy_all_state_lists)
            setCountryLists(filterd_duplicacy_all_country_lists)
    
 
  }

},[stateAndCountryLists])

  return (
    <>
      {popularlists?.map((elem) => (
        <div className="edit-card-main" style={{ marginTop: "10px" }} key={elem.name}>
          <div className="state-serach">
          <input
            className="state"
            value={idEdit === elem.id ? name : elem.name}
            onChange={(e) => idEdit === elem.id ? handleEditName(e.target.value) : null}
            readOnly={idEdit !== elem.id}
          />
          <div style={{position:"relative"}}>
          {idEdit == elem.id&&<ul style={{ listStyle: 'none', height: "300px",overflowY: "auto", width: "230px", zIndex: "99999", position: "absolute", top: "5px",padding:"0px" }}>
  {suggestion?.map((suggestion, index) => (
    <li key={index} onClick={() => handleSuggestionClick(suggestion)} style={{ padding: "12px", backgroundColor: "#e6e6e6", textAlign: "center", fontSize: "18px", cursor: 'pointer', borderBottom: '1px solid #ccc', borderRadius: "2px" }} onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'} onMouseLeave={(e) => e.target.style.backgroundColor = "#e6e6e6"}>
      {suggestion}
    </li>
  ))}
</ul>

}

</div>

   

          </div>
          <div className="description">
            <p>Description</p>
            <textarea
             onChange={(e) => idEdit === elem.id && setDescription(e.target.value)}
             defaultValue={idEdit === elem.id ? description : elem.description}
             
              readOnly={idEdit !== elem.id}
            />

          </div>
          <div className="priority">
            <p>Priority</p>
            <input type="number"
              onChange={(e) => idEdit === elem.id && handleEditPriority(e.target.value)}

              defaultValue={idEdit == elem.id ? priority : elem.priority}
              readOnly={idEdit !== elem.id}
              onWheel={(e) => {
                // Prevent scroll behavior only when the input is focused
                if (document.activeElement === e.currentTarget) {
                  e.preventDefault();
                }
              }}
              onFocus={(e) => {
                // Disable scrolling when the input is focused
                e.currentTarget.addEventListener('wheel', (wheelEvent) => {
                  wheelEvent.preventDefault();
                }, { passive: false });
              }}
            />
          </div>
          <div className="edit-image">
            {(iseditimage == elem.id && !mediaUrl) ? (
              <AddImage altText={altText} setAltText={setAltText} imageTitle={imageTitle} setImageTitle={setImageTitle} />
            ) : (
              <img src={(mediaUrl && iseditimage == elem.id) ? backgroundImage : elem.background_image} alt="image" className="image" />
            )}


            {
              (idEdit === elem.id && !iseditimage) || (mediaUrl && iseditimage == elem.id) ? (
                <div className="delete-image-icon">
                  <img src={DeleteIconWhite} alt="deleteIcon" onClick={() => imageChange(elem?.id)} />
                </div>
              ):""
            }


          </div>
          <div className="edit_delete">
          {idEdit === elem.id && (
              <>
                <button className="back-button" onClick={handleEditcCancel}>Cancel</button>
                <button className="update-button" onClick={handleUpdate}>Update</button>
              </>
            )
          }

          {
            !(idEdit === elem.id || idDelete === elem.id) && (
              <>
                <img src={EditIcon} alt="editIcon" className="editLogo" onClick={() => handleEdit(elem?.id)} />
                <img src={DeleteIcon} alt="deleteIcon" className="deleteLogo" onClick={() => handleDelete(elem?.id)} />
              </>
            )
          }

{
  idDelete === elem.id && (
    <>
      <button className="back-button" onClick={handleEditcCancel}>Cancel</button>
      <button className="delete-button" onClick={() => handleDeleteConfirm(elem?.id)}>Delete</button>
    </>
  )
}

          </div>
        </div>
      ))}
    </>
  );
};

export default EditDiv;
