
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/partials/packageDetails.scss";
import { makeStyles } from "@material-ui/core/styles";
import * as corpListingAction from "../../../actions/corpListingAction";
import * as PartnerAction from "../../../actions/partnerAction";
import ArrowDown from '../../../assets/images/arrowDown.svg'
import { useSnackbar } from "notistack";
import {
  PARTNER_NAME, PARTNER_NAME_LENGTH, ADDRESS, PARTNER_DOMAIN, LOGO
} from '../../../utils/constants'


const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  showHide: {
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
  show: {
    opacity: 1,
    '&:hover': {
      opacity: 1
    }
  }
}));


export default function AddPartner({ data, noRecordFound }) {
  const classes = useStyles();
  // const addImageUrl = useSelector(state => state.corpListing.allImagesData)
  const addImageUrl = useSelector(state => state.partnerListing.partnerLogo)


  const [state, setState] = useState({
    // id: null,
    name: "",
    logo: '',
    email_domain: '',
    location: ""
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isOrgName, setIsOrgName] = useState(null);
  const [isOrgNameLength, setIsOrgNameLength] = useState(null);
  const [isLogoValidation, setLogoValidation] = useState(null);
  const [isAddress, setIsAddress] = useState(null);
  const [isOrgDomain, setIsOrgDomain] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [isLogoUpload, setIsLogoUpload] = useState(false);


  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  
  const inputFile = useRef(null);

  const addImages = (url) => {
    
    setState(prevState => ({
      ...prevState,
      logo: url.url
    }))
  }

  const handleFilePickChange = (event) => {
    const file = event.target.files[0];
    event.target.value = "";
    //upload file and replace reponse_url for url from response
    setIsLogoUpload(true)
    dispatch(PartnerAction.uploadPartnerLogo(file, addImages))
  };

  const handleAddNewImage = () => {
    inputFile.current.click();
  };

  const newDataSubmithandler = (e) => {
    e.preventDefault();
    setIsOrgName(false)
    setIsOrgNameLength(false)
    setIsAddress(false)
    setLogoValidation(false)
    setIsOrgDomain(false)
    const addNewData = {
      "name": state?.name,
      "email": state?.email_domain,
      "location": state?.location,
      "logo": addImageUrl?.data?.url,
    }
    if(state.name ==='' || state.name === null)setIsOrgName(true)
    else if(state.name.length>50)setIsOrgNameLength(true)
    else if(!addImageUrl?.data?.url)setLogoValidation(true)
    else if(state.location ==='' || state.location === null)setIsAddress(true)
    else if(state.email_domain ==='' || !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(state.email_domain))setIsOrgDomain(true)
    else{
    dispatch(PartnerAction.handleAddNewPartner(addNewData, handleRefreshList, handleError))
  }
  }
  console.log(state, "steta")


  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  }

  const handleRefreshList = (message) => {
    setState({
      name: "",
      logo: '',
      email_domain: '',
      location: ""
    })
    enqueueSnackbar('Updated Successfully', {
      variant: "success",
    });
    window.location.reload()
  };

  

  return (
    <div>
      <div className="packageDetails-holder" style={{height: "100vh"}}> 
        <form onSubmit={newDataSubmithandler}>
          <div className="form-options">
            <div>
              <h2>{noRecordFound ? `Add New Partner` : 'Adding Partner'}</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div>
                <button type="submit" className="btn btn-primary btn-save" >
                  Save
                </button>

              </div>

            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: "relative", padding: 0 }} className="form-group form-custom">
              {isLogoUpload && <img src={addImageUrl?.data?.url} style={{ height: '100%', width: '100%', borderRadius: '5px' }} />}
              <div>
                <div
                  onClick={handleAddNewImage}
                  style={{
                    padding: "6px 10px",
                    color: "black",
                    cursor: "pointer",
                    position: 'absolute',
                    left: '0px',
                    bottom: '23px',
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: 'small'
                  }}
                >
                  {<span className={!isLogoUpload ? classes.show : classes.showHide}>Upload Logo here</span>}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFilePickChange}
                  />
                </div>
                {isLogoValidation && <p style={{color:"red", width: 'max-content'}}>{LOGO}</p>}
              </div>
            </div>
            <div
              style={{ flex: 2, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
              <label for="">Partner Name</label>
              <input
                value={state?.data?.name}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("name", value)}
              />
              {isOrgName ? <p style={{color:"red"}}>{PARTNER_NAME}</p>
              : isOrgNameLength ? <p style={{color:"red"}}>{PARTNER_NAME_LENGTH}</p> : ''
            }


            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
            <img src={ArrowDown} style={{ position: 'absolute', right: '58px', bottom: '34px' }} />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: 'relative' }} className="form-group form-custom">
              <label for="">Address</label>
              <input
                value={state?.data?.location}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("location", value)}
              />
              {isAddress && <p style={{color:"red"}}>{ADDRESS}</p>}

            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
              <div>
                <label for="">Partner Email</label>
                {/* <h6 style={{ position: 'absolute', left: '23px', top: '53px', fontWeight: '400', opacity: '0.4' }}>employee@</h6> */}
                <input 
                  value={state?.data?.email_domain}
                  type="text"
                  className="form-control"
                  onChange={(value) => handleChange("email_domain", value)}
                />

              </div>
              {isOrgDomain && <p style={{color:"red"}}>{PARTNER_DOMAIN}</p>}
            </div>
          </div>
          <hr />
          
        </form>
      </div>
    </div >
  );
}
