import { useDispatch } from "react-redux";
import { API } from "../constants/api";
import { axiosPostWithToken, axiosPut } from "../services/apiServices";

import {
  EXPERIENCES_PENDING_REVIEW_LIST,
  EXPERIENCES_REJECTED_REVIEW_LIST,
  EXPERIENCES_REVIEW_END_DATE,
  EXPERIENCES_REVIEW_PAGE_ID,
  EXPERIENCES_REVIEW_PUBLISHED_LIST,
  EXPERIENCES_REVIEW_START_DATE,
  IS_EXP_DATA_LOADING,
} from "./types";

export const getExperienceReviewPendingList = (page) => {
  

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"pending"}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_EXP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_EXPERIENCES_REVIEW_LIST}`, body, token)
      .then((res) => {
        console.log(res.data.status_code)
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCES_PENDING_REVIEW_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_EXP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getExperienceReviewRejectedList = (page) => {
  

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"rejected"}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_EXP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_EXPERIENCES_REVIEW_LIST}`, body, token)
      .then((res) => {
        console.log(res.data.status_code)
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCES_REJECTED_REVIEW_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_EXP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getExperienceReviewPublishedList = (page) => {
  

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"approved"}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_EXP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_EXPERIENCES_REVIEW_LIST}`, body, token)
      .then((res) => {
        console.log(res);
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCES_REVIEW_PUBLISHED_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_EXP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getExperienceReviewsearch = (page, status, startDate, endDate) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        "next_page_id": 1,
        "page_size":10,
        "filters":[{"field":"status", "value":status},{"field":"from", "value":startDate},{"field":"to", "value":endDate}],
        "sort":{"field":"modified", "order":"D"}
    }
    dispatch({
      type: IS_EXP_DATA_LOADING,
      payload: true,
    });
      axiosPostWithToken(`${API.GET_EXPERIENCES_REVIEW_LIST}`, body, token)
        .then((res) => {
          console.log(res);
          if (res.data.status_code === 200) {
            if (status == "approved") {
              dispatch({
                type: EXPERIENCES_REVIEW_PUBLISHED_LIST,
                payload: res.data.data,
              });
            }else if(status=="rejected"){
              dispatch({
                type: EXPERIENCES_REJECTED_REVIEW_LIST,
                payload: res.data.data,
              });
            }
             else {
              dispatch({
                type: EXPERIENCES_PENDING_REVIEW_LIST,
                payload: res.data.data,
              });
            }
            dispatch({
              type: IS_EXP_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };
export const ExperienceReviewStatusUpdate = (
  status,
  main_pack_id,
  review_id,
  ref_id,
  refreshData
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      status: status,
      main_pack_id: main_pack_id,
      review_id: review_id,
      ref_id: ref_id,
    };
    axiosPut(`${API.EXPERIENCES_REVIEW_STATUS_UPDATE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const myExperienceReviewStartDate = (date) => {
    
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCES_REVIEW_START_DATE,
        payload: date,
      });
    };
  };
  
  export const myExperienceReviewEndDate = (date) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCES_REVIEW_END_DATE,
        payload: date,
      });
    };
  };
export const reviewPageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCES_REVIEW_PAGE_ID,
      payload: page,
    });
  };
};
