import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { AiFillCaretDown } from 'react-icons/ai';
import ClearIcon from "@material-ui/icons/Clear";
import '../assets/css/partials/gift/myFamilyFirst/dateFilter.scss'

const useStyles = makeStyles((theme) => ({
  resize: {
    fontSize: 11,
  },
}));

const Datefilter = ({ status, ffStartDate, ffEndDate, handleStartDateChange,  handleEndDateChange, handleStartClear, handleEndClear }) => {
  const classes = useStyles();
  // console.log(status, ffStartDate, ffEndDate, handleStartDateChange,  handleEndDateChange, handleStartClear, handleEndClear);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="date-filter">
        <p className="date-filter-from"> Date filter from</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 100,
            fontSize: 10,
          }}
          autoOk={true}
          InputProps={
            ffStartDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleStartClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          size="small"
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!ffStartDate && "Select"}
          value={ffStartDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <p className="date-filter-to"> to</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 90,
        }}
        autoOk={true}
          InputProps={
            ffEndDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleEndClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!ffEndDate && "Select"}
          value={ffEndDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Datefilter;