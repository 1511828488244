import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ExperienceReviewStatusUpdate,
  getExperienceReviewPendingList,
  getExperienceReviewPublishedList,
  getExperienceReviewRejectedList,
  getExperienceReviewsearch,
  reviewPageId,
} from "../../../actions/experienceReviewActions";

import CustomerReviews from "../../../common/holidayReviews";

import Pagination from "../../../common/pagination";

const Published = () => {
  const dispatch = useDispatch();
  const totalExperienceReviews = useSelector(
    (state) => state?.experienceReviews?.experiencePublishedReviewList
  );
  const PublishedReviewList =
    totalExperienceReviews && totalExperienceReviews?.review_data?.results;
  const totalPage = useSelector(
    (state) =>
      state.experienceReviews.experiencePublishedReviewList.review_data
        ?.total_pages
  );
  console.log(PublishedReviewList?.[0]);
  const pageNum = useSelector((state) => state.experienceReviews.page);
  const ffEndDate = useSelector(
    (state) => state.experienceReviews.experienceReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.experienceReviews.experienceReviewStartDate
  );
  useEffect(() => {
    dispatch(reviewPageId(1));
  }, []);

  const handlePagination = (e, value) => {
    e.preventDefault();
    if (ffEndDate === null && ffStartDate === null) {
      dispatch(getExperienceReviewPublishedList(value));
      dispatch(reviewPageId(value));
    } else {
      dispatch(
        getExperienceReviewsearch(value, "approved", ffStartDate, ffEndDate)
      );
      dispatch(reviewPageId(value));
    }
  };
  const refreshData = () => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(
        getExperienceReviewsearch(pageNum, "approved", ffStartDate, ffEndDate)
      );
    } else {
      dispatch(getExperienceReviewRejectedList(pageNum));
      dispatch(getExperienceReviewPendingList(pageNum));
      dispatch(getExperienceReviewPublishedList(pageNum));
    }
  };
  const publishReview = (e, review_id, main_pack_id, ref_id) => {
    dispatch(
      ExperienceReviewStatusUpdate(
        "deleted",
        main_pack_id,
        review_id,
        ref_id,
        refreshData
      )
    );
  };
  return (
    <>
     {PublishedReviewList && PublishedReviewList?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {PublishedReviewList &&
        PublishedReviewList.map((data, i) => (
          <CustomerReviews
            key={i}
            title={data?.holiday_pack?.pack_title}
            address={`${(data?.holiday_pack.location_city,
            data?.holiday_pack?.location_country)}`}
            status={"Published"}
            price={data?.holiday_pack?.revised_price ? data?.holiday_pack?.revised_price : data?.holiday_pack?.price}
            comment={data?.review_comment}
            review_id={data?.id}
            main_pack_id={data?.main_pack_id}
            publishReview={publishReview}
            ref_id={data?.ref_id}
            review_star={data?.review_star}
          />
        ))}
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
    </>
  );
};
export default Published;
