import React,{useEffect, useState} from 'react'
import AddCard from './addCard'
import EditCard from './editCard'
import Pagination from "@material-ui/lab/Pagination";
import { useSelector, useDispatch } from "react-redux";
import * as popular_destination_action from "../../actions/popularDestinationAction"; 


const PopulardestinationCad = ({addNew,setAddNew,loc_type}) => {

  const dispatch=useDispatch()
  const [page, setPage] = useState(1);
  const PopularTotalData = useSelector((state) => state.popularDestions.popularDestiantionsList)

  const PopularList = useSelector((state) => state.popularDestions.popularDestiantionsList.results );
  const Popular = useSelector((state) => state.popularDestions );



  const handlePagination = (event,pageNo) => {
    setPage(pageNo)
    dispatch(popular_destination_action.get_popular_destinations(pageNo,loc_type))
    
};
useEffect(()=>{
 dispatch(popular_destination_action.get_popular_destinations(1,loc_type))
},[loc_type])

  return (
    <>
     <div className='card-main-container'>
        {addNew?<AddCard setAddNew={setAddNew}/>:""}
        <EditCard PopularList={PopularList} locationType={loc_type} />
      

        {PopularTotalData?.total_results>0 && <Pagination
              count={PopularTotalData?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event,value) =>
                  handlePagination(event,value)
              }
              style={{marginTop:"10px"}}
        />}
   
    </div>
  
    </>
   
  )
}

export default PopulardestinationCad