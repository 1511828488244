import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import { ImCross } from 'react-icons/im';
import DialogContent from '@material-ui/core/DialogContent';
import '../../../assets/css/partials/gift/myFamilyFirst/myFamilyFirstMoreInfoModal.scss';
import moment from 'moment';


import {  GIFT_REQUESTED, PREFERRED_LOCATIONS, BOOK_FOR, MESSAGE, REQUESTED_BY, CONTACT_NUMBER, CONTACT_EMAIL, SCHEDULE_MY_FAMILY } from '../../../utils/constants';

const MyFamilyFirstMoreInfoModal = ({ open, close, modalData,section,isCancellationMessage}) => {
    const { locations_preferred, specific_request, contact_email, contact_number, contact_name, created,guests,cancelling_message } = modalData;
    console.log("modalData", modalData)
    return (
        <Dialog open={open} style={{width:"100%"}}>
         <DialogContent style={{width:"100%", backgroundColor:"#F5F5F5"}}>
            <span style={{position:"absolute", right:"55px", cursor:"pointer"}} onClick={close}>
                <ImCross/> 
            </span>
              <div>
                 <h5>{section?section:GIFT_REQUESTED}</h5>
                 <p style={{color:"#AFAFAF"}}>Requested on {moment(created).format("Do MMMM, YYYY")}</p>
             </div>
             <div className="row my-family-more-info">
             <div class="col-md-6">
               <div  className="my-family-first-label">
                   <label>{PREFERRED_LOCATIONS}</label>
                   <div className="my-family-first-content">
                       <p>{locations_preferred}</p>
                   </div>
               </div>
               <div  className="my-family-first-label">
                   <label>{SCHEDULE_MY_FAMILY}</label>
                   <div className="my-family-first-content">
                       <p>{`${modalData.schedule !== undefined && modalData.schedule !== null? `${modalData.schedule.from} to ${modalData.schedule.to}`: ""} `}</p>
                   </div>
               </div>
               <div>
               <div  className="my-family-first-label">
                   <label>{BOOK_FOR}</label>
                   <div className="my-family-first-content">
                       <p>{guests}</p>
                   </div>
               </div>
               </div>
               <div  className="my-family-first-label">
               {isCancellationMessage? <div >
                   <label>Cancellation Message</label>
                   <div className="my-family-first-content">
                       <p className="common-more-info-msg message-limit" title={cancelling_message}>{cancelling_message?cancelling_message:"No message recieved"}</p>
                   </div>
               </div>:""}
               </div>
             </div>
             <div class="col-md-6">
                 <div className="my-family-first-content">
                 <div className="my-family-first-label">
                   <div>
                     <label>{REQUESTED_BY}</label>
                       <p>{contact_name}</p>
                   </div>
               </div>
               </div>
               <div className="my-family-first-content">
                   <div className="my-family-first-label">
                   <div>
                    <label>{CONTACT_NUMBER} </label>
                       <p> {contact_number}</p>
                   </div>
                   </div>
                </div>
                <div className="my-family-first-content">
                 <div className="my-family-first-label">
                   <div>
                  <label>{CONTACT_EMAIL} </label>
                       <p>{contact_email}</p>
                   </div>
                 </div>
                </div>
            </div>   
            </div>
         </DialogContent>
        </Dialog> 
    );
};

export default MyFamilyFirstMoreInfoModal;
