
import React  from 'react';

import { ImCross } from 'react-icons/im';
import '../assets/css/partials/moreInfoModal.scss';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


import { BookedWithPointAndRs, dateFormat, checkNumberOfGuest } from '../utils/index';

const MoreInfoModal = ({ showMyModal, showInfoModalData, modalClose, className,isCancellationMessage }) => {

    const { contact_email, adults, children, contact_name, pack_image, amount, cancelling_message,special_request, contact_number, total_points, created, infants,schedule } = showInfoModalData;
    const { from, to } = showInfoModalData.schedule;

    const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        min-height: 400px;
        min-width: 710px;
        background-color: #F5F5F5;
    }
    & > .MuiDialog-container > .MuiPaper-root  > .MuiDialogContent-dividers {
        padding: 60px 24px !important;
    }
    & > .MuiDialog-container > .MuiPaper-root  > .MuiDialogContent-root > .MuiDialogActions-root {
        display: block !important;
    }
  `;

    return (
        <StyledDialog open={showMyModal} className={className}
        PaperProps={{
            style: {
                boxShadow:"none"
            },
       }}
        >
            <DialogContent style={{ backgroundColor: "#F5F5F5",padding:"40px" }}>
                <DialogActions>
                    <div className=" more-info-card ">
                        <h5 style={{ textAlign: "left" }}>{`${showInfoModalData.pack_title} Booking`}
                            <span
                                onClick={modalClose}
                                style={{
                                    position: "absolute",
                                    right: "47px",
                                    fontSize: "15px"
                                }}>
                                <ImCross />
                            </span>
                        </h5>
                        <p style={{ color: "#8F8F8F" }}>Booked On {moment(created).format("Do MMM, YYYY")}</p>
                        <div className="row more-info">
                            <div className="col-md-6">
                                <div className="info-image" >
                                    <img src={pack_image} width="300" height="200" style={{borderRadius:"33px"}} alt="packImage"/>
                                </div>
                                
                                    <div style={{marginTop:"13px"}}>
                                        <label>Booked For</label>
                                        <div className="info-book-for">
                                            <span>{Array.isArray(schedule) ? schedule.map((scheduleDate, index) => {
                                        if (schedule[index] === schedule[schedule.length - 1]) {
                                        return scheduleDate
                                        } else {
                                        return `${scheduleDate}, `
                                        }
                                    }) : (typeof schedule === 'object' && schedule !== null ? `${schedule.from}  ${ schedule.to ? `to ${schedule.to}`: ""}` : (schedule ===  null ? "N/A" : schedule))}</span>
                                            <p>
                                                {checkNumberOfGuest(adults, children, infants)}
                                            </p>
                                        </div>
                                    </div>
                                    
                                
                                {from === null && special_request === ''?
                                    null 
                                    : from !== null && special_request === '' ?
                                    <div>
                                        <label>Message</label>
                                        <div className="info-book-for">
                                            <p className="info-msg">No Messgae to display</p>                                            
                                        </div>
                                    </div> : 
                                     <div style={{padding:"7px"}}>
                                     {isCancellationMessage? <div className="info-book-for">
                                         <p>Cancellation Message</p>
                                         <p >{cancelling_message?cancelling_message:"No message recieved"}</p>  
                                     </div>:""}
                                 </div>
                                    // <div>
                                    //     <label>Cancellation Message</label>
                                    //     <div className="info-book-for">
                                    //         <p >{cancelling_message?cancelling_message:"No message recieved"}</p>                                            
                                    //     </div>
                                    // </div> 
                                }
                            </div>
                            <div class="col-md-6">
                                <div style={{padding:"7px"}}>
                                    <div className="info-book-for">
                                        <p>Payment</p>
                                        <p>Booked with {BookedWithPointAndRs(total_points, amount)}</p>
                                    </div>
                                </div>
                                <div style={{padding:"7px"}}>
                                    <div className="info-book-for">
                                        <p>Booked By</p>
                                        <p>{contact_name}</p>
                                    </div>
                                </div>
                                <div style={{padding:"7px"}}>
                                    <div className="info-book-for">
                                        <p>Contact Number</p>
                                        <p>{contact_number}</p>
                                    </div>
                                </div>
                                <div style={{padding:"7px"}}>
                                    <div className="info-book-for">
                                        <p>Contact Email</p>
                                        <p>{contact_email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </DialogContent>
        </StyledDialog>
    );
};

export default MoreInfoModal;