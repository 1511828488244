import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from '../../../assets/images/rightArrow.svg'
import { searchOrganisation } from '../../../actions/accountsAction'
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import * as AccountAction from "../../../actions/accountsAction";
import { Spinner } from "react-bootstrap";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  rootBorder: {
    position: 'relative',
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "2px solid #000000",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  accounts: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  location: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 12,
    color: "#797979",
  },
  rightArrow: {
    position: 'absolute',
    right: '16px',
    top: '22px'
  }
}));

export default function AccountListing({ accountDetails, handleEdit, handleAdd, page, onResetpage }) {
  const classes = useStyles();
  const [add, setAdd] = useState(false);
  const [addFlowLogo, setAddFlowLogo] = useState();
  const [show, setShow] = useState(false)
  const [searchBool, setsearchBool] = useState(false);
  const [chosen, setChosen] = useState(accountDetails?.results[0]?.id)
  const[serchVal,setSearchVAl]=useState("")
  const dispatch = useDispatch();

  // const searchInputRef = useRef(null)

  const accountSearchDetails = useSelector((state) => state.accountListing.searchOrganisationData.data);
  const orgSearchTerm = useSelector((state) => state.accountListing?.org_search_term);
  const isDataLoading = useSelector(
    (state) => state.corpListing.isListingDataLoading
  );
  useEffect(() => {
    setChosen(accountDetails?.results[0]?.id)
  }, [accountDetails?.results[0]?.id]);

  useEffect(() => {
    setAdd(false)
  }, [accountDetails]);

  const setEditDetails = (id) => {
    handleEdit(id);
    setShow(false)
    setAdd(false)
    setChosen(id)
  };

  

  const setNewDetails = (id) => {
    // dispatch(AccountAction.getAccountListing(id))

    setAdd(true);
    handleAdd();
    setShow(true)
    setAddFlowLogo(id)

  };
  //   useEffect(() => {
  //     setsearchBool(false)
  //     searchInputRef.current.value = ''
  // }, [])

  const organisationSearch = (event) => {
    // setsearchBool(true);
    let search = event.target.value;
    setSearchVAl(search)
    dispatch(searchOrganisation(search))
    // dispatch(AccountAction.setOrgSearchTerm(search))
    // if (search === '') {
    //   dispatch(AccountAction.accountsList(handleLoader, page))
    
    // }
    // onResetpage()

    // if (event.charCode === 13) {
    //   dispatch(searchOrganisation(search))
    // }
  }
  const handleLoader = () => {
    
  };

  return (
    <div className="w-100">
      <Row className={classes.row}>
        <Col className="w-100">
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={serchVal}
            // onKeyPress={organisationSearch}
            onChange={organisationSearch}
          // ref = {searchInputRef}
          />
        </Col>
        <Col>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div className='overflow--scroll' style={{ paddingRight: '20px', marginRight: '-20px' }} >
        {add && (
          <Card className={addFlowLogo ? classes.rootBorder : classes.root} onClick={setEditDetails}>
            <h6 className={classes.title}>Adding a new Organisation</h6>
            <p className={classes.location}>Address goes here</p>
            <p className={classes.location}>No accounts yet</p>
            {addFlowLogo ? <img className={classes.rightArrow} src={rightArrow} /> : null}
          </Card>
        )}
        {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}>
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {searchBool ?
          accountSearchDetails &&
          accountSearchDetails.length > 0 &&
          accountSearchDetails.map((item) => (
            <Card key={item.id} className={item.id === chosen ? classes.rootBorder : classes.root} onClick={() => setEditDetails(item.id)}>
              <h6 className={classes.title}>{item.name}</h6>
              <h6 className={classes.subTitle}>{item.location}</h6>
              <h6 className={classes.accounts}>{item.total_accounts} Accounts</h6>
              {item.id === chosen && (!show) ? <img className={classes.rightArrow} src={rightArrow} /> : null}
            </Card>
          ))

          :
          accountDetails?.results &&
          accountDetails?.results.length > 0 &&
          accountDetails?.results.map((item) => (
            <Card key={item.id} className={item.id === chosen && (!show) ? classes.rootBorder : classes.root} onClick={() => setEditDetails(item.id)}>
              <h6 className={classes.title}>{item.name}</h6>
              <h6 className={classes.subTitle}>{item.location}</h6>
              <h6 className={classes.accounts}>{item.total_accounts} Accounts</h6>
              {item.id === chosen && (!show) ? <img className={classes.rightArrow} src={rightArrow} /> : null}
            </Card>
          ))
        }
        {accountDetails?.results?.length == 0 ?
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card> 
          : ''}
      </div>
    </div>
  );
}
