import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux'
import { cancelEventInputVal } from '../../../actions/eventsAction';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        height: '55%',
        width: '60%',
        backgroundColor: '#F5F5F5'
    },

}));

export default function CancelEventPopup({
    openPopup,
    closeModal,
    updatePopupData,
    imageInPopup,
    popupTitle,
    eventsCanceledHandler,
    isCancelMessage,
    handleCloseDone
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [days, setDays] = useState();
    const [nights, setNights] = useState();
    const [inputVal, setInputVal] = useState('');

    const dispatch = useDispatch();
    const handleClickOpen = () => {
        setOpen(closeModal);
    };

    const handleClose = () => {
        setOpen(closeModal);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleDaysCount = (event) => {
        const days = event.target.value
        setDays(days)
    }

    const handleNightsCount = (event) => {
        const nights = event.target.value
        setNights(nights)
    };

    const onHandleChange = (event) => {
        let value = event.target.value;
        setInputVal(value);
        dispatch(cancelEventInputVal(value));
    };

    return (
        <React.Fragment>

            <Dialog
                PaperProps={{
                    style: {
                        height:"auto"
                    },
               }}
                maxWidth={maxWidth}
                open={openPopup}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle id="max-width-dialog-title" style={{ marginLeft: '25px' }}>Are you sure of cancelling this event?</DialogTitle>
                    <IconButton
                aria-label="close"
                style={{
                position:"absolute",
                right:"3%"
                }}
                onClick={handleCloseDone}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogTitle>{popupTitle}</DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                style={{ flex: 0.5, position: "relative" }}
                                className="form-group form-custom"
                            >
                                <div
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        width: 320,
                                        height: 200,
                                    }}
                                >
                                    <img
                                        src={imageInPopup}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: 14,
                                            backgroundColor: "#CECECE",
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{ flex: 0.5, position: "relative" }}
                                className="form-group form-custom"
                            >
                                <label for="">Please write a message for all the registered users explaining the reason of cancellation</label>
                                <textarea
                                    style={{ height: '65%' }}
                                    placeholder="Please mention the reason for cancellation of this event"
                                    value={inputVal}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => onHandleChange(e)}
                                />
                                {isCancelMessage && <p style={{color:"red"}}>Please write reason for cancelling this event</p>}
                            </div>
                        </div>
                    </DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="black" style={{backgroundColor:"#EDEDED", textTransform:"none"}}>
                        Don't Cancel
                    </Button>
                    <Button onClick={ eventsCanceledHandler} color="primary" style={{backgroundColor:"black", color:"white", textTransform:"none"}}>
                        Cancel this event
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
