import React, {useState,useEffect} from 'react'
import AddNewSection from '../../../../common/addNewSection';
import CommonAddNew from '../../../../common/commonAddNew';
import DefaultImage from "../../../../assets/images/plain-background.jpeg";
import { DONE, CANCEL } from '../../../../utils/constants'
import DestinationList from './destinationList';
import * as HoneymoonAction from '../../../../actions/honeymoonAction'
import {holidayImageUpload} from '../../../../actions/holidaySectionAction'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { Spinner } from 'react-bootstrap';

const Index = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch()
    const[imageTitle,setImageTitle]=useState("")
    const[altText,setAltText]=useState("")
    const [state, setState] = useState({
      location: "",
      image: "",
      priority: "",
       title_text:"",
      alt_text:""
    });
    const isDataLoading = useSelector(
      (state) => state.holidayListing.isDataLoading
    );

    const [error, setError] = useState({
      location: false,
      image: false,
      priority: false,
      priorityNegative: false
    })
    

    const locationNameOnchangeHandler = (event) => {
      setState({
        ...state,
        location: event.target.value
      })
      setError({...error, location: false})
    }
    const priorityOnchangeHandler = (event) => {
      setState({
        ...state,
        priority: event.target.value
      })
      setError({...error, priority: false, priorityNegative: false})
    }
    const handleImageChange = (croppedFile) => {
      if (croppedFile) {
        dispatch(HoneymoonAction.honeymoonImageUpload(croppedFile, updatePic));
        }
    }
    const updatePic = (data) => {
      setState({
        ...state,
        image: data.url
      });
      setError({...error, image: false})
    };

    const handleSubmit = (e) => {
      e.preventDefault()
      if(state.image == ""){
        setError({...error, image: true})
      }else if(state.location == ""){
        setError({...error, location: true})
      }else if(state.priority == ""){
        setError({...error, priority: true})
      }else if(state.priority <= -1){
        setError({...error, priorityNegative: true})
      }else{
       dispatch(HoneymoonAction.addHoneymoonDestination(state, handleSuccess))
      }
    }

    const handleSuccess = () => {
      dispatch(HoneymoonAction.getHoneymoonDestinationList());
      enqueueSnackbar("Added successfully", {
        variant: "success",
      });
      setShowModal(false)
      setState({
        location: "",
        image: "",
        priority: "",
        alt_text:"",
        title_text:""
      })
    };
    const handleOpenModal = () => {
      setShowModal(true)
      setState({
        location: "",
        image: "",
        priority: "",
      })
    }

    useEffect(() => {
      setState(prevState => ({
        ...prevState,
        title_text: imageTitle,
        alt_text: altText
      }));
    }, [imageTitle, altText]);
    
  return (
    <>
        <h2>Honeymoon Destinations</h2>
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop:"30px"}}>
          <DestinationList/>
          <CommonAddNew 
            onClick={() => handleOpenModal()}
            textTitle="Add New "
          />
        </div>
        {isDataLoading &&
          <div className="blured-backround">
            <p style={{ paddingTop: "40%", marginLeft: "10%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {showModal && 
         <AddNewSection 
            showMyModal={showModal}
            openHandler={showModal}
            togglebackDrop={showModal}
            heading="Add new Destination"
            image={ state.image ?  state.image : DefaultImage}
            imageText="Image"
            labelTextOne="Location name"
            labelTextTwo="Priority"
            isDestination={true}
            btnTextOne={CANCEL}
            btnTextTwo={DONE}
            closeHandle={() => setShowModal(false)}
            onChangeOne={locationNameOnchangeHandler}
            onChangeTwo={priorityOnchangeHandler}
            handleSubmit={handleSubmit}
            handleFilePickChange={handleImageChange}
            isImage={error.image}
            isSectionNameError={error.location}
            isPriorityEmptyError={error.priority}
            isPriorityError={error.priorityNegative}
            imageTitle={imageTitle}
            setImageTitle={setImageTitle}
            altText={altText}
            setAltText={setAltText}
         />
         }
    </>
  )
}

export default Index