import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from "antd";
import moment from 'moment';
import Datefilter from "../../../common/commonDateFIlter";
import Request from './requests'
import FollowUp from './followUp';
import DropdownFilter from './dropDownFilter';
import Confirmed from './confirmed';
import Closed from './closed';
import { 
  getHolidayRequestList, 
  followUpHolidayList, 
  confirmedHolidayList,
  closedHolidayList , 
  holidayRequestsSearch,
  myHolidayRequestStartDate,
  myHolidayRequestEndDate,
  holidayReqSearchValue,
  getHolidaysRequestStatus,
  pageId
} from '../../../actions/holidayRequestAction';
import { Spinner } from 'react-bootstrap';

const { TabPane } = Tabs;

const HolidaysRequestsIndex = () => {
  const [ status, setStatus ] = useState("request");
  const totalHolidayRequest = useSelector(state => state.holidayRequest.holidayRequestList.total_results);
  const totalHolidayFollowUp = useSelector(state => state.holidayRequest.holidayFollowUpList.total_results);
  const totalHolidayConfirmed=useSelector(state => state.holidayRequest.confirmedHolidayList.total_results);

  
  const totalHolidayClosed = useSelector(state => state.holidayRequest.closeHolidayList.total_results);
 
  const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
  const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
  const pageNum = useSelector(state => state.holidayRequest.page);
  const sectionName = useSelector(state => state.holidayRequest.holidayRequestSecName);
  const dispatch = useDispatch();
  const overallHolidayRequest = totalHolidayRequest + totalHolidayFollowUp + totalHolidayClosed;
  const getHolidayReqSearchValue = useSelector(state => state.holidayRequest.holidayReqSearchValue);
  const followupSearchVal = useSelector(state => state.holidayRequest.holidayFollowupSearchValue);
  const closedSearchVal = useSelector(state => state.holidayRequest.holidayClosedSearchValue);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  useEffect(() => {
    if(sectionName?.name === undefined){
      dispatch(getHolidayRequestList(1));
      dispatch(followUpHolidayList(1));
      dispatch(confirmedHolidayList(1))
      dispatch(closedHolidayList(1));
     
      dispatch(pageId(1));
    };
  }, [sectionName]);
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  useEffect(() => {
     if(status === "request") {
      dispatch(pageId(1));
      if(ffStartDate !== null || ffEndDate !== null)
      dispatch(holidayRequestsSearch(pageNum,"request",getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
    }
    else if(status === "followup") {
      dispatch(pageId(1));
      if(ffStartDate !== null || ffEndDate !== null)
      dispatch(holidayRequestsSearch(pageNum, "followup",getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      
    }
    else if(status === "confirmed") {
      dispatch(pageId(1));
      if(ffStartDate !== null || ffEndDate !== null)
      dispatch(holidayRequestsSearch(pageNum, "confirmed",getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      
    }
    else if(status === "closed") {
      dispatch(pageId(1));
      if(ffStartDate !== null || ffEndDate !== null)
      dispatch(holidayRequestsSearch(pageNum, "closed",getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
    }
  }, [status]);;

  useEffect(() => {
    return () => {
      dispatch(myHolidayRequestStartDate(null));
      dispatch(myHolidayRequestEndDate(null))
    }
  }, []);

  const searchValue = () => {
    if(status === "request")return getHolidayReqSearchValue
    else if(status === "followup")return followupSearchVal
    else if(status === "closed")return closedSearchVal
  }

  const handleTabChange = (key) => {
    
    if(key === '2') {
      setStatus('followup');
      dispatch(getHolidaysRequestStatus('followup'))
      if(Object.keys(sectionName).length === 0) {
        return; 
      }
      else {
        dispatch(holidayRequestsSearch(pageNum, "followup", followupSearchVal,ffStartDate, ffEndDate,sectionName?.name ));
      }
    }
    if(key === '4') {
      setStatus('confirmed');
      dispatch(getHolidaysRequestStatus('confirmed'))
      if(Object.keys(sectionName).length === 0) {
        return; 
      }
      else {
        dispatch(holidayRequestsSearch(pageNum, "confirmed", followupSearchVal,ffStartDate, ffEndDate,sectionName?.name ));
      }
    }
    else if(key === '3') {
      setStatus('closed');
      dispatch(getHolidaysRequestStatus('closed'))
      if(Object.keys(sectionName).length === 0) {
        return; 
      }
      else {
        dispatch(holidayRequestsSearch(pageNum, "closed", closedSearchVal,ffStartDate, ffEndDate, sectionName?.name ));
      }
    }
    else {
      setStatus('request');
      dispatch(getHolidaysRequestStatus('request'))
      if(Object.keys(sectionName).length === 0) {
        return; 
      }
      else {
        dispatch(holidayRequestsSearch(pageNum, "request", getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName?.name ));
      }
    }
  };

  const handleStartDateChange = (date) => {
    dispatch(myHolidayRequestStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(holidayRequestsSearch(pageNum,status,searchValue(), moment(date).format("YYYY-MM-DD"), ffEndDate, sectionName?.name));
  };

  const handleEndDateChange = (date) => {
    dispatch(myHolidayRequestEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(holidayRequestsSearch(pageNum, status, searchValue(), ffStartDate, moment(date).format("YYYY-MM-DD"),sectionName?.name));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayRequestStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayRequestEndDate(null));
    dispatch(getHolidayRequestList());
    dispatch(followUpHolidayList());
    dispatch(confirmedHolidayList());
    dispatch(closedHolidayList());
  };

  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter 
          status={status}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          ffStartDate={ffStartDate}
          ffEndDate={ffEndDate}
          handleStartClear={handleStartClear}
          handleEndClear={handleEndClear}
        />
      </div>
      <div className="enquiry-tabs-holder">
        <div style={{display:'flex'}}>
          <p className="title-holder">{overallHolidayRequest} Holidays Requests</p>
          <div>
            <DropdownFilter status={status}/>
          </div>
        </div>
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <TabPane tab = {`${totalHolidayRequest} Requests`} key="1">
            <Request/> 
          </TabPane>
          <TabPane  tab = {`${totalHolidayFollowUp} Follow-Up`} key="2">
            <FollowUp/> 
          </TabPane>
          <TabPane tab = {`${totalHolidayConfirmed} Confirmed`} key="4">
            <Confirmed />
          </TabPane>
          <TabPane tab = {`${totalHolidayClosed} Cancelled`} key="3">
            <Closed/>
          </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
        <div>
      </div>
      </div>
    </> 
  );
};

export default HolidaysRequestsIndex;
