import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import clsx from 'clsx';
import { NavLink, Route, useHistory, useLocation } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { MENU_LIST } from './MenuItem';
import '../assets/css/partials/layout.scss';
import Accounts from '../components/corporates/accounts/index'
import Bookings from '../components/corporates/bookings'
import Enquiries from '../components/corporates/enquiries/index'
import Sections from '../components/corporates/sections'
import Listings from '../components/corporates/listings/index'
import CorporatesReviewsIndex from '../components/corporates/customerReviews';
// import Events from '../components/events/index'

import { getCorpListing } from "../actions/corpListingAction";
import EventListings from '../components/events/listings/index';
import EventSections from '../components/events/sections/index';
import MyFamilyFirst from '../components/gifts/myFamilyFirst/index';
import MyFamilyStories from '../components/gifts/myFamilyFirst/stories'
import PartnerBookings from '../components/corporates/bookings/partnerBookings'
import EventBookings from '../components/events/bookings/index';
import Partners from '../components/corporates/partners/index';
// import { getCorpListing} from "../actions/corpListingAction";
import { getSectionImageData } from "../actions/sectionAction";
import GiftVoucher from '../components/gifts/giftVoucher/index';
import HolidaysBookingIndex from '../components/holidays/bookings';
import HolidaysRequestsIndex from '../components/holidays/requests/index';
import HolidaysSectionIndex  from '../components/holidays/sections/index';
import PopularDestinations from '../components/popularDestinations';

import ExperienceSections  from '../components/experiences/sections/index'
// import HolidayListingIndex from '../components/holidays/listing';

// import HolidaysListings from '../components/holidays/listings/index';
// import ExperiencesListings from '../components/experiences/index'
// import ExperiencesBookings from '../components/experiences/bookings/index'
//import HolidayListingIndex from '../components/holidays/listing';

// import HolidaysListings from '../components/holidays/listings/index'
// import ExperiencesListings from '../components/experiences/listings/index'
import HolidaysListings from '../components/holidays/listings/index';
import ExperiencesListings from '../components/experiences/listings/index'
import ExperiencesBookings from '../components/experiences/bookings/index'
import SubscriptionNumbers from '../components/subscription/index'
import WeddingStories from '../components/wedding/stories/index'
import WeddingRequests from '../components/wedding/requests/index'
import WeddingDestinations from '../components/wedding/destinations/index'
import CsvIcon from "../../src/assets/images/csvIcon_black.svg";
import { API } from "../../src/constants/api";

import HolidayReviewsIndex from '../components/holidays/customerReviews/index';
import ExperiencesReviewsIndex from '../components/experiences/customerReviews';
import HoneymoonRequests from '../components/gifts/honeymoon/requests/index';
import HoneymoonStories from '../components/gifts/honeymoon/stories/index';
import AnniversaryRequests from '../components/gifts/anniversary/requests/index';
import AnniversaryStories from '../components/gifts/anniversary/stories/index';
import HoneymoonDestinations from '../components/gifts/honeymoon/destinations/index';
import AnniversaryDestinations from '../components/gifts/anniversary/destinations/index';
import CreatePayment from '../components/payment/index'
import PaymentBookings from '../components/payment/booking/index'
import WebStories from '../components/webStories';
import CallBack from '../components/callBack';

import { checkSession } from '../actions/authAction';
import { useSnackbar } from "notistack";

                                            



const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    active: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
    SideNav: {
        display: 'block',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: '24px 50px 24px 24px',
        background: ' #E5E5E5',
        height: '100% !important',
    },
}));

export default function SideNav() {
    const location=useLocation();
    const history=useHistory()
    const dispatch = useDispatch()
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [subMenuOpen, setSubmenuOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    useEffect(()=>{
        dispatch(checkSession(handleSessionExpired))
    //  if(location.pathname==='/'){
    //     history.push('/holidays/listings')
    //  }   
    },[location.pathname])
    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const handleClick = (key) => {
        setMenuOpen((prev) => ({
            [key]: !prev[key]
        }));
    };
    const handleSubMenu = (mainkey, subkey)=>{
        setSubmenuOpen((prev) => ({
            [`${mainkey}${subkey}`]: !prev[`${mainkey}${subkey}`]
        }));
    }

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    }
    const handleSessionExpired = () => {
        localStorage.clear();
        history.push('/')
        window.location.reload()
        enqueueSnackbar("Session Expired", {
            variant: "error",
        });
    }


    const dataHandler = (name) => {
        // console.log("name =>", name)
        // dispatch(getCorpListing(name))
        // dispatch()
        if (name === 'Sections') {
            // console.log("name =>", name)
            // dispatch(getSectionImageData());
        }
    }

    const downloadCSV = () => {
        const url = `${API.DOWNLOAD_USERS_CONTACT_LIST}`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'file';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })} >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}  >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}  >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {!open ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                    </IconButton>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle admin-dropdown ml-1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Admin Panel
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href onClick={handleLogout}>Logout</a>
                        </div>
                    </div>
                </div>
                <Divider />
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.SideNav} >
                    {
                        MENU_LIST.length > 0 && MENU_LIST.map((items, key) => (
                            <div key={key} onClick={() => setOpen(true)}>
                                <ListItem PaperProps={{
                                    style: {
                                        width: '430px',
                                        backgroundColor: 'red',
                                    },
                                }} button onClick={() => handleClick(key)}>
                                    <ListItemIcon style={{minWidth:"40px"}}>
                                        <img src={items.icon}/>
                                    </ListItemIcon>
                                    <ListItemText className="menu-list" primary={items.name} />
                                    {menuOpen[key] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse className="sub-menu-holder" in={menuOpen[key]} timeout="auto" unmountOnExit>
                                    {
                                        items?.children?.length > 0 && items?.children?.map((submenu, index) => (
                                           
                                            <>
                                         
                                            {!submenu?.children?.length?<NavLink to={`/${(items.name.toLocaleLowerCase()=="web stories"||items.name.toLocaleLowerCase()=="call back request"||items.name.toLocaleLowerCase()=="popular destination")?items.url.toLocaleLowerCase():items.name.toLocaleLowerCase()}${submenu.url}`} onClick={() => dataHandler(submenu.name)}>
                                                <List component="div" disablePadding>
                                                    <ListItem button className={classes.nested}>
                                                        <ListItemText className="sub-menuList" primary={submenu.name} />
                                                    </ListItem>
                                                </List>
                                            </NavLink>:<>
                                                {/* <ListItemText className="menu-list" primary={submenu.name} /> */}
                                                <ListItem PaperProps={{
                                                    style: {
                                                        width: '430px',
                                                        backgroundColor: 'red',
                                                    },
                                                }} button onClick={() => handleSubMenu(key, index)}>
                                                    <ListItemText className="menu-list" primary={submenu.name} />
                                                    {subMenuOpen[`${key}${index}`] ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                {/* {subMenuOpen[`${key}${index}`] ? <ExpandLess /> : <ExpandMore />} */}
                                            </>}
                                            <Collapse className="sub-menu-holder-2" in={subMenuOpen[`${key}${index}`]} timeout="auto" unmountOnExit>
                                                {
                                                    submenu?.children?.length > 0 && submenu.children.map((secsubmenu) => (
                                                        <NavLink to={`/${items.url.toLocaleLowerCase()}${secsubmenu.url}`} onClick={() => dataHandler(secsubmenu.name)}>
                                                            <List component="div" disablePadding>
                                                                <ListItem button className={classes.nested}>
                                                                    <ListItemText className="sub-menuList" primary={secsubmenu.name} />
                                                                </ListItem>
                                                            </List>
                                                        </NavLink>
                                                    ))
                                                }
                                            </Collapse>
                                            </>
                                        ))
                                    }
                                </Collapse>
                            </div>
                        ))
                    }
                </List>

                {/* <div className='d-flex justify-content-around align-items-center px-3 bottom-0 cursor-pointer position-absolute ' style={{bottom: "30px"}}  onClick={downloadCSV}>
                    <img src={CsvIcon} alt="" className='mr-3'/>
                    {open ? <p className='mb-0'>Export users contact details </p> : ""}
                </div> */}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {/* <p>dashboard comes here...</p> */}
                <Route path="/corporates/listings" component={Listings} />
                <Route path="/corporates/accounts" component={Accounts} />
                <Route path="/corporates/enquiries" component={Enquiries} />
                <Route path="/corporates/bookings" component={Bookings} />
                <Route path="/corporates/partnerBookings" component={PartnerBookings} />
                <Route path="/corporates/partners" component={Partners} />
                <Route path="/corporates/customerReviews" component={CorporatesReviewsIndex} />
                <Route path="/corporates/popularDestinations" component={PopularDestinations} />
                
                {/* PartnerBookings */}
                <Route path="/corporates/sections" component={Sections} />
                <Route path="/events/listings" component={EventListings} />
                <Route path="/events/sections" component={EventSections} />
                <Route path="/events/bookings" component={EventBookings} />
                <Route path="/myfamiyfirst/gifting/requests" component={MyFamilyFirst} />
                <Route path="/myfamiyfirst/gifting/stories" component={MyFamilyStories} />
                <Route path="/gifts/giftVoucher" component={GiftVoucher} />

                {/* holidays */}

                <Route path="/holidays/bookings" component={HolidaysBookingIndex} />
                <Route path="/holidays/requests" component={HolidaysRequestsIndex} />
                <Route path="/holidays/sections" component={HolidaysSectionIndex} /> 
                <Route path="/holidays/customerReviews" component={HolidayReviewsIndex} /> 
                <Route path="/experiences/sections" component={ExperienceSections} />
                <Route path="/holidays/listings" component={HolidaysListings} />
                <Route path="/holidays/popularDestinations" component={PopularDestinations} />
               
                {/*<Route path="/holidays/listings" component={HolidayListingIndex} />*/}
                
                <Route path="/experiences/listings" component={ExperiencesListings} />
                <Route path="/experiences/bookings" component={ExperiencesBookings} />
                <Route path="/experiences/popularDestinations" component={PopularDestinations} />
                <Route path="/experiences/customerReviews" component={ExperiencesReviewsIndex} />

                {/* Wedding */}
                <Route path="/weddings/destinations" component={WeddingDestinations} />
                <Route path="/weddings/stories" component={WeddingStories} />
                <Route path="/weddings/requests" component={WeddingRequests} />

                <Route path="/subscription/subscriptionNumbers" component={SubscriptionNumbers} />

                <Route path="/myfamiyfirst/honeymoon/requests" component={HoneymoonRequests} />
                <Route path="/myfamiyfirst/honeymoon/stories" component={HoneymoonStories} />
                <Route path="/myfamiyfirst/honeymoon/destinations" component={HoneymoonDestinations} />
                <Route path="/myfamiyfirst/anniversary/requests" component={AnniversaryRequests} />
                <Route path="/myfamiyfirst/anniversary/stories" component={AnniversaryStories} />
                <Route path="/myfamiyfirst/anniversary/destinations" component={AnniversaryDestinations} />
                <Route path="/payment/createPaymentLink" component={CreatePayment} />
                <Route path="/payment/bookings" component={PaymentBookings} />

                {/* {web stories} */}
                <Route path="/webstories/listing" component={WebStories} />

                <Route path="/callback/listing" component={CallBack} />

                {/* popular destination */}
                <Route path="/popular-destination/listing" component={PopularDestinations} />

            </main>
        </div>
    );
}
