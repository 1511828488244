export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// ********** Corporate module starts ********** //

// corporate listing module
export const GET_CORPORATE_LIST = "GET_CORPORATE_LIST";
export const GET_PACKAGE_INFO = "GET_PACKAGE_INFO"
export const GET_LOCATION_CITY_LIST = "GET_LOCATION_CITY_LIST"
export const GET_CORPORATE_FEATURED_LIST = "GET_CORPORATE_FEATURED_LIST"

// corporate enquiry module
export const ENQUIRYLIST = "ENQUIRYLIST";
export const ENQUIRY_FOLLOW_UP_LIST = "ENQUIRY_FOLLOW_UP_LIST";
export const ENQUIRY_RESOLVED_LIST = "ENQUIRY_RESOLVED_LIST";
export const UPDATE_ENQUIRY_STATUS = "UPDATE_ENQUIRY_STATUS";
export const CORP_ENQ_SEARCH_VALUE = "CORP_ENQ_SEARCH_VALUE";
export const CORP_FOLLOWUP_SEARCH_VALUE = "CORP_FOLLOWUP_SEARCH_VALUE";
export const CORP_RESOLVED_SEARCH_VALUE = "CORP_RESOLVED_SEARCH_VALUE";
export const ENQUIRY_STATUS = "ENQUIRY_STATUS";
export const PAGE_NUM = "PAGE_NUM";
export const FOLLOWUP_PAGE_NUM = "FOLLOWUP_PAGE_NUM";
export const RESOLVED_PAGE_NUM = "RESOLVED_PAGE_NUM";

// corporate booking module
export const BOOKINGLIST = "BOOKINGLIST";
export const PARTNERBOOKINGLIST = "PARTNERBOOKINGLIST";
export const PARTNER_CONFIRMED_BOOKINGLIST = "PARTNER_CONFIRMED_BOOKINGLIST";
export const PARTNER_FOLLOWUPLIST="PARTNER_FOLLOWUPLIST"
export const PARTNER_CANCELLED_BOOKINGLIST = "PARTNER_CANCELLED_BOOKINGLIST";
export const BOOKING_CONFIRMED_LIST = "BOOKING_CONFIRMED_LIST";
export const BOOKING_REFUND_LIST="BOOKING_REFUND_LIST";
export const BOOKING_CANCELLED_LIST = "BOOKING_CANCELLED_LIST";
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const CORP_CONFIRMED_SEARCH_VALUE = "CORP_CONFIRMED_SEARCH_VALUE";

export const CORP_CANCELLED_SEARCH_VALUE = "CORP_CANCELLED_SEARCH_VALUE";
export const GET_CORP_BOOKING_STATUS = "GET_CORP_BOOKING_STATUS";
export const BOOKED_SEARCH_TRUE_VALUE = "BOOKED_SEARCH_TRUE_VALUE";
export const CONFIRMED_SEARCH_TRUE_VALUE = "CONFIRMED_SEARCH_TRUE_VALUE";
export const CORP_REFUNDED_SEARCH_VALUE="CORP_REFUNDED_SEARCH_VALUE";
export const CANCELLED_SEARCH_TRUE_VALUE = "CANCELLED_SEARCH_TRUE_VALUE";
export const AVAILABILITY_REQUEST_LIST = "AVAILABILITY_REQUEST_LIST";
export const FOLLOWUP_LIST="FOLLOWUP_LIST"
export const CORP_AVAILABILITY_SEARCH_VALUE = "CORP_AVAILABILITY_SEARCH_VALUE";
export const AVAILABILITY_PAGE_NUM = "AVAILABILITY_PAGE_NUM";
export const IS_CORP_DATA_LOADING='IS_CORP_DATA_LOADING'
export const IS_CORP_LIST_DATA_LOADING='IS_CORP_LIST_DATA_LOADING'

// ********** Corporate module ends ********** //

//********corporate admin panel listing ********//
export const ADD_IMAGE = "ADD_IMAGE";
export const POST_CORPORATES = "POST_CORPORATES";
export const SEARCH_CORPORATE_LIST_DATA = "SEARCH_CORPORATE_LIST_DATA";
export const ALL_IMAGES_DATA = "ALL_IMAGES_DATA";
export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const UPDATE_CORPORATES = "UPDATE_CORPORATES";
export const GET_SELECT_DROPDOWN = "GET_SELECT_DROPDOWN";
export const MOVED_PACK_SEC_ID = "MOVED_PACK_SEC_ID";
export const POST_CORP_LISTING_DATA = "POST_CORP_LISTING_DATA";
export const IMAGE_DELETE = "IMAGE_DELETE";
export const PACKAGE_DELETE = "PACKAGE_DELETE";
export const CORPORATE_DELETE = "CORPORATE_DELETE";
export const CORP_LISTING_UPDATED_BOOL = "CORP_LISTING_UPDATED_BOOL";
export const CORP_LISTING_PAGE_ID = "CORP_LISTING_PAGE_ID";
export const CORP_DROPDOWN_FILTER_SEC_ID = "CORP_DROPDOWN_FILTER_SEC_ID";
export const SEARCH_TEXT_VALUE = "SEARCH_TEXT_VALUE";
export const CORP_LISTING_SEARCH_VALUE = "CORP_LISTING_SEARCH_VALUE";
export const DELETE_PACKAGE_ITEM_BOOL = "DELETE_PACKAGE_ITEM_BOOL";

// **************** corporate section **************//
export const ADD_CORP_SECTION_IMAGES = "ADD_CORP_SECTION_IMAGES";
export const GET_CORP_SECTION_IMAGES_DATA = "GET_CORP_SECTION_IMAGES_DATA";
export const IMAGE_UPDATE_SECTION = "IMAGE_UPDATE_SECTION";
export const ACCOUNTS_LIST = "ACCOUNTS_LIST";
export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST";
export const UPDATE_ACCOUNT = "UPDATE_CORPORATES";
export const UPDATE_POINTS = "UPDATE_POINTS";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const SEARCH_ORGANISATION = "SEARCH_ORGANISATION";
export const SEARCH_USER = "SEARCH_USER";
export const CROPPED_IMAGE_FILE = "CROPPED_IMAGE_FILE"
export const SELCTED_IMAGE_URL = "SELCTED_IMAGE_URL"
export const CONFIRM_UPDATE_BOOL = "CONFIRM_UPDATE_BOOL"
export const CONFIRM_ADD_BOOL = "CONFIRM_ADD_BOOL"
export const ERROR_ALERT = "ERROR_ALERT"
export const SECTION_PACKAGES = "SECTION_PACKAGES"
export const SET_EMPLOYEE_LIST = "SET_EMPLOYEE_LIST"
export const SET_SEARCH_BOOL = "SET_SEARCH_BOOL"
export const SEARCH_VALUE = "SEARCH_VALUE"
export const ORG_SEARCH_TERM = "ORG_SEARCH_TERM"
// export const SET_ADD_ACCOUNT = "SET_ADD_ACCOUNT"

//******************----corp Reviews-----------*********************** */
export const CORPORATES_PENDING_REVIEW_LIST= "CORPORATES_PENDING_REVIEW_LIST"
export const CORPORATES_REJECTED_REVIEW_LIST= "CORPORATES_REJECTED_REVIEW_LIST"
export const CORPORATES_REVIEW_PUBLISHED_LIST="CORPORATES_REVIEW_PUBLISHED_LIST"
export const CORPORATES_REVIEW_PAGE_ID="CORPORATES_REVIEW_PAGE_ID"
export const CORPORATES_REVIEW_START_DATE="CORPORATES_REVIEW_START_DATE"
export const CORPORATES_REVIEW_END_DATE="CORPORATES_REVIEW_END_DATE"

//gift my family first
export const MY_FAMILY_FIRST_REQUESTED = "MY_FAMILY_FIRST_REQUESTED"
export const MY_FAMILY_FIRST_PLANNING = "MY_FAMILY_FIRST_PLANNING"
export const MY_FAMILY_FIRST_CONFIRMED = "MY_FAMILY_FIRST_CONFIRMED"
export const MY_FAMILY_FIRST_CANCELLED = "MY_FAMILY_FIRST_CANCELLED"
export const FAMILY_FIRST_START_DATE = "FAMILY_FIRST_START_DATE"
export const FAMILY_FIRST_END_DATE = "FAMILY_FIRST_END_DATE"
export const MY_FAMILY_FIRST_UPDATE_STATUS = "MY_FAMILY_FIRST_UPDATE_STATUS"
export const REQUESTED_TOTAL_PAGE = "REQUESTED_TOTAL_PAGE"
export const PLANNING_TOTAL_PAGE = "PLANNING_TOTAL_PAGE"
export const CONFIRMED_TOTAL_PAGE = "CONFIRMED_TOTAL_PAGE"
export const CANCELLED_TOTAL_PAGE = "CANCELLED_TOTAL_PAGE"
export const REQUESTED_TOTAL_RESULTS = "REQUESTED_TOTAL_RESULTS"
export const PLANNING_TOTAL_RESULTS = "PLANNING_TOTAL_RESULTS"
export const CONFIRMED_TOTAL_RESULTS = "CONFIRMED_TOTAL_RESULTS"
export const CANCELLED_TOTAL_RESULTS = "CANCELLED_TOTAL_RESULTS"
export const FAMILY_FIRST_BOOKING_PAGE_ID = "FAMILY_FIRST_BOOKING_PAGE_ID"
export const FAMILY_FIRST_STORY_LIST = "FAMILY_FIRST_STORY_LIST"
export const FAMILY_FIRST_STORY_DETAILS = "FAMILY_FIRST_STORY_DETAILS"
export const FAMILY_FIRST_STORY_DELETE = "FAMILY_FIRST_STORY_DELETE"
export const FAMILY_FIRST_STORY_UPDATE = "FAMILY_FIRST_STORY_UPDATE"
export const FAMILY_FIRST_SEARCH_TERM = "FAMILY_FIRST_SEARCH_TERM"

//Event Section
export const GET_EVENT_SECTION = "GET_EVENT_SECTION"
export const EVENT_CONFIRM_ADD_BOOL = "EVENT_CONFIRM_ADD_BOOL"
export const EVENT_CONFIRM_UPDATE_BOOL = "EVENT_CONFIRM_UPDATE_BOOL"
export const EVENT_SELECTED_IMAGE_URL = "EVENT_SELECTED_IMAGE_URL"
export const EVENT_ADD_IMAGE = "EVENT_ADD_IMAGE"
export const ADD_EVENT_SECTION = "ADD_EVENT_SECTION"
export const EVENT_SECTION_PACKAGES = "EVENT_SECTION_PACKAGES"

//EVENTS LIST
export const EVENT_LIST = "EVENT_LIST";
export const GET_EVENT_INFO = "GET_EVENT_INFO";
export const EVENT_UPDATE = "EVENT_UPDATE";
export const EVENTS_IMAGE_UPLOAD = "EVENTS_IMAGE_UPLOAD"
export const ALL_IMAGES_EVENTS_DATA = "ALL_IMAGES_EVENTS_DATA"
export const EVENT_LIST_DROPDOWN= "EVENT_LIST_DROPDOWN"
export const CANCEL_EVENT_INPUT_MESSAGE= "CANCEL_EVENT_INPUT_MESSAGE"
export const SEARCH_EVENT_LISTING= "SEARCH_EVENT_LISTING"
export const PAGE_ID= "PAGE_ID"
export const EVENT_LISTINGS_UPDATE_BOOL= "EVENT_LISTINGS_UPDATE_BOOL"
export const EVENT_LISTINGS_ADD_BOOL= "EVENT_LISTINGS_ADD_BOOL"
export const EVENTS_LISTING_DROP_DOWN_VALUE = "EVENTS_LISTING_DROP_DOWN_VALUE"
export const EVENT_LISTING_SEARCH_VALUE = "EVENT_LISTING_SEARCH_VALUE"
export const SET_SELECTED_EVENTS_CHOSEN_ID = "SET_SELECTED_EVENTS_CHOSEN_ID"
export const EVENT_STATUS_VALUE = "EVENT_STATUS_VALUE"
export const EVENT_BOOKINGS_SECTION_ID = "EVENT_BOOKINGS_SECTION_ID"
export const CANCEL_EVENT = "CANCEL_EVENT"
export const EVENT_UPCOMING_SEARCH_VALUE = "EVENT_UPCOMING_SEARCH_VALUE"
export const EVENT_PAST_SEARCH_VALUE = "EVENT_PAST_SEARCH_VALUE"
export const EVENT_CANCELLED_SEARCH_VALUE = "EVENT_CANCELLED_SEARCH_VALUE"

//****************** ---SEND GIFT VOCHER----  *********************/

export const GIFT_VOUCHER_PURCHASED = "GIFT_VOUCHER_PURCHASED"
export const UPDATE_VOUCHER_STATUS = "UPDATE_VOUCHER_STATUS"
export const PLANNING_LIST = "PLANNING_LIST"
export const CONFIRMED_LIST = "CONFIRMED_LIST"
export const CANCELLED_LIST = "CANCELLED_LIST"

//****************** ---SEND GIFT VOCHER----  *********************
//ACCOUNTS
export const EMAIL_DOMAIN_ERROR_MSG = "EMAIL_DOMAIN_ERROR_MSG"

//Holiday
//*****************------- HolidaySection ----- *************** */
export const GET_HOLIDAY_SECTION = "GET_HOLIDAY_SECTION"
export const HOLIDAY_SELECTED_IMAGE_URL = "HOLIDAY_SELECTED_IMAGE_URL"
export const HOLIDAY_CONFIRM_ADD_BOOL = "HOLIDAY_CONFIRM_ADD_BOOL"
export const HOLIDAY_CONFIRM_UPDATE_BOOL = "HOLIDAY_CONFIRM_UPDATE_BOOL"
export const CLOSE_HOLIDAY_ADD_NEW_SECTION = "CLOSE_HOLIDAY_ADD_NEW_SECTION"
export const HOLIDAY_SECTION_PACKAGES = "HOLIDAY_SECTION_PACKAGES"

//HOLIDAY/LISTING
export const HOLIDAY_LIST= "HOLIDAY_LIST"
export const SET_HOLIDAY_DETAILS = "SET_HOLIDAY_DETAILS"
export const ALL_IMAGES_HOLIDAY_DATA = "ALL_IMAGES_HOLIDAY_DATA"
export const HOLIDAY_ADD_LIST = "HOLIDAY_ADD_LIST"
export const HOLIDAY_ADD_IMAGE = "HOLIDAY_ADD_IMAGE"
export const HOLIDAY_IMAGE_UPLOAD = "HOLIDAY_IMAGE_UPLOAD"
export const ALL_IMAGES_HOLIDAYS_DATA = "ALL_IMAGES_HOLIDAYS_DATA"
export const HOLIDAY_DELETE = "HOLIDAY_DELETE"
export const HOLIDAY_PACKAGE_DELETE = "HOLIDAY_PACKAGE_DELETE"
export const OPENING_SOON_DATE_VALUE = "OPENING_SOON_DATE_VALUE"
export const HOLIDAY_LISTING_DROP_DOWN_VALUE = "HOLIDAY_LISTING_DROP_DOWN_VALUE"
export const HOLIDAY_LISTING_UPDATE_BOOL = "HOLIDAY_LISTING_UPDATE_BOOL"
export const HOLIDAY_LISTING_PAGE_ID = "HOLIDAY_LISTING_PAGE_ID"
export const LOCATION_DROPDOWN = "LOCATION_DROPDOWN"
export const HPLIDAY_LISTING_EDIT_TRUE_VALUE = "HPLIDAY_LISTING_EDIT_TRUE_VALUE"
export const HOLIDAY_LISTING_SEARCH_VALUE = "HOLIDAY_LISTING_SEARCH_VALUE"
export const SET_SELECTED_CHOSEN_ID = "SET_SELECTED_CHOSEN_ID"
export const GET_HOLIDAYS_FEATURED_LIST = "GET_HOLIDAYS_FEATURED_LIST"
export const ISADD_ANOTHER_PACKAGE='ISADD_ANOTHER_PACKAGE'
export const IS_DATA_LOADING='IS_DATA_LOADING'
export const IS_LIST_DATA_LOADING='IS_LIST_DATA_LOADING'


// export const GET_HOLIDAY_LIST = "GET_HOLIDAY_LIST"
export const UPDATE_HOLIDAY_LIST = "UPDATE_HOLIDAY_LIST"
export const HOLIDAY_PACKAGE_INFO= "HOLIDAY_PACKAGE_INFO"
export const GET_HOLIDAY_PACKAGE_INFO = "GET_HOLIDAY_PACKAGE_INFO"
export const HOLIDAY_SELECT_DROPDOWN = "HOLIDAY_SELECT_DROPDOWN"

//******************----Requests-----------*********************** */
export const HOLIDAY_REQUEST_LIST= "HOLIDAY_REQUEST_LIST"
export const FOLLOW_UP_HOLIDAY_LIST= "FOLLOW_UP_HOLIDAY_LIST"
export const CONFIRMED_HOLIDAY_LIST="CONFIRMED_HOLIDAY_LIST"
export const CLOSE_HOLIDAY_LIST= "CLOSE_HOLIDAY_LIST"
export const HOLIDAY_REQUEST_SEARCH= "HOLIDAY_REQUEST_SEARCH"
export const HOLIDAY_REQUEST_START_DATE= "HOLIDAY_REQUEST_START_DATE"
export const HOLIDAY_REQUEST_END_DATE= "HOLIDAY_REQUEST_END_DATE"
export const HOLIDAY_REQUEST_PAGE_ID= "HOLIDAY_REQUEST_PAGE_ID"
export const HOLIDAY_REQUEST_STATUS_VALUE= "HOLIDAY_REQUEST_STATUS_VALUE"
export const HOLIDAY_REQUEST_SECTION_NAME= "HOLIDAY_REQUEST_SECTION_NAME"
export const CLOSE_REQUEST_INPUT_MESSAGE= "CLOSE_REQUEST_INPUT_MESSAGE"
export const CONFIRM_REQUEST_INPUT_AMOUNT="CONFIRM_REQUEST_INPUT_AMOUNT"
export const HOLIDAY_REQUEST_ALL_DATA= "HOLIDAY_REQUEST_ALL_DATA"
export const HOLIDAY_REQ_SEARCH_VALUE= "HOLIDAY_REQ_SEARCH_VALUE"
export const HOLIDAY_FOLLOWUP_SEARCH_VALUE= "HOLIDAY_FOLLOWUP_SEARCH_VALUE"
export const HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST="HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST"
export const HOLIDAY_CLOSED_SEARCH_VALUE= "HOLIDAY_CLOSED_SEARCH_VALUE"
//******************----Reviews-----------*********************** */
export const HOLIDAY_PENDING_REVIEW_LIST= "HOLIDAY_PENDING_REVIEW_LIST"
export const HOLIDAY_REJECTED_REVIEW_LIST= "HOLIDAY_REJECTED_REVIEW_LIST"
export const HOLIDAY_REVIEW_PUBLISHED_LIST="HOLIDAY_REVIEW_PUBLISHED_LIST"
export const HOLIDAY_REVIEW_PAGE_ID="HOLIDAY_REVIEW_PAGE_ID"
export const HOLIDAY_REVIEW_START_DATE="HOLIDAY_REVIEW_START_DATE"
export const HOLIDAY_REVIEW_END_DATE="HOLIDAY_REVIEW_END_DATE"
//****************-----Booking-----****************** */
export const GET_HOLIDAY_BOOKING_LIST = "GET_HOLIDAY_BOOKING_LIST"
export const HOLIDAY_BOOKING_PAGE_ID = "HOLIDAY_BOOKING_PAGE_ID"
export const HOLIDAY_BOOKING_START_DATE = "HOLIDAY_BOOKING_START_DATE"
export const HOLIDAY_BOOKING_END_DATE = "HOLIDAY_BOOKING_END_DATE"
export const HOLIDAY_BOOKING_SEARCH = "HOLIDAY_BOOKING_SEARCH"
export const GET_HOLIDAY_CONFIRMED_TOTAL = "GET_HOLIDAY_CONFIRMED_TOTAL"
export const GET_HOLIDAY_BOOKED_TOTAL = "GET_HOLIDAY_BOOKED_TOTAL"
export const GET_HOLIDAY_CANCELLED_TOTAL = "GET_HOLIDAY_CANCELLED_TOTAL"
export const HOLIDAY_STATUS_VALUE = "HOLIDAY_STATUS_VALUE"
export const BOOKINGS_SECTION_ID = "BOOKINGS_SECTION_ID"
export const GET_HOLIDAY_CONFIRMED_LIST = "GET_HOLIDAY_CONFIRMED_LIST"
export const GET_HOLIDAY_CANCELLED_LIST = "GET_HOLIDAY_CANCELLED_LIST"
export const HOLIDAY_BOOKING_NEXT_PAGE_ID = "HOLIDAY_BOOKING_NEXT_PAGE_ID"
export const CONFIRM_NEXT_PAGE_ID = "CONFIRM_NEXT_PAGE_ID"
export const CANCELLED_NEXT_PAGE_ID = "CANCELLED_NEXT_PAGE_ID"
export const CONFIRM_CURRENT_PAGE_ID = "CONFIRM_CURRENT_PAGE_ID"
export const BOOKING_CURRENT_PAGE_ID = "BOOKING_CURRENT_PAGE_ID"
export const CANCELLED_CURRENT_PAGE_ID = "CANCELLED_CURRENT_PAGE_ID"
export const HOLIDAY_REFUND_SEARCH_VALUE="HOLIDAY_REFUND_SEARCH_VALUE"
export const HOLIDAY_BOOKING_SEARCH_VALUE = "HOLIDAY_BOOKING_SEARCH_VALUE"
export const HOLIDAY_CONFIRMED_SEARCH_VALUE = "HOLIDAY_CONFIRMED_SEARCH_VALUE"
export const HOLIDAY_CANCELLED_SEARCH_VALUE = "HOLIDAY_CANCELLED_SEARCH_VALUE"
export const HOLIDAY_AVAILABILITY_REQUEST_LIST = "HOLIDAY_AVAILABILITY_REQUEST_LIST"
export const HOLIDAY_AVAILABILITY_SEARCH_VALUE = "HOLIDAY_AVAILABILITY_SEARCH_VALUE"
export const GET_HOLIDAY_FOLLOW_UP_TOTAL="GET_HOLIDAY_FOLLOW_UP_TOTAL"
export const GET_HOLIDAY_FOLLOW_UP_LIST="GET_HOLIDAY_FOLLOW_UP_LIST"
export const HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID="HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID"
export const FOLLOW_UP_CURRENT_PAGE_ID="FOLLOW_UP_CURRENT_PAGE_ID"
export const GET_HOLIDAY_REFUND_TOTAL="GET_HOLIDAY_REFUND_TOTAL"
export const GET_HOLIDAY_REFUND_LIST="GET_HOLIDAY_REFUND_LIST"
export const HOLIDAY_REFUND_NEXT_PAGE_ID="HOLIDAY_REFUND_NEXT_PAGE_ID"
export const REFUND_CURRENT_PAGE_ID="REFUND_CURRENT_PAGE_ID"





//Experiences
//*****************------- ExperiencesSection ----- *************** */
export const GET_EXPERIENCES_SECTION = "GET_EXPERIENCES_SECTION"
export const EXPERIENCES_CONFIRM_ADD_BOOL = "EXPERIENCES_CONFIRM_ADD_BOOL"
export const EXPERIENCES_CONFIRM_UPDATE_BOOL = "EXPERIENCES_CONFIRM_UPDATE_BOOL"
export const EXPERIENCES_SELECTED_IMAGE_URL = "EXPERIENCES_SELECTED_IMAGE_URL"
export const EXPERIENCES_ADD_IMAGE = "EXPERIENCES_ADD_IMAGE"
export const ADD_EXPERIENCES_SECTION = "ADD_EXPERIENCES_SECTION"
export const EXPERIENCE_SECTION_PACKAGES = "EXPERIENCE_SECTION_PACKAGES"

//Experiences Bookings

export const GET_EXPERIENCE_BOOKING_LIST = "GET_EXPERIENCE_BOOKING_LIST"
export const EXPERIENCE_BOOKING_PAGE_ID = "EXPERIENCE_BOOKING_PAGE_ID"
export const EXPERIENCE_BOOKING_START_DATE = "EXPERIENCE_BOOKING_START_DATE"
export const EXPERIENCE_BOOKING_END_DATE = "EXPERIENCE_BOOKING_END_DATE"
export const EXPERIENCE_BOOKING_SEARCH = "EXPERIENCE_BOOKING_SEARCH"
export const GET_EXPERIENCE_CONFIRMED_TOTAL = "GET_EXPERIENCE_CONFIRMED_TOTAL"
export const GET_EXPERIENCE_BOOKED_TOTAL = "GET_EXPERIENCE_BOOKED_TOTAL"
export const GET_EXPERIENCE_CANCELLED_TOTAL = "GET_EXPERIENCE_CANCELLED_TOTAL"
export const EXPERIENCE_STATUS_VALUE = "EXPERIENCE_STATUS_VALUE"
export const EXPERIENCE_BOOKINGS_SECTION_ID = "EXPERIENCE_BOOKINGS_SECTION_ID"
export const GET_EXPERIENCE_CONFIRMED_LIST = "GET_EXPERIENCE_CONFIRMED_LIST"
export const  GET_EXPERIENCE_REFUND_LIST= " GET_EXPERIENCE_REFUND_LIST"
export const  GET_EXPERIENCE_REFUND_TOTAL= " GET_EXPERIENCE_REFUND_LIST"
export const GET_EXPERIENCE_CANCELLED_LIST = "GET_EXPERIENCE_CANCELLED_LIST"
export const EXPERIENCE_BOOKING_NEXT_PAGE_ID = "EXPERIENCE_BOOKING_NEXT_PAGE_ID"
export const EXPERIENCE_CONFIRMED_NEXT_PAGE_ID = "EXPERIENCE_CONFIRMED_NEXT_PAGE_ID"
export const EXPERIENCE_CANCELLED_NEXT_PAGE_ID = "EXPERIENCE_CANCELLED_NEXT_PAGE_ID"
export const EXPERIENCE_BOOKING_CURRENT_PAGE_ID = "EXPERIENCE_BOOKING_CURRENT_PAGE_ID"
export const EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID = "EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID"
export const EXPERIENCE_CANCELLED_CURRENT_PAGE_ID = "EXPERIENCE_CANCELLED_CURRENT_PAGE_ID"
export const EXPERIENCE_BOOKED_SEARCH_VALUE = "EXPERIENCE_BOOKED_SEARCH_VALUE"
export const EXPERIENCE_REFUNDED_SEARCH_VALUE="EXPERIENCE_REFUND_SEARCH_VALUE"
export const EXPERIENCE_CONFIRMED_SEARCH_VALUE = "EXPERIENCE_CONFIRMED_SEARCH_VALUE"
export const EXPERIENCE_CANCELLED_SEARCH_VALUE = "EXPERIENCE_CANCELLED_SEARCH_VALUE"
export const EXPERIENCE_REQUESTED_SEARCH_VALUE = "EXPERIENCE_REQUESTED_SEARCH_VALUE"
export const GET_AVAILABILITY_LIST = "GET_AVAILABILITY_LIST"
export const EXPERIENCE_AVAILABILITY_SINGLE_DATA = "EXPERIENCE_AVAILABILITY_SINGLE_DATA"
export const GUEST_MODAL_OPEN_TRUE = "GUEST_MODAL_OPEN_TRUE"
export const DATE_RANGE_ITEM = "DATE_RANGE_ITEM"
export const ADULTS_GUEST = "ADULTS_GUEST"
export const CHILDRENS_GUEST = "CHILDRENS_GUEST"
export const INFANTS_GUEST = "INFANTS_GUEST"
export const RECURRING_DATE = "RECURRING_DATE"
export const RECURRING_MODAL_OPENINI_TRUE = "RECURRING_MODAL_OPENINI_TRUE"
export const SCHEDULE_MODAL_OPEN_TRUE = "SCHEDULE_MODAL_OPEN_TRUE"
export const PACKAGE_START_DATE = "PACKAGE_START_DATE"
export const PACKAGE_END_DATE = "PACKAGE_END_DATE"
export const SPECIFIC_DATE_VALUE = "SPECIFIC_DATE_VALUE"
export const FLEXI_DATE_ITEM = "FLEXI_DATE_ITEM"
export const GET_EXPERIENCE_FOLLOWUP_TOTAL="GET_EXPERIENCE_FOLLOWUP_TOTAL"
export const GET_EXPERIENCE_FOLLOWUP_LIST="GET_EXPERIENCE_FOLLOWUP_LIST"
export const EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID="EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID"
export const EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID="EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID"
export const GET_FOLLOWUP_BOOKING_LIST="GET_FOLLOWUP_BOOKING_LIST"
export const GET_FOLLOWUP_BOOKED_TOTAL="GET_FOLLOWUP_BOOKED_TOTAL"
export const EXPERIENCE_FOLLOWUP_SEARCH_VALUE="EXPERIENCE_FOLLOWUP_SEARCH_VALUE"
export const GET_EXPERIENCE_REFUNDED_TOTAL="GET_EXPERIENCE_REFUNDED_TOTAL"
export const GET_EXPERIENCE_REFUNDED_LIST="GET_EXPERIENCE_REFUNDED_LIST"
export const  EXPERIENCE_REFUNDED_NEXT_PAGE_ID="EXPERIENCE_REFUNDED_NEXT_PAGE_ID"
export const  EXPERIENCE_REFUNDED_CURRENT_PAGE_ID="EXPERIENCE_REFUNDED_CURRENT_PAGE_ID"


  




//*****************------- ExperiencesListing ----- *************** */
export const GET_EXPERIENCES_LIST = "GET_EXPERIENCES_LIST"
export const GET_EXPERIENCES_PACKAGE_INFO = "GET_EXPERIENCES_PACKAGE_INFO"
export const ADD_EXPERIENCES_IMAGE = "ADD_EXPERIENCES_IMAGE";
export const SEARCH_EXPERIENCES_LIST_DATA = "SEARCH_EXPERIENCES_LIST_DATA";
export const ALL_EXPERIENCES_IMAGES_DATA = "ALL_EXPERIENCES_IMAGES_DATA";
export const UPDATE_EXPERIENCES = "UPDATE_EXPERIENCES";
export const GET_SELECT_EXPERIENCES_DROPDOWN = "GET_SELECT_EXPERIENCES_DROPDOWN";
export const POST_EXPERIENCES_LISTING_DATA = "POST_EXPERIENCES_LISTING_DATA";
export const EXPERIENCES_IMAGE_DELETE = "IMAGEXPERIENCES_IMAGE_DELETEE_DELETE";
export const EXPERIENCES_PACKAGE_DELETE = "EXPERIENCES_PACKAGE_DELETE";
export const EXPERIENCES_DELETE = "EXPERIENCES_DELETE";
export const EXPERIENCES_LISTING_PAGE_ID = "EXPERIENCES_LISTING_PAGE_ID"
export const EXPERIENCE_LISTING_DROP_DOWN_VALUE = "EXPERIENCE_LISTING_DROP_DOWN_VALUE"
export const EXPERIENCES_LISTING_UPDATE_BOOL = "EXPERIENCES_LISTING_UPDATE_BOOL"
export const EXPERIENCE_LISTING_SEARCH_VALUE = "EXPERIENCE_LISTING_SEARCH_VALUE";
export const SET_SELECTED_EXPERIENCE_CHOSEN_ID = "SET_SELECTED_EXPERIENCE_CHOSEN_ID";
export const GET_EXPERIENCES_PACKLIST_DOWNLOAD = "GET_EXPERIENCES_PACKLIST_DOWNLOAD";
export const IS_EXP_DATA_LOADING='IS_EXP_DATA_LOADING'
export const IS_EXP_LIST_DATA_LOADING='IS_EXP_LIST_DATA_LOADING'
//******************----Reviews-----------*********************** */
export const EXPERIENCES_PENDING_REVIEW_LIST= "EXPERIENCES_PENDING_REVIEW_LIST"
export const EXPERIENCES_REJECTED_REVIEW_LIST= "EXPERIENCES_REJECTED_REVIEW_LIST"
export const EXPERIENCES_REVIEW_PUBLISHED_LIST="EXPERIENCES_REVIEW_PUBLISHED_LIST"
export const EXPERIENCES_REVIEW_PAGE_ID="EXPERIENCES_REVIEW_PAGE_ID"
export const EXPERIENCES_REVIEW_START_DATE="EXPERIENCES_REVIEW_START_DATE"
export const EXPERIENCES_REVIEW_END_DATE="EXPERIENCES_REVIEW_END_DATE"
//*********corporate testimonial */
export const TESTIMONIAL_CORPORATE_LIST = "TESTIMONIAL_CORPORATE_LIST"
export const TESTIMONIAL_HOLIDAY_LIST = "TESTIMONIAL_HOLIDAY_LIST"
export const TESTIMONIAL_EXP_LIST = "TESTIMONIAL_EXP_LIST"
export const GET_EXPERIENCES_FEATURED_LIST = "GET_EXPERIENCES_FEATURED_LIST"

//*****GET_SUBSCRIBERS_COUNT */
export const GET_SUBSCRIBERS_COUNT = "GET_SUBSCRIBERS_COUNT"

//*****PARTNERS TOKEN */
export const PARTNERS_LIST = "PARTNERS_LIST"
export const SET_PARTNER_DETAILS = "SET_PARTNER_DETAILS"
export const UPDATE_PARTNER_DETAILS = "UPDATE_PARTNER_DETAILS"
export const REGENERATE_TOKEN = "REGENERATE_TOKEN"
export const PARTNER_SEARCH_TERM = "PARTNER_SEARCH_TERM"
export const PARTNER_LOGO = "PARTNER_LOGO"

/******Wedding *******/


export const WEDDING_STORY_LIST = "WEDDING_STORY_LIST"
export const WEDDING_STORY_DETAILS = "WEDDING_STORY_DETAILS"
export const WEDDING_REQUESTED = "WEDDING_REQUESTED"
export const WEDDING_PLANNING = "WEDDING_PLANNING"
export const WEDDING_CONFIRMED = "WEDDING_CONFIRMED"
export const WEDDING_CANCELLED = "WEDDING_CANCELLED"
export const WEDDING_SEARCH_TERM = "WEDDING_SEARCH_TERM"
export const WEDDING_START_DATE = "WEDDING_START_DATE"
export const WEDDING_END_DATE = "WEDDING_END_DATE"
export const WEDDING_PAGE_NO = "WEDDING_PAGE_NO"
export const WEDDING_CONFIRM_AMOUNT="WEDDING_CONFIRM_AMOUNT"

export const WEDDING_DESTINATION_LIST = "WEDDING_DESTINATION_LIST"
export const WEDDING_IMAGE_UPLOAD = "WEDDING_IMAGE_UPLOAD"
export const WEDDING_DESTINATION_CREATE = "WEDDING_DESTINATION_CREATE"

/******Honeymoon *******/


export const HONEYMOON_STORY_LIST = "HONEYMOON_STORY_LIST"
export const HONEYMOON_STORY_DETAILS = "HONEYMOON_STORY_DETAILS"
export const HONEYMOON_REQUESTED = "HONEYMOON_REQUESTED"
export const HONEYMOON_PLANNING = "HONEYMOON_PLANNING"
export const HONEYMOON_CONFIRMED = "HONEYMOON_CONFIRMED"
export const HONEYMOON_CANCELLED = "HONEYMOON_CANCELLED"
export const HONEYMOON_SEARCH_TERM = "HONEYMOON_SEARCH_TERM"
export const HONEYMOON_START_DATE = "HONEYMOON_START_DATE"
export const HONEYMOON_END_DATE = "HONEYMOON_END_DATE"
export const HONEYMOON_PAGE_NO = "HONEYMOON_PAGE_NO"

export const HONEYMOON_DESTINATION_LIST = "HONEYMOON_DESTINATION_LIST"
export const HONEYMOON_IMAGE_UPLOAD = "HONEYMOON_IMAGE_UPLOAD"
export const HONEYMOON_DESTINATION_CREATE = "HONEYMOON_DESTINATION_CREATE"

/****** Anniversary *******/


export const ANNIVERSARY_STORY_LIST = "ANNIVERSARY_STORY_LIST"
export const ANNIVERSARY_STORY_DETAILS = "ANNIVERSARY_STORY_DETAILS"
export const ANNIVERSARY_REQUESTED = "ANNIVERSARY_REQUESTED"
export const ANNIVERSARY_PLANNING = "ANNIVERSARY_PLANNING"
export const ANNIVERSARY_CONFIRMED = "ANNIVERSARY_CONFIRMED"
export const ANNIVERSARY_CANCELLED = "ANNIVERSARY_CANCELLED"
export const ANNIVERSARY_SEARCH_TERM = "ANNIVERSARY_SEARCH_TERM"
export const ANNIVERSARY_START_DATE = "ANNIVERSARY_START_DATE"
export const ANNIVERSARY_END_DATE = "ANNIVERSARY_END_DATE"
export const ANNIVERSARY_PAGE_NO = "ANNIVERSARY_PAGE_NO"

export const ANNIVERSARY_DESTINATION_LIST = "ANNIVERSARY_DESTINATION_LIST"
export const ANNIVERSARY_IMAGE_UPLOAD = "ANNIVERSARY_IMAGE_UPLOAD"
export const ANNIVERSARY_DESTINATION_CREATE = "ANNIVERSARY_DESTINATION_CREATE"

//Create payment
export const PAYMENT_BOOKING_LIST = "PAYMENT_BOOKING_LIST"
export const BOOKED_PAYMENT_BOOKINGS = "BOOKED_PAYMENT_BOOKINGS"
export const PENDING_PAYMENT_BOOKINGS = "PENDING_PAYMENT_BOOKINGS"

// HOLIDAY REVIEW

export const HOLDAY_PACK_REVIEWS_LIST = "HOLDAY_PACK_REVIEWS_LIST"
export const CORPORATE_PACK_REVIEWS_LIST = "CORPORATE_PACK_REVIEWS_LIST"
export const EXPERIENCE_PACK_REVIEWS_LIST = "EXPERIENCE_PACK_REVIEWS_LIST"