import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from "styled-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import CloseIcon from '../../../assets/images/close.svg'
import * as EventsBookingAction from "../../../actions/eventsAction";

function ConfirmPayment({ modalDetails, showConfModal,setPageChange, toggleModal, pageNum, catId, selectedPage }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [showModal, setShowModal] = useState(showConfModal)
    const [state, setState] = useState({
        amount: "",
    });

    const [error, setError] = useState({
        amount: false,
    })

    const handleClose = () => {
        toggleModal(false);
    };

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
    };

    const handleConfirmBooking = (e, id, corporate, selectedValue) => {
        e.preventDefault();
        if (!state.amount || state.amount === "") {
            setError({ amount: true })
        }
        else {
            dispatch(EventsBookingAction.updateBookingStatus(id, selectedValue, corporate, refreshData, parseFloat(state?.amount)))
        }
    }

    const refreshData = () => {
        // dispatch(EventsBookingAction.getUpcomingBookingsList(pageNum));
        dispatch(EventsBookingAction.getUserBookingList(pageNum, catId));
        dispatch(EventsBookingAction.getUpcomingBookingsList(selectedPage));
        enqueueSnackbar("Status updated successfully.", {
            variant: "success",
        });
        toggleModal(false);
        setPageChange(false);
    }

    return (
        <div>
            <Dialog open={showConfModal}
                PaperProps={{
                    style: {
                        width: '430px',
                        padding: '20px 20px 40px'
                    },
                }}
            >
                <div className="">
                    <div className="close-cancel-icon" onClick={handleClose}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <DialogTitle style={{ padding: '16px 0px' }}>
                        <p className="mb-0">Confirm booking</p>
                    </DialogTitle>
                    <div className="message-box">
                        <h5 style={{ textAlign: 'center' }}>{modalDetails?.title}</h5>
                            <p className="text-center" style={{fontWeight: "500"}}>Ref. ID: {modalDetails?.ref_id}</p>
                        <div className="contact-details-holder" style={{ justifyContent: 'center' }}>
                            <div className="contact-info">
                                <div className="title">
                                    <p>Booked for</p>
                                </div>
                                <div className="contact-details">
                                    <p className="email">
                                        {moment(modalDetails?.schedule?.date, 'DD-MM-YYYY').format('Do MMM, YYYY')} </p>
                                    <p>{moment(modalDetails?.schedule?.from, 'hh:mm').format('LT')}</p>
                                </div>
                            </div>

                        </div>
                        <div className="user-info">
                            <h4>{modalDetails?.contact_name}</h4>
                            <p>{modalDetails?.contact_email} , {modalDetails?.contact_number}</p>
                        </div>
                    </div>

                    <form >
                        <div className="input--wrapper">
                            <label>Enter amount collected</label>
                            <div className="amountInput-holder">
                                <p>Rs</p>
                                <input type="text" onChange={(value) => handleChange("amount", value)} value={state.amount}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            {error.amount === true ? <p className="errorMsg">Please enter the amount</p> : null}
                        </div>
                        <div className="cta-holder">
                            <button type="button" className="btn cancel-btn" style={{ background: '#2196F3' }} onClick={(e) => handleConfirmBooking(e, modalDetails?.id, modalDetails?.corporate, modalDetails?.selected_status)} >Confirm booking</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default ConfirmPayment
