import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { searchListing } from "../../../actions/corpListingAction";
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import Popup from "./popup";
import Edit from "../../../assets/images/edit.svg";
import PointsPopup from "./pointsPopup";
import EmployeePopup from "./employeeUpdatePopup";
import "../../../assets/css/partials/accounts.scss";
import { useSnackbar } from "notistack";
import * as AccountAction from "../../../actions/accountsAction";

import {
    addCorpUser,
    accountsList,
    pointsUpdate,
    employeeUpdate,
    searchUser,
} from "../../../actions/accountsAction";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        boxShadow: "none",
        background: "#FAFAFA",
        border: "1px solid #EDEDED",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "18px 20px",
        minHeight: 78,
        cursor: "pointer",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
    },
    title: {
        fontSize: 16,
        marginBottom: 4,
        fontWeight: 500,
        color: "#000000",
    },
    subTitle: {
        fontSize: 12,
        marginBottom: 4,
        fontWeight: 500,
        color: "#000000",
    },
    accounts: {
        fontSize: 12,
        marginBottom: 4,
        fontWeight: 500,
        color: "#000000",
    },
    location: {
        marginBottom: 0,
        fontWeight: 400,
        fontSize: 12,
        color: "#797979",
    },
}));

export default function EmployeeListing({
    handleUpdatePoints,
    page,
    getEmployeeList,
    empListPage
}) {
    const classes = useStyles();
    const [add, setAdd] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchBool, setsearchBool] = useState(false);
    const [popupSelect, setPopupselect] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [addUserPoints, setAddUserPoints] = useState({});
    const [addEmployeeUpdate, setEmployeeUpdate] = useState({});
    const [innitialData, setInnitialData] = useState()

    const searchInputRef = useRef(null)

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setAddUserModal(true);
        setInnitialData({})
    };
    const handleClickPointsOpen = (id) => {
        setAddUserPoints({
            [id]: true
        })
    }

    const handleEmpUpdateOpen = (id, item) => {
        setEmployeeUpdate({
            [id]: true
        })
    }

    const handleClose = (name, mobile, email) => {
        setAddUserModal(false);
    };
    const handlePointsClose = () => {
        setAddUserPoints(false)
    }
    const handleEmpUpdateClose = () => {
        setEmployeeUpdate(false)
    }
    const dispatch = useDispatch();

    const accountSearchDetails = useSelector(
        (state) => state.accountListing.searchUserData.data
    );

    
    const getEmployeeListData = searchBool ? accountSearchDetails?.results : getEmployeeList?.results
    // console.log(getEmployeeListData, "getEmployeeListData")

    const getAccountDetails = useSelector(
        (state) => state.accountListing.accountDetails.user_accounts
    );

    const getCorpDetails = useSelector(
        (state) => state.accountListing.accountDetails
    );

    const setUserData = (name, mobile, email) => {
         const addUserData = {
            org_id: getCorpDetails?.id,
            name: name,
            email: email,
            mobile: mobile || null,
        };
        dispatch(addCorpUser(addUserData, handleNewEmployeeSuccess, handleError));
        // addUserData = {}
        // const result = response
        // getAccountDetails?.user.concat([{ name: name, mobile: mobile, email: email }])
        // getAccountDetails.map((item) => {
        //     item.user.concat([{ name: name, email: email, mobile: mobile }])
        // })
        // accountsList()
    };
    useEffect(() => {
        setsearchBool(false)
        searchInputRef.current.value = ''
        dispatch(AccountAction.setSearchBool(false))
        setSearchTerm("")
    }, [getCorpDetails])

    // const setUserData = (name, mobile, email) => {
    //     const addUserData = {
    //         org_id: getCorpDetails?.id,
    //         name: name,
    //         email: email,
    //         mobile: mobile || null,
    //     };
    //     dispatch(addCorpUser(addUserData, handleNewEmployeeSuccess, handleError));
    // };
    const setPointsData = (points, id) => {
        const corpId = { org_id: getCorpDetails?.id }
        dispatch(pointsUpdate(points, id, corpId, handleLoader, handlePointsSuccess, handleError));
    };

    const handlePointsSuccess = (message, id) => {
        enqueueSnackbar(message, {
            variant: "success",
        });
        handlePointsClose();
        // dispatch(AccountAction.getAccountInfo(id));
        if(searchTerm == ""){
            dispatch(AccountAction.getEmployeeList(id, empListPage))
        }else{
            dispatch(AccountAction.searchUser(searchTerm, id, accountSearchDetails?.current_page))
        }
    };

    const handleNewEmployeeSuccess = (message, id) => {
        enqueueSnackbar("Successfully Added", {
            variant: "success",
        });
        handleClose();
        dispatch(AccountAction.getAccountInfo(id));
    };

    const handleError = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const handleLoader = (id) => {
        // dispatch(AccountAction.getAccountInfo(id));
    };

    const setEmployeeData = (name, mobile, email, id) => {
        const corpId = { org_id: getCorpDetails?.id }
        dispatch( employeeUpdate(name, mobile, email, id, corpId, handleLoader, handleUpdateEmployeeSuccess, handleError));
        accountsList();
    };

    const handleUpdateEmployeeSuccess = (message, id) => {
        enqueueSnackbar("Successfully Updated", {
            variant: "success",
        });
        handleEmpUpdateClose();
        // dispatch(AccountAction.getAccountInfo(id));
        if(searchTerm == ""){
            dispatch(AccountAction.getEmployeeList(id, empListPage))
        }else{
            dispatch(AccountAction.searchUser(searchTerm, id, accountSearchDetails?.current_page))
        }
    };
    
    const userSearch = (event) => {
        setSearchTerm(event.target.value)
        dispatch(AccountAction.setSearchValue(event.target.value))
        const id = getCorpDetails?.id;
        let search = event.target.value;
        if(search !== ""){
            setsearchBool(true);
            dispatch(searchUser(search, id, 1));
            dispatch(AccountAction.setSearchBool(true))
            
            
        }else{
            setsearchBool(false)
            dispatch(AccountAction.getEmployeeList(id, 1))
            dispatch(AccountAction.setSearchBool(false))

            
        }
    };

    return (<>
            <Row className={classes.row} >
                <Col className="w-100">
                    <input
                        type="search"
                        className="form-control search-list"
                        placeholder="Search Accounts"
                        // onKeyPress={userSearch}
                        onChange={userSearch}
                        ref = {searchInputRef}
                    />
                </Col>
                <Col>
                    {/*<button className="btn add-new">
                        Add New <AddSharpIcon />
    </button>*/}
                    <Popup
                        // updateUserData={setUserData}
                        handleClose={handleClose}
                        openPopup={addUserModal}
                        page={page}
                    />
                    <button
                        style={{
                            width: "max-content",
                            border: "1px solid #EDEDED",
                            color: "#000000",
                            height: "42px",
                            background: "white",
                            fontSize: "13px",
                            fontWeight: "500",
                            padding: "20px 10px",
                            margin: "3px 10px"
                        }}
                        type="button"
                        className="btn  btn-save"
                        onClick={handleClickOpen}
                    >
                        Add New Employee&nbsp;&nbsp;
            <AddSharpIcon />
                    </button>

                </Col>
            </Row>
        <div style={{ overflowY: "scroll", height: "500px" , margin: "5px 0"}}>
            {add && (
                <Card className={classes.root}>
                    <h6 className={classes.title}>{ }</h6>
                    <p className={classes.location}>No location provided yet</p>
                </Card>
            )}

            {searchBool
                ? getEmployeeListData &&
                getEmployeeListData.length > 0 &&
                getEmployeeListData.map((item) => (
                    <Card key={item.id} className={classes.root}>
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="parent">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="employeeListHover">
                                        <h6>{item.user[0].full_name}</h6>
                                        <label>{item.user[0].mobile}</label>
                                        <label>{item.user[0].email}</label>
                                    </div>
                                </div>
                                <div className="showHide">
                                    <EmployeePopup
                                        updateEmployeeData={(name, mobile, email) =>
                                            setEmployeeData(name, mobile, email, item.id)
                                        }
                                        currentName={item.user[0].full_name}
                                        currentMobile={item.user[0].mobile}
                                        currentEmail={item.user[0].email}
                                        handleClose={handleEmpUpdateClose}
                                        openPopup={addEmployeeUpdate[item.id]}
                                    />
                                    <img
                                        src={Edit}
                                        style={{ position: "absolute", left: "0", top: "30%", background: 'white', padding: '8px', borderRadius: '100%'}}
                                        onClick={() => handleEmpUpdateOpen(item.id)}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <h6>{item.points} pts</h6>
                                <label>Current Balance</label>
                                <PointsPopup
                                    updatePointsData={(points) => setPointsData(points, item.id)}
                                    currentPoint={item?.points}
                                    userName={item?.user[0].full_name}
                                    handleClose={handlePointsClose}
                                    openPopup={addUserPoints[item.id]}
                                />
                                
                                {<button
                                    style={{ margin: 0 }}
                                    type="button"
                                    className="btn btn-primary btn-save"
                                    onClick={() => handleClickPointsOpen(item.id)}
                                >
                                    Update
                               </button>}
                            </div>
                        </div>
                    </Card>
                ))
                : getEmployeeListData &&
                getEmployeeListData.length > 0 &&
                getEmployeeListData.map((item) => (
                    // <Card className={classes.root} onClick={() => setEditDetails(item)}>
                    <Card
                        key={item.id}
                        className={classes.root}
                    >
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="parent">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="employeeListHover">
                                        <h6>{item.user[0].full_name}</h6>
                                        <label>{item.user[0].mobile}</label>
                                        <label>{item.user[0].email}</label>
                                    </div>
                                    {/*showEdit && <img
                                        src={Edit}
                                        style={{ position: "absolute", left: "9px", top: "42px", }}
                                    />*/}
                                </div>
                                <div className="showHide">
                                    <EmployeePopup
                                        updateEmployeeData={(name, mobile, email) =>
                                            setEmployeeData(name, mobile, email, item.id)
                                        }
                                        currentName={item.user[0].full_name}
                                        currentMobile={item.user[0].mobile}
                                        currentEmail={item.user[0].email}
                                        handleClose={handleEmpUpdateClose}
                                        openPopup={addEmployeeUpdate[item.id]}
                                    />
                                    <img
                                        src={Edit}
                                        style={{ position: "absolute", left: "0", top: "30%", background: 'white', padding: '8px', borderRadius: '100%' }}
                                        onClick={() => handleEmpUpdateOpen(item.id, item)}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <h6>{item.points} pts</h6>
                                <label>Current Balance</label>
                                <PointsPopup
                                    updatePointsData={(points) => setPointsData(points, item.id)}
                                    currentPoint={item?.points}
                                    userName={item?.user[0].full_name}
                                    handleClose={handlePointsClose}
                                    openPopup={addUserPoints[item.id]}
                                />
                                
                                {<button
                                    style={{ margin: 0 }}
                                    type="button"
                                    className="btn btn-primary btn-save"
                                    onClick={() => handleClickPointsOpen(item.id)}
                                >
                                    Update
                               </button>}
                            </div>
                        </div>
                    </Card>
                ))}

        </div>
    </>);
}
