import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import "../assets/css/partials/closeRequestModal.scss";
import closeImage from "../assets/images/close.svg";
import { useSnackbar } from "notistack";

const AddHandleRate = ({
    modalClose,
    open,
    setPriceList,
    handleAddPrice,
    priceList,
    isAdd,
    isEdit,
    priceDataLoad,
    toDeleteIds,
    setToDeleteIds,
    currentPackage,
}) => {
    const [cancelOpen, setCancelOpen] = useState(false);
    const [adults, setAdults] = useState(priceList?.adults);
    const [children, setChildren] = useState(priceList?.children);
    const { enqueueSnackbar } = useSnackbar();
    console.log(adults,"adults");

    const handleWheel = (e) => {
        if (document.activeElement === e.currentTarget) {
            e.preventDefault();
        }
    };

    const onFocus = (e) => {
        e.currentTarget.addEventListener(
            "wheel",
            (wheelEvent) => {
                wheelEvent.preventDefault();
            },
            { passive: false }
        );
    };
    const handleDragEnter = (e) => {
        e.preventDefault();
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
      };
    

    const cancel_addRate = () => {
        setCancelOpen(true);
        
    };

    const cancelAddRateDone = () => {
        if (isAdd) {
            setPriceList({
                adults: [{adult1:null}],
                children: [{child1:null}],
            });
        } else if (isEdit) {
     
            priceDataLoad(true);
            
        }
        modalClose();

    };

    const handleAddAdult = () => {
      if(adults.length>=100){
        enqueueSnackbar("Maximum Adult reached ", {
          variant: "error",
        });

      }
      else{
        setAdults([...adults, { [`adult${adults.length + 1}`]: null }]);

      }
       
    };

    const handleAddChild = () => {
      if(children.length>=100){
        enqueueSnackbar("Maximum Children reached ", {
          variant: "error",
        });

      }
      else{
        setChildren([...children, { [`child${children.length + 1}`]: null }]);
             

      }
 
    };

    const handleAdultChange = (index, value) => {
        const newAdults = [...adults];
        newAdults[index][`adult${index + 1}`] = parseInt(value.replace(/[^0-9]/g, ""));
        setAdults(newAdults);
        setPriceList({ ...priceList, adults: newAdults });
    
      
    };

    const handleChildChange = (index, value) => {
        const newChildren = [...children];
        newChildren[index][`child${index + 1}`] = parseInt(value.replace(/[^0-9]/g, ""));
        setChildren(newChildren);
        setPriceList({ ...priceList, children: newChildren });
    };

    const deleteIdAdult=(adult,index)=>{
        const newAdults = adults.filter(
            (a, i) => i !== index
        );
        setAdults(newAdults);
        setPriceList({ ...priceList, adults: newAdults });
        if(adult.id){

            setToDeleteIds([...toDeleteIds,{"id":adult.id,"age_group":"adult","num_people":parseInt(`${index+1}`),"price":adult['adult' + (index + 1)]}])

        }
   

    }
    const deleteIdChild=(child,index)=>{
        const newChildren = children.filter(
            (c, i) => i !== index
        );
        setChildren(newChildren);
        setPriceList({ ...priceList, children: newChildren });
        if(child.id){
            setToDeleteIds([...toDeleteIds,{"id":child.id,"age_group":"adult","num_people":parseInt(`${index+1}`),"price":child['child' + (index + 1)]}])

        }

    }

    return (
        <>
            <Dialog
                PaperProps={{ style: { height: "auto", maxWidth: "535px" } }}
                open={open}
            >
                <span
                    style={{ position: "absolute", right: "3%", top: "2%" }}
                    onClick={cancel_addRate}
                >
                    <img src={closeImage} alt="close" />
                </span>
              {adults?.length>0?  <div style={{ display: "flex" }}>
                    <Button
                        onClick={handleAddPrice}
                        color="primary"
                        style={{
                            backgroundColor: "#007BFF",
                            color: "white",
                            textTransform: "none",
                            marginLeft: "300px",
                            width: "155px",
                            marginTop: "25px",
                        }}
                    >
                        Update
                    </Button>
                </div>:<></>}
                <div style={{ margin: "30px", background: "#EDEDED" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            height: "20px",
                        }}
                    >
                        <p
                            style={{
                                margin: "0px 25px",
                                marginTop: "5px",
                                width: "155px",
                                textAlign: "center",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                        >
                            {" "}
                            Adults
                        </p>
                        <p
                            style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                width: "155px",
                                textAlign: "center",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                        >
                            {" "}
                            Children
                        </p>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "30px",
                            marginBottom: "40px",
                        }}
                    >
                        <div style={{width:"40%"}}>
                            {adults?.map((adult, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: "relative",
                                        display: "inline-block",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <input
                                        onChange={(e) => handleAdultChange(index, e.target.value)}
                                        onWheel={(e) => handleWheel(e)}
                                        onFocus={(e) => onFocus(e)}
                                    
                                        value={adult[`adult${index + 1}`]==0?0:adult[`adult${index + 1}`] || ""}
                                        placeholder={`Adult ${index+1}`}
                                        onDragEnter={handleDragEnter}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        type="number"
                                        style={{
                                            background: "#FFFFFF",
                                            border: "none",
                                            margin: "0px 25px",
                                            width: "155px",
                                            height: "35px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            paddingRight: "30px", // Make space for the image
                                        }}
                                    />
                                  {(index===adults.length-1&&index!==0) && <img
                                        src={closeImage}
                                        alt="close"
                                        style={{
                                            position: "absolute",
                                            left: "28px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                            backgroundColor: "skyblue",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                        onClick={() => {
                                            deleteIdAdult(adult,index)
                                           
                                        }}
                                    />
                                      }
                                </div>
                            ))}
                            <Button
                                onClick={()=>handleAddAdult()}
                                color="primary"
                                style={{
                                    backgroundColor: "#007BFF",
                                    color: "white",
                                    textTransform: "none",
                                    width: "155px",
                                    height: "35px",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    marginLeft: "25px",
                                }}
                            >
                                Add Adult
                            </Button>
                        </div>

                        <div style={{width:"40%"}}>
                            {children?.map((child, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: "relative",
                                        display: "inline-block",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <input
                                        onChange={(e) => handleChildChange(index, e.target.value)}
                                        onWheel={(e) => handleWheel(e)}
                                        onFocus={(e) => onFocus(e)}
                                        value={child[`child${index + 1}`]==0?0:child[`child${index + 1}`] || ""}
                                        placeholder={`Child ${index + 1}`}
                                        onDragEnter={handleDragEnter}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        type="text"
                                        style={{
                                            background: "#FFFFFF",
                                            border: "none",
                                            marginLeft: "10px",
                                            width: "155px",
                                            height: "35px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            paddingRight: "30px", // Make space for the image
                                        }}
                                    />
                                     {(index===children.length-1&&index!==0) &&  <img
                                        src={closeImage}
                                        alt="close"
                                        style={{
                                            position: "absolute",
                                            left: "14px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                            backgroundColor: "skyblue",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                        onClick={() => {
                                            deleteIdChild(child,index)
                                         
                                        }}
                                    />
                                      }
                                </div>
                            ))}
                            <Button
                                onClick={handleAddChild}
                                color="primary"
                                style={{
                                    backgroundColor: "#007BFF",
                                    color: "white",
                                    textTransform: "none",
                                    width: "155px",
                                    height: "35px",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    marginLeft: "10px",
                                }}
                            >
                                Add Child
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                PaperProps={{ style: { height: "auto", maxWidth: "500px" } }}
                open={cancelOpen}
            >
                <h5 style={{ textAlign: "center", marginBottom: "25px" }}>
                    {" "}
                    Are you sure to want to cancel ?
                </h5>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        onClick={() => setCancelOpen(false)}
                        color="black"
                        style={{
                            backgroundColor: "#ADB0AD",
                            textTransform: "none",
                            margin: "0px 25px",
                            width: "155px",
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={cancelAddRateDone}
                        color="primary"
                        style={{
                            backgroundColor: "#007BFF",
                            color: "white",
                            textTransform: "none",
                            marginLeft: "10px",
                            width: "155px",
                        }}
                    >
                        Yes
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default AddHandleRate;
