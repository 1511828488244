import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import * as AccountAction from "../../../actions/accountsAction";
import { useSnackbar } from "notistack";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    EMPLOYEE_NAME,
    EMPLOYEE_MOBILE,
    EMPLOYEE_EMAIL
} from '../../../utils/constants';
import {
    addCorpUser,
} from "../../../actions/accountsAction";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperWidthMd: {
        // maxWidth: '500px',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function Popup({ handleClose, openPopup, page }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    

    const [name, setName] = React.useState('');
    const [mobile, setMobile] = React.useState(null);
    const [email, setEmail] = React.useState('');

    const [addUserModal, setAddUserModal] = useState(false);


    const [isNameValidation, setIsNameValidation] = useState(null);
    const [isMobileValidation, setIsMobileValidation] = useState(null);
    const [isMobileLengthValidation, setIsMobileLengthValidation] = useState(null);
    const [isEmailEmptyValidation, setEmailEmptyValidation] = useState(null);
    const [isEmailValidation, setIsEmailValidation] = useState(null);

    const getCorpDetails = useSelector(
        (state) => state.accountListing.accountDetails
    );
    const emailError = useSelector((state) => state.accountListing.errorEmailDomain);
    

    const handleNameChange = (event) => {
        // if (/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/.test(event.target.value)) {
        //     const name = event.target.value
        //     setName(name)
        // }
        const name = event.target.value;
        setName(name);
    };
    const clearInputFields = () => {
        setName('')
        setMobile(null)
        setEmail('')
    }

    const clearAndClose = () => {
        clearInputFields()
        handleClose()
    }
    const handleMobileChange = (event) => {
        // if (event.target.value.match(/^\d{10}$/) != null) {
        //     const mobile = event.target.value
        //     setMobile(mobile)
        // }
        // const mobile = event.target.value;
        setMobile(event);
    };
    const handleEmailChange = (event) => {
        const email = event.target.value;
        setEmail(email);

        // let eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        //     // setIsTrue(false);
        //     setEmail(event.target.value);

        //     let ep_emailval = event.target.value;
        //     if (eml.test(ep_emailval) == false) {
        //         alert("Enter Valid Email");
        //     }else{
        //         setEmail(event.target.value);

        //     }
    };

    const inputValidation = () => {
        setIsNameValidation(false)
        setIsMobileValidation(false)
        setIsMobileLengthValidation(false)
        setEmailEmptyValidation(false)
        setIsEmailValidation(false)
        const addUserData = {
            org_id: getCorpDetails?.id,
            name: name,
            email: email,
            mobile: mobile || null,
        };
        if (name === "" || name === null) setIsNameValidation(true)
        else if (mobile === "" || mobile === null) setIsMobileValidation(true) 
        else if (mobile.length < 10) setIsMobileLengthValidation(true)
        else if (email === "" || email === null) setIsEmailValidation(true)
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) setIsEmailValidation(true)
        else {
            dispatch(addCorpUser(addUserData, handleNewEmployeeSuccess, handleError));
            if (emailError === true) return;
            else (clearAndClose())
        };

    }
    const handleNewEmployeeSuccess = (message, id) => {
        enqueueSnackbar("Successfully Added", {
            variant: "success",
        });
        handleClose();
        dispatch(AccountAction.getAccountInfo(id));
        // dispatch(AccountAction.accountsList(handleLoader, page))
    };
   
    const handleLoader =() =>{
        
    }

    const handleError = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    return (
        <div>

            <Dialog
                disableBackdropClick={true}
                onClose={clearAndClose}
                aria-labelledby="customized-dialog-title"
                open={openPopup}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={clearAndClose}
                    style={{ width: "90%" }}
                >
                    Adding an employee in {getCorpDetails.name}
                </DialogTitle>
                <div
                    style={{
                        backgroundColor: "white",
                        marginTop: "10px",
                        padding: "15px",
                        borderRadius: "10px",
                    }}
                    className="form-group form-custom"
                >
                    <label for="">Employee Name</label>
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z\s]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        className="form-control"
                        onChange={handleNameChange}
                    />
                    {isNameValidation &&
                        <p style={{ color: "red" }}>{EMPLOYEE_NAME}</p>
                    }
                    <label style={{ marginTop: "10px" }}>Mobile No</label>
                    {/* <input
                        type="text"
                        placeholder="Mobile"
                        value={mobile}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) || mobile?.length === 10) {
                                event.preventDefault();
                            }
                        }}
                        className="form-control"
                        onChange={handleMobileChange}
                    /> */}
                    <PhoneInput
                    placeholder="Mobile"
                    value={mobile}
                    onChange={handleMobileChange}
                    withCountryCallingCode = {true}
                    limitMaxLength={true}
                    international={true}
                    defaultCountry="IN"
                    />
                    {isMobileValidation ?
                        <p style={{ color: "red" }}>{EMPLOYEE_MOBILE}</p>
                        : isMobileLengthValidation ? <p style={{ color: "red" }}>Enter 10 digit mobile</p> : ''
                    }

                    <label style={{ marginTop: "10px" }}>Email Id </label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        className="form-control"
                        onChange={handleEmailChange}
                    />
                    {isEmailEmptyValidation ?
                        <p style={{ color: "red" }}>{EMPLOYEE_EMAIL}</p>
                        : isEmailValidation ? <p style={{ color: "red" }}>Invalid email address</p> : ''
                    }
                </div>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                    }}
                >
                    {/*<Button autoFocus onClick={() => updateUserData(name, mobile, email)} color="primary">
                        Add
</Button>*/}
                    <DialogTitle >
                        <button
                            type="button"
                            className="btn btn-primary btn-save"
                            onClick={(onClose) => {
                                inputValidation()
                                // clearInputFields()
                                // updateUserData(name, mobile, email, onClose)

                            }
                            }
                        >
                            Add
            </button>
                    </DialogTitle>
                </DialogActions>
            </Dialog>
        </div>
    );
}
