import React from 'react'

const Input = ({handleChange, value, label, key, isDivider, disableChar,disabled}) => {
  return (
        <div>
            <label for="">{label}</label>
            {isDivider ? <div className='divider'/> : ""}
            <input
            value={value}
            type="text"
            disabled={disabled}
            className="form-control"
            onChange={(e) => handleChange(e)}
            onKeyPress={disableChar ? (event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }: null}
            />
        </div>
  )
}

export default Input