import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  holidayRequestsSearch, 
  holidayRequestSectionName,
  holidayReqSearchValue,
  pageId
} from '../../../actions/holidayRequestAction';

const option = [
  {id:1, value:"visa", name:"Visa"},
  {id:3, value:"custom", name:"Custom"}
];


const DropdownFilter = ({status}) => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const pageNum = useSelector(state => state.holidayRequest.page);
  const statusVal = useSelector(state => state.holidayRequest.holidayRequestStatusVal);
  const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
  const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
  const getHolidayReqSearchValue = useSelector(state => state.holidayRequest.holidayReqSearchValue);
  const followupSearchVal = useSelector(state => state.holidayRequest.holidayFollowupSearchValue);
  const closedSearchVal = useSelector(state => state.holidayRequest.holidayClosedSearchValue);
  console.log(useSelector(state => state.holidayRequest));
  const holidayConfirmedSearchValue = useSelector(state => state.holidayRequest.holidayConfirmedSearchValue);
  console.log(status);
  console.log(followupSearchVal);
 
  const dispatch = useDispatch();

  const searchValue = () => {
    if(statusVal === "request")return getHolidayReqSearchValue
    else if(statusVal === "followup")return followupSearchVal
    else if(statusVal === "closed")return closedSearchVal
    else if(statusVal==="confirmed")return holidayConfirmedSearchValue
  };
  
  const handleClose = (item) => {

    setDropdownValue(item?.name);
    setAnchorEl(null);
    dispatch(pageId(1));
    dispatch(holidayRequestsSearch(pageNum, status, searchValue() ,ffEndDate, ffStartDate,item?.name ));
    dispatch(holidayRequestSectionName(item));
    dispatch(holidayReqSearchValue("", statusVal));
  };

  const handleEventClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(pageId(1));
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={handleEventClick} 
         className="dropdown-filter"
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {option && option.map(item => {
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  );
};

export default DropdownFilter;