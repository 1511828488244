import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from "react-redux";
import * as corpListingAction from "../../../actions/corpListingAction";
import * as bookingAction from "../../../actions/bookingAction";
import * as  EventListingAction from "../../../actions/eventsAction";
import * as ExperiencesListingAction from "../../../actions/experienceListingAction";
import * as ListAction from "../../../actions/corpListingAction";


function DropdownFilter({ status, eventBookingSelectedTab,disabled }) {
  const selectedTab = status;
  const eventsSelectedTab = eventBookingSelectedTab;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const selectedMenuDropdown = useSelector((state) => state.corpListing?.selectedDropdown);
  const selectedEventsMenuDropdown = useSelector((state) => state.eventsListing?.selectedEventsDropdown);
  const selectedExperiencessMenuDropdown = useSelector( (state) => state.experiencesListing?.selectedDropdown);

  const dispatch = useDispatch();
  
  const handleClick = (event) => {
    dispatch(corpListingAction.selectDropdown());
    setAnchorEl(event.currentTarget);
    dispatch(ListAction.corpPageId(1));
    dispatch(ListAction.searchValue(""));
    dispatch(bookingAction.searchTextvalue("", selectedTab))
    dispatch(bookingAction.setPageNum(1));
  };

  const handleEventClick = (event) => {
    dispatch(EventListingAction.selectEventsListDropdown());
    setAnchorEl(event.currentTarget);
  };

  const handleExperiencesClick = (event) => {
    dispatch(ExperiencesListingAction.selectDropdown());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    
    if (selectedTab === "booked") {
      dispatch(bookingAction.getBookingFilteredlist(item?.id));
      
    }
    else if (selectedTab === "confirmed") {
      dispatch(bookingAction.getConfirmedBookingFilteredlist(item?.id))
    }
    else if (selectedTab === "cancelled") {
      dispatch(bookingAction.getCancelledBookingFilteredlist(item?.id))
    }
    else if (selectedTab === "requested") {
      dispatch(bookingAction.getAvailabilityFilteredList(item?.id, 1))
    }
    else if (selectedTab === "filteredListingArray") {               
      dispatch(corpListingAction.getCorpFilteredList(item?.id));
      dispatch(corpListingAction.corpDropDownFilterSecId(item))
    }
    else if ((eventsSelectedTab === "upcoming" || eventsSelectedTab === "past" || eventsSelectedTab === "cancelled") && selectedTab === "filteredEventsListArray") {
      // dispatch(EventListingAction.getEventBookingsFilteredList(item?.id, eventsSelectedTab));
      dispatch(EventListingAction.eventBookingSearchValue("", eventsSelectedTab));
      dispatch(EventListingAction.getUpcomingBookingsList(1, item?.id));
      dispatch(EventListingAction.getPastBookingsList(1, item?.id));
      dispatch(EventListingAction.getCancelledBookingsList(1, item?.id));
    }
    else if (selectedTab === "filteredEventsListArray") {
      dispatch(EventListingAction.getEventsFilteredList(item?.id));
    }
    else if (selectedTab === "filteredExperiencesListArray") {
      dispatch(ExperiencesListingAction.getExperienceFilteredList(item?.id));
    }
    // else if (selectedTab === "filteredHolidaysListArray") {
    //   dispatch(HolidayListingAction.getHolidaysFilteredList(item?.id));
    // }

    dispatch(bookingAction.searchBooking("", status, item?.id, 1));
    setDropdownValue(item?.name);
    setAnchorEl(null);
  };

  return (
    <div>
      { selectedTab === "filteredEventsListArray" ?
        <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleEventClick} className="dropdown-filter"
            onChange={(event) => setDropdownValue(event.target.value)} >
            {dropdownValue ? dropdownValue : 'Showing All'}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Show All</MenuItem>
            {selectedEventsMenuDropdown &&
              selectedEventsMenuDropdown.map((item) => {
                return (
                  <MenuItem
                    onClick={() => handleClose(item)}
                    key={item.id} >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Menu> </div> : selectedTab === "filteredExperiencesListArray" ? 
                 <div>
                 <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleExperiencesClick} className="dropdown-filter"
                   onChange={(event) => setDropdownValue(event.target.value)} >
                   {dropdownValue ? dropdownValue : 'Showing All'}
                 </Button>
                 <Menu
                   id="simple-menu"
                   anchorEl={anchorEl}
                   keepMounted
                   open={Boolean(anchorEl)}
                   onClose={handleClose}
                 >
                   {selectedExperiencessMenuDropdown &&
                     selectedExperiencessMenuDropdown.map((item) => {
                       return (
                         <MenuItem
                           onClick={() => handleClose(item)}
                           key={item.id} >
                           {item.name}
                         </MenuItem>
                       );
                     })}
                 </Menu> </div> :(
          <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={disabled ? "" : handleClick} className="dropdown-filter"
              onChange={(event) => setDropdownValue(event.target.value)}  style={{cursor:disabled?'not-allowed':"pointer"}}
              >
              {dropdownValue ? dropdownValue : 'Showing All'}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Show All</MenuItem>
              {selectedMenuDropdown &&
                selectedMenuDropdown.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => handleClose(item)}
                      key={item.id} >
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
        )}
    </div>
  )
}

export default DropdownFilter;



