import React, {useState, useEffect} from 'react'
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import rightArrow from "../../../assets/images/rightArrow.svg";
import { useDispatch, useSelector } from 'react-redux';
import * as PartnerAction from "../../../actions/partnerAction"
import { Spinner } from 'react-bootstrap';

const Listing = ({partnerList, handleAdd, handleEdit}) => {
    
    const useStyles = makeStyles((theme) => ({
        root: {
          position: "relative",
          marginTop: 10,
          boxShadow: "none",
          background: "#FAFAFA",
          border: "1px solid #EDEDED",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "18px 20px",
          minHeight: 78,
          cursor: "pointer",
        },
        rootBlur: {
          position: "relative",
          marginTop: 10,
          boxShadow: "none",
          background: "#FAFAFA",
          border: "1px solid #EDEDED",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "18px 20px",
          minHeight: 78,
          cursor: "pointer",
          opacity: 0.5
        },
        rootBorder: {
          position: "relative",
          marginTop: 10,
          boxShadow: "none",
          background: "#FAFAFA",
          border: "2px solid #000000",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "18px 20px",
          minHeight: 78,
          cursor: "pointer",
        },
        row: {
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
        },
        title: {
          fontSize: 16,
          marginBottom: 4,
          fontWeight: 500,
          color: "#000000",
        },
        location: {
          marginBottom: 0,
          fontWeight: 400,
          fontSize: 12,
          color: "#797979",
        },
        rightArrow: {
          position: "absolute",
          right: "16px",
          top: "22px",
        },
        rootBorderBlur: {
          position: "relative",
          marginTop: 10,
          boxShadow: "none",
          background: "#FAFAFA",
          border: "2px solid #000000",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "18px 20px",
          minHeight: 78,
          cursor: "pointer",
          opacity: 0.5
        }
      }));
    const dispatch = useDispatch();
    const classes = useStyles();
    const [chosen, setChosen] = useState();
    const [show, setShow] = useState(false);
    const [add, setAdd] = useState(false);
    const searchTerm = useSelector((state) => state?.partnerListing?.searchTerm)
    const isDataLoading = useSelector(
      (state) => state.corpListing.isListingDataLoading
    );
    const setEditDetails = (id) => {
        handleEdit(id);
        setShow(false)
        setAdd(false)
        setChosen(id)
      };
    const setNewDetails = (id) => {
        setAdd(true);
        handleAdd();
        setShow(true)
        // setAddFlowLogo(id)
    };

    useEffect(() => {
        setChosen(partnerList?.results?.[0]?.id)
      }, [partnerList?.results?.[0]?.id]);

    useEffect(() => {
      if(partnerList?.results?.length > 0 || searchTerm){
        setAdd(false)
      }else{
        setAdd(true)
      }
    }, [partnerList]);

    const handleSearch = (val) => {
        dispatch(PartnerAction.setPartnerSearchTerm(val))
        dispatch(PartnerAction.searchPartners(val))
    }
    

  return (
    <div style={{ width: "100%" }}>
      <Row className={classes.row} style={{ alignItems: "center" }}>
        <Col className="w-100">
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div
        className="overflow--scroll"
        style={{ paddingRight: "20px", marginRight: "-20px" }}
      >
        {add && (
          <Card className={true ? classes.rootBorder : classes.root}>
            <h6 className={classes.title}>Adding a new partner</h6>
            <p className={classes.location}>Address</p>
            {true ? (
              <img className={classes.rightArrow} src={rightArrow} />
            ) : null}
          </Card>
        )}
        {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}>
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {partnerList?.results &&
          partnerList?.results?.length > 0 &&
          partnerList?.results?.map((item) => (
            <Card 
            key={item.id} 
            className={item.id === chosen && (!show) ? item.active ? classes.rootBorder : classes.rootBorderBlur : item.active ?classes.root : classes.rootBlur} 
            onClick={() => setEditDetails(item.id)}>
              <h6 className={classes.title}>{item.name}</h6>
              <p className={classes.location}>{item.location}</p>
              {item.id === chosen && (!show) ? <img className={classes.rightArrow} src={rightArrow} /> : null}
            </Card>
          ))}
          {partnerList?.results?.length == 0 ?
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card> 
          : ''}
        </div>
    </div>
  )
}

export default Listing