import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styled from "styled-components";
import GoogleMap from "../GoogleMap/GoogleMap"
import '../../../assets/css/partials/addCorpPackageDetails.scss';
function MapModal({
    open,
    toggleModal,
    handleCloseDone,
    updateLocation,
    editCurrentLocation,
    currentLocation,
    edit
}) {
    const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        min-height: 400px;
        min-width: 900px;
        background-color: #F5F5F5;
    }
    & > .MuiDialog-container > .MuiPaper-root  > .MuiDialogContent-dividers {
        padding: 60px 24px !important;
    }
    
  `;


    const handleClose = () => {
        toggleModal(false);
    };

    return (
        <div className="map-dailog">
            <StyledDialog
                open={open}
                onClose={handleClose} aria-labelledby="customized-dialog-title">
                <DialogTitle className="location-title" id="customized-dialog-title" >
                    Pinning location
                        <i class="material-icons" onClick={handleClose}>close</i>
                </DialogTitle>
                <DialogContent dividers>
                    <GoogleMap getLocation={updateLocation} editLocation={currentLocation} isEdit={edit} editMap={editCurrentLocation} mapOpen={open} closeMap={handleClose}/>
                </DialogContent>
            </StyledDialog>
        </div>
    )
}

export default MapModal
