import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../../common/commonSearchBar'
import MyFamilyFirstBooking from '../../../../common/myFamilyFirstBookings'
import MyFamilyFirstMoreInfoModal from '../../myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../../common/pagination';
import * as AnniversaryAction from '../../../../actions/anniversaryAction'
import ConfirmModal from '../../../wedding/requests/confirmModal';

const Cancelled = ({status}) => {
    
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false);
    const [ moreInfoModalData, setMoreInfoModalData ] = useState({});
    const [page, setPage] = useState(1);
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
       const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    
    const [ id, setId ] = useState(null);

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
    const anniversaryCancelledData = useSelector(state => state.anniversary?.anniversaryCancelled?.results);
    console.log(anniversaryCancelledData);
    const totalPage = useSelector(state => state.anniversary?.anniversaryCancelled?.total_pages);
    const START_DATE = useSelector(state => state.anniversary.startDate);
    const END_DATE = useSelector(state => state.anniversary.endDate);
    const SEARCH_TERM = useSelector(state => state.anniversary.searchTerm);
    const PAGE_NO = useSelector(state => state.anniversary.pageNo);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);

        useEffect(() => {
       
        let value = [];
        anniversaryCancelledData?.map((elem) => {
          if (elem.price) {
            value.push(elem.price)
         
          }
          else{
            value.push(0)
          }
        
        },);
           
        const valueSum=value.reduce((acc,elem)=>{
            return acc+elem
    
        },0)
        console.log(value);
        console.log(valueSum/value?.length);
        setTotalOrderValue(valueSum)
        setAverageOrderValue(Math.round(valueSum/anniversaryCancelledData?.length));
           }, [anniversaryCancelledData]);
    useEffect(() => {
        setPage(1)
      }, [status])
    const handleMoreInfoClick  = (data) => {
        setShowModal(true);
        setMoreInfoModalData(data);
    };
    
    const requestStatusChange = (event, id,data) => {
        setId(id);
        setConfirmedRequestModalData(data)
          let status = event.target.value;
    
          if(status === "confirmed")setRequestModalConfirm(true);
          else{
            dispatch(AnniversaryAction.anniversaryRequestStatusChange(status, id, refereshList))
  
          }
          
      };
      const confirmRequestHandler=()=>{
        
        
        setIsconfirmAmountBool(false)
       if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
        else {
        dispatch(AnniversaryAction.anniversaryRequestStatusChangeConfirm("confirmed", id,confirmedRequestAmount, refereshList))
          setRequestModalConfirm(false)
        }
        // dispatch(confirmRequestInputVal(''));
       
        
    
      }
  
  
      const notToConfirm=()=>{
     
        setRequestModalConfirm(false)
        setIsconfirmAmountBool(false);
      
      }
    const refereshList = () => {
        dispatch(AnniversaryAction.getAnniversaryRequested(1));
        dispatch(AnniversaryAction.getAnniversaryPlanning(1));
        dispatch(AnniversaryAction.getAnniversaryConfirmed(1));
        dispatch(AnniversaryAction.getAnniversaryCancelled(page));
    };

    const handlePagination = (e, value) => {
        setPage(value);
        dispatch(AnniversaryAction.setPageNo(value))
        if (SEARCH_TERM || START_DATE || END_DATE) {
            dispatch(AnniversaryAction.searchAnniversaryRequests(SEARCH_TERM, START_DATE, END_DATE, "cancelled", value));
        } else {
            dispatch(AnniversaryAction.getAnniversaryCancelled(value));
        }
    };

    const handleChange = (event) => {
        if (event.target.value) {
            dispatch(AnniversaryAction.searchAnniversaryRequests(event.target.value, START_DATE, END_DATE, "cancelled", PAGE_NO));
        } else {
            dispatch(AnniversaryAction.getAnniversaryCancelled(1));
            setPage(1);
        }
        dispatch(AnniversaryAction.setSearchTerm(event.target.value))
    };

    const anniversaryCancelledDisplayData = anniversaryCancelledData && anniversaryCancelledData.map(data => (
        <MyFamilyFirstBooking 
          locationsPreferred={data.locations_preferred}
          occasion={data.occasion}
          requestedBy={data.contact_name}
          email={data.contact_email}
          contactNum={data.contact_number}
          messageTitle={data.specific_request ? data.specific_request : 'No Message'}
          specialRequest={data.specific_request? data.specific_request:"N/A"}
          onClick={() => handleMoreInfoClick(data)}
          myFamilyFirstStatusChange={requestStatusChange}
          id={data.id}
          data={data}
          refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
          value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {anniversaryCancelledData && anniversaryCancelledData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
               

  <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageOrderValue}
 </b>
  </span></p>
          {anniversaryCancelledDisplayData}
          <MyFamilyFirstMoreInfoModal
              open={showModal}
              close={() => setShowModal(false)}
              modalData={moreInfoModalData}
              section={"Anniversary Requested"}
              isCancellationMessage={true}
            />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
         {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
        </>
    )
}

export default Cancelled;
