import React, {useState} from 'react';
import StructureDialog from './structureDialog';


const StructureData = ({module,setStructureData,structureData})=>{
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState('add');
     const showAddTestimonialDialog = (e)=>{
        e.preventDefault();
        showDialog(true, 'add');
    };
    const showDialog = (isOpen, type,)=>{
        setIsDialogOpen(isOpen);
        setDialogType(type);
    };
    return (
        <div>
        <div className="row">
            <div className="col">Structure Data </div> 
            <div className="row col justify-content-end">
                <button className="mr-4 px-3 rounded bg-white" style={{border: "1px solid #c4c4c4" }} onClick={showAddTestimonialDialog}>{structureData?.length>0?"Edit":"Add"}</button>
            </div> 
        </div>
        <div className="row mt-4">
            
            <div className="col">
            
                {!structureData?.length>0 &&<div style={{textAlign:'center', color:'#545454'}}>No Structure Data added to this {module === "event" ? 'event' : 'package'}</div>}
            </div>
        </div>
        {isDialogOpen&&<StructureDialog isDialogOpen={isDialogOpen} handleClose={()=>setIsDialogOpen(false)} type={dialogType} module={module}  setStructureData={setStructureData} structureData={structureData}/>}
        </div>
    );
};

export default StructureData;