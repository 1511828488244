import { API } from "../constants/api";

import {
  axiosPostWithToken,
  axiosMediaUpload,
  
} from "../services/apiServices";


export const ADD_POPULAR_DESTINATION="ADD_POPULAR_DESTINATION"
export const GET_MEDIA_URL="GET_MEDIA_URL"
export const EMPTY_URL="EMPTY_URL"
export const GET_POPULAR_DESTIONATIONS="GET_POPULAR_DESTIONATIONS"
export const DELETE_POPULAR_DESTINATIONS="DELETE_POPULAR_DESTINATIONS"
export const STATES_COUNTRYS_LISTS="STATES_COUNTRYS_LISTS"

export const mediaUrl = (file,setVisibleMedia,setLoading) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);
    const token = getState().auth.token;
    axiosMediaUpload(API.HOLIDAY_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        // setVisibleMedia(true)
        // setLoading(false)
        dispatch({
          type: GET_MEDIA_URL,
          payload: res.data.data,
        });
        
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};
export const emptyMedia=()=>{

  return async (dispatch, getState) => {
  dispatch({
    type: EMPTY_URL,
    payload: "",
  });
}

}

export const handleAddNewPopularDestination = (data,handleAddSuccess,refreshReload,handleErrorMsg) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_POPULAR_DESTINAIONS}`, data, token)
      .then((res) => {
        
        if (res.data.status_code === 201) {
          dispatch({
            type: ADD_POPULAR_DESTINATION,
            payload: res.data.data,
          });
          handleAddSuccess()
          refreshReload()
         
        }
        else if (res.data.status_code === 400){
          handleErrorMsg(res.data.data.message)

        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleDeletePopularDestination = (idd,refreshReload) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const requestBody = JSON.stringify({
      id: idd,
    });

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `${token}`, 
      },
      body: requestBody,
    };

    try {
      const response = await fetch(`${API.ADD_POPULAR_DESTINAIONS}`, requestOptions);
      if (response.status === 200) {
        refreshReload()
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const handleStateAndCountryLists = () => {
  

  return async (dispatch, getState) => {
    const token = getState().auth.token;
   

    const requestOptions = {
      
      headers: {
      
        'Authorization': `${token}`, 
      },
     
    
    };

    try {
      const response = await fetch(`${API.STATE_COUNTRY_LISTS}`, requestOptions);
    
    
     
      if (response.status === 200) {
        const data=await response.json()
        
        dispatch({
          type: STATES_COUNTRYS_LISTS,
          payload: data,
        });
        
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const handleUpdatePopularDestination = (data,refreshReload,sucessUpdatePopUp,errorUpdatePopUP) => {
  

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const { id, name, background_image, description,priority ,location_type, title_text,alt_text} = data; // Extract properties from the data object
    const requestBody = JSON.stringify({
      id,
      name,
      background_image,
      description,
      priority,
      location_type,
      title_text,
      alt_text
    });
   

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `${token}`, 
      },
      body: requestBody,
    };

    try {
      const response = await fetch(`${API.ADD_POPULAR_DESTINAIONS}`, requestOptions);
      if (response.status === 200) {
        refreshReload()
        sucessUpdatePopUp()
      }
      else if (response.status === 400){
        errorUpdatePopUP("Bad Request 'please check priority should not exists ' ")
     
      

      } 
      else {
    
      
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const get_popular_destinations = (pageNo,location_type) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = {
      Authorization: `${token}`,
    };

   
    
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    try {
      const response = await fetch(`${API.ADD_POPULAR_DESTINAIONS}?page=${pageNo}&list_per_page=10&sort={"field":"created", "order":"D"}&location_type=${location_type}`, requestOptions);
      if (response.status === 200) {
        const responseData = await response.json();
        dispatch({
          type: GET_POPULAR_DESTIONATIONS,
          payload: responseData.data,
        });
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};



// export const handleUpdatewWebStories = (data,refreshReload) => {
//   return async (dispatch, getState) => {
//     const token = getState().auth.token;
//     axiosPatchWithToken(`${API.UPDATE_WEBSTORIES}`, data, token)
//       .then((res) => {
//         if (res.data.status_code === 201) {
//           dispatch({
//             type: "UPDATE_WEBSTORIES",
//             payload: res.data.data,
//           });
//           refreshReload()

         
//         }
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   };
// };











