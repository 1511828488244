export const countryList = [
    {id: 0, city: 'Afghanistan'},
    {id: 1, city: 'Albania'},
    {id: 2, city: 'Algeria'},
    {id: 3, city: 'American Samoa'},
    {id: 4, city: 'Andorra'},
    {id: 5, city: 'Angola'},
    {id: 6, city: 'Anguilla'},
    {id: 7, city: 'Antarctica'},
    {id: 8, city: 'Antigua and Barbuda'},
    {id: 9, city: 'Argentina'},
    {id: 10, city: 'Armenia'},
    {id: 11, city: 'Aruba'},
    {id: 12, city: 'Australia'},
    {id: 13, city: 'Azerbaijan'},
    {id: 14, city: 'Bahamas (the)'},
    {id: 15, city: 'Bahrain'},
    {id: 16, city: 'Bangladesh'},
    {id: 17, city: 'Barbados'},
    {id: 18, city: 'Belarus'},
    {id: 19, city: 'Belgium'},
    {id: 20, city: 'Belize'},
    {id: 21, city: 'Benin'},
    {id: 22, city: 'Bermuda'},
    {id: 23, city: 'Bhutan'},
    {id: 24, city: 'Bolivia (Plurinational State of)'},
    {id: 25, city: 'Bonaire, Sint Eustatius and Saba'},
    {id: 26, city: 'Bosnia and Herzegovina'},
    {id: 27, city: 'Botswana'},
    {id: 27, city: 'Botswana'},
    {id:28, city: "Bouvet Island"},
	{id:29, city:"Brazil"},
	{id:30, city:"British Indian Ocean Territory (the)"},
	{id:31, city:"Brunei Darussalam"},
	{id:32, city:"Bulgaria"},
	{id:33, city:"Burkina Faso"},
	{id:34, city:"Burundi"},
	{id:35, city:"Cabo Verde"},
	{id:36, city:"Cambodia"},
	{id:37, city:"Cameroon"},
	{id:38, city:"Canada"},
	{id:39, city:"Cayman Islands (the)"},
	{id:40, city:"Central African Republic (the)"},
	{id:41, city:"Chad"},
	{id:42, city:"Chile"},
	{id:43, city:"China"},
	{id:44, city:"Christmas Island"},
	{id:45, city:"Cocos (Keeling) Islands (the)"},
	{id:46, city:"Colombia"},
	{id:47, city:"Comoros (the)"},
	{id:48, city:"Congo (the Democratic Republic of the)"},
	{id:49, city:"Congo (the)"},
	{id:50, city:"Cook Islands (the)"},
	{id:51, city:"Costa Rica"},
	{id:52, city:"Croatia"},
	{id:53, city:"Cuba"},
	{id:54, city:"Curaçao"},
	{id:55, city:"Cyprus"},
	{id:56, city:"Czechia"},
	{id:57, city:"Côte d'Ivoire"},
	{id:58, city:"Denmark"},
	{id:59, city:"Djibouti"},
	{id:60, city:"Dominica"},
	{id:61, city:"Dominican Republic (the)"},
	{id:62, city:"Ecuador"},
	{id:63, city:"Egypt"},
	{id:64, city:"El Salvador"},
	{id:65, city:"Equatorial Guinea"},
	{id:66, city:"Eritrea"},
	{id:67, city:"Estonia"},
	{id:68, city:"Eswatini"},
	{id:69, city:"Ethiopia"},
	{id:70, city:"Falkland Islands (the) [Malvinas]"},
	{id:71, city:"Faroe Islands (the)"},
	{id:72, city:"Fiji"},
	{id:73, city:"Finland"},
	{id:74, city:"France"},
	{id:75, city:"French Guiana"},
	{id:76, city:"French Polynesia"},
	{id:77, city:"French Southern Territories (the)"},
	{id:78, city:"Gabon"},
	{id:79, city:"Gambia (the)"},
	{id:80, city:"Georgia"},
	{id:81, city:"Germany"},
	{id:82, city:"Ghana"},
	{id:83, city:"Gibraltar"},
	{id:84, city:"Greece"},
	{id:85, city:"Greenland"},
	{id:86, city:"Grenada"},
	{id:87, city:"Guadeloupe"},
	{id:88, city:"Guam"},
	{id:89, city:"Guatemala"},
	{id:90, city:"Guernsey"},
	{id:91, city:"Guinea"},
	{id:92, city:"Guinea-Bissau"},
	{id:93, city:"Guyana"},
	{id:94, city:"Haiti"},
	{id:95, city:"Heard Island and McDonald Islands"},
	{id:96, city:"Holy See (the)"},
	{id:97, city:"Honduras"},
	{id:98, city:"Hong Kong"},
	{id:99, city:"Hungary"},
	{id:100, city:"Iceland"},
	{id:101, city:"India"},
	{id:102, city:"Indonesia"},
	{id:103, city:"Iran (Islamic Republic of)"},
	{id:104, city:"Iraq"},
	{id:105, city:"Ireland"},
	{id:106, city:"Isle of Man"},
	{id:107, city:"Israel"},
	{id:108, city:"Italy"},
	{id:109, city:"Jamaica"},
	{id:110, city:"Japan"},
	{id:111, city:"Jersey"},
	{id:112, city:"Jordan"},
	{id:113, city:"Kazakhstan"},
	{id:114, city:"Kenya"},
	{id:115, city:"Kiribati"},
	{id:116, city:"Korea (the Democratic People's Republic of)"},
	{id:117, city:"Korea (the Republic of)"},
	{id:118, city:"Kuwait"},
	{id:119, city:"Kyrgyzstan"},
	{id:120, city:"Lao People's Democratic Republic (the)"},
	{id:121, city:"Latvia"},
	{id:122, city:"Lebanon"},
	{id:123, city:"Lesotho"},
	{id:124, city:"Liberia"},
	{id:125, city:"Libya"},
	{id:126, city:"Liechtenstein"},
	{id:127, city:"Lithuania"},
	{id:128, city:"Luxembourg"},
	{id:129, city:"Macao"},
	{id:130, city:"Madagascar"},
	{id:131, city:"Malawi"},
	{id:132, city:"Malaysia"},
	{id:133, city:"Maldives"},
	{id:134, city:"Mali"},
	{id:135, city:"Malta"},
	{id:136, city:"Marshall Islands (the)"},
	{id:137, city:"Martinique"},
	{id:138, city:"Mauritania"},
	{id:139, city:"Mauritius"},
	{id:140, city:"Mayotte"},
	{id:141, city:"Mexico"},
	{id:142, city:"Micronesia (Federated States of)"},
	{id:143, city:"Moldova (the Republic of)"},
	{id:144, city:"Monaco"},
	{id:145, city:"Mongolia"},
	{id:146, city:"Montenegro"},
	{id:147, city:"Montserrat"},
	{id:148, city:"Morocco"},
	{id:149, city:"Mozambique"},
	{id:150, city:"Myanmar"},
	{id:151, city:"Namibia"},
	{id:152, city:"Nauru"},
	{id:153, city:"Nepal"},
	{id:154, city:"Netherlands (the)"},
	{id:155, city:"New Caledonia"},
	{id:156, city:"New Zealand"},
	{id:157, city:"Nicaragua"},
	{id:158, city:"Niger (the)"},
	{id:159, city:"Nigeria"},
	{id:160, city:"Niue"},
	{id:161, city:"Norfolk Island"},
	{id:162, city:"Northern Mariana Islands (the)"},
	{id:163, city:"Norway"},
	{id:164, city:"Oman"},
	{id:165, city:"Pakistan"},
	{id:166, city:"Palau"},
	{id:167, city:"Palestine, State of"},
	{id:168, city:"Panama"},
	{id:169, city:"Papua New Guinea"},
	{id:170, city:"Paraguay"},
	{id:171, city:"Peru"},
	{id:172, city:"Philippines (the)"},
	{id:173, city:"Pitcairn"},
	{id:174, city:"Poland"},
	{id:175, city:"Portugal"},
	{id:176, city:"Puerto Rico"},
	{id:177, city:"Qatar"},
	{id:178, city:"Republic of North Macedonia"},
	{id:179, city:"Romania"},
	{id:180, city:"Russian Federation (the)"},
	{id:181, city:"Rwanda"},
	{id:182, city:"Réunion"},
	{id:183, city:"Saint Barthélemy"},
	{id:184, city:"Saint Helena, Ascension and Tristan da Cunha"},
    { id:185, city:"Saint Kitts and Nevis"},
    {id:186, city:"Saint Lucia"},
    {id:187, city:"Saint Martin (French part)"},
	{id:188, city:"Saint Pierre and Miquelon"},
	{id:189, city:"Saint Vincent and the Grenadines"},
	{id:190, city:"Samoa"},
	{id:191, city:"San Marino"},
	{id:192, city:"Sao Tome and Principe"},
	{id:193, city:"Saudi Arabia"},
	{id:194, city:"Senegal"},
	{id:195, city:"Serbia"},
	{id:196, city:"Seychelles"},
	{id:197, city:"Sierra Leone"},
	{id:198, city:"Singapore"},
	{id:199, city:"Sint Maarten (Dutch part)"},
	{id:200, city:"Slovakia"},
	{id:201, city:"Slovenia"},
	{id:202, city:"Solomon Islands"},
	{id:203, city:"Somalia"},
	{id:204, city:"South Africa"},
	{id:205, city:"South Georgia and the South Sandwich Islands"},
    {id:206, city:"South Sudan"},
	{id:207, city:"Spain"},
	{id:208, city:"Sri Lanka"},
	{id:209, city:"Sudan (the)"},
	{id:210, city:"Suriname"},
	{id:211, city:"Svalbard and Jan Mayen"},
	{id:212, city:"Sweden"},
	{id:213, city:"Switzerland"},
	{id:214, city:"Syrian Arab Republic"},
	{id:215, city:"Taiwan"},
	{id:216, city:"Tajikistan"},
	{id:217, city:"Tanzania, United Republic of"},
	{id:218, city:"Thailand"},
	{id:219, city:"Timor-Leste"},
	{id:220, city:"Togo"},
	{id:221, city:"Tokelau"},
	{id:222, city:"Tonga"},
	{id:223, city:"Trinidad and Tobago"},
	{id:224, city:"Tunisia"},
	{id:225, city:"Turkey"},
	{id:226, city:"Turkmenistan"},
	{id:227, city:"Turks and Caicos Islands (the)"},
	{id:228, city:"Tuvalu"},
	{id:229, city:"Uganda"},
	{id:230, city:"Ukraine"},
	{id:231, city:"United Arab Emirates (the)"},
	{id:232, city:"United Kingdom of Great Britain and Northern Ireland (the)"},
	{id:234, city:"United States Minor Outlying Islands (the)"},
	{id:235, city:"United States of America (the)"},
	{id:236, city:"Uruguay"},
	{id:237, city:"Uzbekistan"},
	{id:238, city:"Vanuatu"},
	{id:239, city:"Venezuela (Bolivarian Republic of)"},
	{id:240, city:"Viet Nam"},
	{id:241, city:"Virgin Islands (British)"},
	{id:242, city:"Virgin Islands (U.S.)"},
	{id:243, city:"Wallis and Futuna"},
	{id:244, city:"Western Sahara"},
	{id:245, city:"Yemen"},
	{id:246, city:"Zambia"},
	{id:247, city:"Zimbabwe"},
	{id:248, city:"Åland Islands"}
	
];

