
import { useState, useEffect } from 'react';
import { Row, Col } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import AnniversaryStoryListing from './anniversaryStoryListing';
import AddAnniversaryStory from './addAnniversaryStory';
import EditAnniversaryStory from './editAnniversaryStory';
import { useDispatch, useSelector } from 'react-redux';
import * as AnniversaryAction from "../../../../actions/anniversaryAction";
import { Spinner } from 'react-bootstrap';

const Index = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [isAdd, setIsAdd] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [searchVal,setSearchVal]=useState('')
    const storyList = useSelector((state) => state.anniversary.anniversaryStoryList);
    const storyDetails = useSelector((state) => state.anniversary?.anniversaryStoryDetails);
    const isDataLoading = useSelector(
        (state) => state.holidayListing.isDataLoading
      );
    useEffect(() => {
        dispatch(AnniversaryAction.anniversaryStoryList());
    }, []);
console.log(storyList, "storyList")
    useEffect(() => {
        selectedId && dispatch(AnniversaryAction.anniversaryStoryDetails(selectedId));
    }, [selectedId]);

    useEffect(() => {
        storyList?.length && setSelectedId(storyList[0].id);//selectedId || 
        if (storyList?.length) {
            setSelectedId(storyList[0].id);
            setIsAdd(false)
        } else {
            setSelectedId(null);
            setIsAdd(true)
        }
    }, [storyList]);

    return (
        <div>
            <p className="title-holder">Anniversary Stories</p>
            <div>
                <Row>
                    <Col style={{ paddingRight: '20px', marginRight: '-20px' }} className="gutter-row" xs={24} lg={8}>
                        <Row className="gutter-row">

                            <AnniversaryStoryListing
                                storyList={storyList}
                                setIsAdd={setIsAdd}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                searchVal={searchVal}
                                setSearchVal={setSearchVal}
                            />
                        </Row>

                    </Col>
                    <Col className="gutter-row" xs={24} lg={16}>
                    {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
                        {isAdd ? <AddAnniversaryStory setIsAdd={setIsAdd} /> : <EditAnniversaryStory storyDetails={storyDetails} setSearchVal={setSearchVal} storyDetailsId={selectedId} />}

                    </Col>
                </Row>
            </div>
        </div>
    ); 
}

export default Index;