import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import { Row, Col } from 'antd';
import logo from '../../assets/images/logo.svg';
import '../../assets/css/partials/login.scss';
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom';

import * as authAction from "../../actions/authAction";
import * as commonAction from "../../actions/commonAction";

function Login() {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const history = useHistory()

  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  const [login, setLogin] = useState(true);

  let eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  
  const handleChange = (key) => (event) => {
    setState({ ...state, [key]: event.target.value });
  };

  const emailValidate = (e) => {
    setEmail(e.target.value);
    setIsTrue(false);
  };

  const loginHandler = () => {
    if (state.email.length === 0) {
      enqueueSnackbar("Please enter email id", {
        variant: "error",
      });
      return;
    }
    else if (eml.test(state.email) === false) {
      enqueueSnackbar("Please enter a valid email id", {
        variant: "error",
      });
      return;
    }
    else if (state.password.length === 0) {
      enqueueSnackbar("password cannot be empty", {
        variant: "error",
      });
      return;
    }
    else {
      dispatch(authAction.login(state.email, state.password, redirectUser, handleErrors));
    }

  };

  const redirectUser = () => {
    history.push('/holidays/listings')
    window.location.reload(false);
    enqueueSnackbar("Logged in successfully", {
      variant: "success",
    });
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  return (
    <div>
      <Row>
        <Col className="gutter-row" xs={24} lg={12}>
          <div className="admin-holder">
            <div className="logo-holder">
              <div>
                <img src={logo} alt="" style={{width:"60%"}}/>
              </div>
              <p>Hammock Leisure Holidays</p>
              <h1>Admin dashboard</h1>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} lg={12}>
          <div className="login-holder">
            <div className="form-holder">
              <form>
                <div className="form-group">
                  <label for="">User ID</label>
                  <input type="text" className="form-control" placeholder="User ID" onChange={handleChange("email")} />
                </div>
                <div className="form-group">
                  <label for="">Password</label>
                  <input type="password" className="form-control" placeholder="Password" onChange={handleChange("password")} />
                </div>
                <div className="form-group text-center">
                  <button type="button" className="btn btn-primary" onClick={loginHandler}>LOGIN</button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
