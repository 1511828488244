import React, { useState, useEffect } from 'react'
import Sections from './sections';
import { useSelector, useDispatch } from 'react-redux'
import AddNewSection from '../../../common/addNewSection';
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import { DONE, CANCEL } from '../../../utils/constants'
import CommonAddNew from '../../../common/commonAddNew';
import { eventSelectedImageUrl, imageUpload, addEventSection, getEventSection } from '../../../actions/eventSectionAction';
import { useSnackbar } from "notistack";

const Index = () => {
  const [ showEventAddNewSectionModal, setShowEventAddNewSectionModal ] = useState(false);
  const [ eventSectionName, setEventSectionName ] = useState("");
  const [ eventSectionDesc, setEventSectionDesc ] = useState("");
  const [ eventSectionPriority, setEventSectionPriority ] = useState("");
  const [refCodeValue, setRefCodeValue] = useState("");

  const [ eventSectionPriorityBool, setEventSectionPriorityBool ] = useState(false);
  const [ eventSectionNameBool, setEventSectionNameBool ] = useState(null);
  const [ eventSectionDescBool, setEventSectionDescBool ] = useState(null);
  const [ eventSectionImageBool, setEventSectionImageBool ] = useState(null);
  const [ eventSectionPriorityEmptyBool, setEventSectionPriorityEmptyBool ] = useState(null);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ descMoreError, setDescMoreErrorr ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
   
  const eventSectionData = useSelector(state => state.eventSection?.getEventSection);
  const eventSelectedImgUrl = useSelector(state => state.eventSection.eventSelectedImageUrl);

  useEffect(() => {
    if(refCodeValue.length === 2){
      setCodeLessErrorr(false);
      setCodeMoreError(false);
    }
   }, [refCodeValue]);

  const updatePic = (data) => {
    dispatch(eventSelectedImageUrl(data.url));
  };

  useEffect(() => {
    dispatch(getEventSection());
  }, [eventSelectedImgUrl]);

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(imageUpload(croppedFile, updatePic));
    }
  };

  const addedSuccessHandle = () => {
    enqueueSnackbar("Added successfully", {
      variant: "success",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEventSectionImageBool(false);
    setEventSectionNameBool(false);
    setEventSectionDescBool(false);
    setEventSectionPriorityBool(false);
    setEventSectionPriorityEmptyBool(false);
    setIscodeError(false);
    setCodeLessErrorr(false);
    setCodeMoreError(false)
    const eventSectionSubmitData = {
      "image":eventSelectedImgUrl,
      "name":eventSectionName,
      "description":eventSectionDesc,
      "priority":eventSectionPriority,
      "code":refCodeValue
    }
    if(eventSelectedImgUrl === null)setEventSectionImageBool(true);
    else if(eventSectionName === "")setEventSectionNameBool(true);
    else if(eventSectionDesc === "")setEventSectionDescBool(true);
    else if(eventSectionDesc.length > 200)setDescMoreErrorr(true)
    else if(eventSectionPriority === "")setEventSectionPriorityEmptyBool(true);
    else if(eventSectionPriority <= -1)setEventSectionPriorityBool(true);
    else if(refCodeValue === "")setIscodeError(true)
    else if(refCodeValue.length < 2)setCodeLessErrorr(true)
    else if(refCodeValue.length > 2)setCodeMoreError(true)
    else if(refCodeValue.length == 2 && codeMatchError)setCodeMatchError(true)
    else{
      dispatch(addEventSection(eventSectionSubmitData, refreshAddEventSectionData,addedSuccessHandle));
      dispatch(eventSelectedImageUrl(null));
      setShowEventAddNewSectionModal(false)
      setEventSectionName("");
      setEventSectionDesc("");
      setDescMoreErrorr(false)
      setEventSectionPriority("");
      setRefCodeValue("");
      dispatch(getEventSection());
    }
  };
 
  const refreshAddEventSectionData = () => {
    dispatch(getEventSection());
  };

  const eventAddNewSectionModalOpenHandler = () => {
    setShowEventAddNewSectionModal(true);
  };

  const closeEventAddNewSectionModal = () => {
    setShowEventAddNewSectionModal(false)
  };

  const handleSectionPriority = (e) => {
    setEventSectionPriorityBool(false);
    setEventSectionPriority(e.target.value);
    if(e.target.value <= -1) setEventSectionPriorityBool(true);
  };
  
  const refrenceCodeHandler = (e) => {
    // setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredData = eventSectionData.filter(item => item.code === code)
    if(filteredData.length){
      setCodeMatchError(true)
    }else{
      setCodeMatchError(false)
    }
    setRefCodeValue(code)
    // eventSectionData.map(item => {
    //   if(code !== '' && item.code === code)setCodeMatchError(true)
    //   else {
    //     setRefCodeValue(code);
    //     // setCodeMatchError(false)
    //   }
    // })
  };

  const handleEventSectionName = (e) => {
    setEventSectionPriority(e.target.value);
  }
  return (
    <>
      <h2> {eventSectionData.length} Events Sections</h2>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop:"30px"}}>
      <Sections/>
        <CommonAddNew 
          onClick={eventAddNewSectionModalOpenHandler}
          textTitle="Add New Section"
        />
      </div>
      {showEventAddNewSectionModal && (
        <AddNewSection
          showMyModal={showEventAddNewSectionModal}
          openHandler={showEventAddNewSectionModal}
          togglebackDrop={showEventAddNewSectionModal}
          heading="Add new Section"
          image={ eventSelectedImgUrl !== null ?  eventSelectedImgUrl : DefaultImage}
          imageText="Image"
          labelTextOne="Section name"
          labelTextThree="Description"
          labelTextTwo="Priority"
          codeRefrence="Code"
          btnTextOne={CANCEL}
          btnTextTwo={DONE}
          valueOne={eventSectionName}
          valueThree={eventSectionDesc}
          // onChangeOne={(e) => handleEventSectionName(e)}
          onChangeOne={(e) => setEventSectionName(e.target.value)}
          onChangeThree={(e) => setEventSectionDesc(e.target.value)}
          valueTwo={eventSectionPriority}
          onChangeTwo={(e) => handleSectionPriority(e)}
          refCodeValue={refCodeValue}
          refrenceCodeHandler={refrenceCodeHandler}
          handleSubmit={handleSubmit}
          closeModal={closeEventAddNewSectionModal}
          closeHandle={() => setShowEventAddNewSectionModal(false)}
          isEventsAddNewSection={true}
          isEventsUpdateSection={false}
          handleFilePickChange={handleImageChange}
          isEventSection={true}
          isPriorityError={eventSectionPriorityBool}
          isSectionNameError={eventSectionNameBool}
          isSectionDescError={eventSectionDescBool}
          isImage={eventSectionImageBool}
          isPriorityEmptyError={eventSectionPriorityEmptyBool}
          iscodeError={iscodeError}
          codeLessError={codeLessError}
          codeMoreError={codeMoreError}
          codeMatchError={codeMatchError}
          descMoreError={descMoreError}
          isDescription={true}
        />
      )}
    </>
  );
};

export default Index;
