import { 
    ANNIVERSARY_STORY_LIST,
    ANNIVERSARY_STORY_DETAILS,
    ANNIVERSARY_REQUESTED,
    ANNIVERSARY_PLANNING,
    ANNIVERSARY_CONFIRMED,
    ANNIVERSARY_CANCELLED,
    ANNIVERSARY_SEARCH_TERM,
    ANNIVERSARY_START_DATE,
    ANNIVERSARY_END_DATE,
    ANNIVERSARY_PAGE_NO,
    ANNIVERSARY_DESTINATION_LIST,
  } 
  from '../actions/types';

const initialState = {
  anniversaryStoryDetails: null,
  anniversaryStoryList: [],
  anniversaryRequested: [],
  anniversaryPlanning: [],
  anniversaryConfirmed: [],
  anniversaryCancelled: [],
  searchTerm: null,
  startDate: null,
  endDate: null,
  pageNo: 1,
  destinationList: []
};

const anniversaryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        
        case ANNIVERSARY_STORY_LIST:{
          console.log(payload);
            return {
              ...state,
              anniversaryStoryList: payload
            }
        }
        case ANNIVERSARY_STORY_DETAILS:{
          return {
            ...state,
            anniversaryStoryDetails: payload
          }
        }
        case ANNIVERSARY_REQUESTED:{
          return {
            ...state,
            anniversaryRequested: payload
          }
        }
        case ANNIVERSARY_PLANNING:{
          return {
            ...state,
            anniversaryPlanning: payload
          }
        }
        case ANNIVERSARY_CONFIRMED:{
          return {
            ...state,
            anniversaryConfirmed: payload
          }
        }
        case ANNIVERSARY_CANCELLED:{
          return {
            ...state,
            anniversaryCancelled: payload
          }
        }
        case ANNIVERSARY_SEARCH_TERM:{
          return {
            ...state,
            searchTerm: payload
          }
        }
        case ANNIVERSARY_START_DATE:{
          return {
            ...state,
            startDate: payload
          }
        }
        case ANNIVERSARY_END_DATE:{
          return {
            ...state,
            endDate: payload
          }
        }
        case ANNIVERSARY_PAGE_NO:{
          return {
            ...state,
            pageNo: payload
          }
        }
        case ANNIVERSARY_DESTINATION_LIST:{
          return {
            ...state,
            destinationList: payload
          }
        }
        
        default:
           return state;
    }
};

export default anniversaryReducer;
