import { 
    WEDDING_STORY_LIST,
    WEDDING_STORY_DETAILS,
    WEDDING_REQUESTED,
    WEDDING_PLANNING,
    WEDDING_CONFIRMED,
    WEDDING_CANCELLED,
    WEDDING_SEARCH_TERM,
    WEDDING_START_DATE,
    WEDDING_END_DATE,
    WEDDING_PAGE_NO,
    WEDDING_DESTINATION_LIST,
  } 
  from '../actions/types';

const initialState = {
  weddingStoryDetails: null,
  weddingStoryList: [],
  weddingRequested: [],
  weddingPlanning: [],
  weddingConfirmed: [],
  weddingCancelled: [],
  searchTerm: null,
  startDate: null,
  endDate: null,
  pageNo: 1,
  destinationList: []
};

const weddingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        
        case WEDDING_STORY_LIST:{
          console.log(payload);
            return {
              ...state,
              weddingStoryList: payload
            }
        }
        case WEDDING_STORY_DETAILS:{
          return {
            ...state,
            weddingStoryDetails: payload
          }
        }
        case WEDDING_REQUESTED:{
          return {
            ...state,
            weddingRequested: payload
          }
        }
        case WEDDING_PLANNING:{
          return {
            ...state,
            weddingPlanning: payload
          }
        }
        case WEDDING_CONFIRMED:{
          return {
            ...state,
            weddingConfirmed: payload
          }
        }
        case WEDDING_CANCELLED:{
          return {
            ...state,
            weddingCancelled: payload
          }
        }
        case WEDDING_SEARCH_TERM:{
          return {
            ...state,
            searchTerm: payload
          }
        }
        case WEDDING_START_DATE:{
          return {
            ...state,
            startDate: payload
          }
        }
        case WEDDING_END_DATE:{
          return {
            ...state,
            endDate: payload
          }
        }
        case WEDDING_PAGE_NO:{
          return {
            ...state,
            pageNo: payload
          }
        }
        case WEDDING_DESTINATION_LIST:{
          return {
            ...state,
            destinationList: payload
          }
        }
        
        default:
           return state;
    }
};

export default weddingReducer;
