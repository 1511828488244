import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import Edit from "../../../assets/images/edit.svg";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import ArrowUp from "../../../assets/images/upArrow.svg";
import "../../../assets/css/partials/packageDetails.scss";
import { makeStyles } from "@material-ui/core/styles";
import { FaFileUpload } from "react-icons/fa";
import JoditEditor from "jodit-react";
import PublishIcon from "@material-ui/icons/Publish";
import ImageMultiSelect from "../../utilities/ImageMultiSelect/ImageMultiSelect";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import * as corpListingAction from "../../../actions/corpListingAction";
import Popup from "../../popup/popup";
import EmployeeListing from "./EmployeeList";
import { useSnackbar } from "notistack";
import {
  ORGANISATION_NAME, ORGANISATION_NAME_LENGTH, ADDRESS, ORGANISATION_DOMAIN, LOGO
} from '../../../utils/constants'


// import * as ListAction from "../../../actions/corpListingAction";
import * as AccountAction from "../../../actions/accountsAction";
import Pagination from '@material-ui/lab/Pagination';
// import * as corpListingAction from "../../../actions/corpListingAction";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  showHide: {
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
}));

export default function AccountEditDetails({ page, onResetpage}) {
  // const addImage = useSelector((state) => state.corpListing.allImagesData);
  const addImageUrl = useSelector(state => state.corpListing.addImage)
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar(); 

  const accountInfo = useSelector(
    (state) => state.accountListing.accountDetails
  );

  const accountUpdateInfo = useSelector(
    (state) => state.accountListing.updateAccount
  );

  const alertMessage = useSelector(state => state.accountListing.errorAlert)
  const searchBoolVal = useSelector(state => state.accountListing.searchBool)

  const [currentPackage, setCurrentPackage] = useState({ accountInfo });
  // const [imageArray, setImageArray] = useState(images);
  const [showOrgDetails, setShowOrgDetails] = useState(false)
  const [isOrgName, setIsOrgName] = useState(null);
  const [isOrgNameLength, setIsOrgNameLength] = useState(null);
  const [isLogoValidation, setLogoValidation] = useState(null);
  const [isAddress, setIsAddress] = useState(null);
  const [isOrgDomain, setIsOrgDomain] = useState(null);
  const [empListPage, setEmpListPage] = useState(1);
  const [empSearchPage, setEmpSearchPage] = useState(1);

  const [isShow, setIsShow] = useState(false);
  const [showArrowUp, setShowArrowUp] = useState(false)
  const [showArrowDown, setShowArrowDown] = useState(false)


  useEffect(() => {
    setCurrentPackage(accountInfo);
    setEmpListPage(1)
    setEmpSearchPage(1)
  }, [accountInfo]);

  useEffect(() => {
    if(accountInfo?.id){
    const org_id = accountInfo?.id;
    dispatch(AccountAction.getEmployeeList(org_id, empListPage))
    }
  }, [accountInfo,empListPage])

  const getEmployeeList = useSelector(state => state.accountListing?.employeeList?.data)
  const accountSearchDetails = useSelector((state) => state.accountListing.searchUserData.data);
  const searchValue = useSelector(state => state.accountListing?.searchValue)
  const orgSearchTerm = useSelector(state => state.accountListing?.org_search_term)
  // useEffect(() => {
  //   const org_id = accountInfo?.id;
  //   dispatch(AccountAction.getEmployeeList(org_id, empListPage))
  // }, [empListPage])

  useEffect(() => {
    if(searchValue){
    const org_id = accountInfo?.id;
    dispatch(AccountAction.searchUser(searchValue, org_id, empSearchPage))
    }
  }, [empSearchPage])

  // const handleEdit = (id) => {
  //   dispatch(AccountAction.getAccountInfo(id));
  //   dispatch(AccountAction.setAddAccount(false));
  // };
  const handleUpdate = () => {
    
    setIsOrgName(false)
    setIsOrgNameLength(false)
    setIsAddress(false)
    setLogoValidation(false)
    setIsOrgDomain(false)
    if (currentPackage.name === '' || currentPackage.name === null) setIsOrgName(true)
    else if(currentPackage.name.length>50)setIsOrgNameLength(true)
    // else if (!addImageUrl?.data?.url) setLogoValidation(true)
    else if (currentPackage.location === '' || currentPackage.location === null) setIsAddress(true)
    else if(currentPackage.email_domain ==='' || !/^[A-Z0-9._%+-]+\.[A-Z]{2,3}$/i.test(currentPackage.email_domain))setIsOrgDomain(true)
    else {
      setCurrentPackage(() => ({
        ...currentPackage,
        logo: addImageUrl.data?.url
      }))
      dispatch(
        AccountAction.updateAccountInformation(currentPackage, handleRefreshList, handleError, handleSuccess)
      )
    }
    // setCurrentPackage(() => ({
    //   ...currentPackage,
    //   logo: addImageUrl.data?.url
    // }))
    // dispatch(
    //   AccountAction.updateAccountInformation(currentPackage, handleRefreshList, handleError, handleSuccess)
    // )
  };

  const handleSuccess = () => {
    enqueueSnackbar('Updated Successfully', {
      variant: "success",
    });
  }
  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  }
  const handleRefreshList = (id) => {
    if(orgSearchTerm){
      dispatch(AccountAction.searchOrganisation(orgSearchTerm))
    }else{
      dispatch(AccountAction.accountsList(handleLoader, page))
    }
    dispatch(AccountAction.getAccountInfo(id))
    // dispatch(AccountAction.getEmployeeList(id))
    // onResetpage()
    
  };
  const handleLoader = () => {
    
  };


  const handleChangeValue = (key, value) => {
    // if (value.target.value.match("^[a-zA-Z ]*$") != null) {}
    setCurrentPackage(() => ({
      ...currentPackage,
      [key]: value.target.value,
    }));

  };

  const inputFile = useRef(null);

  const handleAddNewImage = () => {
    inputFile.current.click();
  };
  const addImages = (url) => {
    setCurrentPackage(prevState => ({
      ...prevState,
      logo: url.url
    }))
  }
  


  const handleFilePickChange = (event) => {
    const file = event.target.files[0];

    event.target.value = "";
    //upload file and replace reponse_url for url from response
    dispatch(corpListingAction.imageUpload(file, addImages))
  };

  const dispatch = useDispatch();

  const showHide = () => {
    if (showOrgDetails === true) {
      setShowOrgDetails(false)
    } else {
      setShowOrgDetails(true)
    }

  }

  return (
    <div>
      <div className="packageDetails-holder">
        <form>
          <div className="form-options">
            <div>
              <h2>Editing Organisation</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-save"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: "relative", padding: 0 }} className="form-group form-custom">
              <img src={currentPackage?.logo} style={{ height: '100%', width: '100%', borderRadius: '5px' }} />
              {/*<img src={currentPackage?.logo? currentPackage?.logo : addImageUrl.data?.url} style={{ height: '68px', width: '97px' }} />*/}
              <div className={classes.showHide}>
                <div
                  onClick={handleAddNewImage}
                  style={{
                    // display: "flex",
                    // display: 'none',
                    padding: "6px 10px",
                    border: "1px solid white",
                    borderRadius: 8,
                    color: "#fff",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    cursor: "pointer",
                    position: 'absolute',
                    left: '20px',
                    bottom: '31px',
                    width: 'fit-content', marginLeft: 10,
                  }}
                >
                  <PublishIcon fontSize="small" />
                  <h7 style={{ fontWeight: "bold", marginLeft: 4 }}></h7>

                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFilePickChange}
                  // onChange={onSelectFile}
                  />
                </div>
                {isLogoValidation && <p style={{color:"red", width: 'max-content'}}>{LOGO}</p>}
              </div>
            </div>
            <div
              style={{ flex: 2, marginLeft: 10, position: "relative" }}
              className="form-group form-custom"
            >
              <label for="">Organisaton Name</label>
              {/*<h6 className={classes.title}>{getAccountDetails.data?.name}</h6>*/}
              <input
                value={currentPackage && currentPackage?.name}
                type="text"
                className="form-control"
                onChange={(value) => handleChangeValue("name", value)}
              />
              {isOrgName ? <p style={{color:"red"}}>{ORGANISATION_NAME}</p>
              : isOrgNameLength ? <p style={{color:"red"}}>{ORGANISATION_NAME_LENGTH}</p> : ''
            }
              {/* <img
                src={Edit}
                style={{ position: "absolute", right: "35px", top: "54px", }}
             />*/}
            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
              className="form-group form-custom"
            >
              <div onClick={showHide}>
                {showOrgDetails ?
                  (<img
                    src={ArrowUp}
                    style={{ position: "absolute", right: "58px", bottom: "46px", cursor: "pointer" }}
                  // onClick={showHide}
                  />) :
                  (
                    <img
                      src={ArrowDown}
                      style={{ position: "absolute", right: "58px", bottom: "46px", cursor: "pointer" }}
                    // onClick={showHide}
                    />)}
              </div>
            </div>
          </div>
          {!showOrgDetails &&
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{ flex: 0.5, position: "relative" }}
                className="form-group form-custom"
              >
                <label for="">Address</label>
                {/*<h6 className={classes.title}>{getAccountDetails.data?.location}</h6>*/}
                <input
                  value={currentPackage?.location}
                  type="text"
                  className="form-control"
                  onChange={(value) => handleChangeValue("location", value)}
                />
                {isAddress && <p style={{color:"red"}}>{ADDRESS}</p>}
                {/*<img
                  src={Edit}
                  style={{ position: "absolute", right: "35px", top: "54px" }}
                />*/}
              </div>
              <div
                style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                className="form-group form-custom"
              >
                <div style={{ position: 'relative' }}>
                  <label for="">Organisation Domain</label>
                  {/*<h6 className={classes.title}>{getAccountDetails.data?.email_domain}</h6>*/}
                  <h6 style={{ position: 'absolute', left: '10px', top: '40px', fontWeight: '400', opacity: 0.4 }}>employee@</h6>
                  <input style={{ paddingLeft: '101px' }}
                    value={currentPackage?.email_domain}
                    type="text"
                    className="form-control"
                    onChange={(value) => handleChangeValue("email_domain", value)}
                  />
                  {isOrgDomain && <p style={{color:"red"}}>{ORGANISATION_DOMAIN}</p>}
                  

                  {/* <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "40px" }}
                 />*/}
                </div>
              </div>
            </div>
          }
          <hr />
          <div >
            <EmployeeListing 
            page={page}
            getEmployeeList={getEmployeeList}
            empListPage={empListPage}
            // handleEdit={handleEdit} 
            />
            {searchBoolVal ?
            <Pagination 
            page={empSearchPage} 
            variant="outlined" 
            shape="rounded" 
            count={accountSearchDetails?.total_pages} 
            boundryCount={2}
            onChange={(event, value) => setEmpSearchPage(value)}
            
            /> : 
            <Pagination 
            page={empListPage} 
            variant="outlined" 
            shape="rounded" 
            count={getEmployeeList?.total_pages} 
            boundryCount={2}
            onChange={(event, value) => setEmpListPage(value)}
            
            />}
          </div>
        </form>
      </div>
    </div>
  );
}
