import React,{ useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar'
import { holidaysConcelledStatusValues } from '../../../resources/status';
import { getHolidayBookingList, holidayBookingsSearch, holidayBookingStatusUpdate, pageId, holidayBookingSearchValue} from '../../../actions/holidayBookingAction';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index';

const Cancelled = () => {

    const [ showMoreInfo, setShowMoreInfo ] = useState(false);
    const [ moreInfoData, setMoreInfoData ] = useState({});
    const[averageBookingAmount,setAverageBookingAmount]=useState()
    const[totalbookingAmount,setTotalbookingAmount]=useState()
    // const [ searchVal, setSearchVal ] = useState('');
    const [ pageVal, setPageVal ] = useState(1);

    const holidayCancelled = useSelector(state => state.holidayBooking.holidayCancelled.results);
    console.log(holidayCancelled);
    const totapPage = useSelector(state => state.holidayBooking.holidayCancelled.total_pages);
    const pageNum = useSelector(state => state.holidayBooking.page);
    const sectionID = useSelector(state => state.holidayBooking.bookingsSecId);
    const holidayCancelledNxtPgeId = useSelector(state => state.holidayBooking.holidayCancelledNxtPgeId);
    const holidayCancelledCurrentPgeId = useSelector(state => state.holidayBooking.holidayCancelledCurrentPgeId);
    const searchVal = useSelector(state => state.holidayBooking.holidayCancelledSearchVal);
    const ffStartDate = useSelector((state) => state.holidayBooking.holidayBookingStartDate);
    const ffEndDate = useSelector((state) => state.holidayBooking.holidayookingEndDate);
    const dispatch = useDispatch();
    useEffect(()=>{
        const data=holidayCancelled?.map((elem)=>{
            return (parseInt(elem.amount_paid==0?elem.amount==null?0:elem.amount:elem.amount_paid))
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(Math.round(totalamount/holidayCancelled?.length))

    },[holidayCancelled])
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMoreInfo]);

    useEffect(() => {
        if(holidayCancelledNxtPgeId === null && holidayCancelledCurrentPgeId > 1 && holidayCancelled?.length === 0) {
            dispatch(getHolidayBookingList(1, sectionID, "cancelled"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [holidayCancelled, pageVal])

    const handlePagination = (e, value) => {
        setPageVal(value);
        if(searchVal !== "" || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayBookingsSearch(value, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        }else{
            dispatch(getHolidayBookingList(value, sectionID, "cancelled"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };

    const handleChange = (event) => {
        dispatch(holidayBookingsSearch(1, 'cancelled', event.target.value, sectionID, ffStartDate, ffEndDate))
        dispatch(holidayBookingSearchValue(event.target.value, "cancelled"))
        dispatch(pageId(1));
    };

    // const refreshHolidayBooking = () => {
    //     if(searchVal !== '') {
    //         dispatch(holidayBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
    //         dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"));
    //     }else if(ffStartDate !== null && ffEndDate !== null ){
    //         dispatch(holidayBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
    //         dispatch(holidayBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
    //     } else {
    //         dispatch(getHolidayBookingList(pageNum, sectionID, "booked"))
    //         dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
    //         dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"))
    //     }
    // };

    // const statusChangeHandler = (event, id, corporate) => {
    //     let status = event.target.value;
    //     dispatch(holidayBookingStatusUpdate(status, id, corporate,sectionID, refreshHolidayBooking));
    //     // dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"));
    // };

    const holidayBookingDisplayData = holidayCancelled && holidayCancelled.map(data => (
        <AdminListing 
            packTitle={data.pack_title}
            address={data?.sec_name  && data?.sec_name == "International" ? data.location_country : data.address}
            amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.from} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            isHolidayBooking={true}
            value={data.booking_status}
            data={data}
            id={data.id}
            messageTitle={data.special_request}
            corporate={data.corporate}
            statusValues={holidaysConcelledStatusValues}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            onClick={() => moreInfoHandler(data)}
            // statusChangeHandler={statusChangeHandler}
            isCancelled={true}
            voucher_info={data?.voucher_info}

        />
    ));

    return (
        <>
        <SearchBar
            searchVal={searchVal}
            handleChange={handleChange}
        />

      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
        {holidayCancelled && holidayCancelled?.length === 0 && 
            <div className="enquiry-card-holder">No Record Found!</div>
        }
        {holidayBookingDisplayData}
        <Pagination 
            totalPage={totapPage} 
            page={pageNum} 
            onChange={(event, value) => handlePagination(event, value)}
        />
        {showMoreInfo && 
            <CommonMoreInfoModel 
              open={showMoreInfo}
              modalClose={() => setShowMoreInfo(false)}
              moreInfoData={moreInfoData}
              isCancellationMessage={true}
            />
          }
        </>
    );
};

export default Cancelled;
