import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Edit from "../../../assets/images/edit.svg";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    EMPLOYEE_NAME,
    EMPLOYEE_MOBILE,
} from '../../../utils/constants';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperWidthSm: {
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function EmployeePopup({ updateEmployeeData, handleClose, openPopup, currentName, currentMobile, currentEmail }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(currentName);
    const [mobile, setMobile] = React.useState(currentMobile);
    const [email, setEmail] = React.useState(currentEmail);
    const [isNameValidation, setIsNameValidation] = useState(null);
    const [isMobileValidation, setIsMobileValidation] = useState(null);
    const [isMobileLengthValidation, setIsMobileLengthValidation] = useState(null);
    const [isEmailErrorvalidation, setIsEmailErrorvalidation] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleNameChange = (value) => {
        const name = value.target.value
        setName(name)
    };

    const handleMobileChange = (event) => {
        // const mobile = event.target.value
        setMobile(event)
    };

    const handleEmailChange = (event) => {
        const email = event.target.value
        setEmail(email)
    };

    const inputValidation= ()=>{
        setIsNameValidation(false)
        setIsMobileValidation(false)
        setIsEmailErrorvalidation(false)
        if (name === "" || name === null) setIsNameValidation(true)
        else if (mobile === "" || mobile === null) setIsMobileValidation(true)
        else if (mobile.length < 10) setIsMobileLengthValidation(true)
        else if (email === undefined || email === "") setIsEmailErrorvalidation(true)
        else{
            updateEmployeeData(name, mobile, email)
        }
    };

    return (
        <div >
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openPopup}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Editing Info
                </DialogTitle>
                <div style={{ backgroundColor: 'white', marginTop: '10px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                    <label for="">Employee Name</label>
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z\s]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        className="form-control"
                        onChange={(value) => handleNameChange(value)}
                    />
                    {isNameValidation &&
                        <p style={{ color: "red" }}>{EMPLOYEE_NAME}</p>
                    }
                    <label style={{ marginTop: '10px' }}>Mobile No</label>
                    {/* <input
                        type="text"
                        placeholder="Mobile"
                        value={mobile}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) || mobile?.length === 10) {
                                event.preventDefault();
                            }
                        }}
                        className="form-control"
                        onChange={handleMobileChange}
                    /> */}
                    <PhoneInput
                    placeholder="Mobile"
                    value={mobile}
                    onChange={handleMobileChange}
                    withCountryCallingCode = {true}
                    limitMaxLength={true}
                    international={true}
                    defaultCountry="IN"
                    />
                    {isMobileValidation ?
                        <p style={{ color: "red" }}>{EMPLOYEE_MOBILE}</p>
                        : isMobileLengthValidation ? <p style={{ color: "red" }}>Enter 10 digit mobile</p> : ''
                    }
                    <label style={{ marginTop: '10px' }}>Email Id </label>
                    <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        className="form-control"
                        onChange={handleEmailChange}
                    />
                    {isEmailErrorvalidation && <p style={{ color: "red" }}>Please enter email</p>}
                </div>
                <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
                    <button
                        type="button"
                        className="btn btn-primary btn-save"
                        onClick={(onClose) => {
                            inputValidation()
                        }}
                    >
                        Done
                </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
