import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import Button from "@material-ui/core/Button";
import DelIcon from "../../../assets/images/bin.svg";
import JoditEditor from "jodit-react";
import {useState, useEffect, useRef} from 'react';
import { FaFileUpload } from "react-icons/fa";
import DefaultImage from "../../../assets/images/plain-background.jpeg";

const StoryForm = ({type, storyProp, handleForm, storyDetails, storyDetailsId, deleteStory, errors, resetErrors, fromMyFamilyFirst, fromWeddings, fromHoneyMoon, fromAnniversary,toDisable})=>{
    let initState = {
      "title": "",
      "description": "",
      "media": []
    }
    console.log(storyDetails)
    const [storyState, setStoryState] =  useState(initState);
    const [showImages, setShowImages] = useState(false);
  const editor = useRef(null);
  const [description, setDescription] = useState('');
    useEffect(()=>{
      if(storyDetails){
        setStoryState(storyDetails);
        setDescription(storyDetails.description);
      }
      
    }, [storyDetails])
    const handleChange = (name, value)=>{
      console.log(storyState);
      resetErrors();
      setStoryState({
        ...storyState,
        [name]:value
      });
    };
    useEffect(()=>{
      setStoryState({
        ...storyState,
        description:description
      });
      resetErrors();
    }, [description])
    const handleUpdatePackageImage = (imagesList)=>{
      console.log(imagesList);
      setStoryState({
        ...storyState,
        media:imagesList
      });
      resetErrors();
    };
    const deleteStoryFun = ()=>{
      console.log('delete story');
      deleteStory();
    };
      // all options from https://xdsoft.net/jodit/doc/
    const configJoditEditor = {
      readonly: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      placeholder: "Description...",
    };
    return (
        <div>
      {/* {savingNewEvent&&<div style={{marginLeft:10, marginBottom:-3}}><LinearProgress  /></div> } */}
      <div className="packageDetails-holder">
      
        
          <div className="form-options">
            <div>
              <h2>{type} Story</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              
              <div>
                <button onClick={()=>{handleForm(storyState)}} disabled={toDisable} className="btn btn-primary btn-save">
                  {type==='Add'?'Save':'Update'}
                </button>
               
              </div>
              <div>
              {type==='Edit'&&<Button
                    
                    style={{background: '#FBFCFD',border: '1px solid #C4C4C4', height:27, width:50, marginLeft:5}}
                    aria-controls="list-menu"
                    aria-haspopup="true"
                    onClick={deleteStoryFun}
                  >
                    
                    <img src={DelIcon} />
                  </Button>}
              </div>
              
            </div>
          </div>
         
          <div>
            <div className="form-group form-custom">
              <label for=""> Title 
              {/* {JSON.stringify(storyState)} */}
              </label>
              <input
                value={storyState?.title}
                type="text"
                className="form-control"
                onChange={(e) => handleChange("title", e.target.value)}
              />
              <img
                // src={Edit}
                style={{
                  position: "absolute",
                  right: "46px",
                //   top: `${isSectionNameErrorValidation ? "141px" : "124px"}`,
                }}
              />

              {errors && errors.title.hasError&&<p style={{color: 'red'}}>{errors.title.message}</p>}
              
            </div>
            {/*Image UI Section*/}


            <div
                  onClick={() => setShowImages(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                    marginBottom:25
                  }}
                >
                  <img
                    src={
                      storyState?.media.length &&storyState?.media.filter(img=>img.thumbnail)[0]?.image ?storyState?.media.filter(img=>img.thumbnail)[0]?.image : DefaultImage
                        
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    style={{
                      color: "white",
                      backgroundColor: "rgb(82, 82, 82)",
                      position: "absolute",
                      bottom: 20,
                      left: 80,
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: "6px",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    {storyState?.media?.length}{" "}
                    images / videos
                  </h6>
                  {errors && errors.media.hasError&&<p style={{color: 'red'}}>{errors.media.message}</p>}
                </div>
            
            <div className="form-group form-custom mt-3">
              <label for="">Description</label>
              <JoditEditor
                // ref={editor}
                value={description}
                config={configJoditEditor}
                tabIndex={1} // tabIndex of textarea
                onBlur={(value) => setDescription(value)} // preferred to use only this option to update the content for performance reasons
                // onChange={(newContent) => {}}
                onChange={(value) => {}}
              />
              
              {errors && errors.description.hasError&&<p style={{color: 'red'}}>{errors.description.message}</p>}
            </div>
          </div>
      </div>
      
        {storyState?.media&&<ImageMultiSelect
          title={storyState?.title}
          images={storyState?.media}
          open={showImages}
          toggleModal={setShowImages}
          handleCloseDone={() => setShowImages(false)}
          handleUpdatePackageImage={handleUpdatePackageImage}
          isAddNewEvent={type==='Edit' ? false : true}
          isUpdateEvent={type==='Edit'? true : false}
          moduleType={'event'}
          fromMyFamilyFirst={fromMyFamilyFirst}
          fromWeddings={fromWeddings}
          fromHoneyMoon={fromHoneyMoon}
          fromAnniversary={fromAnniversary}
        />}
      
    </div>

    )
};

export default StoryForm;