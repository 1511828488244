import React,{ useEffect, useState } from 'react';
import MyFamilyFirstBooking from '../../../../common/myFamilyFirstBookings';
import SearchBar from '../../../../common/commonSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import MyFamilyFirstMoreInfoModal from '../../myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../../common/pagination';
import * as AnniversaryAction from '../../../../actions/anniversaryAction'
import ConfirmModal from '../../../wedding/requests/confirmModal';
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../../actions/holidayRequestAction';

const Requested = ({status}) => {

    const [ showModal, setShowModal ] = useState(false);
    const [ requestedMoreInfoModalData, setRequestedMoreInfoModalData ] = useState({});
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    const [requestModal, setRequestModal] = useState(false);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    
    const [ id, setId ] = useState(null);
    const[statuschange,setStatuschange]=useState()

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
    const [ page, setPage ] = useState(1);
    const [ searchVal, setSearchVal ] = useState('');
    const anniversaryRequestedData = useSelector(state => state.anniversary?.anniversaryRequested?.results);
    const totalPage = useSelector(state => state.anniversary?.anniversaryRequested?.total_pages);
    const dispatch = useDispatch();

    const START_DATE = useSelector(state => state.anniversary.startDate);
    const END_DATE = useSelector(state => state.anniversary.endDate);
    const SEARCH_TERM = useSelector(state => state.anniversary.searchTerm);
    const PAGE_NO = useSelector(state => state.anniversary.pageNo);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);
    
    const handleMoreInfoClick = (data) => {
        setShowModal(true);
        setRequestedMoreInfoModalData(data);
    };
    useEffect(() => {
      setPage(1)
    }, [status])

    const refereshList = () => {
        dispatch(AnniversaryAction.getAnniversaryRequested(page));
        dispatch(AnniversaryAction.getAnniversaryPlanning(1));
        dispatch(AnniversaryAction.getAnniversaryConfirmed(1));
        dispatch(AnniversaryAction.getAnniversaryCancelled(1));
    };

  
    const requestStatusChange = (event, id,data) => {
      setCloseRequestModalData(data)
      setId(id);
      setConfirmedRequestModalData(data)
        let status = event.target.value;
        setStatuschange(status)
  
        if(status === "confirmed")setRequestModalConfirm(true);
        else if(status === "cancelled")setRequestModal(true);
        else{
          dispatch(AnniversaryAction.anniversaryRequestStatusChange(status, id,null, refereshList))

        }
        
    };

    const confirmRequestHandler=()=>{
        
        
      setIsconfirmAmountBool(false)
     if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
      else {
      dispatch(AnniversaryAction.anniversaryRequestStatusChangeConfirm("confirmed", id,confirmedRequestAmount, refereshList))
        setRequestModalConfirm(false)
      }
      // dispatch(confirmRequestInputVal(''));
     
      
  
    }


    const notToConfirm=()=>{
   
      setRequestModalConfirm(false)
      setIsconfirmAmountBool(false);
    
    }

    const closeRequestHandler = () => {
 
      setIsclosingMessageBool(false)
      if(closeRequestMessage === "")setIsclosingMessageBool(true)
      else {
        dispatch(AnniversaryAction.anniversaryRequestStatusChange(statuschange, id,closeRequestMessage, refereshList))
        setRequestModal(false)
      }
      dispatch(closeRequestInputVal(''));
    };
  
  const notToCancel=()=>{
      setRequestModal(false)
      setIsclosingMessageBool(false);
  
  }

    const handlePagination = (e,value) => {
        setPage(value);
        dispatch(AnniversaryAction.setPageNo(value))
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(AnniversaryAction.searchAnniversaryRequests(SEARCH_TERM, START_DATE, END_DATE, "requested", value));
        }else{
          dispatch(AnniversaryAction.getAnniversaryRequested(value));
        }
    };

    const handleChange = (event) => {
      if(event.target.value){
        dispatch(AnniversaryAction.searchAnniversaryRequests(event.target.value, START_DATE, END_DATE, "requested", PAGE_NO));
      }else{
        dispatch(AnniversaryAction.getAnniversaryRequested(1));
        setPage(1);
      }
      dispatch(AnniversaryAction.setSearchTerm(event.target.value))
      };

    const anniversaryRequestedDisplayData = anniversaryRequestedData && anniversaryRequestedData.map((data) => (
        <MyFamilyFirstBooking 
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request? data.specific_request:"N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={requestStatusChange}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {anniversaryRequestedData && anniversaryRequestedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
          {anniversaryRequestedDisplayData}
          <MyFamilyFirstMoreInfoModal
            open={showModal}
            close={() => setShowModal(false)}
            modalData={requestedMoreInfoModalData}
            section={"Anniversary Requested"}
            isCancellationMessage={false}
          />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
        {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
         {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Requested;
