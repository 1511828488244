import React, {useState} from 'react'
import Input from '../../common/uiElements/input'
import { Row, Col, Button } from "antd";
import Edit from "../../assets/images/edit.svg";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from 'react-redux';
import { generateEmail, generateLink } from '../../actions/paymentLinkAction';
import Error from "../../common/error";
import PhoneInput from 'react-phone-number-input'
import ToggleButton from '../holidays/listings/toggle';

const Index = () => {
    const { enqueueSnackbar } = useSnackbar(); 
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: "",
        packTitle: "",
        price: "",
        advance_amount: "",
        contact_no:"",
        email: "",
        isAdvance: false
    })

    const [isError, setIsError] = useState({
        error: false, name: "", msg: ""
    })

    const handleChange = (key, value) => {
        setState({
            ...state,
            [key]: value.target.value
        })
        setIsError({error: false, name: "", msg: ""})
    }
    const handleContactNo = (value) => {
        setState({
            ...state,
            "contact_no": value
        })
    }
    const handleToggleChange = (event) => {
        setState({
            ...state,
            isAdvance: event.target.checked,
            advance_amount: ""
        })
    }

    const handleSendEmail = (isEmail) => {
        const data = {
            "pack_title": state.packTitle.trim(),
            "contact_name": state.name.trim(),
            "contact_email": state.email.trim(),
            "contact_number": state.contact_no.trim(),
            "amount": parseInt(state.price),
            "advance_amount": state.isAdvance? parseInt(state.advance_amount) : 0,
            "request_type": isEmail ? "generate_email" : "generate_link",
        }
        if(state.name == ""){
            setIsError({error: true, name: "name", msg: "Please enter name"})
        }else if(state.packTitle == ""){
            setIsError({error: true, name: "packTitle", msg: "Please enter package title"})
        
        }else if(state.price == ""){
            setIsError({error: true, name: "price", msg: "Please enter price"})
        }else if(state.isAdvance && !state.advance_amount){
            setIsError({error: true, name: "advance_amount", msg: "Please enter advance amount"})
        }else if(state.advance_amount && (Number(state.advance_amount) >= Number(state.price))){
            setIsError({error: true, name: "advance_amount", msg: "Advance should be less than package amount"})
        
        }else if(state.contact_no == ""){
            setIsError({error: true, name: "contact_no", msg: "Please enter contact no"})
        }else if(state.contact_no.length > 10 || state.contact_no.length < 10){
            setIsError({error: true, name: "contact_no", msg: "Please enter valid mobile no"})
        
        }else if(state.email == "" || !testEmail(state.email)){
            setIsError({error: true, name: "email", msg: "Please enter valid email"})
        }else{
            if(isEmail){
                dispatch(generateEmail(data, handleSuccess))
            }else{
                dispatch(generateLink(data, handleCopySuccess))
            }
        }
        
    }
     const testEmail = (email) => {
        let regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return regex.test(email);
      };

    const handleCopySuccess = (url) => {
        navigator.clipboard.writeText(url);
        enqueueSnackbar('Link Copied', {
          variant: "success",
        });
        setState({
            name: "",
            packTitle: "",
            price: "",
            advance_amount: "",
            contact_no:"",
            email: ""
        })
    }
    const handleSuccess = () => {
        enqueueSnackbar('Email sent successfully', {
          variant: "success",
        });
        setState({
            name: "",
            packTitle: "",
            price: "",
            contact_no:"",
            email: "",
            advance_amount: "",
        })
    }

  return (
    <div>
        <h1 className="title-holder">Create Payment</h1>
        <Row>
            <Col className="gutter-row" xs={24} lg={9} style={{ paddingRight: '20px', marginRight: '-20px' }}>
                <Row  className="gutter-row" >
                    <div className='packageDetails-holder' style={{width: "100%"}}>
                        <div className="form-group form-custom" style={{marginTop: "5px", position: "relative"}}>
                            <label for="">Customer Name*</label>
                            <input
                                value={state.name}
                                type="text"
                                className="form-control"
                                onChange={(value) => handleChange("name", value)}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {isError.error && isError.name == "name" && <Error error={isError.msg} /> }
                        </div>
                        <div className="form-group form-custom" style={{position: "relative"}}> 
                            <label for="">Package Title</label>
                            <input
                                value={state.packTitle}
                                type="text"
                                className="form-control"
                                onChange={(value) => handleChange("packTitle", value)}
                            />
                            {isError.error && isError.name == "packTitle" && <Error error={isError.msg} /> }
                        </div>
                        <div className="package-price" id="price">
                            <div className="form-group form-custom" >
                                <h6>Rs</h6>
                                <Input
                                label="Package Price*"
                                value={state?.price}
                                handleChange={(value) => handleChange("price", value)}
                                isDivider={true}
                                disableChar={true}
                                />
                            {isError.error && isError.name == "price" && <Error error={isError.msg} /> }
                            </div>
                        </div>
                        <ToggleButton 
                            label="Advance"
                            name="advance"
                            checkValue={state.isAdvance}
                            handleToggleChange={(event) => handleToggleChange(event)}
                        />
                        {state?.isAdvance &&<div className="package-price" id="price" style={{marginTop: 0}}>
                            <div className="form-group form-custom" style={{marginTop: 0}}>
                                <h6>Rs</h6>
                                <Input
                                label="Advance"
                                value={state?.advance_amount}
                                handleChange={(value) => handleChange("advance_amount", value)}
                                isDivider={true}
                                disableChar={true}
                                />
                            {isError.error && isError.name == "advance_amount" && <Error error={isError.msg} /> }
                            </div>
                        </div>}
                        <div className="form-group form-custom" style={{position: "relative", marginTop: 0}}>
                            <label for="">Customer Contact No.*</label>
                            <input
                                value={state.contact_no}
                                type="text"
                                className="form-control"
                                onChange={(value) => handleChange("contact_no", value)}
                                onKeyPress={ (event) => {
                                    if (!/[0-9+]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                            />
                            {/* <PhoneInput
                                placeholder="Contact no."
                                value={state.contact_no}
                                onChange={handleContactNo}
                                withCountryCallingCode = {true}
                                limitMaxLength={true}
                                international={true}
                                defaultCountry="IN"
                            /> */}
                            {isError.error && isError.name == "contact_no" && <Error error={isError.msg} /> }
                        </div>
                        
                        <div className="form-group form-custom" style={{position: "relative"}}>
                            <label for="">Customer Email Id*</label>
                            <input
                                value={state.email}
                                type="text"
                                className="form-control"
                                onChange={(value) => handleChange("email", value)}
                            />
                            {isError.error && isError.name == "email" && <Error error={isError.msg} /> }
                        </div>
                        <div className='d-flex justify-content-between'>
                            <button 
                                type="button" 
                                className='btn btn-primary btn-save' 
                                style={{width: "140px", padding: "15px 0", marginLeft: "0px"}} 
                                onClick={() => handleSendEmail(true)}
                                >
                                    Share link via Email
                                </button>
                            <button 
                                type="button" 
                                className='btn btn-primary btn-save' 
                                style={{padding: "15px 0"}}
                                onClick={() => handleSendEmail(false)}
                                >
                                    Copy link
                                </button>
                            
                        </div>
                    </div>
                </Row>
            </Col>

            <Col className="gutter-row" xs={24} lg={8}>
                
            </Col>
        </Row>
    </div>
  )
}

export default Index