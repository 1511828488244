import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress,
  axiosPatchWithToken,
  axiosCsvDownload,
  axiosDeleteWithBody
} from "../services/apiServices";

import {
  HOLIDAY_DELETE,
  HOLIDAY_SELECT_DROPDOWN,
  HOLIDAY_PACKAGE_DELETE,
  HOLIDAY_LIST,
  HOLIDAY_LISTING_PAGE_ID,
  ALL_IMAGES_HOLIDAY_DATA,
  HOLIDAY_ADD_LIST,
  UPDATE_HOLIDAY_LIST,
  HOLIDAY_IMAGE_UPLOAD,
  HOLIDAY_LISTING_DROP_DOWN_VALUE,
  HOLIDAY_LISTING_UPDATE_BOOL,
  GET_HOLIDAY_PACKAGE_INFO,
  OPENING_SOON_DATE_VALUE,
  LOCATION_DROPDOWN,
  HPLIDAY_LISTING_EDIT_TRUE_VALUE,
  HOLIDAY_LISTING_SEARCH_VALUE,
  SET_SELECTED_CHOSEN_ID,
  TESTIMONIAL_HOLIDAY_LIST,
  GET_HOLIDAYS_FEATURED_LIST,
  HOLIDAYS_FEATURED_UPDATE,
  HOLDAY_PACK_REVIEWS_LIST,
  ISADD_ANOTHER_PACKAGE,
  IS_DATA_LOADING,
  IS_LIST_DATA_LOADING
} from "./types";

export const SET_EDIT_HOLIDAY_LIST_DETAILS = "SET_EDIT_HOLIDAY_LIST_DETAILS";
export const SET_ADD_HOLIDAY_PACKAGE = "SET_ADD_HOLIDAY_PACKAGE";
export const getFeaturedList = (id) => {
  
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.GET_HOLIDAYS_FEATURED_LIST + id }`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_HOLIDAYS_FEATURED_LIST,
          payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
    });
  };
};
export const updateFeaturedList = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPatchWithToken(`${API.HOLIDAYS_FEATURED_UPDATE + id + "/"}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getHolidayList = (page, secId, search, loader) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
      sec_id: secId,
      search_term: search
    };
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HOLIDAY_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
          loader();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getDropDownLocationList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_LOCATION_DROPDOWN}`, token)
      .then((res) => {
        console.log("res", res)
        if (res.data.status_code === 200) {
          dispatch({
            type: LOCATION_DROPDOWN,
            payload: res.data.data.city_list,
          });
          // loader();    
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const editHolidayListItem = (data) => {
  console.log("data", data)
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_HOLIDAY_LIST_DETAILS,
      payload: data,
    });
  };
};

export const handleAllImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ALL_IMAGES_HOLIDAY_DATA,
      payload: data,
    });
  };
};

export const setAddHolidayPackage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ADD_HOLIDAY_PACKAGE,
      payload: value,
    });
    // showData()
  };
};
export const editTrue = (boolVal) => {
  return async (dispatch) => {
    dispatch({
      type: HPLIDAY_LISTING_EDIT_TRUE_VALUE,
      payload: boolVal,
    });
    // showData()
  };
};
export const holidayListingDropDownVal = (data) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_LISTING_DROP_DOWN_VALUE,
      payload: data,
    });
    // showData()
  };
};

export const deleteListingHolidayEditImg = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.HOLIDAY_IMAGE_DELETE}${id}/`, token)
      .then((res) => {
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAddNewHoliday = (data, refreshList, handleError,loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HOLIDAY_ADD_LISTING}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          // dispatch({
          //   type:HOLIDAY_ADD_LIST,
          //   payload: res.data,
          // })
        refreshList();
        }
        else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          loader()
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAddNewHolidayPackage = (data, refreshList) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HOLIDAY_POST_PACKAGE}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList(data.corp_id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const listHolidayDataUpdate = (data, loader, handleSuccess, urlId, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = data;

    axiosPut(`${API.UPDATE_HOLIDAY_LIST}${urlId}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          loader();
          handleSuccess()
          dispatch({
            type: HOLIDAY_LIST,
            payload: res.data,
          });
          dispatch({
            type: UPDATE_HOLIDAY_LIST,
            payload: res.data,
          });
        } else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          loader(data.holiday);
        }
      }
    );
  };
};
export const imageUploadHolidays = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);
    const token = getState().auth.token;
    axiosMediaUpload(API.HOLIDAY_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        dispatch({
          type: HOLIDAY_IMAGE_UPLOAD,
          payload: res.data,
        });
        addImage(res.data.data);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const videoUpload = (file, addVideo, obj, newArr, getUploadProgress, id) => {
  console.log(getUploadProgress);
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.HOLIDAY_VIDEO_UPLOAD, "image", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const getHolidayPackageInfo = (id) => {
  return async (dispatch, getState) => {
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosGetWithToken(`${API.HOLIDAY_PACKAGE_INFO + id + "/"}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_HOLIDAY_PACKAGE_INFO,
          payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }else{
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
    });
  };
};
export const isAddAnotherPackage = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ISADD_ANOTHER_PACKAGE,
      payload: value,
    });
  };
};
export const deleteHoliday = (id, packId, loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.HOLIDAY_DELETE}${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        loader(packId);
        dispatch({
          type: HOLIDAY_DELETE,
          payload: res.data.data,
        });
        // window.location.reload();
      }
    });
  };
};

export const deleteHolidayPackage = (id, packId, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.HOLIDAY_DELETE_PACKAGE}${id.id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        handleSuccess(packId)
        dispatch({
          type: HOLIDAY_PACKAGE_DELETE,
          payload: res.data.data,
        });

      }
    });
  };
};

export const selectHolidayDropdown = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.HOLIDAY_SELECT_DROPDOWN}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: HOLIDAY_SELECT_DROPDOWN,
          payload: res.data.data,
        });
      }
    });
  };
};

export const searchHolidayListing = (search, secId, page) => {
  return async (dispatch, getState) => {
    const secId = getState().holidayListing.holidayListingDropDownvalue?.id
    const body = {
      search_term: search,
      "next_page_id": page,
      sec_id: secId
    };
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HOLIDAY_SEARCH}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getHolidaysFilteredList = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id: "1",
      sec_id: id
    }
    axiosPostWithToken(`${API.HOLIDAY_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const handleOpeningSoonDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: OPENING_SOON_DATE_VALUE,
      payload: date,
    });
  };
};

export const holidaySearchValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_LISTING_SEARCH_VALUE,
      payload: value,
    });
  };
};

export const holidayListsUpdateBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_LISTING_UPDATE_BOOL,
      payload: bool,
    });
  };
};

export const pageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_LISTING_PAGE_ID,
      payload: page,
    })

  };
};

export const setChosenPackIDBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_CHOSEN_ID,
      payload: bool,
    })

  };
};

/// Testimonial///

export const getHolidayTestimonials = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_HOLIDAY_TESTIMONIAL_LIST}${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: TESTIMONIAL_HOLIDAY_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const holidayPostNewTestimonial = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      main_pack_id:data.id,
      guest_name:data.authorName,
      guest_image:data.data!==null?data.data.url:'',
      message: data.authorDesc
    }
    axiosPostWithToken(`${API.HOLIDAY_POST_TESTIMONIAL}`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
};

export const updateHolidayTestimonial = (data, tid, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "guest_name": data.guest_name,
      "guest_image": data.guest_image,
      "message": data.message,
      "main_pack_id":data.id
  };
    axiosPut(`${API.UPDATE_HOLIDAY_TESTIMONIAL}${tid}/`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
}

export const deleteHolidayTestimonials = (id,eventId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body={
      "main_pack_id":eventId
    }
   
    axiosDeleteWithBody(`${API.DELETE_HOLIDAY_TESTIMONIAL}${id}/`,body,token).then((res) => {
      
        handleSuccess();
    })
    .catch((err) => {
        handleError();
    });
  }
};

// Review

export const getHolidayReviewsList = (id) => {
  const body = {
    "request_type":"list_review",
    "pack_id": id,
    "page":1,
   
    "list_per_page": 10,
    "filters":[{"field":"active", "value":"true"}],
    "sort":{"field":"custom_date", "order":"D"}
    // "filters":[{"field":"active", "value":"true"},{"field":"status", "value":"approved"},{"field":"review_star", "value":4},{"field":"from", "value":"2021-07-12"},{"field":"to", "value":"2022-11-04"}],
    // "sort":{"field":"created", "order":"D"}
  }
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_HOLIDAY_REVIEWS}`,body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLDAY_PACK_REVIEWS_LIST,
            payload: res.data.data.results
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const addHolidayPackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_HOLIDAY_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 201) {
           handleSuccess("Added Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const editHolidayPackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_HOLIDAY_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
           handleSuccess("Updated Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const deleteHolidayPackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_HOLIDAY_REVIEWS}`,data, token)
      .then((res) => {
           handleSuccess("Deleted Successfully")
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const handleCopyPackToCorp = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HOLIDAYS_PACK_COPY_TO_CORP}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleSuccess()
        }else if(res.data.status_code === 400){
          if(res.data.data.message === "This Title is Already present"){
            handleError()
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const downloadCSV = (api, secId, secName) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[
        {"field":"active", "value":"true"},
        {"field":"section", "value": secId == undefined ? null : secId},
        // {"field":"title", "value":"package for final"}
      ],
      // "pack_sort":{"field":"created", "order":"D"},
      // "subpack_sort":{"field":"created", "order":"D"} 
  }
    axiosCsvDownload(api, body, token)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${secName ? secName : "All"}.xlsx`);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
    })
  }
}