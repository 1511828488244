import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentBookings, getPendingPayments, bookingStatusChange, getBookedPayments } from '../../../actions/paymentLinkAction';
import Pagination from '../../../common/pagination';
import Bookings from './bookings'
import ConfirmationPopup from './confirmationPopup';
import { paymentStatusValues } from './../../../resources/status';
import {useSnackbar} from 'notistack'

const Pending = () => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [page, setPage] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState(null)
    const BOOKING_LIST = useSelector(state => state.paymentLinkBookings.pendingPaymentList?.results)
    const TOTAL_PAGES = useSelector(state => state.paymentLinkBookings.pendingPaymentList?.total_pages)

    const handlePagination = (e,value) => {
        setPage(value);
        dispatch(getPendingPayments(value))
      };
    const statusChangeHandler = (e, data) => {
      const status = e.target.value;
      setPopupData(data)
      if(status == "booked"){
        setShowPopup(true)
      }

    }
    const handleSuccess = (message) => {
      enqueueSnackbar(message, {
        variant: "success",
      });
      dispatch(getPendingPayments(1))
      dispatch(getBookedPayments(1))
      setShowPopup(false)
      
  }

    const handleConfirm = () => {
      dispatch(bookingStatusChange(popupData?.pack_booking_slug, handleSuccess))
      
    }

    const handleClose = () => {
      setShowPopup(false)
    }
  return (
    <>
        {BOOKING_LIST && BOOKING_LIST?.length > 0 && 
          BOOKING_LIST?.map((data) =>  (
            <Bookings 
              pack_title={data?.pack_title}
              contact_name = {data?.contact_name}
              contact_email = {data?.email}
              data={data}
              amount={`Rs ${data?.amount}`}
              statusChangeHandler={statusChangeHandler}
              statusValue={data?.is_advance && data?.is_advance_paid ? paymentStatusValues : [{
                name: 'Pending',
                value: 'pending'
            },]}
            />
            
          ))
          }
          {showPopup && 
            <ConfirmationPopup 
              open={showPopup} 
              handleClose={handleClose}
              content="Hello"
              heading="Confirm" 
              btnOneText="Cancel" 
              btnTwoText="Confirm"
              data={popupData}
              handleClick={handleConfirm}
            />
          }
          <Pagination 
              totalPage={TOTAL_PAGES} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
    </>
  )
}

export default Pending