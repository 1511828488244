import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; 
import { useDispatch, useSelector } from 'react-redux';
import { getHolidaySectionList } from '../../../actions/holidaySectionAction';
import { getHolidayBookingList, bookingsSectionId, holidayBookingSearchValue ,pageId} from '../../../actions/holidayBookingAction';

const DropdownFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const holidaySectionLists = useSelector(state => state.holidaySection.getHolidaySectionList);
  const pageNum = useSelector(state => state.holidayBooking.page);
  const statusVal = useSelector(state => state.holidayBooking.holidayStatusVal);
  const dispatch = useDispatch();

  let resultedLists = holidaySectionLists && holidaySectionLists.
  filter(item =>  item.name !== "Visas & More" && item.name !== "Customize" && item.name !== "Send a Gift");
  const handleClose = (item) => {
    setDropdownValue(item?.name);
    setAnchorEl(null);
    dispatch(getHolidayBookingList(pageNum, item?.id, statusVal));
    dispatch(bookingsSectionId(item?.id));
    dispatch(holidayBookingSearchValue("", statusVal));
    dispatch(pageId(1));
  };

  const handleEventClick = (event) => {
    dispatch(getHolidaySectionList());
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={handleEventClick} 
         className="dropdown-filter"
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {resultedLists && resultedLists.map(item => {
            console.log(item);
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  );
};

export default DropdownFilter;