import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { AiFillCaretDown } from 'react-icons/ai';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { familyFirstStartDate, familyFirstEndDate, familyFirstSearchRequested, myFamilyFirstRequested, myFamilyFirstPlanning, myFamilyFirstConfirmed, myFamilyFirstCancelled} from '../actions/myFamilyFirstAction';
import ClearIcon from "@material-ui/icons/Clear";
import '../assets/css/partials/gift/myFamilyFirst/dateFilter.scss'


const useStyles = makeStyles((theme) => ({
  resize: {
    fontSize: 11,
  },
}));

const Datefilter = ({ status }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const ffStartDate = useSelector(
    (state) => state.myFamilyFirst.familyFirstStartDate
  );
  const ffEndDate = useSelector((state) => state.myFamilyFirst.familyFirstEndDate);
  const PAGE_NO = useSelector(state => state.myFamilyFirst.page);

  const handleStartDateChange = (date) => {
    dispatch(familyFirstStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(familyFirstSearchRequested(PAGE_NO,'', status));
  };

  const handleEndDateChange = (date) => {
    dispatch(familyFirstEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(familyFirstSearchRequested(PAGE_NO,'', status));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(familyFirstStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(familyFirstEndDate(null));
    dispatch(myFamilyFirstRequested());
    dispatch(myFamilyFirstPlanning());
    dispatch(myFamilyFirstConfirmed());
    dispatch(myFamilyFirstCancelled());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="date-filter">
        <p className="date-filter-from"> Date filter from</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 100,
            fontSize: 10,
          }}
          autoOk={true}
          InputProps={
            ffStartDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleStartClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          size="small"
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!ffStartDate && "Select"}
          value={ffStartDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <p className="date-filter-to"> to</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 90,
        }}
        autoOk={true}
          InputProps={
            ffEndDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleEndClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!ffEndDate && "Select"}
          value={ffEndDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Datefilter;