import React from 'react';
import { Dialog } from '@material-ui/core';
import { ImCross } from 'react-icons/im';
import moment from 'moment';
import '../assets/css/partials/commonMoreInfoModal.scss';
import { isCorporate, checkNumberOfGuest } from '../utils/index';

const CommonMoreInfoModel = ({open, modalClose, moreInfoData, isAvailabilityReq, amount,isCancellationMessage}) => {
    const { 
     contact_email, 
     adults, children, 
     contact_name, 
     pack_image, 
     special_request, 
     contact_number, 
     total_points, 
     created, 
     infants,
     corporate, 
     amount_paid,
     schedule,
     cancelling_message,
     voucher_info, 
     contact_address,
    } = moreInfoData;
    // const { from, to } = moreInfoData.schedule;
    console.log(isCancellationMessage,"isCancellationMessage",moreInfoData,pack_image);
    return (
        <>
        <Dialog open={open} 
            PaperProps={{
                style: {
                    height:"auto",
                    maxWidth:"700px"
                },
           }}
        >
            <div className="common-more-info-container">
                <h5 className="common-more-info-heading">{moreInfoData.pack_title}{" "}
                    <span style={{color:'grey'}}>Booking</span>
                </h5>
                    <span className="common-more-info-close-icon"
                        onClick={modalClose}>
                        <ImCross />
                    </span>
                <p className="more-info-booked-on">Booked on {moment(created).format("Do MMMM, YYYY")}</p>
                <div className="row common-more-info-details">
                    <div className="col-md-6 common-more-info-left-contain">
                        <div className="info-image common-more-info-image" >
                            <img src={pack_image?.image?pack_image?.image:pack_image} width="300" height="200"alt="packImage"/>
                        </div>
                        
                            <div className="common-more-info-booked-for">
                            <div className="common-more-info-details-left">
                            {isAvailabilityReq ?
                                  <>
                                    <p> Pending Payment</p>
                                    <p>{amount}</p>
                                  </>:
                                  <>
                                   <p>Payment </p>
                                   <p>{voucher_info?.voucher_code ?  `(${voucher_info?.voucher_code} ${voucher_info?.voucher_amount ? `of amount ${voucher_info?.voucher_amount}` : "package price"}) ${((amount_paid !== "N/A" || amount_paid !== "N/A amount")&&(amount_paid>0) )? `& Rs ${amount_paid}` : ""}` :(amount ? amount : isCorporate(corporate, total_points, amount_paid))}</p>
                                  </>
                                }
                                </div>
                            </div> 
                          {adults &&  <div className="common-more-info-booked-for">
                                <label>Booked For</label>
                                <div className="common-more-info-details-left">
                                    <span>
                                    {Array.isArray(schedule) ? schedule.map((scheduleDate, index) => {
                                        if (schedule[index] === schedule[schedule.length - 1]) {
                                        return scheduleDate
                                        } else {
                                        return `${scheduleDate}, `
                                        }
                                    }) : (typeof schedule === 'object' && schedule !== null ? `${moment(schedule.from,"DD-MM-YYYY").format("DD-MM-YYYY")} ${ schedule.to ? `to ${schedule.to}`: ""}` : (schedule ===  null ? "N/A" : schedule))}
                                    </span>
                                    <p> {checkNumberOfGuest(adults, children, infants)}</p>
                                </div>
                            </div>
                            }
                            {isCancellationMessage?
                            <div className="common-more-info-booked-for">
                                <label>Cancellation Message</label>
                                <div className="common-more-info-details-left">
                                    <p  style={{color:"black"}} title={cancelling_message}>{cancelling_message?cancelling_message:"No messgae recieved"}</p>                                            
                                </div>
                            </div> :""}

                       
                    </div>

                    <div class="col-md-6 common-more-info-right-contain">
                       
                       
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Booked By</p>
                                <p>{contact_name}</p>
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Contact Number</p>
                                <p>{contact_number}</p>
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Contact Email</p>
                                <p>{contact_email}</p>
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Location</p>
                                <p>{contact_address}</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </Dialog>
        </>
    );
};

export default CommonMoreInfoModel; 