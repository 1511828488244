  import {
    GET_EXPERIENCE_BOOKING_LIST,
    EXPERIENCE_BOOKING_PAGE_ID,
    EXPERIENCE_BOOKING_START_DATE ,
    EXPERIENCE_BOOKING_END_DATE,
    GET_EXPERIENCE_CONFIRMED_TOTAL,
    GET_EXPERIENCE_BOOKED_TOTAL,
    GET_EXPERIENCE_CANCELLED_TOTAL,
    EXPERIENCE_STATUS_VALUE,
    EXPERIENCE_BOOKINGS_SECTION_ID,
    GET_EXPERIENCE_CONFIRMED_LIST,
    GET_EXPERIENCE_CANCELLED_LIST,
    EXPERIENCE_BOOKING_NEXT_PAGE_ID,
    EXPERIENCE_CONFIRMED_NEXT_PAGE_ID,
    EXPERIENCE_CANCELLED_NEXT_PAGE_ID,
    EXPERIENCE_BOOKING_CURRENT_PAGE_ID,
    EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID,
    EXPERIENCE_CANCELLED_CURRENT_PAGE_ID,
    EXPERIENCE_BOOKED_SEARCH_VALUE,
    EXPERIENCE_CONFIRMED_SEARCH_VALUE,
    EXPERIENCE_CANCELLED_SEARCH_VALUE,
    EXPERIENCE_REQUESTED_SEARCH_VALUE,
    GET_EXPERIENCE_REFUNDED_TOTAL,
    GET_EXPERIENCE_REFUNDED_LIST,
    EXPERIENCE_REFUNDED_SEARCH_VALUE,
    EXPERIENCE_REFUNDED_NEXT_PAGE_ID,
    EXPERIENCE_REFUNDED_CURRENT_PAGE_ID,
    GET_AVAILABILITY_LIST,
    EXPERIENCE_AVAILABILITY_SINGLE_DATA,
    GUEST_MODAL_OPEN_TRUE,
    RECURRING_DATE,
    ADULTS_GUEST,
    CHILDRENS_GUEST,
    INFANTS_GUEST,
    DATE_RANGE_ITEM,
    RECURRING_MODAL_OPENINI_TRUE,
    SCHEDULE_MODAL_OPEN_TRUE,
    PACKAGE_START_DATE,
    PACKAGE_END_DATE,
    SPECIFIC_DATE_VALUE,
    FLEXI_DATE_ITEM,
    GET_EXPERIENCE_FOLLOWUP_TOTAL,
  GET_EXPERIENCE_FOLLOWUP_LIST,
  EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID,
  EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID,
  GET_FOLLOWUP_BOOKED_TOTAL,
  EXPERIENCE_FOLLOWUP_SEARCH_VALUE,
 
  
  } from "../actions/types";
  
  const initialState = {
    experienceBooking:[], 
    experienceFollowup:[],
    experienceRefund:[],
    page:1,
    pageNum:1,
    experienceBookingStartDate:null,
    experienceBookingEndDate:null,
    experienceConfirmedDataLength: 0,
    experienceFollowupDataLength:0,
    experienceBookingDataLength: 0,
    experienceCancelledDataLength: 0,
    experienceRefundDataLength: 0,
    experienceStatusVal: 'requested',
    experienceBookingsSecId: null,
    experienceConfirmed:[],
    experienceCancelled:[],
    experienceAvailabilityList:[],
    experienceBookingNxtPgeId:null,
    experienceConfirmNxtPgeId:null,
    experienceFollowupNxtPgeId:null,
    experienceRefundNxtPgeId:null,
    experienceCancelledNxtPgeId:null,
    experienceBookingCurrentPgeId:null,
    experienceConfirmCurrentPgeId:null,
    experienceCancelledCurrentPgeId:null,
    experienceFollowupCurrentpgeId:null,
    experienceRefundCurrentpgeId:null,
    experiencelBookedSearchVal:"",
    experiencelConfirmedSearchVal:"",
    experiencelCancelledSearchVal:"",
    experienceAvailabilitySearchVal:"",
    experienceFollowupSearchVal:"",
    experienceRefundSearchVal:"",
    availabilitySingledata:{},
    guestModalOpenTrue:false,
    dateRangeItem:{},
    recurringDate:{},
    recurringModalOpenTrue:false,
    scheduleModalOpenTrue:false,
    adultsGuest:0,
    childrenGuest:0,
    infantGuest:0,
    startDate:null,
    endDate:null,
    specificDate:{},
    flexiDate:null
  };


  
  const experienceBookingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_EXPERIENCE_BOOKING_LIST: {
            return {
                ...state,
                experienceBooking: payload,
            };
        }
        case GET_EXPERIENCE_REFUNDED_LIST: {
            return {
                ...state,
                experienceRefund: payload,
            };
        }
        case GET_EXPERIENCE_FOLLOWUP_LIST: {
            return {
                ...state,
                experienceFollowup: payload,
            };
        }
        case GET_EXPERIENCE_CONFIRMED_LIST: {
            return {
                ...state,
                experienceConfirmed: payload,
            };
        }
        case GET_EXPERIENCE_CANCELLED_LIST: {
            return {
                ...state,
                experienceCancelled: payload,
            };
        }
        case GET_AVAILABILITY_LIST: {
            return {
                ...state,
                experienceAvailabilityList: payload,
            };
        }
        case EXPERIENCE_BOOKING_PAGE_ID: {
            return {
                ...state,
                pageNum: payload,
            };
        }
        case EXPERIENCE_BOOKING_START_DATE: {
            return {
                ...state,
                experienceBookingStartDate: payload,
            };
        }
        case EXPERIENCE_BOOKING_END_DATE: {
            return {
                ...state,
                experienceBookingEndDate: payload,
            };
        }
        case GET_EXPERIENCE_CONFIRMED_TOTAL: {
            return {
                ...state,
                experienceConfirmedDataLength: payload,
            };
        }
        case  GET_EXPERIENCE_FOLLOWUP_TOTAL : {
            return {
                ...state,
                experienceFollowupDataLength: payload,
            };
        }

        case  GET_EXPERIENCE_REFUNDED_TOTAL : {
            return {
                ...state,
                experienceRefundDataLength: payload,
            };
        }

        case GET_EXPERIENCE_BOOKED_TOTAL: {
            return {
                ...state,
                experienceBookingDataLength: payload,
            };
        }
        case GET_EXPERIENCE_CANCELLED_TOTAL: {
            return {
                ...state,
                experienceCancelledDataLength: payload,
            };
        }
        case EXPERIENCE_STATUS_VALUE: {
            return {
                ...state,
                experienceStatusVal: payload,
            };
        }
        case EXPERIENCE_BOOKINGS_SECTION_ID: {
            return {
                ...state,
                experienceBookingsSecId: payload,
            };
        }
        case EXPERIENCE_BOOKING_NEXT_PAGE_ID: {
            return {
                ...state,
                experienceBookingNxtPgeId: payload,
            };
        }

        case EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID: {
            return {
                ...state,
                experienceFollowupNxtPgeId: payload,
            };
        }
        case EXPERIENCE_CONFIRMED_NEXT_PAGE_ID: {
            return {
                ...state,
                experienceConfirmNxtPgeId: payload,
            };
        }
        case EXPERIENCE_CANCELLED_NEXT_PAGE_ID: {
            return {
                ...state,
                experienceCancelledNxtPgeId: payload,
            };
        }

        case EXPERIENCE_REFUNDED_NEXT_PAGE_ID: {
            return {
                ...state,
                experienceRefundNxtPgeId: payload,
            };
        }
        case EXPERIENCE_BOOKING_CURRENT_PAGE_ID: {
            return {
                ...state,
                experienceBookingCurrentPgeId: payload,
            };
        }

        case EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID: {
            return {
                ...state,
                experienceFollowupCurrentpgeId: payload,
            };
        }

        case EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID: {
            return {
                ...state,
                experienceConfirmCurrentPgeId: payload,
            };
        }
        case EXPERIENCE_REFUNDED_CURRENT_PAGE_ID: {
            return {
                ...state,
                experienceCancelledCurrentPgeId: payload,
            };
        }

        case EXPERIENCE_CANCELLED_CURRENT_PAGE_ID: {
            return {
                ...state,
                experienceRefundCurrentpgeId: payload,
            };
        }
        case EXPERIENCE_BOOKING_PAGE_ID: {
            return {
                ...state,
                page: payload,
            };
        }
        case EXPERIENCE_BOOKED_SEARCH_VALUE: {
            return {
              ...state,
              experiencelBookedSearchVal: payload
            }
        }
        case EXPERIENCE_CONFIRMED_SEARCH_VALUE: {
            return {
              ...state,
              experiencelConfirmedSearchVal: payload
            }
        }
        case EXPERIENCE_REFUNDED_SEARCH_VALUE: {
            return {
              ...state,
              experienceRefundSearchVal: payload
            }
        }
        

        case EXPERIENCE_FOLLOWUP_SEARCH_VALUE: {
            return {
              ...state,
              experienceFollowupSearchVal: payload
            }
        }
        case EXPERIENCE_CANCELLED_SEARCH_VALUE: {
            return {
              ...state,
              experiencelCancelledSearchVal: payload
            }
        }
        case EXPERIENCE_REQUESTED_SEARCH_VALUE: {
            return {
              ...state,
              experiencelAvailabilitySearchVal: payload
            }
        }
        case EXPERIENCE_AVAILABILITY_SINGLE_DATA: {
            return {
              ...state,
              availabilitySingledata: payload
            }
        }
        case DATE_RANGE_ITEM: {
            return {
              ...state,
              dateRangeItem: payload
            }
        }
        case RECURRING_DATE: {
            return {
              ...state,
              recurringDate: payload
            }
        }
        case RECURRING_MODAL_OPENINI_TRUE: {
            return {
              ...state,
              recurringModalOpenTrue: payload
            }
        }
        case SCHEDULE_MODAL_OPEN_TRUE: {
            return {
              ...state,
              scheduleModalOpenTrue: payload
            }
        }
        case GUEST_MODAL_OPEN_TRUE: {
            return {
              ...state,
              guestModalOpenTrue: payload
            }
        }
        case ADULTS_GUEST: {
            return {
              ...state,
              adultsGuest: payload
            }
        }
        case CHILDRENS_GUEST: {
            return {
              ...state,
              childrenGuest: payload
            }
        }
        case INFANTS_GUEST: {
            return {
              ...state,
              infantGuest: payload
            }
        }
        case PACKAGE_START_DATE: {
            return {
              ...state,
              startDate: payload
            }
        }
        case PACKAGE_END_DATE: {
            return {
              ...state,
              endDate: payload
            }
        }
        case SPECIFIC_DATE_VALUE: {
            return {
              ...state,
              specificDate: payload
            }
        }
        case FLEXI_DATE_ITEM: {
            return {
              ...state,
              flexiDate: payload
            }
        }
          
        default:
            return state;
    }
};

export default experienceBookingReducer;