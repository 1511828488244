import {
  HOLIDAY_PENDING_REVIEW_LIST,
  HOLIDAY_REJECTED_REVIEW_LIST,
  HOLIDAY_REVIEW_END_DATE,
  HOLIDAY_REVIEW_PAGE_ID,
  HOLIDAY_REVIEW_PUBLISHED_LIST,
  HOLIDAY_REVIEW_START_DATE,
  HOLDAY_PACK_REVIEWS_LIST
} from "../actions/types";

const initialState = {
  holidayReviewList: [],
  holidayPublishedReviewList: [],
  holidayRejectedReviewList:[],
  holidayReviewStartDate: null,
  holidayReviewEndDate: null,
  holidayReviewStatusVal: "Review",
  holidayReviewSecName: {},
  page: 1,
  getHolidayReqAllData: {},
  holidayReqSearchValue: "",
  holidayPackReviewList: []
};
const holidayReviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HOLIDAY_PENDING_REVIEW_LIST:
      return {
        ...state,
        holidayReviewList: payload,
      };
    case HOLIDAY_REVIEW_PAGE_ID:
      return {
        ...state,
        page: payload,
      };
    case HOLIDAY_REVIEW_PUBLISHED_LIST:
      return {
        ...state,
        holidayPublishedReviewList: payload,
      };
      case HOLIDAY_REVIEW_START_DATE:
           return{
               ...state,
               holidayReviewStartDate:payload
           }
       case HOLIDAY_REVIEW_END_DATE:
           return{
               ...state,
               holidayReviewEndDate:payload
           }
       case HOLIDAY_REJECTED_REVIEW_LIST:
        return{
          ...state,
          holidayRejectedReviewList:payload
        }
        case HOLDAY_PACK_REVIEWS_LIST: // Package reviews
          return {
            ...state,
            holidayPackReviewList: payload
          }
    default:
      return state;
  }
};
export default holidayReviewReducer;
