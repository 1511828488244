import { 
    HONEYMOON_STORY_LIST,
    HONEYMOON_STORY_DETAILS,
    HONEYMOON_REQUESTED,
    HONEYMOON_PLANNING,
    HONEYMOON_CONFIRMED,
    HONEYMOON_CANCELLED,
    HONEYMOON_SEARCH_TERM,
    HONEYMOON_START_DATE,
    HONEYMOON_END_DATE,
    HONEYMOON_PAGE_NO,
    HONEYMOON_DESTINATION_LIST,
  } 
  from '../actions/types';

const initialState = {
  honeymoonStoryDetails: null,
  honeymoonStoryList: [],
  honeymoonRequested: [],
  honeymoonPlanning: [],
  honeymoonConfirmed: [],
  honeymoonCancelled: [],
  searchTerm: null,
  startDate: null,
  endDate: null,
  pageNo: 1,
  destinationList: []
};

const honeymoonReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        
        case HONEYMOON_STORY_LIST:{
          console.log(payload);
            return {
              ...state,
              honeymoonStoryList: payload
            }
        }
        case HONEYMOON_STORY_DETAILS:{
          return {
            ...state,
            honeymoonStoryDetails: payload
          }
        }
        case HONEYMOON_REQUESTED:{
          return {
            ...state,
            honeymoonRequested: payload
          }
        }
        case HONEYMOON_PLANNING:{
          return {
            ...state,
            honeymoonPlanning: payload
          }
        }
        case HONEYMOON_CONFIRMED:{
          return {
            ...state,
            honeymoonConfirmed: payload
          }
        }
        case HONEYMOON_CANCELLED:{
          return {
            ...state,
            honeymoonCancelled: payload
          }
        }
        case HONEYMOON_SEARCH_TERM:{
          return {
            ...state,
            searchTerm: payload
          }
        }
        case HONEYMOON_START_DATE:{
          return {
            ...state,
            startDate: payload
          }
        }
        case HONEYMOON_END_DATE:{
          return {
            ...state,
            endDate: payload
          }
        }
        case HONEYMOON_PAGE_NO:{
          return {
            ...state,
            pageNo: payload
          }
        }
        case HONEYMOON_DESTINATION_LIST:{
          return {
            ...state,
            destinationList: payload
          }
        }
        
        default:
           return state;
    }
};

export default honeymoonReducer;
