import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/partials/autoComplete.scss';

export class Autocomplete extends Component {
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired
  };

  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: "",
    changeCityBool: false,
  };

  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;
    this.props.postLocationCityData(e.currentTarget.value)
    const filteredOptions = options.filter(
      (optionName) => 
      optionName.city.toLowerCase().indexOf(userInput.toLowerCase()) > -1      
    )
    .sort((a, b) => {
      if (b.city.toLowerCase().startsWith(userInput) > a.city.toLowerCase().startsWith(userInput)) return 1;
      if (b.city.toLowerCase().startsWith(userInput) < a.city.toLowerCase().startsWith(userInput)) return -1;
    });

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      changeCityBool: true,
      userInput: e.target.value,
    });
  };
  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
    this.props.postLocationCityData(e.currentTarget.innerText)
    this.setState({
      userInput:""
    })
  };
  onBlur = (e) => {
    setTimeout(()=>{
      this.setState({
        showOptions: false
      })
    }, 100);
  }

  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;
  };

  render() {
    const {
      onChange,
      // onBlur,
      onClick,
      onKeyDown,
      state: { activeOption, filteredOptions, showOptions, userInput, changeCityBool }
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
        <div className="option-container">
          <ul className="options_test">
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              }
              return (
                <li className={`${className} item-option`} key={optionName.id} onClick={onClick} style={{listStyle:"none", cursor:"pointer", padding:"8px", paddingLeft:'20px'}}>
                  {optionName.city}
                </li>
              );
            })}
          </ul>
          </div>
        );
      } 
    };
    
    return (
      <React.Fragment>
        <div className="search">
          <input
            type="text"
            className="search-box"
            onChange={ onChange}
            onKeyDown={onKeyDown}
            onBlur={this.onBlur}
            value={this.props.locationCityval }
          />
        </div>
        {optionList}
      </React.Fragment>
    );
  };
};

export default Autocomplete;
