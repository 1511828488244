
import { useState, useEffect } from 'react';
import { Row, Col } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import WeddingStoryListing from './weddingStoryListing';
import AddWeddingStory from './addWeddingStory';
import EditWeddingStory from './editWeddingStory';
import { useDispatch, useSelector } from 'react-redux';
import * as weddingAction from "../../../actions/weddingAction";
import { Spinner } from 'react-bootstrap';

const Index = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [isAdd, setIsAdd] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [searchVal,setSearchVal]=useState('')
    const storyList = useSelector((state) => state.wedding.weddingStoryList);
    const storyDetails = useSelector((state) => state.wedding.weddingStoryDetails);
    const isDataLoading = useSelector(
        (state) => state.holidayListing.isDataLoading
      );
    useEffect(() => {
        dispatch(weddingAction.weddingStoryList());
    }, []);

    useEffect(() => {
        selectedId && dispatch(weddingAction.weddingStoryDetails(selectedId));
    }, [selectedId]);

    useEffect(() => {
        storyList?.length && setSelectedId(storyList[0].id);//selectedId || 
        if (storyList?.length) {
            setSelectedId(storyList[0].id);
            setIsAdd(false)
        } else {
            setSelectedId(null);
            setIsAdd(true)
        }
    }, [storyList]);

    return (
        <div>
            <p className="title-holder">Wedding Stories</p>
            <div>
                <Row>
                    <Col style={{ paddingRight: '20px', marginRight: '-20px' }} className="gutter-row" xs={24} lg={8}>
                        <Row className="gutter-row">

                            <WeddingStoryListing
                                storyList={storyList}
                                setIsAdd={setIsAdd}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                searchVal={searchVal}
                                setSearchVal={setSearchVal}
                            />
                        </Row>

                    </Col>
                    <Col className="gutter-row" xs={24} lg={16}>
                    {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
                        {isAdd ? <AddWeddingStory setIsAdd={setIsAdd} /> : <EditWeddingStory setSearchVal={setSearchVal} storyDetails={storyDetails} storyDetailsId={selectedId} />}

                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Index;