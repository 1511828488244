import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CommonAddNew from '../../../common/commonAddNew';
import UpdateHolidaysSection from './updateHolidaysSection';
import AddNewSection from '../../../common/addNewSection';
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import { DONE, CANCEL } from '../../../utils/constants'
import { useSnackbar } from "notistack";
import {
  holidaySelectedImageUrl,
  holidayImageUpload ,
  getHolidaySectionList,
  addHolidaySection,
} from '../../../actions/holidaySectionAction';
import { Spinner } from 'react-bootstrap';

const HolidaysSectionIndex = () => {
  const [ showHolidayAddNewSectionModal, setShowHolidayAddNewSectionModal ] = useState(null);
  const [ holidayaddSectionName, setHolidayAddSectionName ] = useState("");
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")
  const [ holidayaddSectionDesc, setHolidayAddSectionDesc ] = useState("");
  const [ holidayAddSectionPriority, setHolidayAddSectionPriority ] = useState("");
  const [refCodeValue, setRefCodeValue] = useState("");

  const [ isAddImageErrorValidation, setIsAddImageErrorValidation ] = useState(null);
  const [ isAddHolidaySecNameErrorValidation, setIsAddHolidaySecNameErrorValidation ] = useState(null);
  const [ isAddHolidaySecDescErrorValidation, setIsAddHolidaySecDescErrorValidation ] = useState(null);
  const [ isAddHolidayPriorityEmptyErrorValidation, setIsAddHolidayPriorityEmptyErrorValidation ] = useState(null);
  const [ isAddHolidayPriorityNegativeError, setIsAddHolidayPriorityNegativeError ] = useState(null);
   const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ descMoreError, setDescMoreErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const holidaySection = useSelector(state => state.holidaySection.getHolidaySectionList);
  const holidayImageUrl = useSelector(state => state.holidaySection.holidaySelectedImgUrl);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getHolidaySectionList())    
  }, [holidayImageUrl]);
  
  const holidayAddSectionNameOnchangeHandler = (e) => {
    setHolidayAddSectionName(e.target.value);
  };
  const holidayAddSectionDescOnchangeHandler = (e) => {
    setHolidayAddSectionDesc(e.target.value);
  };
  
  const holidayAddSectionPriorityOnchangeHandler = (e) => {
    setIsAddHolidayPriorityNegativeError(false);
    setHolidayAddSectionPriority(e.target.value);
    if(e.target.value <= -1)setIsAddHolidayPriorityNegativeError(true);
  };

  const refrenceCodeHandler = (e) => {
    
    let code = e.target.value.toLocaleUpperCase()
    const filteredData = holidaySection.filter(item => item.code === code)
    if(filteredData.length){
      setCodeMatchError(true)
    }else{
      setCodeMatchError(false)
    }
    setRefCodeValue(code)
  }

  const updatePic = (data) => {
    dispatch(holidaySelectedImageUrl(data.url));
  };

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
    dispatch(holidayImageUpload(croppedFile, updatePic));
    }
  };      

  const refreshAddEventHolidayData = () => {
    dispatch(getHolidaySectionList());
    enqueueSnackbar("Added successfully", {
      variant: "success",
    });
  };

  const holidayAddSectionSubmitHandler = (e) => {
    e.preventDefault();
    setIsAddImageErrorValidation(false);
    setIsAddHolidaySecNameErrorValidation(false);
    setIsAddHolidaySecDescErrorValidation(false);
    setIsAddHolidayPriorityEmptyErrorValidation(false);
    setIsAddHolidayPriorityNegativeError(false);
    setIscodeError(false);
    setCodeLessErrorr(false);
    setCodeMoreError(false)
    
    const holidaySectionAddData = {
      "image":holidayImageUrl,
      "name": holidayaddSectionName,
      "description": holidayaddSectionDesc,
      "priority":holidayAddSectionPriority,
      "code":refCodeValue,
      "title_text":imageTitle,
      "alt_text":altText
    }
    if(holidayImageUrl === null)setIsAddImageErrorValidation(true);
    else if(holidayaddSectionName === "")setIsAddHolidaySecNameErrorValidation(true);
    else if(holidayaddSectionDesc === "")setIsAddHolidaySecDescErrorValidation(true);
    else if(holidayaddSectionDesc.length > 200)setDescMoreErrorr(true)
    else if(holidayAddSectionPriority === "")setIsAddHolidayPriorityEmptyErrorValidation(true);
    else if(holidayAddSectionPriority <= -1)setIsAddHolidayPriorityNegativeError(true);
    else if(refCodeValue === "")setIscodeError(true)
    else if(refCodeValue.length < 2)setCodeLessErrorr(true)
    else if(refCodeValue.length > 2)setCodeMoreError(true)
    else if(refCodeValue.length == 2 && codeMatchError)setCodeMatchError(true)
    else {
      dispatch(addHolidaySection(holidaySectionAddData, refreshAddEventHolidayData));
      setShowHolidayAddNewSectionModal(false)
      dispatch(holidaySelectedImageUrl(null));
      setHolidayAddSectionName("");
      setHolidayAddSectionDesc("")
      setHolidayAddSectionPriority("");
      setRefCodeValue("");
      setAltText("")
      setImageTitle("")
      dispatch(getHolidaySectionList());
    }
  };

  return (
    <>
      <h2> {holidaySection.length} Holidays Sections</h2>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop:"30px"}}>
        <UpdateHolidaysSection/>
        <CommonAddNew 
          onClick={() => setShowHolidayAddNewSectionModal(true)}
          textTitle="Add New Section"
        />
        {isDataLoading &&
          <div className="blured-backround">
            <p style={{ paddingTop: "40%", marginLeft: "10%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
      </div>
      {showHolidayAddNewSectionModal && 
        <AddNewSection 
          showMyModal={showHolidayAddNewSectionModal}
          openHandler={showHolidayAddNewSectionModal}
          togglebackDrop={showHolidayAddNewSectionModal}
          heading="Add new Section"
          image={ holidayImageUrl? holidayImageUrl:DefaultImage}
          imageText="Image"
          labelTextOne="Section name"
          valueOne={holidayaddSectionName}
          valueThree={holidayaddSectionDesc}
          onChangeOne={holidayAddSectionNameOnchangeHandler}
          onChangeThree={holidayAddSectionDescOnchangeHandler}
          labelTextTwo="Priority"
          labelTextThree="Description"
          codeRefrence="Code"
          valueTwo={holidayAddSectionPriority}
          onChangeTwo={holidayAddSectionPriorityOnchangeHandler}
          refCodeValue={refCodeValue}
          refrenceCodeHandler={refrenceCodeHandler}
          btnTextOne={CANCEL}
          btnTextTwo={DONE}
          handleSubmit={holidayAddSectionSubmitHandler}
          closeModal={() => setShowHolidayAddNewSectionModal(false)}
          closeHandle={() => setShowHolidayAddNewSectionModal(false)}
          handleFilePickChange={handleImageChange}
          isHolidayAddNewSection={true}
          isHolidayUpdateSection={false}
          isImage={isAddImageErrorValidation}
          isSectionNameError={isAddHolidaySecNameErrorValidation}
          isSectionDescError={isAddHolidaySecDescErrorValidation}
          isPriorityEmptyError={isAddHolidayPriorityEmptyErrorValidation}
          isPriorityError={isAddHolidayPriorityNegativeError}
          iscodeError={iscodeError}
          codeLessError={codeLessError}
          descMoreError={descMoreError}
          codeMoreError={codeMoreError}
          codeMatchError={codeMatchError}
          isEventSection={true}
          isDescription={true}
          imageTitle={imageTitle}
          setImageTitle={setImageTitle}
          altText={altText}
          setAltText={setAltText}
       />
      }
    </>
  )
}

export default HolidaysSectionIndex;
