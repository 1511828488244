
import React from 'react';
import moment from 'moment';

import { myFamilyFirstStatusValues } from '../resources/status';
import { CONTACT_INFO, MESSAGE, MORE_INFO } from '../utils/constants';

const MyFamilyFirstBooking = ({ id, data,locationsPreferred, occasion, requestedBy, email, contactNum, specialRequest, onClick, myFamilyFirstStatusChange, value, statusValues, messageTitle, refCode ,isConfirmed,}) => {

  return (
    <div>
      <div className="enquiry-card-holder">
        <div className="organization-details" style={{width: "450px"}}>
          <span style={{ color: "#2196F3", fontSize: "12px", display: "block" }}>{refCode}</span>
          <h1>{locationsPreferred}</h1>
          <div className="info">
            <p>{occasion}</p>
            <p>
              {requestedBy}
            </p>
            <p>
                <span style={{fontSize:"12px"}}>Booked on {moment(data?.created).format("Do MMMM, YYYY")}</span>
                </p>
                {isConfirmed?<p style={{fontSize:"14px"}}><b>{data?.price?`Order Value:${data?.price}`:"Amount not mentioned"}</b></p>:""} 
          </div>
        </div>
        {
          <div className="contact-info">
            <div className="title">
              <p>{CONTACT_INFO}</p>
            </div>
            <div className="contact-details">
              <p className="email">
                {email}
              </p>
              <p>
                {contactNum}
              </p>
            </div>
          </div>
        }
        <div className="contact-info">
          <div className="title">
            <p>{MESSAGE}</p>
          </div>
          <div className="contact-details">
            <p className="message-limit" title={messageTitle}>
              {specialRequest}
            </p>
          </div>
        </div>
        <div>
          <p
            style={{
              backgroundColor: "#E7E7E7",
              padding: "5px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer"
            }}
            onClick={onClick}
          >{MORE_INFO}</p>
          <select
            id="status-dropdown-select"
            className="status-dropdown"
            value={value}
            onChange={(event) => myFamilyFirstStatusChange(event, id,data)}
          >
            {myFamilyFirstStatusValues && myFamilyFirstStatusValues.map(option => (
              <option className="greenColor" id="status-option" value={option.value}>{option.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default MyFamilyFirstBooking;
