import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "../assets/css/partials/featuredPackage.scss";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import ArrowDown from "../../src/assets/images/arrowDown.svg";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import * as corpListingAction from "../../src/actions/corpListingAction";
import * as holidayListingAction from "../../src/actions/holidaysAction";
import * as experienceListingAction from "../../src/actions/experienceListingAction";
import { useSnackbar } from "notistack";
const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

const MoveToCorporate = ({copyToCorp, packSlug, refreshList, fromExp, isInternational, data, currentPackage,section}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [select, setSelect] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();
    const selectedMenuDropdown = useSelector((state) => state.corpListing?.selectedDropdown);
    const movedPackSec = useSelector((state) => state.corpListing?.movedPackSec);
    const newSelectedDropDownValue = isInternational ? selectedMenuDropdown.filter(item => item.name === "International"): selectedMenuDropdown.filter(item => item.name !== "Gift Voucher" && item.name !== "International");
    const isPackMoved = data?.packages?.filter(item => {
        return item.copy_to_crop 
    })
    const handleClickSelect = (event) => {
        // if(isPackMoved?.length && movedPackSec?.message !== "Does not exist"){
        //     dispatch(corpListingAction.getMovedPackSectionId(data?.title));
        // }else{
        //     dispatch(corpListingAction.selectDropdown());
        // }
        setSelect(event.currentTarget);
    };

    const handleCloseSelect = (item) => {
        if(isPackMoved?.length && movedPackSec?.message !== "Does not exist"){
            setSectionId(item.section_id)
            setDropdownValue(item.section_name); 
        }else{
            setSectionId(item.id)
            setDropdownValue(item.name); 
        }
        // setIsOnchange(true);
        setSelect(null);
    };
    const refreshReload=()=>{
        setTimeout(() => {
            window.location.reload();
            
        }, 1000);

    }
    const handleUpdateSuccess = (message) => {
        enqueueSnackbar(`${message}`, {
            variant: "success",
        });
        setTimeout(()=>{
            refreshReload()
        },100)
       
        
    };
    const handleUpdateError = (message) => {
        enqueueSnackbar(`${message}`, {
            variant: "error",
        });
        
    };

    const handleCopyToCorp = (event) => {
        setChecked(event.target.checked)

        if(event.target.checked==true){
          
        dispatch(corpListingAction.getMovedPackSectionId(data?.title));
        dispatch(corpListingAction.selectDropdown());

        }
        else if(event.target.checked==false){
             dispatch(corpListingAction.getReversedPackSectionId(data?.slug,handleUpdateSuccess,handleUpdateError,section));
        }
        
    };

    const handleSave = (e) => {
        e.preventDefault()
        const data = {
            "slug": packSlug,
            "sec_id": sectionId
        }
        if(fromExp){
            dispatch(experienceListingAction.handleCopyPackToCorp(data, handleSuccess, handleError));
        }else{
            dispatch(holidayListingAction.handleCopyPackToCorp(data, handleSuccess, handleError));
        }
        }
    const handleSuccess = () => {
        enqueueSnackbar("Copied Successfully", {
            variant: "success",
        });
        refreshList()
    }
    const handleError = () => {
        enqueueSnackbar("Same title exists in corporate, please add the same package inside existing corporate package", {
            variant: "error",
        });
    }

    useEffect(() => {
        if(copyToCorp){
            setChecked(true)
        }else{
            setChecked(false)
            setDropdownValue(null)
        }
    },[copyToCorp, packSlug, currentPackage])
    
    
  return (
    <div className="d-flex mt-2">
      <div className="d-flex align-items-center featured_package" type="button">
            <div
            className="col-md-9 d-flex align-items-center"
            style={{
                paddingRight: "0px",
                paddingLeft: "0px",
            }}>
            <div
                    className="align-items-center"
                    style={{
                    color: "#000000",
                    width: "111px",
                    height: "16px",
                    left: "13px",
                    lineHeight: "16px",
                    paddingLeft: "7px",
                    paddingRight: "0px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "400",
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    }} >
                    Corporate
            </div>
            </div>
            <div
            className="col-md-3"
            style={{
                textAlign: "right",
                paddingRight: "0px",
                paddingLeft: "0px",
            }}>
            <PurpleSwitch
                checked={checked}
                // disabled={copyToCorp}
                size="small"
                width="17.5px"
                height="10px"
                top="11px"
                left="127px"
                color="#2196F3"
                name="featured"
                value="featured"
                onChange={(event) => handleCopyToCorp(event)}
            />
            </div>
        </div>
        {!copyToCorp &&<div
            className="section-dropdown"
            style={{
            position: "relative",
            cursor: "pointer",
            width: "auto",
            }}>
            <Button
            style={{ paddingRight: "32px" }}
            aria-controls="section-menu"
            aria-haspopup="true"
            disabled={!checked}
            onClick={handleClickSelect}
            // onChange={(event) => setDropdownValue(event.target.value)}
                >
            { dropdownValue ? dropdownValue : "Select section"}
            <img
                src={ArrowDown}
                style={{ position: "absolute", right: "12px", top: `${false ? "4px" : "12px"}` }}
                onClick={() => { }}
            />
            </Button>
            <Menu
            id="section-menu"
            anchorEl={select}
            keepMounted
            open={select}
            onClose={() => setSelect(null)}
            >
            {isPackMoved?.length && movedPackSec?.message !== "Does not exist"? 
                <MenuItem
                    onClick={() => handleCloseSelect(movedPackSec)}
                    key={movedPackSec.section_id}
                    >
                    {movedPackSec.section_name}
                </MenuItem>
            :
            newSelectedDropDownValue &&
                newSelectedDropDownValue.map((item) => {
                return (
                    <MenuItem
                    onClick={() => handleCloseSelect(item)}
                    key={item.id}
                    >
                    {item.name}
                    </MenuItem>
                );
                })}
                
            </Menu>

        </div>}
        {!copyToCorp&&<div>
            <button type="submit" disabled={!dropdownValue} className="btn btn-primary btn-save" onClick={handleSave}>
                Add
            </button>
        </div>}
    </div>
  );
};

export default MoveToCorporate;
