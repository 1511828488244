import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as GiftVoucherAction from "../../../actions/giftVoucherActions";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    resize: {
        fontSize: 11,
    },
}));

const Datefilter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const startDate = useSelector(
        (state) => state.voucherListing.filterStartDate
    );
    const endDate = useSelector((state) => state.voucherListing.filterEndDate);

    const handleStartDateChange = (date) => {
        dispatch(GiftVoucherAction.filterStartDate(moment(date).format("YYYY-MM-DD")));
    };

    const handleEndDateChange = (date) => {
        dispatch(GiftVoucherAction.filterEndDate(moment(date).format("YYYY-MM-DD")));
    };

    const handleStartClear = (e) => {
        e.stopPropagation();
        dispatch(GiftVoucherAction.filterStartDate(null));
    };

    const handleEndClear = (e) => {
        e.stopPropagation();
        dispatch(GiftVoucherAction.filterEndDate(null));
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                }}
            >
                <p style={{ margin: 0, marginRight: 10 }}> Date filter from</p>
                <DatePicker
                    style={{
                        borderWidth: 0,
                        width: 90,
                        fontSize: 10,
                    }}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                        endAdornment: (
                            <ClearIcon
                                style={{ fontSize: 12, cursor: "pointer" }}
                                onClick={(e) => handleStartClear(e)}
                            />
                        ),
                    }}
                    size="small"
                    disableFuture
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="none"
                    id="date-picker-inline"
                    label={!startDate && "Select"}
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    keyboardIcon={<ExpandMoreIcon />}
                />
                <p style={{ margin: 0, marginRight: 10, marginLeft: 10 }}> to</p>
                <DatePicker
                    style={{
                        borderWidth: 0,
                        width: 90,
                    }}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                        endAdornment: (
                            <ClearIcon
                                style={{ fontSize: 12, cursor: "pointer" }}
                                onClick={(e) => handleEndClear(e)}
                            />
                        ),
                    }}
                    disableFuture
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="none"
                    id="date-picker-inline"
                    label={!endDate && "Select"}
                    value={endDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    keyboardIcon={<ExpandMoreIcon />}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default Datefilter;
