import { Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import AvailabilityPackage from "../../../common/checkAvailability/availabilityPackage";
import AvailabilityRecurring from "../../../common/checkAvailability/availabilityRecurring";
import AvailabilitySpecific from "../../../common/checkAvailability/availabilitySpecific";
import AvailibilityDateRangePopup from "../../../common/checkAvailability/availibilityDateRangePopup";
import AvalibalityFlexiDatePopup from "../../../common/checkAvailability/avalibalityFlexiDatePopup";
import {
  scheduleModalOpenTrue,
  verifyGiftCard,
} from "../../../actions/bookingAction";
import { checkNumberOfGuest } from "../../../utils/index";
import moment from "moment";

const PartnerBookingConfirmPopUp = ({
  open,
  handleClose,
  data,
  availabilityData,
  guestClickHandler,
  children,
  amountHandleChange,
  amountValue,
  confirmClick,
  amountError,
  amountLengthError,
  guestError,
  adultGuestError,
  scheduleError,
}) => {
  const { pack_title, ref_id } = data;
  const guestOpen = useSelector(
    (state) => state.experienceBooking.guestModalOpenTrue
  );
  const adultsGuest = useSelector(
    (state) => state.experienceBooking.adultsGuest
  );
  const childrenGuest = useSelector(
    (state) => state.experienceBooking.childrenGuest
  );
  const infantGuest = useSelector(
    (state) => state.experienceBooking.infantGuest
  );

  const [popupData, setPopupData] = useState(availabilityData);
  const [openFlexiDatePopup, setOpenFlexiDatePopup] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openRecurringModal, setOpenRecurringModal] = useState(false);
  const [giftVoucher, setGiftVoucher] = useState("");
  const [vgResMsg, setVgResMsg] = useState({
    type: null,
    msg: "",
    points_received: null,
  });
  const [enableCbBtn, setEnableCbBtn] = useState(
    availabilityData.gift_card_redeemed
  );
  const [openPackageCalanderModal, setOpenPackageCalanderModal] = useState(
    false
  );
  const [openSpecificCalanderModal, setOpenSpecificCalenderModal] = useState(
    false
  );

  const dispatch = useDispatch();

  const scheduleClickHandler = () => {
    dispatch(scheduleModalOpenTrue(true));
    if (availabilityData.pack_schedule_type === "date_range")
      setOpenDateRange(true);
    else if (availabilityData.pack_schedule_type === "recurring") {
      setOpenRecurringModal(true);
    } else if (availabilityData.pack_schedule_type === "package")
      setOpenPackageCalanderModal(true);
    else if (availabilityData.pack_schedule_type === "specific_dates")
      setOpenSpecificCalenderModal(true);
    else {
      setOpenFlexiDatePopup(true);
    }
  };

  const DateRangeDoneHandler = (data) => {
    setPopupData({ ...popupData, schedule: data });
    setOpenDateRange(false);
  };

  const flexiDateCalanderDonedHandler = (date) => {
    setOpenFlexiDatePopup(false);
    setPopupData({ ...popupData, schedule: date });
  };

  const specificCalenderDoneModal = (date) => {
    setOpenSpecificCalenderModal(false);
    setPopupData({ ...popupData, schedule: date });
  };

  const recurringCalanderDondHandler = (date) => {
    setOpenRecurringModal(false);
    setPopupData({ ...popupData, schedule: date });
  };

  const packaegDateCalanderDondHandler = (date) => {
    setOpenPackageCalanderModal(false);
    setPopupData({ ...popupData, schedule: date });
  };

  const moreClickHandler = () => {
    setOpenFlexiDatePopup(true);
  };

  const handleVgSuccess = (data) => {
    console.log(data);
    setVgResMsg({
      type: "success",
      msg: data.data.message,
      points_received: data.data.points_received,
    });
    setEnableCbBtn(true);
  };
  const handleVgError = (data) => {
    console.log(data);
    setVgResMsg({
      type: "error",
      msg: data.data.message,
      points_received: null,
    });
  };
  const applyGiftCard = (e) => {
    e.preventDefault();
    console.log(giftVoucher);

    console.log(popupData.id);
    dispatch(
      verifyGiftCard(popupData.id, giftVoucher, handleVgSuccess, handleVgError)
    );
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "auto",
          maxWidth: "430px",
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <div style={{ marginTop: "35px", marginBottom: "25px" }}>
          <h4 style={{ marginLeft: "25px" }}>Confirm Request</h4>
          <span
            style={{ position: "absolute", right: 35, top: 50 }}
            onClick={handleClose}
          >
            <ImCross />
          </span>
        </div>
        <form>
          <div className="message-box" style={{ width: "370px" }}>
            <p style={{ textAlign: "center", color: "#2196F3" }}>
              {pack_title}
            </p>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Ref. ID: {ref_id}{" "}
            </p>
            <div
              className="contact-details-holder"
              style={{ justifyContent: "center" }}
            >
              <div className="contact-info " style={{ width: "250px" }}>
                <div className="title">
                  <p>Requested for</p>
                </div>
                <div
                  className="contact-details"
                  style={{ height: "auto", textAlign: "center" }}
                >
                  <p
                    className="email"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        textAlign: "center",
                      }}
                      value={
                        Array.isArray(popupData.schedule)
                          ? popupData.schedule.slice(0, 2).join(", ")
                          : typeof popupData.schedule === "object" &&
                            popupData.schedule !== null &&
                            popupData.schedule.to !== ""
                          ? `${moment(popupData.schedule.from, 'DD-MM-YYYY').format('DD-MM-YYYY')} ${ popupData.schedule.to ? `to ${moment(popupData.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}`
                          : typeof popupData.schedule === "object" &&
                            popupData.schedule !== null &&
                            popupData.schedule.to === ""
                          ? `${popupData.schedule.from}`
                          : popupData.schedule === null
                          ? "N/A"
                          : popupData.schedule
                      }
                      onClick={scheduleClickHandler}
                    />
                  </p>
                  {popupData?.schedule?.length > 2 && (
                    <span
                      onClick={moreClickHandler}
                      style={{ marginLeft: "10px" }}
                    >
                      {" "}
                      +{popupData.schedule.length - 2} more
                    </span>
                  )}
                  {scheduleError && (
                    <p style={{ color: "red" }}>Please Select Schedule</p>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px" }} onClick={guestClickHandler}>
              <input
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  textAlign: "center",
                }}
                value={
                  guestOpen === true
                    ? checkNumberOfGuest(
                        adultsGuest,
                        childrenGuest,
                        infantGuest
                      )
                    : checkNumberOfGuest(
                        popupData.adults,
                        popupData.children,
                        popupData.infants
                      )
                }
              />
              <div
                style={{
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "25%",
                }}
              ></div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  color: "#797979",
                }}
              >
                People
              </p>
              {guestError && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Please Enter No Of Guest
                </p>
              )}
              {adultGuestError && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Adult Guest Is Mandatory
                </p>
              )}
            </div>
          </div>

          <div
            className="input--wrapper"
            style={{
              width: "370px",
              margin: "20px 21px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <div className="amountInput-holder" style={{ background: "none" }}>
              <input
                style={{ outline: "none" }}
                type="text"
                disabled={enableCbBtn}
                placeholder={"Enter the Gift Card Code"}
                value={
                  popupData.gift_card_redeemed
                    ? popupData.gift_card_code
                    : giftVoucher
                }
                onChange={(e) => setGiftVoucher(e.target.value)}
              />
              <button
                disabled={enableCbBtn}
                className="btn btn-primary"
                style={{
                  background: enableCbBtn ? "#5CB85C" : "#2196F3",
                  marginLeft: 10,
                }}
                onClick={applyGiftCard}
              >
                {enableCbBtn ? "Applied" : "Apply"}
              </button>
            </div>
            {vgResMsg?.type === "error" && (
              <p style={{ color: "red" }}>{vgResMsg.msg}</p>
            )}
            {vgResMsg?.type === "success" && (
              <span>
                <p style={{ color: "green" }}>{vgResMsg.msg}</p>
                <p style={{ color: "green" }}>
                  You have received {vgResMsg.points_received} points
                </p>
              </span>
            )}
            {popupData.gift_card_redeemed && (
              <p style={{ color: "green" }}>Gift Card Code already applied</p>
            )}
            {/*  */}
          </div>

          <div
            className="input--wrapper"
            style={{
              width: "370px",
              margin: "20px 21px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <label>Enter amount collected </label>
            <div className="amountInput-holder">
              <p>Rs</p>
              <input
                style={{ outline: "none" }}
                type="text"
                value={amountValue}
                onChange={amountHandleChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            {amountError && <p style={{ color: "red" }}>Please Enter Amount</p>}
            {amountLengthError && (
              <p style={{ color: "red" }}>
                Amount should be less than six digit
              </p>
            )}
          </div>

          <div className="cta-holder" style={{ marginBottom: "8px" }}>
            <button
              type="button"
              className="btn cancel-btn"
              disabled={!enableCbBtn}
              style={{ background: "#2196F3" }}
              onClick={() => confirmClick(popupData)}
            >
              Confirm booking
            </button>
          </div>
        </form>
        {openFlexiDatePopup && (
          <AvalibalityFlexiDatePopup
            doneHandler={flexiDateCalanderDonedHandler}
            flexiAvalabilityDate={popupData}
          />
        )}

        {openDateRange && (
          <AvailibilityDateRangePopup
            dateRangeAvailabilityData={popupData}
            DoneHandler={DateRangeDoneHandler}
          />
        )}
        {openRecurringModal && (
          <AvailabilityRecurring
            donedHandler={recurringCalanderDondHandler}
            recurringAvailabilityData={popupData}
          />
        )}
        {openPackageCalanderModal && (
          <AvailabilityPackage
            availabilityData={popupData}
            DonedHandler={packaegDateCalanderDondHandler}
          />
        )}
        {openSpecificCalanderModal && (
          <AvailabilitySpecific
            specificAvailabilityData={popupData}
            DonedHandler={specificCalenderDoneModal}
          />
        )}
      </div>
      <div>{children}</div>
    </Dialog>
  );
};

export default PartnerBookingConfirmPopUp;
