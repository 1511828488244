import React from 'react'

const MinMaxInput = ({state, setState, disableChar, placeholder1, placeholder2, setIsError}) => {

  const handleChange = (key, event) => {
    setState({ 
      ...state, 
      group_size: {
        ...state.group_size,
        [key] : event.target.value
       }
    });
    setIsError({ error:false, msg:'', name: "" })
  }

  return (
    <div className="d-flex">
      <input
        value={state?.group_size?.min}
        onKeyPress={disableChar ? (event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }: null}
        type="text"
        placeholder={placeholder1}
        className="form-control"
        onChange={(e) => handleChange("min", e)}
      />
      <span style={{ padding: "6px" }}>to</span>
      <input
        style={{ paddingLeft: '15px' }}
        value={state?.group_size?.max}
        onKeyPress={disableChar ? (event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }: null}
        type="text"
        className="form-control"
        placeholder={placeholder2}
        onChange={(e) => handleChange("max", e)}
      />
    </div>
  )
}

export default MinMaxInput