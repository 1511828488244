import React, { useRef } from 'react'

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Edit from "../assets/images/edit.svg";
import { FaFileUpload } from "react-icons/fa";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import JoditEditor from "jodit-react";


// import ArrowDown from "../../../assets/images/arrowDown.svg";
import { withStyles } from "@material-ui/core/styles";

const PurpleSwitch = withStyles({
    switchBase: {
      color: "white",
      "&$checked": {
        color: "#2196F3",
      },
      "&$checked + $track": {
        backgroundColor: "#2196F3",
        opacity: 1,
      },
    },
    checked: {},
    track: {},
  })(Switch);
const AdminAddNew = () => {

    const editor = useRef(null);

  // all options from https://xdsoft.net/jodit/doc/
  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
  };
    return (
        <div>
      <div className="packageDetails-holder">
        <form >
          <div className="form-options">
            <div>
              <h2>Add Event</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div
                className="section-dropdown"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "auto",
                }}
              >
                <div>
                  <Button
                    style={{ paddingRight: "32px" }}
                    aria-controls="section-menu"
                    aria-haspopup="true"
                    // onClick={handleClickSelect}
                  >
                    {/* {dropdownValue?.name?  dropdownValue?.name : "Select section"} */}
                    <img
                      alt="arrow"
                    //   src={ArrowDown}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "12px",
                      }}
                    />
                  </Button>
                  <Menu
                    id="section-menu"
                    // anchorEl={select}
                    keepMounted
                    // open={Boolean(select)}
                    // onClose={() => setSelect(null)}
                  >
                    {/* {selectedMenuDropdown &&
                      selectedMenuDropdown.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => handleCloseSelect(item)}
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })} */}
                  </Menu>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-primary btn-save">
                  Save
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="form-group form-custom">
              <label for=""> Title</label>
              <input
                // value={state?.pack_title}
                type="text"
                className="form-control"
                // onChange={(value) => handleChange("pack_title", value)}
              />
              <img
                src={Edit}
                style={{
                  position: "absolute",
                  right: "46px",
                  top: "124px",
                }}
              />
            </div>
            {/*Image UI Section*/}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flexDirection: "column" }}>
                <div
                //   onClick={() => setImageSelect(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                  }}
                >
                  <img
                    alt=""
                    // src={imageThubnail?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    style={{
                      color: "white",
                      position: "absolute",
                      bottom: 20,
                      left: 100,
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "#525252",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    0 images
                  </h6>
                </div>
                <div
                  style={{ flex: 0.5, position: "relative" }}
                  className="form-group form-custom"
                >
                  <label for="">Price</label>
                  <h6
                    style={{
                      position: "absolute",
                      left: "38px",
                      top: "53px",
                      fontWeight: "400",
                      opacity: 0.4,
                    }}
                  >
                    Rs
                  </h6>
                  <div
                    style={{
                      position: "absolute",
                      paddingBottom: "36px",
                      height: "17%",
                      width: "1px",
                      left: "80px",
                      backgroundColor: "rgb(202 194 194)",
                    }}
                  />
                  <input
                    style={{ paddingLeft: "75px" }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div
                  style={{ flex: 0.5, height: "35%" }}
                  className="form-group form-custom"
                >
                  <label for="">Brief Description</label>
                  <textarea
                    style={{ height: "70%" }}
                    type="text"
                    className="form-control"
                  />
                  
                </div>
                {/*end of col 1*/}
              </div>
              {/* Second Column */}
              <div style={{ flexDirection: "column", flex: "2" }}>
                <div
                  className="form-group form-custom"
                  style={{ position: "relative", flex: 1, marginLeft: 10 }}
                >
                  <label for="">Meeting ID</label>
                  <input
                    type="text"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "55px" }}
                  />
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                  className="form-group form-custom"
                >
                  <label for="">Schedule</label>
                  <input
                    type="text"
                    className="form-control"
                  />
                  <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "55px" }}
                  />
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10 }}
                  className="form-group form-custom"
                >
                  <div>
                    <label for="">Valid till</label>
                    <input
                      type="date"
                      className="form-control"
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <label for="">Highlighting</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="start"
                          control={
                            <PurpleSwitch
                            //   checked={state?.workshop}
                              size="small"
                              color="primary"
                              name="workshop"
                            //   onChange={handleBsetSeller}
                            />
                          }
                          label="Is this a Workshop?"
                          labelPlacement="start"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/*last line of col 2*/}
            </div>
            <div className="form-group form-custom mt-3">
              <label for="">Description</label>
              <JoditEditor
                ref={editor}
                // value={content}
                config={configJoditEditor}
                tabIndex={1} // tabIndex of textarea
                // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
              {/* {isAddNewEventDescriptionErrorValidation && (
                <p style={{ color: "red" }}>{DESCRIPTION}</p>
              )} */}
            </div>
          </div>
        </form>
      </div>
    </div>
    )
}

export default AdminAddNew;
