import React,{ useEffect, useState } from 'react';
import MyFamilyFirstBooking from '../../../common/myFamilyFirstBookings';
import SearchBar from '../../../common/commonSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import MyFamilyFirstMoreInfoModal from '../../gifts/myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../common/pagination';
import * as WeddingAction from '../../../actions/weddingAction'
import ConfirmModal from './confirmModal';
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Requested = ({status}) => {

    const [ showModal, setShowModal ] = useState(false);
    const [ requestedMoreInfoModalData, setRequestedMoreInfoModalData ] = useState({});
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    
  

    const [id, setId] = useState(null);
    const[iscopr,setIscorp]=useState()

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
    const [ page, setPage ] = useState(1);
    const [ searchVal, setSearchVal ] = useState('');
    const weddingRequestedData = useSelector(state => state.wedding?.weddingRequested?.results);
    const totalPage = useSelector(state => state.wedding?.weddingRequested?.total_pages);
    const dispatch = useDispatch();

  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);

    const START_DATE = useSelector(state => state.wedding.startDate);
    const END_DATE = useSelector(state => state.wedding.endDate);
    const SEARCH_TERM = useSelector(state => state.wedding.searchTerm);
    const PAGE_NO = useSelector(state => state.wedding.pageNo);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);
    
    const handleMoreInfoClick = (data) => {
        setShowModal(true);
        setRequestedMoreInfoModalData(data);
    };
    useEffect(() => {
      setPage(1)
    }, [status])

    const refereshList = () => {
        dispatch(WeddingAction.getWeddingRequested(page));
        dispatch(WeddingAction.getWeddingPlanning(1));
        dispatch(WeddingAction.getWeddingConfirmed(1));
        dispatch(WeddingAction.getWeddingCancelled(1));
    };

    const requestStatusChange = (event, id,data) => {
      setCloseRequestModalData(data)
      setId(id);
      setConfirmedRequestModalData(data)
        let status = event.target.value;
  
        if(status === "confirmed")setRequestModalConfirm(true);
        else if(status === "cancelled")setRequestModal(true);
        else{
          dispatch(WeddingAction.weddingRequestStatusChange(status, id,null, refereshList))

        }
        
    };

    const handlePagination = (e,value) => {
        setPage(value);
        dispatch(WeddingAction.setPageNo(value))
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(WeddingAction.searchWeddingRequests(SEARCH_TERM, START_DATE, END_DATE, "requested", value));
        }else{
          dispatch(WeddingAction.getWeddingRequested(value));
        }
    };

    const handleChange = (event) => {
      if(event.target.value){
        dispatch(WeddingAction.searchWeddingRequests(event.target.value, START_DATE, END_DATE, "requested", PAGE_NO));
      }else{
        dispatch(WeddingAction.getWeddingRequested(1));
        setPage(1);
      }
      dispatch(WeddingAction.setSearchTerm(event.target.value))
      };
      const confirmRequestHandler=()=>{
        
        
        setIsconfirmAmountBool(false)
       if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
        else {
        dispatch(WeddingAction.weddingRequestStatusChangeConfirmed("confirmed", id,confirmedRequestAmount, refereshList))
          setRequestModalConfirm(false)
        }
        // dispatch(confirmRequestInputVal(''));
       
        
    
      }


      const notToConfirm=()=>{
     
        setRequestModalConfirm(false)
        setIsconfirmAmountBool(false);
      
      }
      const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
          dispatch(WeddingAction.weddingRequestStatusChange("cancelled", id,closeRequestMessage, refereshList))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }
      

    const weddingRequestedDisplayData = weddingRequestedData && weddingRequestedData.map((data) => (
        <MyFamilyFirstBooking 
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request? data.specific_request:"N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={requestStatusChange}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {weddingRequestedData && weddingRequestedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
          {weddingRequestedDisplayData}
          <MyFamilyFirstMoreInfoModal
            open={showModal}
            close={() => setShowModal(false)}
            modalData={requestedMoreInfoModalData}
            section={"Wedding Requested"}
            isCancellationMessage={false}
          />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
              {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
         {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Requested;
