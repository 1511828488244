import {
    EVENT_UPCOMING_SEARCH_VALUE, EVENT_PAST_SEARCH_VALUE, EVENT_CANCELLED_SEARCH_VALUE, EVENT_BOOKINGS_SECTION_ID, EVENT_STATUS_VALUE, SET_SELECTED_EVENTS_CHOSEN_ID, EVENT_LIST, GET_EVENT_INFO, EVENTS_IMAGE_UPLOAD,
    ALL_IMAGES_EVENTS_DATA, EVENT_LIST_DROPDOWN,
    CANCEL_EVENT_INPUT_MESSAGE, PAGE_ID, EVENT_LISTINGS_UPDATE_BOOL,
    EVENT_LISTINGS_ADD_BOOL, EVENTS_LISTING_DROP_DOWN_VALUE, EVENT_LISTING_SEARCH_VALUE
} from "../actions/types";
import {
    SET_EDIT_EVENT_LIST_DETAILS,
    SET_ADD_EVENT,
    UPCOMING_BOOKINGS_LIST,
    PAST_BOOKINGS_LIST,
    CANCELLED_BOOKINGS_LIST,
    MOD_USER_BOOKINGS,
    EVENT_CATEGORY_ID,
    PAST_EVENT_CATEGORY_ID,
    CANCELLED_EVENT_CATEGORY_ID,
    TRANSACTION_DETAILS,
    UPCOMING_SEARCH_RESULTS,
    PAST_SEARCH_RESULTS,
    CANCELLED_SEARCH_RESULTS,
    EVENT_FILTER_START_DATE,
    EVENT_FILTER_END_DATE,
    SEC_ID,
    EVENT_PAGE_NUM,
    BOOKINGS_EVENTS_LIST,
    TESTIMONIAL_EVENTS_LIST,

} from "../actions/eventsAction";

const initialState = {
    eventsList: [],
    addNewEvent: false,
    eventInfo: [],
    addEventsImage: {},
    eventListItemDetails: {},
    allImagesEventsData: [],
    selectedEventsDropdown: [],
    upcoming_bookings: [],
    past_bookings: [],
    cancelled_bookings: [],
    modified_user_bookings: [],
    event_category_id: null,
    past_event_category_id: null,
    cancelled_event_category_id: null,
    transaction_details: [],
    search_results: [],
    filterStartDate: null,
    filterEndDate: null,
    getSecId: null,
    cancelEventInputMessage: null,
    page_num: null,
    pageId: 1,
    bookings_events_list: [],
    eventListUpdateBoolVal: false,
    eventListAddBoolVal: false,
    eventsListingDropDownvalue: null,
    eventlistSearchVal: "",
    selectedChosenId: "",
    page: 1,
    eventStatusVal: 'upcoming',
    eventBookingsSecId: null,
    eventUpcomingSearchVal: "",
    eventPastSearchVal: "",
    eventCancelledSearchVal: "",
    testimonialList: [],
};

const eventsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EVENT_LIST: {
            return {
                ...state,
                eventsList: payload,
            }
        }
        case SET_ADD_EVENT: {
            return {
                ...state,
                addNewEvent: payload
            }
        }
        case PAGE_ID: {
            return {
                ...state,
                pageId: payload
            }
        }
        case GET_EVENT_INFO: {
            return {
                ...state,
                eventInfo: payload
            }
        }
        case CANCEL_EVENT_INPUT_MESSAGE: {
            return {
                ...state,
                cancelEventInputMessage: payload
            }
        }
        case EVENTS_IMAGE_UPLOAD: {
            return {
                ...state,
                addEventsImage: payload,
            };
        }
        case ALL_IMAGES_EVENTS_DATA: {
            return {
                ...state,
                allImagesEventsData: payload
            }
        }
        case EVENT_LIST_DROPDOWN: {
            return {
                ...state,
                selectedEventsDropdown: payload
            }
        }
        case SET_EDIT_EVENT_LIST_DETAILS: {
            return {
                ...state,
                eventListItemDetails: payload,
            };
        }
        case UPCOMING_BOOKINGS_LIST: {
            return {
                ...state,
                upcoming_bookings: payload,
            };
        }
        case PAST_BOOKINGS_LIST: {
            return {
                ...state,
                past_bookings: payload,
            };
        }
        case CANCELLED_BOOKINGS_LIST: {
            return {
                ...state,
                cancelled_bookings: payload,
            }
        }
        case MOD_USER_BOOKINGS: {
            return {
                ...state,
                modified_user_bookings: payload,
            }
        }
        case EVENT_CATEGORY_ID: {
            return {
                ...state,
                event_category_id: payload,
            }
        }
        case PAST_EVENT_CATEGORY_ID: {
            return {
                ...state,
                past_event_category_id: payload,
            }
        }
        case CANCELLED_EVENT_CATEGORY_ID: {
            return {
                ...state,
                cancelled_event_category_id: payload,
            }
        }
        case TRANSACTION_DETAILS: {
            return {
                ...state,
                transaction_details: payload,
            }
        }
        case UPCOMING_SEARCH_RESULTS: {
            return {
                ...state,
                upcoming_bookings: payload,
            }
        }
        case PAST_SEARCH_RESULTS: {
            return {
                ...state,
                past_bookings: payload
            }
        }
        case CANCELLED_SEARCH_RESULTS: {
            return {
                ...state,
                cancelled_bookings: payload,
            }
        }
        case EVENT_FILTER_START_DATE: {
            return {
                ...state,
                filterStartDate: payload,
            };
        }
        case EVENT_FILTER_END_DATE: {
            return {
                ...state,
                filterEndDate: payload,
            };
        }
        case SEC_ID: {
            return {
                ...state,
                getSecId: payload,
            };
        }
        case EVENT_PAGE_NUM: {
            return {
                ...state,
                page_num: payload,
            };
        }
        case BOOKINGS_EVENTS_LIST: {
            return {
                ...state,
                bookings_events_list: payload,
            }
        }
        case EVENT_LISTINGS_UPDATE_BOOL: {
            return {
                ...state,
                eventListUpdateBoolVal: payload,
            }
        }
        case EVENT_LISTINGS_ADD_BOOL: {
            return {
                ...state,
                eventListAddBoolVal: payload,
            }
        }
        case EVENTS_LISTING_DROP_DOWN_VALUE: {
            return {
                ...state,
                eventsListingDropDownvalue: payload,
            };
        }
        case EVENT_LISTING_SEARCH_VALUE: {
            return {
                ...state,
                eventlistSearchVal: payload
            }
        }
        case SET_SELECTED_EVENTS_CHOSEN_ID: {
            return {
                ...state,
                selectedChosenId: payload
            }
        }
        case EVENT_STATUS_VALUE: {
            return {
                ...state,
                eventStatusVal: payload,
            };
        }
        case EVENT_BOOKINGS_SECTION_ID: {
            return {
                ...state,
                eventBookingsSecId: payload,
            };
        }
        case EVENT_UPCOMING_SEARCH_VALUE: {
            return {
                ...state,
                eventUpcomingSearchVal: payload
            }
        }
        case EVENT_PAST_SEARCH_VALUE: {
            return {
                ...state,
                eventPastSearchVal: payload
            }
        }
        case EVENT_CANCELLED_SEARCH_VALUE: {
            return {
                ...state,
                eventCancelledSearchVal: payload
            }
        }
        case TESTIMONIAL_EVENTS_LIST: {
            return {
                ...state,
                testimonialList:payload
            }
        }

        default:
            return state;
    }
};

export default eventsReducer;