import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
  axiosDelete,
} from "../services/apiServices";

import {
  ACCOUNTS_LIST,
  GET_ACCOUNT_LIST,
  UPDATE_EMPLOYEE,
  UPDATE_ACCOUNT,
  UPDATE_POINTS,
  SET_ACCOUNT_DETAILS,
  SEARCH_ORGANISATION,
  SEARCH_USER,
  EMAIL_DOMAIN_ERROR_MSG,
  SET_EMPLOYEE_LIST,
  SET_SEARCH_BOOL,
  SEARCH_VALUE,
  ORG_SEARCH_TERM,
  IS_CORP_DATA_LOADING,
  IS_CORP_LIST_DATA_LOADING
} from "./types";
import { ERROR_ALERT } from "./commonAction";

export const SET_ADD_ACCOUNT = "SET_ADD_ACCOUNT";

// export const accountsList = () => {
//     return async (dispatch, getState) => {
//         const token = getState().auth.token;
//         axiosGetWithToken(`${API.ACCOUNTS_LIST}`, token)
//             .then((res) => {
//                 if (res.data.status_code === 200) {
//                     dispatch({
//                         type: ACCOUNTS_LIST,
//                         payload: res.data,
//                     });
//                     //   dispatch({
//                     //     type: SUCCESS_ALERT,
//                     //     payload: "Logged in Successfully",
//                     //   });
//                     //   redirectUser();
//                     // console.log(res);
//                 }

//                 // else {
//                 //   dispatch({
//                 //     type: ERROR_ALERT,
//                 //     payload: res.data.data.message,
//                 //   });
//                 // }
//             })
//             .catch((err) => {
//                 // dispatch({
//                 //   type: ERROR_ALERT,
//                 //   payload:
//                 //     (err && err.response && err.response.data) ||
//                 //     "Something went wrong.",
//                 // });
//             });
//     };
// };

export const accountsList = (loader, page) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
    };
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ACCOUNTS_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ACCOUNTS_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_LIST_DATA_LOADING,
            payload: false,
          });
          // dispatch({
          //   type: SET_ACCOUNT_DETAILS,
          //   payload: res?.data?.data?.results[0] || {},
          // });
          // console.log("ACCOUNT LIST", res?.data?.data?.results[0] || {});
          loader();        }
      })
      .catch((err) => {
        loader();
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
        // console.log("error", err);
      });
  };
};

export const getAccountInfo = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.GET_ACCOUNT_INFO}${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SET_ACCOUNT_DETAILS,
            payload: res.data.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        } else {
          //   dispatch({
          //     type: ERROR_ALERT,
          //     payload: res.data.data.message,
          //   });
        }
      })
      .catch((err) => {
        // dispatch({
        //   type: ERROR_ALERT,
        //   payload:
        //     (err && err.response && err.response.data) ||
        //     "Something went wrong.",
        // });
      });
  };
};

export const getEmployeeList = (id, page) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
    };
    const token = getState().auth.token;
    axiosPostWithToken(`${API.EMPLOYEE_LIST}${id}/`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SET_EMPLOYEE_LIST,
            payload: res.data,
          });
        } else {
          //   dispatch({
          //     type: ERROR_ALERT,
          //     payload: res.data.data.message,
          //   });
        }
      })
      .catch((err) => {
        // dispatch({
        //   type: ERROR_ALERT,
        //   payload:
        //     (err && err.response && err.response.data) ||
        //     "Something went wrong.",
        // });
      });
  };
};

export const setAddAccount = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ADD_ACCOUNT,
      payload: value,
    });
    // console.log("value", value);
    // showData()
  };
};
export const setSearchBool = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SEARCH_BOOL,
      payload: value,
    });
  };
};
export const setSearchValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_VALUE,
      payload: value,
    });
  };
};
export const setOrgSearchTerm = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ORG_SEARCH_TERM,
      payload: value,
    });
  }
}

export const updateAccountInformation = (
  data,
  refreshList,
  handleError,
  handleSuccess
) => {
  console.log(data);
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = data;
    // console.log("body", body);

    axiosPut(`${API.UPDATE_ACCOUNT}${data.id}/`, body, token)
      .then((res) => {
        // console.log("datda", data.id);

        if (res.data.status_code === 200) {
          // console.log("res", res);
          refreshList(data?.id);
          handleSuccess();
          dispatch({
            type: UPDATE_ACCOUNT,
            payload: res.data,
          });
        } else if (res.data.status_code === 400) {
          // console.log("res", res.data.data.message);
          refreshList();
          handleError(res.data.data.message);
          // dispatch({
          //   type: ERROR_ALERT,
          //   payload: res.data.data.message,
          // });
        }
        // else {
        //   dispatch({
        //     type: ERROR_ALERT,
        //     payload: res.data.data.message,
        //   });
        // }
      })
      // .catch((err) => {
      //   refreshList();
      //   // console.log("err", err.message);
      //   console.log("err", err.response.data);
      //   alert(err.response.data.message)

      // })
      .catch((err) => console.log("errorData", err.response.data));
  };
};

export const handleAddNewData = (
  data,
  refreshList,
  handleError,
  handleSuccess
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    // console.log('accountData', data)
    axiosPostWithToken(`${API.POST_ACCOUNT}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList();
          handleSuccess(res.data.data.message);
        }
        // console.log("res data", res);
        if (res.data.status_code === 400) {
          // console.log("res", res.data.data.message);
          // alert(res.data.data.message)
          // refreshList();
          handleError(res.data.data.message);
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
};

export const addCorpUser = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_CORP_USER}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          // console.log("reres.data.data.messages", res.data.data.message);
          // loader()
          handleSuccess(res.data.data.message, data.org_id);
          // alert(res.data.data.message)
          dispatch({
            type: UPDATE_POINTS,
            payload: res.data,
          });
        } else if (res.data.status_code === 400) {
          handleError(res.data.data.message);
          dispatch({
            type: EMAIL_DOMAIN_ERROR_MSG,
            payload: true
          })
          // loader()
        }
        // if (res.data.status_code === 200) {
        //   dispatch({
        //     type: SEARCH_CORPORATE_LIST_DATA,
        //     payload: res.data,
        //   });
        // }
        // console.log("res data", res);
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
};

export const pointsUpdate = (data, id, corpId, loader, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = { points: data, corp_id: id };
    // console.log("body", body, id);

    axiosPut(`${API.UPDATE_POINTS}`, body, token).then((res) => {
      // console.log("datda", id);
      if (res.data.status_code === 200) {
        // console.log("res", res);
        loader();
        handleSuccess(res.data.data.message, corpId.org_id);
        // alert(res.data.data.message)
        dispatch({
          type: UPDATE_POINTS,
          payload: res.data,
        });
      } else if (res.data.status_code === 400) {
        handleError(res.data.data.message);
        loader();
      }
    });
  };
};

export const employeeUpdate = (
  name,
  mobile,
  email,
  id,
  corpId,
  loader,
  handleSuccess,
  handleError
) => {
  // console.log(data);
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = { full_name: name, mobile: mobile, email: email };
    // console.log("body", body, id);

    axiosPut(`${API.UPDATE_EMPLOYEE}${id}/`, body, token).then((res) => {
      // console.log("datda", id);
      if (res.data.status_code === 200) {
        loader();
        handleSuccess(res.data.data.message, corpId.org_id);
        // console.log("res", res);
        dispatch({
          type: UPDATE_EMPLOYEE,
          payload: res.data,
        });
      } else if (res.data.status_code === 400) {
        loader();
        handleError(res.data.data.message);
      }
    });
  };
};

export const searchOrganisation = (search) => {
  return async (dispatch, getState) => {
    const body = {
      "search_term": search,
      next_page_id: 1
    };
    const token = getState().auth.token;
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.SEARCH_ORGANISATION}`, body, token)
      .then((res) => {
        // console.log('searchRes', res);

        if (res.data.status_code === 200) {
          // console.log('searchRes', res);
          dispatch({
            // type: SEARCH_ORGANISATION,
            type: ACCOUNTS_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const searchUser = (search, id, page) => {
  return async (dispatch, getState) => {
    // console.log("searchId", id, search);
    const body = {
      org_id: id,
      search_term: search,
      next_page_id: page,
    };
    const token = getState().auth.token;
    axiosPostWithToken(`${API.SEARCH_USER}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SEARCH_USER,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
};
