import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getHolidaySectionList } from '../../../actions/holidaySectionAction';
import { getHolidayList, holidayListingDropDownVal, pageId, holidaySearchValue } from '../../../actions/holidaysAction';

const DropdownFilter = ({disabled}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const holidaySectionLists = useSelector(state => state.holidaySection.getHolidaySectionList);
  const pageID = useSelector((state) => state.holidayListing.pageId);
  const dispatch = useDispatch();

  let resultedLists = holidaySectionLists && holidaySectionLists.
  filter(item => item.name !== "Visas & More" && item.name !== "Customize" && item.name !== "Send a Gift");

  const handleClose = (item) => {
    setDropdownValue(item?.name);
    setAnchorEl(null);
    dispatch(getHolidayList(1, item?.id ));
    dispatch(holidayListingDropDownVal(item));
    dispatch(pageId(1))
    dispatch(holidaySearchValue(""))
  };

  const handleEventClick = (event) => {
    dispatch(getHolidaySectionList());
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={disabled ? "" : handleEventClick} 
         className="dropdown-filter"
         disabled={false?true:false} style={{cursor:disabled?'not-allowed':"pointer"}}
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {resultedLists && resultedLists.map(item => {
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  )
}

export default DropdownFilter