import StoryForm from './storyForm';
import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import * as myFamilyFirstAction from '../../../actions/myFamilyFirstAction';
import { useSnackbar } from "notistack";

const EditStory = ({storyDetails, storyDetailsId,setSearchVal})=>{
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const initErrors = {
        title: {
            hasError:false,
            message:''
        },
        media: {
            hasError:false,
            message:''
        },
        description: {
            hasError:false,
            message:''
        }
    };
    const [errors, setErrors] = useState(initErrors);

    const changeError = (name, hasError, message)=>{
        setErrors({
            ...errors,
            [name]: {
                hasError,
                message
            }
        });
    };
    const resetErrors = ()=>{
        setErrors(initErrors);
    };
    const[toDisable,setToDisable]=useState(false)
    const handleSuccess = (id)=>{
        console.log('successfully updated');
        enqueueSnackbar("Updated Successfully", {
            variant: "success",
          });
          setSearchVal('')
        dispatch(myFamilyFirstAction.myFamilyFirstStoryList());
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    
    }
    const handleForm = (formDetails)=>{
        setToDisable(true)
        console.log(formDetails);
        console.log(formDetails);
        if(!formDetails.title){ 
            
            changeError('title', true, 'Please enter Title');
            return false;
        }
        if(formDetails.title.length>75){ 
            
            changeError('title', true, 'Package title should be less than 75 characters');
            return false;
        }
        if(!formDetails.media.length){ 
            
            changeError('media', true, 'Please select atleast one image');
            return false;
        }
        if(!formDetails.description){ 
            
            changeError('description', true, 'Please enter Description');
            return false;
        }

        let submitData = {
            title:formDetails.title,
            description:formDetails.description,
            media:formDetails.media.map((img, index)=>{
                if(img.modified){
                    return {
                        ...img,
                        priority:index+1
                    };
                } else {
                    return {
                        image:img.image || '',
                        video:img.video || '',
                        thumbnail:img.thumbnail,
                        priority:index+1,
                        media_type:img.media_type,
                        alt_text:img.alt_txt,
                        title_text:img.title_txt
                    }
                }
            })
        }
        dispatch(myFamilyFirstAction.updateStory(storyDetailsId, submitData, handleSuccess));
        setTimeout(()=>{setToDisable(false)},[2000])
    };
    useEffect(()=>{
        console.log(storyDetails)
    }, [storyDetails])
    console.log(storyDetails);
    const handleDelSuccess = ()=>{
        console.log('Deleted Successfully');
        setSearchVal('')
        dispatch(myFamilyFirstAction.myFamilyFirstStoryList());
    }
    const deleteStory = ()=>{
        if (window.confirm("Are you sure to delete the story!") == true) {
            dispatch(myFamilyFirstAction.deleteStory(storyDetailsId, handleDelSuccess));
          } else {
            
          }
        
    };
    return (
        <div>
            <StoryForm errors={errors} type="Edit" resetErrors={resetErrors} storyDetails={storyDetails} handleForm={handleForm} storyDetailsId={storyDetailsId} deleteStory={deleteStory} fromMyFamilyFirst={true} toDisable={toDisable}/>
        </div>
    )
};

export default EditStory;
