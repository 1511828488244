import {
  HOLIDAY_LIST, SET_HOLIDAY_DETAILS, ALL_IMAGES_HOLIDAY_DATA,
  UPDATE_HOLIDAY_LIST, ALL_IMAGES_HOLIDAYS_DATA, GET_HOLIDAY_PACKAGE_INFO,
  HOLIDAY_SELECT_DROPDOWN, HOLIDAY_PACKAGE_DELETE, OPENING_SOON_DATE_VALUE, 
  HOLIDAY_LISTING_DROP_DOWN_VALUE, HOLIDAY_LISTING_UPDATE_BOOL, HOLIDAY_LISTING_PAGE_ID, 
  LOCATION_DROPDOWN, HPLIDAY_LISTING_EDIT_TRUE_VALUE, HOLIDAY_LISTING_SEARCH_VALUE, SET_SELECTED_CHOSEN_ID,
  TESTIMONIAL_HOLIDAY_LIST,GET_HOLIDAYS_FEATURED_LIST, ISADD_ANOTHER_PACKAGE, IS_DATA_LOADING, IS_LIST_DATA_LOADING
} from "../actions/types";
import {
  SET_ADD_HOLIDAY_PACKAGE,
  SET_ADD_PACKAGE
} from "../actions/holidaysAction";

const initialState = {
  holidayList: [],
  holidayDetails: {},
  holidayListItemDetails: {},
  allImagesHolidayData: [],
  addHolidayPackage: false,
  listingHolidayData: {},
  allImagesHolidaysData: [],
  packageInfoHoliday: [],
  selectedHolidayDropdown: [],
  deletedHolidayPackage: [],
  openingSoonValue: '',
  holidayListingDropDownvalue: null,
  holidatUpdateBoolVal:false,
  pageId:1,
  dropDownLocationlist:[],
  listingEditTrue:false,
  holidaylistSearchVal:"",
  selectedChosenId:"",
  featured: [],
  holidayTestimonialList:[],
  isAddanotherPackage:[],
  isDataLoading:false,
  isListingDataLoading:false,
};

const holidayListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HOLIDAY_LIST: {
      return {
        ...state,
        holidayList: payload,
      };
    }
    case LOCATION_DROPDOWN: {
      return {
        ...state,
        dropDownLocationlist: payload,
      };
    }
    case GET_HOLIDAYS_FEATURED_LIST: {
      return {
        ...state,
        featured: payload
      }
    }
    case HOLIDAY_LISTING_DROP_DOWN_VALUE: {
      return {
        ...state,
        holidayListingDropDownvalue: payload,
      };
    }
    case SET_HOLIDAY_DETAILS: {
      return {
        ...state,
        holidayDetails: payload
      }
    }
    case ALL_IMAGES_HOLIDAY_DATA: {
      return {
        ...state,
        allImagesHolidayData: payload
      }
    }
    case SET_ADD_HOLIDAY_PACKAGE: {
      return {
        ...state,
        addHolidayPackage: payload,
      };
    }
    case UPDATE_HOLIDAY_LIST: {
      return {
        ...state,
        listingHolidayData: payload
      }
    }
    case ISADD_ANOTHER_PACKAGE: {
      return {
        ...state,
        isAddanotherPackage: payload
      }
    }
    case ALL_IMAGES_HOLIDAYS_DATA: {
      return {
        ...state,
        allImagesHolidaysData: payload
      }
    }
    case GET_HOLIDAY_PACKAGE_INFO: {
      return {
        ...state,
        packageInfoHoliday: payload
      }
    }
    case HOLIDAY_SELECT_DROPDOWN: {
      return {
        ...state,
        selectedHolidayDropdown: payload
      }
    }
    case HOLIDAY_PACKAGE_DELETE: {
      return {
        ...state,
        deletedHolidayPackage: payload
      }
    }
    case OPENING_SOON_DATE_VALUE: {
      return {
        ...state,
        openingSoonValue: payload
      }
    }
    case HOLIDAY_LISTING_UPDATE_BOOL: {
      return {
        ...state,
        holidatUpdateBoolVal: payload
      }
    }
    case HOLIDAY_LISTING_PAGE_ID: {
      return {
        ...state,
        pageId: payload
      }
    }
    case HPLIDAY_LISTING_EDIT_TRUE_VALUE: {
      return {
        ...state,
        listingEditTrue: payload
      }
    }
    case HOLIDAY_LISTING_SEARCH_VALUE: {
      return {
        ...state,
        holidaylistSearchVal: payload
      }
    }
    case SET_SELECTED_CHOSEN_ID: {
      return {
        ...state,
        selectedChosenId: payload
      }
    }
    case TESTIMONIAL_HOLIDAY_LIST: {
      return {
        ...state,
        holidayTestimonialList: payload
      }
    }
    case IS_DATA_LOADING: {
      return {
        ...state,
        isDataLoading: payload
      }
    }
    case IS_LIST_DATA_LOADING: {
      return {
        ...state,
        isListingDataLoading: payload
      }
    }
    default:
      return state;
  }
};

export default holidayListingReducer;