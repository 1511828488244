import React, { useState, useEffect } from "react";
import { useDispatch,useSelector} from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";
import MoreInfoModal from "../../../common/moreInfoModal";
import { bookedstatusValues, statusValues} from "../../../resources/bookingStatus";
import { followUPstatusval } from "../../../resources/bookingStatus";
import { bookingConfirmAndCalcelledStatusValue } from "../../../resources/bookingStatus";
import SearchBar from "./partnerSearchBar";
import { BookedWithPointAndRs, checkNumberOfGuest } from '../../../utils/index';
import GuestModal from "../../../common/checkAvailability/guestModal";
import PartnerBookingConfirmPopUp from "./partnerBookingConfirmPopUp"
import { guestData, guestModalOpentrue } from '../../../actions/experienceBookingAction';
import moment from "moment";
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

export default function Booking({ bookingData, status, selectedPartner, refreshEnquiries }) {


  const dispatch = useDispatch();


  const guestOpen = useSelector((state) => state.experienceBooking.guestModalOpenTrue);
  const adultsGuest = useSelector((state) => state.experienceBooking.adultsGuest);
  const childrenGuest = useSelector((state) => state.experienceBooking.childrenGuest);
  const infantGuest = useSelector((state) => state.experienceBooking.infantGuest);
  const [ showInfoModal, setShowInfoModal ] = useState(false);
 
  const [id, setId] = useState(null);
  const[iscopr,setIscorp]=useState()
  const [amountValue, setAmountValue] = useState("");
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [availabilityConfirmationPopupdata, setAvailabilityConfirmationPopupdata] = useState({});
  const [openRequestConfirmedPopup, setOpenRequestConfirmedPopup] = useState(false);
  const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  console.log(totalbookingAmount);
  const[totalusedpoints,setTotalusedpoints]=useState()
  const [ handleStatusChange, setHandleStatusChange ] = useState(false);
  const [ showInfoModalDataForBooking, setShowInfoModalDataForBooking ] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
  const [statusList, setStatusList] = useState(status==='booked'?statusValues:status==='follow_up'?followUPstatusval:bookingConfirmAndCalcelledStatusValue);
  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const getPageNum = useSelector((state) => state?.bookingListing?.page_num);
  const secId = useSelector((state) => state.bookingListing.getSecId);
  const searchValue = useSelector((state) => state.bookingListing.searchValue);
  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);
  const [changedStatus, setChangedStatus] = useState("");
  const [scheduleError, setScheduleError] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [adultGuestError, setAdultGuestError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [amountLengthError, setAmountLengthError] = useState(false);
  // useEffect(() => {
  //   return () => {
  //     dispatch(BookingAction.searchPartnerTextvalue(''));
  //   }
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      setGuestError(false);
      setAdultGuestError(false);
      setScheduleError(false);
      setAmountError(false);
      setAmountLengthError(false);
    }, 2000);
  }, [scheduleError, guestError, amountError, amountLengthError]);

  const bookingStatusChange = (id, status, item) => {

    setCloseRequestModalData(item)

    setAvailabilityConfirmationPopupdata(item)
    dispatch(guestModalOpentrue(false))
    setChangedStatus(status);
    setId(id)
  
    if (status === "confirmed"){
      setOpenRequestConfirmedPopup(true)
    }else if(status === "cancelled")setRequestModal(true); 
    else {
      dispatch(BookingAction.updatePartnerBookingStatus(id, status,secId, null,refreshEnquiries, item));

    }
   
  };

  const handleInfoModal = (item) => {
    setShowInfoModalDataForBooking(item);
    setShowInfoModal(true);
  };
  const amountHandleChange = (e) => {
    setAmountValue(e.target.value)
  };
  const handleModalClose = () => {
    setShowInfoModal(false);
  };
  const handleClose = () => {
    setOpenRequestConfirmedPopup(false)
    setAmountValue("")
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    return ()=> document.body.style.overflow = 'unset';
  }, [showInfoModal])

  const updateAvailabilityConfirmationData = (data) => {
    setAvailabilityConfirmationPopupdata(data)
  };

  const adultGuestIncrementHandler = () => {
    setAdultCount(adultCount + 1)
  }

  const adultGuestIDecrementHandler = () => {
    if (adultCount <= 0) {
      setAdultCount(0);
    } else {
      setAdultCount(adultCount - 1)
    }
  };

  const childrensGuestIncrementHandler = () => {
    setChildrenCount(childrenCount + 1);
  }

  const childrensGuestIDecrementHandler = () => {
    if (childrenCount <= 0) {
      setChildrenCount(0);
    } else {
      setChildrenCount(childrenCount - 1)
    }
  };

  const infantGuestIncrement = () => {
    setInfantCount(infantCount + 1)
  };

  const infantGuestDecrement = () => {
    if (infantCount <= 0) {
      setInfantCount(0);
    } else {
      setInfantCount(infantCount - 1)
    }
  };
  const guestClickHandler = () => {
    console.log(adultCount, childrenCount, infantCount);
    setOpenGuestModal(true)
    dispatch(guestModalOpentrue(true));
  }
  const doneClickHandler = () => {
    dispatch(guestData(adultCount, childrenCount, infantCount))
    console.log(adultCount, childrenCount, infantCount);
    setOpenGuestModal(false);
  };

  useEffect(()=>{
    const amount=bookingData?.results.map((elem)=>{
      return (parseInt(elem.amount_paid!==0?elem.amount_paid:0))
    })
    const totalamount=amount?.reduce((acc,elem)=>{
        return acc+elem
    },0)

    const points=bookingData?.results?.map((elem)=>{
    
      return parseInt(parseInt(elem.total_points)!==0?elem.total_points:0)
    })
    const totalpoints=points?.reduce((acc,elem)=>{
        return acc+elem
    },0)
    setTotalusedpoints(totalpoints)
    setTotalbookingAmount(totalamount)
    setAverageBookingAmount(Math.round(totalamount/bookingData?.results?.length))

},[bookingData?.results,status])
  
  useEffect(() => {
    setAdultCount(availabilityConfirmationPopupdata.adults);
    setChildrenCount(availabilityConfirmationPopupdata.children)
    setInfantCount(availabilityConfirmationPopupdata.infants)
  }, [availabilityConfirmationPopupdata]);
  
  const confirmedHandler = (item) => { 
    setGuestError(false);
    setAdultGuestError(false);
    setScheduleError(false);
    setAmountError(false);
    setAmountLengthError(false);
    const guestData = {
      adults: guestOpen === true ? adultsGuest : adultCount,
      children: guestOpen === true ? childrenGuest : childrenCount,
      infants: guestOpen === true ? infantGuest : infantCount
    } 
    if (item.pack_schedule_type === "" && item.schedule.length === 0) setScheduleError(true);
    else if(adultsGuest === 0 && adultCount === 0 && childrenCount === 0 && childrenGuest === 0 && infantCount === 0 && infantGuest === 0) setGuestError(true)
    else if (adultsGuest === 0 && (childrenGuest > 0 || infantGuest > 0)) setAdultGuestError(true);
    else if (amountValue === "") setAmountError(true);
    else if (amountValue.length > 6) setAmountLengthError(true)
    else {
    dispatch(BookingAction.updatePartnerBookingStatus(item.id, changedStatus, secId, null,refreshEnquiries, item, guestData, amountValue));
    setOpenRequestConfirmedPopup(false);
    setAmountValue("");
    }
  }
  const closeRequestHandler = () => {
 
    setIsclosingMessageBool(false)
    if(closeRequestMessage === "")setIsclosingMessageBool(true)
    else {
      dispatch(BookingAction.updatePartnerBookingStatus(id, changedStatus,secId, closeRequestMessage,refreshEnquiries));
      setRequestModal(false)
    }
    dispatch(closeRequestInputVal(''));
  };

const notToCancel=()=>{
    setRequestModal(false)
    setIsclosingMessageBool(false);

}
  return (
    <div>
    <SearchBar status={status} inputValue={searchValue} selectedPartner={selectedPartner} />
    {bookingData?.results.length === 0 && 
     <div className="enquiry-card-holder">No Record Found!</div>
    }
 {status=="confirmed" || status=="cancelled" ?<p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):totalbookingAmount}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):0}
 </b>
 </span> <span style={{marginLeft:"80px"}}><b> 
 Total Points Used: {" "} {totalusedpoints?Math.round(totalusedpoints):0}
 </b>
 
  </span></p>:""}
      
    <div className="scroll-top overflow--scroll" style={{marginBottom: '10px'}}>
      {bookingData?.results &&
        bookingData?.results.length > 0 &&
        bookingData?.results.map((item) => (
          <div className="enquiry-card-holder">
            <div className="organization-details">
            <span style={{color: "#2196F3"}}>{item?.ref_id? `#${item.ref_id}`: "No ref id"}</span>
            <span style={{marginLeft:20, color:'#C4C4C4', fontSize:12}}>{item.partner_name}</span>
              <h1>{item.pack_title}</h1>
              <div className="info">
                <p>{item?.sec_name  && item?.sec_name == "International" ? item.location_country : item.address}</p>
                <p>
                  Booked with {BookedWithPointAndRs(item.total_points, item.amount)}
                </p>
                <p> <span style={{fontSize:"12px"}}>Booked on {moment(item?.created).format("Do MMMM, YYYY")}</span></p>
              </div>
            </div>
            {
              ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null) ) ?
              <div className="contact-info">
                <div className="title">
                  <p>Booked for </p>
                </div>
                <div className="contact-details">
                  <p className="email" >
                  <span>{Array.isArray(item.schedule) ? item.schedule.sort().map((scheduleDate, index) => {
                                        if (item.schedule[index] === item.schedule[item.schedule.length - 1]) {
                                        return scheduleDate
                                        } else {
                                        return `${scheduleDate}, `
                                        }
                                    }) : (typeof item.schedule === 'object' && item.schedule !== null ? `${moment(item.schedule.from, 'DD-MM-YYYY').format('DD-MM-YYYY')} ${ item.schedule.to ? `to ${moment(item.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}` : (item.schedule ===  null ? "N/A" : item.schedule))}</span>
                  </p>
                  <p>
                    {checkNumberOfGuest(item.adults, item.children, item.infants)}
                  </p>
                </div>
              </div> : ''
            }

            {/* {
              ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null) ) ? 
              <div className="contact-info">
              <div className="title">
                <p>Message</p>
              </div>
              <div className="contact-details">
                <p className="message-limit" title={item.special_request ? item.special_request : 'No Message'}>
                  {item.special_request ? item.special_request : (<p>No Message</p>)}
                </p>
              </div>
            </div> : ''
            } */}
            
            <div>
              <p 
                onClick={() => handleInfoModal(item)}
                style={{
                backgroundColor:"#E7E7E7",
                padding:"5px",
                textAlign:"center",
                borderRadius:"5px",
                cursor:"pointer"
              }}>More Info</p>
              {status=="cancelled"?
                  <p
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px 30px",
                    marginBottom: "0px",
                    textAlign: "center",
                    borderRadius: "5px",
                    // cursor: "pointer",
                    border: "1px solid #C4C4C4",
                    width: "154px",
                    height: "32px"
                  }}
                >Cancelled</p>
              :
              <select
                className="status-dropdown"
                value={item.booking_status}
                disabled={status==='cancelled'}
                onChange={(event) =>
                  bookingStatusChange(item.id, event.target.value, item)
                }
              >
                {statusList.length > 0 &&
                  statusList.map((options) => (
                    <option value={options.value}>{options.name}</option>
                  ))}
              </select>
}
            </div>
          </div>
        ))}
        { showInfoModal && 
        <MoreInfoModal 
          className="booking"
          showInfoModalData={showInfoModalDataForBooking} 
          showMyModal={showInfoModal} 
          // modalClose={() => setShowInfoModal(false)}
          modalClose={handleModalClose}
          isCancellationMessage={status=="cancelled"?true:false}

        />}
        {openRequestConfirmedPopup &&
        <PartnerBookingConfirmPopUp
          open={openRequestConfirmedPopup}
          handleClose={handleClose}
          data={availabilityConfirmationPopupdata}
          guestClickHandler={guestClickHandler}
          changeData={updateAvailabilityConfirmationData}
          availabilityData={availabilityConfirmationPopupdata}
          amountValue={amountValue}
          scheduleError={scheduleError}
          guestError={guestError}
          adultGuestError={adultGuestError}
          amountError={amountError}
          amountLengthError={amountLengthError}
          confirmClick={confirmedHandler}
          amountHandleChange={amountHandleChange}
        >
          {openGuestModal &&
            <GuestModal
              adultGuestIncrementHandler={adultGuestIncrementHandler}
              adultGuestIDecrementHandler={adultGuestIDecrementHandler}
              childrensGuestIncrementHandler={childrensGuestIncrementHandler}
              childrensGuestIDecrementHandler={childrensGuestIDecrementHandler}
              infantGuestIncrement={infantGuestIncrement}
              infantGuestDecrement={infantGuestDecrement}
              doneClickHandler={doneClickHandler}
              adultCount={adultCount}
              childrenCount={childrenCount}
              infantCount={infantCount}
            />
          }
        </PartnerBookingConfirmPopUp>
      }
           {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
    </div>
    </div>
  );
}
