import React, { useEffect, useRef, useState } from "react";
import useObjectURL from "use-object-url";
import CropImagePanel from "./CropImagePanel";
import { getCroppedFile, limitImageSize } from "./utils";
import Button from "@material-ui/core/Button";

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import { Box, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

export default function CropImageModal({
  altText,
  setAltText,
  imageTitle,
  setImageTitle,
  show,
  imageFile,
  value, // crop value
  onChange, // on crop value change
  onConfirm, // (croppedFile, title, altText) => void
  onCancel, // void => void
  onRemove, // void => void
  inputOptions = {}, // {maxWidth, maxHeight, mimeType, quality}
  cropOptions = {}, // {aspect, maxZoom}
  outputOptions = {}, // {maxWidth, maxHeight, mimeType, quality}
  displayOptions = {}, // {title, removeButtonText, confirmButtonText, showRemoveButton, showConfirmButton},

}) {
  const {
    maxWidth: inputMaxWidth = Infinity,
    maxHeight: inputMaxHeight = Infinity,
    mimeType: inputMimeType = "image/jpeg",
    quality: inputQuality,
  } = inputOptions;
  const { aspect, maxZoom } = cropOptions;

  const {
    maxWidth = Infinity,
    maxHeight = Infinity,
    mimeType = "image/jpeg",
    quality,
  } = outputOptions;

  const {
    title = "Crop Image",
    removeButtonText = "Remove",
    confirmButtonText = "Confirm",
    showRemoveButton = true,
    showConfirmButton = true,
  } = displayOptions;

  const imageUrl = useObjectURL(imageFile);

  const [resizedUrl, setResizedUrl] = useState();
  const [resizing, setResizing] = useState(false);


  useEffect(() => {
    if (imageUrl) {
      setResizing(true);
      limitImageSize({
        imageUrl,
        maxWidth: inputMaxWidth,
        maxHeight: inputMaxHeight,
        mimeType: inputMimeType,
        quality: inputQuality,
      })
        .then((url) => setResizedUrl(url))
        .catch((err) => console.error(err))
        .finally(() => setResizing(false));
    } else {
      setResizedUrl();
    }
  }, [imageUrl]);

  const cropResultRef = useRef();

  function handleCropComplete(croppedArea, croppedAreaPixels) {
    cropResultRef.current = { croppedArea, croppedAreaPixels };
  }

  function handleConfirm() {
    getCroppedFile(
      resizedUrl,
      cropResultRef.current.croppedAreaPixels,
      maxWidth,
      maxHeight,
      mimeType,
      quality
    ).then(onConfirm);
  }
   
  return (
    <Modal open={show} onClose={onCancel}>
      <div
        className="card-box card-box-index"
        style={{ 
          width: "50%", 
          backgroundColor: "white",
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center",
          marginTop:"5px",
          height:"75%",
          marginLeft:"25%%",
          position:"absolute",
          top:"15%",
          left:"30%"
        }}
      >
        <Typography style={{ padding: 10 }} varaint={"subtitle1"}>
          {title}
        </Typography>

        <Box
          style={{
            width: 500,
            height: 400,
            // overflow: 'hidden',
            position: "relative",
          }}
        >
          {resizedUrl && (
            <CropImagePanel
              imageUrl={resizedUrl}
              value={value}
              onChange={onChange}
              onCropComplete={handleCropComplete}
              aspect={aspect}
              maxZoom={maxZoom}
            />
          )}

        </Box>
            <Box style={{marginTop:"10px"}}>
              <TextField
                label="Image Title"
                variant="outlined"
                fullWidth
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
                style={{ marginBottom: 10 }}
              />
              <TextField
                label="Alt Text"
                variant="outlined"
                fullWidth
                value={altText}
                onChange={(e) => setAltText(e.target.value)}
              />
            </Box>



        <Box style={{display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",marginBottom:"10px"}}>
          {showRemoveButton && (
            <Button variant="secondary" onClick={onRemove} style={{ backgroundColor:"#e2e2e2",
              width: "49%",
              height: "38px",
              borderRadius: "2px",
              opacity: "0.8",
              color: "#fff",
              fontWeight: "600",
              padding:"0 10px",
              color:"black",
              fontSize: "larger"}}>
              {removeButtonText}
            </Button>
          )}
          {showConfirmButton && (
            <Button variant="primary" onClick={handleConfirm} style={{ backgroundColor:"#007BFF",
            width: "49%",
            height: "38px",
            borderRadius: "2px",
            opacity: "0.8",
            color: "#fff",
            fontWeight: "600",
            fontSize: "larger"}}>
            {confirmButtonText}
            </Button>
          )}
        </Box>
      </div>
    </Modal>
  );
}
