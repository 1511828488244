import React, {useState, useEffect} from 'react';
import TestimonialBlock from './testimonialBlock';
import TestimonialDialog from './testimonialDialog';
import { useSelector, useDispatch } from "react-redux";
import * as  EventListingAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";

const Testimonial = ({module, id,cid})=>{
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState('add');
    const [testimonialEditData, setTestimonialEditData] = useState({
        "guest_name": "",
        "guest_image": "",
        "message": "",
        "tid":null
    });
    const dispatch = useDispatch();
    const testimonialList = useSelector((state) => state.eventsListing.testimonialList);
    const corpTestimonial = useSelector((state) => state.corpListing.corpTestimonial);
    const holidayTestimonialList = useSelector((state) => state.holidayListing.holidayTestimonialList);
    const expTestimonialList = useSelector((state) => state.experiencesListing.expTestimonialList);
    const [ allTestimonialList, setallTestimonialList ] = useState()
    console.log(allTestimonialList,">>>>>>>>>>>>>>>>>>>>>>>>>>");
    const text = "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host is.";
    useEffect(()=>{
        if(module==='event'){
            dispatch(EventListingAction.getEventTestimonials(cid));
        }else if(module === "corporate"){
            dispatch(CorpListingAction.getCorporateTestimonials(cid));
            
        }else if(module === 'holiday'){
            dispatch(HolidaysAction.getHolidayTestimonials(cid))
        }else if(module === 'experience'){
            dispatch(experiencesListingAction.getExpTestimonials(cid))
        }
        else{
            return;
        }
    }, [id, module]);

    useEffect(()=>{
        if(module==='event'){
            setallTestimonialList(testimonialList)
        }else if(module === "corporate"){
            setallTestimonialList(corpTestimonial)
        }else if(module === 'holiday'){
            setallTestimonialList(holidayTestimonialList)
        }else if(module === 'experience'){
            setallTestimonialList(expTestimonialList)
        }
        else{
            return;
        }
    }, [testimonialList, corpTestimonial, holidayTestimonialList, expTestimonialList]);


    const showAddTestimonialDialog = (e)=>{
        e.preventDefault();
        showDialog(true, 'add');
    };
    const showDialog = (isOpen, type, editData)=>{
        setIsDialogOpen(isOpen);
        setDialogType(type);
        if(type==='edit'){
            setTestimonialEditData(editData);
        }
    };
    return (
        <div>
        <div className="row">
            <div className="col">Testimonials </div> 
            <div className="row col justify-content-end">
                <button className="mr-4 px-3 rounded bg-white" style={{border: "1px solid #c4c4c4" }} disabled={allTestimonialList?.length>5} onClick={showAddTestimonialDialog}>Add</button>
            </div> 
        </div>
        <div className="row mt-4">
            
            <div className="col">
                {allTestimonialList?.map((testimonial, index)=>{
                    return <TestimonialBlock key={`testimonial-${index}`} userImage={testimonial.guest_image} name={testimonial.guest_name} testimonial={testimonial.message} tid={testimonial.id} showDialog={showDialog} eventId={id} module={module} cid={cid} holiday_Id={testimonial.holiday_pack}/>
                })}
                {!allTestimonialList?.length&&<div style={{textAlign:'center', color:'#545454'}}>No Testimonials added to this {module === "event" ? 'event' : 'package'}</div>}
            </div>
        </div>
        {isDialogOpen&&<TestimonialDialog isDialogOpen={isDialogOpen} handleClose={()=>setIsDialogOpen(false)} type={dialogType} module={module} id={id} editData={testimonialEditData} cid={cid} />}
        </div>
    );
};

export default Testimonial;