import {
    EXPERIENCES_PENDING_REVIEW_LIST,
    EXPERIENCES_REJECTED_REVIEW_LIST,
    EXPERIENCES_REVIEW_END_DATE,
    EXPERIENCES_REVIEW_PAGE_ID,
    EXPERIENCES_REVIEW_PUBLISHED_LIST,
    EXPERIENCES_REVIEW_START_DATE,
  } from "../actions/types";
  
  const initialState = {
    experienceReviewList: [],
    experiencePublishedReviewList: [],
    experienceRejectedReviewList:[],
  experienceReviewStartDate: null,
    experienceReviewEndDate: null,
    experienceReviewStatusVal: "Review",
    experienceReviewSecName: {},
    page: 1,
    getexperienceReqAllData: {},
    experienceReqSearchValue: "",
  };
  const experienceReviewReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case EXPERIENCES_PENDING_REVIEW_LIST:
        return {
          ...state,
          experienceReviewList: payload,
        };
      case EXPERIENCES_REVIEW_PAGE_ID:
        return {
          ...state,
          page: payload,
        };
      case EXPERIENCES_REVIEW_PUBLISHED_LIST:
        return {
          ...state,
          experiencePublishedReviewList: payload,
        };
        case EXPERIENCES_REVIEW_START_DATE:
            return{
                ...state,
                experienceReviewStartDate:payload
            }
        case EXPERIENCES_REVIEW_END_DATE:
            return{
                ...state,
                experienceReviewEndDate:payload
            }
        case EXPERIENCES_REJECTED_REVIEW_LIST:
          return{
            ...state,
            experienceRejectedReviewList:payload
          }    
      default:
        return state;
    }
  };
  export default experienceReviewReducer;
  