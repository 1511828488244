import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    padding: 6,
    borderRadius: 3,
  },
}));

 const SearchBar = ({ status, inputValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);
  const secId = useSelector((state) => state.bookingListing.getSecId);
  const [filterSecId, setFilterSecId] = useState(secId);
  const [ value, setValue ] = useState("");

  useEffect(() => {
    dispatch(BookingAction.searchBooking("", status, secId, 1));
    setFilterSecId(secId);
  }, [startDate, endDate, secId]);
  
  useEffect(() => {
    dispatch(BookingAction.getStatus( status));
  }, []);
  const handleChange = (value) => {
    let search = value.target.value
    setValue(search)
    dispatch(BookingAction.searchBooking(value.target.value, status, filterSecId, 1));
    dispatch(BookingAction.searchTextvalue(search, status));
    dispatch(BookingAction.searchTruevlue(true, status))
  };

  return (
    <TextField
      className={classes.input}
      size="small"
      placeholder={"Search"}
      value={inputValue}
      fullWidth={true}
      onChange={handleChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default SearchBar;
