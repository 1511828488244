import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";
import { bookingConfirmAndCalcelledStatusValue } from "../../../resources/bookingStatus";
import SearchBar from "./searchBar";
import MoreInfoModal from "../../../common/moreInfoModal";
import { BookedWithPointAndRs, checkNumberOfGuest } from '../../../utils/index'
import CommonMoreInfoModel from "../../../common/commonMoreInfoModel";
import moment from "moment";
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

export default function Confirmed({ confirmedBookings }) {
  const dispatch = useDispatch();
  const [showConfirmInfoModal, setShowConfirmInfoModal] = useState(false);
  const [showInfoDataForConfirm, setShowInfoDataForConfirm] = useState(false)
  const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  const[totalusedpoints,setTotalusedpoints]=useState()

  const [id, setId] = useState(null);
  const[iscopr,setIscorp]=useState()
  const [status,setStatus]=useState()

  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);

  const getPageNum = useSelector((state) => state?.bookingListing?.page_num);
  const searchValue = useSelector((state) => state.bookingListing.corpConfirmedSearchValue);

  const secId = useSelector((state) => state.bookingListing.getSecId);
  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);
  const [state, setState] = React.useState({
    status: undefined,
  });

  useEffect(() => {
    if (searchValue === "" &&
      confirmedBookings?.next_page_id === null &&
      confirmedBookings?.current_page > 1 &&
      confirmedBookings?.results.length === 0) {
      dispatch(BookingAction.getBookingConfirmedlist(getPageNum, secId));
      dispatch(BookingAction.setPageNum(1));
    }
    else if (searchValue !== "" &&
      confirmedBookings?.next_page_id === null &&
      confirmedBookings?.current_page > 1 &&
      confirmedBookings?.results.length === 0) {
      dispatch(BookingAction.searchBooking(searchValue, "confirmed", secId, 1));
      dispatch(BookingAction.setPageNum(1));
    }
  }, [confirmedBookings, getPageNum, searchValue]);

  const bookingStatusChange = (id, status, data) => {
   
    setCloseRequestModalData(data)
    setState({ ...state, status });
    setStatus(status)
    setId(id)
    if(status === "cancelled")setRequestModal(true);
    else{

      dispatch(BookingAction.updateBookingStatus(id, status, secId,null, refreshEnquiries));
    }

  };
  const refreshEnquiries = () => {
    if (searchValue !== "") {
      dispatch(BookingAction.searchBooking(searchValue, "confirmed", secId, getPageNum));
      dispatch(BookingAction.getCancelledBookinglist(getPageNum, secId))
    } else if (startDate !== null && endDate !== null) {
      dispatch(BookingAction.searchBooking(searchValue, "confirmed", secId, getPageNum));
      dispatch(BookingAction.searchBooking(searchValue, "cancelled", secId, getPageNum));
    }
    else {
      dispatch(BookingAction.getBookingList(getPageNum, secId));
      dispatch(BookingAction.getBookingConfirmedlist(getPageNum, secId));
      dispatch(BookingAction.getCancelledBookinglist(getPageNum, secId));
    }
  };

  const handleInfoModal = (confirmData) => {
    setShowInfoDataForConfirm(confirmData)
    setShowConfirmInfoModal(true)
  };

  const closeRequestHandler = () => {
 
    setIsclosingMessageBool(false)
    if(closeRequestMessage === "")setIsclosingMessageBool(true)
    else {
      dispatch(BookingAction.updateBookingStatus(id, status, secId,closeRequestMessage, refreshEnquiries));
      setRequestModal(false)
    }
    dispatch(closeRequestInputVal(''));
  };

const notToCancel=()=>{
    setRequestModal(false)
    setIsclosingMessageBool(false);

}

  useEffect(()=>{

    const amount=confirmedBookings?.results?.map((elem)=>{
    
      return parseInt(parseInt(elem.amount_paid)!==0?elem.amount_paid:0)
    })
    const totalamount=amount?.reduce((acc,elem)=>{
        return acc+elem
    },0)
    const points=confirmedBookings?.results?.map((elem)=>{
    
      return parseInt(parseInt(elem.total_points)!==0?elem.total_points:0)
    })
    const totalpoints=points?.reduce((acc,elem)=>{
        return acc+elem
    },0)
    setTotalusedpoints(totalpoints)
    setTotalbookingAmount(totalamount)

  
    setAverageBookingAmount(totalamount/confirmedBookings?.results?.length)
 

},[confirmedBookings?.results])
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => document.body.style.overflow = 'unset';
  }, [showConfirmInfoModal]);

  return (
    <div>
      <SearchBar status="confirmed" inputValue={searchValue} />
     

      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span> <span style={{marginLeft:"80px"}}><b> 
 Total Points Used: {" "} {totalusedpoints?Math.round(totalusedpoints):""}
 </b>
  </span>
  </p>
      {confirmedBookings?.results.length === 0 &&
        <div className="enquiry-card-holder">No Record Found!</div>
      }
      <div className="overflow--scroll" style={{ marginBottom: '10px' }}>
        {confirmedBookings?.results &&
          confirmedBookings?.results.length > 0 &&
          confirmedBookings?.results.map((item) => (
            <div className="enquiry-card-holder">
              <div className="organization-details">
                <span style={{ display: "block", color: "#2196F3" }}>{item?.ref_id ? `#${item.ref_id}` : "No ref id"}</span>
                <h1>{item.pack_title}</h1>
                <div className="info">
                  {/* <p>{item.address}</p> */}
                  <p>{item?.sec_name && item?.sec_name == "International" ? item.location_country : item.address}</p>
                  <p>
                    Booked with {item?.voucher_info?.voucher_code?`(${item?.voucher_info?.voucher_code}  ${item?.voucher_info?.voucher_amount?`of amount` +" " +  item?.voucher_info?.voucher_amount:"package price"})  ${item?.amount_paid>0 ? `&` +" "+  "Rs" + " " +item?.amount_paid:""}`:BookedWithPointAndRs(item.total_points, item.amount_paid)}
                  </p>
                  <p> <span style={{fontSize:"12px"}}>Booked on {moment(item?.created).format("Do MMMM, YYYY")}</span></p>
                </div>
              </div>
              {
                ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null)) ?
                  <div className="contact-info">
                    <div className="title">
                      <p>Booked for</p>
                    </div>
                    <div className="contact-details">
                      <p className="email">
                      <span>{Array.isArray(item.schedule) ? item.schedule.map((scheduleDate, index) => {
                                        if (item.schedule[index] === item.schedule[item.schedule.length - 1]) {
                                        return scheduleDate
                                        } else {
                                        return `${scheduleDate}, `
                                        }
                                    }) : (typeof item.schedule === 'object' && item.schedule !== null ? `${moment(item.schedule.from, "DD-MM-YYYY").format("DD-MM-YYYY")} ${ item.schedule.to ? `to ${moment(item.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}` : (item.schedule ===  null ? "N/A" : item.schedule))}</span>
                      </p>
                      <p>
                        {checkNumberOfGuest(item.adults, item.children, item.infants)}
                      </p>
                    </div>
                  </div> : ''

              }

              {/* {
                ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null)) ?
                  <div className="contact-info">
                    <div className="title">
                      <p>Message</p>
                    </div>
                    <div className="contact-details">
                      <p className="message-limit" title={item.special_request ? item.special_request : 'No Message'}>
                        {item.special_request ? item.special_request : (<p>No Message</p>)}
                      </p>
                    </div>
                  </div> : ''
              } */}

              <div>
                <p
                  onClick={() => handleInfoModal(item)}
                  style={{
                    backgroundColor: "#E7E7E7",
                    padding: "5px",
                    textAlign: "center",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}>More Info</p>
                <select
                  className="status-dropdown"
                  value={item.booking_status}
                  onChange={(event) =>
                    bookingStatusChange(item.id, event.target.value,item)
                  }
                >
                  {bookingConfirmAndCalcelledStatusValue.length > 0 &&
                    bookingConfirmAndCalcelledStatusValue.map((options) => (
                      <option value={options.value}>{options.name}</option>
                    ))}
                </select>
              </div>
              
            </div>
          ))}
          {showConfirmInfoModal &&
              <CommonMoreInfoModel
              open={showConfirmInfoModal}
              modalClose={() => setShowConfirmInfoModal(false)}
              moreInfoData={showInfoDataForConfirm}
              isCancellationMessage={false}
              />
                // <MoreInfoModal
                //   className="confirmed"
                //   showInfoModalData={showInfoDataForConfirm}
                //   showMyModal={showConfirmInfoModal}
                //   modalClose={() => setShowConfirmInfoModal(false)}

                // />
              }
                  {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
      </div>
    </div>
  );
}
