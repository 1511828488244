import moment from "moment";
import { ACTIVITY_LEVEL_OPTIONS } from "../resources/packageData";


export const BookedWithPointAndRs = (points, amount) => {
  if(points > 0 && amount > 0){
    return `${points} pts +  Rs ${amount}`
  }else if(points > 0 ){
    return `${ points } pts`
  }else {
    return `Rs ${amount}`
  }
};

export const dateFormat = (dateInput) => {
  let startdateFormate =dateInput!== undefined? dateInput.toString().split('-').reverse().join('-'): '';
  let options = {  month: 'long', day: 'numeric', year: 'numeric'};
  let newDate = new Date(startdateFormate);
  let resultedDate = newDate.toLocaleDateString('en-US', options);
  return resultedDate;
};

export const stringDateFormat = (stringInput) => {
  let resData = stringInput.slice(0,10);
  let newDate = new Date(resData);
  let resultedDate = newDate.toLocaleDateString('en-US', {  month: 'long', day: 'numeric', year: 'numeric'});
  return resultedDate;
};

export const removeSpaces = (string) => {
  return /\s/g.test(string) ? string.split(' ').join('') : string;
}
 
export  const scheduleDaysVal = (daysVal) => {
  if(daysVal && daysVal > 1) return `${daysVal} Days `
    else if(daysVal === undefined )return '';
    else if(daysVal && daysVal <= 1) return `${daysVal} Day `
  else return 'day'
}

export const scheduleNightsVal = (nightVal) => {
  if( nightVal > 1) return `${nightVal} Nights`
  else if(nightVal === undefined ) return '';
  else if ( nightVal ==0||nightVal==1) return `${nightVal} Night`
  else return 'Night'
}

export  const scheduleHoursVal = (hoursVal) => {
  if(hoursVal && hoursVal > 1) return `${hoursVal} hr `
    else if(hoursVal === undefined )return '';
    else if(hoursVal && hoursVal <= 1) return `${hoursVal} hr `
  else return '0hr'
}

export const scheduleMinutesVal = (minutesVal) => {
  if(minutesVal && minutesVal > 1) return `${minutesVal} min`
  else if(minutesVal === undefined ) return '';
  else if (minutesVal && minutesVal <= 1) return `${minutesVal} min`
  else return '0min'
}

export const recurringDaysVal = (days) => {
  var newRecurringDays = [];
    for (var i = 0; i < days.length; i++) {
      if (days[i] === 0) {
        newRecurringDays.push("Sunday");
      } else if (days[i] === 1) {
        newRecurringDays.push("Monday");
      }
      else if (days[i] === 2) {
        newRecurringDays.push("Tuesday");
      } else if (days[i] === 3) {
        newRecurringDays.push("Wednesday");
      } else if (days[i] === 4) {
        newRecurringDays.push("Thursday");
      } else if (days[i] === 5) {
        newRecurringDays.push("Friday");
      } else if (days[i] === 6) {
        newRecurringDays.push("Saturday");
      } else {
        const indexi = newRecurringDays.indexOf("start");
        if(indexi > -1) {
          newRecurringDays.splice(indexi, 1);
      }
    }
  }
}

export const isCorporate = (corporate, points, amount) => {
  let amountVal = parseInt(amount)
  if(corporate === true){
    if(amountVal > 0 && points > 0){
      return `Rs ${amount} + ${points} pts`
    } else if (points === 0 && amountVal > 0){
      return `Rs ${amount}`
    } else if (points > 0 && amountVal === 0 ){
      return `${points} pts`
    } else if (points === 0 && amountVal === 0){
      return "N/A"
    }
  } else {
    if (amountVal > 0){
      return `Rs ${amount}`
    } else {
      return "N/A"
    }
  }
}

export const checkNumberOfGuest = (adults, children, infants) => {
  if(adults === 1 && children === 1 && infants === 1){
    return `${adults} Adult, ${children} Kid, ${infants} Infant`
  }else if(adults === 1 && children > 1 && infants === 1){
    return `${adults} Adult, ${children} Kids, ${infants} Infant`
  }else if(adults === 1 && children === 1 && infants > 1){
    return `${adults} Adult, ${children} Kid, ${infants} Infants`
  }else if(adults === 1 && children > 1 && infants > 1){
    return `${adults} Adult, ${children} Kids, ${infants} Infants`
  }else if(adults > 1 && children > 1 && infants === 1){
    return `${adults} Adults, ${children} Kids, ${infants} Infant`
  }else if(adults > 1 && children === 1 && infants > 1){
    return `${adults} Adults, ${children} Kid, ${infants} Infants`
  }else if(adults > 1 && children === 1 && infants === 1){
    return `${adults} Adults, ${children} Kid, ${infants} Infant`
  }
  else if(adults > 1 && children >1 && infants > 1){
    return `${adults} Adults, ${children} Kids, ${infants} Infants`
  }
  else if(adults > 1 && children <= 0  && infants <= 0) return `${adults} Adults`
  else if(adults === 1 && children <= 0  && infants <= 0) return `${adults} Adult`
  else if(adults===1 && children <= 0 && infants > 1) return `${adults} Adult, ${infants} Infants`
  else if(adults>1 && children <= 0 && infants > 1) return `${adults} Adults, ${infants} Infants`
  else if(adults===1 && children <= 0 && infants === 1) return `${adults} Adult, ${infants} Infant`
  else if(adults>1 && children <= 0 && infants === 1) return `${adults} Adults, ${infants} Infant`
  else if(adults===1 && infants <= 0 && children > 1) return `${adults} Adult, ${children} Kids`
  else if(adults>1 && infants <= 0 && children > 1) return `${adults} Adults, ${children} Kids`
  else if(adults===1 && infants <= 0 && children === 1) return `${adults} Adult, ${children} Kid`
  else if(adults>1 && infants <= 0 && children === 1) return `${adults} Adults, ${children} Kid`
  else if(adults <= 0 && infants <= 0 && children <= 0) return "N/A"
  else if(adults <= 0 && (infants === 1 || children === 1)) return `${children} Kid, ${infants} Infant`

}

export const checkFeaturedAmenitiesLength = (arr) => {
  // const filteredArr = arr.filter((item) => {
  //   return item.value
  // })
  // if(filteredArr.length < 4 ){
  //   return true
  // }
  if(arr && arr.length > 0){
    const filteredArr = arr?.filter((item) => {
      return item.value
    })
    if(filteredArr?.length < 4 ){
      return true
    }
  }else{
    return true
  }
}

export const getActivityTypeVal = (val, arr) => {
  let value = "";
  arr?.map(item => {
    if(item.name == val){
      value = item.label
    }
  })
  return value
}

export const focusInput = (inputDom)=>{ 
    document.querySelector(`${inputDom}`)?.scrollIntoView();
};

export const countExpiredPackages = (data) => {
  let count = 0;
  data.packages?.map(item => {
    if(!item?.valid_pack){
      count = count + 1
    }
  })
  return count ? count + " " + "Expired": null;
}
export const countActivePackages = (data) => {
  let count = 0;
  data.packages?.map(item => {
    if(item?.valid_pack){
      count = count + 1
    }
  })
  return count + " " + "Active";
}

export const ACTIVITY_LEVEL_VALUE = (val) => {
  let value = "";
  ACTIVITY_LEVEL_OPTIONS.map(item => {
    if(item.id == val){
      value = item.name
    }
  })
  return value
}

export const handleLocationOrder = (location) => {
  let arr = [];
  if(Math.floor(location?.[0].latitude) == 0){
    arr = [location[1],location[0]]
  }else{
    arr = location
  }
  console.log(arr, "location test")
  return arr
}

export const isNewPack = (createdDate) => {
  const date = moment(createdDate).format("DD-MM-YYYY")
  const isNewPack = moment(date, 'DD-MM-YYYY').isAfter(moment("19-12-2022", "DD-MM-YYYY"))
  // true if package created after 19-12-2022
  console.log(date, isNewPack)
  return isNewPack
}

// export const checkValid = (data) => {
//   const valid = moment(data.valid_till, "YYYY-MM-DD").format("DD-MM-YYYY")
//   const condOne = !moment(valid, 'DD-MM-YYYY').isBefore(moment(), 'day')
//   if(condOne){
//     if(data.schedule_type == "date_range"){
//       let result;
//       const selectedDate = data?.schedule_data?.selectedDates
//       selectedDate?.map((item, index) => {
//         if(selectedDate.length == index + 1){
//           const date = item.to
//           result = !moment(date, 'DD-MM-YYYY').isBefore(moment(), 'day')
//           console.log(result,date, "data")
//         }
        
//       })
//       return result
//     }else{
//       return true
//     }
//   }else{
//     return false
//   }
  
// }

