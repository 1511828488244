import { FAMILY_FIRST_BOOKING_PAGE_ID, MY_FAMILY_FIRST_PLANNING, MY_FAMILY_FIRST_REQUESTED, 
    MY_FAMILY_FIRST_CONFIRMED, MY_FAMILY_FIRST_CANCELLED, FAMILY_FIRST_STORY_LIST, FAMILY_FIRST_STORY_DETAILS,
    FAMILY_FIRST_START_DATE, FAMILY_FIRST_END_DATE, REQUESTED_TOTAL_PAGE, PLANNING_TOTAL_PAGE, CONFIRMED_TOTAL_PAGE, CANCELLED_TOTAL_PAGE, REQUESTED_TOTAL_RESULTS, PLANNING_TOTAL_RESULTS, CONFIRMED_TOTAL_RESULTS, CANCELLED_TOTAL_RESULTS,FAMILY_FIRST_SEARCH_TERM
} from '../actions/types';

const initialState = {
  myFamilyFirstRequested:[],
  myFamilyFirstPlanning:[],
  myFamilyFirstConfirmed:[],
  myFamilyFirstCancelled:[],
  myFamilyFirstStoryDetails:null,
  myFamilyFirstStoryList:[],
  familyFirstStartDate: null,
  familyFirstEndDate: null, 
  requestedTotalPage:null ,
  planningTotalPage:null,  
  confirmedTotalPage:null, 
  cancelledTotalPage:null, 
  requestedTotalResults:null,
  planningTotalResults:null,
  confirmedTotalResults:null,
  cancelledTotalResults:null,
  page:1,
  searchTerm: ""
};
const myFamilyFirstReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case MY_FAMILY_FIRST_REQUESTED: {
            return {
                ...state,
                myFamilyFirstRequested: payload,
            }
        }
        case FAMILY_FIRST_STORY_LIST:{
          console.log(payload);
            return {
              ...state,
              myFamilyFirstStoryList:payload
            }
        }
        case FAMILY_FIRST_STORY_DETAILS:{
          return {
            ...state,
            myFamilyFirstStoryDetails:payload
          }
        }
        case MY_FAMILY_FIRST_PLANNING: {
            return {
                ...state,
                myFamilyFirstPlanning: payload,
            }
        }
        case MY_FAMILY_FIRST_CONFIRMED: {
            return {
                ...state,
                myFamilyFirstConfirmed: payload,
            }
        }
        case MY_FAMILY_FIRST_CANCELLED: {
            return {
                ...state,
                myFamilyFirstCancelled: payload,
            }
        }
        case FAMILY_FIRST_START_DATE: {
            return {
              ...state,
              familyFirstStartDate: payload,
            };
          }
          case FAMILY_FIRST_END_DATE: {
            return {
              ...state,
              familyFirstEndDate: payload,
            };
          }
          case REQUESTED_TOTAL_PAGE: {
            return {
              ...state,
              requestedTotalPage: payload,
            };
          }
          case PLANNING_TOTAL_PAGE: {
            return {
              ...state,
              planningTotalPage: payload,
            };
          }
          case CONFIRMED_TOTAL_PAGE: {
            return {
              ...state,
              confirmedTotalPage: payload,
            };
          }
          case CANCELLED_TOTAL_PAGE: {
            return {
              ...state,
              cancelledTotalPage: payload,
            };
          }
          case REQUESTED_TOTAL_RESULTS: {
            return {
              ...state,
              requestedTotalResults: payload,
            };
          }
          case PLANNING_TOTAL_RESULTS: {
            return {
              ...state,
              planningTotalResults: payload,
            };
           }
          case CONFIRMED_TOTAL_RESULTS: {
            return {
              ...state,
              confirmedTotalResults: payload,
            };
          }
          case CANCELLED_TOTAL_RESULTS: {
            return {
              ...state,
             cancelledTotalResults: payload,
            };
            
          
        }
        case FAMILY_FIRST_BOOKING_PAGE_ID: {
          return {
              ...state,
              page: payload,
          };
      }
        case FAMILY_FIRST_SEARCH_TERM: {
          return {
              ...state,
              searchTerm: payload,
          };
      }
        default:
           return state;
    }
};

export default myFamilyFirstReducer;
