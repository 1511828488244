

import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
  axiosDelete,
  axiosPut,
  axiosMediaUpload
} from "../services/apiServices";
import { 
  GET_EXPERIENCES_SECTION,
  EXPERIENCES_CONFIRM_ADD_BOOL,
  EXPERIENCES_CONFIRM_UPDATE_BOOL,
  EXPERIENCES_SELECTED_IMAGE_URL,
  EXPERIENCES_ADD_IMAGE,
  EXPERIENCE_SECTION_PACKAGES,
  IS_EXP_DATA_LOADING
} from '../actions/types'

export const getExperienceSection = () => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      // dispatch({
      //   type: IS_EXP_DATA_LOADING,
      //   payload: true,
      // });
      axiosGetWithToken(`${API.GET_EXPERIENCES_SECTION}`, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: GET_EXPERIENCES_SECTION,
              payload: res.data.data,
            });
            // dispatch({
            //   type: IS_EXP_DATA_LOADING,
            //   payload: false,
            // });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const handleExperienceAddConfirmBool = (val) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCES_CONFIRM_ADD_BOOL,
        payload: val
      });
    };
  };
  
  export const handleExperienceUpdateConfirmBool = (val) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCES_CONFIRM_UPDATE_BOOL,
        payload: val
      });
    };
  };

  export const experienceSelectedImageUrl = (url) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCES_SELECTED_IMAGE_URL,
        payload: url
      });
    };
  };

export const imageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;

    axiosMediaUpload(API.EXPERIENCE_POST_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        console.log("RESPO IMAGE", res);
        dispatch({
          type: EXPERIENCES_ADD_IMAGE,
          payload: res.data,
        });
        addImage(res.data.data);
        console.log("RESP2", res.data.data);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};


export const addExperienceSection = (experienceSectionSubmitData,refreshAddExperienceSectionData, addedSuccessHandle) => {
  return async (dispatch,getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_EXPERIENCE_SECTION_LIST_POST}`, experienceSectionSubmitData, token)
      .then((res) => {
        if(res.data.status_code === 200){
          dispatch({
            type:GET_EXPERIENCES_SECTION,
            payload:res.data.data
          })
          refreshAddExperienceSectionData()
          addedSuccessHandle()
        }
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const deleteExperienceSection = (item,handleSuccess) => {
  return async (dispatch, getState) => {

    const token = getState().auth.token;
    const sectionExperienceData = getState().experienceSection.getExperienceSection;
    let resDeleteData = sectionExperienceData.filter(itm => itm.id !== item.id);

    axiosDelete(`${API.EXPERIENCE_SECTION_DELETE}${item.id}`, token)
    .then((res) => {
      if(res.status === 204){
        handleSuccess("Deleted successfully");
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
    dispatch({
      type: GET_EXPERIENCES_SECTION,
      payload: resDeleteData
    });
  };
};

export const updateExperienceSection = (data, sectionUpdateData, updateHandleSuccess,) => {
  return async (dispatch,getState) => {
    let id = sectionUpdateData.id
    const token = getState().auth.token;
    axiosPut(`${API.EXPERIENCE_SECTION_DELETE}${id}/`, data, token)
      .then((res) => {
        if(res.data.status_code === 200){
          updateHandleSuccess()
        }
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const getSectionPackages = (itemNeedToBeDeleted) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;  
    let id = itemNeedToBeDeleted.id      
    axiosGetWithToken(`${API.GET_EXPERIENCE_SECTION_PACKAGES}${id}/`, token)
        .then((res) => {
          console.log("jhkjhjkhkjhk", res)
          dispatch({
            type:EXPERIENCE_SECTION_PACKAGES,
            payload:res.data.data.total_pack
          })
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
};