import { PENDING_PAYMENT_BOOKINGS, BOOKED_PAYMENT_BOOKINGS} from "../actions/types";

const initialState = {
    pendingPaymentList: [],
    bookedPaymentList: [],
    
};



const paymentLinkBookingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PENDING_PAYMENT_BOOKINGS: {
            return {
                ...state,
                pendingPaymentList: payload,
            }
        }
        case BOOKED_PAYMENT_BOOKINGS: {
            return {
                ...state,
                bookedPaymentList: payload,
            }
        }
        
        default:
            return state;
    }
};

export default paymentLinkBookingReducer;
