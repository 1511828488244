import React, {useState, useEffect} from 'react';
import ReviewBlock from './reviewsBlock';
import ReviewDialog from './reviewsDialog';
import { useSelector, useDispatch } from "react-redux";
import * as  EventListingAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";

const Reviews = ({module, id,mainPackId})=>{
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState('add');
    const [reviewEditData, setreviewEditData] = useState({
        "pack_id": "",
        "review_star": "",
        "review_comment": "",
        "custom_user_name": "",
        "review_id": ""
    });
    const dispatch = useDispatch();
    
    const corpReview = useSelector((state) => state.corpListing.corporatePackReviewList);
    const holidayReviewList = useSelector((state) => state.holidayReviews.holidayPackReviewList);
    const expReviewList = useSelector((state) => state.experiencesListing.experienceReviewList);
    const [ allReviewList, setallReviewList ] = useState()
    
    useEffect(()=>{
        if(module === "corporate"){
            dispatch(CorpListingAction.getCorporateReviewsList(id));
        }else if(module === 'holiday'){
            dispatch(HolidaysAction.getHolidayReviewsList(id))
        }else if(module === 'experience'){
            dispatch(experiencesListingAction.getExperienceReviewsList(id))
        }
        else{
            return;
        }
    }, [id, module]);

    useEffect(()=>{
        if(module === "corporate"){
            setallReviewList(corpReview)
        }else if(module === 'holiday'){
            setallReviewList(holidayReviewList)
        }else if(module === 'experience'){
            setallReviewList(expReviewList)
        }
        else{
            return;
        }
    }, [ corpReview, holidayReviewList, expReviewList]);


    const showAddReviewDialog = (e)=>{
        e.preventDefault();
        showDialog(true, 'add');
    };
    const showDialog = (isOpen, type, editData)=>{
        setIsDialogOpen(isOpen);
        setDialogType(type);
        if(type==='edit'){
            setreviewEditData(editData);
        }
    };
    return (
        <div>
        <div className="row">
            <div className="col">Reviews </div> 
            <div className="row col justify-content-end">
                <button className="mr-4 px-3 rounded bg-white" style={{border: "1px solid #c4c4c4" }} 
                    disabled={allReviewList?.length>5}
                 onClick={showAddReviewDialog}>Add</button>
            </div> 
        </div>
        <div className="row mt-4">
            
            <div className="col">
                {allReviewList?.map((review, index)=>{
                    return <ReviewBlock key={`review-${index}`} data={review}  name={review.custom_user_name} review={review.review_comment} reviewId={review.id} showDialog={showDialog} mainPackId={mainPackId} eventId={id} module={module} rating={review.review_star}
                    packId={id}
                    />
                })}
                {!allReviewList?.length&&<div style={{textAlign:'center', color:'#545454'}}>No Reviews added to this {module === "event" ? 'event' : 'package'}</div>}
            </div>
        </div>
        {isDialogOpen&&<ReviewDialog isDialogOpen={isDialogOpen} handleClose={()=>setIsDialogOpen(false)} type={dialogType} module={module} id={id} 
        mainPackId={mainPackId} editData={reviewEditData}  />}
        </div>
    );
};

export default Reviews;