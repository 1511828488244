import React from 'react';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import Parser from 'html-react-parser';
import * as EventsAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";

import { useDispatch, useSelector } from "react-redux";

const TestimonialBlock = ({ userImage, name, testimonial, showDialog, tid, eventId, module,cid ,holiday_Id}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditBtnClick = () => {
        setAnchorEl(null);
        showDialog(true, 'edit', {
            "guest_name": name,
            "guest_image": userImage,
            "message": testimonial,
            "tid": tid
        });


    };
    const handleDeleteBtnClick = () => {
        // confirm('Are you sure?');

        if (window.confirm('Are you sure you want delete the Testimonial?')) {
            // Save it!
            if (module === 'event') {
                dispatch(EventsAction.deleteTestimonials(tid, handleDeleteSuccess, handleDeleteError));
            } else if (module === 'corporate') {
                dispatch(CorpListingAction.deleteCorpTestimonials(tid, eventId,handleDeleteSuccess, handleDeleteError));
            } else if (module === 'holiday') {
                dispatch(HolidaysAction.deleteHolidayTestimonials(tid,eventId, handleDeleteSuccess, handleDeleteError));
            }else if(module === 'experience'){
                dispatch(experiencesListingAction.deleteExpTestimonials(tid, eventId,handleDeleteSuccess, handleDeleteError));
            }
        } else {
            // Do nothing!
            console.log('Dont do.');
        }

        setAnchorEl(null);
    };
    const handleDeleteSuccess = () => {
        if (module === 'event') {
            dispatch(EventsAction.getEventTestimonials(cid));
        } else if (module === 'corporate') {
            dispatch(CorpListingAction.getCorporateTestimonials(cid));
        } else if (module === 'holiday') {
            dispatch(HolidaysAction.getHolidayTestimonials(cid))
        }else if(module === 'experience'){
            dispatch(experiencesListingAction.getExpTestimonials(cid))
        }

    };
    const handleDeleteError = () => {

    }
    return (
        <div className="row bg-white mb-3 p-3  mx-1 rounded">
            <div className="col ml-n3" style={{ flex: '0 0 75px' }}>
                {userImage !== '' ?
                    <img className="rounded-circle mt-2" style={{ width: '50px' }} src={userImage} alt="user image" />
                    : <div className="nameChar">
                        <p>{name !== undefined && name?.charAt(0).toUpperCase()}</p>
                    </div>
                }
            </div>
            <div className="col ">
                <div className="row">
                    <div className="col"><h5 className="mb-1" style={{ fontSize: '18px' }}>{name}</h5></div>
                    <div className="col text-right" style={{ flex: "0 0 100px" }}>
                        <span>
                            {/* <MoreHorizIcon /> */}
                            <span
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <MoreHorizIcon />
                            </span>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem divider={true} onClick={handleEditBtnClick}>
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={() => handleDeleteBtnClick()}>
                                    Delete
                                </MenuItem>
                            </Menu>
                        </span></div>
                </div>
                <div style={{ fontSize: '12px' }}>{Parser(testimonial)}</div>
            </div>
        </div>
    );
};

export default TestimonialBlock;