import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchBar from '../../../common/commonSearchBar';
import MyFamilyFirstBooking from '../../../common/myFamilyFirstBookings'
import MyFamilyFirstMoreInfoModal from './myFamilyFirstMoreInfoModal';
import {
    myFamilyFirstCancelled,
    familyFirstStatusUpdateConfirm,
    myFamilyFirstRequested,
    myFamilyFirstPlanning, 
    myFamilyFirstConfirmed,
    familyFirstStatusUpdate,
    familyFirstSearchRequested,
    pageId,
    setSearchTerm
} from '../../../actions/myFamilyFirstAction';
import Pagination from '../../../common/pagination';
import ConfirmModal from '../../wedding/requests/confirmModal'

const Cancelled = () => {

    const [ showMyFamilyFirstMoreInfoModal, setShowMyFamilyFirstMoreInfoModal ] = useState(false);
    const [ myFamilyFirstMoreInfoModalData, setMyFamilyFirstMoreInfoModalData ] = useState({});
    const [page, setPage] = useState(1);
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    
    const [ id, setId ] = useState(null);

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
   
    const dispatch = useDispatch();
    const START_DATE = useSelector(state => state.myFamilyFirst.familyFirstStartDate);
   const END_DATE = useSelector(state => state.myFamilyFirst.familyFirstEndDate);
    const SEARCH_TERM = useSelector(state => state.myFamilyFirst.searchTerm);
   const PAGE_NO = useSelector(state => state.myFamilyFirst.page);

    const myFamilyFirstCancelledData = useSelector(state => state.myFamilyFirst?.myFamilyFirstCancelled);
    const totalPage = useSelector(state => state.myFamilyFirst?.cancelledTotalPage);

    useEffect(() => {
       
      let value = [];
      myFamilyFirstCancelledData?.map((elem) => {
        if (elem.price) {
          value.push(elem.price)
       
        }
        else{
          value.push(0)
        }
      
      },);
      console.log(myFamilyFirstCancelledData);
         
      const valueSum=value.reduce((acc,elem)=>{
          return acc+elem
  
      },0)
      console.log(value);
      console.log(valueSum/value?.length);
      setTotalOrderValue(valueSum)
      setAverageOrderValue(Math.round(valueSum/myFamilyFirstCancelledData?.length));
         }, [myFamilyFirstCancelledData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMyFamilyFirstMoreInfoModal]);

    // useEffect(() => {
    //     if (myFamilyFirstCancelledData?.length === 0) {
    //         dispatch(myFamilyFirstCancelled(1));
    //         dispatch(pageId(1));
    //         setPage(1);
    //     }
    // }, [myFamilyFirstCancelledData, page])
   
    const handleMoreInfoClick  = (data) => {
        setShowMyFamilyFirstMoreInfoModal(true);
        setMyFamilyFirstMoreInfoModalData(data);
    };
    
    const refreshFamilyFirstCancelled = () => {
        dispatch(familyFirstSearchRequested(page, "", 'requested'));
        dispatch(familyFirstSearchRequested(page, "", 'planning'));
        dispatch(familyFirstSearchRequested(page, "", 'confirmed'));
        dispatch(familyFirstSearchRequested(page, "", 'cancelled'));
    }; 

   
    const myFamilyFirstStatusChange = (event, id,data) => {
      setId(id);
      setConfirmedRequestModalData(data)
        let status = event.target.value;
  
        if(status === "confirmed")setRequestModalConfirm(true);
        else{
          dispatch(familyFirstSearchRequested("", 'requested'));
        dispatch(familyFirstStatusUpdate(status, id, refreshFamilyFirstCancelled));
        dispatch(pageId(page));

        }
        
    };

    const confirmRequestHandler=()=>{
        
        
      setIsconfirmAmountBool(false)
     if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
      else {
      dispatch(familyFirstStatusUpdateConfirm("confirmed", id,confirmedRequestAmount, refreshFamilyFirstCancelled))
        setRequestModalConfirm(false)
      }
      // dispatch(confirmRequestInputVal(''));
     
      
  
    }


    const notToConfirm=()=>{
   
      setRequestModalConfirm(false)
      setIsconfirmAmountBool(false);
    
    }
    const handlePagination = (e,value) => {
        setPage(value);
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(familyFirstSearchRequested(value, SEARCH_TERM, "cancelled"));
        }else{
          dispatch(myFamilyFirstCancelled(value));
        }
        
        dispatch(pageId(value));
    };

    const handleChange = (event) => {
        if(event.target.value){
          dispatch(familyFirstSearchRequested(PAGE_NO,event.target.value, "cancelled"));
        }else{
          dispatch(myFamilyFirstCancelled(1));
          setPage(1);
        }
        dispatch(setSearchTerm(event.target.value))
        };

    const myFamilyFirstCancelledDisplaydata = myFamilyFirstCancelledData && myFamilyFirstCancelledData.map(data => (
        <MyFamilyFirstBooking 
          locationsPreferred={data.locations_preferred}
          occasion={data.occasion}
          requestedBy={data.contact_name}
          email={data.contact_email}
          contactNum={data.contact_number}
          messageTitle={data.specific_request ? data.specific_request : 'No Message'}
          specialRequest={data.specific_request? data.specific_request:"N/A"}
          onClick={() => handleMoreInfoClick(data)}
          myFamilyFirstStatusChange={myFamilyFirstStatusChange}
          data={data}
          id={data.id}
          refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
          value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
                searchVal={SEARCH_TERM}
                handleChange={handleChange}
            />
          {myFamilyFirstCancelledData && myFamilyFirstCancelledData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
   <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
          {myFamilyFirstCancelledDisplaydata}
          <MyFamilyFirstMoreInfoModal
              open={showMyFamilyFirstMoreInfoModal}
              close={() => setShowMyFamilyFirstMoreInfoModal(false)}
              modalData={myFamilyFirstMoreInfoModalData}
              isCancellationMessage={true}
            />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
                      {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
        </>
    )
}

export default Cancelled;
