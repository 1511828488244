import React, { useEffect, useState } from 'react';
import { Row, Col } from "antd";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as EventsBookingAction from "../../../actions/eventsAction";
import UpcomingBookings from "../../../components/events/bookings/upcomingBookings";
import PastBookings from "../../../components/events/bookings/pastBookings";
import CancelledBookings from "../../../components/events/bookings/cancelledBookings";
import '../../../assets/css/partials/events/events.scss';
import DropdownFilter from "../../corporates/bookings/DropdownFilter";
// import DropdownFilter from "../listings/dropDownFilter"
import Datefilter from "./dateFilter";
import Pagination from '@material-ui/lab/Pagination';

const { TabPane } = Tabs;

function Index() {
    const dispatch = useDispatch();
    const upcomingBookingsList = useSelector(state => state?.eventsListing?.upcoming_bookings?.data?.results);
    const upcomingListPages = useSelector(state => state?.eventsListing?.upcoming_bookings?.data);
    const pastBookingsList = useSelector(state => state?.eventsListing?.past_bookings?.data?.results);
    const pastBookingsPages = useSelector(state => state?.eventsListing?.past_bookings?.data);
    const cancelledBookingsList = useSelector(state => state?.eventsListing?.cancelled_bookings?.data?.results);
    const cancelledBookingsPages = useSelector(state => state?.eventsListing?.cancelled_bookings?.data);
    const getSecId = useSelector(state => state?.eventsListing?.getSecId);
    const getCatId = useSelector(state => state?.eventsListing?.event_category_id);

    // var pageNum = useSelector((state) => state.eventsListing.page_num);
    const [selectedTab, setSelectedTab] = useState("upcoming");
    const updatedId = upcomingBookingsList && upcomingBookingsList[0]?.id;
    const [page, setPage] = useState(1);
    const [pageChange, setPageChange] = useState(false);

    const handleResetPage = () => {
        setPage(1)
      }

    function getSelectedTab(key) {
        setSelectedTab(key);
        setPage(1);
        dispatch(EventsBookingAction.filterStartDate(null));
        dispatch(EventsBookingAction.filterEndDate(null));
        
    }
    useEffect(()=>{
        // dispatch(EventsBookingAction.getUpcomingBookingsList(page, null));
        // dispatch(EventsBookingAction.getPastBookingsList(page, null));
        // dispatch(EventsBookingAction.getCancelledBookingsList(page, null));
        
        return () => {
            dispatch(EventsBookingAction.getUpcomingBookingsList(page, null));
            dispatch(EventsBookingAction.getPastBookingsList(page, null));
            dispatch(EventsBookingAction.getCancelledBookingsList(page, null));
        }
    }, []);
    
    useEffect(() => {
        // dispatch(EventsBookingAction.getUpcomingBookingsList(page, getSecId));
        // dispatch(EventsBookingAction.getPastBookingsList(page, getSecId));
        // dispatch(EventsBookingAction.getCancelledBookingsList(page, getSecId));
       
    }, [pageChange])
  
    useEffect(() => {
        dispatch(EventsBookingAction.getUpcomingBookingsList(page, getSecId));
        dispatch(EventsBookingAction.getPastBookingsList(page, getSecId));
        dispatch(EventsBookingAction.getCancelledBookingsList(page, getSecId));
        dispatch(EventsBookingAction.filterStartDate(null));
        dispatch(EventsBookingAction.filterEndDate(null));
        if(pageChange){
            dispatch(EventsBookingAction.setCategoryId(updatedId));
        }
    }, [page, pageChange ? updatedId : '']);
    
    const onPageChange = (value) => {
        setPage(value);
        dispatch(EventsBookingAction.getUpcomingBookingsList(page, getSecId));
        handlePageChange();
    }  

    const handlePageChange = () => {
        setPageChange(true);
    }



    const totalEvents = upcomingListPages?.total_results + pastBookingsPages?.total_results + cancelledBookingsPages?.total_results;

    return (
        <div>
            <div style={{ position: "absolute", right: 50 }}>
                     <Datefilter />
            </div>
            <div className="enquiry-tabs-holder">
                <div style={{ display: 'flex' }}>
                    <p className="title-holder">{totalEvents} Events</p>
                    <div>
                        <DropdownFilter status={"filteredEventsListArray"}  eventBookingSelectedTab={selectedTab}/>
                        {/* <DropdownFilter /> */}
                    </div>
                </div>
                <Tabs defaultActiveKey="Upcoming" onChange={getSelectedTab}>
                    <TabPane tab={upcomingListPages?.total_results + " " + "Upcoming"} key="upcoming">
                        {upcomingBookingsList && selectedTab==='upcoming' ? <UpcomingBookings allUpcomingBookings={upcomingBookingsList} onResetPage={handleResetPage} pageChanged={pageChange ? pageChange : null} setPageChange={setPageChange} pageNum={page} selectedPage={page} /> : null}
                        <Pagination
                        count={upcomingListPages?.total_pages}
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(event, value) => onPageChange(value)}
                    />
                    </TabPane>
                    <TabPane tab={pastBookingsPages?.total_results + " " + "Past"} key="past">
                        {pastBookingsList && selectedTab==='past' ?<PastBookings pastBookings={pastBookingsList} onResetPage={handleResetPage} setPageChange={setPageChange} selectedPage={page} />:null}
                        <Pagination
                        count={pastBookingsPages?.total_pages}
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(event, value) => setPage(value)} />
                    </TabPane>
                    <TabPane tab={cancelledBookingsPages?.total_results + " " + "Cancelled"} key="cancelled">
                        {cancelledBookingsList && selectedTab==='cancelled' ?<CancelledBookings cancelledBookings={cancelledBookingsList} onResetPage={handleResetPage} setPageChange={setPageChange} selectedPage={page}  />:null}
                        <Pagination
                        count={cancelledBookingsPages?.total_pages}
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Index;