import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from "../../../assets/images/rightArrow.svg";
import { searchListing } from "../../../actions/corpListingAction";
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import * as CorporateListingAction from "../../../actions/corpListingAction";
import * as EventListingAction from "../../../actions/eventsAction";
import SearchBar from "../../../common/searchBar";
import { setISODay } from "date-fns";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  rootBorder: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "2px solid #000000",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  location: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 12,
    color: "#797979",
  },
  rightArrow: {
    position: "absolute",
    right: "16px",
    top: "22px",
  },
}));

export default function Listing({
  currentPage,
  eventDetails,
  handleEdit,
  handleAdd,
  handleResetPage
}) {
  const classes = useStyles();
  const [searchBool, setsearchBool] = useState(false);
  const [chosen, setChosen] = useState(
    eventDetails?.results && eventDetails?.results[0]?.id
  );
  const [addFlowLogo, setAddFlowLogo] = useState();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [editDetailsId, setEditDetailsId] = useState("");
  const search = useSelector((state) => state.eventsListing.eventlistSearchVal);
  const selectedChosenPckId = useSelector(
    (state) => state.eventsListing.selectedChosenId
  );
  const dispatch = useDispatch();
  const addEventListDetails = useSelector(
    (state) => state.eventsListing.addNewEvent
  );
  const corpSearchListData = useSelector(
    (state) => state.corpListing.searchCorpListData.data
  );
  const updateBoolVal = useSelector(
    (state) => state.eventsListing.eventListUpdateBoolVal
  );
  const addBoolVal = useSelector(
    (state) => state.eventsListing.eventListAddBoolVal
  );

  useEffect(() => {
    setChosen(eventDetails?.results && eventDetails?.results[0]?.id);
    if (updateBoolVal) {
      dispatch(EventListingAction.getEventsEditInfo(editDetailsId));
      setChosen(editDetailsId);
    } else {
      dispatch(
        EventListingAction.getEventsEditInfo(eventDetails?.results[0]?.id)
      );
      setChosen(eventDetails?.results[0]?.id);
    }
  }, [currentPage, eventDetails?.results && eventDetails?.results[0]?.id]);

  // useEffect(() => {
  //     if (addBoolVal) {
  //         setChosen(eventDetails?.results && eventDetails?.results[0]?.id);
  //     }
  // }, [eventDetails?.results && eventDetails?.results[0]?.id]);

  // useEffect(() => {
  //     return dispatch(EventListingAction.getEventsEditInfo(eventDetails?.results[0]?.id))
  // }, []);

  useEffect(() => {
    if (selectedChosenPckId) {
      setChosen(eventDetails?.results[0]?.id);
      dispatch(
        EventListingAction.getEventsEditInfo(eventDetails?.results[0]?.id)
      );
    }
  }, [selectedChosenPckId]);

  const setEditDetails = (id) => {
    setEditDetailsId(id);
    addEventListDetails &&
      dispatch(EventListingAction.setAddEventPackage(false));
    setChosen(id);
    setShow(false);
    handleEdit(id);
    dispatch(EventListingAction.getEventsEditInfo(id));
  };

  useEffect(() => {
    setShow(false);
    dispatch(EventListingAction.setAddEventPackage(false));
  }, []);

  const setNewDetails = (id) => {
    setAddFlowLogo(id);
    setShow(true);
    dispatch(EventListingAction.setAddEventPackage(true));
    dispatch(EventListingAction.handleAllEventsImages([]));
    handleAdd();
  };

  // const handleChange = (e) => {
  //     let input = e.target.value;
  //     dispatch(EventListingAction.eventListingSearch(input));
  //     dispatch(EventListingAction.eventSearchValue(input));
  // };
  useEffect(() => {
    handleResetPage()
    dispatch(EventListingAction.eventListingSearch(searchVal));
    dispatch(EventListingAction.eventSearchValue(searchVal));
  }, [searchVal]);
  return (
    <div style={{ width: "100%" }}>
      <Row
        className={classes.row}
        style={{ margin: "3px 3px 0px 2px", alignItems: "center" }}
      >
        <Col className="w-100">
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div
        className="overflow--scroll"
        style={{ paddingRight: "20px", marginRight: "-20px" }}
      >
        {addEventListDetails && (
          <Card className={addFlowLogo ? classes.rootBorder : classes.root}>
            <h6 className={classes.title}>Adding New</h6>
            <p className={classes.location}>No location provided yet</p>
            {addFlowLogo ? (
              <img className={classes.rightArrow} src={rightArrow} />
            ) : null}
          </Card>
        )}
        {searchBool
          ? corpSearchListData &&
            corpSearchListData.length > 0 &&
            corpSearchListData.map((item) => (
              <Card
                className={
                  item.id === chosen && !show
                    ? classes.rootBorder
                    : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}></h6>

                <h6 className={classes.title}>{item.section.name}</h6>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))
          : eventDetails?.results &&
            eventDetails?.results.length > 0 &&
            eventDetails?.results.map((item) => (
              <Card
                className={
                  item.id === chosen && !show
                    ? classes.rootBorder
                    : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.packages[0].pack_title}</h6>

                <p>{item.section === null ? "" : item.section.name}</p>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))}
        {eventDetails?.results?.length === 0 && (
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card>
        )}
      </div>
    </div>
  );
}
