import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
// import TimePicker from 'react-time-picker';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        height: '55%',
        width: '60%',
        backgroundColor: '#F5F5F5'
    },

}));

export default function ShedulePopup({ openPopup, closeModal, updatePopupData, currentPackageData }) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [days, setDays] = React.useState();
    const [nights, setNights] = React.useState();
    const [value, onChange] = useState('10:00')
    const [date, setDate] = useState(currentPackageData.schedule_data.date);
    const [startTime, setStartTime] = useState(currentPackageData.schedule_data.from);
    const [endTime, setEndTime] = useState(currentPackageData.schedule_data.to);
    const [isDateErrorValidation, setIsDateErrorValidation] = useState(null);
    const maxTime = new Date("01/02/2021 12:00 PM");
    const [schError, setSchError] = useState({
        hasError:false,
        msg:''
    });

    const clearSchError = ()=>{
        setSchError({
            hasError:false,
            msg:''
        });
    };
    const updateSchError = (hasEr, erMsg)=>{
        setSchError({
            hasError:hasEr,
            msg:erMsg
        });
    }
    const handleClickOpen = () => {
        setOpen(closeModal);
    };

    const handleClose = () => {
        setOpen(closeModal);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleDaysCount = (event) => {
        const days = event.target.value
        setDays(days)
    }

    const handleNightsCount = (event) => {
        const nights = event.target.value
        setNights(nights)
    }
    const dateChange = (date) => {
        setDate(date.format('DD-MM-YYYY'))

    }
    const startTimeChange = (startTime) => {
        if (startTime !== null) setStartTime(startTime)
    };

    const endTimeChange = (endTime) => {
        setEndTime(endTime)
    };

    const handleStartTime = (event) => {
        const start_time = event.target.value;
        setStartTime(start_time);
    }

    const handleEndTime = (event) => {
        const end_time = event.target.value;
        setEndTime(end_time);
    }

    const onClickHandler = () => {
        let start_time = document.getElementById("start-time").value;
        let end_time = document.getElementById("end-time").value;
        if(!date){
            updateSchError(true, 'Please select Date');
            return;
        }
        if(!start_time){
            updateSchError(true, 'Please enter Start Time');
            return;
        }
        if(!end_time){
            updateSchError(true, 'Please enter End Time');
            return;
        }
        setIsDateErrorValidation(false)
        if (date.e === {}) setIsDateErrorValidation(true);
        else {
            updatePopupData(date, start_time, end_time);
            clearSchError();
        }
    }

    const deleteHandler = () => {
        setDate('');
        setStartTime('');
        setEndTime('');
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    return (
        <React.Fragment>

            <Dialog
                PaperProps={{
                    style: {
                        height: "auto"
                    },
                }}

                maxWidth={maxWidth}
                open={openPopup}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle id="max-width-dialog-title" style={{ marginLeft: '25px', overflow: 'hodden' }}>Add Schedule</DialogTitle>
                <DialogContent>
                    <button style={{ height: '13%', width: '12%', backgroundColor: 'white', pointer: 'arrow', marginLeft: '23px', borderRadius: '5px', border: 'none' }}>
                        <span>Packages</span>
                    </button>
                    <DialogContent>
                    {schError.hasError&&<div  style={{ color: "red", marginTop:'5px' }}>{schError.msg}</div>}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                style={{ flex: 0.5, position: "relative", paddingRight: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                {
                                    <Calendar
                                        format="DD-MM-YYYY"
                                        minDate={new Date()}
                                        value={date}
                                        onChange={dateChange}
                                    />
                                }
                            </div>
                            <div
                                style={{ width: '30%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Time</label>
                                    {/* <DatePicker
                                        disableDayPicker
                                        minDate={new Date()}
                                        format="HH:mm A"
                                        
                                        plugins={[
                                            <TimePicker hideSeconds/>
                                        ]}
                                        value={startTime}
                                        onChange={startTimeChange}
                                    />
                          
                                    <DatePicker
                                        disableDayPicker
                                        minDate={new Date()}
                                        format="HH:mm A"
                                        
                                        plugins={[
                                            <TimePicker hideSeconds />
                                        ]}
                                        value={endTime}
                                        onChange={endTimeChange}
                                    /> */}<br />
                                    <label for="start-time" style={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>Start Time:</label>
                                    <input style={{ float: 'right', width: '58%' }} type="time" id="start-time" name="start-time"
                                        value={startTime} onChange={handleStartTime} required></input>
                                    <label for="end-time" style={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>End Time:</label>
                                    <input style={{ float: 'right', width: '58%' }} type="time" id="end-time" name="end-time"
                                        value={endTime} onChange={handleEndTime} required></input>
                                </div>
                            </div>
                            <div
                                style={{ width: '24%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>
                                        {`${date}`}<br />
                                        <p style={{marginTop:'10px'}}>{`${tConvert(startTime)} - ${tConvert(endTime)}`}</p>
                                    </div>
                                    <button onClick={deleteHandler} style={{ marginTop: '10px', height: '38px', width: '100%', backgroundColor: '#F5F5F5', color: 'black', borderRadius: '5px', border: 'none' }}>
                                        <span>Delete</span>
                                    </button>
                                    <button onClick={onClickHandler} style={{ marginTop: '10px', height: '38px', width: '100%', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
