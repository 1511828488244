

import {
    SEARCH_VOUCHER_PURCHASED,
    SEARCH_VOUCHER_PLANNING,
    SEARCH_VOUCHER_CONFIRMED,
    ENQUIRY_VOUCHER_CANCELLED,
    VOUCHER_FILTER_START_DATE,
    VOUCHER_FILTER_END_DATE,
} from "../actions/giftVoucherActions";

import {
    GIFT_VOUCHER_PURCHASED,
    UPDATE_VOUCHER_STATUS,
    PLANNING_LIST,
    CONFIRMED_LIST,
    CANCELLED_LIST


} from "../actions/types";

const initialState = {
    voucherPurchased: [],
    planningQueries: [],
    confirmedQueries: [],
    cancelledQueries: [],
    selectedStatus: undefined,
    followUpSearchText: "",
    filterStartDate: null,
    filterEndDate: null,
};

const giftVoucherReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GIFT_VOUCHER_PURCHASED: {
            return {
                ...state,
                voucherPurchased: payload,
            };
        }
        case SEARCH_VOUCHER_PURCHASED: {
            return {
                ...state,
                voucherPurchased: payload,
            };
        }
        case UPDATE_VOUCHER_STATUS: {
            return {
                ...state,
                selectedStatus: payload,
            };
        }
        case PLANNING_LIST: {
            return {
                ...state,
                planningQueries: payload,
            };
        }
        case CONFIRMED_LIST: {
            return {
                ...state,
                confirmedQueries: payload,
            };
        }
        case CANCELLED_LIST: {
            return {
                ...state,
                cancelledQueries: payload,
            };
        }
        case SEARCH_VOUCHER_PLANNING: {
            return {
                ...state,
                planningQueries: payload,
            };
        }
        case SEARCH_VOUCHER_CONFIRMED: {
            return {
                ...state,
                confirmedQueries: payload,
            };
        }
        case ENQUIRY_VOUCHER_CANCELLED: {
            return {
                ...state,
                cancelledQueries: payload,
            };
        }
        case VOUCHER_FILTER_START_DATE: {
            return {
                ...state,
                filterStartDate: payload,
            };
        }
        case VOUCHER_FILTER_END_DATE: {
            return {
                ...state,
                filterEndDate: payload,
            };
        }

        default:
            return state;
    }
};

export default giftVoucherReducer;
