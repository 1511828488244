import React,{ useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { ImCross } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import * as BookingAction from "../actions/bookingAction"
import { useSnackbar } from "notistack";

import   "../assets/css/partials/closeRequestModal.scss"


const ConfirmRefundModal = ({ modalClose,open,section,refundPackId}) => {
    console.log(section,refundPackId);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleRefundSuccess = (message) => {
        enqueueSnackbar(`${message}`, {
            variant: "success",
        });

    };

    const handleErrorMessage = (message) => {
        enqueueSnackbar(`${message}`, {
            variant: "error",
        });
       
    };


    
    const handleRefund=()=>{
        dispatch(BookingAction.refundBookedPackage(section,refundPackId,modalClose,handleRefundSuccess,handleErrorMessage))

    }
    
 
  return (
    <Dialog
    PaperProps={{
        style: {
            height: "auto",
            maxWidth: "535px"
        },
    }}
    open={open}
>
    <div style={{padding:"10px"}}>
    <div style={{margin:"10px 0px", padding:"10px"}}>
        <h5>Are you sure that you want to  process the refund ?</h5>
        <span className="close-icon"
            onClick={modalClose}>
            <ImCross />
        </span>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
            style={{ flex: 0.5, position: "relative" }}
            className="form-group form-custom"
        >
        
        </div>
      
    </div>
    <div style={{display:"flex",float:"right"}}>
        <Button   onClick={modalClose} color="black" style={{ backgroundColor: "#EDEDED", textTransform: "none", margin: "0px 25px" , width: "155px"}}>
          Cancel
            </Button>
        <Button  onClick={handleRefund} color="primary" style={{ backgroundColor: "#007BFF", color: "white", textTransform: "none", marginLeft: "10px" , width: "155px" }}>
            Refund
         </Button>
    </div>
    </div>
</Dialog>
  )
}

export default ConfirmRefundModal