import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Listing  from "./listing"
import AddPartner from "./addPartner"
import EditPartner from "./editPartner"
import * as partnerAction from "../../../actions/partnerAction";
import Pagination from '@material-ui/lab/Pagination';
import { Spinner } from "react-bootstrap";
import FollowUp from "../partners/followUp";
// import * as AccountAction from "../../../actions/accountsAction";

const Index = () => {
    const [page, setPage] = useState(1);
    const [addPartner, setAddPartner] = useState(false);
    const dispatch = useDispatch()
    const partnerList = useSelector((state) => state.partnerListing?.partnersList);
    const id = useSelector((state) => state.partnerListing?.partnersList?.results?.[0]?.id);
    const isDataLoading = useSelector(
      (state) => state.corpListing.isDataLoading
    );
    const handleEdit = (id) => {
        dispatch(partnerAction.getPartnerInfo(id));
        setAddPartner(false)
    };
    useEffect(() => {
        if(partnerList?.results?.length > 0){
          dispatch(partnerAction.getPartnerInfo(id));
        }
    }, [partnerList?.results?.[0]?.id]);

    const handleAdd = () => {
        setAddPartner(true)
    }
  
    useEffect(() => {
        dispatch(partnerAction.partnersList(page));
    }, [page]);

  return (
    <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div className="col-md-8" style={{ display: "flex", padding:"0px" }}>
            <h1 className="title-holder">{partnerList && partnerList?.total_results} Corporate Partner</h1>
            </div>
        </div>
        <Row>
        <Col
          style={{ paddingRight: "20px", marginRight: "-20px" }}
          className="gutter-row"
          xs={24}
          lg={8}
        >
          
          <Row className="gutter-row ">
            <Listing
              partnerList={partnerList}
              handleEdit={handleEdit}
              handleAdd={handleAdd}
            //   currentPage={page}
            //   handleResetPage={handleResetPage}
            />
          </Row>
          <Row >
          <Pagination
              count={partnerList?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, value) => setPage(value)}
          />
          </Row>
        </Col>
        <Col className="gutter-row" xs={24} lg={16}>
        {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
          {addPartner ? <AddPartner /> : null}
          {partnerList?.results?.length > 0 && !addPartner ? <EditPartner page={page} /> : !addPartner ? <AddPartner /> : null}
        </Col>
      </Row>
    </div>
  )
}

export default Index