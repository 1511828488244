import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../../common/commonSearchBar'
import MyFamilyFirstBooking from '../../../../common/myFamilyFirstBookings'
import MyFamilyFirstMoreInfoModal from '../../../gifts/myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../../common/pagination';
import * as HoneymoonAction from '../../../../actions/honeymoonAction'
import ConfirmModal from '../../../wedding/requests/confirmModal';

const Cancelled = ({status}) => {
    
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false);
    const [ moreInfoModalData, setMoreInfoModalData ] = useState({});
    const [page, setPage] = useState(1);
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    
    const [ id, setId ] = useState(null);

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")

    const honeymoonCancelledData = useSelector(state => state.honeymoon?.honeymoonCancelled?.results);
    const totalPage = useSelector(state => state.honeymoon?.honeymoonCancelled?.total_pages);
    const START_DATE = useSelector(state => state.honeymoon.startDate);
    const END_DATE = useSelector(state => state.honeymoon.endDate);
    const SEARCH_TERM = useSelector(state => state.honeymoon.searchTerm);
    
    const PAGE_NO = useSelector(state => state.honeymoon.pageNo);

    useEffect(() => {
       
      let value = [];
      honeymoonCancelledData?.map((elem) => {
        if (elem.price) {
          value.push(elem.price)
       
        }
        else{
          value.push(0)
        }
      
      },);
         
      const valueSum=value.reduce((acc,elem)=>{
          return acc+elem
  
      },0)
      console.log(value);
      console.log(valueSum/value?.length);
      setTotalOrderValue(valueSum)
      setAverageOrderValue(Math.round(valueSum/honeymoonCancelledData?.length));
         }, [honeymoonCancelledData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);
    useEffect(() => {
        setPage(1)
      }, [status])
    const handleMoreInfoClick  = (data) => {
        setShowModal(true);
        setMoreInfoModalData(data);
    };
    

    const requestStatusChange = (event, id,data) => {
        setId(id);
        setConfirmedRequestModalData(data)
          let status = event.target.value;
    
          if(status === "confirmed")setRequestModalConfirm(true);
          else{
            dispatch(HoneymoonAction.honeymoonRequestStatusChange(status, id, refereshList))
  
          }
          
      };
  
      const confirmRequestHandler=()=>{
          
          
        setIsconfirmAmountBool(false)
       if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
        else {
        dispatch(HoneymoonAction.honeymoonRequestStatusChangeConfirm("confirmed", id,confirmedRequestAmount, refereshList))
          setRequestModalConfirm(false)
        }
        // dispatch(confirmRequestInputVal(''));
       
        
    
      }
  
  
      const notToConfirm=()=>{
     
        setRequestModalConfirm(false)
        setIsconfirmAmountBool(false);
      
      }
    const refereshList = () => {
        dispatch(HoneymoonAction.getHoneymoonRequested(1));
        dispatch(HoneymoonAction.getHoneymoonPlanning(1));
        dispatch(HoneymoonAction.getHoneymoonConfirmed(1));
        dispatch(HoneymoonAction.getHoneymoonCancelled(page));
    };

    const handlePagination = (e, value) => {
        setPage(value);
        dispatch(HoneymoonAction.setPageNo(value))
        if (SEARCH_TERM || START_DATE || END_DATE) {
            dispatch(HoneymoonAction.searchHoneymoonRequests(SEARCH_TERM, START_DATE, END_DATE, "cancelled", value));
        } else {
            dispatch(HoneymoonAction.getHoneymoonCancelled(value));
        }
    };

    const handleChange = (event) => {
        if (event.target.value) {
            dispatch(HoneymoonAction.searchHoneymoonRequests(event.target.value, START_DATE, END_DATE, "cancelled", PAGE_NO));
        } else {
            dispatch(HoneymoonAction.getHoneymoonCancelled(1));
            setPage(1);
        }
        dispatch(HoneymoonAction.setSearchTerm(event.target.value))
    };

    const honeymoonCancelledDisplayData = honeymoonCancelledData && honeymoonCancelledData.map(data => (
        <MyFamilyFirstBooking 
          locationsPreferred={data.locations_preferred}
          occasion={data.occasion}
          requestedBy={data.contact_name}
          email={data.contact_email}
          contactNum={data.contact_number}
          messageTitle={data.specific_request ? data.specific_request : 'No Message'}
          specialRequest={data.specific_request? data.specific_request:"N/A"}
          onClick={() => handleMoreInfoClick(data)}
          myFamilyFirstStatusChange={requestStatusChange}
          data={data}
          id={data.id}
          refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
          value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {honeymoonCancelledData && honeymoonCancelledData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }

<p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
          {honeymoonCancelledDisplayData}
          <MyFamilyFirstMoreInfoModal
              open={showModal}
              close={() => setShowModal(false)}
              modalData={moreInfoModalData}
              section={"Honeymoon Requested"}
              isCancellationMessage={true}
            />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
        {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
        </>
    )
}

export default Cancelled;
