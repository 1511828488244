import React from 'react';
import Modal from "@material-ui/core/Modal";
import { ImCross } from "react-icons/im";
import { BookedWithPointAndRs } from '../../../utils';

const ConfirmationPopup = ({open, content, heading, btnOneText, btnTwoText, handleClick, handleClose, data}) => {

    const RECEIVED_TAG = <span className='success-status'>Received</span>
    const PENDING_TAG = <span className='pending-status'>Pending</span>
    const isPending = data?.booking_status == "pending";
    const isBooked = data?.booking_status == "booked";

    const calcAdvanceAmount = (status) => {
        if(data?.is_advance){
            if(data?.is_advance_paid){
                if(isPending){
                    return <p>{BookedWithPointAndRs(data?.advance_points, data?.amount_paid)} {RECEIVED_TAG} </p>
                }else{
                    return <p>{BookedWithPointAndRs(data?.advance_points, data?.advance_amount)}  </p> 
                }
            }else{
                return <p>Rs {data?.advance_amount} {PENDING_TAG}</p>
            }
        }else{
            return <p>-</p>
        } 
    }   
    const calcBalanceAmount = (status) => {
        if(isPending){
                return <span>Rs. {data?.balance_amount} </span>
            // if(data?.is_advance){
            //     return <p>Rs. {data?.amount - data?.advance_amount} {PENDING_TAG}</p>
            // }else{
            //     return <p>Rs. {data?.balance_amount} {PENDING_TAG}</p>
            // }
        }else if(isBooked){
            return <span>{BookedWithPointAndRs(data?.total_points - data?.advance_points, data?.amount_paid - data?.advance_amount)} </span>
        }else{
            return <p>Rs {data?.balance_amount} </p>
        }
    }
  return (
    <div>
        <Modal
        className={`modal-wrapper ${true ? "show" : "hide"}`}
        open={open}
        // onClose={closeHandle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="card-box card-box-index" style={{ width: "450px" }}>
          <div className="add-new-section-heading">
            <h5>{heading}</h5>
            <span
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "35px",
                right: "25px",
              }}
                >
                <ImCross />
                </span>
            </div>
            <p>{data?.pack_title}</p>
            <div className="common-more-info-container" style={{padding: 0}}>
                <div className="row common-more-info-details">
                    <div class="col-md-12 common-more-info-right-contain">
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Package price</p>
                                <p>Rs {data?.amount}</p>
                                {/* <p>{isCorporate(corporate, total_points, amount_paid)}</p> */}
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Advance amount</p>
                                {calcAdvanceAmount(data?.booking_status)}
                                {/* <p>{isCorporate(corporate, total_points, amount_paid)}</p> */}
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Balance amount</p>
                                <p>{calcBalanceAmount(data?.booking_status)}</p>
                                {/* <p>{isCorporate(corporate, total_points, amount_paid)}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div className="form-group-container">
            <div className="add-new-section-btn-container">
              <button
                type="button"
                className="add-new-section-cancel-btn"
                onClick={handleClose}
              >
                {btnOneText}
              </button>
              <button 
                type="submit" 
                className={`add-new-section-done-btn ${false ? 'disable-btn' : ''}`} 
                disabled={false}
                onClick={handleClick}
                >
                {btnTwoText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmationPopup