import React, { useState, useRef, useCallback, useEffect } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';



const ProgressPercent = ({value})=>{
  return (
    <Box style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${value}%`}
        </Typography>
      </Box>
    </Box>
  )
}
export default function ImageItem({
  data,
  handleThumbnail,
  handleDeleteImage,
  result,
  handleCrop,
  moduleType,
  uploadProgress,
  isAddAnotherPackage,
  index
}) {

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isCropImage, setCropImage] = useState(false);
  const [itemUploadProgress, setItemUploadProgress] = useState(uploadProgress);


  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //  if (imageArray.length === 1) {
  //   imageArray[0].thumbnail = true;
  // }
  useEffect(()=>{
    // console.log(uploadProgress);
    setItemUploadProgress(uploadProgress);
  }, [uploadProgress]);

  // const cancelUpload = (id)=>{
  //   console.log(id)
  //   uploadProgress[id].cancelTokenSource.cancel();
  //   handleDeleteImage(data.id);
  //   // cancelTokenSource.cancel();
  // };
  return (
    <div
      style={{
        // display: "inline-block",
        marginRight: 10,
        marginBottom: 10,
        height: 200,
        // width: "32%",
        position: "relative",
      }}
    >

      {(data.media_type==='image' || !data.media_type)&&<div
        onClick={() => handleThumbnail(data.id)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: data.thumbnail ? "#18a0fb" : "rgba(0,0,0,0.6)",
          padding: "4px 8px",
          borderRadius: 4,
          border: "1px solid white",
          cursor: "pointer",
        }}
      >
        <h7 style={{ color: "#fff", fontSize: 11, fontWeight: "bold" }}>
          Set as thumbnail
        </h7>
      </div>}

      {(data.media_type==='image' || !data.media_type)&&<img
        alt="img"
        src={data.image}
        style={{ height: "100%", width: "100%", borderRadius: 10 }}
      />}
      {data.media_type==='video'&&
      <div>
        <video width="285" height="200" controls>
        <source src={data.video} type="video/mp4" / >
        {/* <source src="movie.ogg" type="video/ogg" / > */}
        Your browser does not support the video tag.
        </video>
      </div>}
      {data.media_type==='youtube'&&
      <div>
      <iframe width="285" 
              height="200" 
              src={`https://www.youtube.com/embed/${new URL(data.video).searchParams.get("v")}`}
              title="YouTube video player" 
              frameborder="0" 
              
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
              
              </iframe>
              
       </div>       }
      
      {data.media_type==='other' && new URL(data.video).hostname==='vimeo.com' &&
  <div>
      <iframe src={`https://player.vimeo.com/video${new URL(data.video).pathname}`} 
              width="280" 
              height="200" 
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen>
      </iframe></div>}   
      {data.media_type==='uploading' &&
      <div>
          <div className="row justify-content-center items-center mt-4" style={{height:'200'}}>
        
          <ProgressPercent value={itemUploadProgress[data.id] && (parseInt(Math.round(uploadProgress[data.id].uploadProgress.loaded * 100) / uploadProgress[data.id].uploadProgress.total))} />
          
        </div>
        <div style={{textAlign:'center'}}>{uploadProgress[data.id]&&`${(Math.round(uploadProgress[data.id].uploadProgress.loaded)/1024/1024).toFixed(2)} MB / ${(Math.round(uploadProgress[data.id].uploadProgress.total)/1024/1024).toFixed(2)} MB`}</div>
        <div style={{textAlign:'center'}}> Uploading...</div>
        <div style={{textAlign:'center', cursor:'pointer'}} onClick={()=>{uploadProgress[data.id]&&handleDeleteImage(data.id)}} className="text-primary"> Cancel</div>
      </div>}           

      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: "flex",
          color: "white",
        }}
      >
        <div
          className={isAddAnotherPackage?.includes(data.id)?'d-none':''}
          onClick={() => handleDeleteImage(data.id)}
          style={{
            padding: "4px 8px",
            borderRadius: 4,
            border: "1px solid white",
            cursor: "pointer",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </div>
      </div>
    </div>
  );
}
