
import {useState, useEffect} from 'react';
import { Row, Col } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import StoryListing from './storyListing';
import NewStory from './newStory';
import EditStory from './editStory';
import { useDispatch, useSelector } from 'react-redux';
import * as myFamilyFirstAction from "../../../actions/myFamilyFirstAction";
import { Spinner } from 'react-bootstrap';

const MyFamilyStories = ()=>{
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [isAdd, setIsAdd] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const storyList = useSelector((state) => state.myFamilyFirst.myFamilyFirstStoryList);
    const storyDetails = useSelector((state) => state.myFamilyFirst.myFamilyFirstStoryDetails);
    const [searchVal,setSearchVal]=useState('')
    const isDataLoading = useSelector(
        (state) => state.holidayListing.isDataLoading
      );
    // const [storyList, setStoryList] = useState([
    //     {title:'My Experience of giftin a travel package to my mom'},
    //     {title:'Be together: a celebration of family travel memories'},
    //     {title:'Be together: acelebration of family travel memories'},
    //     {title:'Be together: a celebration of family trael memories'},
    // ]);
    const handleEdit = ()=>{
        console.log('handle edit')
    };
    const handleAdd = ()=>{
        console.log('handle add')
    };
    useEffect(()=>{
        console.log(myFamilyFirstAction.myFamilyFirstStoryList);
        dispatch(myFamilyFirstAction.myFamilyFirstStoryList());
        
    }, []);
    useEffect(()=>{
        selectedId&&dispatch(myFamilyFirstAction.myFamilyFirstStoryDetails(selectedId));
        console.log(storyDetails);
    }, [selectedId]);
    useEffect(()=>{
        storyList.length && setSelectedId(storyList[0].id);//selectedId || 
        if(storyList.length){
            setSelectedId(storyList[0].id);
            setIsAdd(false)
        } else {
            setSelectedId(null);
            setIsAdd(true)
        }
    }, [storyList]);
   console.log(storyList);
    return (
        <div>
            <p className="title-holder">My family Stories</p>
            <div>
            <Row>
                <Col style={{ paddingRight: '20px', marginRight: '-20px' }} className="gutter-row" xs={24} lg={8}>
                    <Row className="gutter-row">
                        
                    <StoryListing
                        storyList={storyList}
                        handleEdit={handleEdit}
                        handleAdd={handleAdd}
                        currentPage={page}
                        setIsAdd={setIsAdd}
                        setSelectedId={setSelectedId}
                        selectedId = {selectedId}
                        searchVal={searchVal}
                        setSearchVal={setSearchVal}
                    />
                    </Row>
                    <Row style={{paddingTop:"54%"}}>
                    
                    {/* <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                        page={1}
                        onChange={(event, value) => setPage(value)}
                    /> */}
                    </Row>
                </Col>
                <Col className="gutter-row" xs={24} lg={16}>
                {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
                {isAdd?<NewStory setIsAdd={setIsAdd} />:<EditStory storyDetails={storyDetails} setSearchVal={setSearchVal}  storyDetailsId={selectedId} />}
                    {/* {addEventListDetails ? (
                        <div>
                            <AddNewEvent />
                        </div>
                    ) : (
                        <div>
                            {eventListFromStore?.results && eventListFromStore?.results.length>0?(
                                <EventsEditPanel
                                // onResetpage={handleResetPage}
                                    data={
                                        Object.keys(eventDetails).length === 0
                                        ? eventListFromStore?.results[0]
                                        : eventDetails
                                    }
                                />
                            ): <AddNewEvent />}
                        </div>
                    )} */}
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default MyFamilyStories;