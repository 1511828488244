import {
    CORPORATES_PENDING_REVIEW_LIST,
    CORPORATES_REJECTED_REVIEW_LIST,
    CORPORATES_REVIEW_END_DATE,
    CORPORATES_REVIEW_PAGE_ID,
    CORPORATES_REVIEW_PUBLISHED_LIST,
    CORPORATES_REVIEW_START_DATE,
  } from "../actions/types";
  
  const initialState = {
    corporateReviewList: [],
    corporatePublishedReviewList: [],
    corporateRejectedReviewList:[],
    corporateReviewStartDate: null,
    corporateReviewEndDate: null,
    corporateReviewStatusVal: "Review",
    corporateReviewSecName: {},
    page: 1,
    getcorporateReqAllData: {},
    corporateReqSearchValue: "",
  };
  const corporateReviewReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case CORPORATES_PENDING_REVIEW_LIST:
        return {
          ...state,
          corporateReviewList: payload,
        };
      case CORPORATES_REVIEW_PAGE_ID:
        return {
          ...state,
          page: payload,
        };
      case CORPORATES_REVIEW_PUBLISHED_LIST:
        return {
          ...state,
          corporatePublishedReviewList: payload,
        };
        case CORPORATES_REVIEW_START_DATE:
            return{
                ...state,
                corporateReviewStartDate:payload
            }
        case CORPORATES_REVIEW_END_DATE:
            return{
                ...state,
                corporateReviewEndDate:payload
            }
        case CORPORATES_REJECTED_REVIEW_LIST:
          return{
            ...state,
            corporateRejectedReviewList:payload
          }    
      default:
        return state;
    }
  };
  export default corporateReviewReducer;
  