import { PARTNERS_LIST, SET_PARTNER_DETAILS, UPDATE_PARTNER_DETAILS, REGENERATE_TOKEN, PARTNER_SEARCH_TERM, PARTNER_LOGO} from "../actions/types";

const initialState = {
    partnersList: [],
    partnerDetails: {},
    updatePartnerDetails: {},
    regeneratedToken: null,
    searchTerm: null,
    partnerLogo: {}
    
};



const partnersListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PARTNERS_LIST: {
            return {
                ...state,
                partnersList: payload,
            }
        }
        case SET_PARTNER_DETAILS: {
            return {
                ...state,
                partnerDetails: payload,
            }
        }
        case UPDATE_PARTNER_DETAILS: {
            return {
                ...state,
                updatePartnerDetails: payload,
            }
        }
        case REGENERATE_TOKEN: {
            return {
                ...state,
                regeneratedToken: payload,
            }
        }
        case PARTNER_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
            }
        }
        case PARTNER_LOGO: {
            return {
                ...state,
                partnerLogo: payload,
            }
        }
        default:
            return state;
    }
};

export default partnersListReducer;
