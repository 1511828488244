
import { API } from "../constants/api";
import {
  axiosPostWithToken,
  
} from "../services/apiServices";
import { 
  PENDING_PAYMENT_BOOKINGS,
  BOOKED_PAYMENT_BOOKINGS,
  IS_DATA_LOADING,
} 
from '../actions/types';

export const generateEmail = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    
    axiosPostWithToken(`${API.CREATE_INTERNAL_PACKAGE}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
            handleSuccess()
        }
      })
  };
};

export const generateEmailForBalanceAmt = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    
    axiosPostWithToken(`${API.GENERATE_LINK}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          handleSuccess(res?.data?.data?.url, "Email Sent Successfully")
        }
      })
  };
};
export const generateEmailForAdvanceAmt = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    
    axiosPostWithToken(`${API.GENERATE_ADVANCE_LINK}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          handleSuccess(res?.data?.data?.url, "Email Sent Successfully")
        }
      })
  };
};

export const generateLink = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    
    axiosPostWithToken(`${API.CREATE_INTERNAL_PACKAGE}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
            handleSuccess(res.data.data.url)
        }
      })
  };
};


export const getPendingPayments = (page, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{"field":"booking_status", "value": "pending"}], 
      "page": page, 
      "list_per_page": 10,
      "sort":{"field":"modified", "order":"D"},
 }
 dispatch({
  type: IS_DATA_LOADING,
  payload: true,
});
    axiosPostWithToken(`${API.PAYMENT_BOOKINGS_API}`, body, token)
      .then((res) => {
        
        if (res.data.status_code === 200) {
            dispatch({
              type: PENDING_PAYMENT_BOOKINGS,
              payload: res.data.data
            })
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
        }
      })
  };
};
export const getBookedPayments = (page, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{"field":"booking_status", "value": "booked"}], 
      "page": page, 
      "list_per_page": 10,
      "sort":{"field":"modified", "order":"D"},
 }
 dispatch({
  type: IS_DATA_LOADING,
  payload: true,
});
    axiosPostWithToken(`${API.PAYMENT_BOOKINGS_API}`, body, token)
      .then((res) => {
        
        if (res.data.status_code === 200) {
            dispatch({
              type: BOOKED_PAYMENT_BOOKINGS,
              payload: res.data.data
            })
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
        }
      })
  };
};

export const bookingStatusChange = (pack_slug, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
        "pack_slug": pack_slug,
        "request_type": "edit_package_status" 
    }
    axiosPostWithToken(`${API.OFFLINE_COLLECT_EDIT}`, body, token)
      .then((res) => {
        
        if (res.data.data.message === "Updated successfully") {
          handleSuccess("Updated successfully")
        }
      })
  };
};
export const packAmountUpdate = (pack_slug, price, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
        "pack_slug": pack_slug,
        "revised_balance_price": price,
        "request_type": "edit_package_price" 
    }
    axiosPostWithToken(`${API.OFFLINE_COLLECT_EDIT}`, body, token)
      .then((res) => {
        
        if (res.data.data.message === "Updated successfully") {
          handleSuccess(null, "Updated successfully")
        }
      })
  };
};

