import React, { useState } from 'react'
import { Calendar } from "react-multi-date-picker";
import '../../assets/css/partials/availabilityFlexiDatePopup.scss';
import { useDispatch } from 'react-redux';
import { flexiDateItem } from '../../actions/experienceBookingAction';
import moment from 'moment';

const AvalibalityFlexiDatePopup = ({ doneHandler, flexiAvalabilityDate }) => {
    const [values, setValues] = useState(flexiAvalabilityDate?.schedule);
      const dispatch = useDispatch();
    const [bool, setBool] = useState(false)
    const dateChangeHandler = (date) => {
        let tempDateData = date.map(d => d.format())
        dispatch(flexiDateItem(tempDateData))
        setValues(tempDateData)
    };
    return (
        <div className="calander-container">
         <Calendar
            multiple
            minDate={new Date()}
            format={'DD-MM-YYYY'}
            value={values} 
            onChange={dateChangeHandler}
         />
         <div style={{textAlign:"end", marginBottom:"25px"}}>
            <button onClick={() => doneHandler(values)}>Done</button>
         </div>
        </div>
    );
};

export default AvalibalityFlexiDatePopup;
