import { Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporateReviewPendingList,
  getCorporateReviewPublishedList,
  getCorporateReviewRejectedList,
  getCorporateReviewsearch,
  myCorporateReviewEndDate,
  myCorporateReviewStartDate,
} from "../../../actions/corporateReviewAction";
import { getHolidayReviewRejectedList } from "../../../actions/holidayReviewAction";
import Datefilter from "../../../common/commonDateFIlter";
import DropdownFilter from "../../holidays/customerReviews/dropDownFilter";
import Pending from "./pending";
import Published from "./published";
import Rejected from "./rejected";
const { TabPane } = Tabs;
const CorporatesReviewsIndex = () => {
  const [status, setStatus] = useState("pending");

  const dispatch = useDispatch();
  const CorporatePendingReviews = useSelector(
    (state) => state.corporateReviews?.corporateReviewList
  );
  const CorporateReviewsPublished = useSelector(
    (state) => state.corporateReviews?.corporatePublishedReviewList
  );
  const CorporateReviewsRejected = useSelector(
    (state) => state.corporateReviews?.corporateRejectedReviewList
  );
  const ffEndDate = useSelector(
    (state) => state.corporateReviews?.corporateReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.corporateReviews.corporateReviewStartDate
  );
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  console.log(ffEndDate, ffStartDate);
  const handleStartDateChange = (date) => {
    dispatch(myCorporateReviewStartDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleEndDateChange = (date) => {
    dispatch(myCorporateReviewEndDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myCorporateReviewStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myCorporateReviewEndDate(null));
  };
  const totalCorporatePendingReviews =
    CorporatePendingReviews &&
    CorporatePendingReviews?.review_data?.total_results;
  const totalCorporatePublishedReviews =
    CorporateReviewsPublished &&
    CorporateReviewsPublished?.review_data?.total_results;
  const totalCorporateRejectedReviews =
    CorporateReviewsRejected &&
    CorporateReviewsRejected?.review_data?.total_results;
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document
        .querySelector(".enquiry-card-holder")?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  useEffect(() => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(getCorporateReviewsearch(1, status, ffStartDate, ffEndDate));
    } else if (ffEndDate === null && ffStartDate === null) {
      dispatch(getCorporateReviewPublishedList(1));
      dispatch(getCorporateReviewRejectedList(1))
    }
  }, [ffEndDate, ffEndDate, status]);
  const handleTabChange = (key) => {
    if (key === "2") {
      setStatus("approved");
    }
    else if (key === "3") {
      setStatus("rejected");
    } else {
      setStatus("pending");
    }
  };
  return (
    <div>
      <>
        <div style={{ position: "absolute", right: 50 }}>
          <Datefilter
            status={status}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            ffStartDate={ffStartDate}
            ffEndDate={ffEndDate}
            handleStartClear={handleStartClear}
            handleEndClear={handleEndClear}
          />
        </div>
        <div className="enquiry-tabs-holder">
          <div style={{ display: "flex" }}>
            <p className="title-holder">Reviews</p>

          </div>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab={`${totalCorporatePendingReviews} Pending`} key="1">
              <Pending />
            </TabPane>
            <TabPane
              tab={`${totalCorporatePublishedReviews} Published`}
              key="2"
            >
              <Published />
            </TabPane>
            <TabPane tab={`${totalCorporateRejectedReviews} Rejected`} key="3">
              <Rejected />
            </TabPane>
          </Tabs>
          {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
        </div>
      </>
    </div>
  );
};

export default CorporatesReviewsIndex;
