import React, { useEffect, useState } from 'react'
import { availabilityStatusValue } from '../../../resources/status';
import { useDispatch, useSelector } from 'react-redux';
import { checkNumberOfGuest } from '../../../utils/index';
import { guestModalOpentrue, guestData } from '../../../actions/experienceBookingAction';
import {
    getHolidayBookingList,
    holidayBookingSearchValue,
    holidayBookingsSearch,
    holidayBookingStatusUpdate,
    pageId
} from '../../../actions/holidayBookingAction';
import AvailabilityCard from '../../../common/availabilityCard';
import SearchBar from '../../../common/commonSearchBar';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import AvailabilityRequestConfirmPopUp from '../../../common/availabilityRequestConfirmPopUp';
import GuestModal from '../../../common/checkAvailability/guestModal';
import ClosedModal from '../requests/closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Avalibility = () => {
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [requestConfirmPopUp, setRequestConfirmPopUp] = useState(false);
    const [requestModal, setRequestModal] = useState(false);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const[iscopr,setIscorp]=useState()
   
  
   
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [amountLengthError, setAmountLengthError] = useState(false);
    const [guestError, setGuestError] = useState(false);
    const [adultGuestError, setAdultGuestError] = useState(false);
    const [scheduleError, setScheduleError] = useState(false);
    const [moreInfoData, setMoreInfoData] = useState({});
    const [availabilityConfirmationPopupdata, setAvailabilityConfirmationPopupdata] = useState({});
    const [status, setStatus] = useState('');

    const [adultCount, setAdultCount] = useState(0);
    const [childrenCount, setChildrenCount] = useState(0);
    const [infantCount, setInfantCount] = useState(0);
    const [amountValue, setAmountValue] = useState("");
    const [id, setId] = useState(null);
    const [requestType, setRequestType] = useState(null);
    
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
   
    const guestOpen = useSelector((state) => state.experienceBooking.guestModalOpenTrue);
    const adultsGuest = useSelector((state) => state.experienceBooking.adultsGuest);
    const childrenGuest = useSelector((state) => state.experienceBooking.childrenGuest);
    const infantGuest = useSelector((state) => state.experienceBooking.infantGuest);
    const availabilityReqList = useSelector(state => state.holidayBooking.availabilityReqList);
    console.log(availabilityReqList);
    const searchVal = useSelector(state => state.holidayBooking.holidayAvailabilitySearchVal);
    const ffStartDate = useSelector((state) => state.holidayBooking.holidayBookingStartDate);
    const ffEndDate = useSelector((state) => state.holidayBooking.holidayookingEndDate);
    const sectionID = useSelector(state => state.holidayBooking.bookingsSecId);
    const pageNum = useSelector(state => state.holidayBooking.page);
    const dispatch = useDispatch();
    //    console.log("availabilityReqList", availabilityReqList)
    useEffect(() => {
        dispatch(getHolidayBookingList(1, null, "requested"));
    }, []);

    useEffect(() => {
        setAdultCount(availabilityConfirmationPopupdata.adults);
        setChildrenCount(availabilityConfirmationPopupdata.children)
        setInfantCount(availabilityConfirmationPopupdata.infants)
    }, [availabilityConfirmationPopupdata]);

    useEffect(() => {
        setTimeout(() => {
          setGuestError(false);
          setAdultGuestError(false);
          setScheduleError(false);
          setAmountError(false);
          setAmountLengthError(false);
        }, 2000);
      }, [scheduleError, guestError, amountError, amountLengthError]);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => document.body.style.overflow = 'unset';
    }, [showMoreInfo]);


    useEffect(() => {
        if (searchVal === "" && searchVal === undefined && ffStartDate === null &&
            ffEndDate === null && availabilityReqList?.next_page_id === null && availabilityReqList?.current_page > 1 && availabilityReqList?.length === 0) {
            dispatch(getHolidayBookingList(1, sectionID, "requested"));
            dispatch(pageId(1));
        } else if (sectionID !== undefined && availabilityReqList?.current_page > 1 &&
            availabilityReqList?.next_page_id === null &&
            availabilityReqList?.results.length === 0) {
            dispatch(holidayBookingsSearch(1, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(pageId(1));
        } else if (searchVal !== "" && searchVal === undefined && availabilityReqList?.current_page > 1 &&
            availabilityReqList?.next_page_id === null &&
            availabilityReqList?.results.length === 0) {
            dispatch(pageId(1));
            dispatch(holidayBookingsSearch(1, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
        } else if (ffStartDate !== null && ffEndDate !== null && availabilityReqList?.current_page > 1 &&
            availabilityReqList?.next_page_id === null &&
            availabilityReqList?.results.length === 0) {
            dispatch(pageId(1));
            dispatch(holidayBookingsSearch(1, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
        } else if (sectionID !== undefined && searchVal !== "" && searchVal !== undefined &&
            ffStartDate !== null && ffEndDate !== null &&
            availabilityReqList?.current_page > 1 &&
            availabilityReqList?.next_page_id === null &&
            availabilityReqList?.results.length === 0) {
            dispatch(pageId(1));
            dispatch(holidayBookingsSearch(1, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
        }

    }, [availabilityReqList]);

    const moreInfoClick = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    }

    const handleChange = (event) => {
        dispatch(holidayBookingSearchValue(event.target.value, "requested"));
        dispatch(holidayBookingsSearch(1, 'requested', event.target.value, sectionID, ffStartDate, ffEndDate));
        dispatch(pageId(1));
    };

    const handlePagination = (e, value) => {
        if (searchVal !== "" || ffStartDate !== null && ffEndDate !== null) {
            dispatch(holidayBookingsSearch(value, 'requested', searchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        } else {
            dispatch(getHolidayBookingList(value, sectionID, "requested"));
            dispatch(pageId(value));
        }
    };

    const refreshHolidayBooking = () => {
        setAmountValue("")
        if (searchVal !== '') {
            dispatch(holidayBookingsSearch(pageNum, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"));
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"));
            dispatch(getHolidayBookingList(pageNum, sectionID, "follow_up"));
        } else if (ffStartDate !== null && ffEndDate !== null) {
            dispatch(holidayBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'requested', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'follow_up', searchVal, sectionID, ffStartDate, ffEndDate));

        } else {
            dispatch(getHolidayBookingList(pageNum, sectionID, "requested"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "follow_up"))
        }
    };

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        setCloseRequestModalData(data)
      
        setAvailabilityConfirmationPopupdata(data);
        
        dispatch(guestModalOpentrue(false))
        let status = event.target.value
        setIscorp(corporate)
        setStatus(status);
        setId(id);
        setRequestType(requestsType)
        if (status === "confirmed") {
            setRequestConfirmPopUp(true)
        } 
        else if(status === "cancelled")setRequestModal(true);
        else {
            dispatch(holidayBookingStatusUpdate(status, id, corporate, sectionID,null, refreshHolidayBooking));;
        }
    };

    const confirmedHandler = (data) => {
        setGuestError(false);
        setScheduleError(false);
        setAdultGuestError(false);
        setAmountError(false);
        setAmountLengthError(false);
        const guestData = {
            adults: guestOpen === true ? adultsGuest : adultCount,
            children: guestOpen === true ? childrenGuest : childrenCount,
            infants: guestOpen === true ? infantGuest : infantCount
        }
        if (data.pack_schedule_type === "" && data.schedule.length === 0) setScheduleError(true);
        else if(adultsGuest === 0 && adultCount === 0 && childrenCount === 0 && childrenGuest === 0 && infantCount === 0 && infantGuest === 0) setGuestError(true)
        else if (adultsGuest === 0 && (childrenGuest > 0 || infantGuest > 0)) setAdultGuestError(true);
        else if (amountValue === "") setAmountError(true);
        else if (amountValue.length > 6) setAmountLengthError(true);
        else {
            dispatch(holidayBookingStatusUpdate(status, id, data.corporate, sectionID, null,refreshHolidayBooking, data, guestData, amountValue))
            setRequestConfirmPopUp(false);
        }
    };

    const updateAvailabilityConfirmationData = (data) => {
        setAvailabilityConfirmationPopupdata(data)
    };

    const amountHandleChange = (e) => {
        setAmountValue(e.target.value)
    };

    const handleClose = () => {
        setRequestConfirmPopUp(false)
        dispatch(guestData())
        dispatch(guestModalOpentrue(false));
        setAmountValue("")

    };

    // guest functionality
    const adultGuestIncrementHandler = () => {
        setAdultCount(adultCount + 1)
    }

    const adultGuestIDecrementHandler = () => {
        if (adultCount <= 0) {
            setAdultCount(0);
        } else {
            setAdultCount(adultCount - 1)
        }
    };

    const childrensGuestIncrementHandler = () => {
        setChildrenCount(childrenCount + 1);
    }

    const childrensGuestIDecrementHandler = () => {
        if (childrenCount <= 0) {
            setChildrenCount(0);
        } else {
            setChildrenCount(childrenCount - 1)
        }
    };

    const infantGuestIncrement = () => {
        setInfantCount(infantCount + 1)
    };

    const infantGuestDecrement = () => {
        if (infantCount <= 0) {
            setInfantCount(0);
        } else {
            setInfantCount(infantCount - 1)
        }
    };
    const guestClickHandler = () => {
        setOpenGuestModal(true)
        dispatch(guestModalOpentrue(true));
    }
    const doneClickHandler = () => {
        dispatch(guestData(adultCount, childrenCount, infantCount))
        setOpenGuestModal(false);
    };
    //end///

    const availabilityDisplayList = availabilityReqList && availabilityReqList?.results?.map(data => (
        <AvailabilityCard
            packTitle={data.pack_title}
            location={data?.sec_name  && data?.sec_name == "International" ? data.location_country : data.address}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                    return scheduleDate
                } else {
                    return `${scheduleDate}, `
                }
            }) :
                (typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to !== "" ? `${data.schedule.from} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to === "" ? `${data.schedule.from}` : (data.schedule === null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request ? data.special_request : "N/A"}
            messageTitle={data.special_request}
            statusValues={availabilityStatusValue}
            statusChangeHandler={statusChangeHandler}
            moreInfoClick={() => moreInfoClick(data)}
            value={data.booking_status}
            corporate={data.corporate}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            amount={data.amount === null ?"No amount" : `Amount to be paid ${data.amount}`}
            // messageTitle={data.special_request}
            data={data}
            id={data.id}

        />
    ));
    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
            dispatch(holidayBookingStatusUpdate(status, id, iscopr, sectionID, closeRequestMessage,refreshHolidayBooking))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };

    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    return (
        <>
            <SearchBar
                searchVal={searchVal}
                handleChange={handleChange}
            />
            {availabilityReqList && availabilityReqList?.results?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
            {availabilityDisplayList}
            {showMoreInfo &&
                <CommonMoreInfoModel
                    open={showMoreInfo}
                    modalClose={() => setShowMoreInfo(false)}
                    moreInfoData={moreInfoData}
                    amount={` ${moreInfoData.amount === null ? "No amount" :`Rs ${moreInfoData.amount}`}`}
                    isAvailabilityReq={true}
                    isCancellationMessage={false}
                />
            }

            {requestConfirmPopUp &&
                <AvailabilityRequestConfirmPopUp
                    open={requestConfirmPopUp}
                    handleClose={handleClose}
                    data={availabilityConfirmationPopupdata}
                    guestClickHandler={guestClickHandler}
                    changeData={updateAvailabilityConfirmationData}
                    availabilityData={availabilityConfirmationPopupdata}
                    confirmClick={confirmedHandler}
                      scheduleError={scheduleError}
                      guestError={guestError}
                      adultGuestError={adultGuestError}
                    amountError={amountError}
                    amountLengthError={amountLengthError}
                    amountValue={amountValue}
                    amountHandleChange={amountHandleChange}

                >
                    {openGuestModal &&
                        <GuestModal
                            adultGuestIncrementHandler={adultGuestIncrementHandler}
                            adultGuestIDecrementHandler={adultGuestIDecrementHandler}
                            childrensGuestIncrementHandler={childrensGuestIncrementHandler}
                            childrensGuestIDecrementHandler={childrensGuestIDecrementHandler}
                            infantGuestIncrement={infantGuestIncrement}
                            infantGuestDecrement={infantGuestDecrement}
                            doneClickHandler={doneClickHandler}
                            adultCount={adultCount}
                            childrenCount={childrenCount}
                            infantCount={infantCount}
                        />
                    }
                </AvailabilityRequestConfirmPopUp>
            }
            <Pagination
                totalPage={availabilityReqList.total_pages}
                page={pageNum}
                onChange={(event, value) => handlePagination(event, value)}
            />
            {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                 
                />
              }
        </>
    )
}

export default Avalibility;
