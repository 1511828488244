import { GET_CORP_SECTION_IMAGES_DATA, IMAGE_UPDATE_SECTION , CROPPED_IMAGE_FILE, SELCTED_IMAGE_URL,
  CONFIRM_UPDATE_BOOL, CONFIRM_ADD_BOOL, SECTION_PACKAGES
} from '../actions/types'
const initialState = {
  corpSectionImageData:[],
  imageUpdatedSection: {},
  croppedImageFile:null,
  selectedImageUrl: null,
  confirmUpdateBoolVal: false,
  confirmAddBoolVal: false,
  sectionPackages:null
}

const sectionReducer = (state = initialState ,{type, payload}) => {
    switch (type) {  
        case GET_CORP_SECTION_IMAGES_DATA: {
            return {
              ...state,
              corpSectionImageData: payload
            }
          }
        case IMAGE_UPDATE_SECTION: {
            return {
              ...state,
              imageUpdatedSection: payload
            }
          }
        case CROPPED_IMAGE_FILE: {
            return {
              ...state,
              croppedImageFile: payload
            }
          }
        case SELCTED_IMAGE_URL: {
            return {
              ...state,
              selectedImageUrl: payload
            }
          }
        case CONFIRM_UPDATE_BOOL: {
            return {
              ...state,
              confirmUpdateBoolVal: payload
            }
          }
        case CONFIRM_ADD_BOOL: {
            return {
              ...state,
              confirmAddBoolVal: payload
            }
          }
        case SECTION_PACKAGES: {
            return {
              ...state,
              sectionPackages: payload
            }
          }
        default:
            return state;
    }
}

export default sectionReducer;