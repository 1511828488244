import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { connect, useDispatch } from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";
import "../../../assets/css/partials/enquiry.scss";
import Datefilter from "./DateFilter";
import DropdownFilter from "./DropdownFilter";
import Booking from "./Booking";
import Confirmed from "./Confirmed";
import Cancelled from "./Cancelled";
import Pagination from '@material-ui/lab/Pagination';
import { useSelector } from 'react-redux';
import Availability from "./availability";
import Refunded from "./refunded.js"
import { availabilityPage, getAvailabilityRequestList } from '../../../actions/bookingAction';
import { Spinner } from "react-bootstrap";
import FollowUp from "../bookings/followUp";
const { TabPane } = Tabs;


function mapStateToProps(state) {
  return {
    bookingDetails: state.bookingListing.bookings.data,
    confirmedBookings: state.bookingListing.confirmedBookings.data,
    cancelledBookings: state.bookingListing.cancelledBookings.data,
  };
}

function Bookings({ bookingDetails, confirmedBookings, cancelledBookings }) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("requested");
  const [page, setPage] = useState(1);
  const refundlength = useSelector(state => state.bookingListing.refundBookings.total_results);
  const searchValue = useSelector((state) => state.bookingListing.searchValue);
  const cancelledSearchValue = useSelector((state) => state.bookingListing.corpCancelledSearchValue);
  const confirmedSearchValue = useSelector((state) => state.bookingListing.corpConfirmedSearchValue);
  const availabilityRequest = useSelector((state) => state?.bookingListing?.availabilityRequest);
  const totalfollowup =useSelector((state) => state?.bookingListing?.followupList?.total_results);
 
 


  
  const availabilitySearchValue = useSelector((state) => state.bookingListing.corpAvailabilitySearchValue);
  const followSearchValue= useSelector((state) => state.bookingListing.corpFollowupSearchValue);

  
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  const bookedSearchTrueValue = useSelector((state) => state.bookingListing.bookedSearchTrueValue);
  const confirmedSearchTrueValue = useSelector((state) => state.bookingListing.confirmedSearchTrueValue);
  const cancelledSearchTrueValue = useSelector((state) => state.bookingListing.cancelledSearchTrueValue);

  const getPageNum = useSelector((state) => state?.bookingListing?.page_num);
  const secId = useSelector((state) => state.bookingListing.getSecId);
  const getStatus = useSelector((state) => state.bookingListing.getStatus);
 
  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);
  function getSelectedTab(key) {
    setSelectedTab(key)
    setPage(1);
    dispatch(BookingAction.getStatus(key));
      dispatch(availabilityPage(1))
    // dispatch(BookingAction.searchBooking(searchValue, getStatus, secId, page));
  }
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  const allSearchvalue = () => {
    if(getStatus === "booked") return searchValue
    else if(getStatus === "confirmed") return confirmedSearchValue
    else if(getStatus === "cancelled") return cancelledSearchValue
    else if(getStatus === "requested") return availabilitySearchValue
    else if(getStatus === "follow_up") return followSearchValue
  };

  useEffect(() => {
    if(bookedSearchTrueValue === true && searchValue == "" || 
      confirmedSearchTrueValue === true && confirmedSearchValue === "" || 
      cancelledSearchTrueValue === true && cancelledSearchValue === ""|| followSearchValue==="")
    {
      dispatch(BookingAction.setPageNum(1));
    }
  }, [
    bookedSearchTrueValue, 
    searchValue, confirmedSearchTrueValue, 
    confirmedSearchValue, 
    cancelledSearchTrueValue,
    cancelledSearchValue]
  );
  
  useEffect(() => {
    if(searchValue !== "" || cancelledSearchValue !== "" || confirmedSearchValue !== "" || availabilitySearchValue !== "" || followSearchValue !== ""){
      dispatch(BookingAction.searchBooking(allSearchvalue(), getStatus, secId, page));
      dispatch(BookingAction.setPageNum(page));
    }else if(startDate !== null && endDate !== null){
      dispatch(BookingAction.searchBooking(allSearchvalue(), getStatus,secId, page));
      dispatch(BookingAction.setPageNum(page));
    }else {
      dispatch(BookingAction.getBookingList(page, secId));
      dispatch(BookingAction.getBookingConfirmedlist(page, secId));
      dispatch(BookingAction.getCancelledBookinglist(page, secId));
      dispatch(BookingAction.getFollowupList(page, secId));
      dispatch(BookingAction.getRefundedlist(page, secId));
      dispatch(getAvailabilityRequestList(1, secId))
      dispatch(BookingAction.setPageNum(page));
    }
  }, [page]);

  const totalBookings = bookingDetails?.total_results;
  const totalConfirmedBookings = confirmedBookings?.total_results;
  const totalCancelledBookings = cancelledBookings?.total_results;
  const totalAvailabilityRequest = availabilityRequest.total_results;
  
  const overallEnquiries = totalBookings + totalConfirmedBookings + totalCancelledBookings + totalAvailabilityRequest +totalfollowup;

  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter />
      </div>
      <div className="enquiry-tabs-holder">
       <div style={{display:'flex'}}>
          <p className="title-holder">{overallEnquiries} Corporate Bookings</p>
          <div>
          <DropdownFilter status={selectedTab}/>
         </div>
       </div>
        <Tabs defaultActiveKey="requested" onChange={getSelectedTab}>
        <TabPane  tab={totalAvailabilityRequest + " " + "Requests"} key="requested">
            <Availability />
          </TabPane>

          <TabPane  tab={totalfollowup + " " + "Follow-Up"} key="follow_up">
            <FollowUp />
          </TabPane>
          <TabPane tab={totalBookings + " " + "Booked"} key="booked">
            <Booking bookingData={bookingDetails} />
            <Pagination
            count={bookingDetails?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
          <TabPane tab={totalConfirmedBookings + " " + "Confirmed"} key="confirmed">
            <Confirmed confirmedBookings={confirmedBookings} />
            <Pagination
            count={confirmedBookings?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
          <TabPane tab={totalCancelledBookings + " " + "Cancelled"} key="cancelled">
            <Cancelled cancelledBookings={cancelledBookings} />
            <Pagination
            count={cancelledBookings?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
          <TabPane tab = {`${refundlength} Refunded`} key="refunded">
          <Refunded/> 
        
        </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Bookings);
