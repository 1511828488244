import { API } from "../constants/api";
import {
    axiosGetWithToken,
    axiosPostWithToken,
    axiosPut,
} from "../services/apiServices";
import {
    GIFT_VOUCHER_PURCHASED,
    UPDATE_VOUCHER_STATUS,
    PLANNING_LIST,
    CONFIRMED_LIST,
    CANCELLED_LIST,


} from "./types";

export const SEARCH_VOUCHER_PURCHASED = "SEARCH_VOUCHER_PURCHASED";
export const SEARCH_VOUCHER_PLANNING = "SEARCH_VOUCHER_PLANNING";
export const SEARCH_VOUCHER_CONFIRMED = "SEARCH_VOUCHER_CONFIRMED";
export const ENQUIRY_VOUCHER_CANCELLED = "ENQUIRY_VOUCHER_CANCELLED";
export const VOUCHER_FILTER_END_DATE = "VOUCHER_FILTER_END_DATE";
export const VOUCHER_FILTER_START_DATE = "VOUCHER_FILTER_START_DATE";

export const getGiftVouchersList = (page) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = {
            next_page_id: page,
        };
        axiosPostWithToken(`${API.SEND_GIFT_PURCHASED}`, body, token)
            .then((res) => {
                if (res.data.status_code === 200) {
                    // console.log(res.data, ' voucher list')
                    dispatch({
                        type: GIFT_VOUCHER_PURCHASED,
                        payload: res.data,
                    });
                }


            })
            .catch((err) => {
                dispatch({
                    //   type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};


export const updateVoucherStatus = (id, selectedStatus, refreshEnquiries) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = {
            booking_status: selectedStatus,
        };
        axiosPut(`${API.SEND_GIFT_UPDATE}${id}/`, body, token)
            .then((res) => {
                if (res.data.status_code === 200) {
                    dispatch({
                        type: UPDATE_VOUCHER_STATUS,
                        payload: res.data,
                    });
                    refreshEnquiries();
                }
            })
            .catch((err) => {
                dispatch({
                    // type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};

export const getPlanningVoucherlist = (page) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = {
            next_page_id: page
        }
        axiosPostWithToken(`${API.SEND_GIFT_PLANNING}`, body, token)
            .then((res) => {

                if (res.data.status_code === 200) {
                    dispatch({
                        type: PLANNING_LIST,
                        payload: res.data,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    // type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};


export const getConfirmedVoucherlist = (page) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = {
            next_page_id: page
        }
        axiosPostWithToken(`${API.SEND_GIFT_CONFIRMED}`, body, token)
            .then((res) => {

                if (res.data.status_code === 200) {
                    dispatch({
                        type: CONFIRMED_LIST,
                        payload: res.data,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    // type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};

export const getCancelledVoucherlist = (page) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = {
            next_page_id: page
        }
        axiosPostWithToken(`${API.SEND_GIFT_CANCELLED}`, body, token)
            .then((res) => {

                if (res.data.status_code === 200) {
                    dispatch({
                        type: CANCELLED_LIST,
                        payload: res.data,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    // type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};

export const searchVoucher = (text, status) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const startDate = getState().voucherListing.filterStartDate;
        const endDate = getState().voucherListing.filterEndDate;
        // console.log(status, text, 'status')
        let body = {
            search_term: text,
            booking_status: status,
            from: startDate,
            to: endDate,
            next_page_id: 1,
        };

        // console.log("body", body);

        axiosPostWithToken(API.SEND_GIFT_SEARCH, body, token).then((res) => {
            if (res.data.status_code === 200) {
                if (status === "purchased") {
                    dispatch({
                        type: SEARCH_VOUCHER_PURCHASED,
                        payload: res.data,
                    });
                } else if (status === "planning") {
                    dispatch({
                        type: SEARCH_VOUCHER_PLANNING,
                        payload: res.data,
                    });
                } else if (status === "confirmed") {
                    dispatch({
                        type: SEARCH_VOUCHER_CONFIRMED,
                        payload: res.data,
                    });
                } else if (status === "cancelled") {
                    dispatch({
                        type: ENQUIRY_VOUCHER_CANCELLED,
                        payload: res.data,
                    });
                }
            } else {
                console.log("giftvoucheraction", res);
            }
        });
    };
};

export const filterStartDate = (date) => {
    console.log("sdate", date);

    return async (dispatch, getState) => {
        dispatch({
            type: VOUCHER_FILTER_START_DATE,
            payload: date,
        });
    };
};

export const filterEndDate = (date) => {
    console.log("edate", date);

    return async (dispatch, getState) => {
        dispatch({
            type: VOUCHER_FILTER_END_DATE,
            payload: date,
        });
    };
};

