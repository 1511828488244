import React from "react";
import CommentsField from "../components/holidays/customerReviews/commandField";

const CustomerReviews = ({
  title,
  status,
  address,
  price,
  comment,
  review_id,
  main_pack_id,
  publishReview,
  ref_id,
  review_star
}) => {
  
  return (
    <>
      <div className="enquiry-card-holder">
        <div
          className="organization-details"
          style={{ width: "306px", marginRight: "0px" }}
        >
          <h1>{title}</h1>
          <div className="info">
            <div>
              <span style={{ fontSize: "12px", color: "#7A7A7A" }}>
                {address}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "12px" }}>{price}</span>
            </div>
          </div>
        </div>
        <div className="contact-info" style={{ width: "376px" }}>
          <div className="title">
            <p>Comments</p>
          </div>
          <div className="contact-details">
            <p className="comment">
              <CommentsField content={comment} limit={100} />
            </p>
          </div>
        </div>
        <div className="contact-info" style={{ width: "150px",height:"76px" }}>
          <div className="rating-title">
            <p>Rating</p>
          </div>
          <div className="rating-details">
            <p >
             {review_star}
            </p>
          </div>
        </div>
        <div className={status == "Published" ? "d-none" : ""}>
          <p
            style={{
              backgroundColor: "#E7E7E7",
              padding: "5px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Status
          </p>
          <select
            id="status-dropdown-select"
            onChange={(e) => publishReview(e, review_id, main_pack_id, ref_id)}
            className="status-dropdown"
            value={"Pending"}
          >
            <option className="greenColor" id="status-option" value="Pending">
            {status=="pending"?"Pending":"Rejected"}  
            </option>
            <option className="greenColor" id="status-option" value="approved">
              Published
            </option>
            <option className="greenColor" id="status-option" value={status=="pending"?"rejected":"deleted"}>
            {status=="pending"?"Reject":"Delete"}  
            </option>
          </select>
        </div>
        <div className={status == "Published" ? "" : "d-none"}>
        <p
            style={{
              backgroundColor: "#E7E7E7",
              padding: "5px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
              height:"30px",
              width:"109px"

            }}
            onClick={()=>publishReview("deleted",review_id,main_pack_id, ref_id)}
          >
            Delete
          </p>
          </div>
      </div>
    </>
  );
};
export default CustomerReviews;
