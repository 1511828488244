import React, { useState } from 'react'
import  { Calendar } from "react-multi-date-picker";
import moment from 'moment';
import { specificDateValue } from '../../actions/experienceBookingAction';
import { useDispatch } from 'react-redux';

const AvailabilitySpecific = ({DonedHandler, specificAvailabilityData}) => {
    const [ bool, setBool ] = useState(false)
    const [values, setValues] = useState([
        moment(specificAvailabilityData.schedule.from, 'DD-MM-YYYY')._d,
    
        moment(specificAvailabilityData.schedule.to, 'DD-MM-YYYY')._d
    ]);
    const [value, setValue ] = useState({
        from:specificAvailabilityData.schedule.from,
       to:specificAvailabilityData.schedule.to
    });
    
    const dispatch = useDispatch();
    const packagedateValue = (val) => {
        setBool(false);
        dispatch(specificDateValue(val?.format()))
        setValue({
            from:val.format(),
            to:""
        })
    };

    return (
        <div className="calander-container">
         <Calendar
            mapDays={({ date }) => {
                let formatDates = specificAvailabilityData?.pack_schedule_data?.multipleDates.map((val)=>{
                        return moment(val).format('DD-MM-YYYY');
                });
                let enableDate = formatDates?.includes(date.format());
                if (!enableDate) return {
                    disabled: true,
                }
            }}
            minDate={new Date()}
            single
            values={value}
            format={'DD-MM-YYYY'}
            onChange={packagedateValue}

         />
         <div style={{textAlign:"end", marginBottom:"25px"}}>
            <button onClick={() => DonedHandler(value)}>Done</button>
         </div>
        </div>
    );
};

export default AvailabilitySpecific;
