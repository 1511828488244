import React from 'react';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import Parser from 'html-react-parser';
import * as EventsAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";

import { useDispatch, useSelector } from "react-redux";
import { Rating } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

const ReviewBlock = ({ userImage, name, review, rating, showDialog, id, packId, module, reviewId, data, mainPackId }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditBtnClick = () => {
        setAnchorEl(null);
        showDialog(true, 'edit', {
            "custom_user_name": name,
            "review_comment": review,
            "review_star": rating,
            "review_id": reviewId,
            "pack_id": packId,
            'custom_date': data.custom_date,
            'created': data.created
        });


    };
    const handleDeleteBtnClick = () => {
        // confirm('Are you sure?');
        const data = {
            "request_type": "delete_review",
            "pack_id": packId,
            "review_id": reviewId
        }
        if (window.confirm('Are you sure you want delete the Review?')) {
            // Save it!
            if (module === 'holiday') {
                dispatch(HolidaysAction.deleteHolidayPackReview(data, handleDeleteSuccess))
            } else if (module === 'experience') {
                dispatch(experiencesListingAction.deleteExperiencePackReview(data, handleDeleteSuccess))
            } else if (module === 'corporate') {
                dispatch(CorpListingAction.deleteCorporatePackReview(data, handleDeleteSuccess))
            }
        } else {
            // Do nothing!
            console.log('Dont do.');
        }

        setAnchorEl(null);
    };
    const handleDeleteSuccess = () => {
        enqueueSnackbar("Deleted Successfully", {
            variant: "success"
        })
        if (module == "holiday") {
            dispatch(HolidaysAction.getHolidayReviewsList(packId))
            dispatch(HolidaysAction.getHolidayPackageInfo(mainPackId))
        } else if (module == "corporate") {
            dispatch(CorpListingAction.getCorporateReviewsList(packId))
            dispatch(CorpListingAction.getPackageInfo(mainPackId))
        } else {
            dispatch(experiencesListingAction.getExperienceReviewsList(packId))
            dispatch(experiencesListingAction.getPackageInfo(mainPackId))
        }

    };


    const handleDeleteError = () => {

    }
    return (
        <div className="row bg-white mb-3 p-3  mx-1 rounded">
            <div className="col ml-n3" >
                {/* <img className="rounded-circle mt-2" style={{ width: '50px' }} src={userImage} alt="user image" /> */}
                <Rating
                    value={rating}
                    name="size-small"
                    readOnly
                    size="small"
                />
                <h5 className="mb-1" style={{ fontSize: '18px' }}>{name}</h5>
            </div>
            <div className="col ">
                <div className="row">
                    <div className="col text-right" >
                        <span>
                            {/* <MoreHorizIcon /> */}
                            <span
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <MoreHorizIcon />
                            </span>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem divider={true} onClick={handleEditBtnClick}>
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={() => handleDeleteBtnClick()}>
                                    Delete
                                </MenuItem>
                            </Menu>
                        </span></div>
                </div>

            </div>
        </div>
    );
};

export default ReviewBlock;