import React,{ useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { holidaysbookingStatusValues } from '../../../resources/status';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import ConfirmRefundModal from '../../../common/confirmRefundModel';

import { 
    getHolidayBookingList, 
    holidayBookingsSearch, 
    holidayBookingStatusUpdate,
    pageId,
    holidayBookingSearchValue
} from '../../../actions/holidayBookingAction';
import ClosedModal from '../requests/closeRequestModal';
import { checkNumberOfGuest, isCorporate} from '../../../utils/index';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

import moment from 'moment'
const Bookings = () => {
    const [ showMoreInfo, setShowMoreInfo ] = useState(false);
    const[refundModel,setRefundModel]=useState(false)
    const [ moreInfoData, setMoreInfoData ] = useState({});
    const [ searchVal, setSearchVal ] = useState('');
    const [ pageVal, setPageVal ] = useState(1);
    const [status, setStatus] = useState('');
    const [id, setId] = useState(null);
    const[iscopr,setIscorp]=useState()
    const [ statusChangeBool, setStatusChangeBool ] = useState(false);
    const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()

  const[refundPackId,setRefundPackId]=useState()

  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
   

    const holidayBooked = useSelector(state => state.holidayBooking.holidayBooking.results);
    console.log(holidayBooked,"holidayBooked");
    const totapPage = useSelector(state => state.holidayBooking.holidayBooking.total_pages);
    const pageNum = useSelector(state => state.holidayBooking.page);
    const sectionID = useSelector(state => state.holidayBooking.bookingsSecId);
    const holidayBookingNxtPgeId = useSelector(state => state.holidayBooking.holidayBookingNxtPgeId);
    const holidayBookingCurrentPgeId = useSelector(state => state.holidayBooking.holidayBookingCurrentPgeId);
    const holidayBookingSearchVal = useSelector(state => state.holidayBooking.holidayBookingSearchVal);
    const ffStartDate = useSelector((state) => state.holidayBooking.holidayBookingStartDate);
   const ffEndDate = useSelector((state) => state.holidayBooking.holidayookingEndDate);
   const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMoreInfo]);

    useEffect(() => {
        if(holidayBookingNxtPgeId === null && holidayBookingCurrentPgeId > 1 && holidayBooked?.length === 0) {
            dispatch(getHolidayBookingList(1, sectionID, "booked"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [holidayBooked, pageVal]);

    const handlePagination = (e, value) => {
        setPageVal(value);
        if(holidayBookingSearchVal !== "" || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayBookingsSearch(value, 'booked', holidayBookingSearchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        }else{
            dispatch(getHolidayBookingList(value, sectionID, "booked"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };
    const handleRefund=(id)=>{
        setRefundModel(true)
        setRefundPackId(id)
        

    }

    const handleChange = (event) => {
        setSearchVal(event.target.value);
        dispatch(holidayBookingsSearch(1, 'booked', event.target.value, sectionID, ffStartDate, ffEndDate))
        dispatch(holidayBookingSearchValue(event.target.value, "booked"))
        dispatch(pageId(1));
    };

    const refreshHolidayBooking = () => {
        if(searchVal !== '' ) {
            dispatch(holidayBookingsSearch(pageNum, 'booked', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"));
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"));
        }else if(ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'booked', searchVal, sectionID, ffStartDate, ffEndDate));

        } else {
            dispatch(getHolidayBookingList(pageNum, sectionID, "booked"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
        }
    };

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;
        setStatusChangeBool(true)
        setStatus(status);
        setId(id);
        setIscorp(corporate)
        if(status === "cancelled")setRequestModal(true);
        else{
            dispatch(holidayBookingStatusUpdate(status, id, corporate,sectionID,null, refreshHolidayBooking));
        // dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"))
        // dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
        dispatch(pageId(pageVal));

        }
        
    };


    useEffect(()=>{
        const data=holidayBooked?.map((elem)=>{
            return parseInt(elem.amount_paid)
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(totalamount/holidayBooked?.length)

    },[holidayBooked])

      




    const holidayBookingDisplayData = holidayBooked && holidayBooked.map(data => (
        <AdminListing 
            packTitle={data.pack_title}
            address={data?.sec_name  && data?.sec_name == "International" ? data.location_country : data.address}
            amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.from} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            messageTitle={data.special_request}
            isHolidayBooking={true}
            value={data.booking_status}
            statusValues={holidaysbookingStatusValues}
            onClick={() => moreInfoHandler(data)}
            id={data.id}
            data={data}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            isHolidayRequest={false}
            corporate={data.corporate}
            statusChangeHandler={statusChangeHandler}
            handleRefund={handleRefund}
            status={"booked"}
            voucher_info={data?.voucher_info}
       />
    ));

    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
       else {
            dispatch(holidayBookingStatusUpdate(status, id, iscopr, sectionID, closeRequestMessage,refreshHolidayBooking))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };

    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    return(
        <>
            <SearchBar
                searchVal={holidayBookingSearchVal}
                handleChange={handleChange}
            />
            {holidayBooked && holidayBooked?.length === 0 && 
             <div className="enquiry-card-holder">No Record Found!</div>
            }

    
      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
            {holidayBookingDisplayData}
            {showMoreInfo && 
                <CommonMoreInfoModel 
                    open={showMoreInfo}
                    modalClose={() => setShowMoreInfo(false)}
                    moreInfoData={moreInfoData}
                    isCancellationMessage={false}
                />
            }
             {refundModel&&<ConfirmRefundModal 
                 open={refundModel}
                 modalClose={() => setRefundModel(false)}
                 section={"holiday"}
                 refundPackId={refundPackId}
            />}
            <Pagination 
                totalPage={totapPage} 
                page={pageNum} 
                onChange={(event, value) => handlePagination(event, value)}
            />
                       {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Bookings;