import holidays from '../assets/images/holidays.svg';
import corporates from '../assets/images/corporates.svg';
import experiences from '../assets/images/experiences.svg';
import gifts from '../assets/images/gifts.svg';
import events from '../assets/images/eventsIcon.png'
import subscription from '../assets/images/envelope.svg'
import payment from '../assets/images/payment.svg'
import wedding from '../assets/images/wedding.svg'
import webstories from '../assets/images/webstories.svg'
import callIcon from "../assets/images/call.svg"
import popularDestination  from "../assets/images/popularIcon.svg"


export const MENU_LIST = [
    // {
    //     name:"Events",
    //     id:0,
    //     icon:`${events}`,
    //     children:[
    //         {
    //             name:"Listings",
    //             url:"/listings",
    //         },
    //         {
    //             name:"Bookings",
    //             url:"/bookings",
    //         },
    //         {
    //             name:"Sections",
    //             url:"/sections",
    //         },
    //     ]
    // },
    {
        name: "Holidays",
        id: 1,
        icon: `${holidays}`,
        children: [
            {

                name: "Listings",
                url: "/listings"
            },
            {
                name: "Bookings",
                url: "/bookings"
            },
            {
                name: "Requests",
                url: "/requests"
            },
            {
                name: "Sections",
                url: "/sections"
            },
            {
                name: "Customer Reviews",
                url: "/customerReviews"
            }
        ]
    },
    {
        name: "Corporates",
        id: 2,
        icon: `${corporates}`,
        children: [
            {
                name: "Listings",
                url: "/listings"
            },
            {
                name: "Bookings",
                url: "/bookings"
            },
            {
                name: "Partner Bookings",
                url: "/partnerBookings"
            },
            {
                name: "Enquiries",
                url: "/enquiries"
            },
            {
                name: "Accounts",
                url: "/accounts"
            },
            {
                name: "Partners",
                url: "/partners"
            },
            {
                name: "Sections",
                url: "/sections"
            },
            {
                name: "Customer Reviews",
                url: "/customerReviews"
            }
        ]
    },
    {
        name: "Experiences",
        id: 3,
        icon: `${experiences}`,
        children: [
            {
                name: "Listings",
                url: "/listings"
            },
            {
                name: "Bookings",
                url: "/bookings"
            },
            {
                name: "Sections",
                url: "/sections"
            },
            {
                name: "Customer Reviews",
                url: "/customerReviews"
            }
        ]
    },
    {
        name: "My Family First",
        id: 4,
        icon: `${gifts}`,
        url: "myfamiyfirst",
        children: [
            // {
            //     name: "My family first",
            //     url: "/myfamilyfirst"
            // },
            {
                name: "Gifting",
                url: "/gifting",
                children:[
                    {
                        name: "Stories",
                        url: "/gifting/stories"
                    },
                    {
                        name: "Requests",
                        url: "/gifting/requests"
                    },
                    
                ]
            },
            {
                name: "Honeymoon",
                url: "/honeymoon",
                children:[
                    {
                        name: "Destinations",
                        url: "/honeymoon/destinations"
                    },
                    {
                        name: "Stories",
                        url: "/honeymoon/stories"
                    },
                    {
                        name: "Requests",
                        url: "/honeymoon/requests"
                    },
                    
                ]
            },
            {
                name: "Anniversary",
                url: "/anniversary",
                children:[
                    {
                        name: "Destinations",
                        url: "/anniversary/destinations"
                    },
                    {
                        name: "Stories",
                        url: "/anniversary/stories"
                    },
                    {
                        name: "Requests",
                        url: "/anniversary/requests"
                    },
                    
                ]
            },
            // {
            //     name: "Gift voucher",
            //     url: "/giftvoucher"
            // }
        ]
    },
    {
        name: "Weddings",
        id: 5,
        icon: `${wedding}`,
        children: [
            {
                name: "Destinations",
                url: "/destinations"
            },
            {
                name: "Stories",
                url: "/stories"
            },
            {
                name: "Requests",
                url: "/requests"
            },
        ]
    },
    {
        name: "Subscription",
        id: 6,
        icon: `${subscription}`,
        children: [
            {
                name: "Subscription",
                url: "/subscriptionNumbers"
            }
        ]
    },
    {
        name: "Payment",
        id: 6,
        icon: `${payment}`,
        children: [
            {
                name: "Create Payment Link",
                url: "/createPaymentLink"
            },
            {
                name: "Bookings",
                url: "/bookings"
            }
        ]
    },
    {
        name: "Web Stories",
        id: 6,
        icon: `${webstories}`,
        url: "webstories",
        children: [
            {
                name: "Listing",
                url: "/listing"
            },
           
        ]
    },
    {
        name: "Call Back Request",
        id: 7,
        icon: `${callIcon}`,
        url: "callback",
        children: [
            {
                name: "Listing",
                url: "/listing"
            },
           
        ]
    },
    {
        name: "Popular Destination",
        id: 8,
        icon: `${popularDestination}`,
        url: "popular-Destination",
        children: [
            {
                name: "Listing",
                url: "/listing"
            },
           
        ]
    },
    
]