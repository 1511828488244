import { API } from "../constants/api";
export const GET_CALLBACK_LIST="GET_CALLBACK_LIST"
export const get_callback_list = (pageNo) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const headers = {
        Authorization: `${token}`,
      };
    const requestOptions = {
        method: 'GET',
        headers: headers,
      };
  
      try {
        const response = await fetch(`${API.CALL_BACK_LIST}?page=${pageNo}&list_per_page=10`, requestOptions);
        if (response.status === 200) {
          const responseData = await response.json();
          
          dispatch({
            type: GET_CALLBACK_LIST,
            payload: responseData.data,
          });
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  };

  export const search = (value) => {
   
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const headers = {
        Authorization: `${token}`,
      };
  
     
      
      const requestOptions = {
        method: 'GET',
        headers: headers,
      };
  
      try {
        const response = await fetch(`${API.CALL_BACK_LIST}?search=${value}`, requestOptions);
        if (response.status === 200) {
          const responseData = await response.json();
          
          dispatch({
            type: GET_CALLBACK_LIST,
            payload: responseData.data,
          });
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  };
