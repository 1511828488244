import { Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperienceReviewPendingList,
  getExperienceReviewPublishedList,
  getExperienceReviewRejectedList,
  getExperienceReviewsearch,
  myExperienceReviewEndDate,
  myExperienceReviewStartDate,
} from "../../../actions/experienceReviewActions";
import { getHolidayReviewRejectedList } from "../../../actions/holidayReviewAction";
import Datefilter from "../../../common/commonDateFIlter";
import DropdownFilter from "../../holidays/customerReviews/dropDownFilter";
import Pending from "./pending";
import Published from "./published";
import Rejected from "./rejected";
const { TabPane } = Tabs;
const ExperiencesReviewsIndex = () => {
  const [status, setStatus] = useState("pending");

  const dispatch = useDispatch();
  const ExperiencePendingReviews = useSelector(
    (state) => state.experienceReviews?.experienceReviewList
  );
  const ExperienceReviewsPublished = useSelector(
    (state) => state.experienceReviews?.experiencePublishedReviewList
  );
  const ExperienceReviewsRejected = useSelector(
    (state) => state.experienceReviews?.experienceRejectedReviewList
  );
  const ffEndDate = useSelector(
    (state) => state.experienceReviews.experienceReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.experienceReviews.experienceReviewStartDate
  );
  const isDataLoading = useSelector(
    (state) => state.experiencesListing.isDataLoading
  );
  console.log(ffEndDate, ffStartDate);
  const handleStartDateChange = (date) => {
    dispatch(myExperienceReviewStartDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleEndDateChange = (date) => {
    dispatch(myExperienceReviewEndDate(moment(date).format("YYYY-MM-DD")));
  };
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myExperienceReviewStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myExperienceReviewEndDate(null));
  };
  const totalExperiencePendingReviews =
    ExperiencePendingReviews &&
    ExperiencePendingReviews?.review_data?.total_results;
  const totalExperiencePublishedReviews =
    ExperienceReviewsPublished &&
    ExperienceReviewsPublished?.review_data?.total_results;
    const totalExperienceRejectedReviews =
    ExperienceReviewsRejected &&
    ExperienceReviewsRejected?.review_data?.total_results;

  useEffect(() => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(getExperienceReviewsearch(1, status, ffStartDate, ffEndDate));
    } else if (ffEndDate === null && ffStartDate === null) {
      dispatch(getExperienceReviewPublishedList(1));
      dispatch(getExperienceReviewRejectedList(1))
    }
  }, [ffEndDate, ffEndDate, status]);
  const handleTabChange = (key) => {
    if (key === "2") {
      setStatus("approved");
    }
    else if (key === "3") {
      setStatus("rejected");
    } else {
      setStatus("pending");
    }
  };
  return (
    <div>
      <>
        <div style={{ position: "absolute", right: 50 }}>
          <Datefilter
            status={status}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            ffStartDate={ffStartDate}
            ffEndDate={ffEndDate}
            handleStartClear={handleStartClear}
            handleEndClear={handleEndClear}
          />
        </div>
        <div className="enquiry-tabs-holder">
          <div style={{ display: "flex" }}>
            <p className="title-holder">Reviews</p>
           
          </div>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab={`${totalExperiencePendingReviews} Pending`} key="1">
              <Pending />
            </TabPane>
            <TabPane
              tab={`${totalExperiencePublishedReviews} Published`}
              key="2"
            >
              <Published />
            </TabPane>
            <TabPane tab={`${totalExperienceRejectedReviews} Rejected`} key="3">
            <Rejected />
          </TabPane>
          </Tabs>
          {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
        </div>
      </>
    </div>
  );
};

export default ExperiencesReviewsIndex;
