import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";
import MoreInfoModal from "../../../common/moreInfoModal";
import { bookingConfirmAndCalcelledStatusValue } from "../../../resources/bookingStatus";
import SearchBar from "./searchBar";
import { BookedWithPointAndRs, checkNumberOfGuest } from '../../../utils/index'
import CommonMoreInfoModel from "../../../common/commonMoreInfoModel";
import moment from "moment";

export default function Cancelled({ cancelledBookings, status }) {

  const dispatch = useDispatch();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  const[totalusedpoints,setTotalusedpoints]=useState()
  const [showInfoModalDataForCancelled, setShowInfoModaldataForCancelled] = useState(false)
  const getPageNum = useSelector((state) => state?.bookingListing?.page_num);
  const searchValue = useSelector((state) => state.bookingListing.corpCancelledSearchValue);
  const secId = useSelector((state) => state.bookingListing.getSecId);
  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);

  const [state, setState] = React.useState({
    status: undefined,
  });
  useEffect(()=>{
    const amount=cancelledBookings?.results?.map((elem)=>{
        return (parseInt(elem.amount_paid!==0?elem.amount_paid:0))
    })
  
    const totalamount=amount?.reduce((acc,elem)=>{
        return acc+elem
    },0)
    const points=cancelledBookings?.results?.map((elem)=>{
    
      return parseInt(parseInt(elem.total_points)!==0?elem.total_points:0)
    })
    const totalpoints=points?.reduce((acc,elem)=>{
        return acc+elem
    },0)
    setTotalusedpoints(totalpoints)
    setTotalbookingAmount(totalamount)
    setAverageBookingAmount(Math.round(totalamount/cancelledBookings?.results?.length))

},[cancelledBookings?.results])

  useEffect(() => {
    if (searchValue === "" &&
      cancelledBookings?.next_page_id === null &&
      cancelledBookings?.current_page > 1 &&
      cancelledBookings?.results.length === 0) {
      dispatch(BookingAction.getCancelledBookinglist(1, secId));
      dispatch(BookingAction.setPageNum(1));
    } else if (searchValue !== "" &&
      cancelledBookings?.next_page_id === null &&
      cancelledBookings?.current_page > 1 &&
      cancelledBookings?.results.length === 0) {
      dispatch(BookingAction.setPageNum(1));
      dispatch(BookingAction.searchBooking(searchValue, "cancelled", secId, 1));
    }
  }, [cancelledBookings.results, getPageNum, searchValue]);

  // const bookingStatusChange = (id, status) => {
  //   setState({ ...state, status });
  //   dispatch(BookingAction.updateBookingStatus(id, status, secId, refreshEnquiries));
  // };

  // const refreshEnquiries = () => {
  //   if (searchValue !== "") {
  //     dispatch(BookingAction.searchBooking(searchValue, "cancelled", secId, getPageNum));
  //     dispatch(BookingAction.getBookingConfirmedlist(getPageNum, secId, getPageNum));
  //   } else if (startDate !== null && endDate !== null) {
  //     dispatch(BookingAction.searchBooking(searchValue, "cancelled", secId, getPageNum));
  //     dispatch(BookingAction.searchBooking(searchValue, "confirmed", secId, getPageNum));
  //   }
  //   else {
  //     dispatch(BookingAction.getBookingList(getPageNum, secId));
  //     dispatch(BookingAction.getBookingConfirmedlist(getPageNum, secId));
  //     dispatch(BookingAction.getCancelledBookinglist(getPageNum, secId));
  //   }
  // };
  const handleModalClose = () => {
    setShowInfoModal(false);
  };
  const handleInfoModal = (infoCancelData) => {
    setShowInfoModaldataForCancelled(infoCancelData);
    setShowInfoModal(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => document.body.style.overflow = 'unset';
  }, [showInfoModal]);

  return (
    <div>
      <SearchBar status="cancelled" inputValue={searchValue} />
      {cancelledBookings?.results.length === 0 &&
        <div className="enquiry-card-holder">No Record Found!</div>
      }
     
      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
 </span> <span style={{marginLeft:"80px"}}><b> 
 Total Points Used: {" "} {totalusedpoints?Math.round(totalusedpoints):""}
 </b>
  </span>
  </p>
      <div className="overflow--scroll" style={{ marginBottom: '10px' }}>
        {cancelledBookings?.results &&
          cancelledBookings?.results.length > 0 &&
          cancelledBookings?.results.map((item) => (
            <div className="enquiry-card-holder">
              <div className="organization-details">
              <span style={{display:"block", color: "#2196F3"}}>{item?.ref_id? `#${item.ref_id}`: "No ref id"}</span>
                <h1>{item.pack_title}</h1>
                <div className="info">
                  {/* <p>{item.address}</p> */}
                  <p>{item?.sec_name && item?.sec_name == "International" ? item.location_country : item.address}</p>
                  <p>
                  Booked with {item?.voucher_info?.voucher_code?`(${item?.voucher_info?.voucher_code}  ${item?.voucher_info?.voucher_amount?`of amount` +" " +  item?.voucher_info?.voucher_amount:"package price"})  ${item?.amount_paid>0 ? `&` +" "+  "Rs" + " " +item?.amount_paid:""}`:BookedWithPointAndRs(item.total_points, item.amount)}
  
                  </p>
                  <p> <span style={{fontSize:"12px"}}>Booked on {moment(item?.created).format("Do MMMM, YYYY")}</span></p>
                </div>
              </div>
              {
                ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null)) ?
                  <div className="contact-info">
                    <div className="title">
                      <p>Booked for</p>
                    </div>
                    <div className="contact-details">
                      <p className="email">
                        {
                          item.schedule && item.schedule?.from
                            ? `${moment(item.schedule.from, "DD-MM-YYYY").format("DD-MM-YYYY")} ${ item.schedule.to ? `to ${moment(item.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}`
                            : Array.isArray(item.schedule) ? item.schedule.map((scheduleDate, index) => {
                              if (item.schedule[index] === item.schedule[item.schedule.length - 1]) {
                                return scheduleDate
                              } else {
                                return `${scheduleDate}, `
                              }
                            }): "" 
                            }
                      </p>
                      <p>
                        {checkNumberOfGuest(item.adults, item.children, item.infants)}
                      </p>
                    </div>
                  </div> : ''
              }

              {/* {
                ((item.adults !== 0 && item.adults !== null) || (item.children !== 0 && item.children !== null) || (item.infants !== 0 && item.infants !== null)) ?

                  <div className="contact-info">
                    <div className="title">
                      <p>Message</p>
                    </div>
                    <div className="contact-details">
                      <p className="message-limit" title={item.special_request ? item.special_request : 'No Message'}>
                        {item.special_request ? item.special_request : (<p>No Message</p>)}
                      </p>
                    </div>
                  </div> : ''

              } */}
              <div>
                <p
                  onClick={() => handleInfoModal(item)}
                  style={{
                    backgroundColor: "#E7E7E7",
                    padding: "5px",
                    textAlign: "center",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}>More Info</p>
                <p
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px 30px",
                    marginBottom: "0px",
                    textAlign: "center",
                    borderRadius: "5px",
                    // cursor: "pointer",
                    border: "1px solid #C4C4C4",
                    width: "154px",
                    height: "32px"
                  }}
                >Cancelled</p>
                {/* <select
                  className="status-dropdown"
                  value={item.booking_status}
                  onChange={(event) =>
                    bookingStatusChange(item.id, event.target.value)
                  }
                >
                  {bookingConfirmAndCalcelledStatusValue.length > 0 &&
                    bookingConfirmAndCalcelledStatusValue.map((options) => (
                      <option value={options.value}>{options.name}</option>
                    ))}
                </select> */}

              </div>
              
            </div>
          ))}
          {showInfoModal &&
                <CommonMoreInfoModel 
              open={showInfoModal}
              modalClose={handleModalClose}
              moreInfoData={showInfoModalDataForCancelled}
              isCancellationMessage={true}
            />
                // <MoreInfoModal
                //   className="cancelled"
                //   showInfoModalData={showInfoModalDataForCancelled}
                //   showMyModal={showInfoModal}
                //   modalClose={() => setShowInfoModal(false)}
                // />
                }
      </div>
    </div>
  );
}
