import React, { useEffect, useState } from 'react'
import { Tabs } from "antd";
import Pending from './pending';
import { useDispatch, useSelector } from 'react-redux';
import Booked from './booked';
import { getBookedPayments, getPaymentBookings, getPendingPayments } from '../../../actions/paymentLinkAction';
import Cancelled from './cancelled';
import { Spinner } from 'react-bootstrap';

const { TabPane } = Tabs;

const Index = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending")
  const PENDING_PAYMENT_COUNT = useSelector(state => state.paymentLinkBookings.pendingPaymentList?.total_results)
  const BOOKED_PAYMENT_COUNT = useSelector(state => state.paymentLinkBookings.bookedPaymentList?.total_results)
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  const handleTabChange = (key) => {
    if(key === '2') {
      setStatus('booked')
      dispatch(getBookedPayments(1));
    }
    else {
      setStatus('pending')
      dispatch(getPendingPayments(1));
    }
  };
  useEffect(() => {
    dispatch(getPendingPayments(1));
    dispatch(getBookedPayments(1));
  }, [])

  return (
    <div className="enquiry-tabs-holder">
      <p className="title-holder">{PENDING_PAYMENT_COUNT + BOOKED_PAYMENT_COUNT} Bookings</p>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={`${PENDING_PAYMENT_COUNT} Pending`} key="1">
          <Pending />
        </TabPane>
        <TabPane tab={`${BOOKED_PAYMENT_COUNT} Booked`} key="2">
          <Booked />
        </TabPane>
        {/* <TabPane tab={`${0} Cancelled`} key="3">
          <Cancelled />
        </TabPane> */}
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      </Tabs>
    </div>
  )
}

export default Index