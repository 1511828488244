import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress,
  axiosPatchWithToken,
  axiosDeleteWithBody
} from "../services/apiServices";

import {
  GET_CORPORATE_LIST,
  ADD_IMAGE,
  GET_LOCATION_CITY_LIST,
  ALL_IMAGES_DATA,
  UPDATE_CORPORATES,
  GET_SELECT_DROPDOWN,
  PACKAGE_DELETE,
  CORPORATE_DELETE,
  CORP_LISTING_UPDATED_BOOL,
  IMAGE_DELETE,
  ADD_CORP_SECTION_IMAGES,
  GET_PACKAGE_INFO,
  CORP_LISTING_PAGE_ID,
  CORP_DROPDOWN_FILTER_SEC_ID,
  CORP_LISTING_SEARCH_VALUE,
  DELETE_PACKAGE_ITEM_BOOL,
  TESTIMONIAL_CORPORATE_LIST,
  GET_CORPORATE_FEATURED_LIST,
  CORP_FEATURED_UPDATE,
  GET_SUBSCRIBERS_COUNT,
  MOVED_PACK_SEC_ID,
  CORPORATE_PACK_REVIEWS_LIST,
  IS_CORP_LIST_DATA_LOADING,
  IS_CORP_DATA_LOADING,
  IS_DATA_LOADING
} from "./types";

export const SET_EDIT_CORPORATE_LIST_DETAILS =
  "SET_EDIT_CORPORATE_LIST_DETAILS";
export const SET_ADD_CORPORATE_PACKAGE = "SET_ADD_CORPORATE_PACKAGE";
export const SET_ADD_PACKAGE = "SET_ADD_PACKAGE";

export const updateFeaturedList = (data, id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPatchWithToken(`${API.CORP_FEATURED_UPDATE + id + "/"}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getCorpListing = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id: page,
      sec_id:secId
    };
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.CORPLISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CORPORATE_LIST,
            payload: res.data,
            // payload: res.data.results.length > 0 && res.data,
          });
          dispatch({
            type: IS_CORP_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};
export const getCorpLocatioCityList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.CORP_LOCATION_CITY_LIST}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_LOCATION_CITY_LIST,
            payload: res.data.data.city_list,
          });
        }
      })
      .catch((err) => {
      });
  };
};

export const getCorpFilteredList = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id: "1",
      sec_id: id
    }
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.CORPLISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CORPORATE_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};


export const editCorpListItem = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_CORPORATE_LIST_DETAILS,
      payload: data,
    });
  };
};
export const corpDropDownFilterSecId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CORP_DROPDOWN_FILTER_SEC_ID,
      payload: id,
    });
  };
};

export const setAddPackage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ADD_CORPORATE_PACKAGE,
      payload: value,
    });
    // showData()
  };
};
export const CorpListsUpdateBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: CORP_LISTING_UPDATED_BOOL,
      payload: bool,
    });
    // showData()
  };
};

export const corpPageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type:CORP_LISTING_PAGE_ID,
      payload: page,
    })
  
  };
}; 

export const imageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;

    axiosMediaUpload(API.IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        console.log("RESPO IMAGE", res);
        dispatch({
          type: ADD_IMAGE,
          payload: res.data,
        });
        addImage(res.data.data);
        console.log("RESP2", res.data.data);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const getCorporateTestimonials = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_CORPORATE_TESTIMONIAL_LIST}${id}/`, token)
    .then((res) => {
        if (res.data.status_code === 200) {
            dispatch({
                type: TESTIMONIAL_CORPORATE_LIST,
                payload: res.data.data,
            });
        }
    })
    .catch((err) => {
       console.log(err);
    });
  }
};

export const corpPostNewTestimonial = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      main_pack_id:data.id,
      guest_name:data.authorName,
      guest_image:data.data!==null?data.data.url:'',
      message: data.authorDesc
    }
    axiosPostWithToken(`${API.CORP_POST_TESTIMONIAL}`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
};

export const updateCorpTestimonial = (data, tid, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "guest_name": data.guest_name,
      "guest_image": data.guest_image,
      "message": data.message,
      "main_pack_id":data.id
  };
    axiosPut(`${API.UPDATE_CORP_TESTIMONIAL}${tid}/`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
}

export const deleteCorpTestimonials = (id,eventId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body={
      "main_pack_id":eventId
    }
   
    axiosDeleteWithBody(`${API.DELETE_CORP_TESTIMONIAL}${id}`, body,token)
    .then((res) => {
      
        handleSuccess();
    })
    .catch((err) => {
        handleError();
    });
  }
};
export const videoUpload = (file, addVideo, obj, newArr, getUploadProgress, id) => {
  console.log(getUploadProgress);
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.VIDEO_UPLOAD, "image", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const listDataUpdate = (data, loader, handleSuccess, handleError, corp_id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = data;

    axiosPut(`${API.UPDATE_CORPORATES}${data.corp_id}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          // loader()
          loader(data.corp_id);
          handleSuccess()
          dispatch({
            type: GET_CORPORATE_LIST,
            payload: res.data,
          });
          dispatch({
            type: UPDATE_CORPORATES,
            payload: res.data,
          });
        } else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          loader(data.corp_id);
        }
      }
    );
  };
};

export const searchListing = (search, page) => {
  return async (dispatch, getState) => {
    const id = getState().corpListing.corpDropDownFilteredSecId?.id;
    const body = {
      search_term: search,
      "next_page_id":page,
      sec_id:id
    };
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CORP_SEARCH_LISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            // type: SEARCH_CORPORATE_LIST_DATA,
            type: GET_CORPORATE_LIST,
            payload: res.data,
            // payload: res.data.results.length > 0 && res.data,
          });
          dispatch({
            type: IS_CORP_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};
export const handleAddNewData = (data, refreshList, handleError, loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.POST_CORPORATES}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList();
        }
        else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          // loader(data.experience_id);
          loader()
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAddNewPackage = (data, refreshList) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.POST_PACKAGE}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList(data.corp_id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAllImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ALL_IMAGES_DATA,
      payload: data,
    });
  };
};
export const getFeaturedList = (id) => {
  const body = {
    "featuredList": [
      {"id": 168,  "featured_number": 11
      },
      {"id": 166,  "featured_number": 10
      }
    ]
  };
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.GET_CORPORATE_FEATURED_LIST + id }`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_CORPORATE_FEATURED_LIST,
          payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
    });
  };
};
export const searchValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CORP_LISTING_SEARCH_VALUE,
      payload: value,
    });
  };
};

export const deletePackageItemBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PACKAGE_ITEM_BOOL,
      payload: bool,
    });
  };
};

export const selectDropdown = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.SELECT_DROPDOWN}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SELECT_DROPDOWN,
          payload: res.data.data,
        });
      }
    });
  };
};
export const getMovedPackSectionId = (packSlug) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "title": packSlug
    }
    axiosPostWithToken(`${API.MOVED_PACK_SECTION_ID}`,body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: MOVED_PACK_SEC_ID,
          payload: res.data.data,
        });
      }else if(res.data.status_code === 400){
        dispatch({
          type: MOVED_PACK_SEC_ID,
          payload: res.data.data,
        });
      }
    });
  };
};
export const getReversedPackSectionId = (packSlug,handleUpdateSuccess,handleUpdateError,section) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "slug": packSlug
    }

    if(section=="holidays"){
      axiosPostWithToken(`${API.HOLIDAYS_PACK_COPY_TO_CORP_FALSE}`,body, token).then((res) => {
        if (res.data.status_code === 200) {
          handleUpdateSuccess(res.data.data.message)
        }else if(res.data.status_code === 400){
          handleUpdateError(res.data.data.message)
        }
      });
    
    }
    else if(section=="experiences"){
  
      axiosPostWithToken(`${API.EXPERIENCE_PACK_COPY_TO_CORP_FALSE}`,body, token).then((res) => {
        if (res.data.status_code === 200) {
          handleUpdateSuccess(res.data.data.message)
        }else if(res.data.status_code === 400){
          handleUpdateError(res.data.data.message)
        }
      });

    }
 
  };
};

export const deletePackage = (id, packId, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.PACKAGE_DELETE}${id.id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        handleSuccess(packId)
        dispatch({
          type: PACKAGE_DELETE,
          payload: res.data.data,
        });

      }
    });
  };
};

export const imageDelete = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.IMAGE_DELETE}${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: IMAGE_DELETE,
          payload: res.data.data,
        });
        // onDelete();
      }
    });
  };
};

export const deleteCorporate = (id, packId,loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.CORPORATE_DELETE}${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        loader(packId);
        dispatch({
          type: CORPORATE_DELETE,
          payload: res.data.data,
        });
        // window.location.reload();
      }
    });
  };
};

export const addCorpSectionImages = (
  submitData,
  refreshData,
  handleSuccess,
  handleError
) => {
  return async (dispatch, getState) => {
    const body = {
      name: submitData.section,
      image: submitData.imageUrl,
      priority: submitData.priority,
      code:submitData.refCodeValue,
      gift_voucher: submitData.gift_voucher
    };
    const token = getState().auth.token;

    axiosPostWithToken(`${API.ADD_SECTION_IMAGES}`, body, token)
      .then((res) => {
        if (res.data.status_code === 201) {
          dispatch({
            type: ADD_CORP_SECTION_IMAGES,
            payload: res.data,
          });
          refreshData();
          handleSuccess();
        } else if (res.data.status_code === 400) {
          handleError(res.data.data.name);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getPackageInfo = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.PACKAGE_INFO + id + "/"}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_PACKAGE_INFO,
          payload: res.data.data,
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      }else{
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: IS_CORP_DATA_LOADING,
        payload: false,
      });
    });
  };
};

export const getSubscribersCount = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.SUBSCRIBERS_COUNT}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SUBSCRIBERS_COUNT,
          payload: res.data.data,
        });
      }
    });
  };
};

// pack reviews

export const getCorporateReviewsList = (id) => {
  const body = {
    "request_type":"list_review",
    "pack_id": id,
    "page":1,
    "list_per_page": 50,
    "filters":[{"field":"active", "value":"true"}],
    "sort":{"field":"custom_date", "order":"D"}
  }
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_CORPORATE_REVIEWS}`,body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: CORPORATE_PACK_REVIEWS_LIST,
            payload: res.data.data.results
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const addCorporatePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_CORPORATE_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 201) {
           handleSuccess("Added Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const editCorporatePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_CORPORATE_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
           handleSuccess("Updated Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const deleteCorporatePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_CORPORATE_REVIEWS}`,data, token)
      .then((res) => {
           handleSuccess("Deleted Successfully")
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

