import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import Edit from "../../../assets/images/edit.svg";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/css/partials/packageDetails.scss";
import { FaFileUpload } from "react-icons/fa";
import JoditEditor from "jodit-react";
// import ImageMultiSelect from "./imageMultiSelect/imageMultiSelect";
import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CancelEventPopup from "./cancelEventPopup";
import ShedulePopup from "./shedulePopup";
import { useSnackbar } from "notistack";
import * as EventListingAction from "../../../actions/eventsAction";
import { removeSpaces } from "../../../utils/index";
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import moment from "moment";
import id from "date-fns/locale/id/index";
import Testimonial from "../../../common/testimonial";
import LinearProgress from "@material-ui/core/LinearProgress";

//replace this with data from response when available

const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  selectedPackage: {
    position: "relative",
    cursor: "pointer",
    borderRadius: "7px",
    backgroundColor: "black",
    color: "white",
    fontWeight: "400",
  },
  package: {
    position: "relative",
    cursor: "pointer",
  },
  selectedPoints: {
    paddingTop: "7px",
    paddingLeft: "16px",
    paddingRight: "49px",
    fontSize: "17px",
    color: "white",
    width: "141px",
  },
  points: {
    paddingTop: "7px",
    paddingLeft: "16px",
    paddingRight: "49px",
    fontSize: "17px",
    color: "black",
    width: "141px",
  },
}));

export default function EventsEditPanel({ data }) {
  const classes = useStyles();
  const selectedMenuDropdown = useSelector(
    (state) => state.eventsListing?.selectedEventsDropdown
  );
  const [currentPackage, setCurrentPackage] = useState(
    data?.packages[0] !== undefined ? data?.packages[0] : []
  );
  const cancelEventMessage = useSelector(
    (state) => state.eventsListing.cancelEventInputMessage
  );
  const [imageSelect, setImageSelect] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [popupSelect, setPopupSelect] = useState(false);
  const [cancelPopupSelect, setCancelPopupSelect] = useState(false);
  const [isOnchange, setIsOnchange] = useState(false);
  const [isGiftVoucher, setIsGiftVoucher] = useState(false);
  const [eventCancelData, setEventCancelData] = useState({});

  const [
    isCancelledEventErrorMsgValidation,
    setIsCancelledEventErrorMsgValidation,
  ] = useState(null);
  const [
    isEditEventTitleEmptyErrorValidation,
    setIsEditEventTitleEmptyErrorValidation,
  ] = useState(null);
  const [
    isEditEventTitleLengthErrorValidation,
    setIsEditEventTitleLengthErrorValidation,
  ] = useState(null);
  const [
    isEditEventMeetingIdErrorValidation,
    setIsEditEventMeetingIdErrorValidation,
  ] = useState(null);
  const [
    isEditEventScheduleErrorValidation,
    setIsEditEventScheduleErrorValidation,
  ] = useState(null);
  const [
    isEditValidTillErrorValidation,
    setIsEditValidTillErrorValidation,
  ] = useState(null);
  const [isEditPriceErrorValidation, setIsEditPriceErrorValidation] = useState(
    null
  );
  const [
    isEditPriceLengthErrorValidation,
    setIsEditPriceLengthErrorValidation,
  ] = useState(null);
  const [isEditImageErrorValidation, setIsEditImageErrorValidation] = useState(
    null
  );
  const [
    isEditThumbnailImageErrorValidation,
    setIsEditThumbnailImageErrorValidation,
  ] = useState(null);
  const [
    isEditBriefDesErrorValidation,
    setIsEditBriefDesErrorValidation,
  ] = useState(null);
  const [
    isEditBriefDesLengthErrorValidation,
    setIsEditBriefDesLengthErrorValidation,
  ] = useState(null);
  const [isEditDesErrorValidation, setIsEditDesErrorValidation] = useState(
    null
  );
  const [isEmptyResultsArrayBool, setIsEmptyResultsArrayBool] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Select section");
  const [anchorEl, setAnchorEl] = useState(null);
  const search = useSelector((state) => state.eventsListing.eventlistSearchVal);
  const [select, setSelect] = useState(null);
  const eventListingResults = useSelector(
    (state) => state.eventsListing.eventsList
  );
  const pageID = useSelector((state) => state.eventsListing.pageId);
  const eventList = useSelector((state) => state.eventsListing.eventsList.data);
  const eventListingDropDownVal = useSelector(
    (state) => state.eventsListing.eventsListingDropDownvalue
  );
  const [deletePackItem, setDeletePackItem] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const dispatch = useDispatch();
  const addImage = useSelector(
    (state) => state.eventsListing.allImagesEventsData
  );
  const apiGiftVoucher = data?.section?.gift_voucher;

  const editor = useRef(null);
  const [content, setContent] = useState(currentPackage?.description);

  const currentImages = addImage || [];
  const setThumbnail = addImage.find((img) => img.thumbnail === true);
  const imageInPopup = setThumbnail?.image;

  // useEffect(() => {
  //     dispatch(EventListingAction.getEventList());
  // }, []);

  useEffect(() => {
    if (deletePackItem) {
      let id = eventList.results[0].id;
      dispatch(EventListingAction.getEventsEditInfo(id));
    }
  }, [deletePackItem]);

  const [state, setState] = useState({
    section: null,
    event_id: null,
    corp_id: null,
    pack_id: null,
    title: "",
    pack_title: "",
    brief_description: "",
    note: null,
    images: [],
    packages: [],
    meeting_id: "",
    schedule_type: "",
    schedule_data: {},
    valid_till: "",
    price: null,
    best_seller: false,
    description: "",
  });

  useEffect(() => {
    if (eventListingResults.data.results.length === 0) {
      setIsEmptyResultsArrayBool(true);
    } else {
      setIsEmptyResultsArrayBool(false);
    }
  }, [eventListingResults]);

  useEffect(() => {
    setState({
      ...state,
      section: data?.section?.id,
      event_id: data?.id || null,
      title: data?.title,
      pack_id: currentPackage?.id,
      pack_title: currentPackage?.pack_title,
      brief_description: currentPackage.brief_description,
      note: currentPackage?.note || null,
      // images: currentPackage.images,
      images: currentPackage?.images || [],
      packages: data?.packages || [],
      meeting_id: currentPackage?.meeting_id,
      schedule_type: "specific_dates",
      schedule_data:
        {
          to: currentPackage?.schedule_data?.to,
          date: currentPackage?.schedule_data?.date,
          from: currentPackage?.schedule_data?.from,
        } || {},
      valid_till: currentPackage?.valid_till,
      price: currentPackage?.price,
      best_seller: currentPackage?.best_seller,
      workshop: currentPackage?.workshop,
      description: content,
    });
    setCurrentPackage((data?.packages?.length && data?.packages[0]) || []);
    setTimeout(() => {
      setIsEditDesErrorValidation(false);
      setIsEditBriefDesErrorValidation(false);
      setIsEditBriefDesLengthErrorValidation(false);
    }, 2000);
    setIsUpdating(false);
  }, [data]);

  const popupTitle = state?.title;

  useEffect(() => {
    dispatch(
      EventListingAction.handleAllEventsImages(currentPackage?.images || [])
    );
    setContent(currentPackage?.description);
  }, [currentPackage]);

  useEffect(() => {
    setCurrentPackage(() => ({
      ...currentPackage,
      description: content,
    }));
  }, [content]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevImageArray = usePrevious(addImage);

  useEffect(() => {
    if (prevImageArray?.length !== addImage.length) {
      console.log("differ");
    }
  }, [addImage]);

  const handleUpdatePackageImage = (data) => {
    setCurrentPackage({
      ...currentPackage,
      images: data,
    });
  };

  const handleChange = (key, value) => {
    setCurrentPackage(() => ({
      ...currentPackage,
      [key]: value.target.value,
    }));
  };

  //Changing Values of Input Fields
  const handleChangeValue = (key, value) => {
    setCurrentPackage(() => ({
      ...currentPackage,
      [key]: value.target.value,
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSelect = (event) => {
    dispatch(EventListingAction.selectEventsListDropdown());
    setSelect(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSelect = (item) => {
    setSectionId(item.id);
    setDropdownValue(item.name);
    setIsOnchange(true);
    if (item?.gift_voucher === true) {
      setIsGiftVoucher(true);
    } else setIsGiftVoucher(false);

    setSelect(null);
  };

  const handleBestSeller = (event) => {
    setCurrentPackage({
      ...currentPackage,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCancelSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
    dispatch(
      EventListingAction.getEventList(pageID, eventListingDropDownVal?.id)
    );
    dispatch(EventListingAction.editEventListItem(id));
  };

  const handleCancelEvent = (data) => {
    setCancelPopupSelect(true);
    setAnchorEl(null);
    setEventCancelData(data);
  };

  const eventsCanceledHandler = () => {
    setIsCancelledEventErrorMsgValidation(false);
    if (cancelEventMessage === null)
      setIsCancelledEventErrorMsgValidation(true);
    else {
      setDeletePackItem(true);
      dispatch(EventListingAction.setChosenPackIDBool(true));
      dispatch(
        EventListingAction.cancelEvent(
          eventCancelData.id,
          cancelEventMessage,
          handleCancelSuccess
        )
      );
      dispatch(EventListingAction.cancelEventInputVal(null));
      setCancelPopupSelect(false);
      // window.location.reload();
    }
    // dispatch(EventListingAction.getEventList());
    dispatch(EventListingAction.pageId(1));
  };

  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
    buttonsSM: [
      "source",
      "|",
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      // "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "video",
      "table",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "fullsize",
    ],
  };

  const setCancelSchedules = (days, nights) => {
    setCurrentPackage((prevstate) => {
      return { ...prevstate, schedule_data: { days: days, nights: nights } };
    });
    setCancelPopupSelect(false);
  };

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const setSchedules = (date, from, to) => {
    const startTimeString = moment(from).format("hh:mm a");
    const endTimeString = moment(to).format("hh:mm a");
    //console.log(from, to);
    setCurrentPackage((prevstate) => {
      return {
        ...prevstate,
        schedule_data: { to: to, date: date, from: from },
      };
    });
    setPopupSelect(false);
    console.log(currentPackage.schedule_data);
  };

  function mapDataforPost(packageData) {
    let tempImages = addImage.map((item, index) => {
      console.log("object");
      if (item.isUpdate) {
        return {
          name: item?.name,
          image: item.image,
          thumbnail: item.thumbnail,
          media_type: item.media_type,
          video: item.video,
          priority: index + 1,
        };
      } else {
        return item;
      }
    });
    const obj = {
      section: !isOnchange ? data?.section?.id : sectionId,
      event_id: data?.id,
      title: currentPackage.pack_title,
      pack_id: currentPackage?.id,
      pack_title: currentPackage["pack_title"],
      brief_description: currentPackage.brief_description,
      note: currentPackage?.note,
      images: tempImages,
      meeting_id: removeSpaces(currentPackage.meeting_id),
      schedule_type: "specific_dates",
      schedule_data:
        {
          to: currentPackage?.schedule_data?.to,
          date: currentPackage?.schedule_data?.date,
          from: currentPackage?.schedule_data?.from,
        } || {},
      valid_till: currentPackage?.valid_till,
      price: currentPackage?.price,
      best_seller: currentPackage?.best_seller,
      workshop: currentPackage?.workshop,
      description: content,
    };
    return obj;
  }

  const handleUpdate = (event) => {
    setIsEditEventTitleEmptyErrorValidation(false);
    setIsEditEventTitleLengthErrorValidation(false);
    setIsEditEventMeetingIdErrorValidation(false);
    setIsEditEventScheduleErrorValidation(false);
    setIsEditValidTillErrorValidation(false);
    setIsEditPriceErrorValidation(false);
    setIsEditPriceLengthErrorValidation(false);
    setIsEditImageErrorValidation(false);
    setIsEditThumbnailImageErrorValidation(false);
    setIsEditBriefDesErrorValidation(false);
    setIsEditBriefDesLengthErrorValidation(false);
    setIsEditDesErrorValidation(false);
    const postData = mapDataforPost(state);
    const htmltoText = (html) => {
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
      text = text.replace(/<\/div>/gi, "");
      text = text.replace(/<\/li>/gi, "");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "");
      text = text.replace(/<\/p>/gi, "");
      text = text.replace(/<br\s*[\/]?>/gi, "");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, "");
      text = text.replace(/ +/gi, "");
      text = text.replace(/\n+/gi, "");
      text = text.replace("&nbsp;", "");
      return text;
    };
    if (
      htmltoText(content)
        .replaceAll(" ", "")
        .trim().length === 0
    ) {
      setIsEditDesErrorValidation(true);
      return;
    }
    if (
      currentPackage.pack_title === "" ||
      currentPackage.pack_title === undefined
    )
      setIsEditEventTitleEmptyErrorValidation(true);
    else if (currentPackage.pack_title.length > 75)
      setIsEditEventTitleLengthErrorValidation(true);
    else if (currentPackage.meeting_id === "")
      setIsEditEventMeetingIdErrorValidation(true);
    else if (
      Object.entries(state.schedule_data).length === 0 ||
      state.schedule_data === {}
    )
      setIsEditEventScheduleErrorValidation(true);
    else if (currentPackage.valid_till === "")
      setIsEditValidTillErrorValidation(true);
    else if (currentPackage.price === "") setIsEditPriceErrorValidation(true);
    else if (currentPackage.price.length > 6)
      setIsEditPriceLengthErrorValidation(true);
    else if (addImage.length === 0) setIsEditImageErrorValidation(true);
    else if (!setThumbnail) setIsEditThumbnailImageErrorValidation(true);
    else if (currentPackage.brief_description === "")
      setIsEditBriefDesErrorValidation(true);
    else if (currentPackage.brief_description.length > 300)
      setIsEditBriefDesLengthErrorValidation(true);
    // else if (content === "" || content === undefined) setIsEditDesErrorValidation(true)
    else if (
      currentPackage.description === "" ||
      currentPackage.description === undefined
    )
      setIsEditDesErrorValidation(true);
    else {
      setIsUpdating(true);
      dispatch(
        EventListingAction.eventListUpdate(
          postData,
          refreshList,
          handleSuccess,
          data.id
        )
      );
    }
    //dispatch(EventListingAction.pageId(1))
    dispatch(EventListingAction.eventListsUpdateBool(true));
  };

  const handleSuccess = () => {
    setIsUpdating(false);
    enqueueSnackbar("Updated Successfully", {
      variant: "success",
    });
  };

  const refreshList = (id) => {
    if (search !== "") {
      dispatch(
        EventListingAction.eventListingSearch(
          search,
          eventListingDropDownVal?.id,
          pageID
        )
      );
    } else {
      dispatch(
        EventListingAction.getEventList(pageID, eventListingDropDownVal?.id)
      );
      dispatch(EventListingAction.getEventsEditInfo(id));
      dispatch(EventListingAction.eventSearchValue(""));
    }
  };

  const handleCloseDone = () => {
    setImageSelect(false);
  };

  const getModifiedHtml = (html) => {
    if (html.startsWith("<div")) {
      return html;
    } else {
      return `<div>${html}</div>`;
    }
  };

  return (
    <div>
      {isUpdating && (
        <div style={{ marginLeft: 10, marginBottom: -3 }}>
          <LinearProgress />
        </div>
      )}
      <div className="packageDetails-holder">
        <form>
          <div
            className="Edit-title-heading"
            style={{ marginBottom: "10px", borderBottom: "1px solid #d0d0d0" }}
          >
            <div className="form-options">
              <div>
                <h2>Editing</h2>
              </div>
              <div className="form-btns d-flex align-items-center">
                <div
                  className="section-dropdown"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  <Button
                    style={{ paddingRight: "32px" }}
                    aria-controls="section-menu"
                    aria-haspopup="true"
                    onClick={handleClickSelect}
                    // value={dropdownValue}
                    onChange={(event) => setDropdownValue(event.target.value)}
                  >
                    {isEmptyResultsArrayBool
                      ? ""
                      : !isOnchange
                      ? data?.section.name
                      : dropdownValue}
                    <img
                      src={ArrowDown}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: `${isEmptyResultsArrayBool ? "4px" : "12px"}`,
                      }}
                      onClick={() => {}}
                    />
                  </Button>
                  <Menu
                    id="section-menu"
                    anchorEl={select}
                    keepMounted
                    open={Boolean(select)}
                    onClose={() => setSelect(null)}
                  >
                    {selectedMenuDropdown &&
                      selectedMenuDropdown.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => handleCloseSelect(item)}
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-save"
                    disabled={isUpdating}
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
                <div className="">
                  <Button
                    className="menu-option"
                    aria-controls="list-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    id="list-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleCancelEvent(data)}>
                      Cancel this event
                    </MenuItem>
                  </Menu>
                  {cancelPopupSelect && (
                    <CancelEventPopup
                      openPopup={cancelPopupSelect}
                      closeModal={setCancelPopupSelect}
                      updatePopupData={setCancelSchedules}
                      imageInPopup={imageInPopup}
                      popupTitle={popupTitle}
                      isCancelMessage={isCancelledEventErrorMsgValidation}
                      eventsCanceledHandler={eventsCanceledHandler}
                      handleCloseDone={() => setCancelSchedules(false)}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* {!isGiftVoucher && apiGiftVoucher !== true ? (
                            <div style={{ display: "flex", alignItems: "center", width: '700px', overflowX: 'scroll' }}>
                            </div>
                        ) : null} */}

            <div
              className="form-group form-custom"
              style={{ position: "relative" }}
            >
              <label for="">Title</label>
              <input
                value={
                  isEmptyResultsArrayBool
                    ? ""
                    : currentPackage && currentPackage.pack_title
                }
                type="text"
                className="form-control"
                onChange={(value) => handleChangeValue("pack_title", value)}
              />
              {isEditEventTitleEmptyErrorValidation ? (
                <p style={{ color: "red" }}>Please add title</p>
              ) : isEditEventTitleLengthErrorValidation ? (
                <p style={{ color: "red" }}>
                  Title should be less than 75 characters
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          {/*Image UI Section*/}
          <div className="overflow--scroll">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: "100px"
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                  }}
                >
                  <img
                    src={
                      isEmptyResultsArrayBool
                        ? DefaultImage
                        : setThumbnail
                        ? setThumbnail.image
                        : DefaultImage
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    onClick={() => setImageSelect(true)}
                    style={{
                      color: "white",
                      backgroundColor: "rgb(82, 82, 82)",
                      position: "absolute",
                      bottom: 20,
                      left: 80,
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: "6px",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    {/* {addImage.length > 0
                                        ? addImage.length
                                        :  */}
                    {addImage.length} images / videos
                  </h6>
                </div>
                {isEditImageErrorValidation ? (
                  <p style={{ color: "red" }}>Please add atleast one image</p>
                ) : isEditThumbnailImageErrorValidation ? (
                  <p style={{ color: "red" }}>Please select thumbnail image</p>
                ) : (
                  ""
                )}
                <div
                  style={{ flex: 0.5, position: "relative", marginTop: "12px" }}
                  className="form-group form-custom"
                >
                  <label for="">Price</label>
                  <h6
                    style={{
                      position: "absolute",
                      left: "38px",
                      top: "53px",
                      fontWeight: "400",
                      opacity: 0.4,
                    }}
                  >
                    Rs
                  </h6>
                  <div
                    style={{
                      position: "absolute",
                      height: "17%",
                      paddingBottom: "37px",
                      width: "1px",
                      left: "73px",
                      backgroundColor: "rgb(202 194 194)",
                    }}
                  />
                  <input
                    style={{ paddingLeft: "75px" }}
                    value={
                      isEmptyResultsArrayBool
                        ? ""
                        : currentPackage && currentPackage.price
                    }
                    type="text"
                    min="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className="form-control"
                    onChange={(value) => handleChangeValue("price", value)}
                  />
                  {isEditPriceErrorValidation ? (
                    <p style={{ color: "red" }}>Please enter price</p>
                  ) : isEditPriceLengthErrorValidation ? (
                    <p style={{ color: "red" }}>
                      Price should be less than six digits
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{alignItems:"flex-end"}}>
                  <label for="" style={{ marginLeft:"17px"}}>Note</label>
                  <p style={{ marginLeft:"17px", fontSize:"12px", fontWeight:"400", lineHeight:"14px", opacity:"0.4",color:"#000000"}}>{currentPackage?.note != null ? currentPackage?.note.trim().length : 0}/100</p>
                  </div>
                  <textarea
                    style={{ height: "98px" }}
                    maxlength="100"
                    value={currentPackage.note!== null ? currentPackage.note :""}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      handleChangeValue("note", value)
                    }
                  />
                </div>
                <div
                  className="form-group form-custom"
                  style={{ height: "35%" }}
                >
                  <label for="">Brief Description</label>
                  <textarea
                    style={{ height: "70%" }}
                    value={
                      isEmptyResultsArrayBool
                        ? ""
                        : currentPackage?.brief_description
                    }
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      handleChangeValue("brief_description", value)
                    }
                  />
                  {isEditBriefDesErrorValidation ? (
                    <p style={{ color: "red" }}>
                      Please enter brief description
                    </p>
                  ) : isEditBriefDesLengthErrorValidation ? (
                    <p style={{ color: "red" }}>
                      Brief description should not be more than 300 characters
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Second Column */}

              <div style={{ flexDirection: "column", flex: "2" }}>
                <div
                  className="form-group form-custom"
                  style={{
                    position: "relative",
                    flex: 1,
                    marginLeft: 10,
                    marginTop: 0,
                  }}
                >
                  <label for="">Meeting ID</label>
                  <input
                    value={
                      isEmptyResultsArrayBool
                        ? ""
                        : removeSpaces(currentPackage?.meeting_id)
                    }
                    type="text"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(value) => handleChange("meeting_id", value)}
                  />
                  {isEditEventMeetingIdErrorValidation && (
                    <p style={{ color: "red" }}>
                      Please enter provided meeting id
                    </p>
                  )}
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                  className="form-group form-custom"
                >
                  <label for="">Schedule </label>
                  <input
                    value={
                      isEmptyResultsArrayBool
                        ? ""
                        : (currentPackage?.schedule_data?.date
                            ? `${currentPackage?.schedule_data?.date} , `
                            : "Date ") +
                          (currentPackage?.schedule_data?.from
                            ? `${tConvert(
                                currentPackage?.schedule_data?.from
                              )} - `
                            : "Time") +
                          (currentPackage?.schedule_data?.to
                            ? `${tConvert(currentPackage?.schedule_data?.to)} `
                            : "Time")
                    }
                    type="text"
                    className="form-control"
                  />
                  {isEditEventScheduleErrorValidation && (
                    <p style={{ color: "red" }}>Please enter schedule</p>
                  )}
                  <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "55px" }}
                    onClick={() => setPopupSelect(true)}
                  />

                  {popupSelect && (
                    <ShedulePopup
                      openPopup={popupSelect}
                      closeModal={setPopupSelect}
                      updatePopupData={setSchedules}
                      currentPackageData={currentPackage}
                    />
                  )}
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10 }}
                  className="form-group form-custom"
                >
                  <div>
                    <label for="">Valid till</label>
                    <input
                      value={
                        isEmptyResultsArrayBool
                          ? ""
                          : currentPackage && currentPackage.valid_till
                      }
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      onChange={(value) =>
                        handleChangeValue("valid_till", value)
                      }
                    />
                    {isEditValidTillErrorValidation && (
                      <p style={{ color: "red" }}>Please enter validity date</p>
                    )}
                  </div>
                  </div>
                  <div className="form-group toggle-container" style={{ marginTop: "10px", marginLeft: "10px"}}>
                    <label for="" style={{ fontSize: "12px", opacity: 0.4, fontWeight: 400 }}>Highlighting</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={
                                  isEmptyResultsArrayBool
                                    ? ""
                                    : currentPackage && currentPackage.workshop
                                }
                                size="small"
                                color="primary"
                                name="workshop"
                                onChange={handleBestSeller}
                              />
                            }
                            label="Is this a Workshop?"
                            labelPlacement="start"
                          />
                        </FormControl>
                      }
                    </div>
                  </div>
                
              </div>
            </div>

            <div className="form-group form-custom mt-3">
              <label for="">Description</label>
              <JoditEditor
                ref={editor}
                value={
                  isEmptyResultsArrayBool
                    ? ""
                    : currentPackage &&
                      getModifiedHtml(currentPackage.description)
                }
                config={configJoditEditor}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
              {isEditDesErrorValidation && (
                <p style={{ color: "red" }}>Please add some description</p>
              )}
            </div>
          </div>
        </form>
        <div className="form-group form-custom mt-3">
          <Testimonial module="event" id={currentPackage?.id} />
        </div>
      </div>
      {imageSelect && (
        <ImageMultiSelect
          title={currentPackage.pack_title}
          city={currentPackage?.location?.city}
          images={currentImages || []}
          open={imageSelect}
          toggleModal={setImageSelect}
          handleCloseDone={handleCloseDone}
          handleUpdatePackageImage={handleUpdatePackageImage}
          isAddNewEvent={false}
          isUpdateEvent={true}
          moduleType="event"
        />
      )}
    </div>
  );
}
