import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getExperienceSection } from '../../../actions/experienceSectionAction';
import { getExperienceListing, experienceListingDropDownVal, pageId, experienceSearchValue } from '../../../actions/experienceListingAction';
import {setAddPackage} from '../../../actions/corpListingAction'
const DropdownFilter = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const experienceSectionLists = useSelector(state => state.experienceSection.getExperienceSection);
  const pageID = useSelector((state) => state.experiencesListing.pageId);
  const dispatch = useDispatch();

  const handleClose = (item) => {
    setDropdownValue(item?.name);
    setAnchorEl(null);
    if(dropdownValue !== "Showing all"){
      props.setShowManageFeaturePackageButton(true)
    }
    else 
    props.setShowManageFeaturePackageButton(false)

    dispatch(getExperienceListing(1, item?.id ));
    dispatch(experienceListingDropDownVal(item));
    dispatch(pageId(1));
    dispatch(experienceSearchValue(""));
  };
console.log(dropdownValue, 'ssssss');
  const handleEventClick = (event) => {
    dispatch(getExperienceSection());
    
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={props?.disabled ? " " : handleEventClick} 
         className="dropdown-filter"
          style={{cursor:props?.disabled?'not-allowed':"pointer"}}
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {experienceSectionLists && experienceSectionLists.map(item => {
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  )
}

export default DropdownFilter