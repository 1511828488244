import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SpecificDatePopUp from '../experiences/listings/specificDatePopUp';
import { ImCross } from 'react-icons/im';
import '../../assets/css/partials/holidaylistingDaterange.scss';
import DateRangePopUp from '../experiences/listings/dateRangePopUp';
import DateRangePopUpBlock from '../experiences/listings/dateRangeblockPopup';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        height: '55%',
        width: '60%',
        backgroundColor: '#F5F5F5'
    },

}));

export default function BlockDatePopup({
    openPopup,
    closeModal,
    // updatePopupData,
    saveDateData,
    isHolidayListing,
    data,
    IsCorpAddNew,
    popupDateBlock

}) {
    let packageScheduleData =data?.restricted_booking_dates?.selected_dates
    const classes = useStyles();
    const [ isPackages, setIspackages ] = useState(false)
    const [ isDateRange, setIsDateRange ] = useState(false)
    const [ arrData, setArrData ] = useState(data?.restricted_booking_dates?.selected_dates|| [])
  
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [days, setDays] = useState( packageScheduleData?.days);
    const [nights, setNights] = useState( packageScheduleData?.nights);
    const [ isScheduleDateErrorValidation, setIsScheduleDateErrorValidation ] = useState(null)
    const [ dateRangeError, setDateRangeError ] = useState(null)
    const [ isScheduleNightDateErrorValidation, setIsScheduleNightDateErrorValidation ] = useState(null);
    const [isScheduleNightMatchErrorValidation, setIsScheduleNightMatchErrorValidation] = useState(false);
    const [isScheduleDateErrorValidationDayNight, setIsScheduleDateErrorValidationDayNight] = useState(false);
    const [dublicateData,setDublicateDate]=useState(null)
   let selectedDates = [];
   console.log(data, "data------")
    useEffect(() => {
        if(data?.schedule_type === "package"){
            setIspackages(true)
        }else if(IsCorpAddNew){
            setIspackages(true)
        }else{
            setIsDateRange(true)
        }
    }, [data]);

    const packageClickHandler = () => {
       setIspackages(true);
       setIsDateRange(false);
    };

    const dateRangeClickHandler = () => {
        setIsDateRange(true);
        setIspackages(false);
    };

    const addDateRange = (arrayData) => {
        setDublicateDate(null)
        const arr = [...arrData, arrayData]
        let resDate = arr.filter(d => {
            return moment(d.from, 'DD-MM-YYYY'); ;
        });
        resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
        if(resDate?.length===1){
            setArrData(resDate);
       
        }else{
            let joinedDate=arrayData.from+arrayData.to
            arrData.map(e=>{
                let joined=e.from+e.to
                if(joinedDate==joined){
                    setDublicateDate(arrayData.id)
                }
            })
            setArrData(resDate);
        }
       
    };
    useEffect(()=>{
        if(dublicateData){
            setArrData(arrData.filter(item => item.id !== dublicateData)); 
        }
    },[dublicateData])
    const deleteAllDate = () => {
        setArrData([])
    }

    const deleteData = (from,to) => {
        let deleteDateData = arrData.filter(item => `${item.from+item.to}` !== `${from+to}`);
        setArrData(deleteDateData);
     
        const newArray = arrData.map(({ from, to }) => ({ from, to }));
        // saveDateData(newArray);
     
    };

    const saveDate = () => {
        setDateRangeError(false)
        if(arrData.length === 0){
            saveDateData(null);
        }else{
            const newArray = arrData.map(({ from, to }) => ({ from, to }));
            saveDateData(newArray);
            setOpen(closeModal);
        }
    }
    const handleClickOpen = () => {
        setOpen(closeModal);
    };

    const handleClose = () => {
        setOpen(closeModal);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleDaysCount = (event) => {
        setIsScheduleDateErrorValidation(false)
        const days = event.target.value
        setDays(days)
        if(days <0)setIsScheduleDateErrorValidation(true);
    }
   
    const handleNightsCount = (event) => {
        setIsScheduleNightDateErrorValidation(false)
        const nights = event.target.value
        setNights(nights)
        if(nights < 0)setIsScheduleNightDateErrorValidation(true)
    }

    // const upDateDaysAndNightsHandler = () => {
    //     if(parseInt(days)-parseInt(nights)>1 || parseInt(days)-parseInt(nights)<-1){
    //         setIsScheduleNightMatchErrorValidation(true);
    //         return false;
    //     }
    //     if(!days || !nights){
    //         setIsScheduleDateErrorValidationDayNight(true);
    //         return false;
    //     }
    //     if(days <0)setIsScheduleDateErrorValidation(true);
    //     else if (nights < 0)setIsScheduleNightDateErrorValidation(true)
    //     else{
    //         updatePopupData(days, nights, "package")
    //     }
        
    // }
   
    return (
        <React.Fragment>
           
            <Dialog
                maxWidth={maxWidth}
                open={openPopup}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                PaperProps={{
                    style: {
                        height:"auto"
                    },
               }}
            >
                <DialogTitle id="max-width-dialog-title" style={{ marginLeft: '25px' }}> Block Date Range</DialogTitle>
                <DialogContent>
                    {isHolidayListing && 
                    <button className={`${isDateRange === true? "date-range-active": "date-range-btn"}` } onClick={dateRangeClickHandler}>
                    <span>DateRange</span>
                    </button>
                    }
                 
                   
                  
                    <>
                        {dateRangeError && <p style={{color:"red", margin: "9px 0px 0px 0px"}}>Please select date range </p>}
                        {dublicateData && <p style={{color:"red", margin: "9px 0px 0px 0px"}}>This daterange already added </p>}
                       <div className='row date-range_container' >
                           <div className="col-sm-4 date-range">

                           {popupDateBlock?<DateRangePopUpBlock
                            dateData={addDateRange}
                            isHolidayListing={true}
                           />
                           :
                             <DateRangePopUp
                                dateData={addDateRange}
                                isHolidayListing={true}
                             />
                }
                           </div>
                           <div className="col-sm-4" style={{marginLeft:"28%"}}>
                               <div className="schedule-container">
                                <div className="schedule-containet">
                               <span>Block Dates</span>
                                {arrData && arrData.map(item => {
                                return(
                                    <div>
                                        <p>{`${item.from} - ${item.to}`}</p><span className="cross-icon" onClick={() => deleteData(item.from,item.to)}><ImCross/></span> 
                                 
                                    </div>
                                )})}
                                </div>
                               <hr style={{width:"90%"}}/>
                               <div className="btn-container">
                                  <button className="delete-btn" onClick={deleteAllDate}>Delete</button> 
                                  <button className="save-btn" onClick={ saveDate}>Save</button> 
                               </div>
                               </div>
                           </div>
                       </div>
                       </>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
