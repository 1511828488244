import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { connect, useDispatch } from "react-redux";
import * as BookingAction from "../../../actions/bookingAction";
import "../../../assets/css/partials/enquiry.scss";
import Datefilter from "./DateFilter";
import DropdownFilter from "./DropdownFilter";
import PartnerBooked from "./BookedList";
// import PartnerBooked from "./Booking";
import Pagination from '@material-ui/lab/Pagination';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Spinner } from "react-bootstrap";

const { TabPane } = Tabs;

function mapStateToProps(state) {
  return {
    // bookingDetails: state.bookingListing.partnerBookings.booked?.data,
    bookingDetails:state.bookingListing.partnerBookings.data,
    confirmedBookings: state.bookingListing.partnerconfirmedBookings.data,
    cancelledBookings: state.bookingListing.partnercancelledBookings.data,
  };
}

function Bookings({ bookingDetails, confirmedBookings, cancelledBookings }) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("booked");
  const [page, setPage] = useState(1);
  const searchValue = useSelector((state) => state.bookingListing.searchValue);
  const cancelledSearchValue = useSelector((state) => state.bookingListing.corpCancelledSearchValue);
  const confirmedSearchValue = useSelector((state) => state.bookingListing.corpConfirmedSearchValue);
  const availabilitySearchValue = useSelector((state) => state.bookingListing.corpAvailabilitySearchValue);

  const followupData=useSelector((state) => state?.bookingListing.partnerfollowUP?.data);



  
  const bookedSearchTrueValue = useSelector((state) => state.bookingListing.bookedSearchTrueValue);
  const confirmedSearchTrueValue = useSelector((state) => state.bookingListing.confirmedSearchTrueValue);
  const cancelledSearchTrueValue = useSelector((state) => state.bookingListing.cancelledSearchTrueValue);
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  const getPageNum = useSelector((state) => state?.bookingListing?.page_num);
  const secId = null;
  const getStatus = useSelector((state) => state.bookingListing.getStatus);
  const startDate = useSelector((state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  function getSelectedTab(key) {
    setSelectedTab(key)
    setPage(1);
    dispatch(BookingAction.getStatus(key));
      
    // dispatch(BookingAction.searchBooking(searchValue, getStatus, secId, page));
  }

  const allSearchvalue = () => {
    if(getStatus === "booked") return searchValue
    else if(getStatus === "confirmed") return confirmedSearchValue
    else if(getStatus === "cancelled") return cancelledSearchValue
    else if(getStatus === "requested") return availabilitySearchValue
  };

  // useEffect(() => {
  //   if(bookedSearchTrueValue === true && searchValue == "" || 
  //     confirmedSearchTrueValue === true && confirmedSearchValue === "" || 
  //     cancelledSearchTrueValue === true && cancelledSearchValue === "")
  //   {
  //     dispatch(BookingAction.setPageNum(1));
  //   }
  // }, [
  //   bookedSearchTrueValue, 
  //   searchValue, confirmedSearchTrueValue, 
  //   confirmedSearchValue, 
  //   cancelledSearchTrueValue,
  //   cancelledSearchValue]
  // );
  
  useEffect(()=>{
    dispatch(BookingAction.searchPartnerTextvalue(''));
  }, [selectedTab]);
  useEffect(() => {
    console.log(cancelledBookings);
    if(searchValue !== "" || cancelledSearchValue !== "" || confirmedSearchValue !== ""){
      // dispatch(BookingAction.searchPartnerBooking(allSearchvalue(), getStatus, selectedPartner, page));
      dispatch(BookingAction.setPageNum(page));
    }else if(startDate !== null && endDate !== null){
      dispatch(BookingAction.searchPartnerBooking(allSearchvalue(), getStatus,selectedPartner, page));
      dispatch(BookingAction.setPageNum(page));
    }else {
      dispatch(BookingAction.getPartnerBookingList(page, 'booked', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'confirmed', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'cancelled', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'follow_up', selectedPartner));
      // dispatch(BookingAction.getBookingList(page, secId, true));
      // dispatch(BookingAction.getBookingConfirmedlist(page, secId, true));
      // dispatch(BookingAction.getCancelledBookinglist(page, secId, true));
      dispatch(BookingAction.setPageNum(page));
    }
  }, [page, selectedPartner, searchValue, cancelledSearchValue, confirmedSearchValue, startDate, endDate, selectedTab]);

  const totalBookings = bookingDetails?.total_results;
  const totalConfirmedBookings = confirmedBookings?.total_results;
  const totalCancelledBookings = cancelledBookings?.total_results;
  
  const overallEnquiries = totalBookings + totalConfirmedBookings + totalCancelledBookings;
  const refreshEnquiries = ()=>{
    if(searchValue !== "" || cancelledSearchValue !== "" || confirmedSearchValue !== ""){
      dispatch(BookingAction.searchPartnerBooking(allSearchvalue(), getStatus, selectedPartner, page));
      dispatch(BookingAction.setPageNum(page));
    }else if(startDate !== null && endDate !== null){
      dispatch(BookingAction.searchPartnerBooking(allSearchvalue(), getStatus,selectedPartner, page));
      dispatch(BookingAction.setPageNum(page));
    }else {
      dispatch(BookingAction.getPartnerBookingList(page, 'booked', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'confirmed', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'cancelled', selectedPartner));
      dispatch(BookingAction.getPartnerBookingList(page, 'follow_up', selectedPartner));
      dispatch(BookingAction.setPageNum(page));
    }
  };
  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter />
      </div>
      <div className="enquiry-tabs-holder">
       <div style={{display:'flex'}}>
          <p className="title-holder">{overallEnquiries} Partner Bookings</p>
          <div>
          {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Open Menu
          </Button> */}
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="dropdown-filter" >
            {selectedPartner ? selectedPartner : 'Showing All'}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={()=>{setSelectedPartner(null);handleClose()}}>Show All</MenuItem>
            <MenuItem onClick={()=>{setSelectedPartner('vantage-circle');handleClose()}}>Vantage Circle</MenuItem>
            
          </Menu>
          {/* <DropdownFilter status={selectedTab}/> */}
         </div>
       </div>
        <Tabs defaultActiveKey="requested" onChange={getSelectedTab}>
        
          <TabPane tab={totalBookings + " " + "Booked"} key="booked">
            <PartnerBooked bookingData={bookingDetails} status="booked" refreshEnquiries={refreshEnquiries} selectedPartner={selectedPartner} />
            <Pagination
            count={bookingDetails?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>

          <TabPane tab={followupData?.total_results+ " " + "Follow-Up"} key="follow_up">
            <PartnerBooked bookingData={followupData} status="follow_up" refreshEnquiries={refreshEnquiries} selectedPartner={selectedPartner} />
            <Pagination
            count={followupData?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
          <TabPane tab={totalConfirmedBookings + " " + "Confirmed"} key="confirmed">
            {/* <Confirmed confirmedBookings={confirmedBookings}  isPartner={true} /> */}
            <PartnerBooked bookingData={confirmedBookings}  status="confirmed" refreshEnquiries={refreshEnquiries} selectedPartner={selectedPartner}  />
            <Pagination
            count={confirmedBookings?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
          <TabPane tab={totalCancelledBookings + " " + "Cancelled"} key="cancelled">
            <PartnerBooked bookingData={cancelledBookings} status="cancelled" refreshEnquiries={refreshEnquiries} selectedPartner={selectedPartner} />
            <Pagination
            count={cancelledBookings?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => setPage(value)} />
          </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Bookings);
