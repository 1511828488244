
import React, {useState, useEffect} from 'react';
import MoreInfoModal from './moreInfoModal';


const Bookings = ({ data , amount, statusChangeHandler, statusValue}) => {

  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(false)
    window.scroll(0,0)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => document.body.style.overflow = 'unset';
  }, [showModal])

  return (
    <div>
      <div className="enquiry-card-holder">
        <div className="organization-details" style={{width: "300px"}}>
          <span style={{ color: "#2196F3", fontSize: "12px", display: "block" }}>#{data?.ref_id}</span>
          <h1>{data?.pack_title}</h1>
          <p style={{fontSize:"13px"}}>{amount}</p>
        </div>
        
          <div className="contact-info" style={{width: "170px"}}>
            <div className="title">
              <p>Booked By</p>
            </div>
            <div className="contact-details">
              <p >
                {data?.contact_name}
              </p>
              
            </div>
          </div>
          <div className="contact-info" style={{width: "170px"}}>
            <div className="title">
              <p>Email</p>
            </div>
            <div className="contact-details">
              <p >
                {data?.contact_email}
              </p>
              
            </div>
          </div>
        
        <div className="contact-info" style={{width: "170px"}}>
          <div className="title">
            <p>Contact number</p>
          </div>
          <div className="contact-details">
            <p>
              {data?.contact_number}
            </p>
          </div>
        </div>
        <div>
          <p
            style={{
              backgroundColor: "#E7E7E7",
              padding: "5px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer"
            }}
            onClick={() => setShowModal(true)}
          >More info</p>
          <select
              id="status-dropdown-select"
              className="status-dropdown"
              value={data?.booking_status}
              onChange={(event) => statusChangeHandler(event, data)}
            >
              { statusValue && statusValue.map(option => (
                <option className="greenColor" id="status-option" value={option.value} >{option.name}</option>
              ))}
            </select>
        </div>
        {<MoreInfoModal open={showModal} modalClose={handleClose} data={data}/>}
      </div>
    </div>
  );
}

export default Bookings;
