import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut
} from "../services/apiServices";
import { 
  FOLLOW_UP_HOLIDAY_LIST, 
  CONFIRMED_HOLIDAY_LIST,
  CLOSE_HOLIDAY_LIST, 
  HOLIDAY_REQUEST_STATUS_VALUE, 
  HOLIDAY_REQUEST_START_DATE, 
  HOLIDAY_REQUEST_END_DATE, 
  HOLIDAY_REQUEST_PAGE_ID,
  HOLIDAY_REQUEST_LIST,
  HOLIDAY_REQUEST_SECTION_NAME,
  CLOSE_REQUEST_INPUT_MESSAGE,
  CONFIRM_REQUEST_INPUT_AMOUNT,
  HOLIDAY_REQUEST_ALL_DATA,
  HOLIDAY_REQ_SEARCH_VALUE,
  HOLIDAY_FOLLOWUP_SEARCH_VALUE,
  HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST,
  HOLIDAY_CLOSED_SEARCH_VALUE,
  IS_DATA_LOADING
} from '../actions/types';

export const getHolidayRequestList = (page, secId) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      const body = {
        "next_page_id":page,
        sec_id:secId,
        "status":"request",
        "page_size":50
      }
      axiosPostWithToken(`${API.GET_HOLIDAY_REQUEST_LIST}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: HOLIDAY_REQUEST_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: HOLIDAY_REQUEST_ALL_DATA,
              payload: res.data,
            });
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const followUpHolidayList = (page,secId) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const pageNum = getState().holidayRequest.page;
      const body = {
        "next_page_id":page,
        "status":"followup",
        sec_id:secId,
        "page_size":50
      }
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_HOLIDAY_REQUEST_LIST}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: FOLLOW_UP_HOLIDAY_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const confirmedHolidayList = (page,secId) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const pageNum = getState().holidayRequest.page;
      const body = {
        "next_page_id":page,
        "status":"confirmed",
        sec_id:secId,
        "page_size":50
      }
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_HOLIDAY_REQUEST_LIST}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: CONFIRMED_HOLIDAY_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const closedHolidayList = (page, secId) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const pageNum = getState().holidayRequest.page;
      const body = {
        "next_page_id":page,
        "sec_id":secId,
        "status":"closed",
        "page_size":50
      }
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_HOLIDAY_REQUEST_LIST}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: CLOSE_HOLIDAY_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const holidayRequestsSearch = (pageId,status, searchTerm, startDate, endDate, section) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        "next_page_id": pageId,
        "status": status,
        "search_term": searchTerm,
        "from": startDate,
        "to": endDate,
        "section":section,
        "page_size":50,
      }
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_HOLIDAY_REQUEST_SEARCH}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            if(status === "request"){
              dispatch({
                type: HOLIDAY_REQUEST_LIST,
                payload: res.data.data,
              });
            }
          
            else if(status === "followup"){
              dispatch({
                type: FOLLOW_UP_HOLIDAY_LIST,
                payload: res.data.data,
              });
            }
            else if(status === "confirmed"){
              dispatch({
                type:CONFIRMED_HOLIDAY_LIST,
                payload: res.data.data,
              });
            }
            else if(status === "closed"){
              dispatch({
                type: CLOSE_HOLIDAY_LIST,
                payload: res.data.data,
              });
            }
            else{
              dispatch({
                type: HOLIDAY_REQUEST_LIST,
                payload: res.data.data,
              });
            }
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
};

export const holidayRequestStatusUpdate = (status, id,requestType, message, refreshData) => {
 
  return async (dispatch, getState) => {
  
    const token = getState().auth.token;
    const body = {
      "status":status,
      "request_type":requestType,
      "admin_message":message
    }
    axiosPut(`${API.HOLIDAY_REQUEST_STATUS_UPDATE}${id}/`,body, token)
      .then((res) => {
        if (res.data.status_code === 200) {


          refreshData()
         
        }
      })
      .catch((err) => {
      
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const holidayRequestStatusUpdateConfirm = (status, id,requestType,message,amount, refreshData) => {
 
  return async (dispatch, getState) => {
  
    const token = getState().auth.token;
    const body = {
      "status":status,
      "request_type":requestType,
      "price":parseInt(amount),
     "admin_message":message
    }
    axiosPut(`${API.HOLIDAY_REQUEST_STATUS_UPDATE}${id}/`,body, token)
      .then((res) => {
        if (res.data.status_code === 200) {

          refreshData()
       
        }
      })
      .catch((err) => {
   
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const myHolidayRequestStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REQUEST_START_DATE,
      payload: date,
    });
  };
};
  
export const myHolidayRequestEndDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REQUEST_END_DATE,
      payload: date,
    });
  };
};

export const pageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type:HOLIDAY_REQUEST_PAGE_ID,
      payload: page,
    })
  
  };
};

export const getHolidaysRequestStatus = (statusVal) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REQUEST_STATUS_VALUE,
      payload: statusVal,
    });
  };
};

export const holidayRequestSectionName = (secName) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REQUEST_SECTION_NAME,
      payload: secName,
    });
  };
};

export const  closeRequestInputVal = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE_REQUEST_INPUT_MESSAGE,
      payload: value,
    });
  }
};

export const  confirmRequestInputVal = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CONFIRM_REQUEST_INPUT_AMOUNT,
      payload: value,
    });
  }
};

export const  holidayReqSearchValue = (value, status) => {
  return async (dispatch) => {
    if(status === "request"){
      dispatch({
        type: HOLIDAY_REQ_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "followup"){
      dispatch({
        type: HOLIDAY_FOLLOWUP_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "confirmed"){
      dispatch({
        type: HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST,
        payload: value,
      });
    }
    else if(status === "closed"){
      dispatch({
        type: HOLIDAY_CLOSED_SEARCH_VALUE,
        payload: value,
      });
    }
  }
}