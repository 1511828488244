
import { 
    GET_HOLIDAY_BOOKING_LIST,
    HOLIDAY_BOOKING_PAGE_ID, 
    HOLIDAY_BOOKING_START_DATE,
    HOLIDAY_BOOKING_END_DATE ,
    GET_HOLIDAY_CONFIRMED_TOTAL, 
    GET_HOLIDAY_BOOKED_TOTAL,
    GET_HOLIDAY_CANCELLED_TOTAL,
    HOLIDAY_STATUS_VALUE,
    BOOKINGS_SECTION_ID,
    GET_HOLIDAY_CONFIRMED_LIST,
    GET_HOLIDAY_CANCELLED_LIST,
    HOLIDAY_BOOKING_NEXT_PAGE_ID,
    CONFIRM_NEXT_PAGE_ID,
    CANCELLED_NEXT_PAGE_ID,
    CONFIRM_CURRENT_PAGE_ID,
    BOOKING_CURRENT_PAGE_ID,
    CANCELLED_CURRENT_PAGE_ID,
    GET_HOLIDAY_REFUND_TOTAL,
    GET_HOLIDAY_REFUND_LIST,
    HOLIDAY_REFUND_NEXT_PAGE_ID,
    REFUND_CURRENT_PAGE_ID,
    HOLIDAY_BOOKING_SEARCH_VALUE,
    HOLIDAY_FOLLOWUP_SEARCH_VALUE,
    HOLIDAY_CONFIRMED_SEARCH_VALUE,
    HOLIDAY_REFUND_SEARCH_VALUE,
    HOLIDAY_CANCELLED_SEARCH_VALUE,
    HOLIDAY_AVAILABILITY_REQUEST_LIST,
    HOLIDAY_AVAILABILITY_SEARCH_VALUE,
    GET_HOLIDAY_FOLLOW_UP_TOTAL,
    GET_HOLIDAY_FOLLOW_UP_LIST,
    HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID,
    FOLLOW_UP_CURRENT_PAGE_ID
} from '../actions/types';

const initialState = {
    holidayBooking:[],
    availabilityReqList:[],
    holidayConfirmed:[],
    holidayCancelled:[],
    holidayFollowup:[],
    refundBooking:[],

    page:1,
    holidayBookingStartDate:null,
    holidayookingEndDate:null,
    holidayStatusVal: 'requested',
    bookingsSecId: null,

    holidayConfirmedDataLength: 0,
    holidayBookingDataLength: 0,
    holidayCancelledgDataLength: 0,
    holidayFollowupgDataLength: 0,
    holidayRefundDataLength: 0,


   

    holidayBookingNxtPgeId:null,
    holidayConfirmNxtPgeId:null,
    holidayCancelledNxtPgeId:null,
    holidayFollowupNxtPgeId:null,
    holidayRefundNxtPgeId:null,
    


    holidayConfirmCurrentPgeId:null,
    holidayBookingCurrentPgeId:null,
    holidayCancelledCurrentPgeId:null,
    holidayFollowupCurrentPgeId:null,
    holidayRefundCurrentPgeId:null,

    holidayBookingSearchVal:"",
    holidayConfirmedSearchVal:"",
    holidayCancelledSearchVal:"",
    holidayAvailabilitySearchVal:"",
    holidayFollowupSearchVal:"",
    holidayRefundSearchVal:"",

   

    
};


const holidayBookingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_HOLIDAY_BOOKING_LIST: {
            return {
                ...state,
                holidayBooking: payload,
            };
        }
        case GET_HOLIDAY_CONFIRMED_LIST: {
            return {
                ...state,
                holidayConfirmed: payload,
            };
        }
   
     
     
        case GET_HOLIDAY_CANCELLED_LIST: {
            return {
                ...state,
                holidayCancelled: payload,
            };
        }

        case  GET_HOLIDAY_REFUND_LIST: {
            return {
                ...state,
                refundBooking: payload,
            };
        }
        case HOLIDAY_AVAILABILITY_REQUEST_LIST: {
            return {
                ...state,
                availabilityReqList: payload,
            };
        }
        case GET_HOLIDAY_FOLLOW_UP_LIST: {
            return {
                ...state,
                holidayFollowup: payload,
            };
        }


        case HOLIDAY_BOOKING_PAGE_ID: {
            return {
                ...state,
                page: payload,
            };
        }
        case HOLIDAY_BOOKING_START_DATE: {
            return {
                ...state,
                holidayBookingStartDate: payload,
            };
        }
        case HOLIDAY_BOOKING_END_DATE: {
            return {
                ...state,
                holidayookingEndDate: payload,
            };
        }

        
        case GET_HOLIDAY_CONFIRMED_TOTAL: {
            return {
                ...state,
                holidayConfirmedDataLength: payload,
            };
        }
        case GET_HOLIDAY_FOLLOW_UP_TOTAL: {
            return {
                ...state,
                holidayFollowupgDataLength: payload,
            };
        }
        case GET_HOLIDAY_BOOKED_TOTAL: {
            return {
                ...state,
                holidayBookingDataLength: payload,
            };
        }
        case GET_HOLIDAY_CANCELLED_TOTAL: {
            return {
                ...state,
                holidayCancelledDataLength: payload,
            };
        }

        case GET_HOLIDAY_REFUND_TOTAL: {
            return {
                ...state,
                holidayRefundDataLength: payload,
            };
        }


        case HOLIDAY_STATUS_VALUE: {
            return {
                ...state,
                holidayStatusVal: payload,
            };
        }
        case BOOKINGS_SECTION_ID: {
            return {
                ...state,
                bookingsSecId: payload,
            };
        }


        case HOLIDAY_BOOKING_NEXT_PAGE_ID: {
            return {
                ...state,
                holidayBookingNxtPgeId: payload,
            };
        }
        case HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID: {
            return {
                ...state,
                holidayFollowupNxtPgeId: payload,
            };
        }
        case CONFIRM_NEXT_PAGE_ID: {
            return {
                ...state,
                holidayConfirmNxtPgeId: payload,
            };
        }
        case CANCELLED_NEXT_PAGE_ID: {
            return {
                ...state,
                holidayCancelledNxtPgeId: payload,
            };
        }
        case HOLIDAY_REFUND_NEXT_PAGE_ID: {
            return {
                ...state,
                holidayRefundNxtPgeId: payload,
            };
        }


        case CONFIRM_CURRENT_PAGE_ID: {
            return {
                ...state,
                holidayConfirmCurrentPgeId: payload,
            };
        }

        case FOLLOW_UP_CURRENT_PAGE_ID: {
            return {
                ...state,
                holidayFollowupCurrentPgeId: payload,
            };
        }

        case BOOKING_CURRENT_PAGE_ID: {
            return {
                ...state,
                holidayBookingCurrentPgeId: payload,
            };
        }
        case CANCELLED_CURRENT_PAGE_ID: {
            return {
                ...state,
                holidayCancelledCurrentPgeId: payload,
            };
        }


        case REFUND_CURRENT_PAGE_ID: {
            return {
                ...state,
                holidayRefundCurrentPgeId: payload,
            };
        }


        case HOLIDAY_BOOKING_SEARCH_VALUE: {
            return {
                ...state,
                holidayBookingSearchVal: payload,
            };
        }

        case HOLIDAY_FOLLOWUP_SEARCH_VALUE: {
            return {
                ...state,
                holidayFollowupSearchVal: payload,
            };
        }

        case HOLIDAY_CONFIRMED_SEARCH_VALUE: {
            return {
                ...state,
                holidayConfirmedSearchVal: payload,
            };
        }
        case HOLIDAY_REFUND_SEARCH_VALUE: {
            return {
                ...state,
                holidayRefundSearchVal: payload,
            };
        }
        
        case HOLIDAY_CANCELLED_SEARCH_VALUE: {
            return {
                ...state,
                holidayCancelledSearchVal: payload,
            };
        }
        case HOLIDAY_AVAILABILITY_SEARCH_VALUE: {
            return {
                ...state,
                holidayAvailabilitySearchVal: payload,
            };
        }
          
        default:
            return state;
    }
};

export default holidayBookingReducer;