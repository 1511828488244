import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { divide } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default class DateRangePopUp extends React.Component {
    static defaultProps = {
        numberOfMonths: 1,
    };
    
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.state = this.getInitialState();
        
    }
    
    getInitialState() {
        return {
            from: undefined,
            to: undefined,
            dateArray: []
        };
    }

    handleDayClick(day) {
        const date = moment(day).format('YYYY-MM-DD')
        const today = moment().format("YYYY-MM-DD")
        if(moment(date).isBefore(today)){
            console.log("past", "---this")
        }else{
            const range = DateUtils.addDayToRange(day, this.state);
            this.setState(range);
        }
    }


    handleAddClick() {
        const dateObject = {
        id: uuidv4(),
        from: moment(this.state.from).format("DD-MM-YYYY"),
        to: moment(this.state.to).format("DD-MM-YYYY"),
        // from: this.state.from,
        // to: this.state.to,
    };
        this.state.dateArray.push(dateObject);
    if(this.props.isHolidayListing) this.props.dateData(dateObject)
    else {
      this.props.addArray(dateObject)
    }
    this.handleResetClick()
    }

    handleResetClick() {
        this.setState(this.getInitialState());
    }
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (<>
            <div className="RangeExample" style={{ position: 'relative' }} >

                <DayPicker
                    className="Selectable"
                    numberOfMonths={1}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    disabledDays= {{before: new Date()}} 
                />
                <Helmet>
                    <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
                </Helmet>
                <div style={{ position: 'absolute', top: '-5px', left: '90%', width: '74%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }} className="form-group form-custom">
                    <div >
                        <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>
                            <p onClick={this.handleResetClick} style={{ textAlign: 'center', fontSize: '14px', fontWeight: '400', color: 'rgb(33, 150, 243)', opacity: '1', marginBottom: '12px', cursor: 'pointer' }}>Clear</p>
                            {!from && !to && 'Please select the first day.'}
                            {from && !to && 'Please select the last day.'}
                            {from &&
                                to &&
                                `\n ${moment(from).format("Do MMM")} -
                                   ${moment(to).format("Do MMM")}`}{' '}
                                {from && to && (<>
                                <div><button style={{ marginTop: '10px', height: '38px', width: '190px', backgroundColor: '#b0b0b0', color: 'black', borderRadius: '5px', border: 'none' }} className="link" onClick={this.handleAddClick}>
                                    Add
                                </button></div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

