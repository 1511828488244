import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
  axiosDelete,
  axiosMediaUpload,
} from "../services/apiServices";

import {
    PARTNERS_LIST,
    SET_PARTNER_DETAILS,
    UPDATE_PARTNER_DETAILS,
    REGENERATE_TOKEN,
    PARTNER_SEARCH_TERM,
    PARTNER_LOGO,
    IS_CORP_LIST_DATA_LOADING,
    IS_CORP_DATA_LOADING
    
  } from "./types";


  export const partnersList = (page) => {
    return async (dispatch, getState) => {
      const body = {
        "next_page_id": page
      };
      dispatch({
        type: IS_CORP_LIST_DATA_LOADING,
        payload: true,
      });
      const token = getState().auth.token;
      axiosPostWithToken(`${API.PARTNERS_LIST}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: PARTNERS_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: IS_CORP_LIST_DATA_LOADING,
              payload: false,
            });
            // loader();
                }
        })
        .catch((err) => {
        //   loader();
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
        });
    };
  };

  export const getPartnerInfo = (id) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      dispatch({
        type: IS_CORP_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.PARTNER_INFO}${id}/`,{}, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: SET_PARTNER_DETAILS,
              payload: res.data.data,
            });
            dispatch({
              type: IS_CORP_DATA_LOADING,
              payload: false,
            });
          } 
        })
        .catch((err) => {
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        });
    };
  };

  export const handleAddNewPartner = (
    data,
    refreshList,
    handleError,
    handleSuccess
  ) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      axiosPostWithToken(`${API.CREATE_PARTNER}`, data, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            refreshList();
            handleSuccess(res.data.data.message);
          }
          if (res.data.status_code === 400) {
            handleError(res.data.data.message);
          }
        })
        .catch((err) => {
          
        });
    };
  };

  export const updatePartnerDetails = (data, handleSuccess, handleError, refreshList) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        "name": data?.name,
        "location": data?.location,
        "logo": data?.logo,
        "active": data?.active
      }
      axiosPut(`${API.UPDATE_PARTNER}${data.id}/`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            console.log(res.data.status_code === 200)
            handleSuccess();
            refreshList();
            dispatch({
              type: UPDATE_PARTNER_DETAILS,
              payload: res.data,
            });
          } else if (res.data.status_code === 400) {
            // refreshList();
            handleError(res.data.data.message);
            
          }
          
        })
        .catch((err) => {
          //   loader();
          });
    };
  };

  export const handleRegenerateToken = (id, setToken) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      // const body = data;
      axiosPut(`${API.REGENERATE_TOKEN}${id}/`,{}, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            // handleSuccess();
            dispatch({
              type: REGENERATE_TOKEN,
              payload: res.data.data,
            });
            setToken(res.data.data.token)
          } else if (res.data.status_code === 400) {
            // refreshList();
            // handleError(res.data.data.message);
            
          }
          
        })
        .catch((err) => console.log("errorData", err.response));
    };
  };

  export const setPartnerSearchTerm = (value) => {
    return async (dispatch) => {
      dispatch({
        type: PARTNER_SEARCH_TERM,
        payload: value,
      });
    }
  }

  export const searchPartners = (value) => {
    return async (dispatch, getState) => {
      const body = {
        "search_term": value,
        "next_page_id":1
    }
    dispatch({
      type: IS_CORP_LIST_DATA_LOADING,
      payload: true,
    });
      const token = getState().auth.token;
      axiosPostWithToken(`${API.SEARCH_PARTNER}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: PARTNERS_LIST,
              payload: res.data.data,
            });
            dispatch({
              type: IS_CORP_LIST_DATA_LOADING,
              payload: false,
            });
            // loader();
                }
        })
        .catch((err) => {
        //   loader();
        dispatch({
          type: IS_CORP_LIST_DATA_LOADING,
          payload: false,
        });
        });
    };
  };

  export const uploadPartnerLogo = (file, addImage) => {
    return async (dispatch, getState) => {
      const entries = new FormData();
      entries.append("media", file);
  
      const token = getState().auth.token;
  
      axiosMediaUpload(API.IMAGE_UPLOAD, "image", file, token)
        .then((res) => {
          dispatch({
            type: PARTNER_LOGO,
            payload: res.data,
          });
          addImage(res.data.data);
        })
        .catch((err) => {
          console.log("ERROR_ALERT", err);
        });
    };
  };