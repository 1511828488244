import { PageHeader } from "antd";
import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress,
  axiosPatchWithToken,
  axiosDeleteWithBody
} from "../services/apiServices";

import {
  GET_EXPERIENCES_LIST,
  ADD_EXPERIENCES_IMAGE,
  UPDATE_EXPERIENCES,
  EXPERIENCES_LISTING_PAGE_ID,
  SEARCH_EXPERIENCES_LIST_DATA,
  ALL_EXPERIENCES_IMAGES_DATA,
  GET_SELECT_EXPERIENCES_DROPDOWN,
  EXPERIENCE_LISTING_DROP_DOWN_VALUE,
  POST_EXPERIENCES_LISTING_DATA, 
  GET_EXPERIENCES_PACKAGE_INFO,
  EXPERIENCES_PACKAGE_DELETE,
  EXPERIENCES_DELETE,
  EXPERIENCES_IMAGE_DELETE,
  EXPERIENCES_LISTING_UPDATE_BOOL,
  EXPERIENCE_LISTING_SEARCH_VALUE,
  SET_SELECTED_EXPERIENCE_CHOSEN_ID,
  TESTIMONIAL_EXP_LIST,
  GET_EXPERIENCES_FEATURED_LIST,
  EXPERIENCE_FEATURED_UPDATE,
  EXPERIENCES_PACKLIST_DOWNLOAD,
  EXPERIENCE_PACK_REVIEWS_LIST,
  IS_EXP_LIST_DATA_LOADING,
  IS_EXP_DATA_LOADING,
  IS_DATA_LOADING
}from "./types"

export const SET_EDIT_EXPERIENCES_LIST_DETAILS = "SET_EDIT_EXPERIENCE_LIST_DETAILS";
export const SET_ADD_EXPERIENCES_PACKAGE = "SET_ADD_EXPERIENCES_PACKAGE";
export const SET_ADD_NEW_PACKAGE = "SET_ADD_NEW_PACKAGE";
export const SELECTED_DAYS = "SELECTED_DAYS";
export const SELECTED_DATES = "SELECTED_DATES";
export const SELECTED_RANGE = "SELECTED_RANGE"

export const getExperienceListing = (page, secId, loader) => {
  return async (dispatch, getState) => {
    
    const body = {
      next_page_id: page,
      sec_id: secId
    };
    dispatch({
      type: IS_EXP_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.GET_EXPERIENCES_LISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_EXPERIENCES_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_EXP_LIST_DATA_LOADING,
            payload: false,
          });
          loader();
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch({
          type: IS_EXP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};
export const getExperienceFilteredList = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id: "1",
      sec_id: id
    }
    axiosPostWithToken(`${API.GET_EXPERIENCES_LISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_EXPERIENCES_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};


export const editExperienceListItem = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_EXPERIENCES_LIST_DETAILS,
      payload: data,
    });
  };
};

export const setAddPackage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ADD_EXPERIENCES_PACKAGE,
      payload: value,
    });
    // showData()
  };
};


export const imageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;

    axiosMediaUpload(API.EXPERIENCES_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        console.log("RESPO IMAGE", res.data);
        dispatch({
          type: ADD_EXPERIENCES_IMAGE,
          payload: res.data,
        });
        addImage(res.data.data);
        console.log("RESP2", res.data.data);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const videoUpload = (file, addVideo, obj, newArr, getUploadProgress, id) => {
  console.log(getUploadProgress);
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.EXPERIENCES_VIDEO_UPLOAD, "image", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const listDataUpdate = (data, loader, handleSuccess, urlId, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = data;

    axiosPut(`${API.UPDATE_EXPERIENCES}${urlId}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          // loader()
          loader(data.experience_id);
          handleSuccess()
          
          dispatch({
            type: GET_EXPERIENCES_LIST,
            payload: res.data,
          });
          dispatch({
            type: UPDATE_EXPERIENCES,
            payload: res.data,
          });
        } else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          loader(data.experience_id);
        }
      }
    );
  };
};

export const searchExperienceListing = (search, secId, page) => {
  return async (dispatch, getState) => {
    const secId = getState().experiencesListing.experienceListingDropDownvalue?.id
    const body = {
      search_term: search,
      "next_page_id":page,
      sec_id:secId
    };
    dispatch({
      type: IS_EXP_LIST_DATA_LOADING,
      payload: true,
    });
    const token = getState().auth.token;
    axiosPostWithToken(`${API.EXPERIENCES_SEARCH_LISTING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            // type: SEARCH_EXPERIENCES_LIST_DATA,
            type: GET_EXPERIENCES_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_EXP_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch({
          type: IS_EXP_LIST_DATA_LOADING,
          payload: false,
        });
      });
  };
};
export const handleAddNewData = (data, refreshList, handleError, loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.POST_EXPERIENCES_LISTING}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList();
        }
        else if (res.data.status_code === 400) {
          handleError(res.data.data.message)
          // loader(data.experience_id);
          loader();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAddNewPackage = (data, refreshList) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.POST_EXPERIENCES_PACKAGE}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshList(data.experience_id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const updateFeaturedList = (data,id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPatchWithToken(`${API.EXPERIENCE_FEATURED_UPDATE + id + "/"}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleAllImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ALL_EXPERIENCES_IMAGES_DATA,
      payload: data,
    });
  };
};
export const selectDropdown = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.EXPERIENCES_SELECT_DROPDOWN}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SELECT_EXPERIENCES_DROPDOWN,
          payload: res.data.data,
        });
      }
    });
  };
};

export const deletePackage = (id, packId, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.EXPERIENCES_PACKAGE_DELETE}${id.id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        handleSuccess(packId)
        dispatch({
          type: EXPERIENCES_PACKAGE_DELETE,
          payload: res.data.data,
        });

      }
    });
  };
};

export const imageDelete = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.EXPERIENCES_IMAGE_DELETE}${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCES_IMAGE_DELETE,
          payload: res.data.data,
        });
        // onDelete();
      }
    });
  };
};

export const deleteExperience = (id, packId,loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.EXPERIENCES_DELETE}${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        loader(packId);
        dispatch({
          type: EXPERIENCES_DELETE,
          payload: res.data.data,
        });
        // window.location.reload();
      }
    });
  };
};

// export const addExperienceSectionImages = (
//   submitData,
//   refreshData,
//   handleSuccess,
//   handleError
// ) => {
//   return async (dispatch, getState) => {
//     const body = {
//       name: submitData.section,
//       image: submitData.imageUrl,
//       priority: submitData.priority,
//       gift_voucher: submitData.gift_voucher
//     };
//     const token = getState().auth.token;

//     axiosPostWithToken(`${API.ADD_SECTION_IMAGES}`, body, token)
//       .then((res) => {
//         if (res.data.status_code === 201) {
//           dispatch({
//             type: ADD_CORP_SECTION_IMAGES,
//             payload: res.data,
//           });
//           refreshData();
//           handleSuccess();
//         } else if (res.data.status_code === 400) {
//           handleError(res.data.data.name);
//         }
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   };
// };

export const getPackageInfo = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_EXP_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.EXPERIENCES_PACKAGE_INFO + id + "/"}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_EXPERIENCES_PACKAGE_INFO,
          payload: res.data.data,
        });
        dispatch({
          type: IS_EXP_DATA_LOADING,
          payload: false,
        });
      }else{
        dispatch({
          type: IS_EXP_DATA_LOADING,
          payload: false,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: IS_EXP_DATA_LOADING,
        payload: false,
      });
   });
  };
};
export const getFeaturedList = (id) => {
  const body = {
    "featuredList": [
      {"id": 168,  "featured_number": 11
      },
      {"id": 166,  "featured_number": 10
      }
    ]
  };
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.GET_EXPERIENCES_FEATURED_LIST + id }`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_EXPERIENCES_FEATURED_LIST,
          payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
    });
  };
};

export const setSelectedDay = (array) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_DAYS,
      payload: array
    });
  }
}

export const setSelectedDate = (array) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_DATES,
      payload: array
    });
  }
}

export const setSelectedRange = (array) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_RANGE,
      payload: array
    });
  }
}

export const pageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type:EXPERIENCES_LISTING_PAGE_ID,
      payload: page,
    })
  
  };
};

export const experienceListingDropDownVal = (data) => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCE_LISTING_DROP_DOWN_VALUE,
      payload: data,
    });
    // showData()
  };
};

export const experienceListsUpdateBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCES_LISTING_UPDATE_BOOL,
      payload: bool,
    });
  };
};

export const experienceSearchValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCE_LISTING_SEARCH_VALUE,
      payload: value,
    });
  };
};

export const setChosenPackIDBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type:SET_SELECTED_EXPERIENCE_CHOSEN_ID,
      payload: bool,
    })

  };
};

////testimonial/////

export const getExpTestimonials = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_EXP_TESTIMONIAL_LIST}${id}/`, token)
    .then((res) => {
        if (res.data.status_code === 200) {
            dispatch({
                type: TESTIMONIAL_EXP_LIST,
                payload: res.data.data,
            });
        }
    })
    .catch((err) => {
       console.log(err);
    });
  }
};

export const expPostNewTestimonial = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      main_pack_id:data.id,
      guest_name:data.authorName,
      guest_image:data.data!==null?data.data.url:'',
      message: data.authorDesc
    }
    axiosPostWithToken(`${API.EXP_POST_TESTIMONIAL}`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
};

export const updateExpTestimonial = (data, tid, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "guest_name": data.guest_name,
      "guest_image": data.guest_image,
      "message": data.message,
      "main_pack_id":data.id
  };
    axiosPut(`${API.UPDATE_EXP_TESTIMONIAL}${tid}/`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
};

export const deleteExpTestimonials = (id, eventId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body={
      "main_pack_id":eventId
    }
    axiosDeleteWithBody(`${API.DELETE_EXP_TESTIMONIAL}${id}`, body,token)
    .then((res) => {
      
        handleSuccess();
    })
    .catch((err) => {
        handleError();
    });
  }
};

export const handleCopyPackToCorp = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.EXPERIENCE_PACK_COPY_TO_CORP}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleSuccess()
        }else if(res.data.status_code === 400){
          if(res.data.data.message === "This Title is Already present"){
            handleError()
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// pack reviews

export const getExperienceReviewsList = (id) => {
  const body = {
    "request_type":"list_review",
    "pack_id": id,
    "page":1,
    "list_per_page": 50,
    "filters":[{"field":"active", "value":"true"}],
    "sort":{"field":"custom_date", "order":"D"}
  }
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_EXPERIENCE_REVIEWS}`,body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCE_PACK_REVIEWS_LIST,
            payload: res.data.data.results
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const addExperiencePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_EXPERIENCE_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 201) {
           handleSuccess("Added Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const editExperiencePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_EXPERIENCE_REVIEWS}`,data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
           handleSuccess("Updated Successfully")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const deleteExperiencePackReview = (data, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.CREATE_DELETE_UPDATE_EXPERIENCE_REVIEWS}`,data, token)
      .then((res) => {
           handleSuccess("Deleted Successfully")
      })
      .catch((err) => {
        console.log(err);
      });
  }
};