import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import { Col, Row } from "antd";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as EventsBookingAction from "../../../actions/eventsAction";
import "../../../assets/css/partials/events/events.scss";
import CloseIcon from '../../../assets/images/close.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';
import CsvIcon from '../../../assets/images/csvIcon.svg';
import { API } from "../../../constants/api";
import ConfirmPayment from "./confirmPayment";
import SearchBar from './searchBar';
import TransactionDetailsModal from './transactionDetailsModal';

function UpcomingBookings({ allUpcomingBookings, onResetPage, pageChanged, setPageChange, pageNum, selectedPage }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [userBooking, setUserBookings] = useState([])
    const [totalBookings, setTotalBookings] = useState(0)
    const [totalConfirmed, setTotalConfirmed] = useState(0)
    const [totalCancelled, setTotalCancelled] = useState(0)
    const searchValue = useSelector((state) => state.eventsListing.eventUpcomingSearchVal);
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalPoints, setTotalPoints] = useState(0)

    const [totalFailed, setTotalFailed] = useState(0)
    const [searchVal, setSearchVal] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showPackTitle, setPackTitle] = useState('');
    const [showModalDetails, setshowModalDetails] = useState({});
    const [refund, setRefund] = useState('');
    const [userPage, setUserPage] = useState(1);
    const [state, setState] = useState({
        amount: "",
    });

    const [error, setError] = useState({
        amount: false,
    })
    const [page, setPage] = useState(1);
    const [bookingIndex, setBookingIndex] = useState(0);
    const initialPackageBookings = pageChanged ? allUpcomingBookings && allUpcomingBookings[0] : allUpcomingBookings && allUpcomingBookings[bookingIndex];
    console.log("HERE initialPackageB", allUpcomingBookings)
    const getCatId = useSelector(state => state?.eventsListing?.event_category_id);
    const getSecId = useSelector(state => state?.eventsListing?.getSecId);
    // const stateRef = useRef();
    // stateRef.current = getCatId;
    const transactionDetails = useSelector(state => state?.eventsListing?.transaction_details);
    // const eventId = useSelector(state => state?.eventsListing?.event_category_id)
    const [catId, setCatId] = useState(getCatId);
    const [refundError, setRefundError] = useState(false);
    const [changeStatusError, setChangeStatusError] = useState({
        hasError:false,
        message:''
    });
    const [isFieldEmptyError, setIsFieldEmptyError] = useState(false);
    const modifiedUserBooking = useSelector(state => state?.eventsListing?.modified_user_bookings?.data?.results);
    const modifiedUserBookingPages = useSelector(state => state?.eventsListing?.modified_user_bookings?.data);

    const [selectValue, setSelectValue] = useState(null);

    const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        width: 430px;
        background-color: #F5F5F5 !important;
        position: relative;
        padding: 20px 20px 40px;
    }
    & > .MuiDialog-container > .MuiPaper-root > .close-login {
        position: absolute;
        top: 12px;
        right: 23px;
        cursor: pointer
      }
    `;
    // console.log("initialPackageBookings as",initialPackageBookings)
    // console.log("initialPackageBookingsID",initialPackageBookings?.id)

    useEffect(() => {
        dispatch(EventsBookingAction.setCategoryId(initialPackageBookings?.id));
        setUserBookings(initialPackageBookings?.user_bookings);
        setTotalBookings(initialPackageBookings?.total_bookings);
        setTotalConfirmed(initialPackageBookings?.total_confirmed);
        setTotalCancelled(initialPackageBookings?.total_cancelled);
        setTotalAmount(initialPackageBookings?.total_amount);
        setTotalPoints(initialPackageBookings?.total_points);
        setTotalFailed(initialPackageBookings?.total_fail);
        setCatId(initialPackageBookings?.id);
    }, [initialPackageBookings]);

    useEffect(() => {
        if (getSecId) {
            dispatch(EventsBookingAction.getEventBookingsFilteredList(getSecId, 'upcoming', selectedPage));
        } else {
            dispatch(EventsBookingAction.getUpcomingBookingsList(selectedPage, getSecId));

        }
        dispatch(EventsBookingAction.getUserBookingList(userPage, catId));
    }, [page, selectedPage])

    useEffect(() => {
        if (allUpcomingBookings[0]?.id === catId) {
            dispatch(EventsBookingAction.setCategoryId(allUpcomingBookings[0]?.id));
            if (allUpcomingBookings[0]?.id) {
                dispatch(EventsBookingAction.getUserBookingList(userPage, allUpcomingBookings[0]?.id));
            }
        } else {
            dispatch(EventsBookingAction.getUserBookingList(userPage, catId));
        }
    }, [allUpcomingBookings]);

    useEffect(() => {
        dispatch(EventsBookingAction.eventBookingSearchValue('', 'upcoming'));
        if (getSecId) {
            dispatch(EventsBookingAction.getEventBookingsFilteredList(getSecId, 'upcoming'));
        } else {
            dispatch(EventsBookingAction.getUpcomingBookingsList(userPage, getSecId));
        }
        dispatch(EventsBookingAction.getUserBookingList(userPage, allUpcomingBookings[0]?.id));

    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
            marginTop: 10,
            boxShadow: "none",
            background: "#FAFAFA",
            border: "1px solid #EDEDED",
            borderRadius: "4px",
            marginBottom: "10px",
            padding: "18px 20px",
            minHeight: 78,
            cursor: "pointer",
            display: 'flex',
            justifyContent: 'space-between'
        },
        rootBorder: {
            position: 'relative',
            marginTop: 10,
            boxShadow: "none",
            background: "#FAFAFA",
            border: "2px solid #000000",
            borderRadius: "4px",
            marginBottom: "10px",
            padding: "18px 20px",
            minHeight: 78,
            cursor: "pointer"
        },
        row: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
        },
        title: {
            fontSize: 16,
            marginBottom: 4,
            fontWeight: 500,
            color: "#000000",
        },
        rightArrow: {
            position: 'absolute',
            right: '16px',
            top: '22px'
        }
    }));
    const classes = useStyles();

    console.log("modifiedUserBooking", modifiedUserBooking);

    const getUserBookings = (id, packTitle, userbookings, totalBookings, totalConfirmed, totalCancelled, totalAmount, totalPoints, totalFailed, index) => {
        setUserBookings(userbookings);
        setPackTitle(packTitle);
        setTotalBookings(totalBookings);
        setTotalConfirmed(totalConfirmed);
        setTotalCancelled(totalCancelled);
        setTotalAmount(totalAmount);
        setTotalPoints(totalPoints);
        setTotalFailed(totalFailed);
        dispatch(EventsBookingAction.setCategoryId(id));
        setCatId(id);
        setBookingIndex(index);
        setUserPage(1);
        setSelectValue('');
    }

    const getModifiedUserBookings = (id, packTitle, totalBookings, totalConfirmed, totalCancelled, totalAmount, totalPoints, totalFailed, index) => {
        setPackTitle(packTitle);
        setTotalBookings(totalBookings);
        setTotalConfirmed(totalConfirmed);
        setTotalCancelled(totalCancelled);
        setTotalAmount(totalAmount);
        setTotalPoints(totalPoints);
        setTotalFailed(totalFailed);
        //dispatch(EventsBookingAction.setCategoryId(id));
        setSearchVal("");
        dispatch(EventsBookingAction.getUserBookingList(userPage, id));
        setSelectValue('');
        setCatId(id);
        setBookingIndex(index);
    }

    const handleTransactionModal = (item) => {
        setShowModal(true);
        dispatch(EventsBookingAction.getTransactionDetails(item.txnid, item.pay_mode));
    }

    const closeTransactionModal = () => {
        setShowModal(false);
    }

    const closeCancelledModal = () => {
        setShowCancelModal(false);
        setRefundError(false);
        setIsFieldEmptyError(false);
    }

    const closeConfirmedModal = () => {
        setShowConfirmedModal(false);
    }

    const statusOptions = [
        {
            status: 'confirmed',
            name: 'Confirm this'
        },
        {
            status: 'cancelled',
            name: 'Cancel this'
        }
    ]

    const pendingStatusOptions = [
        {
            status: 'confirmed',
            name: 'Confirm this'
        },
    ]

    const bookingStatusChange = (item, value, showPackTitle) => {
        const eventTitle = {
            title: showPackTitle,
            selected_status: value
        }
        const body = { ...item, ...eventTitle }
        if (value === "cancelled") {
            setChangeStatusError({
                hasError:false,
                message:''
            });
            setShowCancelModal(true);
            console.log(body);
            setshowModalDetails(body)
        }
        else if (value === "confirmed") {
            setChangeStatusError({
                hasError:false,
                message:''
            });
            setShowConfirmedModal(true);
            console.log(body);
            setshowModalDetails(body)
        }
    }

    const cancelBooking = (e, id, value, corpStatus) => {
        e.preventDefault();
        if (parseFloat(showModalDetails.refund_amount) > (parseFloat(showModalDetails.amount_paid) + parseFloat(showModalDetails.total_points))) {
            setRefundError(true);
        } else if (showModalDetails.refund_amount === "" || !showModalDetails.refund_amount) {
            setIsFieldEmptyError(true);
        } else {
            dispatch(EventsBookingAction.updateBookingStatus(id, value, corpStatus, refreshData, parseFloat(showModalDetails?.refund_amount)));

        }
    }

    const handleChangeValue = (key, value) => {
        setshowModalDetails(() => ({
            ...showModalDetails,
            [key]: value.target.value,
        }));
    };

    const refreshData = (res) => {
        if(res?.hasError){
            setChangeStatusError(res);
            return false;
        }
        // dispatch(EventsBookingAction.getUpcomingBookingsList(pageNum));
        dispatch(EventsBookingAction.getUserBookingList(userPage, catId));

        dispatch(EventsBookingAction.getUpcomingBookingsList(selectedPage));

        dispatch(EventsBookingAction.setCategoryId(catId));
        enqueueSnackbar("Status updated successfully.", {
            variant: "success",
        });
        setShowCancelModal(false);
        setPageChange(false);
    }


    const handleConfirmBooking = (e) => {
        e.preventDefault();
        if (!state.amount || state.amount === "") {
            setError({ amount: true })
        }
    }

    const userSearchBooking = (event) => {
        let input = event.target.value;
        setSearchVal(input);
        dispatch(EventsBookingAction.userSearchBookingList(input, catId, selectValue ? selectValue : null));
        setUserPage(1);
    }

    const handleStatusSelect = (e) => {
        let input = e.target.value;
        setSelectValue(input ? input : '');
        dispatch(EventsBookingAction.userSearchBookingList(searchVal, catId, input ? input : null))
        setUserPage(1);
    }

    const onPageChange = (value) => {
        setUserPage(value);
        dispatch(EventsBookingAction.userSearchBookingList(searchVal, catId, selectValue ? selectValue : null, value))
    }

    const downloadCSV = () => {
        console.log(catId);


        const url = `${API.CSV_DOWNLOAD}${catId}/`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'file';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div>
            <div className="bookings--holder">
                <Row >
                    <Col xs={24} lg={24} className="gutter-row">
                        <SearchBar searchValue={searchValue} status="upcoming" onResetPage={onResetPage} setPageChange={setPageChange} />
                    </Col>
                    <Col style={{ paddingRight: '20px', marginRight: '-20px' }} className="gutter-row overflow--scroll cards-list" style={{ marginBottom: '10px' }} xs={24} lg={8}>
                        {
                            allUpcomingBookings && allUpcomingBookings.length > 0 && allUpcomingBookings.map((item, index) => (
                                <Card
                                    className={(catId == item?.id) ? `active-card ${classes.root}` : `${classes.root}`}
                                    onClick={() => { getModifiedUserBookings(item?.id, item?.pack_title, item?.total_bookings, item?.total_confirmed, item?.total_cancelled, item?.total_amount, item?.total_points, item?.total_fail, index); getUserBookings(item?.id, item?.pack_title, item?.user_bookings, item?.total_bookings, item?.total_confirmed, item?.total_cancelled, item?.total_amount, item?.total_points, item?.total_fail, index); }}   >
                                    <div>
                                        <h4>{item?.pack_title}</h4>
                                        <div class="booking-info">
                                            <p>{item?.total_bookings} bookings</p>
                                        </div>
                                    </div>
                                    {
                                        catId == item?.id ? <div>
                                            <img src={rightArrow} alt="" />
                                        </div> : ''
                                    }
                                </Card>
                            ))
                        }

                    </Col>
                    <Col className="gutter-row overflow--scroll" xs={24} lg={16}>
                        {
                            allUpcomingBookings && allUpcomingBookings.length > 0 ?
                                <div className="overview-holder">
                                    <div className="data-holder">
                                        <div className="bookings">
                                            <h5>{totalBookings} bookings</h5>
                                            <div className="info">
                                                <p>Received so far</p>
                                                <p>{totalConfirmed} Confirmed, <span style={{ color: '#DA7519' }}> {totalCancelled} Cancelled </span></p>
                                            </div>
                                        </div>
                                        <div className="bookings payment-details">
                                            <h5>Rs {totalAmount} and {totalPoints} pts</h5>
                                            <div className="info">
                                                <p>Received so far</p>
                                                <div className="payment-status">
                                                    <p>{totalFailed} Payments pending</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Col xs={24} lg={24} className="gutter-row inputss d-flex" style={{ borderBottom: '4px solid #ccc' }}>
                                        <div className="flex-grow-1" >
                                            <input
                                                type="search"
                                                className="form-control search-list"
                                                placeholder="Search bookings"
                                                value={searchVal}
                                                onChange={userSearchBooking}
                                                style={{ margin: '8px' }}
                                            />
                                        </div>
                                        <div className="Input secondInput">
                                            <select className="selectTag" value={selectValue} onChange={handleStatusSelect} >
                                                <option value=""  >See all</option>
                                                <option value="confirmed" >Confirmed</option>
                                                <option value="pending" >Pending payment</option>
                                                <option value="cancelled" >Cancelled</option>
                                            </select>
                                        </div>
                                        <div className="Input">
                                            <button className="btn btn-md btn-primary p-2" style={{ margin: 7 }} onClick={downloadCSV}><img src={CsvIcon} /> Export to CSV</button>
                                        </div>
                                        <div className="clear"></div>
                                    </Col>
                                    <div className="payments-list-holder">
                                        {
                                            // all payment details
                                            modifiedUserBooking && modifiedUserBooking.length > 0 ?
                                                modifiedUserBooking.map((item) => (
                                                    <div className="all-payment-details">
                                                        <div className="user-details">
                                                            <div className="user">
                                                                <span style={{ color: "#2196F3", fontSize: "12px", display: "block" }}>{item.ref_id? `#${item.ref_id}`:"No ref id"}</span>
                                                                <h6>{item?.contact_name}</h6>
                                                                <p>{item?.contact_email}, {item?.contact_number}</p>
                                                            </div>
                                                            <div className="amount">
                                                                <h6>
                                                                    {
                                                                        item?.txn_status === "failure" ? `Rs ${item?.amount}` :
                                                                            <span>
                                                                                {
                                                                                    item?.amount_paid === "0.00" && item?.total_points > 0 ? null : `Rs ${item?.amount_paid}`
                                                                                }
                                                                                {
                                                                                    (item?.amount_paid !== "0.00" && item?.total_points > 0) ? ' and ' : ''
                                                                                }
                                                                                {
                                                                                    item?.total_points > 0 ? `${item?.total_points} pts` : null
                                                                                }
                                                                            </span>
                                                                    }

                                                                </h6>
                                                                {
                                                                    item?.txn_status === "success" ? <p>Paid amount</p> : item?.txn_status === "failure" ? <p>Payable amount</p> : null
                                                                }

                                                            </div>
                                                        </div>

                                                        <div className="contact-details-holder">
                                                            <div className="contact-info">
                                                                <div className="title">
                                                                    <p>Booked for</p>
                                                                </div>
                                                                <div className="contact-details">
                                                                    <p className="email">
                                                                        {moment(item?.schedule?.date, 'DD-MM-YYYY').format('Do MMM, YYYY')} </p>
                                                                    <p>{moment(item?.schedule?.from, 'hh:mm').format('LT')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="contact-info">
                                                                <div className="title">
                                                                    <p>Transaction details</p>
                                                                </div>
                                                                <div className="contact-details">
                                                                    <p className="email" style={{ color: '#212121' }}>
                                                                        ID: {item?.txnid} </p>
                                                                    {
                                                                        item?.total_points > 0 || (item?.amount_paid == "0.00" || item?.amount_paid === 0) ? null : <p style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => handleTransactionModal(item)} >see more</p>
                                                                    }


                                                                </div>
                                                            </div>


                                                            <div className="payment-status">
                                                                {
                                                                    item?.booking_status === "confirmed" ? <p className="confirmed--btn">Confirmed</p> : item?.txn_status === "failure" ? <p style={{ background: '#F49B9B' }}>Payment failed</p> : item?.booking_status === "cancelled" ? <p className="cancelled--btn">Cancelled</p> : null
                                                                }

                                                                <select
                                                                    className="status-dropdown"
                                                                    value=""
                                                                    style={{ textTransform: 'capitalize' }}
                                                                    onChange={(event) =>
                                                                        bookingStatusChange(item, event.target.value, showPackTitle)
                                                                    }

                                                                >
                                                                    <option value="" disabled>{item?.booking_status}</option>
                                                                    {
                                                                        item.booking_status === "pending" ?
                                                                            pendingStatusOptions && pendingStatusOptions.length > 0 && pendingStatusOptions?.map((options) => (
                                                                                (item?.booking_status !== options?.status) ? <option value={options?.status}  >{options?.name}</option> : ''
                                                                            )) :

                                                                            statusOptions && statusOptions.length > 0 && statusOptions?.map((options) => (
                                                                                (item?.booking_status !== options?.status) ? <option value={options?.status}  >{options?.name}</option> : ''
                                                                            ))
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <p style={{ margin: '20px' }}>No Bookings Available</p>

                                        }

                                    </div>
                                    <Row style={{ margin: '10px' }}>
                                        <Pagination
                                            count={modifiedUserBookingPages?.total_pages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={userPage}
                                            onChange={(event, value) => onPageChange(value)}
                                        />
                                    </Row>
                                </div> : <p style={{ marginLeft: '20px' }}>No Bookings Available</p>
                        }
                    </Col>
                </Row>
            </div>

            <div className="transaction-modal">
                <Dialog open={showModal} >
                    <div className="tranaction-title">
                        <DialogTitle>
                            <p>Transaction details</p>
                        </DialogTitle>
                        <div className="close-icon" onClick={closeTransactionModal}>
                            <img src={CloseIcon} alt="" />
                        </div>
                    </div>
                    <TransactionDetailsModal transDetails={transactionDetails} />
                </Dialog>
            </div>

            {
                // popup to change booking status to cancelled
            }
            <div className="cancel-booking-modal">
                <Dialog
                    open={showCancelModal}
                    PaperProps={{
                        style: {
                            width: '430px',
                            padding: '20px 20px 40px'
                        },
                    }} >
                    <div className="close-cancel-icon" onClick={closeCancelledModal}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <DialogTitle>
                        <p className="mb-0">Are you sure of cancelling this booking?</p>
                    </DialogTitle>
                    <div className="message-box">
                        <h5 style={{ textAlign: 'center' }}>{showModalDetails?.title}</h5>
                        <p style={{ fontWeight: '500' }} className="text-center">Ref. ID: {showModalDetails?.ref_id}</p>
                        <div className="contact-details-holder" style={{ justifyContent: 'center' }}>
                            <div className="contact-info">
                                <div className="title">
                                    <p>Booked for</p>
                                </div>
                                <div className="contact-details">
                                    <p className="email">
                                        {moment(showModalDetails?.schedule?.date, 'DD-MM-YYYY').format('Do MMM, YYYY')} </p>
                                    <p>{moment(showModalDetails?.schedule?.from, 'hh:mm').format('LT')}</p>
                                </div>
                            </div>

                        </div>
                        <div className="user-info">
                            <h4>{showModalDetails?.contact_name}</h4>
                            <p>{showModalDetails?.contact_email} , {showModalDetails?.contact_number}</p>
                            <h4>Amount Received : Rs {showModalDetails.corporate?parseFloat(showModalDetails.amount_paid) + parseFloat(showModalDetails.total_points): showModalDetails.amount_paid}</h4>
                        </div>
                    </div>
                    <form>
                        <div className="message-box" style={{ width: '85%', marginTop: '20px', backgroundColor: '#ebebeb', borderRadius: '8px' }}>
                            <p style={{ textAlign: 'center', fontSize: '95%' }}>Enter amount you are refunding</p>
                            <div style={{ flex: 0.5, position: 'relative', }} className="form-group form-custom">
                                <h6 style={{ position: 'absolute', left: '25px', top: '10px', fontWeight: '400', opacity: 0.4 }}>Rs</h6>
                                <div style={{
                                    position: 'absolute',
                                    height: '17%',
                                    paddingBottom: '37px',
                                    width: '1px',
                                    left: '60px',
                                    backgroundColor: 'rgb(202 194 194)',
                                }}
                                />

                                <input
                                    style={{ paddingLeft: '75px' }}
                                    value={showModalDetails.refund_amount}
                                    type="text"
                                    min='0'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    className="form-control"
                                    onChange={(value) => handleChangeValue("refund_amount", value)}
                                />
                                {isFieldEmptyError ?
                                    <p style={{ color: "red", marginTop: '10px' }}>Please enter the amount</p> :
                                    refundError ?
                                        <p style={{ color: "red", marginTop: '10px' }}>Refund amount should not be more than received amount</p>
                                        : ""}

                                 {changeStatusError.hasError&& <p style={{ color: "red", marginTop: '10px' }}>{changeStatusError?.message}</p>}       
                            </div>
                        </div>
                        <div className="cta-holder">
                            <button type="button" className="btn dont-cancel" onClick={closeCancelledModal}>Don't cancel</button>
                            <button type="button" className="btn cancel-btn" onClick={(e) => cancelBooking(e, showModalDetails?.id, showModalDetails?.selected_status, showModalDetails?.corporate)}>Cancel this booking</button>
                        </div>
                    </form>
                </Dialog>
            </div>

            {
                // popup to change booking status to confirm and enter amount
                showConfirmedModal ?
                    <ConfirmPayment modalDetails={showModalDetails} showConfModal={showConfirmedModal} setPageChange={setPageChange} toggleModal={setShowConfirmedModal} pageNum={userPage} catId={catId} selectedPage={selectedPage} /> : null
            }

        </div>
    )
}

export default UpcomingBookings