import React, { useEffect, useState } from 'react'
import SectionImageItem from '../../../common/sectionImageItem'
import * as WeddingAction from '../../../actions/weddingAction'
import { useDispatch, useSelector } from 'react-redux'
import AddNewSection from '../../../common/addNewSection';
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import { DONE, CANCEL, DELETE, DELETE_CONFIRMATION_MESSAGE } from '../../../utils/constants'
import {holidayImageUpload} from '../../../actions/holidaySectionAction'
import DeleteConfirmationModal from '../../../common/deleteConfirmationModal';
import { useSnackbar } from "notistack";

const DestinationList = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const [updateModal, setUpdateModal] = useState(false)
  const [isUpdateLocation, setIsUpdateLocation] = useState(false)
  const [isUpdateImage, setIsUpdateImage] = useState(false)
  const [isUpdatePriority, setIsUpdatePriority] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [deteleDestinationId, setDeteleDestinationId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")
  
  const [state, setState] = useState({
    location: "",
    image: "",
    priority: "",
    title_text:"",
    alt_text:""
  });

  const [error, setError] = useState({
    location: false,
    image: false,
    priority: false,
    priorityNegative: false
  })
  const weddingDestination = useSelector(state => state?.wedding?.destinationList)
  const sectionUpdateHandler = (data) => {
    setUpdateModal(true)
    setUpdateData(data)
  }
  const locationNameOnchangeHandler = (event) => {
    setIsUpdateLocation(true)
    setState({
      ...state,
      location: event.target.value
    })
    setError({...error, location: false})
  }
  const priorityOnchangeHandler = (event) => {
    setIsUpdatePriority(true)
    setState({
      ...state,
      priority: event.target.value
    })
    setError({...error, priority: false, priorityNegative: false})
  }
  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(WeddingAction.weddingImageUpload(croppedFile, updatePic));
      }
  }
  const updatePic = (data) => {
    setIsUpdateImage(true)
    setState({
      ...state,
      image: data.url
    });
    setError({...error, image: false})
  };

  const handleUpdate = (e) => {
    e.preventDefault()
    const data = {
      location: isUpdateLocation ? state.location : updateData.location,
      priority: isUpdatePriority ? state.priority : updateData.priority,
      image: isUpdateImage ? state.image : updateData.image,
      alt_text:altText,
      title_text:imageTitle
    }
    if(isUpdateImage && state.image == ""){
      setError({...error, image: true})
    }else if(isUpdateLocation && state.location == ""){
      setError({...error, location: true})
    }else if(isUpdatePriority && state.priority == ""){
      setError({...error, priority: true})
    }else if(isUpdatePriority && state.priority <= -1){
      setError({...error, priorityNegative: true})
    }else{
     dispatch(WeddingAction.updateWeddingDestination(data, updateData?.id, handleSuccess))
    }

  }
  const handleSuccess = () => {
    dispatch(WeddingAction.getWeddingDestinationList());
    enqueueSnackbar("Updated Successfully", {
      variant: "success",
    });
    setUpdateModal(false)
    setIsUpdateImage(false)
    setIsUpdatePriority(false)
    setIsUpdateLocation(false)
  };

  const openDeleteConfirmationModal = (data) => {
    setShowDeleteModal(true);
    setDeteleDestinationId(data.id);
  };
  const destinationDeleteHandler = ( ) => {
      dispatch(WeddingAction.deleteWeddingDestination(deteleDestinationId, handleDeleteSuccess))
  }
  const handleDeleteSuccess = () => {
    enqueueSnackbar("Deleted Successfully", {
      variant: "success",
    });
    setShowDeleteModal(false)
    dispatch(WeddingAction.getWeddingDestinationList());
  };

  const handleClose = () => {
    setUpdateModal(false)
    setIsUpdateImage(false)
    setIsUpdatePriority(false)
    setIsUpdateLocation(false)
    setState({
      location: "",
      image: "",
      priority: "",
    })
  }

  useEffect(() => {
    dispatch(WeddingAction.getWeddingDestinationList())
  }, [])
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      title_text: imageTitle,
      alt_text: altText
    }));
  }, [imageTitle, altText]);

  const weddingDestinationList = weddingDestination && weddingDestination?.map(data => (
    <SectionImageItem 
      sectionText={data.location}
      sectionImage={data.image}
      priority={data.priority}
      sectionImageUpdateHandlere={() => sectionUpdateHandler(data)}
      sectionImageDeleteHandler={() => openDeleteConfirmationModal(data)}
    />  
    ))

  return (
    <>
        {weddingDestinationList}
        {updateModal && 
         <AddNewSection 
            showMyModal={updateModal}
            openHandler={updateModal}
            togglebackDrop={updateModal}
            heading="Add new Destination"
            image={isUpdateImage && state.image ?  state.image : updateData.image}
            imageText="Image"
            labelTextOne="Location name"
            labelTextTwo="Priority"
            isDestination={true}
            btnTextOne={CANCEL}
            btnTextTwo={DONE}
            closeHandle={() => handleClose()}
            onChangeOne={locationNameOnchangeHandler}
            onChangeTwo={priorityOnchangeHandler}
            handleSubmit={handleUpdate}
            handleFilePickChange={handleImageChange}
            isImage={error.image}
            isSectionNameError={error.location}
            isPriorityEmptyError={error.priority}
            isPriorityError={error.priorityNegative}
            valueOne={isUpdateLocation ? state.location : updateData.location}
            valueTwo={isUpdatePriority ? state.priority : updateData.priority}
            imageTitle={imageTitle}
            setImageTitle={setImageTitle}
            altText={altText}
            setAltText={setAltText}
         />
         }
         <DeleteConfirmationModal 
          open={showDeleteModal}
          btnTextOne={CANCEL}
          btnTextTwo={DELETE}
          onClick={() =>setShowDeleteModal(false)}
          deleteImage={destinationDeleteHandler}
          // disable={sectionTotalPack > 0 ? true : false}
          // fontSize={sectionTotalPack > 0 ?"20px":""}
          deleteConfirmationMsg={DELETE_CONFIRMATION_MESSAGE}
      />
    </>
  )
}

export default DestinationList