import React from 'react'
import Input from './uiElements/input'

const SecondLocation = ({currentPackage, setCurrentPackage, isInternational}) => {
    const handleSecondLocation = (val) => {
      if(val==""){
        const arr = currentPackage?.location;
        if(arr.length>1){
          arr.pop()
        }
        setCurrentPackage(() => ({
          ...currentPackage,
          location: arr,
        }));
      }else{
        const arr = currentPackage?.location;
        arr[1] = {
          address: val,
          latitude: 0,
          longitude: 0,
          city: "",
          state: "",
          country: ""
        }
        setCurrentPackage(() => ({
          ...currentPackage,
          location: arr,
        }));
      }
      }
        
  return (
      <>
        {!isInternational&& <div className="form-group form-custom" style={{marginLeft: "10px"}} id="locationType">
            <Input
                label="2nd Location (optional)"
                value={currentPackage?.location?.length == 2 ? currentPackage?.location?.[1]?.address : ""}
                handleChange={(val) => handleSecondLocation(val.target.value)}
            />
            
        </div>}
      </>
  )
}

export default SecondLocation