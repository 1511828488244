import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionImageItem from './sectionImageItem';
import * as corpListingAction from "../actions/corpListingAction";
import { 
  deleteSectionImage, 
  imageUpdateHandler, 
  getSectionImageData, 
  updateSection, 
  getSectionPackages ,
  handleUpdateConfirmBool
} from '../actions/sectionAction';
import { DELETE, CANCEL,DELETE_CONFIRMATION_MESSAGE} from '../utils/constants'
import AddNewSection from '../common/addNewSection';
import { selectedImageUrl } from "../actions/sectionAction";
import { useSnackbar } from "notistack";
import DeleteConfirmationModal from './deleteConfirmationModal';

const SectionImageItems = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [ isUpdate, setIsUpdate ] = useState(false);
  const [ section, setSection ] = useState("");
  const [ sectionBool, setSectionBool ] = useState(false);
  const [ priority, setPriority ] = useState("");
  const [ priorityBool, setPriorityBool ] = useState(false);
  const [ refCodeValue, setRefCodeValue] = useState("");
  const [ refCodeBool, setRefCodeBool ] = useState(false);
  const [ imageBool, setImageBool ] = useState(false);

  const [ updateCorpSectionNameErrorBool, setUpdateCorpSectionNameErrorBool ] = useState(false);
  const [ updateCorpPriorityEmptyErrorBool, setUpdateCorpPriorityEmptyErrorBool ] = useState(false);
  const [ updateCorpPriorityNegErrorBool, setUpdateCorpPriorityNegErrorBool ] = useState(false);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ isCodeMatch, setIsCodeMatch ] = useState(false);

  const [ itemNeedToBeDeleted, setItemNeedToBeDeleted ] = useState({});
  const [ itemDeleteMsgModal, setItemDeleteMsgModal ] = useState(false);
  const [ updateSectiondata, setUpdateSectiondata ] = useState({})

  const sectionImageData = useSelector(state => state.section.corpSectionImageData);
  const updateImage = useSelector(state => state.section.imageUpdatedSection);
  const [ isGiftVoucherBool, setIsGiftVoucherBool ] = useState(false);
  const confirmUpdateBoolVal = useSelector(state => state.section.confirmUpdateBoolVal);
  const sectionTotalPack = useSelector(state => state.section.sectionPackages);

  const [ check, setCheck ] = useState(false);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [file, setFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const filterGiftVoucherTrue = sectionImageData.filter(item => item.gift_voucher === true);
  const resFilterGiftVoucherTrue = filterGiftVoucherTrue.map(item => item.gift_voucher)
  // console.log("updateImage", updateImage)

  useEffect(() => {
    if(refCodeBool && refCodeValue.length === 2){
      setCodeLessErrorr(false);
      setCodeMoreError(false);
    }
   }, [refCodeBool,refCodeValue]);
   
  const updatePic = (data) => {
    setImageUrl(data.url);
    setFile("");
    dispatch(selectedImageUrl(data.url));
    dispatch(getSectionImageData());
  };

  const addSectionImages = () => {
    inputFile.current.click();
  };

  const handleImageChange = (croppedFile) => {
    setFile(croppedFile);
    if (croppedFile) {
      dispatch(corpListingAction.imageUpload(croppedFile, updatePic));
    }
  };

  const sectionImageDeleteHandler = (item) => {
    setItemNeedToBeDeleted(item);
    setItemDeleteMsgModal(true);
    setIsGiftVoucherBool(item);
    dispatch(getSectionPackages(item));
  };

  const sectionImageUpdateHandlere = (item) => {
    setUpdateSectiondata(item)
    setIsUpdate(true);
    setImageBool(true);
    dispatch(imageUpdateHandler(item));
  };

  const handleSectionChange = (e) => {
    setSectionBool(true);
    setSection(e.target.value);
  };

  const handleClose = () => {
    setSectionBool(false);
    setPriorityBool(false);
    setImageBool(false);
    setRefCodeBool(false);
    setSection("");
    setPriority("")
    setImageUrl("")
    setRefCodeValue("")
    dispatch(selectedImageUrl(null))
    dispatch(handleUpdateConfirmBool(false))
    setIsUpdate(false)
  }

  const handlePriorityChange = (e) => {
    setPriorityBool(true);
    setPriority(e.target.value);
  };
  const refrenceCodeHandler = (e) => {
    setRefCodeBool(true)
    setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredSection = sectionImageData.filter(item => {
      return code === item.code
    })
    if(filteredSection.length){
      setIsCodeMatch(true)
    }else{
      setIsCodeMatch(false)
    }
    setRefCodeValue(code)
  }
  const handleEditSubmit = (e,id) => {
    e.preventDefault();
    setUpdateCorpSectionNameErrorBool(false);
    setUpdateCorpPriorityEmptyErrorBool(false);
    setUpdateCorpPriorityNegErrorBool(false);
    setCodeLessErrorr(false);
    setIscodeError(false);
    setCodeMoreError(false);
    const submitData = {
      image: confirmUpdateBoolVal ? imageUrl : updateImage.image,
      name: !sectionBool ? updateImage.name : section,
      priority: parseInt(!priorityBool? updateImage.priority : priority),
      code:!refCodeBool?updateImage.code :refCodeValue,
      gift_voucher:!isGiftVoucherBool?updateImage.gift_voucher:check
    };

    if(sectionBool && section === "") setUpdateCorpSectionNameErrorBool(true)
    else if(priorityBool && priority === "") setUpdateCorpPriorityEmptyErrorBool(true)
    else if(priorityBool && priority <= -1) setUpdateCorpPriorityNegErrorBool(true)
    else if(refCodeBool && refCodeValue.length === 0) setIscodeError(true)
    else if(refCodeBool && refCodeValue.length < 2) setCodeLessErrorr(true)
    else if(refCodeBool && refCodeValue.length > 2) setCodeMoreError(true)
    else if(refCodeBool && refCodeValue.length == 2 && isCodeMatch) setCodeMatchError(true)
    else {
    setIsUpdate(false);
    dispatch(updateSection(submitData,refreshUpdateSection,id,handleSuccess,handleError));
    setSectionBool(false);
    setPriorityBool(false);
    setImageBool(false);
    setRefCodeBool(false);
    setSection("");
    setPriority("")
    setImageUrl("")
    setRefCodeValue("")
    dispatch(selectedImageUrl(null))
    dispatch(handleUpdateConfirmBool(false))
    dispatch(getSectionImageData());
    }
  };

  const handleSuccess = () => {
    enqueueSnackbar("Updated successfully", {
      variant: "success",
    });
  };

  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };
  
  const checkHandler = (e) => {
    setIsGiftVoucherBool(true)
    setCheck(!updateImage.gift_voucher);
  };

  useEffect(() => {
    // dispatch(getSectionImageData());
  },[imageUrl]);

  const deleteImage = () => {
    dispatch(deleteSectionImage(itemNeedToBeDeleted, handleDeleteSuccess, handleError));
    setItemDeleteMsgModal(false)
  };

  const refreshUpdateSection = () => {
    dispatch(getSectionImageData());
  };

  const handleDeleteSuccess = () => {
    enqueueSnackbar("Deleted successfully", {
      variant: "success",
    });
  };

  return (
    <Fragment>
      { sectionImageData && sectionImageData.map(item => (
        <SectionImageItem sectionText={item.name} sectionImage={item.image}
          sectionImageUpdateHandlere={() => sectionImageUpdateHandlere(item)}
          sectionImageDeleteHandler={() => sectionImageDeleteHandler(item)}
          priority={item.priority}
          isDisableDelete = {
            item.name === "Gift Voucher" || item.name === "International" && true
          }
        />
      ))}
  
      {isUpdate && 
        <AddNewSection 
          showMyModal={isUpdate}
          closeHandle={() => handleClose()}
          heading="Update Section"
          image={confirmUpdateBoolVal ? imageUrl : updateImage.image}
          labelTextOne="Section Name"
          labelTextTwo="Priority"
          codeRefrence="Code"
          btnTextOne="Cancel"
          btnTextTwo="Update"
          valueOne={!sectionBool ? updateImage.name : section}
          onChangeOne={handleSectionChange}
          valueTwo={!priorityBool? updateImage.priority : priority}
          onChangeTwo={handlePriorityChange}
          refCodeValue={!refCodeBool? updateImage.code :refCodeValue}
          refrenceCodeHandler={refrenceCodeHandler}
          closeModal={() => setIsUpdate(false)}
          addSectionImages={addSectionImages}
          inputFile={inputFile}
          handleSubmit={(e) => handleEditSubmit(e, updateImage.id)}
          handleFilePickChange={handleImageChange}
          isUpdateImageSection={true}
          isAddImageSection={false}
          checkedValue={updateImage.gift_voucher}
          value={!isGiftVoucherBool?updateImage.gift_voucher:check}
          checkHandler={checkHandler}
          isSectionNameError={updateCorpSectionNameErrorBool}
          isPriorityError={updateCorpPriorityNegErrorBool}
          isPriorityEmptyError={updateCorpPriorityEmptyErrorBool}
          iscodeError={iscodeError}
          codeLessError={codeLessError}
          codeMoreError={codeMoreError}
          codeMatchError={codeMatchError}
          isEventSection={true}
          pointerEvents = {updateSectiondata.name === "Gift Voucher" || updateSectiondata.name === "International" && "none"}
          disabled={confirmUpdateBoolVal  && imageUrl !== null || section || priority || refCodeValue ? false:true}
          isUpdateSec={true}
          // checkBoxdisable={`${resFilterGiftVoucherTrue.length >= 1 && updateSectiondata.gift_voucher === false? "none" :resFilterGiftVoucherTrue.length === 0? "block":""}`}
        />
      };
      <DeleteConfirmationModal 
        open={itemDeleteMsgModal}
        btnTextOne={CANCEL} 
        btnTextTwo={DELETE}
        deleteImage={deleteImage}
        onClick={() => setItemDeleteMsgModal(false)}
        disable={sectionTotalPack > 0 ? true : false}
        fontSize={sectionTotalPack > 0 ?"20px":""}
        deleteConfirmationMsg={
          sectionTotalPack > 0 ?
          `You have 
          ${sectionTotalPack} ${sectionTotalPack > 1 ? "packges": "packge"} 
          in this section please shift or delete this ${sectionTotalPack > 1 ? "packges": "packge"} to delete section`:
          DELETE_CONFIRMATION_MESSAGE
        }
      />
    </Fragment>
  );
};

export default SectionImageItems;
