
import { GET_HOLIDAY_SECTION ,HOLIDAY_CONFIRM_ADD_BOOL, HOLIDAY_CONFIRM_UPDATE_BOOL, HOLIDAY_SELECTED_IMAGE_URL, CLOSE_HOLIDAY_ADD_NEW_SECTION, HOLIDAY_SECTION_PACKAGES} from '../actions/types'

const initialState = {
    getHolidaySectionList:[],
    holidayAddConfirmBool:false,
    holidayUpdateBool:false,
    holidaySelectedImgUrl:null,
    closeHolidayAddNewSection:true,
    sectionPackages:null
};

const holidaySectionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_HOLIDAY_SECTION: {
            return {
                ...state,
                getHolidaySectionList: payload,
            }
        }
        case HOLIDAY_CONFIRM_ADD_BOOL: {
            return {
                ...state,
                holidayAddConfirmBool: payload,
            }
        }
        case HOLIDAY_CONFIRM_UPDATE_BOOL: {
            return {
                ...state,
                holidayUpdateBool: payload,
            }
        }
        case HOLIDAY_SELECTED_IMAGE_URL: {
            return {
                ...state,
                holidaySelectedImgUrl: payload,
            }
        }
        case CLOSE_HOLIDAY_ADD_NEW_SECTION: {
            return {
                ...state,
                closeHolidayAddNewSection: payload,
            }
        }
        case HOLIDAY_SECTION_PACKAGES: {
            return {
                ...state,
                sectionPackages: payload,
            }
        }
        default:
        return state;
    }
};

export default holidaySectionReducer;