import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { familyFirstSearchRequested } from '../actions/myFamilyFirstAction';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    padding: 6,
    borderRadius: 3,
  },
}));

const SearchBar = ({ searchVal, handleChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  return (
    <TextField
      className={classes.input}
      defaultValue={""}
      size="small"
      value={searchVal}
      placeholder={"Search by title,number, name"}
      fullWidth={true}
      onChange={handleChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default SearchBar;