import { SET_SELECTED_EXPERIENCE_CHOSEN_ID,GET_EXPERIENCES_PACKLIST_DOWNLOAD,GET_EXPERIENCES_FEATURED_LIST, EXPERIENCE_LISTING_SEARCH_VALUE, EXPERIENCES_LISTING_UPDATE_BOOL, EXPERIENCE_LISTING_DROP_DOWN_VALUE, GET_EXPERIENCES_LIST, ADD_EXPERIENCES_IMAGE, UPDATE_EXPERIENCES, SEARCH_EXPERIENCES_LIST_DATA, ALL_EXPERIENCES_IMAGES_DATA, GET_SELECT_EXPERIENCES_DROPDOWN, POST_EXPERIENCES_LISTING_DATA, GET_EXPERIENCES_PACKAGE_INFO, EXPERIENCES_PACKAGE_DELETE, EXPERIENCES_DELETE, EXPERIENCES_IMAGE_DELETE, EXPERIENCES_LISTING_PAGE_ID, TESTIMONIAL_EXP_LIST, EXPERIENCE_PACK_REVIEWS_LIST, IS_EXP_DATA_LOADING, IS_EXP_LIST_DATA_LOADING } from "../actions/types";
import {
  SET_EDIT_EXPERIENCES_LIST_DETAILS,
  SET_ADD_EXPERIENCES_PACKAGE,
  SET_ADD_NEW_PACKAGE,
  SELECTED_DAYS,
  SELECTED_DATES,
  SELECTED_RANGE
} from "../actions/experienceListingAction";

const initialState = {
  experiencesList: [],
  experiencesListItemDetails: {},
  addExperiencesPackage: false,
  addNewPackage: {},
  addExperiencesImage: {},
  listingData: {},
  searchExperiencesListData: [],
  allExperiencesImagesData: [],
  imageUpload: '',
  selectedDropdown: [],
  postExperiencesListingData: {},
  deletedPackage: [],
  deletedExperiences: [],
  deleteImage: [],
  packageInfo: [],
  selected_days: [],
  selected_dates: [],
  selected_range: [],
  pageId:1,
  featured: [],
  downloadPackList: [],
  experienceListingDropDownvalue: null,
  experienceUpdateBoolVal: false,
  experiencelistSearchVal:"",
  selectedChosenId:"",
  expTestimonialList:[],
  experienceReviewList: [],
  isDataLoading:false,
  isListingDataLoading:false
};

const experienceListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EXPERIENCES_LIST: {
      return {
        ...state,
        experiencesList: payload,
      };
    }
    case SET_EDIT_EXPERIENCES_LIST_DETAILS: {
      return {
        ...state,
        experiencesListItemDetails: payload,
      };
    }
    case SET_ADD_EXPERIENCES_PACKAGE: {
      return {
        ...state,
        addExperiencesPackage: payload,
      };
    }
    case GET_EXPERIENCES_PACKLIST_DOWNLOAD: {
      return {
        ...state,
        downloadPackList: payload,
      };
    }
    case SET_ADD_NEW_PACKAGE: {
      return {
        ...state,
        addNewPackage: payload,
      }
    }
    case ADD_EXPERIENCES_IMAGE: {
      return {
        ...state,
        addExperiencesImage: payload,
      };
    }
    case UPDATE_EXPERIENCES: {
      return {
        ...state,
        listingData: payload
      }
    }
    case SEARCH_EXPERIENCES_LIST_DATA: {
      return {
        ...state,
        searchExperiencesListData: payload
      }
    }
    case ALL_EXPERIENCES_IMAGES_DATA: {
      return {
        ...state,
        allExperiencesImagesData: payload
      }
    }
    case GET_SELECT_EXPERIENCES_DROPDOWN: {
      return {
        ...state,
        selectedDropdown: payload
      }
    }
    case POST_EXPERIENCES_LISTING_DATA: {
      return {
        ...state,
        postExperiencesListingData: payload
      }
    }
    case GET_EXPERIENCES_PACKAGE_INFO: {
      return {
        ...state,
        packageInfo: payload
      }
    }
    case GET_EXPERIENCES_FEATURED_LIST: {
      return {
        ...state,
        featured: payload
      }
    }
    case EXPERIENCES_PACKAGE_DELETE: {
      return {
        ...state,
        deletedPackage: payload
      }
    }
    case EXPERIENCES_DELETE: {
      return {
        ...state,
        deletedExperiences: payload
      }
    }
    case EXPERIENCES_IMAGE_DELETE: {
      return {
        ...state,
        deleteImage: payload
      }
    }
    case SELECTED_DAYS:{
      return {
        ...state,
        selected_days: payload
      }
    }
    case SELECTED_DATES:{
      return {
        ...state,
        selected_dates: payload
      }
    }
    case SELECTED_RANGE:{
      return {
        ...state,
        selected_range: payload
      }
    }
    case EXPERIENCES_LISTING_PAGE_ID: {
      return {
        ...state,
        pageId: payload
      }
    }
    case EXPERIENCE_LISTING_DROP_DOWN_VALUE: {
      return {
        ...state,
        experienceListingDropDownvalue: payload,
      };
    }
    case EXPERIENCES_LISTING_UPDATE_BOOL: {
      return {
        ...state,
        experienceUpdateBoolVal: payload
      }
    }
    case EXPERIENCE_LISTING_SEARCH_VALUE: {
      return {
        ...state,
        experiencelistSearchVal: payload
      }
    }
    case SET_SELECTED_EXPERIENCE_CHOSEN_ID: {
      return {
        ...state,
        selectedChosenId: payload
      }
    }
    case TESTIMONIAL_EXP_LIST: {
      return {
        ...state,
        expTestimonialList: payload
      }
    }
    case EXPERIENCE_PACK_REVIEWS_LIST: {
      return {
        ...state,
        experienceReviewList: payload
      }
    }
    case IS_EXP_DATA_LOADING: {
      return {
        ...state,
        isDataLoading: payload
      }
    }
    case IS_EXP_LIST_DATA_LOADING: {
      return {
        ...state,
        isListingDataLoading: payload
      }
    }
   
    
    default:
      return state;
  }
};


export default experienceListingReducer;
