import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as BookingAction from "../../../actions/bookingAction";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { AiFillCaretDown } from 'react-icons/ai';


const useStyles = makeStyles((theme) => ({
  resize: {
    fontSize: 11,
  },
}));

const Datefilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state) => state.bookingListing.filterStartDate);
  const endDate = useSelector((state) => state.bookingListing.filterEndDate);

  const handleStartDateChange = (date) => {
    dispatch(BookingAction.filterStartDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleEndDateChange = (date) => {
    dispatch(BookingAction.filterEndDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(BookingAction.filterStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(BookingAction.filterEndDate(null));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="date-filter">
        <p className="date-filter-from"> Date filter from</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 90,
            fontSize: 10,
          }}
          autoOk={true}
          InputProps={
            startDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleStartClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          size="small"
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!startDate && "Select"}
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          // keyboardIcon={<ExpandMoreIcon />}
        />
        <p style={{ margin: 0, marginRight: 10, marginLeft: 10 }}> to</p>
        <DatePicker
          style={{
            borderWidth: 0,
            width: 90,
          }}
          autoOk={true}
          InputProps={
            endDate ? ({
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <ClearIcon className="date-filter-icon"
                  onClick={(e) => handleEndClear(e)}
                />
              ),
            }): {
              classes: {
                input: classes.resize,
              },
              endAdornment: (
                <AiFillCaretDown className="date-filter-icon"
                />
              ),
            }
          }
          disableFuture
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date-picker-inline"
          label={!endDate && "Select"}
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          // keyboardIcon={<ExpandMoreIcon />}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Datefilter;
