import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyFamilyFirstBooking from '../../../common/myFamilyFirstBookings'
import SearchBar from '../../../common/commonSearchBar';
import MyFamilyFirstMoreInfoModal from './myFamilyFirstMoreInfoModal';
import {
    myFamilyFirstConfirmed,
    myFamilyFirstRequested,
    myFamilyFirstPlanning,
    myFamilyFirstCancelled,
    familyFirstStatusUpdate,
    familyFirstSearchRequested,
    pageId,
    setSearchTerm
} from '../../../actions/myFamilyFirstAction';
import Pagination from '../../../common/pagination';
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';


const Confirmed = () => {
    const [showMyFamilyFirstMoreInfoModal, setShowMyFamilyFirstMoreInfoModal] = useState(false);
    const [confirmedMoreInfoModalData, setConfirmedMoreInfoModalData] = useState({});
   const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    const [ id, setId ] = useState(null);
    const[statuschange,setStatuschange]=useState()
    const [requestModal, setRequestModal] = useState(false);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    const [page, setPage] = useState(1);
    const pageNum = useSelector(state => state.myFamilyFirst.page);
    
    const myFamilyFirstConfirmedData = useSelector(state => state.myFamilyFirst?.myFamilyFirstConfirmed);
    const totalPage = useSelector(state => state.myFamilyFirst?.confirmedTotalPage);
    const [ searchVal, setSearchVal ] = useState('');
    const dispatch = useDispatch();
    const START_DATE = useSelector(state => state.myFamilyFirst.familyFirstStartDate);
    const END_DATE = useSelector(state => state.myFamilyFirst.familyFirstEndDate);
    const SEARCH_TERM = useSelector(state => state.myFamilyFirst.searchTerm);
   const PAGE_NO = useSelector(state => state.myFamilyFirst.page);

   useEffect(() => {
       
    let value = [];
    myFamilyFirstConfirmedData?.map((elem) => {
      if (elem.price) {
        value.push(elem.price)
     
      }
    
    },);
    const valueSum=value.reduce((acc,elem)=>{
        return acc+elem

    },0)

        setAverageOrderValue(Math.round(valueSum/myFamilyFirstConfirmedData?.length));
        setTotalOrderValue(valueSum)
  }, [myFamilyFirstConfirmedData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => document.body.style.overflow = 'unset';
    }, [showMyFamilyFirstMoreInfoModal]);

    // useEffect(() => {
    //     if (myFamilyFirstConfirmedData?.length === 0) {
    //         dispatch(myFamilyFirstConfirmed(1));
    //         dispatch(pageId(1));
    //         setPage(1);
    //     }
    // }, [myFamilyFirstConfirmedData, page])

    const handleMoreInfoClick = (data) => {
        setShowMyFamilyFirstMoreInfoModal(true);
        setConfirmedMoreInfoModalData(data);
    };

    const refreshFamilyFirstConfirmed = () => {
        dispatch(familyFirstSearchRequested(page, "", 'requested'));
        dispatch(familyFirstSearchRequested(page, "", 'planning'));
        dispatch(familyFirstSearchRequested(page, "", 'confirmed'));
        dispatch(familyFirstSearchRequested(page, "", 'cancelled'));
    };

    const myFamilyFirstStatusChange = (event, id,data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;
        setStatuschange(status)
        setId(id)
     if(status === "cancelled")setRequestModal(true);
        dispatch(familyFirstStatusUpdate(status, id,null, refreshFamilyFirstConfirmed));
        dispatch(pageId(page));
    };

    const handlePagination = (e,value) => {
        setPage(value);
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(familyFirstSearchRequested(value, SEARCH_TERM, "confirmed"));
        }else{
          dispatch(myFamilyFirstConfirmed(value));
        }
        
        dispatch(pageId(value));
    };

    const handleChange = (event) => {
        if(event.target.value){
        dispatch(familyFirstSearchRequested(PAGE_NO,event.target.value, "confirmed"));
        }else{
        dispatch(myFamilyFirstConfirmed(1));
        setPage(1);
        }
        dispatch(setSearchTerm(event.target.value))
    };

    console.log("xeoe", searchVal);
    const myFamilyFisrtConfirmedDisplayData = myFamilyFirstConfirmedData && myFamilyFirstConfirmedData.map((data) => (
        <MyFamilyFirstBooking
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request ? data.specific_request : "N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={myFamilyFirstStatusChange}
            isConfirmed={true}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));
    console.log("pag", pageNum);
    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
            dispatch(familyFirstStatusUpdate(statuschange, id,closeRequestMessage, refreshFamilyFirstConfirmed));
            setRequestModal(false)
          }
          dispatch(closeRequestInputVal(''));
      };
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    return (
        <>
            <SearchBar
                searchVal={SEARCH_TERM}
                handleChange={handleChange}
            />
            {myFamilyFirstConfirmedData && myFamilyFirstConfirmedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
       <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
            {myFamilyFisrtConfirmedDisplayData}
            <MyFamilyFirstMoreInfoModal
                open={showMyFamilyFirstMoreInfoModal}
                close={() => setShowMyFamilyFirstMoreInfoModal(false)}
                modalData={confirmedMoreInfoModalData}
                isCancellationMessage={false}
            />
            <Pagination
                totalPage={totalPage}
                page={page}
                onChange={(event, value) => handlePagination(event, value)}
            />
              {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }

        </>
    );
};

export default Confirmed;
