    import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getHolidaySectionList, deleteHolidaySection, holidayImageUpload, 
  holidaySelectedImageUrl,updateHolidaySection, getSectionPackages
} from '../../../actions/holidaySectionAction'
import UpdateHolidaySection from '../../../common/addNewSection';
import { CANCEL, UPDATE, DELETE, DELETE_CONFIRMATION_MESSAGE } from '../../../utils/constants';
import SectionImageItem from '../../../common/sectionImageItem';
import DeleteConfirmationModal from '../../../common/deleteConfirmationModal';
import { useSnackbar } from "notistack";


const UpdateHolidaysSection = () => {
  const [ showHolidayUpdateSectionModal, setShowHolidayUpdateSectionModal ] = useState(false);
  const [ showHolidaySectionDeleteModal, setShowHolidaySectionDeleteModal ] = useState(false);
  const [ updateImageBool, setUpdateImageBool] = useState(false);
  const [ updateHolidaySecNameBool, setUpdateHolidaySecNameBool ] = useState(false);
  const [ updateHolidaySecDescBool, setUpdateHolidaySecDescBool ] = useState(false);
  const [ updateHolidaySecPriorityBool, setUpdateHolidaySecPriorityBool ] = useState(false);
  const [ refCodeBool, setRefCodeBool ] = useState(false);

  const [ updateHolidatSectionNameErrorBool, setUpdateHolidatSectionNameErrorBool ] = useState(false);
  const [ updateHolidatSectionDescErrorBool, setUpdateHolidatSectionDescErrorBool ] = useState(false);
  const [ updateHolidayPriorityEmptyErrorBool, setUpdateHolidayPriorityEmptyErrorBool ] = useState(false);
  const [ updateHolidayPriorityNegErrorBool, setUpdateHolidayPriorityNegErrorBool ] = useState(false);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ descMoreError, setDescMoreError ] = useState(null);
  const [ isCodeMatch, setIsCodeMatch ] = useState(false);
  
  const [ updateHolidaySectionName, setUpdateHolidaySectionName ] = useState("");
  const [ updateHolidaySectionDesc, setUpdateHolidaySectionDesc ] = useState("");
  const [ updateHolidaySectionPriority, setUpdateHolidaySectionPriority ] = useState("");
  const [ refCodeValue, setRefCodeValue] = useState("");
  const [ holidayUpdateData, setHolidayUpdateData ] = useState({});
  const [ deleteHolidaySectionData, setDeleteHolidaySectionData ] = useState({});
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const sectionTotalPack = useSelector(state => state.holidaySection.sectionPackages);
  const holidaySection = useSelector(state => state.holidaySection.getHolidaySectionList);
  const holidaySectionImgUrl = useSelector(state => state.holidaySection.holidaySelectedImgUrl);


 useEffect(() => {
  // dispatch(getHolidaySectionList())
 }, []);

 useEffect(() => {
  if(refCodeBool && refCodeValue.length === 2){
    setCodeLessErrorr(false);
    setCodeMoreError(false);
  }
 }, [refCodeBool,refCodeValue]);

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
  };

  const updateHandleSuccess = () => {
    dispatch(getHolidaySectionList());
    enqueueSnackbar("Updated successfully" ,{
      variant: "success",
    });
  };
  const updatePic = (data) => {
    dispatch(holidaySelectedImageUrl(data.url));
  };

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(holidayImageUpload(croppedFile, updatePic));
    }
    setUpdateImageBool(true);
  };

  const updateHolidaySectionNameOnchangeHandler = (e) => {
    setUpdateHolidaySecNameBool(true);
    setUpdateHolidaySectionName(e.target.value)
  };

  const updateHolidaySectionDescOnchangeHandler = (e) => {
    setUpdateHolidaySecDescBool(true);
    setUpdateHolidaySectionDesc(e.target.value)
  };

  const updateHolidayPriorityOnchangeHandler = (e) => {
    setUpdateHolidaySecPriorityBool(true);
    setUpdateHolidaySectionPriority(e.target.value)
  };

  const holidaySectionUpdateHandler = (data) => {
    setShowHolidayUpdateSectionModal(true);
    setHolidayUpdateData(data);
  };

  const refrenceCodeHandler = (e) => {
    setRefCodeBool(true)
    setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredHolidaySection = holidaySection.filter(item => {
      return code === item.code
    })
    if(filteredHolidaySection.length){
      setIsCodeMatch(true)
    }else{
      setIsCodeMatch(false)
    }
    setRefCodeValue(code)

  };

  const openDeleteConfirmationModal = (data) => {
    setShowHolidaySectionDeleteModal(true);
    setDeleteHolidaySectionData(data);
    dispatch(getSectionPackages(data))
  };

  const holidaySectionDeleteHandler = () => {
    dispatch(deleteHolidaySection(deleteHolidaySectionData, handleSuccess));
    setShowHolidaySectionDeleteModal(false)
  };

  const handleClose = () => {
      setUpdateImageBool(false);
      dispatch(holidaySelectedImageUrl(null));
      setUpdateHolidaySecNameBool(false);
      setUpdateHolidaySecDescBool(false);
      setUpdateHolidaySectionName('');
      setUpdateHolidaySectionDesc('');
      setUpdateHolidaySecPriorityBool(false);
      setUpdateHolidaySectionPriority('');
      setRefCodeBool(false);
      setRefCodeValue("")
      setShowHolidayUpdateSectionModal(false);
  }

  const sectionUpdateHolidaySubmitHandler = (e) => {
    e.preventDefault();
    setUpdateHolidatSectionNameErrorBool(false);
    setUpdateHolidayPriorityEmptyErrorBool(false);
    setUpdateHolidayPriorityNegErrorBool(false);
    setCodeLessErrorr(false);
    setIscodeError(false);
    setCodeMoreError(false);
    const data ={
      image:updateImageBool && holidaySectionImgUrl !== null ? holidaySectionImgUrl:holidayUpdateData.image,
      name:updateHolidaySecNameBool ? updateHolidaySectionName: holidayUpdateData.name,
      description:updateHolidaySecDescBool ? updateHolidaySectionDesc: holidayUpdateData.description,
      priority:updateHolidaySecPriorityBool? updateHolidaySectionPriority : holidayUpdateData.priority,
      code:refCodeBool? refCodeValue : holidayUpdateData.code,
      alt_text:altText,
      title_text:imageTitle
    }
    if(updateHolidaySecNameBool && updateHolidaySectionName === "")setUpdateHolidatSectionNameErrorBool(true);
    else if(updateHolidaySecDescBool && updateHolidaySectionDesc === "")setUpdateHolidatSectionDescErrorBool(true);
    else if(updateHolidaySecDescBool && updateHolidaySectionDesc.length > 200 )setDescMoreError(true);
    else if(updateHolidaySecPriorityBool && updateHolidaySectionPriority === "")setUpdateHolidayPriorityEmptyErrorBool(true);
    else if(updateHolidaySecPriorityBool && updateHolidaySectionPriority <= -1)setUpdateHolidayPriorityNegErrorBool(true);
    else if(refCodeBool && refCodeValue.length === 0) setIscodeError(true)
    else if(refCodeBool && refCodeValue.length < 2) setCodeLessErrorr(true)
    else if(refCodeBool && refCodeValue.length > 2) setCodeMoreError(true)
    else if(refCodeBool && refCodeValue.length == 2 && isCodeMatch) setCodeMatchError(true)
    else{
      dispatch(updateHolidaySection(data, holidayUpdateData.id, updateHandleSuccess));
      setShowHolidayUpdateSectionModal(false);
      setUpdateImageBool(false);
      setUpdateHolidaySecNameBool(false);
      setUpdateHolidaySecDescBool(false);
      setUpdateHolidaySecPriorityBool(false);
      dispatch(holidaySelectedImageUrl(null));
      setUpdateHolidaySectionName('');
      setUpdateHolidaySectionDesc('');
      setUpdateHolidaySectionPriority('');
      setRefCodeBool(false);
      setRefCodeValue("")
    }
  };

  const holidaySectionListingData = holidaySection && holidaySection.map(data => (
    <SectionImageItem 
      sectionText={data.name}
      sectionImage={data.image}
      priority={data.priority}
      sectionImageUpdateHandlere={() => holidaySectionUpdateHandler(data)}
      sectionImageDeleteHandler={() => openDeleteConfirmationModal(data)}
      isDisableDelete = {
        (data.name === "Deals" || 
        data.name === "International" || 
        data.name === "Customize" || 
        data.name === "Send a Gift" ||
        data.name === "Visas & More"||
        data.name === "Bharat Dekho") && true
      }
    />
  ))
  return (
    <>
      {holidaySectionListingData}
      {showHolidayUpdateSectionModal && 
        <UpdateHolidaySection 
        showMyModal={showHolidayUpdateSectionModal}
        openHandler={showHolidayUpdateSectionModal}
        togglebackDrop={showHolidayUpdateSectionModal}
        heading="Update Section"
        image={updateImageBool && holidaySectionImgUrl !== null ? holidaySectionImgUrl:holidayUpdateData.image}
        imageText="Image"
        labelTextOne="Section name"
        valueOne={updateHolidaySecNameBool ? updateHolidaySectionName: holidayUpdateData.name }
        valueThree={updateHolidaySecDescBool ? updateHolidaySectionDesc: holidayUpdateData.description }
        onChangeOne={updateHolidaySectionNameOnchangeHandler}
        onChangeThree={updateHolidaySectionDescOnchangeHandler}
        labelTextTwo="Priority"
        labelTextThree="Description"
        codeRefrence="Code"
        refrenceCodeHandler={refrenceCodeHandler}
        valueTwo={updateHolidaySecPriorityBool? updateHolidaySectionPriority : holidayUpdateData.priority}
        onChangeTwo={updateHolidayPriorityOnchangeHandler}
        refCodeValue={refCodeBool? refCodeValue :holidayUpdateData.code }
        btnTextOne={CANCEL}
        btnTextTwo={UPDATE}
        handleSubmit={sectionUpdateHolidaySubmitHandler}
        closeModal={() => setShowHolidayUpdateSectionModal(false)}
        closeHandle={() => handleClose()}
        handleFilePickChange={handleImageChange}
        isSectionNameError={updateHolidatSectionNameErrorBool}
        isSectionDescError={updateHolidatSectionDescErrorBool}
        isPriorityError={updateHolidayPriorityNegErrorBool}
        isPriorityEmptyError={updateHolidayPriorityEmptyErrorBool}
        iscodeError={iscodeError}
        codeLessError={codeLessError}
        descMoreError={descMoreError}
        codeMoreError={codeMoreError}
        codeMatchError={codeMatchError}
        isEventSection={true}
        isHolidayAddNewSection={false}
        isHolidayUpdateSection={true}
        isUpdateSec={true}
        isDescription={true}
        imageTitle={imageTitle}
        setImageTitle={setImageTitle}
        altText={altText}
        setAltText={setAltText}
        disabled={updateImageBool && holidaySectionImgUrl !== null || updateHolidaySectionName || updateHolidaySectionDesc || updateHolidaySectionPriority || refCodeValue ? false:true}
        pointerEvents={
          (holidayUpdateData.name === "Deals" || 
          holidayUpdateData.name === "International" || 
          holidayUpdateData.name === "Customize" || 
          holidayUpdateData.name === "Send a Gift" || 
          holidayUpdateData.name === "Visas & More" ||
          holidayUpdateData.name === "Bharat Dekho") && "none"
        }
        pointerEventsForRefCode={(holidayUpdateData.name === "Customize" || holidayUpdateData.name === "Visas & More") && "none"}
        />
      }  
      <DeleteConfirmationModal 
          open={showHolidaySectionDeleteModal}
          btnTextOne={CANCEL}
          btnTextTwo={DELETE}
          onClick={() =>setShowHolidaySectionDeleteModal(false)}
          deleteImage={holidaySectionDeleteHandler}
          disable={sectionTotalPack > 0 ? true : false}
          fontSize={sectionTotalPack > 0 ?"20px":""}
          deleteConfirmationMsg={
          sectionTotalPack > 0 ?
          `You have 
          ${sectionTotalPack} ${sectionTotalPack > 1 ? "packges": "packge"} 
          in this section please shift or delete this ${sectionTotalPack > 1 ? "packges": "packge"} to delete section`:
          DELETE_CONFIRMATION_MESSAGE
        }
      />
    </>
  )
}

export default UpdateHolidaysSection;
