import { GET_EXPERIENCES_SECTION, EXPERIENCES_CONFIRM_ADD_BOOL,  EXPERIENCES_CONFIRM_UPDATE_BOOL, EXPERIENCES_SELECTED_IMAGE_URL,
    EXPERIENCES_ADD_IMAGE, EXPERIENCE_SECTION_PACKAGES
} from '../actions/types'
const initialState = {
    getExperienceSection:[],
    experienceConfirmAddBoolVal: false,
    experienceConfirmUpdateBoolVal: false,
    experienceSelectedImageUrl: null,
    experienceAddImage: {},
    sectionPackages:null
};

const experienceSectionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_EXPERIENCES_SECTION: {
            return {
                ...state,
                getExperienceSection: payload,
            }
        }
        case EXPERIENCES_CONFIRM_ADD_BOOL: {
            return {
              ...state,
              experienceConfirmAddBoolVal: payload
            }
          }
        case EXPERIENCES_CONFIRM_UPDATE_BOOL: {
            return {
              ...state,
              experienceConfirmUpdateBoolVal: payload
            }
          }
          case EXPERIENCES_SELECTED_IMAGE_URL: {
            return {
              ...state,
              experienceSelectedImageUrl: payload
            }
          }
          case EXPERIENCES_ADD_IMAGE: {
            return {
              ...state,
              experienceAddImage: payload,
            };
          }
          case EXPERIENCE_SECTION_PACKAGES: {
            return {
              ...state,
              sectionPackages: payload
            }
          }
        default:
            return state;
    }
};

export default experienceSectionReducer;