import React from 'react';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";

const PurpleSwitch = withStyles({
    switchBase: {
      color: 'white',
      '&$checked': {
        color: '#2196F3',
      },
      '&$checked + $track': {
        backgroundColor: '#2196F3',
        opacity: 1,
      },
    },
    checked: {},
    track: {},
  })(Switch);

const ToggleButton= ({handleToggleChange, checkValue, label, name, onclick, disabled}) => {
    return (
        <div>
            <div>
                <FormControl component="fieldset">
                    <FormControlLabel
                        value="start"
                        control={
                          <div style={{marginLeft:"10px"}}
                          >
                            <PurpleSwitch
                                checked={checkValue}
                                size="small"
                                color="#2196F3"
                                name={name}
                                onChange={handleToggleChange}
                                onClick={onclick}
                                id="my-opening-soon-toggle" 
                                disabled={disabled}
                            />
                            </div>
                        }
                        label={label}
                        labelPlacement="start"
                    />
                </FormControl>
            </div>
        </div>
    );
};

export default ToggleButton;
