import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from "antd";
import Datefilter from '../../../../common/commonDateFIlter'
import Requested from './requested';
import Planning from './planning';
import Confirmed from './confirmed';
import Cancelled from './cancelled';
import * as AnniversaryAction from '../../../../actions/anniversaryAction'
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
const { TabPane } = Tabs;

const Index = () => {
  const [ status, setStatus ] = useState("requested");
  const dispatch = useDispatch();

  const totalRequestedResults = useSelector(state => state.anniversary.anniversaryRequested?.total_results);
  const totalPlanningResults = useSelector(state => state.anniversary.anniversaryPlanning?.total_results);
  const totalConfirmedResults = useSelector(state => state.anniversary.anniversaryConfirmed?.total_results);
  const totalCancelledResults = useSelector(state => state.anniversary.anniversaryCancelled?.total_results);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const START_DATE = useSelector(state => state.anniversary.startDate);
  const END_DATE = useSelector(state => state.anniversary.endDate);
  const SEARCH_TERM = useSelector(state => state.anniversary.searchTerm);
  const PAGE_NO = useSelector(state => state.anniversary.pageNo);
  
  useEffect(() => {
    dispatch(AnniversaryAction.getAnniversaryRequested(1));
    dispatch(AnniversaryAction.getAnniversaryPlanning(1));
    dispatch(AnniversaryAction.getAnniversaryConfirmed(1));
    dispatch(AnniversaryAction.getAnniversaryCancelled(1));
  }, []);

  useEffect(() => {
    // if(status === "requested") {
    //   dispatch(familyFirstSearchRequested("", 'requested'));
    // }
    // else if(status === "planning") {
    //   dispatch(familyFirstSearchRequested("", 'planning'));
    // }
    // else if(status === "confirmed") {
    //   dispatch(familyFirstSearchRequested("", 'confirmed'));
    // }
    // else if(status === "cancelled") {
    //   dispatch(familyFirstSearchRequested("", 'cancelled'));
    // }
  }, [status]);

  const handleTabChange = (key) => {
    dispatch(AnniversaryAction.setSearchTerm(''))
    
    if(key === '2') {
      dispatch(AnniversaryAction.setPageNo(1))
      setStatus('planning')
      dispatch(AnniversaryAction.searchAnniversaryRequests('', START_DATE, END_DATE, "planning", 1));
    }
    else if(key === '3') {
      dispatch(AnniversaryAction.setPageNo(1))
      setStatus('confirmed')
      dispatch(AnniversaryAction.searchAnniversaryRequests('', START_DATE, END_DATE, "confirmed", 1));
    }
    else if(key === '4') {
      dispatch(AnniversaryAction.setPageNo(1))
      setStatus('cancelled')
      dispatch(AnniversaryAction.searchAnniversaryRequests('', START_DATE, END_DATE, "cancelled", 1));
    }
    else {
      dispatch(AnniversaryAction.setPageNo(1))
      setStatus('requested')
      dispatch(AnniversaryAction.searchAnniversaryRequests('', START_DATE, END_DATE, "requested", 1));
    }
  };
  
  const overallAnniversaryRequest = 
  totalRequestedResults+ 
  totalPlanningResults+ 
  totalConfirmedResults +
  totalCancelledResults; 

  

  const handleStartDateChange = (date) => {
    dispatch(AnniversaryAction.setStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(AnniversaryAction.searchAnniversaryRequests(SEARCH_TERM, moment(date).format("YYYY-MM-DD"), null, status, PAGE_NO));
  };

  const handleEndDateChange = (date) => {
    dispatch(AnniversaryAction.setEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(AnniversaryAction.searchAnniversaryRequests(SEARCH_TERM, START_DATE, moment(date).format("YYYY-MM-DD"), status, PAGE_NO));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(AnniversaryAction.setStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(AnniversaryAction.setEndDate(null));
    dispatch(AnniversaryAction.getAnniversaryRequested(1));
    dispatch(AnniversaryAction.getAnniversaryPlanning(1));
    dispatch(AnniversaryAction.getAnniversaryConfirmed(1));
    dispatch(AnniversaryAction.getAnniversaryCancelled(1));
  };

  return (
    <>
    <div style={{ position: "absolute", right: 50 }}>
      <Datefilter 
        status={status}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        ffStartDate={START_DATE}
        ffEndDate={END_DATE}
        handleStartClear={handleStartClear}
        handleEndClear={handleEndClear}
      />
    </div>
    <div className="enquiry-tabs-holder">
      <p className="title-holder">{overallAnniversaryRequest} Anniversary Requests</p>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={`${totalRequestedResults} Requested`} key="1">
          <Requested status={status}/> 
        </TabPane>
        <TabPane tab={`${totalPlanningResults} Planning`} key="2">
          <Planning status={status}/> 
        </TabPane>
        <TabPane tab={`${totalConfirmedResults} Confirmed`} key="3">
          <Confirmed  status={status}/> 
        </TabPane>
        <TabPane tab={`${totalCancelledResults} Cancelled`} key="4">
          <Cancelled status={status}/> 
        </TabPane>
      </Tabs>
      {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      <div>
    </div>
    </div>
  </> 
  )
};

export default Index;