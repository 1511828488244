import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import ArrowUp from "../../../assets/images/upArrow.svg";
import "../../../assets/css/partials/packageDetails.scss";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import * as corpListingAction from "../../../actions/corpListingAction";
import { useSnackbar } from "notistack";
import * as PartnerAction from "../../../actions/partnerAction";
import {
  ORGANISATION_NAME, ORGANISATION_NAME_LENGTH, ADDRESS, ORGANISATION_DOMAIN, LOGO
} from '../../../utils/constants';
import CommonConfirmationPopup from "../../../common/commonConfirmationPopup";
import ToggleButton from "../../../common/toggleButton";



const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  showHide: {
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
}));

export default function EditPartnerDetails({ page, onResetpage}) {
  
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar(); 
  const accountInfo = useSelector((state) => state.partnerListing.partnerDetails);
  const regeneratedToken = useSelector((state) => state.partnerListing.regeneratedToken?.token);
  const searchTerm = useSelector((state) => state?.partnerListing?.searchTerm)
  const [currentPackage, setCurrentPackage] = useState({ accountInfo });
  const [showOrgDetails, setShowOrgDetails] = useState(false)
  const [isOrgName, setIsOrgName] = useState(null);
  const [isOrgNameLength, setIsOrgNameLength] = useState(null);
  const [isLogoValidation, setLogoValidation] = useState(null);
  const [isAddress, setIsAddress] = useState(null);
  const [isOrgDomain, setIsOrgDomain] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [empListPage, setEmpListPage] = useState(1);
  const [empSearchPage, setEmpSearchPage] = useState(1);
  const dispatch = useDispatch();
  

  useEffect(() => {
    setCurrentPackage(accountInfo);
    // setEmpListPage(1)
    // setEmpSearchPage(1)
    setIsUpdate(false)
  }, [accountInfo]);

  const handleUpdate = () => {
    setIsOrgName(false)
    setIsOrgNameLength(false)
    setIsAddress(false)
    setLogoValidation(false)
    setIsOrgDomain(false)
    if (currentPackage.name === '' || currentPackage.name === null) setIsOrgName(true)
    else if(currentPackage.name.length>50)setIsOrgNameLength(true)
    // else if (!addImageUrl?.data?.url) setLogoValidation(true)
    else if (currentPackage.location === '' || currentPackage.location === null) setIsAddress(true)
    else if(currentPackage.email ==='' || !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(currentPackage.email))setIsOrgDomain(true)
    else {
      dispatch(
        PartnerAction.updatePartnerDetails(currentPackage, handleSuccess, handleError, refreshList)
      )
    }
  };

  const handleSuccess = () => {
    enqueueSnackbar('Updated Successfully', {
      variant: "success",
    });
  }
  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  }
  const refreshList = () => {
    if(searchTerm){
      dispatch(PartnerAction.searchPartners(searchTerm));
    }else{
      dispatch(PartnerAction.partnersList(page));
    }
    dispatch(PartnerAction.getPartnerInfo(currentPackage?.id));
  }

  const handleChangeValue = (key, value) => {
    setCurrentPackage(() => ({
      ...currentPackage,
      [key]: value.target.value,
    }));
    setIsUpdate(true)

  };

  const inputFile = useRef(null);

  const handleAddNewImage = () => {
    inputFile.current.click();
  };
  const addImages = (url) => {
    setCurrentPackage(prevState => ({
      ...prevState,
      logo: url.url
    }))
    setIsUpdate(true)
  }

  const handleFilePickChange = (event) => {
    const file = event.target.files[0];
    event.target.value = "";
    //upload file and replace reponse_url for url from response
    dispatch(PartnerAction.uploadPartnerLogo(file, addImages))
  };


  const showHide = () => {
    if (showOrgDetails === true) {
      setShowOrgDetails(false)
    } else {
      setShowOrgDetails(true)
    }

  }

  const handleGenerateToken = () => {
      dispatch(PartnerAction.handleRegenerateToken(accountInfo?.id, setToken))
      // setIsUpdate(true)
    }

  const setToken = (regeneratedToken) => {
    setShowModal(false)
    enqueueSnackbar('Token Generated Successfully', {
      variant: "success",
    });
    refreshList()
  }

  const copyToken = () => {
    navigator.clipboard.writeText(currentPackage?.partner_token);
    enqueueSnackbar('Token Copied', {
      variant: "success",
    });
  }

  const hanldeSwitch = (value) => {
      setCurrentPackage(() => ({
        ...currentPackage,
        active: !value.target.checked
      }))
      setIsUpdate(true)
  }
  
  return (
    <div>
      <div className="packageDetails-holder" style={{height: "100vh"}}>
        <form>
          <div className="form-options">
            <div>
              <h2>Editing Partner</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
               <ToggleButton btnText="Deactivate Partner" hanldeSwitch={hanldeSwitch} data={currentPackage}/>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-save"
                  onClick={handleUpdate}
                  disabled={!isUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: "relative", padding: 0 }} className="form-group form-custom">
              <img src={currentPackage?.logo} style={{ height: '100%', width: '100%', borderRadius: '5px' }} />
              <div className={classes.showHide}>
                <div
                  onClick={handleAddNewImage}
                  style={{
                    padding: "6px 10px",
                    border: "1px solid white",
                    borderRadius: 8,
                    color: "#fff",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    cursor: "pointer",
                    position: 'absolute',
                    left: '20px',
                    bottom: '31px',
                    width: 'fit-content', marginLeft: 10,
                  }}
                >
                  <PublishIcon fontSize="small" />
                  <h7 style={{ fontWeight: "bold", marginLeft: 4 }}></h7>

                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFilePickChange}
                  />
                </div>
                {isLogoValidation && <p style={{color:"red", width: 'max-content'}}>{LOGO}</p>}
              </div>
            </div>
            <div
              style={{ flex: 2, marginLeft: 10, position: "relative" }}
              className="form-group form-custom"
            >
              <label for="">Partner Name</label>
              <input
                value={currentPackage && currentPackage?.name}
                type="text"
                className="form-control"
                onChange={(value) => handleChangeValue("name", value)}
                disabled={accountInfo?.active ? false : true}
              />
              {isOrgName ? <p style={{color:"red"}}>{ORGANISATION_NAME}</p>
              : isOrgNameLength ? <p style={{color:"red"}}>{ORGANISATION_NAME_LENGTH}</p> : ''
            }
            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
              className="form-group form-custom"
            >
              <div onClick={showHide}>
                {showOrgDetails ?
                  (<img
                    src={ArrowUp}
                    style={{ position: "absolute", right: "58px", bottom: "46px", cursor: "pointer" }}
                  />) :
                  (
                    <img
                      src={ArrowDown}
                      style={{ position: "absolute", right: "58px", bottom: "46px", cursor: "pointer" }}
                    />)}
              </div>
            </div>
          </div>
          {!showOrgDetails &&
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{ flex: 0.5, position: "relative" }}
                className="form-group form-custom"
              >
                <label for="">Address</label>
                <input
                  value={currentPackage?.location}
                  type="text"
                  className="form-control"
                  onChange={(value) => handleChangeValue("location", value)}
                  disabled={accountInfo?.active ? false : true}
                />
                {isAddress && <p style={{color:"red"}}>{ADDRESS}</p>}
              </div>
              <div
                style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                className="form-group form-custom"
              >
                <div style={{ position: 'relative' }}>
                  <label for="">Partner Email</label>
                  <input 
                    value={currentPackage?.email}
                    type="text"
                    className="form-control"
                    disabled={true}
                    style={{cursor: "not-allowed"}}
                    // onChange={(value) => handleChangeValue("email", value)}
                  />
                  {isOrgDomain && <p style={{color:"red"}}>{ORGANISATION_DOMAIN}</p>}
                </div>
              </div>
            </div>
          }
          <hr />

         <div
              style={{ flex: 2, marginLeft: 10, position: "relative" }}
              className="form-group form-custom"
            >
              <p for="" style={{fontSize: "16px", color: "#000", fontWeight: 500}}>Generate Token</p>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <input
                value={currentPackage && currentPackage?.partner_token}
                type="text"
                className="form-control blurry-text"
                disabled={true}
                id="token"
              />
              <div className="copy-button">
                <a 
                className={!accountInfo?.active ? "disabled" : ""} 
                onClick={accountInfo?.active  ? () => copyToken() : null}
                >Copy</a>
              </div>
              <div className="copy-button">
                <a 
                  className={!accountInfo?.active ? "disabled" : ""} 
                  onClick={accountInfo?.active  ? () => setShowModal(true) : null}>
                  Regenerate
                </a>
              </div>
            </div>
            </div>
        </form>
      </div>
      <CommonConfirmationPopup 
      open={showModal}
      heading="Regenerate Token"
      content="Please confirm you wish to regenerate token?"
      btnOneText="Cancel"
      btnTwoText="Confirm"
      handleClose={() => setShowModal(false)}
      handleClick={handleGenerateToken}
      />
    </div>
  );
}
