import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
} from "../services/apiServices";
import {
  ENQUIRYLIST,
  ENQUIRY_FOLLOW_UP_LIST,
  ENQUIRY_RESOLVED_LIST,
  UPDATE_ENQUIRY_STATUS,
  CORP_ENQ_SEARCH_VALUE,
  CORP_FOLLOWUP_SEARCH_VALUE,
  CORP_RESOLVED_SEARCH_VALUE,
  ENQUIRY_STATUS,
  FOLLOWUP_PAGE_NUM,
  RESOLVED_PAGE_NUM,
  PAGE_NUM,
  IS_CORP_DATA_LOADING
} from "./types";

export const SEARCH_ENQUIRY_FOLLOW_UP = "SEARCH_ENQUIRY_FOLLOW_UP";
export const SEARCH_ENQUIRY_ENQURIES = "SEARCH_ENQUIRY_ENQURIES";
export const SEARCH_ENQUIRY_RESOLVED = "SEARCH_ENQUIRY_RESOLVED";
export const ENQUIRY_FILTER_START_DATE = "ENQUIRY_FILTER_START_DATE";
export const ENQUIRY_FILTER_END_DATE = "ENQUIRY_FILTER_END_DATE";
// export const PAGE_NUM = "PAGE_NUM"

export const getEnquiriesList = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.ENQUIRIES_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ENQUIRYLIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }

        // else {
        //   dispatch({
        //     type: ERROR_ALERT,
        //     payload: res.data.data.message,
        //   });
        // }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getEnquiryFollowuplist = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.ENQUIRIES_FOLLOW_UP_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ENQUIRY_FOLLOW_UP_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getResolvedEnquirylist = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.ENQUIRIES_RESOLVED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ENQUIRY_RESOLVED_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const updateEnquiryStatus = (id, selectedStatus, refreshEnquiries) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      status: selectedStatus,
    };
    axiosPut(`${API.UPDATE_ENQUIRY_STATUS_API}${id}/`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: UPDATE_ENQUIRY_STATUS,
            payload: res.data,
          });
          refreshEnquiries();
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const searchEnquiry = (text, status, pageId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const startDate = getState().enquiryListing.filterStartDate;
    const endDate = getState().enquiryListing.filterEndDate;

    let body = {
      search_term: text,
      status: status,
      next_page_id: pageId,
      from: startDate,
      to: endDate,
    };
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(API.SEARCH_ENQUIRIES, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if (status === "follow-up") {
          dispatch({
            type: SEARCH_ENQUIRY_FOLLOW_UP,
            payload: res.data,
          });
        } else if (status === "enquiry") {
          dispatch({
            type: SEARCH_ENQUIRY_ENQURIES,
            payload: res.data,
          });
        } else if (status === "resolved") {
          dispatch({
            type: SEARCH_ENQUIRY_RESOLVED,
            payload: res.data,
          });
        }
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      } else {
        // console.log("res", res);
      }
    });
  };
};

export const filterStartDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ENQUIRY_FILTER_START_DATE,
      payload: date,
    });
  };
};

export const filterEndDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ENQUIRY_FILTER_END_DATE,
      payload: date,
    });
  };
};

// export const setPageNum = (value, status) => {
//   return async (dispatch) => {
//     dispatch({
//       type: PAGE_NUM,
//       payload: value,
//     });
//   }
// }

export const setPageNum = (value, status) => {
  return async (dispatch) => {
    if(status === "enquier"){
      dispatch({
        type: PAGE_NUM,
        payload: value,
      });
    }else if(status === "follow-up"){
      dispatch({
        type:FOLLOWUP_PAGE_NUM,
        payload:value
      })
    }else if(status === "resolved"){
      dispatch({
        type:RESOLVED_PAGE_NUM,
        payload:value
      })
    }
  }
}

export const corpEnquiriesSearchvalue = (value, status) => {
  return async (dispatch) => {
    if(status === "enquiry"){
      dispatch({
        type: CORP_ENQ_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "follow-up"){
      dispatch({
        type: CORP_FOLLOWUP_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "resolved"){
      dispatch({
        type: CORP_RESOLVED_SEARCH_VALUE,
        payload: value,
      });
    }
  }
};

export const enquiryStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: ENQUIRY_STATUS,
      payload: status,
    });
  }
}