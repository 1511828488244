
import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut,
  axiosGetWithToken,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress
} from "../services/apiServices";
import { 
  HONEYMOON_STORY_LIST,
  HONEYMOON_STORY_DETAILS,
  HONEYMOON_REQUESTED,
  HONEYMOON_REQUESTED_TOTAL_PAGE,
  HONEYMOON_REQUESTED_TOTAL_RESULTS,
  HONEYMOON_CONFIRMED,
  HONEYMOON_PLANNING,
  HONEYMOON_CANCELLED,
  HONEYMOON_SEARCH_TERM,
  HONEYMOON_START_DATE,
  HONEYMOON_END_DATE,
  HONEYMOON_PAGE_NO,
  HONEYMOON_DESTINATION_LIST,
  HONEYMOON_IMAGE_UPLOAD,
  HONEYMOON_DESTINATION_CREATE,
  IS_LIST_DATA_LOADING,
  IS_DATA_LOADING
} 
from '../actions/types';

export const honeymoonStoryList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{"field":"request_type", "value":"honeymoon"},{"field":"active", "value":"true"}],
       "page": 1, 
       "list_per_page": 50
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.HONEYMOON_STORY_LIST}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: HONEYMOON_STORY_LIST,
            payload: res.data.data.results,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const searchStory = (searchVal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    let body = {
      "filters":[{
        "field":"search_term", 
        "value": searchVal
      },
      {"field":"active", "value":"true"},
      {"field":"request_type", "value":"honeymoon"}
    ]
    }
    axiosPostWithToken(`${API.HONEYMOON_STORY_SEARCH}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: HONEYMOON_STORY_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};
// newStory

export const newStory = (formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPostWithToken(`${API.HONEYMOON_STORY_ADD}`, formData,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};

export const deleteStory = (storyId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosDelete(`${API.HONEYMOON_STORY_DELETE}${storyId}/`,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};
// deleteStory

export const honeymoonStoryDetails = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.HONEYMOON_STORY_DETAILS}${id}/`, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: HONEYMOON_STORY_DETAILS,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const updateStory = (id, formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPut(`${API.HONEYMOON_STORY_UPDATE}${id}/`, formData, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          handleSuccess(id);
        }
      })
  };
};

// ***********Request action***********
export const getHoneymoonRequested = (page) => {
  console.log("page", page)
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
         "field":"status",
         "value":"requested"
        },
        {"field":"request_type", "value":"honeymoon"}
      ],
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.HONEYMOON_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HONEYMOON_REQUESTED,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const getHoneymoonPlanning = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
        "field":"status",
        "value":"planning"
       },
       {"field":"request_type", "value":"honeymoon"}
      ],
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.HONEYMOON_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HONEYMOON_PLANNING,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

        }
        
      })
  };
};

export const getHoneymoonConfirmed = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"confirmed"
     },
     {"field":"request_type", "value":"honeymoon"}
    ],
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.HONEYMOON_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
        type: HONEYMOON_CONFIRMED,
        payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });

        
      }
      })
  };
};

export const getHoneymoonCancelled = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"cancelled"
     },
     {"field":"request_type", "value":"honeymoon"}
    ],
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.HONEYMOON_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: HONEYMOON_CANCELLED,
          payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

          
        }
      })
  };
};

export const searchHoneymoonRequests = ( searchTerm, startDate, endDate, status,  page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[
        {"field":"search_term", "value": searchTerm},
        {"field":"status", "value": status},
        {"field":"from", "value": startDate},
        {"field":"to", "value": endDate},
        {"field":"request_type", "value":"honeymoon"}
      ],
      "page": page, 
      "list_per_page": 50
  
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.HONEYMOON_REQUEST_SEARCH}`, body, token)
    .then((res) => {
      if(res?.data?.status_code === 200){
        if(status == "requested"){
          dispatch({
            type: HONEYMOON_REQUESTED,
            payload: res?.data?.data
          })
        }else if(status == "planning"){
          dispatch({
            type: HONEYMOON_PLANNING,
            payload: res?.data?.data
          })
        }else if(status == "confirmed"){
          dispatch({
            type: HONEYMOON_CONFIRMED,
            payload: res?.data?.data
          })
        }else{
          dispatch({
            type: HONEYMOON_CANCELLED,
            payload: res?.data?.data
          })
        }
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
       
      }
    })
  }
}

export const setSearchTerm = (value) => {
  return async (dispatch) => {
    dispatch({
      type: HONEYMOON_SEARCH_TERM,
      payload: value
    })
  }
}

export const setStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HONEYMOON_START_DATE,
      payload: date,
    });
  };
};
  
export const setEndDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HONEYMOON_END_DATE,
      payload: date,
    });
  };
};

export const setPageNo = (page) => {
  return async (dispatch) => {
    dispatch({
      type: HONEYMOON_PAGE_NO,
      payload: page,
    });
  };
};

export const honeymoonRequestStatusChange = (status, id,cancelling_message, refereshList) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    cancelling_message:cancelling_message
  };
  axiosPut(`${API.HONEYMOON_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};

export const honeymoonRequestStatusChangeConfirm = (status, id,amount, refereshList) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    "price":amount
  };
  axiosPut(`${API.HONEYMOON_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};

//************  Destination **********/
export const getHoneymoonDestinationList = () => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[
      {"field":"active", "value":"true"},
      {"field":"request_type", "value":"honeymoon"}],
    "sort":{"field":"priority", "order":"A"},
    "page": 1, 
    "list_per_page": 50 
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.HONEYMOON_DESTINATION_LIST}`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
            type: HONEYMOON_DESTINATION_LIST,
            payload: res?.data?.data?.results
          })
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
        
      })
  };
};

export const addHoneymoonDestination = (data, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "alt_text":data?.alt_text,
    "title_text":data?.title_text,
    "request_type":"honeymoon"
}
  axiosPostWithToken(`${API.HONEYMOON_DESTINATION_CREATE}`, body, token)
    .then((res) => {
      if (res.data.status_code === 201) {
          success()
        }
        
      })
  };
};

export const updateHoneymoonDestination = (data, id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "alt_text":data?.alt_text,
    "title_text":data?.title_text,
    "request_type":"honeymoon"
}
  axiosPut(`${API.HONEYMOON_DESTINATION_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          success()
        }
        
      })
  };
};

export const deleteHoneymoonDestination = (id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  
  axiosDelete(`${API.HONEYMOON_DESTINATION_DELETE}${id}/`, token)
    .then((res) => {
      if (res.data.status_code === 200) { 
          success()
        }
        
      })
  };
};

export const honeymoonImageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    function blob2file(blobData) {
      const entries = new FormData();
      entries.set('file', blobData, "fileName.jpeg");
      return entries.get('file');
    }

    axiosMediaUpload(API.HONEYMOON_IMAGE_VIDEO_UPLOAD, "honeymoon-image", blob2file(file), token)
    .then((res) => {
      dispatch({
        type: HONEYMOON_IMAGE_UPLOAD,
        payload: res.data,
      });
      addImage(res.data.data);
    })
    .catch((err) => {
      console.log("ERROR_ALERT", err);
    });
  };
};

export const videoUploadHoneymoon = (file, addVideo, obj, newArr, getUploadProgress, id) => {
    
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.HONEYMOON_IMAGE_VIDEO_UPLOAD, "honeymoon-video", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const deleteHoneymoonImage = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.HONEYMOON_IMAGE_VIDEO_DELETE}${id}/`,{"type" : "honeymoon"}, token)
      .then((res) => {
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};