import { ACCOUNTS_LIST, GET_ACCOUNT_LIST, UPDATE_ACCOUNT, UPDATE_POINTS, SET_ACCOUNT_DETAILS, UPDATE_EMPLOYEE, SEARCH_ORGANISATION, SEARCH_USER, ERROR_ALERT, EMAIL_DOMAIN_ERROR_MSG, SET_EMPLOYEE_LIST, SET_SEARCH_BOOL, SEARCH_VALUE, ORG_SEARCH_TERM} from "../actions/types";
import {
    SET_ADD_ACCOUNT
} from "../actions/accountsAction";

const initialState = {
    accountsList: [],
    accountDetails: {},
    addNewAccount: false,
    updateAccount: {},
    updatePoints: null,
    updateEmployee: {},
    searchOrganisationData: [],
    searchUserData: [],
    errorAlert: '',
    errorEmailDomain: false,
    employeeList: {},
    searchBool: false,
    searchValue: null,
    org_search_term: null
};



const accountListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACCOUNTS_LIST: {
            return {
                ...state,
                accountsList: payload,
            }
        }
        case SET_ACCOUNT_DETAILS: {
            // console.log("RESP REDUCER", payload)
            return {
                ...state,
                accountDetails: payload
            }
        }
        case SET_ADD_ACCOUNT: {
            return {
                ...state,
                addNewAccount: payload
            }
        }
        case UPDATE_ACCOUNT: {
            return {
                ...state,
                updateAccount: payload
            }
        }
        case UPDATE_POINTS: {
            return {
                ...state,
                updatePoints: payload
            }
        }
        case UPDATE_EMPLOYEE: {
            return {
                ...state,
                updateEmployee: payload
            }
        }
        case SEARCH_ORGANISATION: {
            return {
                ...state,
                searchOrganisationData: payload
            }
        }
        case SEARCH_USER: {
            return {
                ...state,
                searchUserData: payload
            }
        }
        case ERROR_ALERT: {
            return {
                ...state,
                errorAlert: payload
            }
        }
        case EMAIL_DOMAIN_ERROR_MSG: {
            return {
                ...state,
                errorEmailDomain: payload
            }
        }
        case SET_EMPLOYEE_LIST: {
            return {
                ...state,
                employeeList: payload
            }
        }
        case SET_SEARCH_BOOL: {
            return {
                ...state,
                searchBool: payload
            }
        }
        case SEARCH_VALUE: {
            return {
                ...state,
                searchValue: payload
            }
        }
        case ORG_SEARCH_TERM: {
            return {
                ...state,
                org_search_term: payload
            }
        }
        default:
            return state;
    }
};

export default accountListReducer;
