import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useEffect } from "react";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

const ToggleButton = ({btnText, hanldeSwitch, data}) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        if(data){
            setChecked(data?.active)
        }
    }, [data])
  return (
    <div>
        <div className="d-flex align-items-center toggle-button" type="button">
        <div
          className="col-md-9 d-flex align-items-center"
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
          }}
        >
          <div
            className="align-items-center"
            style={{
              color: "#000000",
              width: "115px",
              height: "16px",
              left: "13px",
              lineHeight: "16px",
              paddingLeft: "7px",
              paddingRight: "0px",
              display: "flex",
              alignItems: "center",
              fontWeight: "400",
              fontFamily: "DM Sans",
              fontSize: "12px",
            }}
          >
            {btnText}
          </div>
        </div>
        <div
          className="col-md-3"
          style={{
            textAlign: "right",
            paddingRight: "0px",
            paddingLeft: "0px",
          }}
        >
          <PurpleSwitch
            checked={!checked}
            size="small"
            width="17.5px"
            height="10px"
            top="11px"
            left="127px"
            color="#2196F3"
            name="featured"
            value="featured"
            onChange={(event) => hanldeSwitch(event)}
          />
        </div>
      </div>
    </div>
  )
}

export default ToggleButton