import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";

import EventsEditPanel from "./editEvents";
import AddNewEvent from "./addNewEvent";
import Pagination from "@material-ui/lab/Pagination";
import Listing from "./eventListing";
import DropdownFilter from "./dropDownFilter";
import * as EventListingAction from "../../../actions/eventsAction";

export default function EventListings() {
  const dispatch = useDispatch();

  const addEventListDetails = useSelector(
    (state) => state.eventsListing.addNewEvent
  );
  const selectedMenuDropdown = useSelector(
    (state) => state.eventsListing?.selectedEventsDropdown
  );
  const addEvent = useSelector((state) => state.eventsListing.addNewEvent);

  const pageID = useSelector((state) => state.eventsListing.pageId);
  const search = useSelector((state) => state.eventsListing.eventlistSearchVal);
  const eventListFromStore = useSelector(
    (state) => state.eventsListing.eventsList.data
  );

  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const eventsListingDropDownVal = useSelector(
    (state) => state.eventsListing.eventsListingDropDownvalue
  );

  useEffect(() => {
    dispatch(EventListingAction.getEventList(page));
  }, []);

  useEffect(() => {
    if (eventListFromStore && eventListFromStore?.results?.length === 0) {
      dispatch(EventListingAction.handleAllEventsImages([]));
    }
  }, [eventListFromStore && eventListFromStore?.results]);

  const handleResetPage = () => {
    setPage(1);
  };

  const handleEdit = (details) => {
    dispatch(EventListingAction.editEventListItem(details));
    dispatch(EventListingAction.setAddEventPackage(false));
  };

  const handleAdd = () => {
    dispatch(EventListingAction.setAddEventPackage(true));
    setPage(1);
    dispatch(EventListingAction.pageId(1));
  };
  const handlePagination = (e, value) => {
    setPage(value);
    if (search !== "") {
      dispatch(
        EventListingAction.eventListingSearch(
          search,
          eventsListingDropDownVal?.id,
          value
        )
      );
    } else {
      dispatch(EventListingAction.pageId(value));
      dispatch(
        EventListingAction.getEventList(value, eventsListingDropDownVal?.id)
      );
    }
  };
  const totalListItems = eventListFromStore?.total_results;
  const eventDetails = useSelector((state) => state.eventsListing.eventInfo);
  return (
    <div>
      <div style={{ position: "absolute", right: 50 }}>
        {/*<Datefilter />*/}
      </div>
      <div className="enquiry-tabs-holder">
        <div style={{ display: "flex" }}>
          <h1 className="title-holder">{totalListItems} Events Listings</h1>
          <div>
            <DropdownFilter />
          </div>
        </div>
      </div>
      <Row>
        <Col
          style={{ paddingRight: "20px", marginRight: "-20px" }}
          className="gutter-row"
          xs={24}
          lg={8}
        >
          <Row className="gutter-row overflow--scroll ">
            <Listing
              eventDetails={eventListFromStore}
              handleEdit={handleEdit}
              handleAdd={handleAdd}
              currentPage={page}
              handleResetPage={handleResetPage}
            />
          </Row>
          <Row style={{ paddingTop: "54%" }}>
            <Pagination
              count={eventListFromStore?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, value) => handlePagination(event, value)}
            />
          </Row>
        </Col>
        {
          <Col className="gutter-row" xs={24} lg={16}>
            {addEventListDetails ? (
              <div>
                <AddNewEvent />
              </div>
            ) : (
              <div>
                {eventListFromStore?.results &&
                eventListFromStore?.results.length > 0 ? (
                  <EventsEditPanel
                    // onResetpage={handleResetPage}
                    data={
                      Object.keys(eventDetails).length === 0
                        ? eventListFromStore?.results[0]
                        : eventDetails
                    }
                  />
                ) : (
                  <AddNewEvent />
                )}
              </div>
            )}
          </Col>
        }
      </Row>
    </div>
  );
}
