import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut
} from "../services/apiServices";

import { 
  GET_HOLIDAY_BOOKING_LIST,
  HOLIDAY_BOOKING_PAGE_ID,
  HOLIDAY_BOOKING_START_DATE ,
  HOLIDAY_BOOKING_END_DATE,
  GET_HOLIDAY_CONFIRMED_TOTAL,
  GET_HOLIDAY_BOOKED_TOTAL,
  GET_HOLIDAY_CANCELLED_TOTAL,
  HOLIDAY_STATUS_VALUE,
  BOOKINGS_SECTION_ID,
  GET_HOLIDAY_CONFIRMED_LIST,
  GET_HOLIDAY_CANCELLED_LIST,
  HOLIDAY_BOOKING_NEXT_PAGE_ID,
  CONFIRM_NEXT_PAGE_ID,
  CANCELLED_NEXT_PAGE_ID,
  FOLLOW_UP_CURRENT_PAGE_ID,
  CONFIRM_CURRENT_PAGE_ID,
  BOOKING_CURRENT_PAGE_ID,
  CANCELLED_CURRENT_PAGE_ID,
  HOLIDAY_BOOKING_SEARCH_VALUE,
  GET_HOLIDAY_REFUND_TOTAL,
  GET_HOLIDAY_REFUND_LIST,
  HOLIDAY_REFUND_NEXT_PAGE_ID,
  REFUND_CURRENT_PAGE_ID,
  HOLIDAY_CONFIRMED_SEARCH_VALUE,
  HOLIDAY_CANCELLED_SEARCH_VALUE,
  HOLIDAY_AVAILABILITY_REQUEST_LIST,
  HOLIDAY_AVAILABILITY_SEARCH_VALUE,
  HOLIDAY_FOLLOWUP_SEARCH_VALUE,
  IS_DATA_LOADING,
  GET_HOLIDAY_FOLLOW_UP_TOTAL,
  GET_HOLIDAY_FOLLOW_UP_LIST,
  HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID,
  HOLIDAY_REFUND_SEARCH_VALUE
  
} from '../actions/types'

export const getHolidayBookingList = (pageId, secId, status) => {
  console.log("status", status)
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        
        "page_size":50,
        "next_page_id":pageId,
        "sec_id":secId,
        "status":status,
       
      };
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_HOLIDAY_BOOKING}`,body, token)
        .then((res) => {
          console.log(res,">>>>>>>>>>");
          if (res.data.status_code === 200) {
            if(status === 'confirmed') {
              dispatch({
                type: GET_HOLIDAY_CONFIRMED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_CONFIRMED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:CONFIRM_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:CONFIRM_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }
            else if(status === 'booked') {
              dispatch({
                type: GET_HOLIDAY_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_BOOKING_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:HOLIDAY_BOOKING_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:BOOKING_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }

            else if(status === 'refunded') {
              dispatch({
                type: GET_HOLIDAY_REFUND_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_REFUND_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:HOLIDAY_REFUND_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:REFUND_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }



            else if(status === 'follow_up') {
              dispatch({
                type: GET_HOLIDAY_FOLLOW_UP_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_FOLLOW_UP_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:HOLIDAY_FOLLOW_UP_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:FOLLOW_UP_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }

            else if(status === 'cancelled') {
              dispatch({
                type: GET_HOLIDAY_CANCELLED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_CANCELLED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:CANCELLED_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:CANCELLED_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }else if(status === "requested"){
              dispatch({
                type: HOLIDAY_AVAILABILITY_REQUEST_LIST,
                payload: res.data.data,
              });
            // dispatch({
            //   type: GET_HOLIDAY_BOOKING_LIST,
            //   payload: res.data.data,
            // });
            }
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

export const holidayBookingsSearch = (pageID, status, searchTerm, secId, startDate, endDate,page_size) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        "page_size":50,
        "next_page_id": pageID,
        "status": status,
        "sec_id":secId,
        "search_term": searchTerm,
        "from": startDate,
        "to": endDate,
     
      }
      dispatch({
        type: IS_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.HOLIDAY_BOOKING_SEARCH}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            if(status === "requested"){
              console.log("requested search", res)
              dispatch({
                type: HOLIDAY_AVAILABILITY_REQUEST_LIST,
                payload: res.data.data,
              });
            }
            else if(status === "booked"){
              dispatch({
                type: GET_HOLIDAY_BOOKING_LIST,
                payload: res.data.data,
              })
              dispatch({
                type: GET_HOLIDAY_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "follow_up"){
              dispatch({
                type: GET_HOLIDAY_FOLLOW_UP_LIST,
                payload: res.data.data,
              })
              dispatch({
                type: GET_HOLIDAY_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "confirmed"){
              dispatch({
                type: GET_HOLIDAY_CONFIRMED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type: GET_HOLIDAY_CONFIRMED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "refunded"){
              dispatch({
                type: GET_HOLIDAY_REFUND_LIST,
                payload: res.data.data,
              });
              dispatch({
                type: GET_HOLIDAY_REFUND_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "cancelled"){
              dispatch({
                type: GET_HOLIDAY_CANCELLED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_HOLIDAY_CANCELLED_LIST,
                payload: res.data.data,
              });
            }
            dispatch({
              type: IS_DATA_LOADING,
              payload: false,
            });
          } 
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
};

export const holidayBookingStatusUpdate = (status, id, corporate,secId,cancelling_message, refreshFuction, data, guestData, amount ) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const statusVal = getState().holidayBooking.holidayStatusVal;
    
    const bodyForRequested = {
      "status": status,
      "booking_id": id,
      "corporate": corporate,
      sec_id:secId,

      schedule:data?.schedule,
      adults:guestData?.adults,
      children:guestData?.children,
      infants:guestData?.infants,
      offline_payment:true,
      amount_paid:parseInt(amount)
    }
    const bodyForAll = {
      "status": status,
      "booking_id": id,
      "corporate": corporate,
      sec_id:secId,
      "cancelling_message":cancelling_message
    }
    axiosPut(`${API.HOLIDAY_BOOKING_STATUS_UPDATE}`,(statusVal === "requested" || statusVal==="follow_up") && status === "confirmed" ?  bodyForRequested : bodyForAll, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshFuction()
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

  export const pageId = (page) => {
    return async (dispatch) => {
      dispatch({
        type:HOLIDAY_BOOKING_PAGE_ID,
        payload: page,
      })
    
    };
  };

  export const myHolidayBookingStartDate = (date) => {
    return async (dispatch) => {
      dispatch({
        type: HOLIDAY_BOOKING_START_DATE,
        payload: date,
      });
    };
  };
    
  export const myHolidayBookingEndDate = (date) => {
    return async (dispatch) => {
      dispatch({
        type: HOLIDAY_BOOKING_END_DATE,
        payload: date,
      });
    };
  };

  export const getHolidaysStatus = (statusVal) => {
    return async (dispatch) => {
      dispatch({
        type: HOLIDAY_STATUS_VALUE,
        payload: statusVal,
      });
    };
  };

  export const bookingsSectionId = (id) => {
    return async (dispatch) => {
      dispatch({
        type: BOOKINGS_SECTION_ID,
        payload: id,
      });
    };
  };

  export const holidayBookingSearchValue = (value, status) => {
    return async (dispatch) => {
      if(status === "booked"){
        dispatch({
          type: HOLIDAY_BOOKING_SEARCH_VALUE,
          payload: value,
        });
      }else if(status === "confirmed"){
        dispatch({
          type: HOLIDAY_CONFIRMED_SEARCH_VALUE,
          payload: value,
        });
      }else if(status === "cancelled"){
        dispatch({
          type: HOLIDAY_CANCELLED_SEARCH_VALUE,
          payload: value,
        });
      }else if(status === "requested"){
        dispatch({
          type: HOLIDAY_AVAILABILITY_SEARCH_VALUE,
          payload: value,
        });
      }
      else if(status === "follow_up"){
        dispatch({
          type: HOLIDAY_FOLLOWUP_SEARCH_VALUE,
          payload: value,
        });
      }
      else if(status === "refunded"){
        dispatch({
          type: HOLIDAY_REFUND_SEARCH_VALUE,
          payload: value,
        });
      }
      
    
    
    };
  };