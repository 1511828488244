import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
// import TimePicker from 'react-time-picker';
import moment from 'moment'
import * as ExperiencesAction from '../../../actions/experienceListingAction';
import './listing.scss';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { constant } from 'lodash';
import DateRangePopUp from './dateRangePopUp';
import SpecificDatePopUp from './specificDatePopUp';
import { ImCross } from 'react-icons/im';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        height: '55%',
        width: '60%',
        backgroundColor: '#F5F5F5'
    },

}));

export default function ShedulePopup({ openPopup, closeModal, updatePopupData, updateRecurringData, updateScheduledTimeData, updateDateRangeData, currentPackageData, recurringOriginal, isSpecificDisabled, isRecurringDisabled }) {

    var newSelectedDays = [];
    let newDates = currentPackageData.schedule_data.newSelectedDyas;
    if (currentPackageData.schedule_type === "recurring") {
        for (var i = 0; i < newDates.length; i++) {
            if (newDates[i] === 0) {
                newSelectedDays.push("Sunday");
            } else if (newDates[i] === 1) {
                newSelectedDays.push("Monday");
            } else if (newDates[i] === 2) {
                newSelectedDays.push("Tuesday");
            } else if (newDates[i] === 3) {
                newSelectedDays.push("Wednesday");
            } else if (newDates[i] === 4) {
                newSelectedDays.push("Thursday");
            } else if (newDates[i] === 5) {
                newSelectedDays.push("Friday");
            } else if (newDates[i] === 6) {
                newSelectedDays.push("Saturday");
            }
        }
    }
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedDay = useSelector((state) => state?.experiencesListing?.selected_days);
    const selectedDate = useSelector((state) => state?.experiencesListing?.selected_dates);
    const selectedRange = useSelector((state) => state?.experiencesListing?.selected_range);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [days, setDays] = useState(currentPackageData.schedule_data.days);
    const [nights, setNights] = useState(currentPackageData.schedule_data.nights);
    const [isScheduleDateErrorValidation, setIsScheduleDateErrorValidation] = useState(null)
    const [isScheduleNightDateErrorValidation, setIsScheduleNightDateErrorValidation] = useState(null)
    const [recurringHours, setRecurringHours] = useState(currentPackageData.schedule_data.recurringHours);
    const [recurringMinutes, setRecurringMinutes] = useState(currentPackageData.schedule_data.recurringMinutes);
    const [hours, setHours] = useState(currentPackageData.schedule_data.hours);
    const [minutes, setMinutes] = useState(currentPackageData.schedule_data.minutes);
    const [isScheduleHoursErrorValidation, setIsScheduleHoursErrorValidation] = useState(null)
    const [isScheduleMinutesErrorValidation, setIsScheduleMinutesErrorValidation] = useState(null)
    const [isScheduleNightMatchErrorValidation, setIsScheduleNightMatchErrorValidation] = useState(false);
    const [weekDays, setWeekDays] = useState(newSelectedDays);
    const [value, onChange] = useState('10:00')
    const [date, setDate] = useState(new DateObject());
    const [selectedDays, setSelectedDays] = useState(null);
    const [startTime, setStartTime] = useState(new Date())
    // const [startTime, setStartTime] = useState("select start time")
    const [endTime, setEndTime] = useState(new Date())
    const [isDateErrorValidation, setIsDateErrorValidation] = useState(null)
    const maxTime = new Date("01/02/2021 12:00 PM");
    const [dateRange, setDateRange] = useState(currentPackageData?.schedule_data?.selectedDates);
    const [specificDate, setSpecificDate] = useState(currentPackageData.schedule_data.multipleDates);
    const [isPackage, setIspackage] = useState(false);
    const [isDateRange, setIsDateRange] = useState(false);
    const [isSpecificDate, setIsSpecificDate] = useState(false);
    const [isRecurring, setIsRecurringe] = useState(false);
    const [dublicateData,setDublicateDate]=useState(null)
    const [schError, setSchError] = useState({
        hasError:false,
        msg:''
    });
    
   useEffect(() => {
      if(currentPackageData.schedule_type === "specific_dates") {
        displaySpecificDates()
      }else if(currentPackageData.schedule_type === "package"){
        displayPackage()
      }else if(currentPackageData.schedule_type === "recurring"){
        displayRecurring()  
    }else{
        displayDateRange()
      }
   }, [currentPackageData]);

    const clearSchError = ()=>{
        setSchError({
            hasError:false,
            msg:''
        });
    };
    const updateSchError = (hasEr, erMsg)=>{
        setSchError({
            hasError:hasEr,
            msg:erMsg
        });
    }
    const handleClickOpen = () => {
        setOpen(closeModal);
    };

    const handleClose = () => {
        setOpen(closeModal);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleDaysCount = (event) => {
        setIsScheduleDateErrorValidation(false)
        const days = event.target.value
        setDays(days)
        if (days <=0) setIsScheduleDateErrorValidation(true);
        if(days>0){
            setNights(days-1)
            if(nights < 0)setIsScheduleNightDateErrorValidation(true)

        }
        if(!days){
            setNights(" ")
        }
    }

    // const handleNightsCount = (event) => {
    //     setIsScheduleNightDateErrorValidation(false)
    //     const nights = event.target.value
    //     setNights(nights)
    //     if (nights < 0) setIsScheduleNightDateErrorValidation(true)
    // }

    const upDateDaysAndNightsHandler = () => {
        if(!days || nights<0){
            updateSchError(true, 'Please enter No. of Days / No. Nights');
            return false;
        } 
        if(parseInt(days)-parseInt(nights)>1 || parseInt(days)-parseInt(nights)<-1){
            setIsScheduleNightMatchErrorValidation(true);
            return false;
        }
        if (days <=0) setIsScheduleDateErrorValidation(true);
        else if (nights < 0) setIsScheduleNightDateErrorValidation(true)
        else {
            updatePopupData(days, nights, "package")
        }

    }

    const handleRecurringHoursCount = (event) => {
        setIsScheduleHoursErrorValidation(false)
        const hours = event.target.value
        setRecurringHours(hours)
        if (hours < 0) setIsScheduleHoursErrorValidation(true);
    }

    const handleRecurringMinutesCount = (event) => {
        setIsScheduleMinutesErrorValidation(false)
        const minutes = event.target.value
        setRecurringMinutes(minutes)
        if (minutes < 0) setIsScheduleMinutesErrorValidation(true)
    }

    const handleHoursCount = (event) => {
        setIsScheduleHoursErrorValidation(false)
        const hours = event.target.value
        setHours(hours)
        if (hours < 0) setIsScheduleHoursErrorValidation(true);
    }

    const handleMinutesCount = (event) => {
        setIsScheduleMinutesErrorValidation(false)
        const minutes = event.target.value
        setMinutes(minutes)
        if (minutes < 0) setIsScheduleMinutesErrorValidation(true)
    }

    var array = []
    const handleDays = (event) => {
        var checkboxes = document.querySelectorAll('input[class="weekday"]:checked')
        for (var i = 0; i < checkboxes.length; i++) {
            array.push(checkboxes[i].value)
            const indexi = array.indexOf("start");
            if (indexi > -1) {
                array.splice(indexi, 1);
            }
        }
        dispatch(ExperiencesAction.setSelectedDay(array))
        console.log(array);
        setWeekDays(array);
    };

    const upDateHoursAndMinutesHandler = () => {

        if(!weekDays.length){
            updateSchError(true, 'Please select Recurring week');
            return false;
        }
        if(!recurringHours && !recurringMinutes){
            updateSchError(true, 'Please select Time Duration');
            return false;
        }
        // return false;
        if (hours < 0) setIsScheduleHoursErrorValidation(true);
        else if (minutes < 0) setIsScheduleMinutesErrorValidation(true)
        else {
            updateRecurringData(weekDays, recurringHours, recurringMinutes, "recurring")
            console.log("array", weekDays);
            clearSchError();
        }

    }

    const dateChange = (date) => {
        setDate(date.format('DD-MM-YYYY'))
        console.log("datesss", date.format('DD-MM-YYYY'));
    }

    const startTimeChange = (startTime) => {
        console.log("startTime ====startTimeChange", startTime)
        if (startTime !== null) setStartTime(startTime)
    };

    const endTimeChange = (endTime) => {
        setEndTime(endTime)
    };


    const displaySpecificDates = () => {
        setIsSpecificDate(true)
        setIsRecurringe(false);
        setIspackage(false)
        setIsDateRange(false)
        clearSchError();
        // document.getElementById("packagesDates").style.display = "none"
        // document.getElementById("recurring").style.display = "none"
        // document.getElementById("dateRange").style.display = "none"
        // document.getElementById("specificDates").style.display = "block"
    };

    const displayPackage = () => {
        setIsSpecificDate(false)
        setIsRecurringe(false);
        setIspackage(true)
        setIsDateRange(false)
        clearSchError();
        // document.getElementById("specificDates").style.display = "none"
        // document.getElementById("recurring").style.display = "none"
        // document.getElementById("dateRange").style.display = "none"
        // document.getElementById("packagesDates").style.display = "block"
    };

    const displayRecurring = () => {
        setIsSpecificDate(false)
        setIsRecurringe(true);
        setIspackage(false)
        setIsDateRange(false)
        clearSchError();
        // document.getElementById("specificDates").style.display = "none"
        // document.getElementById("packagesDates").style.display = "none"
        // document.getElementById("dateRange").style.display = "none"
        // document.getElementById("recurring").style.display = "block"
    };

    const displayDateRange = () => {
        setIsSpecificDate(false)
        setIsRecurringe(false);
        setIspackage(false)
        setIsDateRange(true)
        clearSchError();
        // document.getElementById("specificDates").style.display = "none"
        // document.getElementById("packagesDates").style.display = "none"
        // document.getElementById("recurring").style.display = "none"
        // document.getElementById("dateRange").style.display = "block"
    }

    const addDateRange = (data) => {
        setDublicateDate(null)
        if(dateRange){
            const arr = [...dateRange, data]
            let resDate = arr.filter(d => {
                return moment(d.from, 'DD-MM-YYYY'); ;
            });
            resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
         
                let joinedDate =data.from + data.to
                dateRange.map(e => {
                    let joined = e.from + e.to
                    if (joinedDate == joined) {
                        setDublicateDate(data.id)
                    }
                })
                setDateRange(resDate);
            
        } else {
            setDateRange([data]);
        }
        
        console.log("data", data);

    };
    useEffect(()=>{
        if(dublicateData){
            setDateRange(dateRange.filter(item => item.id !== dublicateData)); 
        }
    },[dublicateData])
    const dateRangeHandler = () => {
        console.log(dateRange);
        if(!dateRange || !dateRange.length){
            updateSchError(true, 'Please add  Date range');
            return false;
        }
        
        updateDateRangeData(dateRange, "date_range");
        setOpen(closeModal);
    };

    const deleteData = (id) => {
        let deleteDateData = dateRange.filter(item => item.id !== id);
        setDateRange(deleteDateData);
    };

    const deleteRangeHandler = () => {
        setDateRange([]);
    }

    const addSpecificDate = (data) => {
        data.forEach(element => {
            moment(element).format('Do MMMM');
        });
        setSpecificDate(data);
        //console.log("Data", specificDate);
    };

    const specificDateHandler = () => {

        console.log(specificDate);
        console.log(hours);
        console.log(minutes);
        if(!specificDate || !specificDate.length){
            updateSchError(true, 'Please Select Dates');
            return false;
        }
        if(!hours && !minutes){
            updateSchError(true, 'Please Enter Time Duration');  
            return false;
        }
        if (hours < 0) setIsScheduleHoursErrorValidation(true);
        else if (minutes < 0) setIsScheduleMinutesErrorValidation(true)
        else {
            updateScheduledTimeData(specificDate, hours, minutes, "specific_dates")
        }
    };

    const deleteSpecificData = () => {
        setSpecificDate([]);
    };

    return (
        <React.Fragment>

            <Dialog
                PaperProps={{
                    style: {
                        height: "580px"
                    },
                }}

                maxWidth={maxWidth}
                open={openPopup}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle id="max-width-dialog-title" style={{ marginLeft: '25px', overflow: 'hodden' }}>Add Schedule</DialogTitle>
                <DialogContent>
                    <input type="radio" onClick={displayDateRange} name="Button" class="ButtonState" id="Button3" value="1" />
                    <label class="Button" for="Button3" style={{border: `${isDateRange ? "solid 2px #2296F3" : ""}`}}>Date range</label>
                    <input type="radio" onClick={displaySpecificDates} name="Button" class="ButtonState" id="Button2" value="2" style={{border: `${isSpecificDate ? "solid 2px #2296F3" : ""}`}}/>
                    {!isSpecificDisabled &&<label class="Button" for="Button2" style={{border: `${isSpecificDate ? "solid 2px #2296F3" : ""}`}}>Specific Dates</label>}
                    <input type="radio" onClick={displayRecurring} name="Button" class="ButtonState"  id="Button1" value="3" />
                    {!isRecurringDisabled && <label class="Button" for="Button1"  style={{border: `${isRecurring ? "solid 2px #2296F3" : ""}`}}>Recurring</label>}
                    <input type="radio" onClick={displayPackage} name="Button" class="ButtonState" id="Button4" value="4" />
                    <label class="Button" for="Button4" style={{border: `${isPackage ? "solid 2px #2296F3" : ""}`}}>Package</label>
                    {isRecurring && 
                    <DialogContent id="recurring">
                        {schError.hasError&&<div  style={{ color: "red", marginTop:'5px' }}>{schError.msg}</div>}
                        <div style={{ display: "flex" }}>
                            <div style={{ backgroundColor: 'white', marginTop: '10px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom weekDays-selector">
                                <input type="checkbox" onClick={handleDays} value="Sunday" id="weekday-sun" class="weekday" />
                                <label for="weekday-sun">Sunday</label>
                                <input type="checkbox" onClick={handleDays} value="Monday" id="weekday-mon" class="weekday" />
                                <label for="weekday-mon">Monday</label>
                                <input type="checkbox" onClick={handleDays} value="Tuesday" id="weekday-tue" class="weekday" />
                                <label for="weekday-tue">Tuesday</label>
                                <input type="checkbox" onClick={handleDays} value="Wednesday" id="weekday-wed" class="weekday" />
                                <label for="weekday-wed">Wednesday</label>
                                <input type="checkbox" onClick={handleDays} value="Thursday" id="weekday-thu" class="weekday" />
                                <label for="weekday-thu">Thursday</label>
                                <input type="checkbox" onClick={handleDays} value="Friday" id="weekday-fri" class="weekday" />
                                <label for="weekday-fri">Friday</label>
                                <input type="checkbox" onClick={handleDays} value="Saturday" id="weekday-sat" class="weekday" />
                                <label for="weekday-sat">Saturday</label>
                            </div>
                            <div style={{ backgroundColor: 'white', marginTop: '10px', marginLeft: '50px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                                <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Time Duration</label><br />
                                <label>Hours</label>
                                <input
                                    type="number"
                                    placeholder="Enter Hours"
                                    value={recurringHours}
                                    min='0'
                                    className="form-control"
                                    onChange={handleRecurringHoursCount}
                                />
                                {isScheduleHoursErrorValidation && <p style={{ color: "red" }}>Hours should not be negative value</p>}
                                <label style={{ marginTop: '10px' }}>Minutes</label>
                                <input
                                    type="number"
                                    placeholder="Enter Minutes"
                                    value={recurringMinutes}
                                    min='0'
                                    className="form-control"
                                    onChange={handleRecurringMinutesCount}
                                />
                                {isScheduleMinutesErrorValidation && <p style={{ color: "red" }}>Minutes should not be negative value</p>}
                            </div>
                            <div
                                style={{ width: '24%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{days}Days, {nights}Nights</div> */}
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{`${days?.length > 1 ? ${days} days}`} {nights}Nights</div> */}
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>

                                        {/* Every {selectedDay && selectedDay.length > 0 && selectedDay.map((weekdays) => (<span>{weekdays}s, </span>))}<br /> */}
                                        Every {weekDays && weekDays.length > 0 && weekDays.map((weekdays) => (<span>{weekdays}s, </span>))}<br />
                                        {recurringHours > 1 ? `${recurringHours} hr ` : `${recurringHours === undefined ? '' : recurringHours} hr `}
                                        {recurringMinutes > 1 ? `${recurringMinutes} min` : `${recurringMinutes === undefined ? '' : recurringMinutes} min`}
                                    </div>

                                    <button onClick={upDateHoursAndMinutesHandler} style={{ marginTop: '10px', height: '38px', width: '80px', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Done</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    }
                    {isSpecificDate && 
                    
                    <DialogContent className="" id="specificDates">
                        {schError.hasError&&<div  style={{ color: "red", marginTop:'5px' }}>{schError.msg}</div>}
                        <div style={{ display: "flex" }}>
                            <div
                                style={{ flex: 0.5, position: "relative", paddingRight: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                {
                                    <SpecificDatePopUp
                                        addSpecificDateArray={addSpecificDate}

                                    />
                                }
                            </div>
                            <div style={{ backgroundColor: 'white', marginTop: '10px', marginLeft: '50px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                                <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Time Duration</label><br />
                                <label>Hours</label>
                                <input
                                    type="number"
                                    placeholder="Enter Hours"
                                    value={hours}
                                    min='0'
                                    className="form-control"
                                    onChange={handleHoursCount}
                                />
                                {isScheduleHoursErrorValidation && <p style={{ color: "red" }}>Hours should not be negative value</p>}
                                <label style={{ marginTop: '10px' }}>Minutes</label>
                                <input
                                    type="number"
                                    placeholder="Enter Minutes"
                                    value={minutes}
                                    min='0'
                                    className="form-control"
                                    onChange={handleMinutesCount}
                                />
                                {isScheduleMinutesErrorValidation && <p style={{ color: "red" }}>Minutes should not be negative value</p>}
                            </div>
                            <div
                                style={{ width: '24%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{days}Days, {nights}Nights</div> */}
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{`${days?.length > 1 ? ${days} days}`} {nights}Nights</div> */}
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>
                                        {/* {specificDate?.length > 1 ? `${specificDate.map(item => item)}` : `${specificDate === undefined ? '' : specificDate.map(item => item)}`}, */}
                                        {specificDate && specificDate.map(item => (
                                            <div>
                                                {moment(item).format("Do MMMM")},
                                            </div>
                                        ))}
                                        {/* {selectedDate && selectedDate.length > 0 && selectedDate.map((specificDate) => (<span>{specificDate}, </span>))}<br /> */}
                                        {hours > 1 ? `${hours} hr ` : `${hours === undefined ? '' : hours} hr `}
                                        {minutes > 1 ? `${minutes} min` : `${minutes === undefined ? '' : minutes} min`}
                                    </div>
                                    <button onClick={deleteSpecificData} style={{ marginTop: '10px', height: '38px', width: '140px', backgroundColor: '#F5F5F5', color: 'black', borderRadius: '5px', border: 'none' }}>
                                        <span>Delete</span>
                                    </button>
                                    <button onClick={specificDateHandler} style={{ marginTop: '10px', height: '38px', width: '140px', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    }
                     {isDateRange && 
                     
                    <DialogContent className="" id="dateRange">
                        {schError.hasError&&<div  style={{ color: "red", marginTop:'5px' }}>{schError.msg}</div>}
                        {dublicateData && <p style={{color:"red", margin: "9px 0px 0px 0px"}}>This daterange already added </p>}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                style={{ flex: 0.5, position: "relative", paddingRight: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                {
                                    <DateRangePopUp addArray={addDateRange} />
                                }

                            </div>

                            <div
                                style={{ padding: '0px', width: '30%', height: 'max-content', marginLeft: '50px', marginRight: '-13px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{days}Days, {nights}Nights</div> */}
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{`${days?.length > 1 ? ${days} days}`} {nights}Nights</div> */}
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>

                                        {dateRange && dateRange.map(item => (
                                            <div>
                                                <p>{`${item.from} to ${item.to}  `}<span className="cross-icon" onClick={() => deleteData(item.id)}><ImCross /></span></p>
                                            </div>
                                        ))}

                                    </div>
                                    <button onClick={deleteRangeHandler} style={{ display: 'inline', marginTop: '10px', height: '38px', width: '60px', backgroundColor: '#F5F5F5', color: 'black', borderRadius: '5px', border: 'none' }}>
                                        <span>Delete</span>
                                    </button>
                                    <button onClick={dateRangeHandler} style={{ display: 'inline', margin: '10px', height: '38px', width: '80px', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                     }
                     {isPackage && 
                    <DialogContent className="" id="packagesDates">
                        {schError.hasError&&<div  style={{ color: "red", marginTop:'5px' }}>{schError.msg}</div>}
                        <div style={{ display: "flex" }}>
                            <div style={{ backgroundColor: 'white', marginTop: '10px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                                <label for="">{`${days?.length > 0 ? "No of Days" : "No of Days"}`}</label>
                                <input
                                    type="number"
                                    placeholder="Days"
                                    value={days}
                                    min='1'
                                    className="form-control"
                                    onChange={handleDaysCount}
                                />
                                {isScheduleDateErrorValidation && <p style={{ color: "red" }}>Days should not be negative value or zero</p>}
                                <label style={{ marginTop: '10px' }}>No of Nights</label>
                                <input
                                    type="number"
                                    placeholder="Nights"
                                    value={nights}
                                    min='0'
                                    className="form-control"
                                    disabled={true}
                                    // onChange={handleNightsCount}
                                />
                                {isScheduleNightDateErrorValidation && <p style={{ color: "red" }}>Nights should not be negative value</p>}
                                {isScheduleNightMatchErrorValidation && <p style={{color:"red"}}>No of Days/Nights doesn't match</p>}
                            </div>
                            <div
                                style={{ width: '24%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{days}Days, {nights}Nights</div> */}
                                    {/* <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>{`${days?.length > 1 ? ${days} days}`} {nights}Nights</div> */}
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>
                                        {/* {days?.length > 1 ? `${days} days` : `${days === undefined ? '' : days} day`}, */}
                                        {days > 1 ? `${days} days` : `${days === undefined ? '' : days} day`},
                                        {nights > 1 ? ` ${nights} nights` : ` ${nights === undefined ? '' : nights} night`}
                                    </div>

                                    <button onClick={upDateDaysAndNightsHandler} style={{ marginTop: '10px', height: '38px', width: '80px', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Done</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                     }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
