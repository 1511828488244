import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from '../../../assets/images/rightArrow.svg'
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import * as EventsBookingAction from "../../../actions/eventsAction";
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TransactionDetailsModal from './transactionDetailsModal';
import CloseIcon from '../../../assets/images/close.svg'
import CsvIcon from '../../../assets/images/csvIcon.svg';
import { API } from "../../../constants/api";
import { useSnackbar } from "notistack";
import SearchBar from './searchBar';
import Pagination from '@material-ui/lab/Pagination';

function CancelledBookings({ cancelledBookings, onResetPage, setPageChange, selectedPage }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [userBooking, setUserBookings] = useState([])
    const [totalBookings, setTotalBookings] = useState(0)
    const [totalConfirmed, setTotalConfirmed] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalPoints, setTotalPoints] = useState(0)
    const [totalCancelled, setTotalCancelled] = useState(0)
    const [payMode, setPayMode] = useState("")
    const searchValue = useSelector((state) => state.eventsListing.eventCancelledSearchVal);
    const [totalSuccess, setTotalSuccess] = useState(0)
    const [totalFailed, setTotalFailed] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [searchVal, setSearchVal] = useState("");
    const initialPackageBookings = cancelledBookings && cancelledBookings[0];
    const getCatId = useSelector(state => state?.eventsListing?.cancelled_event_category_id);
    const getSecId = useSelector(state => state?.eventsListing?.getSecId);
    const transactionDetails = useSelector(state => state?.eventsListing?.transaction_details);
    const modifiedUserBooking = useSelector(state => state?.eventsListing?.modified_user_bookings?.data?.results);
    const modifiedUserBookingPages = useSelector(state => state?.eventsListing?.modified_user_bookings?.data);
    const [catId, setCatId] = useState(getCatId);
    const [page, setPage] = useState(1);
    const [userPage, setUserPage] = useState(1);

    useEffect(() => {
        dispatch(EventsBookingAction.setCancelledCategoryId(initialPackageBookings?.id));
        setUserBookings(initialPackageBookings?.user_bookings);
        setTotalBookings(initialPackageBookings?.total_bookings);
        setTotalConfirmed(initialPackageBookings?.total_confirmed);
        setTotalCancelled(initialPackageBookings?.total_cancelled);
        setTotalAmount(initialPackageBookings?.total_amount);
        setTotalPoints(initialPackageBookings?.total_points);
        setTotalSuccess(initialPackageBookings?.total_success);
        setTotalFailed(initialPackageBookings?.total_fail);
        setCatId(initialPackageBookings?.id);
    }, [initialPackageBookings]);

    useEffect(() => {
        if(getSecId){
            dispatch(EventsBookingAction.getCancelledBookingsList(selectedPage, getSecId));
        } else {
            dispatch(EventsBookingAction.getCancelledBookingsList(selectedPage));
            dispatch(EventsBookingAction.getUserBookingList(page, catId));
        }
        
    }, [page, selectedPage]);

    useEffect(() => {
        dispatch(EventsBookingAction.setCategoryId(cancelledBookings[0]?.id));
        dispatch(EventsBookingAction.getUserBookingList(page, cancelledBookings[0]?.id));
    }, [cancelledBookings]);

    useEffect(() => {
        dispatch(EventsBookingAction.eventBookingSearchValue('', 'cancelled'));
    //     if(getSecId){
    //         dispatch(EventsBookingAction.getCancelledBookingsList(selectedPage, getSecId));
    //     } else {
    //         dispatch(EventsBookingAction.getCancelledBookingsList(page));
    //         dispatch(EventsBookingAction.getUserBookingList(page, cancelledBookings[0]?.id));
    //     }
    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
            marginTop: 10,
            boxShadow: "none",
            background: "#FAFAFA",
            border: "1px solid #EDEDED",
            borderRadius: "4px",
            marginBottom: "10px",
            padding: "18px 20px",
            minHeight: 78,
            cursor: "pointer",
            display: 'flex',
            justifyContent: 'space-between'
        },
        rootBorder: {
            position: 'relative',
            marginTop: 10,
            boxShadow: "none",
            background: "#FAFAFA",
            border: "2px solid #000000",
            borderRadius: "4px",
            marginBottom: "10px",
            padding: "18px 20px",
            minHeight: 78,
            cursor: "pointer"
        },
        row: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
        },
        title: {
            fontSize: 16,
            marginBottom: 4,
            fontWeight: 500,
            color: "#000000",
        },
        rightArrow: {
            position: 'absolute',
            right: '16px',
            top: '22px'
        }
    }));
    const classes = useStyles();


    const getUserBookings = (id, userbookings, totalBookings, totalConfirmed, totalCancelled, totalAmount, totalPoints, totalSucess, totalFailed) => {
        setUserBookings(userbookings);
        setTotalBookings(totalBookings);
        setTotalConfirmed(totalConfirmed);
        setTotalCancelled(totalCancelled);
        setTotalAmount(totalAmount);
        setTotalPoints(totalPoints);
        setTotalSuccess(totalSucess);
        setTotalFailed(totalFailed);
        dispatch(EventsBookingAction.setCancelledCategoryId(id));
        setUserPage(1);
    }

    const getModifiedUserBookings = (id, packTitle, totalBookings, totalConfirmed, totalCancelled, totalAmount, totalPoints, totalFailed, index) => {
        //setPackTitle(packTitle);
        setTotalBookings(totalBookings);
        setTotalConfirmed(totalConfirmed);
        setTotalCancelled(totalCancelled);
        setTotalAmount(totalAmount);
        setTotalPoints(totalPoints);
        setTotalFailed(totalFailed);
        dispatch(EventsBookingAction.setCategoryId(id));
        dispatch(EventsBookingAction.getUserBookingList(page, id));
        setSearchVal("");
        setCatId(id);
        //setBookingIndex(index);
    }


    const handleTransactionModal = (item) => {
        setShowModal(true);
        dispatch(EventsBookingAction.getTransactionDetails(item.txnid, item.pay_mode));
    }

    const closeTransactionModal = () => {
        setShowModal(false);
    }

    const bookingStatusChange = (id, value, corporateStatus) => {
        dispatch(EventsBookingAction.updateBookingStatus(id, value, corporateStatus, refreshData))
    }

    const refreshData = () => {
        dispatch(EventsBookingAction.getCancelledBookingsList());
        dispatch(EventsBookingAction.getUserBookingList());
        enqueueSnackbar("Status updated successfully.", {
            variant: "success",
        });
    }

    const userSearchBooking = (event) => {
        let input = event.target.value;
        setSearchVal(input);
        dispatch(EventsBookingAction.userSearchBookingList(input, catId))
    }

    const onPageChange = (value) => {
        setUserPage(value);
        dispatch(EventsBookingAction.userSearchBookingList(searchVal, catId, null, value))
    }

    const downloadCSV = ()=>{
        console.log(catId);

        const url = `${API.CSV_DOWNLOAD}${catId}/`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'file';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div>
            <div className="bookings--holder">
                <Row >
                    <Col xs={24} lg={24} className="gutter-row">
                        <SearchBar searchValue={searchValue} status="cancelled" onResetPage={onResetPage} setPageChange={setPageChange} />
                    </Col>
                    <Col style={{ paddingRight: '20px', marginRight: '-20px' }} className="gutter-row overflow--scroll cards-list" style={{ marginBottom: '10px' }} xs={24} lg={8}>
                        {
                            cancelledBookings && cancelledBookings.length > 0 && cancelledBookings.map((item) => (
                                <Card
                                    className={(getCatId == item?.id) ? `active-card ${classes.root}` : `${classes.root}`}
                                    onClick={() => { getModifiedUserBookings(item?.id, item?.pack_title, item?.total_bookings, item?.total_confirmed, item?.total_cancelled, item?.total_amount, item?.total_points, item?.total_fail); getUserBookings(item?.id, item?.user_bookings, item?.total_bookings, item?.total_confirmed, item?.total_cancelled, item?.total_amount, item?.total_points, item?.total_success, item?.total_fail) }}
                                >
                                    <div>
                                        <h4>{item?.pack_title}</h4>
                                        <div class="booking-info">
                                            <p>{item?.total_bookings} bookings</p>
                                        </div>
                                    </div>
                                    {
                                        getCatId == item?.id ? <div>
                                            <img src={rightArrow} alt="" />
                                        </div> : ''
                                    }

                                </Card>
                            ))
                        }

                    </Col>
                    <Col className="gutter-row overflow--scroll" xs={24} lg={16}>
                        {
                            cancelledBookings && cancelledBookings.length > 0 ?
                                <div className="overview-holder">
                                    <div className="data-holder">
                                        <div className="bookings">
                                            <h5>{totalBookings} bookings</h5>
                                            <div className="info">
                                                <p>Received so far</p>
                                                <p>{totalConfirmed} Confirmed, <span style={{ color: '#DA7519' }}> {totalCancelled} Cancelled </span></p>
                                            </div>
                                        </div>
                                        <div className="bookings payment-details">
                                            <h5>Rs {totalAmount} and {totalPoints} pts</h5>
                                            <div className="info">
                                                <p>Received so far</p>
                                                <div className="payment-status">
                                                    <p>{totalFailed} Payments pending</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Col xs={24} lg={24} className="gutter-row d-flex" style={{ borderBottom: '4px solid #ccc' }}>
                                        <div className="flex-grow-1">
                                            <input
                                                type="search"
                                                className="form-control search-list"
                                                placeholder="Search bookings"
                                                value={searchVal}
                                                onChange={userSearchBooking}
                                                style={{ margin: '8px', width: '90%' }}
                                            />
                                        </div>
                                        <div className="Input">
                                            <button className="btn btn-md btn-primary p-2" style={{margin:7}} onClick={downloadCSV}><img src={CsvIcon} />  Export to CSV</button>
                                        </div>
                                    </Col>
                                    <div className="payments-list-holder">
                                        {
                                            // all payment details
                                            modifiedUserBooking && modifiedUserBooking.length > 0 ?
                                                modifiedUserBooking.map((item) => (
                                                    <div className="all-payment-details">
                                                        <div className="user-details">
                                                            <div className="user">
                                                            <span style={{ color: "#2196F3", fontSize: "12px", display: "block" }}>{item.ref_id? `#${item.ref_id}`:"No ref id"}</span>
                                                                <h6>{item?.contact_name}</h6>
                                                                <p>{item?.contact_email}, {item?.contact_number}</p>
                                                            </div>
                                                            <div className="amount">
                                                                <h6>
                                                                    {
                                                                        item?.txn_status === "failure" ? `Rs ${item?.amount}` :
                                                                            <span>
                                                                                {
                                                                                    item?.amount_paid !== "0.00" ? `Rs ${item?.amount_paid}` : null
                                                                                }
                                                                                {
                                                                                    (item?.amount_paid !== "0.00" && item?.total_points > 0) ? ' and ' : ''
                                                                                }
                                                                                {
                                                                                    item?.total_points > 0 ? `${item?.total_points} pts` : null
                                                                                }
                                                                            </span>
                                                                    }

                                                                </h6>
                                                                {
                                                                    item?.txn_status === "success" ? <p>Paid amount</p> : item?.txn_status === "failure" ? <p>Payable amount</p> : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="contact-details-holder">
                                                            <div className="contact-info">
                                                                <div className="title">
                                                                    <p>Booked for</p>
                                                                </div>
                                                                <div className="contact-details">
                                                                    <p className="email">
                                                                        {moment(item?.schedule?.date, 'DD-MM-YYYY').format('Do MMM, YYYY')}   </p>
                                                                    <p> {moment(item?.schedule?.from, 'hh:mm').format('LT')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="contact-info">
                                                                <div className="title">
                                                                    <p>Transaction details</p>
                                                                </div>
                                                                <div className="contact-details">
                                                                    <p className="email" style={{ color: '#212121' }}>
                                                                        ID: {item?.txnid} </p>
                                                                    {
                                                                        item?.total_points > 0 || (item?.amount_paid == "0.00" || item?.amount_paid === 0) ? null : <p style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => handleTransactionModal(item)} >see more</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="payment-status">
                                                                {
                                                                    item?.booking_status === "confirmed" ? <p className="confirmed--btn">Confirmed</p> : item?.txn_status === "failure" ? <p style={{ background: '#F49B9B' }}>Payment failed</p> : item?.booking_status === "cancelled" ? <p className="cancelled--btn">Cancelled</p> : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <p style={{ margin: '20px' }}>No Bookings Available</p>

                                        }
                                    </div>
                                    <Row style={{ margin: '10px' }}>
                                        <Pagination
                                            count={modifiedUserBookingPages?.total_pages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={userPage}
                                            onChange={(event, value) => onPageChange(value)}
                                        />
                                    </Row>
                                </div> : <p style={{ marginLeft: '20px' }}>No Bookings Available</p>
                        }
                    </Col>
                </Row>
            </div>

            <div className="transaction-modal">
                <Dialog open={showModal} >
                    <div className="tranaction-title">
                        <DialogTitle>
                            <p>Transaction details</p>
                        </DialogTitle>
                        <div className="close-icon" onClick={closeTransactionModal}>
                            <img src={CloseIcon} alt="" />
                        </div>
                    </div>
                    <TransactionDetailsModal transDetails={transactionDetails} />
                </Dialog>
            </div>

        </div>
    )
}

export default CancelledBookings