import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as corpListingAction from "../../actions/corpListingAction";
import { getSectionImageData, selectedCroppedFile, selectedImageUrl, handleAddConfirmBool, getSectionPackages } from "../../actions/sectionAction";
import AddNewSection from "../../common/addNewSection";
import DefaultImage from "../../assets/images/plain-background.jpeg";
import SectionImageItems from "../../common/sectionImageItems";
import "../../assets/css/partials/corporate/section.scss";
import { useSnackbar } from "notistack";
import CommonAddNew from "../../common/commonAddNew";
import { Spinner } from "react-bootstrap";

const Sections = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [section, setSection] = useState("");
  const [priority, setPriority] = useState("");
  const [refCodeValue, setRefCodeValue] = useState("");
  const [check, setCheck] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState("");
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const selectedImgUrl = useSelector(state => state.section.selectedImageUrl)
  const confirmAddBoolVal = useSelector(state => state.section.confirmAddBoolVal)
  const sectionImageData = useSelector(state => state.section.corpSectionImageData)
  const [ corpSectionNameBool, setCorpSectionNameBool ] = useState(null)
  const [ corpSectionPriorityBool, setCorpSectionPriorityBool ] = useState(false);
  const [ corpSectionPriorityEmptyBool, setCorpSectionPriorityEmptyBool ] = useState(null);
  const [ corpSectionImageBool, setCorpSectionImageBool ] = useState(null);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(false);
  const filterGiftVoucherTrue = sectionImageData.filter(item => item.gift_voucher === true);
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  const shoModalHandler = () => {
    setShowSectionModal(true);
  };

  const addSectionImages = () => {
    inputFile.current.click();
  };
  useEffect(() => {
    dispatch(getSectionImageData());
  }, [imageUrl]);

  // useEffect(() => {
  //   setCodeMatchError(false)
    
  // });
  // }, [sectionImageData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCorpSectionImageBool(false);
    setCorpSectionNameBool(false);
    setCorpSectionPriorityBool(false);
    setCorpSectionPriorityEmptyBool(false);
    setIscodeError(false);
    setCodeLessErrorr(false);
    setCodeMoreError(false)
    const submitData = {
      imageUrl,
      section,
      priority,
      refCodeValue,
      gift_voucher:check
    }; 
    if(imageUrl === "")setCorpSectionImageBool(true)
    else if(section === "")setCorpSectionNameBool(true)
    else if(priority === "")setCorpSectionPriorityEmptyBool(true)
    else if(priority <= -1)setCorpSectionPriorityBool(true)
    else if(refCodeValue === "")setIscodeError(true)
    else if(refCodeValue.length < 2)setCodeLessErrorr(true)
    else if(refCodeValue.length > 2)setCodeMoreError(true)
    else if(refCodeValue.length == 2 && codeMatchError)setCodeMatchError(true)
    else{
    dispatch(corpListingAction.addCorpSectionImages(submitData, refreshData, handleSuccess, handleError));
    setShowSectionModal(false);
    setSection("");
    setPriority("");
    setFile("");
    setImageUrl("");
    setRefCodeValue("")
    dispatch(selectedImageUrl(null));
    dispatch(handleAddConfirmBool(false));
    }
  };

  // get refreshed data
  const refreshData = () => {
    dispatch(getSectionImageData());
  }

  //

  const refrenceCodeHandler = (e) => {
    let code = e.target.value.toLocaleUpperCase()
    const filteredData = sectionImageData.filter(item => item.code === code)
    if(filteredData.length){
      setCodeMatchError(true)
    }else{
      setCodeMatchError(false)
    }
    setRefCodeValue(code)
  }

  const handleSuccess = () => {
    enqueueSnackbar("Added successfully", {
      variant: "success",
    });
  }

  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  }

  // Image Upload using crop tool

  const updatePic = (data) => {
    setImageUrl(data.url);
    setFile("");
    dispatch(selectedImageUrl(data.url));
  };

  const fileRef = useRef();

  const handleImageChange = (croppedFile) => {
    setFile(croppedFile);
    if (croppedFile) {
      dispatch(corpListingAction.imageUpload(croppedFile, updatePic));
    }
  };

  const closeCroppedFileImage = () => {
    dispatch(selectedCroppedFile(""))
    setImageUrl(null);
    dispatch(selectedImageUrl(null));
  };

  const closeModal = () => {
    setShowSectionModal(false);
    setSection("");
    setPriority("");
    dispatch(selectedImageUrl(null))
    
  };

  const closeHandle = () => {
    setShowSectionModal(false);
    // setSection("");
    // setPriority("");
    // dispatch(selectedImageUrl(null));
    
  };
  
  const checkHandler =() => {
    setCheck(true)
  };

  const corpSectionPriorityHandler = (e) => {
    setCorpSectionPriorityBool(false);
    setPriority(e.target.value);
    if(e.target.value <= -1)setCorpSectionPriorityBool(true)

  };
  
  return (
    <>
    <h2>{sectionImageData.length} Corporate Sections</h2>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop:"30px"}}>
        <SectionImageItems />
        <CommonAddNew 
          onClick={shoModalHandler}
          textTitle="Add New Section"
        />
        {isDataLoading &&
          <div className="blured-backround">
            <p style={{ paddingTop: "40%", marginLeft: "10%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
      </div>
      {showSectionModal && (
        <AddNewSection
          showMyModal={showSectionModal}
          openHandler={showSectionModal}
          togglebackDrop={showSectionModal}
          closeHandle={closeHandle}
          heading="Add New Section"
          image={!confirmAddBoolVal ? DefaultImage : selectedImgUrl}
          imageText="Image"
          labelTextOne="Section Name"
          labelTextTwo="Priority"
          codeRefrence="Code"
          btnTextOne="Cancel"
          btnTextTwo="Done"
          valueOne={section}
          onChangeOne={(e) => setSection(e.target.value)}
          valueTwo={priority}
          onChangeTwo={corpSectionPriorityHandler}
          refCodeValue={refCodeValue}
          refrenceCodeHandler={refrenceCodeHandler}
          handleSubmit={handleSubmit}
          closeModal={closeModal}
          addSectionImages={addSectionImages}
          handleFilePickChange={handleImageChange}
          inputFile={fileRef}
          closeCroppedFileImage={closeCroppedFileImage}
          isUpdateImageSection={false}
          isAddImageSection={true}
          addCheckValue={check}
          checkHandler={checkHandler}
          isPriorityError={corpSectionPriorityBool}
          isSectionNameError={corpSectionNameBool}
          isImage={corpSectionImageBool}
          iscodeError={iscodeError}
          codeLessError={codeLessError}
          codeMoreError={codeMoreError}
          codeMatchError={codeMatchError}
          isPriorityEmptyError={corpSectionPriorityEmptyBool}
          checkBoxdisable={`${filterGiftVoucherTrue.length >= 1 ? "none": ""}`}
        />
      )}
    </>
  );
};
export default Sections;
