export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const WARNING_ALERT = "WARNING_ALERT";
export const INFO_ALERT = "INFO_ALERT";

export const successAlert = (message) => ({
  type: SUCCESS_ALERT,
  payload: message,
});

export const errorAlert = (message) => ({
  type: ERROR_ALERT,
  payload: message,
});

export const warningAlert = (message) => ({
  type: WARNING_ALERT,
  payload: message,
});

export const infoAlert = (message) => ({
  type: INFO_ALERT,
  payload: message,
});
