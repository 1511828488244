import {
  SEARCH_ENQUIRY_ENQURIES,
  SEARCH_ENQUIRY_FOLLOW_UP,
  SEARCH_ENQUIRY_RESOLVED,
  ENQUIRY_FILTER_START_DATE,
  ENQUIRY_FILTER_END_DATE,
  // PAGE_NUM
} from "../actions/enquiryAction";
import {
  ENQUIRYLIST,
  ENQUIRY_FOLLOW_UP_LIST,
  ENQUIRY_RESOLVED_LIST,
  UPDATE_ENQUIRY_STATUS,
  CORP_ENQ_SEARCH_VALUE,
  CORP_FOLLOWUP_SEARCH_VALUE,
  CORP_RESOLVED_SEARCH_VALUE,
  ENQUIRY_STATUS,
  PAGE_NUM,
  FOLLOWUP_PAGE_NUM,
  RESOLVED_PAGE_NUM
} from "../actions/types";

const initialState = {
  enqueries: [],
  followUpQueries: [],
  resolvedQeries: [],
  selectedStatus: undefined,
  followUpSearchText: "",
  filterStartDate: null,
  filterEndDate: null,
  page_num: 1,
  followup_page_num:1,
  resolved_page_num:1,
  corpEnqSearchValue:"",
  corpFollowupSearchValue:"",
  corpResolvedSearchValue:"",
  enquiryStatus:""
};

const enquiryListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ENQUIRYLIST: {
      return {
        ...state,
        enqueries: payload,
      };
    }
    case SEARCH_ENQUIRY_ENQURIES: {
      return {
        ...state,
        enqueries: payload,
      };
    }
    case ENQUIRY_FOLLOW_UP_LIST: {
      return {
        ...state,
        followUpQueries: payload,
      };
    }
    case SEARCH_ENQUIRY_FOLLOW_UP: {
      return {
        ...state,
        followUpQueries: payload,
      };
    }

    case ENQUIRY_RESOLVED_LIST: {
      return {
        ...state,
        resolvedQeries: payload,
      };
    }
    case SEARCH_ENQUIRY_RESOLVED: {
      return {
        ...state,
        resolvedQeries: payload,
      };
    }
    case UPDATE_ENQUIRY_STATUS: {
      return {
        ...state,
        selectedStatus: payload,
      };
    }
    case ENQUIRY_FILTER_START_DATE: {
      return {
        ...state,
        filterStartDate: payload,
      };
    }
    case ENQUIRY_FILTER_END_DATE: {
      return {
        ...state,
        filterEndDate: payload,
      };
    }
    case PAGE_NUM: {
      return {
        ...state,
        page_num: payload,
      };
    }
    case FOLLOWUP_PAGE_NUM: {
      return {
        ...state,
        followup_page_num: payload,
      };
    }
    case RESOLVED_PAGE_NUM: {
      return {
        ...state,
        resolved_page_num: payload,
      };
    }
    case CORP_ENQ_SEARCH_VALUE: {
      return {
        ...state,
        corpEnqSearchValue: payload,
      };
    }
    case CORP_FOLLOWUP_SEARCH_VALUE: {
      return {
        ...state,
        corpFollowupSearchValue: payload,
      };
    }
    case CORP_RESOLVED_SEARCH_VALUE: {
      return {
        ...state,
        corpResolvedSearchValue: payload,
      };
    }
    case ENQUIRY_STATUS: {
      return {
        ...state,
        enquiryStatus: payload,
      };
    }
    default:
      return state;
  }
};

export default enquiryListingReducer;
