import { useState } from "react";

export default function CommentsField({ content, limit }) {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (content.length <= limit) {
    return <div>{content}</div>;
  }
  if (showAll) {
    return (
      <>
        {content}
        <span onClick={showLess}>Read less</span>
      </>
    );
  }
  const toShow = content.substring(0, limit);
  return (
    <>
      {toShow}
      <span onClick={showMore}>Read more</span>
    </>
  );
}
