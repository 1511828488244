import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../assets/css/_common.scss";
import * as experienceListingAction from "../actions/experienceListingAction";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "antd/dist/antd.css";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getFeaturedList } from "../actions/experienceListingAction";
import { Spinner } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "780px",
    height: "824px",
    // background: "#FFFFFF",
    // borderRadius: "8px",
    // // padding: "24px 48px",
    // display: "flex",
    // // flexDirection: "column",
    // alignItems: "flex-start",
    // position: "relative",
  },
}));

function ManageFeaturedPackage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [list, setList] = React.useState([]);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  useEffect(() => {
    console.log(props.featuredList_data);

    if (props.dropDownvalue !== null) {
      dispatch(props.listingAction.getFeaturedList(props.dropDownvalue.id));
    } else {
      dispatch(props.listingAction.getFeaturedList(1));
    }
  }, [props.dropDownvalue]);

  useEffect(() => {
    let index_list = props.featuredList_data;
    let newArray = index_list.map((obj, index) => {
      // if(obj.featured_number == null)
      obj.featured_number = index + 1;
      return { featured_number: index + 1, id: obj.id };
    });
    console.log(newArray);
    setList(index_list);
  }, [props.featuredList_data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onEnd = (result) => {
    // console.log(result);
    let newArray = reorder(list, result.source.index, result.destination.index);
    setList(newArray);
    // dispatch()
  };
  const updateOrder = () => {
    let newArray = list.map((obj, index) => {
      // if(obj.featured_number == null)
      obj.featured_number = index + 1;
      return { featured_number: index + 1, id: obj.id };
    });
    props.updateOrder({ featuredList: newArray });
    props.toggleFunc(false);
  };
  return (
    <Dialog
      open={props.showModal}
      onClose={() => props.toggleFunc(false)}
      maxWidth="md"
      // style={{maxWidth:"784px",}}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle 
      // style={{ marginLeft: "25px", display: "flex" }}
      >
        <div style={{display:"flex"}}>
          <div
          className="manageFeatured_box_title"
            // style={{
            //   marginRight: "80px",
            //   fontSize: "32px",
            //   fontWeight: "bold",
            //   lineHeight: "42px",
            //   fontStyle: "normal",
            //   fontFamily: "DM Sans",
            //   letterSpacing: "0.15px",
            // }}
          >
            Manage featured packages
          </div>

          <IconButton
            aria-label="close"
            className="manageFeatured_box_closeIcon"
            // style={{
            //   left: "16.67%",
            //   right: "16.7%",
            //   top: "16.67%",
            //   bottom: "16.67%",
            //   marginLeft: "70px",
            // }}
            onClick={() => props.toggleFunc(false)}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
        <div
        className="manageFeatured_box_subTitle"
          // style={{
          //   marginRight: "80px",
          //   fontSize: "18px",
          //   lineHeight: "24px",
          //   fontStyle: "normal",
          //   fontFamily: "DM Sans",
          //   // width:"509px",
          //   height: "24px",
          // }}
        >
          Click on the packages and drag it in order of their prefrences.
        </div>
      </DialogTitle>
      <DialogContent 
      // className="manageFeatured_box"
      >
        <DragDropContext onDragEnd={onEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppablePr} ref={provided.innerRef}>
                {list.map((item, index) => (
                  <Draggable
                    draggableId={`${item.id}`}
                    key={item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className="manageFeatured_box"
                          // style={{
                          //   margin: "20px",
                          //   height: "98px",
                          //   width: "709px",
                          //   top: "-2px",
                          //   borderRadius: "4px",
                          //   padding: "18px 20px 16px",
                          //   backgroundColor: "#FAFAFA",
                          // }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              height: "24px",
                              width: "669px",
                            }}
                          >
                            <div className="title-manageFeatured">
                              {item.title}
                            </div>
                            <IconButton>
                              <DragHandleIcon />
                            </IconButton>
                          </div>
                          <div className="manageFeatured_city">
                            <p>{item.location_city}</p>
                          </div>
                          <div className="sectionName-manageFeatured">
                            {item.section.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <div 
        // className="manageFeatured_box_save"
        style={{ display: "flex", marginLeft: "650px", marginRight:"16px" }}
        >
          <Button
            onClick={() => updateOrder()}
            className="btn-save"
            style={{ background: "#2196f3", color: "white" }}
            // className="manageFeatured_box_save_content"
          >
            SAVE
          </Button>
        </div>
      </DialogActions>
      {isDataLoading&&
          <div className="blured-backround">
            <p style={{ paddingTop: "50%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
    </Dialog>
  );
}

export default ManageFeaturedPackage;
