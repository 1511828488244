import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { connect, useDispatch } from "react-redux";
import * as EnquiryAction from "../../../actions/enquiryAction";
import "../../../assets/css/partials/enquiry.scss";
import Datefilter from "./DateFilter";
import Enquiry from "./enquiry";
import FollowUp from "./followUp";
import Resolved from "./resolved";
import Pagination from '@material-ui/lab/Pagination';
import {useSelector} from 'react-redux'
import { Spinner } from "react-bootstrap";
const { TabPane } = Tabs;




function mapStateToProps(state) {
  return {
    enquiryDetails: state.enquiryListing.enqueries.data,
    followUpDetails: state.enquiryListing.followUpQueries.data,
    resolvedDetails: state.enquiryListing.resolvedQeries.data,
  };
}

function Enquiries({ enquiryDetails, followUpDetails, resolvedDetails }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const enquiriesSearchVal = useSelector((state) => state?.enquiryListing?.corpEnqSearchValue);
  const enquiryStatus = useSelector((state) => state?.enquiryListing?.enquiryStatus);
  const getPageNum = useSelector((state) => state?.enquiryListing?.page_num);
  const getFollowupPageNum = useSelector((state) => state?.enquiryListing?.followup_page_num);
  const getResolvedPageNum = useSelector((state) => state?.enquiryListing?.resolved_page_num);
  const startDate = useSelector((state) => state.enquiryListing.filterStartDate);
  const endDate = useSelector((state) => state.enquiryListing.filterEndDate);
  const enquiriesFollowupSearchVal = useSelector((state) => state?.enquiryListing?.corpFollowupSearchValue);
  const enquiriesResolvedSearchVal = useSelector((state) => state?.enquiryListing?.corpResolvedSearchValue);
  const isDataLoading = useSelector(
    (state) => state.corpListing.isDataLoading
  );
  function callback(key) {
    setPage(1);
    // dispatch(EnquiryAction.setPageNum(1));
    if(key === "2")dispatch(EnquiryAction.enquiryStatus("follow-up"));
    else if(key === "3")dispatch(EnquiryAction.enquiryStatus("resolved"));
    else{
      dispatch(EnquiryAction.enquiryStatus("enquiry"));
    }
  }

  useEffect(() => {
    if(enquiriesSearchVal === "" || enquiriesFollowupSearchVal === "" || enquiriesResolvedSearchVal === ""){
      setPage(1)
      dispatch(EnquiryAction.setPageNum(1));
    }
  }, [enquiriesSearchVal, enquiriesFollowupSearchVal, enquiriesResolvedSearchVal]);

  useEffect(() => {
    dispatch(EnquiryAction.enquiryStatus("enquiry"));
  }, []);
  
  const allSearchvalue = () => {
    if(enquiryStatus === "enquiry") return enquiriesSearchVal
    else if(enquiryStatus === "follow-up") return enquiriesFollowupSearchVal
    else if(enquiryStatus === "resolved") return enquiriesResolvedSearchVal
  };

  useEffect(() => {
    return () => {
    dispatch(EnquiryAction.filterStartDate(null));
    dispatch(EnquiryAction.filterEndDate(null))
  }
  }, []);
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  useEffect(() => {
    dispatch(EnquiryAction.getEnquiriesList(page));
    dispatch(EnquiryAction.getEnquiryFollowuplist(page));
    dispatch(EnquiryAction.getResolvedEnquirylist(page));
  
  }, []);

  const totalEnquiries = enquiryDetails?.total_results;
  const totalFollowUpEnquiries = followUpDetails?.total_results;
  const totalResolvedEnquiries = resolvedDetails?.total_results;

  const overallEnquiries =
    totalEnquiries + totalFollowUpEnquiries + totalResolvedEnquiries;

  const enquiryHandlePageChange = (value) => {
    if(enquiriesFollowupSearchVal !== ""){
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else if(startDate !== null && endDate !== null){
      dispatch(EnquiryAction.searchEnquiry(allSearchvalue(), enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else{
      dispatch(EnquiryAction.getEnquiriesList(value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }
  };

  const followupHandlePagination = (value) => {
    if(enquiriesFollowupSearchVal !== ""){
      dispatch(EnquiryAction.searchEnquiry(enquiriesFollowupSearchVal, enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else if(startDate !== null && endDate !== null){
      dispatch(EnquiryAction.searchEnquiry(allSearchvalue(), enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else{
      dispatch(EnquiryAction.getEnquiryFollowuplist(value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }
  };

  const resolvedHandlePagination = (value) => {
    console.log("value", value)
    if(enquiriesResolvedSearchVal !== ""){
      dispatch(EnquiryAction.searchEnquiry(enquiriesResolvedSearchVal, enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else if(startDate !== null && endDate !== null){
      dispatch(EnquiryAction.searchEnquiry(allSearchvalue(), enquiryStatus, value));
      dispatch(EnquiryAction.setPageNum(value, enquiryStatus));
    }else{
      dispatch(EnquiryAction.getResolvedEnquirylist(value));
      dispatch(EnquiryAction.setPageNum(value, "resolved"));
    }
  };

  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter />
      </div>
      <div className="enquiry-tabs-holder">
        <p className="title-holder">{overallEnquiries} Corporates Enquiries</p>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab={totalEnquiries + " " + "Enquiries"} key="1">
            <Enquiry enquiryData={enquiryDetails} />
            <Pagination
            count={enquiryDetails?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getPageNum}
            onChange={(event, value) => enquiryHandlePageChange(value)} />
          </TabPane>
          <TabPane tab={totalFollowUpEnquiries + " " + "Follow-Up"} key="2">
            <FollowUp followUpData={followUpDetails} page={page}/>
            <Pagination
            count={followUpDetails?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getFollowupPageNum}
            onChange={(event, value) => followupHandlePagination(value)} />
          </TabPane>
          <TabPane tab={totalResolvedEnquiries + " " + "Resolved"} key="3">
            <Resolved resolvedData={resolvedDetails} />
            <Pagination
            count={resolvedDetails?.total_pages}
            variant="outlined"
            shape="rounded"
            page={getResolvedPageNum}
            onChange={(event, value) => resolvedHandlePagination(value)} />
          </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Enquiries);
