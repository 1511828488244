import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPut,
} from "../services/apiServices";
import {
  BOOKINGLIST,
  PARTNERBOOKINGLIST,
  PARTNER_CONFIRMED_BOOKINGLIST,
  PARTNER_CANCELLED_BOOKINGLIST,
  BOOKING_REFUND_LIST,
  PARTNER_FOLLOWUPLIST,
  BOOKING_CONFIRMED_LIST,
  BOOKING_CANCELLED_LIST,
  UPDATE_BOOKING_STATUS,
  SEARCH_TEXT_VALUE,
  CORP_CONFIRMED_SEARCH_VALUE,
  CORP_CANCELLED_SEARCH_VALUE,
  CORP_FOLLOWUP_SEARCH_VALUE,
  CORP_REFUNDED_SEARCH_VALUE,
  GET_CORP_BOOKING_STATUS,
  BOOKED_SEARCH_TRUE_VALUE,
  CONFIRMED_SEARCH_TRUE_VALUE,
  CANCELLED_SEARCH_TRUE_VALUE,
  AVAILABILITY_REQUEST_LIST,
  CORP_AVAILABILITY_SEARCH_VALUE,
  AVAILABILITY_PAGE_NUM,
  GUEST_MODAL_OPEN_TRUE,
  ADULTS_GUEST,
  CHILDRENS_GUEST,
  INFANTS_GUEST,
  SCHEDULE_MODAL_OPEN_TRUE,
  IS_CORP_DATA_LOADING,
  FOLLOWUP_LIST,
} from "./types";

export const SEARCH_BOOKING = "SEARCH_BOOKING";
export const SEARCH_BOOKING_CONFIRMED = "SEARCH_BOOKING_CONFIRMED";
export const SEARCH_BOOKING_CANCELLED = "SEARCH_BOOKING_CANCELLED";
export const SEARCH_BOOKING_FOLLOWUP= "SEARCH_BOOKING_FOLLOWUP";
export const SEARCH_BOOKING_REFUNDED="SEARCH_BOOKING_REFUNDED"
export const BOOKING_FILTER_START_DATE = "BOOKING_FILTER_START_DATE";
export const BOOKING_FILTER_END_DATE = "BOOKING_FILTER_END_DATE";
export const BOOKING_FILTERED_LIST = "BOOKING_FILTERED_LIST";
export const BOOKINGLIST_ID = "BOOKINGLIST_ID";
export const PAGE_NUM="PAGE_NUM"
export const BLOCK_RESTRICTIONS="BLOCK_RESTRICTIONS"
export const REFUND_AMOUNT="REFUND_AMOUNT"

export const selectBlockDatesRestrictions=(payload)=>{
  console.log(payload,"redux");
  const {flexi_schedule,schedule_data}=payload
const restriction =schedule_data?.selectedDates
  return async (dispatch, getState) => {

  dispatch({
    type: BLOCK_RESTRICTIONS,
    payload: restriction,
  });

}
}
export const refundBookedPackage=(section,bookin_id,modalClose,handleRefundSuccess,handleErrorMessage)=>{

  const body = {
    "package_section_type": section,
    "booking_id":bookin_id
}


  

  return async (dispatch, getState) => {

    const token = getState().auth.token;
    console.log( `${API.REFUND_BOOKED_PACKAGE}`);

    axiosPostWithToken(`${API.REFUND_BOOKED_PACKAGE}`, body, token)
      .then((res) => {
        console.log(res);
      
        if (res?.data.status_code === 202) {
      
          modalClose()
          handleRefundSuccess(res.data.data.message)
        
        }
        else{
         
          modalClose()
          handleErrorMessage(res.data.data.message)
        }
      })
      .catch((err) => {
        console.log(err);

        modalClose()
        handleErrorMessage("Refund Sucessfull")
      
      
      });
  
  }

}

export const getBookingList = (page, secId, isPartner) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"booked"
  } 
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
  let apiUrl = API.BOOKINGS_LIST;
  if(isPartner){
    apiUrl = API.PARTNER_BOOKINGS_LIST;
    body = {
      "next_page_id": page,
      "partner_name": "vantage-circle",
      "status": "booked"
  } 
  }
    axiosPostWithToken(`${apiUrl}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKINGLIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getPartnerBookingList = (page, status, selectedPartner) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    
    let body = {
      "next_page_id": page,
      "partner_name": selectedPartner,
      "status": status,
      "page_size":50
    } 
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.PARTNER_BOOKINGS_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          if(status==='booked'){
            // console.log(res.data);
            dispatch({
              type: PARTNERBOOKINGLIST,
              payload: {data:res.data},
            });
          } else if(status==='confirmed'){
            dispatch({
              type: PARTNER_CONFIRMED_BOOKINGLIST,
              payload: {data:res.data},
            });
          }  
          else if(status==='follow_up'){
            dispatch({
              type: PARTNER_FOLLOWUPLIST,
              payload: {data:res.data},
            });
          } 
          else if(status==='cancelled'){
            dispatch({
              type: PARTNER_CANCELLED_BOOKINGLIST,
              payload: {data:res.data},
            });
          }
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getAvailabilityRequestList = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"requested",
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.AVAILABILITY_REQUEST_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: AVAILABILITY_REQUEST_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getFollowupList = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"follow_up",
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.CORP_BOOKING_FOLLOWUP}`, body, token)
      .then((res) => {
        console.log(res,"res");
        if (res.data.status_code === 200) {
          dispatch({
            type: FOLLOWUP_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getBookingConfirmedlist = (page, secId,status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"confirmed",
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.BOOKINGS_CONFIRMED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKING_CONFIRMED_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const getRefundedlist = (page, secId,status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"refunded",
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.BOOKINGS_CONFIRMED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKING_REFUND_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

// crop booking list filter

export const getBookingFilteredlist = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id : "1",
      sec_id: id
    }
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.BOOKINGS_FILTERED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKINGLIST,
            payload: res.data,
          });
          dispatch({
            type: BOOKINGLIST_ID,
            payload: id,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

//

export const getConfirmedBookingFilteredlist = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id : "1",
      sec_id: id
    }
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.BOOKINGS_CONFIRMED_FILTERED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKING_CONFIRMED_LIST,
            payload: res.data,
          });
          dispatch({
            type: BOOKINGLIST_ID,
            payload: id,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

//

export const getCancelledBookingFilteredlist = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      next_page_id : "1",
      sec_id: id
    }
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.BOOKINGS_CANCELLED_FILTERED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKING_CANCELLED_LIST,
            payload: res.data,
          });
          dispatch({
            type: BOOKINGLIST_ID,
            payload: id,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};
export const getAvailabilityFilteredList = (id, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      sec_id: id,
      next_page_id : page,
    }
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.AVAILABILITY_REQUEST_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: AVAILABILITY_REQUEST_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

///

export const getCancelledBookinglist =(page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      sec_id:secId,
      "page_size":50,
      "status":"cancelled",
  }
  dispatch({
    type: IS_CORP_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.BOOKINGS_CANCELLED_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: BOOKING_CANCELLED_LIST,
            payload: res.data,
          });
          dispatch({
            type: IS_CORP_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      });
  };
};

export const updatePartnerBookingStatus = (id, selectedStatus,secId,cancelling_message, refreshEnquiries,  data,guestData, amountValue) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const statusVal = getState().bookingListing.getStatus
    
    const body = {
      "status": selectedStatus,
      sec_id:secId,
      "schedule": data?.schedule,
      "adults": guestData?.adults,
      "children": guestData?.children,
      "infants": guestData?.infants,
      "partner_booking": true,
      "total_points": parseInt(amountValue)
    };
    const bodyForAll = {
      status: selectedStatus,
      sec_id:secId,
      "partner_booking": true,
      cancelling_message:cancelling_message,
    }
    axiosPut(`${API.UPDATE_BOOKING_STATUS}${id}/`,selectedStatus=="cancelled"?bodyForAll:body , token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: UPDATE_BOOKING_STATUS,
            payload: res.data,
          });
          refreshEnquiries();
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const updateBookingStatus = (id, selectedStatus, secId,cancelling_message, refreshEnquiries,  data, guestData, amountValue) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const statusVal = getState().bookingListing.getStatus
    const bodyForRequested = {
      status: selectedStatus,
        sec_id:secId,

      schedule:data?.schedule,
      adults:guestData?.adults,
      children:guestData?.children,
      infants:guestData?.infants,
      offline_payment:true,
      amount_paid:parseInt(amountValue)
    }
    const bodyForAll = {
      status: selectedStatus,
      sec_id:secId,
      cancelling_message:cancelling_message
    }
    axiosPut(`${API.UPDATE_BOOKING_STATUS}${id}/`, (statusVal === "requested" || statusVal ==="follow_up") && selectedStatus === "confirmed" ?  bodyForRequested : bodyForAll, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: UPDATE_BOOKING_STATUS,
            payload: res.data,
          });
          refreshEnquiries();
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const searchPartnerBooking = (text, status, selectedPartner, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const startDate = getState().bookingListing.filterStartDate;
    const endDate = getState().bookingListing.filterEndDate;
    // const secId =  getState().bookingListing.getSecId
    
    let body = {
      search_term: text,
      status: status,
      partner_name: selectedPartner,
      next_page_id: page,
      from: startDate,
      to: endDate,
    };
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(API.PARTNER_SEARCH_BOOKINGS, body, token).then((res) => {
      if (res.data.status_code === 200) {
        console.log('PARTNERBOOKINGLIST', res.data);
        if (status === "booked") {
          dispatch({
            type: PARTNERBOOKINGLIST,
            payload: {data:res.data},
          });
          
        } else if (status === "confirmed") {
          dispatch({
            type: PARTNER_CONFIRMED_BOOKINGLIST,
            payload: {data:res.data},
          });
        } else if (status === "follow_up") {
          dispatch({
            type: PARTNER_FOLLOWUPLIST,
            payload: {data:res.data},
          });
        }
        else if (status === "cancelled") {
          dispatch({
            type: PARTNER_CANCELLED_BOOKINGLIST,
            payload: {data:res.data},
          });
        }
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      } else {
        // console.log("res", res);
      }
     
    });
  };
};



export const searchBooking = (text, status, secId, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const startDate = getState().bookingListing.filterStartDate;
    const endDate = getState().bookingListing.filterEndDate;
    // const secId =  getState().bookingListing.getSecId
    let body = {
      search_term: text,
      status: status,
      sec_id: secId,
      next_page_id: page,
      from: startDate,
      to: endDate,
      "page_size":50
    };
    dispatch({
      type: IS_CORP_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(API.SEARCH_BOOKINGS, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if (status === "booked") {
          dispatch({
            type: SEARCH_BOOKING,
            payload: res.data,
          });
        } else if (status === "confirmed") {
          dispatch({
            type: SEARCH_BOOKING_CONFIRMED,
            payload: res.data,
          });
        } 
        else if (status === "follow_up") {
          dispatch({
            type: SEARCH_BOOKING_FOLLOWUP,
            payload: res.data.data,
          });
        } 
        else if (status === "cancelled") {
          dispatch({
            type: SEARCH_BOOKING_CANCELLED,
            payload: res.data,
          });
        }else if(status === "requested"){
          dispatch({
            type: AVAILABILITY_REQUEST_LIST,
            payload: res.data.data,
          });
        }
        else if(status === "refunded"){
          dispatch({
            type: SEARCH_BOOKING_REFUNDED,
            payload: res.data.data,
          });
        }
        dispatch({
          type: IS_CORP_DATA_LOADING,
          payload: false,
        });
      } else {
        // console.log("res", res);
      }
    });
  };
};

export const filterStartDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: BOOKING_FILTER_START_DATE,
      payload: date,
    });
  };
};

export const filterEndDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: BOOKING_FILTER_END_DATE,
      payload: date,
    });
  };
};

export const setPageNum = (value) => {
  return async (dispatch) => {
    dispatch({
      type: PAGE_NUM,
      payload: value,
    });
  }
};
export const availabilityPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: AVAILABILITY_PAGE_NUM,
      payload: value,
    });
  }
};

export const getStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CORP_BOOKING_STATUS,
      payload: status,
    });
  }
};
export const availabilitySearch = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CORP_AVAILABILITY_SEARCH_VALUE,
      payload: value,
    });
  }
};
export const searchPartnerTextvalue = (value) => {
  return async (dispatch) => {
      dispatch({
        type: SEARCH_TEXT_VALUE,
        payload: value,
      });
  }
}
export const searchTextvalue = (value, status) => {
  return async (dispatch) => {
    if(status === "booked"){
      dispatch({
        type: SEARCH_TEXT_VALUE,
        payload: value,
      });
    }else if(status === "confirmed"){
      dispatch({
        type: CORP_CONFIRMED_SEARCH_VALUE,
        payload: value,
      });
    }
    else if(status === "refunded"){
      dispatch({
        type: CORP_REFUNDED_SEARCH_VALUE,
        payload: value,
      });
    }
    else if(status === "follow_up"){
      dispatch({
        type:CORP_FOLLOWUP_SEARCH_VALUE ,
        payload: value,
      });
    }
    else if(status === "refunded"){
      dispatch({
        type:CORP_REFUNDED_SEARCH_VALUE ,
        payload: value,
      });
    }
    else if(status === "cancelled"){
      dispatch({
        type: CORP_CANCELLED_SEARCH_VALUE,
        payload: value,
      });
    
    }
  }
}
export const searchTruevlue = (value, status) => {
  return async (dispatch) => {
    if(status === "booked"){
      dispatch({
        type: BOOKED_SEARCH_TRUE_VALUE,
        payload: value,
      });
    }else if(status === "confirmed"){
      dispatch({
        type: CONFIRMED_SEARCH_TRUE_VALUE,
        payload: value,
      });
    }else if(status === "cancelled"){
      dispatch({
        type: CANCELLED_SEARCH_TRUE_VALUE,
        payload: value,
      });
    }
  }
}
export const guestModalOpentrue = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: GUEST_MODAL_OPEN_TRUE,
      payload: bool,
    });
  };
};
export const guestData = (adult, children, infant) => {
  return async (dispatch) => {
    dispatch({
      type: ADULTS_GUEST,
      payload: adult,
    });
    dispatch({
      type: CHILDRENS_GUEST,
      payload: children,
    });
    dispatch({
      type: INFANTS_GUEST,
      payload: infant,
    });
  };
};
export const scheduleModalOpenTrue = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: SCHEDULE_MODAL_OPEN_TRUE,
      payload: bool,
    });
  };
};

export const verifyGiftCard = (booking_id, gift_card_code, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      booking_id,
      gift_card_code
  }
    axiosPostWithToken(`${API.VERIFY_GIFT_CARD}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleSuccess(res.data);
        } else {
          handleError(res.data);
        }
      })
      .catch((err) => {
        
            console.log((err && err.response && err.response.data) ||
            "Something went wrong.")
        
      });
  };
};