import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from "../../../assets/images/rightArrow.svg";
import {
  searchExperienceListing,
  experienceSearchValue,
} from "../../../actions/experienceListingAction";
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import * as ExperiencesListingAction from "../../../actions/experienceListingAction";
import {countActivePackages, countExpiredPackages} from "../../../utils/index"
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  rootBorder: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "2px solid #000000",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  location: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 12,
    color: "#797979",
  },
  rightArrow: {
    position: "absolute",
    right: "16px",
    top: "22px",
  },
}));

export default function Listing({
  currentPage,
  experienceDetails,
  handleEdit,
  handleAdd,
  handleResetPage
}) {
  const selectedId =
    experienceDetails?.results && experienceDetails?.results[0]?.id;
  const classes = useStyles();
  const [add, setAdd] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  // const [search, setSearch] = useState("");
  const [searchBool, setsearchBool] = useState(false);
  const [chosen, setChosen] = useState(selectedId);
  const [addFlowLogo, setAddFlowLogo] = useState();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [editDetailsId, setEditDetailsId] = useState("");
  const updateExperienceListingBool = useSelector(
    (state) => state.experiencesListing.experienceUpdateBoolVal
  );
  const isDataLoading = useSelector(
    (state) => state.experiencesListing.isListingDataLoading
  );
  const search = useSelector(
    (state) => state.experiencesListing.experiencelistSearchVal
  );
  const experienceListingDropDownVal = useSelector(
    (state) => state.experiencesListing.experienceListingDropDownvalue
  );
  const selectedChosenPckId = useSelector(
    (state) => state.experiencesListing.selectedChosenId
  );
  const dispatch = useDispatch();
  const addExperiencePackage = useSelector(
    (state) => state.experiencesListing.addExperiencesPackage
  );

  const experienceSearchListData = useSelector(
    (state) => state.experiencesListing.searchExperiencesListData.data
  );

  useEffect(() => {
    setChosen(experienceDetails?.results && experienceDetails?.results[0]?.id);
    if (updateExperienceListingBool) {
      if(editDetailsId){
      dispatch(ExperiencesListingAction.getPackageInfo(editDetailsId));
      }
      setChosen(editDetailsId);
    } else {
      if(experienceDetails?.results?.length > 0){
        dispatch(
          ExperiencesListingAction.getPackageInfo(
            experienceDetails?.results[0]?.id
          )
        );
        setChosen(experienceDetails?.results[0]?.id);
      }
    }
  }, [
    currentPage,
    experienceDetails?.results && experienceDetails?.results[0]?.id,
  ]);

  useEffect(() => {
    dispatch(ExperiencesListingAction.experienceListsUpdateBool(false));
  }, [currentPage]);

  useEffect(() => {
    if (selectedChosenPckId) {
      setChosen(experienceDetails?.results[0]?.id);
      dispatch(
        ExperiencesListingAction.getPackageInfo(
          experienceDetails?.results[0]?.id
        )
      );
      dispatch(ExperiencesListingAction.setChosenPackIDBool(false));
    }
  }, [selectedChosenPckId]);
  useEffect(()=>{
    if(search==''){
  setSearchVal('')
    }
  },[search])
  const setEditDetails = (id) => {
    setEditDetailsId(id);
    addExperiencePackage &&
      dispatch(ExperiencesListingAction.setAddPackage(false));
    setChosen(id);
    setShow(false);
    handleEdit(id);
    dispatch(ExperiencesListingAction.getPackageInfo(id));
  };

  useEffect(() => {
    setShow(false);
    dispatch(ExperiencesListingAction.setAddPackage(false));
  }, []);

  const setNewDetails = (id) => {
    setAddFlowLogo(id);
    setShow(true);
    dispatch(ExperiencesListingAction.setAddPackage(true));
    dispatch(ExperiencesListingAction.handleAllImages([]));
    handleAdd();
    setSearchVal('')
  };

  // const experienceSearchlisting = (event) => {
  //   let input = event.target.value;
  //   dispatch(searchExperienceListing(input));
  //   dispatch(experienceSearchValue(input));
  // };
  const handleChange=(val)=>{
    setSearchVal(val)
    if(val===''){
      dispatch(ExperiencesListingAction.getExperienceListing(1,experienceListingDropDownVal?.id));
      dispatch(ExperiencesListingAction.experienceSearchValue(''));
    }
  }
  useEffect(() => {
    handleResetPage()
    if(searchVal){
    const getData = setTimeout(() => {
      dispatch(ExperiencesListingAction.searchExperienceListing(searchVal));
      dispatch(ExperiencesListingAction.pageId(1));
      dispatch(ExperiencesListingAction.experienceSearchValue(searchVal));
    }, 350)
    return () => clearTimeout(getData)
  }
  }, [searchVal]);
  return (
    <div style={{ width: "100%" }}>
      <Row
        className={classes.row}
        style={{ margin: "0px", alignItems: "center" }}
      >
        <Col className="w-100">
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Col>
        <Col>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div
        className="overflow--scroll"
        style={{ paddingRight: "20px", marginRight: "-20px" }}
      >
        {addExperiencePackage && (
          <Card className={addFlowLogo ? classes.rootBorder : classes.root}>
            <h6 className={classes.title}>Adding New</h6>
            <p className={classes.location}>No location provided yet</p>
            {addFlowLogo ? (
              <img className={classes.rightArrow} src={rightArrow} />
            ) : null}
          </Card>
        )}
        {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}>
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {searchBool
          ? experienceDetails &&
            experienceDetails.length > 0 &&
            experienceDetails.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {item.city}
                  {item.city !== "" && item.state !== ""
                    ? ", "
                    : "No location provided yet"}
                  {item.state}
                </p>
                <h6 className={classes.title}>{item.section.name}</h6>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))
          : experienceDetails?.results &&
            experienceDetails?.results.length > 0 &&
            experienceDetails?.results.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {item?.city}
                  {(item?.city === "" || item?.city === null) &&
                  (item.state === "" || item.state === null) ? (
                    <p className="no-location">No location provided yet </p>
                  ) : (
                    ", "
                  )}
                  {item?.state}
                </p>
                <p style={{ marginBottom: "10px"}}>{item.section === null ? "" : item.section.name}</p>
                <p style={{marginBottom: "0px", fontSize: "13px"}}><span style={{color: "green"}}> {countActivePackages(item)}</span><span style={{color: "#CD1414"}}> {countExpiredPackages(item) ? ", " + countExpiredPackages(item) : null}</span></p>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))}
        {experienceDetails?.results?.length === 0 && (
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card>
        )}
      </div>
    </div>
  );
}
