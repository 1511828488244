import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as enquiryAction from "../../../actions/enquiryAction";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    padding: 6,
    borderRadius: 3,
  },
}));

const SearchBar = ({ status, searchValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getPageNum = useSelector((state) => state?.enquiryListing?.page_num);
  const startDate = useSelector(
    (state) => state.enquiryListing.filterStartDate
  );
  const endDate = useSelector((state) => state.enquiryListing.filterEndDate);
  const enquiriesSearchVal = useSelector((state) => state?.enquiryListing?.corpEnqSearchValue);

  // useEffect(() => {
  //   dispatch(enquiryAction.enquiryStatus(status));
  // }, []);

  useEffect(() => {
    dispatch(enquiryAction.searchEnquiry("", status, 1));
  }, [startDate, endDate]);

  const handleChange = (value) => {
    // console.log("value", value.target.value)
    let searchVal =  value.target.value
    // search(value);
    dispatch(enquiryAction.searchEnquiry(value.target.value, status, 1));
    dispatch(enquiryAction.corpEnquiriesSearchvalue(searchVal, status))
  };

  // const sendQuery = async (value) => {
  //   dispatch(enquiryAction.searchEnquiry(value.target.value, status, 1));
  //   // dispatch(enquiryAction.setPageNum(1));
  // };

  // const search = useCallback(_.debounce(sendQuery, 500), []);

  return (
    <TextField
      className={classes.input}
      defaultValue={""}
      size="small"
      placeholder={"Search"}
      fullWidth={true}
      // value={enquiriesSearchVal}
      value={searchValue}
      onChange={handleChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default SearchBar;
