import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as corpListingAction from "../../../src/actions/corpListingAction";

function Index() {
    const dispatch = useDispatch()
    const subscribeCount = useSelector((state) => state?.corpListing?.subscribeCount);
    useEffect(() => {
        dispatch(corpListingAction.getSubscribersCount())
    }, [])
    
  return (
    <div style={{height: "100vh"}}>
        <p className="title-holder">Subscription Numbers</p>
        <div className='subscribtion-card'>
            <h1>Subscribers</h1>
            <p>{subscribeCount?.subscribe_count}</p>
        </div>
    </div>
  )
}

export default Index