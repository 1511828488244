import React from 'react'
import moment from 'moment'
import Edit from "../../../assets/images/edit.svg";
import { scheduleDaysVal, scheduleNightsVal } from '../../../utils/index';

const ScheduleBoxItinerary = ({setPopupSelect, state, isEdit}) => {
  
  return (
    <div className="itinerary-schedule-box">
        <div className='d-flex justify-content-between add' >
            <p>Schedule</p>
            <div className='add-button' onClick={() => setPopupSelect(true)}><a>Add</a></div>
        </div>
        <div className='dates-card'>
          {state?.schedule_type == "package"? 
            <p>{scheduleDaysVal(state?.schedule_data?.days) + scheduleNightsVal(state?.schedule_data?.nights)}</p> 
            : 
            <div>
              {state && state?.schedule_data?.selectedDates?.map((item, index) => (
                <div className='date-item'>
                  <p>{moment(item?.from, "DD-MM-YYYY").format("Do-MMM-YYYY")} - {moment(item?.to, "DD-MM-YYYY").format("Do-MMM-YYYY")} </p>
                </div>
              ))}
          </div>}
          {isEdit && <img
            src={Edit}
            style={{ position: "relative"}}
            onClick={() => setPopupSelect(true)}
          />}
        </div>
    </div>
  )
}

export default ScheduleBoxItinerary