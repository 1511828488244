import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { DELETE_CONFIRMATION_MESSAGE } from '../utils/constants';

const useStyles = makeStyles({
  test: {
    color: red,
  },
  black:{
    color:"black"
  }
});

const DeleteConfirmationModal = ({open, btnTextOne, btnTextTwo, deleteImage, onClick, disable, deleteConfirmationMsg, fontSize}) => {
  const classes = useStyles()
  return (
      <Dialog
      open={open}
      >
      <DialogTitle >
        <h4 style={{fontSize:fontSize}}>{deleteConfirmationMsg}</h4>
      </DialogTitle>
        <DialogActions>
          <Button onClick={onClick} color="primary">
            {btnTextOne}
          </Button>
          <Button onClick={deleteImage} color="primary" disabled={disable}>
            {btnTextTwo}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
