import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "antd";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ArrowDown from "../../assets/images/arrowDown.svg";
import editblackLogo from "../../assets/images/edit.svg";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import Spinner from "./loaderSpinner";
import * as webStoriesAction from "../../actions/webStoriesAction";
import { useSelector, useDispatch } from "react-redux";
import DelIcon from "../../assets/images/delete.svg";
import { useSnackbar } from "notistack";



const Cardmodal = ({
    setModalCard,
    stories,
    sortOrder,
    setSortOrder,
    indx,
    setStories,
    setIndx,
    sliteIndx,
    slideEditDetails,
    setSlideEditDetils,
    slideEdit,
    imageId
}) => {

    const FileUrl = useSelector(
        (state) => state?.webStories?.webStoriesImageUrl?.url
    );
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState(true);
    const [select, setSelect] = React.useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [valError, setValError] = useState(false);
    const [mediaTitle, setMediaTitle] = useState();
    const[altText,setAltText]=useState("")
    console.log(altText,"asdf");
    const [mediaDescription, setMediaDescription] = useState();
    const [visibleMedia, setVisibleMedia] = useState(false);
    const [mediaUrl, setMediaUrl] = useState();
    const[loading,setLoading]=useState(false)
   

    const filteredDropDownList = {
        "Zoom in": "zoom-in",
        "Zoom out": "zoom-out",
        "Pan left": "pan-left",
        "Pan right": "pan-right",
        "Pan top": "pan-up",
        "Pan down": "pan-down",
    };

    const handleAdd = () => {
        if(mediaTitle?.length>50){
            enqueueSnackbar("Image Title length exceeding 50 character", {
                variant: "error",
            });

        }
        else if(mediaDescription?.length>175){

            enqueueSnackbar("Image Description length exceeding 175 character", {
                variant: "error",
            });


        }

       else {
        if(mediaUrl ){
            // const toStories = [...stories];
            // toStories[indx] = {
            //     id:indx + 9,
            //     media_title: mediaTitle,
            //     media_description: mediaDescription,
            //     media_caption_placement: "center",
            //     transition_effects: dropdownValue,
            //     media_url: mediaUrl,
            //     media_type: "image",
            //     sort_order: sortOrder,
            // };
            setStories(prevState => [
                ...prevState,
                {
                    media_title: mediaTitle ? mediaTitle : "",
                    media_description: mediaDescription ? mediaDescription : "",
                    media_caption_placement: "center",
                    transition_effects: dropdownValue ? dropdownValue : "",
                    media_url: mediaUrl,
                    media_type: "image",
                    sort_order: sortOrder,
                    alt_text: altText
                },
            ]);
            

            setIndx(indx + 1);
            setSortOrder(sortOrder + 1);
            setMediaUrl("");
            setModalCard(false);
            setVisibleMedia(false);
            setSlideEditDetils(false);
        } else setValError(true);

        }
            
    };

    const handleUpdate = () => {

        if(mediaTitle?.length>50){
            enqueueSnackbar("Image Title length exceeding 50 character", {
                variant: "error",
            });

        }
        else if(mediaDescription?.length>175){

            enqueueSnackbar("Image Description length exceeding 175 character", {
                variant: "error",
            });


        }
        else {
        if (mediaUrl) {
            console.log("cllling");
            const toStories = [...stories];
            toStories[indx] = {
                id:imageId,
                media_title: mediaTitle?mediaTitle:"",
                media_description: mediaDescription?mediaDescription:"",
                media_caption_placement: "center",
                transition_effects: dropdownValue?dropdownValue:"",
                media_url: mediaUrl,
                media_type: "image",
                sort_order: sortOrder,
                alt_text: altText
            };
            
            setStories(toStories);
            setSlideEditDetils(false);

            setMediaUrl("");
            setModalCard(false);
            setVisibleMedia(false);
        }
    }
    };

    const getMediaUrl = (file) => {

       const media = file[0];
        let mediaExtension;

        if(media){
            mediaExtension=media?.name?.split(".").pop().toLowerCase()

        }
            if(mediaExtension=="mp4"||mediaExtension=="jpeg"||mediaExtension=="jpg"||mediaExtension=="png"||mediaExtension=="webp"){
                setLoading(true)
                    dispatch(webStoriesAction.mediaWebstories(media,setVisibleMedia,setLoading));
               

        }
        else{
            enqueueSnackbar("Please upload mp4/jpeg/jpg/png/webp format media only", {
                variant: "error",
            });

        }
        
    };

    const handleAddcancel = () => {
        setMediaUrl("");
        setModalCard(false);
        setVisibleMedia(false);
        setSlideEditDetils(false);
    };

    const handleUpdatecancel = () => {
        setMediaUrl("");
        setModalCard(false);
        setVisibleMedia(false);
        setSlideEditDetils(false);
    };

    const handleClickSelect = (event) => {
        setSelect(event.currentTarget);
    };
    const handleCloseSelect = (item) => {
        setDropdownValue(item);
        setSelect(null);
        // setIsCorpAddSecnameErrorValidation(false);
    };
    const deleteImg=()=>{
        setVisibleMedia(false)
        setMediaUrl("")
    }

    useEffect(() => {
        setMediaUrl(FileUrl);
    }, [FileUrl]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if (slideEditDetails) {
            const urlinArray = slideEditDetails.map((elem) => elem.media_url);
            const titleArray = slideEditDetails.map((elem) => elem.media_title);
            const descriptionArray = slideEditDetails.map(
                (elem) => elem.media_description
            );
            const animationinArray = slideEditDetails.map(
                (elem) => elem.transition_effects
            );
            const alttext=slideEditDetails.map((elem) => elem.alt_text);
            setMediaUrl(urlinArray[0]);
            setMediaTitle(titleArray[0]);
            setMediaDescription(descriptionArray[0]);
            setDropdownValue(animationinArray[0]);
            setAltText(alttext[0])
        }
    }, [slideEditDetails]);

    useEffect(()=>{
        if(mediaTitle?.length>50){
            enqueueSnackbar("Image Title length exceeding 50 character", {
                variant: "error",
            });

        }
        else if(mediaDescription?.length>175){

            enqueueSnackbar("Image Description length exceeding 175 character", {
                variant: "error",
            });


        }

      

    },[mediaTitle,mediaDescription])

    return (
        <div >
            {!slideEditDetails ? (
                <Row className="story-card-modal">
                    <Col className="story-modal-add-image" style={{marginTop:"60px", border:visibleMedia?"":" 2px dashed black", backgroundColor:visibleMedia?"": "#F4F4F4"}}>
                        {visibleMedia &&
                        mediaUrl?.split(".")
                            .pop()
                            .toLowerCase() == "mp4" ? (
                                <div style={{ position: "relative", display: "inline-block" }}>
                                <video controls width="100%" height="350">
                                    <source src={mediaUrl} type="video/mp4" />
                                </video>
                                <button
                                     style={{
                                        position: "absolute",
                                        top: "15px",
                                        right: "10px",
                                        zIndex: 1,
                                        // backgroundColor: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => deleteImg()}
                                    className="uploded-image-delete"
                                >
                                    <img src={DelIcon} alt="delIcon" />
                                </button>
                            </div>

                        ) : visibleMedia ? (
                            <>  <img
                            src={mediaUrl}
                            alt="uploadIcon"
                            className="uploded-image"
                        />
                       <button  className="uploded-image-delete"onClick={deleteImg} ><img src={DelIcon} alt="delIcon"  /></button> 
                            </>
                          
                        ) : (
                            <>
                            {loading?<p>Uploading....</p>:<div className="story-modal-image-guidelines">
                                <div className="story-modal-upload-img">
                                    <label for="file-input">
                                        <img
                                            src={uploadIcon}
                                            alt="uploadIcon"
                                        />
                                    </label>

                                    <input
                                        id="file-input"
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            getMediaUrl(e.target.files)
                                        }
                                    />
                                </div>

                              <p style={{fontSize:"14px"}}><b>Drag & Drop images or videos</b></p>
                                <div className="story-modal-image-recomadation">
                                    <p>
                                        image should meet the recommended
                                        minimum size:
                                    </p>
                                    <ul>
                                        <li>Portrait: 640px x 853px</li>
                                        <li>Landscape: 853px x 640px</li>
                                        <li>Square: 640px x 640px</li>
                                    </ul>
                                </div>
                            </div>}
                            </>
                            
                        )}
                    </Col>

                    <Col className="story-modal-image-details">
                        <Row className="story-modal-image-title">
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Image Title</p>
                                    <div className="story-title-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(event) => {
                                                setMediaTitle(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-title" style={{marginTop:"15px"}}>
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Alt Text</p>
                                    <div className="story-title-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(event) => {
                                                setAltText(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-description">
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Image Description </p>
                                    <div className="story-title-input">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            onChange={(event) => {
                                                setMediaDescription(
                                                    event.target.value
                                                );
                                            }}
                                            style={{resize: "none"}}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-animation">
                            <div
                                className="section-dropdown"
                                style={{
                                    position: "relative",
                                    cursor: "pointer",
                                    width: "100%",
                                    background: " #F5F5F5;",
                                }}
                            >
                                <div>
                                    <Button
                                        style={{
                                            paddingRight: "50%",
                                            width: "100%",
                                            marginTop: "20px",
                                        }}
                                        aria-controls="section-menu"
                                        aria-haspopup="true"
                                        onClick={handleClickSelect}
                                    >
                                        {dropdownValue || "Select animation"}
                                        <img
                                            alt="arrow"
                                            src={ArrowDown}
                                            style={{
                                                position: "absolute",
                                                left: "90%",
                                                top: "12px",
                                            }}
                                        />
                                    </Button>
                                    <Menu
                                        id="section-menu"
                                        anchorEl={select}
                                        keepMounted
                                        open={Boolean(select)}
                                        onClose={() => setSelect(null)}
                                        style={{ width: "400px" }}
                                    >
                                        {Object.entries(
                                            filteredDropDownList
                                        ).map(([key, value]) => {
                                            return (
                                                <MenuItem
                                                    onClick={() =>
                                                        handleCloseSelect(value)
                                                    }
                                                    key={key}
                                                    style={{ width: "305px" }}
                                                >
                                                    {key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </div>
                            </div>
                        </Row>
                    </Col>

                    <div className="story-card-modal-button" style={{marginTop:"120px"}}>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            style={{ padding: "5px 15px" }}
                            onClick={handleAddcancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={loading?true:false}
                            class="btn btn-primary"
                            style={{ marginLeft: "20px", padding: "5px 15px" }}
                            onClick={() => handleAdd()}
                        >
                            Add
                        </button>
                    </div>

                    {valError ? (
                        <p style={{ color: "red", marginTop: "5px" }}>
                            <b>please add image</b>
                        </p>
                    ) : (
                        ""
                    )}
                </Row>
            ) : (
                <Row className="story-card-modal" style={{height:"auto",display:"flex"}}>
              
                     <Col className="story-modal-add-image" style={{marginTop:"60px"}}>
                        {
                        mediaUrl?.split(".")
                            .pop()
                            .toLowerCase() == "mp4" ? (
                                <div style={{ position: "relative", display: "inline-block" }}>
                                <video controls width="220px" height="350">
                                    <source src={mediaUrl} type="video/mp4" />
                                </video>
                                <button
                                     style={{
                                        position: "absolute",
                                        top: "15px",
                                        right: "10px",
                                        zIndex: 1,
                                        // backgroundColor: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setMediaUrl("")}
                                    className="uploded-image-delete"
                                >
                                    <img src={DelIcon} alt="delIcon" />
                                </button>
                            </div>

                        ) : mediaUrl ? (
                            <>  <img
                            src={mediaUrl}
                            alt="uploadIcon"
                            className="uploded-image"
                        />
                       <button  className="uploded-image-delete"onClick={()=>setMediaUrl("")} ><img src={DelIcon} alt="delIcon"  /></button> 
                            </>
                          
                        ) : (<>
                         {loading?<p>Uploading....</p>:  <div className="story-modal-image-guidelines">
                                <div className="story-modal-upload-img">
                                    <label for="file-input">
                                        <img
                                            src={uploadIcon}
                                            alt="uploadIcon"
                                        />
                                    </label>

                                    <input
                                        id="file-input"
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            getMediaUrl(e.target.files)
                                        }
                                    />
                                </div>

                                <p style={{fontSize:"14px"}}><b>Drag & Drop images or videos</b></p>
                                <div className="story-modal-image-recomadation">
                                    <p>
                                        image should meet the recommended
                                        minimum size:
                                    </p>
                                    <ul>
                                        <li>Portrait: 640px x 853px</li>
                                        <li>Landscape: 853px x 640px</li>
                                        <li>Square: 640px x 640px</li>
                                    </ul>
                                </div>
                            </div>}
                        </>
                           
                        )}
                    </Col>

                    <Col className="story-modal-image-details">
                        <Row className="story-modal-image-title">
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Image Title</p>
                                    <div className="story-title-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={mediaTitle}
                                            onChange={(event) => {
                                                setMediaTitle(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-title" style={{marginTop:"15px"}}>
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Alt Text</p>
                                    <div className="story-title-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={altText}
                                            onChange={(event) => {
                                                setAltText(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-description">
                            <div className="story-title-box">
                                <div className="story-title-label">
                                    <p>Image Description </p>
                                    <div className="story-title-input">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            value={mediaDescription}
                                            onChange={(event) => {
                                                setMediaDescription(
                                                    event.target.value
                                                );
                                            }}
                                            style={{resize: "none"}}
                                        />
                                        <img
                                            src={editblackLogo}
                                            alt="editlogo"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="story-modal-image-animation">
                            <div
                                className="section-dropdown"
                                style={{
                                    position: "relative",
                                    cursor: "pointer",
                                    width: "100%",
                                    background: " #F5F5F5;",
                                }}
                            >
                                <div>
                                    <Button
                                        style={{
                                            paddingRight: "50%",
                                            width: "100%",
                                            marginTop: "20px",
                                        }}
                                        aria-controls="section-menu"
                                        aria-haspopup="true"
                                        onClick={handleClickSelect}
                                    >
                                        {dropdownValue || "Select animation"}
                                        <img
                                            alt="arrow"
                                            src={ArrowDown}
                                            style={{
                                                position: "absolute",
                                                left: "90%",
                                                top: "12px",
                                            }}
                                        />
                                    </Button>
                                    <Menu
                                        id="section-menu"
                                        anchorEl={select}
                                        keepMounted
                                        open={Boolean(select)}
                                        onClose={() => setSelect(null)}
                                        style={{ width: "400px" }}
                                    >
                                        {Object.entries(
                                            filteredDropDownList
                                        ).map(([key, value]) => {
                                            return (
                                                <MenuItem
                                                    onClick={() =>
                                                        handleCloseSelect(value)
                                                    }
                                                    key={key}
                                                    style={{ width: "305px" }}
                                                >
                                                    {key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </div>
                            </div>
                        </Row>
                    </Col>
                    <div className="story-card-modal-button" style={{marginTop:"120px"}}>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            style={{ padding: "5px 15px" }}
                            onClick={handleUpdatecancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            style={{ marginLeft: "20px", padding: "5px 15px" }}
                            onClick={() => handleUpdate()}
                        >
                            Update
                        </button>
                    </div>
                </Row>
            )}
        </div>
    );
};

export default Cardmodal;
