import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from '../../../assets/images/rightArrow.svg'
import { useDispatch, useSelector } from "react-redux";
import * as weddingAction from "../../../actions/weddingAction";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: 10,
        boxShadow: "none",
        background: "#FAFAFA",
        border: "1px solid #EDEDED",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "18px 20px",
        minHeight: 78,
        cursor: "pointer",
    },
    rootBorder: {
        position: 'relative',
        marginTop: 10,
        boxShadow: "none",
        background: "#FAFAFA",
        border: "2px solid #000000",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "18px 20px",
        minHeight: 78,
        cursor: "pointer"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
    },
    title: {
        fontSize: 16,
        marginBottom: 4,
        fontWeight: 500,
        color: "#000000",
        marginRight: 10
    },
    location: {
        marginBottom: 0,
        fontWeight: 400,
        fontSize: 12,
        color: "#797979",
    },
    rightArrow: {
        position: 'absolute',
        right: '16px',
        top: '22px'
    }
}));

export default function Listing({ storyList, setIsAdd, selectedId, setSelectedId,setSearchVal,searchVal }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const handleSearch = (searchVal) => {
        dispatch(weddingAction.searchStory(searchVal));
        setSearchVal(searchVal)
    }
    const isDataLoading = useSelector(
        (state) => state.holidayListing.isListingDataLoading
      );

    return (
        <div style={{width:"100%"}}>
            <Row className={classes.row} style={{margin:"3px 3px 0px 2px", alignItems:"center"}}>
                <Col className="w-100">
                    <input
                        type="search"
                        className="form-control search-list"
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e)=>handleSearch(e.target.value)}
                    />
                </Col>
                <Col>
                    <button disabled={storyList?.length>=6}  className="btn add-new" onClick={()=>{setIsAdd(true);setSelectedId(null)}}>
                        Add New <AddSharpIcon />
                    </button>
                </Col>
            </Row>
            <div className='overflow--scroll' style={{ paddingRight: '20px', marginRight: '-20px' }}>    
                {
                    storyList?.length > 0 &&
                    storyList.map((item) => (
                        <Card
                            className={selectedId===item.id ? classes.rootBorder : classes.root}
                            onClick={() => {setIsAdd(false);setSelectedId(item.id)}}
                        >
                            <h6 className={classes.title}>{item.title} </h6>
                            
                            {true ? <img className={classes.rightArrow} src={rightArrow} /> : null}

                        </Card>
                    )
                    )}
                     {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}>
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
                {storyList?.length === 0 &&
                    <Card className={classes.root}>
                        <p>No Records Found!</p>
                    </Card>}
            </div>
        </div>
    );
}
