import { Dialog } from '@material-ui/core';
import React from 'react';
import { Calendar } from "react-multi-date-picker";
import { ImCross } from "react-icons/im";
import '../../../assets/css/partials/openingSoonCalander.scss';

const OpeningSoonCalander = ({ open, closeHandle, dateChange, value }) => {
    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    height: "auto",
                    maxWidth: "400px",
                    padding: "25px"
                },
            }}
        >
            <div style={{ display: "flex", marginBottom: "10px" }}>
                <h5>Opening On</h5>
                <span style={{ position: "absolute", right: "30px" }} onClick={closeHandle}>
                    <ImCross />
                </span>
            </div>
            <div className="opening-soon-calander-container">
                <Calendar
                    format="DD-MM-YYYY"
                    onChange={(date) => dateChange(date)}
                    value={value}
                    minDate={new Date().toISOString().split('T')[0]}
                />
            </div>
        </Dialog>
    );
};

export default OpeningSoonCalander;
