import React, { useState } from 'react'
import  { Calendar } from "react-multi-date-picker";
import '../../assets/css/partials/availabilityFlexiDatePopup.scss';
import { packageDateItem } from '../../actions/experienceBookingAction';
import { useDispatch } from 'react-redux';

const AvailabilityPackage = ({DonedHandler, availabilityData}) => {
    const [ isUpdated, setIsUpdated ] = useState(false)
    const dispatch = useDispatch();

    const [ value, setValue ] = useState([
      availabilityData.schedule.from,
      availabilityData.schedule.to
    ]);

    const packagedateValue = (val) => {
        setValue(val)
        setIsUpdated(true)
        dispatch(packageDateItem(val[0].format(), val[1]?.format()))
    } ;

    return (
        <div className="calander-container">
         <Calendar
            minDate={new Date()}
            range
            format={'DD-MM-YYYY'}
            value={value} 
            onChange={packagedateValue}
         />
         <div style={{textAlign:"end", marginBottom:"25px"}}>
            <button onClick={() => DonedHandler({from:isUpdated? value[0]?.format() : value[0], to:isUpdated? value[1]?.format(): value[1]})}>Done</button>
         </div>
        </div>
    );
};

export default AvailabilityPackage;
