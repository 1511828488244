import React, { useState, useEffect } from 'react'
import ExperienceSections from './experienceSections'
import { useSelector, useDispatch } from 'react-redux'
import AddNewSection from '../../../common/addNewSection'
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import { DONE, CANCEL } from '../../../utils/constants'
import CommonAddNew from '../../../common/commonAddNew';
import { experienceSelectedImageUrl, imageUpload, addExperienceSection, getExperienceSection } from '../../../actions/experienceSectionAction';
import { useSnackbar } from "notistack";
import { Spinner } from 'react-bootstrap';

const Index = () => {
  const [ showExperienceAddNewSectionModal, setShowExperienceAddNewSectionModal ] = useState(false);
  const [ experienceSectionName, setExperienceSectionName ] = useState("");
  const [ experienceSectionDesc, setExperienceSectionDesc ] = useState("");
  const [ experienceSectionPriority, setExperienceSectionPriority ] = useState("");
  const [refCodeValue, setRefCodeValue] = useState("");
  const [ experienceSectionPriorityBool, setExperienceSectionPriorityBool ] = useState(false);
  const [ experienceSectionNameBool, setExperienceSectionNameBool ] = useState(null);
  const [ experienceSectionDescBool, setExperienceSectionDescBool ] = useState(null);
  const [ experienceSectionImageBool, setExperienceSectionImageBool ] = useState(null);
  const [ experienceSectionPriorityEmptyBool, setExperienceSectionPriorityEmptyBool ] = useState(null);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ descMoreError, setDescMoreErrorr ] = useState(null);
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
   
  const experienceSectionData = useSelector(state => state.experienceSection?.getExperienceSection);
  const experienceSelectedImgUrl = useSelector(state => state.experienceSection.experienceSelectedImageUrl);
  const isDataLoading = useSelector(
    (state) => state.experiencesListing.isDataLoading
  );
  const updatePic = (data) => {
    dispatch(experienceSelectedImageUrl(data.url));
  };
  useEffect(() => {
    dispatch(getExperienceSection());
  }, [experienceSelectedImgUrl]);

  useEffect(() => {
    if(refCodeValue.length === 2){
      setCodeLessErrorr(false);
      setCodeMoreError(false);
    }
   }, [refCodeValue]);

  // useEffect(() => {
  //   dispatch(getExperienceSection());
  // }, [experienceSelectedImgUrl]);

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(imageUpload(croppedFile, updatePic));
    }
  };

  const addedSuccessHandle = () => {
    enqueueSnackbar("Added successfully", {
      variant: "success",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setExperienceSectionImageBool(false);
    setExperienceSectionNameBool(false);
    setExperienceSectionDescBool(false);
    setExperienceSectionPriorityBool(false);
    setExperienceSectionPriorityEmptyBool(false);
    setIscodeError(false);
    setCodeLessErrorr(false);
    setCodeMoreError(false)
    const experienceSectionSubmitData = {
      "image":experienceSelectedImgUrl,
      "name":experienceSectionName,
      "description":experienceSectionDesc,
      "priority":experienceSectionPriority,
      "code":refCodeValue,
      "title_text":imageTitle,
      "alt_text":altText
    }
    if(experienceSelectedImgUrl === null)setExperienceSectionImageBool(true);
    else if(experienceSectionName === "")setExperienceSectionNameBool(true);
    else if(experienceSectionDesc === "")setExperienceSectionDescBool(true);
    else if(experienceSectionDesc.length > 200)setDescMoreErrorr(true)
    else if(experienceSectionPriority === "")setExperienceSectionPriorityEmptyBool(true);
    else if(experienceSectionPriority <= -1)setExperienceSectionPriorityBool(true);
    else if(refCodeValue === "")setIscodeError(true)
    else if(refCodeValue.length < 2)setCodeLessErrorr(true)
    else if(refCodeValue.length > 2)setCodeMoreError(true)
    else if(refCodeValue.length == 2 && codeMatchError)setCodeMatchError(true)
    else{
      dispatch(addExperienceSection(experienceSectionSubmitData, refreshAddExperienceSectionData,addedSuccessHandle));
      dispatch(experienceSelectedImageUrl(null));
      setShowExperienceAddNewSectionModal(false)
      setExperienceSectionName("");
      setExperienceSectionDesc("");
      setExperienceSectionPriority("");
      setRefCodeValue("")
      setAltText("")
      setImageTitle("")
      dispatch(getExperienceSection());
      
    }
  };
 
  const refreshAddExperienceSectionData = () => {
    dispatch(getExperienceSection());
  };

  const experienceAddNewSectionModalOpenHandler = () => {
    setShowExperienceAddNewSectionModal(true);
  };

  const closeExperienceAddNewSectionModal = () => {
    setShowExperienceAddNewSectionModal(false)
  };

  const handleSectionPriority = (e) => {
    setExperienceSectionPriorityBool(false);
    setExperienceSectionPriority(e.target.value);
    if(e.target.value <= -1) setExperienceSectionPriorityBool(true);
  };

  const refrenceCodeHandler = (e) => {
    // console.log("e", e.target.value)
    // setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredData = experienceSectionData.filter(item => item.code === code)
    if(filteredData.length){
      setCodeMatchError(true)
    }else{
      setCodeMatchError(false)
    }
    setRefCodeValue(code)
    // experienceSectionData.map(item => {
    //   if(code !== '' && code === item.code)setCodeMatchError(true)
    //   else {
    //     setRefCodeValue(code);
    //     // setCodeMatchError(false)
    //   }
    // })
  };
  
  
  const handleExperienceSectionName = (e) => {
    setExperienceSectionPriority(e.target.value);
    
  }
  return (
    <>
      <h2> {experienceSectionData.length} Experiences Sections</h2>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop:"30px"}}>
      <ExperienceSections/>
        <CommonAddNew 
          onClick={experienceAddNewSectionModalOpenHandler}
          textTitle="Add New Section"
        />
        {isDataLoading &&
          <div className="blured-backround">
            <p style={{ paddingTop: "40%", marginLeft: "10%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
      </div>
      {showExperienceAddNewSectionModal && (
        <AddNewSection
          showMyModal={showExperienceAddNewSectionModal}
          openHandler={showExperienceAddNewSectionModal}
          togglebackDrop={showExperienceAddNewSectionModal}
          heading="Add new Section"
          image={ experienceSelectedImgUrl !== null ?  experienceSelectedImgUrl : DefaultImage}
          imageText="Image"
          labelTextOne="Section name"
          labelTextTwo="Priority"
          labelTextThree="Description"
          codeRefrence="Code"
          btnTextOne={CANCEL}
          btnTextTwo={DONE}
          valueOne={experienceSectionName}
          valueThree={experienceSectionDesc}
          // onChangeOne={(e) => handleExperienceSectionName(e)}
          onChangeOne={(e) => setExperienceSectionName(e.target.value)}
          valueTwo={experienceSectionPriority}
          onChangeTwo={(e) => handleSectionPriority(e)}
          onChangeThree={(e) => setExperienceSectionDesc(e.target.value)}
          refCodeValue={refCodeValue}
          refrenceCodeHandler={refrenceCodeHandler}
          handleSubmit={handleSubmit}
          closeModal={closeExperienceAddNewSectionModal}
          closeHandle={() => setShowExperienceAddNewSectionModal(false)}
          isExperiencesAddNewSection={true}
          isExperiencesUpdateSection={false}
          handleFilePickChange={handleImageChange}
          isExperienceSection={true}
          isPriorityError={experienceSectionPriorityBool}
          isSectionNameError={experienceSectionNameBool}
          isSectionDescError={experienceSectionDescBool}
          isImage={experienceSectionImageBool}
          iscodeError={iscodeError}
          codeLessError={codeLessError}
          codeMoreError={codeMoreError}
          codeMatchError={codeMatchError}
          descMoreError={descMoreError}
          isPriorityEmptyError={experienceSectionPriorityEmptyBool}
          isDescription={true}
          imageTitle={imageTitle}
          setImageTitle={setImageTitle}
          altText={altText}
          setAltText={setAltText}
        />
      )}
    </>
  );
};

export default Index;
