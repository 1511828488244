import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from "antd";
import Datefilter from '../../../../common/commonDateFIlter'
import Requested from './requested';
import Planning from './planning';
import Confirmed from './confirmed';
import Cancelled from './cancelled';
import * as HoneymoonAction from '../../../../actions/honeymoonAction'
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
const { TabPane } = Tabs;

const Index = () => {
  const [ status, setStatus ] = useState("requested");
  const dispatch = useDispatch();

  const totalRequestedResults = useSelector(state => state.honeymoon.honeymoonRequested?.total_results);
  const totalPlanningResults = useSelector(state => state.honeymoon.honeymoonPlanning?.total_results);
  const totalConfirmedResults = useSelector(state => state.honeymoon.honeymoonConfirmed?.total_results);
  const totalCancelledResults = useSelector(state => state.honeymoon.honeymoonCancelled?.total_results);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const START_DATE = useSelector(state => state.honeymoon.startDate);
  const END_DATE = useSelector(state => state.honeymoon.endDate);
  const SEARCH_TERM = useSelector(state => state.honeymoon.searchTerm);
  const PAGE_NO = useSelector(state => state.honeymoon.pageNo);
  
  useEffect(() => {
    dispatch(HoneymoonAction.getHoneymoonRequested(1));
    dispatch(HoneymoonAction.getHoneymoonPlanning(1));
    dispatch(HoneymoonAction.getHoneymoonConfirmed(1));
    dispatch(HoneymoonAction.getHoneymoonCancelled(1));
  }, []);

  useEffect(() => {
    // if(status === "requested") {
    //   dispatch(familyFirstSearchRequested("", 'requested'));
    // }
    // else if(status === "planning") {
    //   dispatch(familyFirstSearchRequested("", 'planning'));
    // }
    // else if(status === "confirmed") {
    //   dispatch(familyFirstSearchRequested("", 'confirmed'));
    // }
    // else if(status === "cancelled") {
    //   dispatch(familyFirstSearchRequested("", 'cancelled'));
    // }
  }, [status]);

  const handleTabChange = (key) => {
    dispatch(HoneymoonAction.setSearchTerm(''))
    
    if(key === '2') {
      dispatch(HoneymoonAction.setPageNo(1))
      setStatus('planning')
      dispatch(HoneymoonAction.searchHoneymoonRequests('', START_DATE, END_DATE, "planning", 1));
    }
    else if(key === '3') {
      dispatch(HoneymoonAction.setPageNo(1))
      setStatus('confirmed')
      dispatch(HoneymoonAction.searchHoneymoonRequests('', START_DATE, END_DATE, "confirmed", 1));
    }
    else if(key === '4') {
      dispatch(HoneymoonAction.setPageNo(1))
      setStatus('cancelled')
      dispatch(HoneymoonAction.searchHoneymoonRequests('', START_DATE, END_DATE, "cancelled", 1));
    }
    else {
      dispatch(HoneymoonAction.setPageNo(1))
      setStatus('requested')
      dispatch(HoneymoonAction.searchHoneymoonRequests('', START_DATE, END_DATE, "requested", 1));
    }
  };
  
  const overallHoneymoonRequest = 
  totalRequestedResults+ 
  totalPlanningResults+ 
  totalConfirmedResults +
  totalCancelledResults; 

  

  const handleStartDateChange = (date) => {
    dispatch(HoneymoonAction.setStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(HoneymoonAction.searchHoneymoonRequests(SEARCH_TERM, moment(date).format("YYYY-MM-DD"), null, status, PAGE_NO));
  };

  const handleEndDateChange = (date) => {
    dispatch(HoneymoonAction.setEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(HoneymoonAction.searchHoneymoonRequests(SEARCH_TERM, START_DATE, moment(date).format("YYYY-MM-DD"), status, PAGE_NO));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(HoneymoonAction.setStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(HoneymoonAction.setEndDate(null));
    dispatch(HoneymoonAction.getHoneymoonRequested(1));
    dispatch(HoneymoonAction.getHoneymoonPlanning(1));
    dispatch(HoneymoonAction.getHoneymoonConfirmed(1));
    dispatch(HoneymoonAction.getHoneymoonCancelled(1));
  };

  return (
    <>
    <div style={{ position: "absolute", right: 50 }}>
      <Datefilter 
        status={status}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        ffStartDate={START_DATE}
        ffEndDate={END_DATE}
        handleStartClear={handleStartClear}
        handleEndClear={handleEndClear}
      />
    </div>
    <div className="enquiry-tabs-holder">
      <p className="title-holder">{overallHoneymoonRequest} Honeymoon Requests</p>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={`${totalRequestedResults} Requested`} key="1">
          <Requested status={status}/> 
        </TabPane>
        <TabPane tab={`${totalPlanningResults} Planning`} key="2">
          <Planning status={status}/> 
        </TabPane>
        <TabPane tab={`${totalConfirmedResults} Confirmed`} key="3">
          <Confirmed  status={status}/> 
        </TabPane>
        <TabPane tab={`${totalCancelledResults} Cancelled`} key="4">
          <Cancelled status={status}/> 
        </TabPane>
      </Tabs>
      {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      <div>
    </div>
    </div>
  </> 
  )
};

export default Index;