import React,{ useState, useEffect } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { holidaysRequestConfirmedStatusValues } from '../../../resources/status';
import { 
  closedHolidayList, 
  holidayRequestsSearch, 
  holidayRequestStatusUpdate, 
  getHolidayRequestList,
  confirmedHolidayList,  
  followUpHolidayList,
  holidayReqSearchValue,
  pageId
} from '../../../actions/holidayRequestAction';
import { useSelector, useDispatch } from 'react-redux';
import ContactInfo from './contactInfo';
import Pagination from '../../../common/pagination';
import moment from 'moment';
import {checkNumberOfGuest } from '../../../utils/index';

import { closeRequestInputVal } from '../../../actions/holidayRequestAction';
import ClosedModal from './closeRequestModal';
 
const Confirmed = () => {
    const [openContactInfoModal, setOpenContactInfoModal] = useState(false)
    const [contactInfoData, setContactInfoData] = useState({});
   const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const [ requestType, setRequestType ] = useState(null);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [ status, setStatus ] = useState('');
    const [ id, setId ] = useState(null);
    const [requestModal, setRequestModal] = useState(false);
    const confirmedHoliday = useSelector(state => state.holidayRequest.confirmedHolidayList);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    
    const totapPage = useSelector(state => state.holidayRequest.confirmedHolidayList.total_pages);
    const sectionName = useSelector(state => state.holidayRequest.holidayRequestSecName);
    const searchVal = useSelector(state => state.holidayRequest.holidayConfirmedSearchValue);
   
    const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
    const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
    const pageNum = useSelector(state => state.holidayRequest.page);
    const dispatch = useDispatch();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [openContactInfoModal]);

    useEffect(() => {
        if(sectionName.name === undefined && 
            searchVal === "" && ffStartDate === null &&  
            ffEndDate === null && confirmedHoliday?.current_page > 1 && 
            confirmedHoliday?.next_page_id === null && confirmedHoliday?.results.length === 0) {
            dispatch(closedHolidayList(1, sectionName?.id));
            dispatch(pageId(1))
        }else if(sectionName.name !== undefined && confirmedHoliday?.current_page > 1 && 
            confirmedHoliday?.next_page_id === null && 
            confirmedHoliday?.results.length === 0){
                dispatch(holidayRequestsSearch(1, "confirmed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
                dispatch(pageId(1));
            }else if(searchVal !== "" && confirmedHoliday?.current_page > 1 && 
                confirmedHoliday?.next_page_id === null && 
                confirmedHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "confirmed", searchVal ,ffStartDate, ffEndDate,sectionName.name ))
            }else if(ffStartDate !== null && ffEndDate !== null && confirmedHoliday?.current_page > 1 && 
                confirmedHoliday?.next_page_id === null && 
                confirmedHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "confirmed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }else if(sectionName.name !== undefined && 
                sectionName.name !== undefined && 
                ffStartDate !== null && ffEndDate !== null && 
                confirmedHoliday?.current_page > 1 && 
                confirmedHoliday?.next_page_id === null && 
                confirmedHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "confirmed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }
    }, [confirmedHoliday, pageNum])
    useEffect(() => {
       
        let value = [];
        confirmedHoliday?.results.map((elem) => {
          if (elem.price) {
            value.push(elem.price)
         
          }
        
        },);
        const valueSum=value.reduce((acc,elem)=>{
            return acc+elem
    
        },0)
    
            setAverageOrderValue(Math.round(valueSum/value?.length));
        setTotalOrderValue(valueSum)
      }, [confirmedHoliday?.results]);
    


    const moreInfoModalOpenHandler = (data) => {
        setContactInfoData(data)
        setOpenContactInfoModal(true);
    };

    const handlePagination = (e,value) => {
        dispatch(pageId(value))
        if(searchVal !== "" || sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(value, "confirmed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
        }else{
        dispatch(closedHolidayList(value, sectionName?.id));
        }
    };

    const handleChange = (event) => {
        dispatch(holidayRequestsSearch(1,'confirmed', event.target.value, ffStartDate, ffEndDate, sectionName.name))
        dispatch(holidayReqSearchValue(event.target.value, 'confirmed'))
    };
    
    const refreshData = () => {
        if(searchVal !== ""){
            dispatch(holidayRequestsSearch(pageNum, 'confirmed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(followUpHolidayList(pageNum));
        }else if( sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(pageNum, 'confirmed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'closed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'followup', searchVal, ffStartDate, ffEndDate, sectionName.name));
        }else{
            dispatch(getHolidayRequestList(pageNum));
            dispatch(followUpHolidayList(pageNum));
            dispatch(confirmedHolidayList(pageNum));
            dispatch(closedHolidayList(pageNum));
        }
    };
   
   
    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        
        setCloseRequestModalData(data)
        let status = event.target.value;
        
        setStatus(status);
        setId(id);
        setRequestType(requestsType)
        if(status === "closed")setRequestModal(true);
        else{
          dispatch(holidayRequestStatusUpdate(status, id, requestsType, null, refreshData))
        }
    };
    const closeRequestHandler = () => {
        setIsclosingMessageBool(false);
        if(closeRequestMessage === "")setIsclosingMessageBool(true);
        else {
          dispatch(holidayRequestStatusUpdate(status, id, requestType,closeRequestMessage, refreshData));
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
    };
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);

    }
    const displayConfirmedHolidayList = confirmedHoliday && confirmedHoliday?.results.map(data => (
        <AdminListing 
            locationsPreferred={data.locations_preferred}
            bookedOn={moment(data.created).format("Do MMMM, YYYY")}
            requestType={data.request_type}
            requestedBy={`Requested by ${data.contact_name}`}
            schedule={Array.isArray(data.schedule) ? data.schedule.map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.to} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            firstTimeTraveller={data.first_time_traveller === true? "First time traveller": ""}
            messageTitle={data.special_request}
            value={data.status}
            statusValues={holidaysRequestConfirmedStatusValues}
            isHolidayRequest={true}
            isConfirmed={true}
            data={data}
            id={data.id}
            visaRequestType={data.request_type}
            requestsType={data.request_type}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusChangeHandler={statusChangeHandler}
           contactInfoOnClick={() => moreInfoModalOpenHandler(data)}
           isShowMessage={true}
        />
    ));

    return (
        <>
        <SearchBar
           searchVal={searchVal} 
           handleChange={handleChange}
        />
         {confirmedHoliday && confirmedHoliday?.results.length === 0 && 
             <div className="enquiry-card-holder">No Record Found!</div>
         }
       <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
            {displayConfirmedHolidayList}
            {openContactInfoModal && 
                <ContactInfo 
                open={openContactInfoModal}
                modalClose={() => setOpenContactInfoModal(false)}
                contactInfoData={contactInfoData}
                isCancellationMessage={false}
                />
            }
            <Pagination 
                totalPage={totapPage} 
                page={pageNum} 
                onChange={(event, value) => handlePagination(event, value)}
            />
              {requestModal && 
               <ClosedModal 
                    open={requestModal}
                    handleClose={notToCancel}
                    data={closeRequestModalData}
                    isclosingMessage={isclosingMessageBool}
                    closeRequestHandler={closeRequestHandler}
               />
              }
        </>
    );
};

export default Confirmed;
