import { 
    HOLIDAY_REQUEST_LIST, 
    FOLLOW_UP_HOLIDAY_LIST, 
    CLOSE_HOLIDAY_LIST,
    CONFIRMED_HOLIDAY_LIST,
    HOLIDAY_REQUEST_END_DATE,
    HOLIDAY_REQUEST_START_DATE,
    HOLIDAY_REQUEST_PAGE_ID,
    HOLIDAY_REQUEST_STATUS_VALUE,
    HOLIDAY_REQUEST_SECTION_NAME,
    CLOSE_REQUEST_INPUT_MESSAGE,
    CONFIRM_REQUEST_INPUT_AMOUNT,
    HOLIDAY_REQUEST_ALL_DATA,
    HOLIDAY_REQ_SEARCH_VALUE,
    HOLIDAY_FOLLOWUP_SEARCH_VALUE,
    HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST,
    HOLIDAY_CLOSED_SEARCH_VALUE
} from '../actions/types'

const initialState = {
    holidayRequestList:[],
    holidayFollowUpList:[],
    confirmedHolidayList:[],
    closeHolidayList:[],
    holidayRequestStartDate: null,
    holidayRequestEndDate: null, 
    holidayRequestStatusVal:"request",
    holidayRequestSecName:{},
    closeRequestMsg:"",
    confirmedRequestAmount:"",
    page:1,
    getHolidayReqAllData:{},
    holidayReqSearchValue:"",
    holidayFollowupSearchValue:"",
    holidayConfirmedSearchValue:"",
    holidayClosedSearchValue:""
};

const holidayRequestReducer = (state = initialState, { type, payload }) => {
    switch (type) {
       case HOLIDAY_REQUEST_LIST:
           return{
               ...state,
               holidayRequestList:payload
           }
       case FOLLOW_UP_HOLIDAY_LIST:
           return{
               ...state,
               holidayFollowUpList:payload
           }
        case CONFIRMED_HOLIDAY_LIST :
           return{
               ...state,
               confirmedHolidayList:payload
           }
       case CLOSE_HOLIDAY_LIST:
           return{
               ...state,
               closeHolidayList:payload
           }
       case HOLIDAY_REQUEST_START_DATE:
           return{
               ...state,
               holidayRequestStartDate:payload
           }
       case HOLIDAY_REQUEST_END_DATE:
           return{
               ...state,
               holidayRequestEndDate:payload
           }
       case HOLIDAY_REQUEST_PAGE_ID:
           return{
               ...state,
               page:payload
           }
       case HOLIDAY_REQUEST_STATUS_VALUE:
           return{
               ...state,
               holidayRequestStatusVal:payload
           }
       case HOLIDAY_REQUEST_SECTION_NAME:
           return{
               ...state,
               holidayRequestSecName:payload
           }
       case CLOSE_REQUEST_INPUT_MESSAGE:
           return{
               ...state,
               closeRequestMsg:payload
           }
        case CONFIRM_REQUEST_INPUT_AMOUNT:

        return{
            ...state,
            confirmedRequestAmount:payload
        }


       case HOLIDAY_REQUEST_ALL_DATA:
           return{
               ...state,
               getHolidayReqAllData:payload
           }
       case HOLIDAY_REQ_SEARCH_VALUE:
           return{
               ...state,
               holidayReqSearchValue:payload
           }
       case HOLIDAY_FOLLOWUP_SEARCH_VALUE:
           return{
               ...state,
               holidayFollowupSearchValue:payload
           }
        case HOLIDAY_CONFIRMED_SEARCH_VALUE_REQUEST:
            return{
                ...state,
                holidayConfirmedSearchValue:payload

            }
       case HOLIDAY_CLOSED_SEARCH_VALUE:
           return{
               ...state,
               holidayClosedSearchValue:payload
           }
        default:
        return state;
    }
};

export default holidayRequestReducer;