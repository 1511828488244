import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getExperienceSection } from '../../../actions/experienceSectionAction';
import { getExperienceBookingList, bookingsSectionId , experienceSearchValue} from '../../../actions/experienceBookingAction';

const DropdownFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const experienceSectionLists = useSelector(state => state.experienceSection.getExperienceSection);
  const pageNum = useSelector(state => state.experienceBooking.page);
  const statusVal = useSelector(state => state.experienceBooking.experienceStatusVal);
  const dispatch = useDispatch();

//   let resultedLists = holidaySectionLists && holidaySectionLists.
//   filter(item => item.name !== "International" && item.name !== "Visas & More" && item.name !== "Customize" && item.name !== "Send a Gift");

  const handleClose = (item) => {
    setDropdownValue(item?.name);
    setAnchorEl(null);
    dispatch(getExperienceBookingList(pageNum, item?.id, statusVal));
    dispatch(bookingsSectionId(item?.id));
    dispatch(experienceSearchValue("", statusVal));
  };

  const handleEventClick = (event) => {
    dispatch(getExperienceSection());
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={handleEventClick} 
         className="dropdown-filter"
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {experienceSectionLists && experienceSectionLists.map(item => {
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  );
};

export default DropdownFilter;