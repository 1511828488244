import React,{ useEffect, useState } from 'react';
import MyFamilyFirstBooking from '../../../common/myFamilyFirstBookings';
// import SearchBar from '../../../common/searchBar';
import SearchBar from '../../../common/commonSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import MyFamilyFirstMoreInfoModal from './myFamilyFirstMoreInfoModal';
import { 
  familyFirstStatusUpdate,
  familyFirstStatusUpdateConfirm,
  myFamilyFirstRequested,
  myFamilyFirstPlanning,
  myFamilyFirstConfirmed,
  myFamilyFirstCancelled,
  familyFirstSearchRequested,
  pageId,
  setSearchTerm
} from '../../../actions/myFamilyFirstAction';
import Pagination from '../../../common/pagination';
import ConfirmModal from '../../wedding/requests/confirmModal'
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Requested = () => {

   const [ showMyFamilyFirstMoreInfoModal, setShowMyFamilyFirstMoreInfoModal ] = useState(false);
   const [ requestedMoreInfoModalData, setRequestedMoreInfoModalData ] = useState({});
   const [ page, setPage ] = useState(1);
   const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    const [requestModal, setRequestModal] = useState(false);
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    
    const [ id, setId ] = useState(null);
       const[statuschange,setStatuschange]=useState()

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
   const [ searchVal, setSearchVal ] = useState('');
   const myFamilyFirstRequestedData = useSelector(state => state.myFamilyFirst?.myFamilyFirstRequested);
   const totalPage = useSelector(state => state.myFamilyFirst?.requestedTotalPage);
   const dispatch = useDispatch();

   const START_DATE = useSelector(state => state.myFamilyFirst.familyFirstStartDate);
   const END_DATE = useSelector(state => state.myFamilyFirst.familyFirstEndDate);
   const SEARCH_TERM = useSelector(state => state.myFamilyFirst.searchTerm);
   const PAGE_NO = useSelector(state => state.myFamilyFirst.page);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMyFamilyFirstMoreInfoModal]);

    // useEffect(() => {
    //     if (myFamilyFirstRequestedData?.length === 0) {
    //         dispatch(myFamilyFirstRequested(1));
    //         dispatch(pageId(1));
    //         setPage(1);
    //     }
    // }, [myFamilyFirstRequestedData, page])
    
    const handleMoreInfoClick = (data) => {
        setShowMyFamilyFirstMoreInfoModal(true);
        setRequestedMoreInfoModalData(data);
    };

    const refreshFamilyFirstRequested = () => {
        // dispatch(myFamilyFirstRequested(page));
        // dispatch(myFamilyFirstPlanning(page));
        // dispatch(myFamilyFirstConfirmed(page));
        // dispatch(myFamilyFirstCancelled(page));
        dispatch(familyFirstSearchRequested(page, "", 'requested'));
        dispatch(familyFirstSearchRequested(page, "", 'planning'));
        dispatch(familyFirstSearchRequested(page, "", 'confirmed'));
        dispatch(familyFirstSearchRequested(page, "", 'cancelled'));
    };

    // const myFamilyFirstStatusChange = (event, id) => {
    //     let status = event.target.value;
    //     dispatch(familyFirstSearchRequested("", 'requested'));
    //     dispatch(familyFirstStatusUpdate(status, id, refreshFamilyFirstRequested));
    //     dispatch(pageId(page));
    // };
    

 


    const myFamilyFirstStatusChange = (event, id,data) => {
      setId(id);
      setCloseRequestModalData(data)
      setConfirmedRequestModalData(data)
        let status = event.target.value;
        setStatuschange(status)
  
        if(status === "confirmed")setRequestModalConfirm(true);
        else if(status === "cancelled")setRequestModal(true);
        else{
          dispatch(familyFirstSearchRequested("", 'requested'));
        dispatch(familyFirstStatusUpdate(status, id,null, refreshFamilyFirstRequested));
        dispatch(pageId(page));

        }
        
    };

    const confirmRequestHandler=()=>{
        
        
      setIsconfirmAmountBool(false)
     if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
      else {
      dispatch(familyFirstStatusUpdateConfirm("confirmed", id,confirmedRequestAmount, refreshFamilyFirstRequested))
        setRequestModalConfirm(false)
      }
      // dispatch(confirmRequestInputVal(''));
     
      
  
    }


    const notToConfirm=()=>{
   
      setRequestModalConfirm(false)
      setIsconfirmAmountBool(false);
    
    }
    const closeRequestHandler = () => {
 
      setIsclosingMessageBool(false)
      if(closeRequestMessage === "")setIsclosingMessageBool(true)
      else {
        dispatch(familyFirstStatusUpdate(statuschange, id,closeRequestMessage, refreshFamilyFirstRequested));
        setRequestModal(false)
      }
      dispatch(closeRequestInputVal(''));
    };
  
  const notToCancel=()=>{
      setRequestModal(false)
      setIsclosingMessageBool(false);
  
  }
 


    const handlePagination = (e,value) => {
        setPage(value);
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(familyFirstSearchRequested(value, SEARCH_TERM, "requested"));
        }else{
          dispatch(myFamilyFirstRequested(value));
        }
        
        dispatch(pageId(value));
    };

    
    const handleChange = (event) => {
      if(event.target.value){
        dispatch(familyFirstSearchRequested(PAGE_NO,event.target.value, "requested"));
      }else{
        dispatch(myFamilyFirstRequested(1));
        setPage(1);
      }
      dispatch(setSearchTerm(event.target.value))
      };

    const myFamilyFirstRequestedDisplayData = myFamilyFirstRequestedData && myFamilyFirstRequestedData.map((data) => (
        <MyFamilyFirstBooking 
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request? data.specific_request:"N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={myFamilyFirstStatusChange}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));

    return (
        <>
          {/* <SearchBar status="requested"/> */}
          <SearchBar
                searchVal={SEARCH_TERM}
                handleChange={handleChange}
            />
           
          {myFamilyFirstRequestedData && myFamilyFirstRequestedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
          {myFamilyFirstRequestedDisplayData}
          <MyFamilyFirstMoreInfoModal
            open={showMyFamilyFirstMoreInfoModal}
            close={() => setShowMyFamilyFirstMoreInfoModal(false)}
            modalData={requestedMoreInfoModalData}
            isCancellationMessage={false}
          />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
              {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }

{requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Requested;
