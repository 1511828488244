export const statusValues = [
  {
    name: "Booked",
    value: "booked",
  },
  {
    name: "Follow-Up",
    value: "follow_up",
  },
  {
    name: "Confirmed",
    value: "confirmed",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];
export const bookedstatusValues = [
  {
    name: "Booked",
    value: "booked",
  },
 
  {
    name: "Confirmed",
    value: "confirmed",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];

export const followUPstatusval = [
 
  {
    name: "Follow-Up",
    value: "follow_up",
  },
  {
    name: "Confirmed",
    value: "confirmed",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];
export const bookingConfirmAndCalcelledStatusValue = [

  {
    name: "Confirmed",
    value: "confirmed",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];

