import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import CropImageModal from "./CropImageModal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedCroppedFile,
  selectedImageUrl,
  handleAddConfirmBool,
  handleUpdateConfirmBool
} from "../../../actions/sectionAction";
import { handleEventAddConfirmBool, handleEventUpdateConfirmBool } from '../../../actions/eventSectionAction';
import { handleHolidayAddNewConfirmBool, handleHolidayUpdateConfirmBool } from '../../../actions/holidaySectionAction'
import { FaRegTimesCircle } from "react-icons/fa";
import PublishIcon from "@material-ui/icons/Publish";

export default function ImageCropper({
  imageArray,
  fileRef,
  onChange,
  inputOptions,
  cropOptions,
  outputOptions,
  displayOptions,
  previewOptions,
  buttonStyle,
  isUpdateImageSection,
  isAddImageSection,
  isEventsAddNewSection,
  isEventsUpdateSection,
  isHolidayAddNewSection,
  isHolidayUpdateSection,
  isUpdateSec,
  moduleType,
  sectionName,
  isAddImagePopular,
  altText,
  setAltText,
  imageTitle,
  setImageTitle,

}) {
  const [imageFile, setImageFile] = useState(null);
  const [cropState, setCropState] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [croppedFile, setCroppedFile] = useState(null);
  const confirmedCropState = useRef(cropState);
  const confirmedImageFile = useRef(imageFile);
 
  const dispatch = useDispatch();

  // maintain the state of fileRef. no need to care it in other places
  useEffect(() => {
    fileRef && (fileRef.current = croppedFile);
  }, [croppedFile, fileRef]);

  // notify cropped file changed
  useEffect(() => {
    onChange && onChange(croppedFile);
  }, [croppedFile]);

  function handleSelectImageFile(file) {
    if (file === confirmedImageFile.current) {
      // continue to edit
      setImageFile(confirmedImageFile.current);
      setCropState(confirmedCropState.current);
    } else {
      // edit new file
      setImageFile(file);
      setCropState(null);
    }
    setShowModal(true);
  }

  function handleConfirm(croppedFile) {
    setShowModal(false);

    // save confirmed state
    confirmedImageFile.current = imageFile;
    confirmedCropState.current = cropState;

    // setup cropped file
    setCroppedFile(croppedFile);
    dispatch(selectedCroppedFile(croppedFile));
    dispatch(selectedImageUrl(imageFile));
    dispatch(handleAddConfirmBool(isAddImageSection));
    dispatch(handleUpdateConfirmBool(isUpdateImageSection));
    dispatch(handleEventAddConfirmBool(isEventsAddNewSection));
    dispatch(handleEventUpdateConfirmBool(isEventsUpdateSection));
    dispatch(handleHolidayAddNewConfirmBool(isHolidayAddNewSection));
    dispatch(handleHolidayUpdateConfirmBool(isHolidayUpdateSection));
  }

  function handleCancel() {
    setShowModal(false);

    // restore state
    setCropState(confirmedCropState.current);
    setImageFile(confirmedImageFile.current);
  }

  function handleRemove() {
    setShowModal(false);

    // clear working state
    setCropState(null);
    setImageFile(null);

    // clear confirmed state
    confirmedImageFile.current = null;
    confirmedCropState.current = null;

    // removed cropped file
    setCroppedFile(null);
  }
  function onImageRemove() {
    handleRemove();
    dispatch(selectedCroppedFile(""));
    dispatch(selectedImageUrl(null));
    dispatch(handleUpdateConfirmBool(false));
    dispatch(handleAddConfirmBool(false));
    dispatch(handleEventAddConfirmBool(false));
    dispatch(handleEventUpdateConfirmBool(false));
  }

  return (
    <ControlledImageCropper
      imageArray={imageArray}
      imageFile={imageFile}
      onSelectImageFile={handleSelectImageFile}
      showModal={showModal}
      cropState={cropState}
      onChangeCropState={setCropState}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onRemove={handleRemove}
      inputOptions={inputOptions}
      cropOptions={cropOptions}
      outputOptions={outputOptions}
      displayOptions={displayOptions}
      changeCroppedFile={setCroppedFile}
      previewOptions={{ ...previewOptions, imageFile: croppedFile }}
      buttonStyle={buttonStyle}
      onImageRemove={onImageRemove}
      isUpdateSec={isUpdateSec}
      moduleType={moduleType}
      sectionName={sectionName}
      isAddImagePopular={isAddImagePopular}
      altText={altText}
      setAltText={setAltText}
      imageTitle={imageTitle}
      setImageTitle={setImageTitle}
    />
  );
}

export function ControlledImageCropper({
  imageArray,
  imageFile,
  onSelectImageFile,
  showModal,
  cropState,
  onChangeCropState,
  onConfirm,
  onCancel,
  onRemove,
  onImageRemove,
  inputOptions = {},
  cropOptions = {},
  outputOptions = {},
  displayOptions = {},
  previewOptions = {},
  isUpdateSec,
  changeCroppedFile,
  moduleType,
  sectionName,
  isAddImagePopular,
  altText,
  setAltText,
  imageTitle,
  setImageTitle,
}) {
  const fileInputRef = useRef();
  const selectedImageUrl = useSelector(
    (state) => state.section.selectedImageUrl
  );
  const holidaySectionImgUrl = useSelector(state => state.holidaySection.holidaySelectedImgUrl);
  function handleFileChange(e) {
    const file = e.target.files[0];
    console.log(file);
    console.log(file && file.type.split('/')[0])
    if (file && file.type.split('/')[0]==='image') {
      onSelectImageFile(file);
    }
    if (file && file.type.split('/')[0]==='video') {
      changeCroppedFile(file);
    }
  }

  function handleClick() {
    // if (imageFile) {
    //   onSelectImageFile(imageFile);
    // } else {
    //   fileInputRef.current.click();
    // }
    fileInputRef.current.click();
    setAltText("")
    setImageTitle("")
  }

  return (
    <React.Fragment>
      <input
        ref={fileInputRef}
        type="file"
        accept={moduleType==='event' || moduleType=== 'experience' || moduleType==='holidays' || (moduleType==='corporate'&&sectionName!=='Gift Voucher')?'image/*,video/*':'image/*'}
        value=""
        onChange={handleFileChange}
        hidden
      />
      {/* {imageArray < 5 ? */}
        <div
        className="add-image-cropper-btn"
          onClick={handleClick}
          style={{
            padding: `${isAddImagePopular?"6px 2px":"6px 10px"}`,
            border:`${isAddImagePopular?"":"1px solid white"}` ,
            borderRadius: 6,
            color: "#fff",
            backgroundColor: "rgba(0,0,0,0.6)",
            cursor: "pointer",
          }}
        >
          <PublishIcon fontSize="small" />
          {/* <h7 style={{ fontWeight: "bold", marginLeft: 4 }}>Add Image</h7> */}
          {/* <h7 style={{ fontWeight: "bold", marginLeft: 4 }}>{`${isUpdateSec ? "Update Image":  moduleType==='event' || moduleType=== 'experience' || (moduleType==='holidays' && sectionName!=='International' ) || (moduleType==='corporate'&&sectionName!=='Gift Voucher' )?"Add Image / Video":"Add Image"}`}</h7> */}
          <h7 style={{ fontWeight: "bold", marginLeft: 4 }}>{`${isUpdateSec ? "Update Image":  moduleType==='event' || moduleType=== 'experience' || (moduleType==='holidays' ) || (moduleType==='corporate'&&sectionName!=='Gift Voucher' )?"Add Image / Video":"Add Image"}`}</h7>
          
        </div>
      <CropImageModal
        show={showModal}
        imageFile={imageFile}
        value={cropState}
        onChange={onChangeCropState}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onRemove={onRemove}
        inputOptions={inputOptions}
        cropOptions={cropOptions}
        outputOptions={outputOptions}
        displayOptions={displayOptions}
        altText={altText}
        setAltText={setAltText}
        imageTitle={imageTitle}
        setImageTitle={setImageTitle}
      />
    </React.Fragment>
  );
}
