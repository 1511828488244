import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import * as EnquiryAction from "../../../actions/enquiryAction";
// import "../../../assets/css/partials/enquiry.scss";
import Datefilter from "./DateFilter";
import * as GiftVoucherAction from '../../../actions/giftVoucherActions';
import Purchased from "./purchased";
import Planning from "./planning"
import Cancelled from "./cancelled";
import Confirmed from "./confirmed";
import Pagination from '@material-ui/lab/Pagination';


const { TabPane } = Tabs;

function callback(key) {
    // console.log(key);
}
function mapStateToProps(state) {

    return {

        voucherDetails: state.voucherListing.voucherPurchased.data,
        planningDetails: state.voucherListing.planningQueries.data,
        confirmedDetails: state.voucherListing.confirmedQueries.data,
        cancelledDetails: state.voucherListing.cancelledQueries.data,
    };
}


function GiftVoucher({ voucherDetails, planningDetails, confirmedDetails, cancelledDetails }) {

    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GiftVoucherAction.getGiftVouchersList(page));
        dispatch(GiftVoucherAction.getPlanningVoucherlist(page));
        dispatch(GiftVoucherAction.getConfirmedVoucherlist(page));
        dispatch(GiftVoucherAction.getCancelledVoucherlist(page));
    }, [page]);

    useEffect(() => {
        dispatch(GiftVoucherAction.filterStartDate(null))
        dispatch(GiftVoucherAction.filterEndDate(null))
    }, [])

    const voucherPages = voucherDetails?.total_pages;
    const planningPages = planningDetails?.total_pages;
    const confirmedPages = confirmedDetails?.total_pages;
    const cancelledPages = cancelledDetails?.total_pages;

    const totalVouchers = voucherDetails?.total_results;
    const totalPlanningVouchers = planningDetails?.total_results;
    const totalConfirmedVouchers = confirmedDetails?.total_results;
    const totalCancelledVouchers = cancelledDetails?.total_results;

    const overallEnquiries =
        totalVouchers + totalPlanningVouchers + totalConfirmedVouchers + totalCancelledVouchers;

    return (
        <>
            {/* <div style={{ position: "absolute", right: 50 }}>
                <Datefilter />
            </div>

            <div className="enquiry-tabs-holder">
                <p className="title-holder"> {overallEnquiries} Gift Voucher Purchased</p>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab={totalVouchers + " " + "Purchased"} key="1">
                        <Purchased voucherData={voucherDetails} />
                        <Pagination
                            count={voucherPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </TabPane>
                    <TabPane tab={totalPlanningVouchers + " " + "Planning"} key="2">
                        <Planning planningData={planningDetails} />
                        <Pagination
                            count={planningPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </TabPane>
                    <TabPane tab={totalConfirmedVouchers + " " + "Confirmed"} key="3">
                        <Confirmed confirmedData={confirmedDetails} />
                        <Pagination
                            count={confirmedPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </TabPane>
                    <TabPane tab={totalCancelledVouchers + " " + "Cancelled"} key="4">
                        <Cancelled cancelledData={cancelledDetails} />
                        <Pagination
                            count={cancelledPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </TabPane>
                </Tabs>
            </div> */}
        </>
    );
}

export default connect(mapStateToProps)(GiftVoucher);
