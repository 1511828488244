import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { holidaysRequestStatusValues } from '../../../resources/status';
import moment from 'moment';
import ContactInfo from './contactInfo';
import Pagination from '../../../common/pagination';
import ConfirmRequestModal from './confirmRequestModal';
import { 
  closeRequestInputVal, 
  confirmRequestInputVal,
  getHolidayRequestList, 
  holidayRequestsSearch, 
  holidayRequestStatusUpdate,
  holidayRequestStatusUpdateConfirm, 
  followUpHolidayList,
  confirmedHolidayList, 
  closedHolidayList,
  holidayReqSearchValue,
  pageId 
} from '../../../actions/holidayRequestAction';
import {checkNumberOfGuest } from '../../../utils/index';
import ClosedModal from './closeRequestModal';

const Requests = () => {
  const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const[requestModalConfirm,setRequestModalConfirm]=useState(false)
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
  const [contactInfoData, setContactInfoData] = useState({});
  const [ status, setStatus ] = useState('');
  const [ id, setId ] = useState(null);
  const [ requestType, setRequestType ] = useState(null);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  
  const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})

  const [searchVal, setSearchVal] = useState('');
  const holidayrequest = useSelector(state => state.holidayRequest.holidayRequestList.results);
  const totalPage = useSelector(state => state.holidayRequest.holidayRequestList.total_pages);
  const sectionName = useSelector(state => state.holidayRequest.holidayRequestSecName);
  const closeRequestMessag = useSelector(state => state.holidayRequest);
  const confirm_message="as discussed on call for confirm"
  
 
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
  const confirmedRequestAmount=useSelector(state => state.holidayRequest.confirmedRequestAmount);
  
  const getHolidayReqAllData = useSelector(state => state.holidayRequest.holidayRequestList);
  const getHolidayReqSearchValue = useSelector(state => state.holidayRequest.holidayReqSearchValue);
  const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
  const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
  const pageNum = useSelector(state => state.holidayRequest.page);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    return ()=> document.body.style.overflow = 'unset';
  }, [openContactInfoModal]);

  useEffect(() => {
    if( sectionName.name === undefined && 
      getHolidayReqSearchValue === "" && ffStartDate === null &&  
      ffEndDate === null && getHolidayReqAllData?.data?.current_page > 1 && getHolidayReqAllData?.data?.next_page_id === null && holidayrequest?.length === 0) {
      dispatch(getHolidayRequestList(1, sectionName?.id));
      dispatch(pageId(1))
    }else if(sectionName.name !== undefined && getHolidayReqAllData?.current_page > 1 && 
      getHolidayReqAllData?.next_page_id === null && 
      getHolidayReqAllData?.results.length === 0){
          dispatch(holidayRequestsSearch(1, "request", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
          dispatch(pageId(1));
      }else if(getHolidayReqSearchValue !== "" && getHolidayReqAllData?.current_page > 1 && 
          getHolidayReqAllData?.next_page_id === null && 
          getHolidayReqAllData?.results.length === 0){
          dispatch(pageId(1));
          dispatch(holidayRequestsSearch(1, "request", getHolidayReqSearchValue ,ffStartDate, ffEndDate,sectionName.name ))
      }else if(ffStartDate !== null && ffEndDate !== null && getHolidayReqAllData?.current_page > 1 && 
          getHolidayReqAllData?.next_page_id === null && 
          getHolidayReqAllData?.results.length === 0){
          dispatch(pageId(1));
          dispatch(holidayRequestsSearch(1, "request", getHolidayReqSearchValue ,ffStartDate, ffEndDate,sectionName.name ));
      }else if(sectionName.name !== undefined && 
          sectionName.name !== undefined && 
          ffStartDate !== null && ffEndDate !== null && 
          getHolidayReqAllData?.current_page > 1 && 
          getHolidayReqAllData?.next_page_id === null && 
          getHolidayReqAllData?.results.length === 0){
          dispatch(pageId(1));
          dispatch(holidayRequestsSearch(1, "request", getHolidayReqSearchValue ,ffStartDate, ffEndDate,sectionName.name ));
      }
  }, [holidayrequest, pageNum])

  const moreInfoModalOpenHandler = (data) => {
    setContactInfoData(data);
    setOpenContactInfoModal(true);
  };

  const handlePagination = (e, value) => {
    if(getHolidayReqSearchValue !== "" || sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
      dispatch(holidayRequestsSearch(value, "request", getHolidayReqSearchValue ,ffStartDate, ffEndDate,sectionName.name ));
      dispatch(pageId(value))
    }else{
      dispatch(getHolidayRequestList(value, sectionName?.id));
      dispatch(pageId(value))
    }
  };
  
  const handleChange = (event) => {
    setSearchVal(event.target.value);
    dispatch(holidayRequestsSearch(pageNum, 'request', event.target.value, ffStartDate, ffEndDate, sectionName.name));
    dispatch(holidayReqSearchValue(event.target.value, "request"))
  };

  const refreshData = () => {
  
    if(getHolidayReqSearchValue !== ""){
      
      dispatch(holidayRequestsSearch(pageNum, 'request', getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      dispatch(followUpHolidayList(pageNum))
      dispatch(closedHolidayList(pageNum))
    }else if(sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
      
      dispatch(holidayRequestsSearch(pageNum, 'request', getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      dispatch(holidayRequestsSearch(pageNum, 'followup', getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      dispatch(holidayRequestsSearch(pageNum, 'confirmed', getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
      dispatch(holidayRequestsSearch(pageNum, 'closed', getHolidayReqSearchValue, ffStartDate, ffEndDate, sectionName.name));
    }else{
      
      dispatch(getHolidayRequestList(pageNum))
      dispatch(followUpHolidayList(pageNum))
      dispatch(confirmedHolidayList(pageNum))
      dispatch(closedHolidayList(pageNum))

    }
  };

  const statusChangeHandler = (event, id, corporate, requestsType, data) => {
   
    setCloseRequestModalData(data)
    setConfirmedRequestModalData(data)
    let status = event.target.value;
     setStatus(status);
    setId(id);
    setRequestType(requestsType)
    if(status === "closed")setRequestModal(true);
    else if(status==="confirmed")setRequestModalConfirm(true)
    else{
     
      dispatch(holidayRequestStatusUpdate(status, id, requestsType,null, refreshData))
    }
  };

  const closeRequestHandler = () => {
 
    setIsclosingMessageBool(false)
    if(closeRequestMessage === "")setIsclosingMessageBool(true)
    else {
      dispatch(holidayRequestStatusUpdate(status, id, requestType,closeRequestMessage, refreshData));
      setRequestModal(false)
    }
    dispatch(closeRequestInputVal(''));
  };

  const confirmRequestHandler=()=>{
    setIsconfirmAmountBool(false)
   if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
    else {
      dispatch(holidayRequestStatusUpdateConfirm(status, id, requestType,confirm_message,confirmedRequestAmount, refreshData));
      setRequestModalConfirm(false)
    }
    dispatch(confirmRequestInputVal(''));
   
    

  }
  const notToCancel=()=>{
    setRequestModal(false)
    setRequestModalConfirm(false)
    setIsclosingMessageBool(false);

}
const notToConfirm=()=>{
  setRequestModal(false)
  setRequestModalConfirm(false)
  setIsconfirmAmountBool(false);

}


  const displayHolidayRequestList = holidayrequest && holidayrequest.map(data => (
    <AdminListing
      locationsPreferred={data.request_type === "Visa" ? `Visa for ${data.locations_preferred}`:data.locations_preferred}
      bookedOn={moment(data.created).format("Do MMMM, YYYY")}
      requestType={data.request_type}
      requestedBy={`Requested by ${data.contact_name}`}
      schedule={Array.isArray(data.schedule) ? data.schedule.map((scheduleDate, index) => {
        if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
          return `${moment(scheduleDate).format(" MMM YYYY")}, `
        } else {
          return `${moment(scheduleDate).format("MMM YYYY")}, `
        }
      }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.to} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
      noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
      specialRequest={data.special_request ? data.special_request : "N/A"}
      messageTitle={data.special_request}
      firstTimeTraveller={data.first_time_traveller === true? "First time traveller": ""}
      statusValues={holidaysRequestStatusValues}
      value={data.status}
      id={data.id}
      data={data}
      visaRequestType={data.request_type}
      requestsType={data.request_type}
      refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
      contactInfoOnClick={() => moreInfoModalOpenHandler(data)}
      isHolidayRequest={true}
      statusChangeHandler={statusChangeHandler}
      isShowMessage={true}
    />
  ));
  return (
    <>
      <SearchBar
        searchVal={getHolidayReqSearchValue}
        handleChange={handleChange}
      />
       {holidayrequest && holidayrequest?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {displayHolidayRequestList}
      {openContactInfoModal &&
        <ContactInfo
          open={openContactInfoModal}
          modalClose={() => setOpenContactInfoModal(false)}
          contactInfoData={contactInfoData}
          isCancellationMessage={false}
        />
      }
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
      {requestModal && 
        <ClosedModal 
          open={requestModal}
          handleClose={notToCancel}
          data={closeRequestModalData}
          isclosingMessage={isclosingMessageBool}
          closeRequestHandler={closeRequestHandler}
        />
      }
      {requestModalConfirm &&
        <ConfirmRequestModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
       
    </>
  );
};

export default Requests;
