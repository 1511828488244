import React from 'react';
import { FaFileUpload } from "react-icons/fa";


const CommonAddNew = ({onClick,textTitle }) => {
    return (
        <div className="section-container">
            <h6>
            <span style={{ padding: "0px 5px" }}>
                <FaFileUpload />
            </span>
            <span onClick={onClick}>{textTitle}</span>
            </h6>
        </div>
    );
};

export default CommonAddNew;
