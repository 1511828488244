import React, {useRef, useState, useCallback, useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import JoditEditor from "jodit-react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as EventsAction from "../../actions/eventsAction";
import * as CorpListingAction from "../../actions/corpListingAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as experiencesListingAction from "../../actions/experienceListingAction";
import { useDispatch, useSelector } from "react-redux";
import { remove } from 'lodash';
import { ImCross } from "react-icons/im";

const TestimonialDialog = ({isDialogOpen, handleClose, type, module, id, editData,cid})=>{
    const dispatch = useDispatch();
    const [authorName, setAuthorName] = useState('');
    const [authorImage, setAuthorImage] = useState('');
    const [authorDesc, setAuthorDesc] = useState('');
    const [formError, setFormError] = useState({
        authorName: {msg:'', hasError:false},
        authorImage: {msg:'', hasError:false},
        authorDesc: {msg:'', hasError:false}
    });

    const fileRef = useRef();
    const [unCroppedImage, setUnCroppedImage] = useState(null);
    const [enableCropImage, setEnableCropImage] = useState(false);
    const [crop, setCrop] = useState({
        aspect: 1,
        unit: "%", width: 90
    });
    const [imgSrc, setImgSrc] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [croppedImageBlob, setCroppedImageBlob] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl]  = useState(null);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [croppedFile, setCroppedFile] = useState(null);
    const removeImage=()=>{
        setCroppedImageBlob(null)
        setCroppedFile(null)
        setCroppedImageUrl('')
    }
    useEffect(()=>{
        if(type==='edit'){
            setAuthorName(editData.guest_name);
            setCroppedImageUrl(editData.guest_image);
            setAuthorDesc(editData.message);
        }
    }, []);
    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
          return;
        }
    
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        // const scaleX = 52 //image.naturalWidth / image.width;
        // const scaleY = 52 //image.naturalHeight / image.height;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width =  crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        // canvas.width =  250;
        // canvas.height = 250;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
      }, [completedCrop]);
    const onLoad = useCallback((img) => {
        imgRef.current = img;
      }, []);

    const editor = useRef(null);
    const configJoditEditor = {
        readonly: false,
        // limitChars: 400,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        placeholder: "Description...",
    };
    const imageSelectedFun = (file)=>{
        setUnCroppedImage(file);
        setEnableCropImage(true);
    }

    const getImageUrl = (data)=>{
        setIsImageUploading(false);
        setCroppedImageUrl(data.url);
        const allPostData = {
            id,
            authorName,
            data,
            authorDesc
        }
        const postData= {
             id,
            "guest_name": authorName,
            "guest_image": data.url,
            "message": authorDesc
        }
        if(type==='add'){
            if(module === 'event'){
                dispatch(EventsAction.postNewTestimonial(postData, handleNewSuccess, handleNewError));
            }else if(module === 'corporate'){
                dispatch(CorpListingAction.corpPostNewTestimonial(allPostData, handleNewSuccess, handleNewError));
            }else if(module === 'holiday'){
                dispatch(HolidaysAction.holidayPostNewTestimonial(allPostData, handleNewSuccess, handleNewError));
            }else if(module === 'experience'){
                dispatch(experiencesListingAction.expPostNewTestimonial(allPostData, handleNewSuccess, handleNewError))
            }
        } else if (type==='edit'){
            if(module === 'event'){
                dispatch(EventsAction.updateTestimonial(postData, editData.tid, handleNewSuccess, handleNewError));
            }else if(module === 'corporate'){
                dispatch(CorpListingAction.updateCorpTestimonial(postData, editData.tid, handleNewSuccess, handleNewError));
            }else if(module === 'holiday'){
                dispatch(HolidaysAction.updateHolidayTestimonial(postData, editData.tid, handleNewSuccess, handleNewError))
            }else if(module === 'experience'){
                dispatch(experiencesListingAction.updateExpTestimonial(postData, editData.tid, handleNewSuccess, handleNewError))
            }
        }
    };
    const cropImage = ()=>{
        resetError('authorImage');
        if (!completedCrop || !previewCanvasRef.current) {
            return;
          }
          previewCanvasRef.current.toBlob(
          (blob) => {
            setCroppedImageBlob(blob);
            setEnableCropImage(false);
            setCroppedFile(new File([blob], unCroppedImage.name,{ type: "image/png" }))
            
            // console.log(file);
            // const previewUrl = window.URL.createObjectURL(blob);
            // console.log(blob)
      
            // const anchor = document.createElement('a');
            // anchor.download = 'cropPreview.png';
            // anchor.href = URL.createObjectURL(blob);
            // anchor.click();
      
            // window.URL.revokeObjectURL(previewUrl);
          },
          'image/png',
          1
        );
        // generateDownload(previewCanvasRef.current, completedCrop);
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            setImgSrc(reader.result)
          );
          reader.readAsDataURL(e.target.files[0]);
          imageSelectedFun(e.target.files[0]);
        }
      };
   
    // useEffect(() => {
    //     console.log(formError)
        
    // }, [formError])
    const submitTestimonial = ()=>{
        
       
        if(!authorName){
            setFormError({
                ...formError,
                authorName:{
                    hasError:true,
                    msg:'Please Enter Author Name'
                }
            });
            return;
        }
        // if(!croppedImageBlob && type!=='edit'){
        //     setFormError({
        //         ...formError,
        //         authorImage:{
        //             hasError:true,
        //             msg:'Please Select Author Image'
        //         }
        //     });
        //     return;
        // }
        if(!authorDesc){
            setFormError({
                ...formError,
                authorDesc:{
                    hasError:true,
                    msg:'Please Enter Description'
                }
            });
            return;
        }
        const htmltoText = (html) => {
            let text = html;
            text = text.replace(/\n/gi, "");
            text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
            text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
            text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
            text = text.replace(/<\/div>/gi, "");
            text = text.replace(/<\/li>/gi, "");
            text = text.replace(/<li.*?>/gi, "  *  ");
            text = text.replace(/<\/ul>/gi, "");
            text = text.replace(/<\/p>/gi, "");
            text = text.replace(/<br\s*[\/]?>/gi, "");
            text = text.replace(/<[^>]+>/gi, "");
            text = text.replace(/^\s*/gim, "");
            text = text.replace(/ ,/gi, "");
            text = text.replace(/ +/gi, "");
            text = text.replace(/\n+/gi, "");
            text = text.replace('&nbsp;', "");
            return text;
          };
        if(htmltoText(authorDesc).replaceAll(' ', '').trim().length>410){
            setFormError({
                ...formError,
                authorDesc:{
                    hasError:true,
                    msg:'Description should not exceed more than 400 Characters'
                }
            });
            return;
        }
        if(htmltoText(authorDesc).replaceAll(' ', '').trim().length===0){
            setFormError({
                ...formError,
                authorDesc:{
                    hasError:true,
                    msg:'Please Enter Description'
                }
            });
            return;
        }        

        if(croppedFile){
            setIsImageUploading(true);
            if(module === "event"){
                dispatch(EventsAction.imageUploadEvents(croppedFile, getImageUrl));
            }else if(module === "corporate"){
                dispatch(CorpListingAction.imageUpload(croppedFile, getImageUrl))
            }else if(module === 'holiday'){
                dispatch(HolidaysAction.imageUploadHolidays(croppedFile, getImageUrl))
            }else if(module === 'experience'){
                dispatch(experiencesListingAction.imageUpload(croppedFile, getImageUrl))
            }
        } else {
            const postData= {
                 id,
                "guest_name": authorName,
                "guest_image": croppedImageUrl,
                "message": authorDesc
            }
            let data=croppedImageUrl
            const allPostData = {
            id,
            authorName,
            data,
            authorDesc
        }
            if(type==='add'){
                if(module === 'event'){
                    dispatch(EventsAction.postNewTestimonial(postData, handleNewSuccess, handleNewError));
                }else if(module === 'corporate'){
                    dispatch(CorpListingAction.corpPostNewTestimonial(allPostData, handleNewSuccess, handleNewError));
                }else if(module === 'holiday'){
                    dispatch(HolidaysAction.holidayPostNewTestimonial(allPostData, handleNewSuccess, handleNewError));
                }else if(module === 'experience'){
                    dispatch(experiencesListingAction.expPostNewTestimonial(allPostData, handleNewSuccess, handleNewError))
                }
            } else if (type==='edit'){
                if(module === 'event'){
                    dispatch(EventsAction.updateTestimonial(postData, editData.tid, handleNewSuccess, handleNewError));
                }else if(module === 'corporate'){
                   dispatch(CorpListingAction.updateCorpTestimonial(postData, editData.tid, handleNewSuccess, handleNewError));
                }else if(module === 'holiday'){
                    dispatch(HolidaysAction.updateHolidayTestimonial(postData, editData.tid, handleNewSuccess, handleNewError))
                }else if(module === 'experience'){
                    dispatch(experiencesListingAction.updateExpTestimonial(postData, editData.tid, handleNewSuccess, handleNewError))
                }
            }
        }
        
        // handleClose();
    };

    const handleNewSuccess = ()=>{
        if(module==='event'){
            dispatch(EventsAction.getEventTestimonials(cid));
        } else if(module === 'corporate'){
            dispatch(CorpListingAction.getCorporateTestimonials(cid))
        }else if(module === 'holiday'){
            dispatch(HolidaysAction.getHolidayTestimonials(cid))
        }else if(module === 'experience'){
            dispatch(experiencesListingAction.getExpTestimonials(cid))

        }
        handleClose();
    };
    const handleNewError = ()=>{
        
    }
    const resetError = (field)=>{
        

        setFormError({
            ...formError,
            [field]:{
                msg:'',
                hasError:false
            }
        })
    };
    return (
        <div>
            <Dialog open={isDialogOpen} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title" >
                    {type==='add'?'Add new': 'Edit'}  testimonial
                    
                    <button type="button" onClick={handleClose} className="btn btn-link" style={{position:'absolute', right:'3%'}}>Close</button>
                </DialogTitle>
                <DialogContent>
                    {!enableCropImage?<div>
                    <div className="row">
                        <div className="col">

                            <div className="form-group form-custom" >
                                <label for="authorName">Author Name</label>
                                <input
                                    name="authorName"
                                    type="text"
                                    value={authorName}
                                    className="form-control"
                                    onChange={(e)=>{setAuthorName(e.target.value);resetError('authorName')}}
                                />
                                {formError.authorName.hasError && <p style={{ color: "red" }}>{formError.authorName.msg}</p>}
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group form-custom" >
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label for="userImage">Author Image</label>
                                    <label className={croppedImageBlob||croppedImageUrl?"d-block":'d-none'} onClick={removeImage} style={{cursor:'pointer'}}><ImCross /></label>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col pr-1" style={{flexGrow:0}}>
                                            {croppedImageBlob&&<img style={{width:36,height:36, borderRadius:'50%'}} src={URL.createObjectURL(croppedImageBlob)}  />}
                                            {!croppedImageBlob && type!=='edit'&&<AccountCircle style={{width:36,height:36,color:'#6c6b6b'}} />}

                                            {type === 'edit' && !croppedImageBlob && (croppedImageUrl !== '' ? <img style={{ width: 36, height: 36, borderRadius: '50%' }} src={croppedImageUrl} /> : <div style={{ width: 36, height: 36, borderRadius: '50%' }} className="nameChar">
                                                <p>{authorName !== undefined && authorName?.charAt(0).toUpperCase()}</p>
                                            </div>)}
                                        </div>
                                        <div className="col pl-0">
                                            <div className="custom-file">
                                                <input type="file" ref={fileRef} accept="image/*"  className="custom-file-input" id="inputGroupFile03"  onClick={(e)=>e.target.value=''} onChange={e=> onSelectFile(e)} />
                                                <label className="custom-file-label" for="customFile">{!croppedImageBlob && type!=='edit'&&`Choose file`} {!croppedImageBlob&&type==='edit'?croppedImageUrl?croppedImageUrl?.split('/')[croppedImageUrl?.split('/').length-1]:`Choose file`:null} {croppedImageBlob&&unCroppedImage?.name} </label>
                                                {formError.authorImage.hasError && <p style={{ color: "red" }}>{formError.authorImage.msg}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {isImageUploading&&<div style={{textAlign:'center', fontSize:'10px', color:'rgb(84 84 84)'}}>uploading Image....</div>}
                                </div>
                                
                        </div>
                        
                    </div>
                    <div className="row">
                    <div className="col">
                        <div className="form-group form-custom mt-3">
                            <label for="">Description </label>
                            
                            <JoditEditor
                                ref={editor}
                                value={authorDesc}
                                config={configJoditEditor}
                                tabIndex={1} // tabIndex of textarea
                                // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                // onChange={(newContent) => { }}
                                onBlur={(val)=>{setAuthorDesc(val);resetError('authorDesc')}}

                            />
                            {formError.authorDesc.hasError && <p style={{ color: "red" }}>{formError.authorDesc.msg}</p>}
                        </div>
                        </div>
                    </div>
                    </div>:
                    <div className="row">
                        <div className="col">
                            <div>Crop </div>
                            <ReactCrop src={imgSrc} crop={crop} circularCrop onChange={newCrop => setCrop(newCrop)} onComplete={(c) => setCompletedCrop(c)} onImageLoaded={onLoad} />
                        </div>
                        <div className="col" style={{flex:'0 0 70px'}}>
                        <div style={{textAlign:'center'}}>Preview</div>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                width: 100,
                                height: 100,
                                borderRadius:'50%'
                            }}
                            />
                        </div>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    
                        {!enableCropImage?<button onClick={submitTestimonial} disabled={isImageUploading} className="btn btn-primary btn-save mr-3">
                            Done 
                        </button>:
                        <span>
                            <button onClick={()=>{setEnableCropImage(false);setCroppedImageBlob(null)}}>Cancel</button>    
                            <button onClick={()=>cropImage()}>Crop</button>
                        </span>
                        }
                    
                    
                </DialogActions>
            </Dialog>
            
        </div>
    );
};

export default TestimonialDialog;