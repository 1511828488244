
import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut,
  axiosGetWithToken,
  axiosDelete
} from "../services/apiServices";
import { 
  MY_FAMILY_FIRST_REQUESTED, 
  MY_FAMILY_FIRST_PLANNING, 
  MY_FAMILY_FIRST_CONFIRMED, 
  MY_FAMILY_FIRST_CANCELLED,
  FAMILY_FIRST_START_DATE,
  FAMILY_FIRST_END_DATE,
  MY_FAMILY_FIRST_UPDATE_STATUS,
  REQUESTED_TOTAL_PAGE,
  PLANNING_TOTAL_PAGE,
  CONFIRMED_TOTAL_PAGE,
  CANCELLED_TOTAL_PAGE,
  REQUESTED_TOTAL_RESULTS,
  PLANNING_TOTAL_RESULTS,
  CONFIRMED_TOTAL_RESULTS,
  CANCELLED_TOTAL_RESULTS,
  FAMILY_FIRST_BOOKING_PAGE_ID,
  FAMILY_FIRST_STORY_LIST,
  FAMILY_FIRST_STORY_DETAILS,
  FAMILY_FIRST_STORY_DELETE,
  FAMILY_FIRST_STORY_UPDATE,
  FAMILY_FIRST_SEARCH_TERM,
  IS_LIST_DATA_LOADING,
  IS_DATA_LOADING
} 
from '../actions/types';

export const myFamilyFirstRequested = (page) => {
  console.log("page", page)
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":50
    };
    axiosPostWithToken(`${API.MY_FAMILY_FIRST_REQUESTED}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: MY_FAMILY_FIRST_REQUESTED,
            payload: res.data.data.results,
          });
        }
        dispatch({
          type:REQUESTED_TOTAL_PAGE,
          payload:res.data.data.total_pages
        })
        dispatch({
          type:REQUESTED_TOTAL_RESULTS,
          payload:res.data.data.total_results
        })
      })
  };
};

export const myFamilyFirstStoryList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.MY_FAMILY_FIRST_STORY_LIST}`, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: FAMILY_FIRST_STORY_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const searchStory = (searchVal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    let body = {
      "search_term": searchVal
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.MY_FAMILY_FIRST_STORY_SEARCH}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: FAMILY_FIRST_STORY_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};
// newStory

export const newStory = (formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPostWithToken(`${API.MY_FAMILY_FIRST_STORY_ADD}`, formData,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};

export const deleteStory = (storyId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosDelete(`${API.MY_FAMILY_FIRST_STORY_DELETE}${storyId}`,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};
// deleteStory

export const myFamilyFirstStoryDetails = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.MY_FAMILY_FIRST_STORY_DETAILS}${id}`, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: FAMILY_FIRST_STORY_DETAILS,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const updateStory = (id, formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPut(`${API.MY_FAMILY_FIRST_STORY_UPDATE}${id}/`, formData, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          handleSuccess(id);
        }
      })
  };
};

export const deleteListingStoryEditImg = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.MY_FAMILY_FIRST_DELETE_UPDATE_IMAGE}${id}/`, token)
      .then((res) => {
        //   if (res.data.status_code === 200) {
        //     dispatch({
        //       type: EVENT_LIST,
        //     });
        // }
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};


export const myFamilyFirstPlanning = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":50
    };
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.MY_FAMILY_FIRST_PLANNING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: MY_FAMILY_FIRST_PLANNING,
            payload: res.data.data.results,
          });

          dispatch({
            type:PLANNING_TOTAL_PAGE,
            payload:res.data.data.total_pages
          })
          dispatch({
            type:PLANNING_TOTAL_RESULTS,
            payload:res.data.data.total_results
          })
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
        
      })
  };
};

export const myFamilyFirstConfirmed = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "next_page_id": page,
    "page_size":50
    
  };
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.MY_FAMILY_FIRST_CONFIRMED}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
        type: MY_FAMILY_FIRST_CONFIRMED,
        payload: res.data.data.results,
        });

        dispatch({
          type:CONFIRMED_TOTAL_PAGE,
          payload:res.data.data.total_pages
        })
        dispatch({
          type:CONFIRMED_TOTAL_RESULTS,
          payload:res.data.data.total_results
        })
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
      })
  };
};

export const myFamilyFirstCancelled = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "next_page_id": page,
    "page_size":50
  };
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.MY_FAMILY_FIRST_CANCELLED}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: MY_FAMILY_FIRST_CANCELLED,
          payload: res.data.data.results,
          });

          dispatch({
            type:CANCELLED_TOTAL_PAGE,
            payload:res.data.data.total_pages
          })
          dispatch({
            type:CANCELLED_TOTAL_RESULTS,
            payload:res.data.data.total_results
          })
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const familyFirstSearchRequested = (page, input, status) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const startDate = getState().myFamilyFirst.familyFirstStartDate;
  const endDate = getState().myFamilyFirst.familyFirstEndDate;
  const body = {
    search_term: input,
    status: status,
    from: startDate,
    to: endDate,
    next_page_id: page,
    "page_size":50
  };
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.MY_FAMILY_FIRST_SEARCH_REQUESTED}`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        if(status === "requested"){
          dispatch({
            type: MY_FAMILY_FIRST_REQUESTED,
            payload: res.data.data.results,
          });
          dispatch({
            type:REQUESTED_TOTAL_PAGE,
            payload:res.data.data.total_pages
          });
          dispatch({
            type:REQUESTED_TOTAL_RESULTS,
            payload:res.data.data.total_results
          });
        }

        else if(status === "planning"){
          dispatch({
            type: MY_FAMILY_FIRST_PLANNING,
            payload: res.data.data.results,
          });
          dispatch({
            type:PLANNING_TOTAL_PAGE,
            payload:res.data.data.total_pages
          });
          dispatch({
            type:PLANNING_TOTAL_RESULTS,
            payload:res.data.data.total_results
          });
        }

        else if(status === "confirmed"){
          dispatch({
            type: MY_FAMILY_FIRST_CONFIRMED,
            payload: res.data.data.results,
          });
          dispatch({
            type:CONFIRMED_TOTAL_PAGE,
            payload:res.data.data.total_pages
          });
          dispatch({
            type:CONFIRMED_TOTAL_RESULTS,
            payload:res.data.data.total_results
          });
        }

        else if(status === "cancelled"){
          dispatch({
            type: MY_FAMILY_FIRST_CANCELLED,
            payload: res.data.data.results,
          });
          dispatch({
            type:CANCELLED_TOTAL_PAGE,
            payload:res.data.data.total_pages
          });
          dispatch({
            type:CANCELLED_TOTAL_RESULTS,
            payload:res.data.data.total_results
          });
        }
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
      }
    })
  };
};

export const familyFirstStatusUpdate = (status, id, cancelling_message,refreshFamilyFirstRequested) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status":status,
    cancelling_message:cancelling_message
  };
  axiosPut(`${API.MY_FAMILY_FIRST_STATUS_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: MY_FAMILY_FIRST_UPDATE_STATUS,
          payload: res.data.data.results,
          });
        }
        refreshFamilyFirstRequested()
      })
  };
};

export const familyFirstStatusUpdateConfirm = (status, id, amount,refreshFamilyFirstRequested) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status":status,
    "price":amount
  };
  axiosPut(`${API.MY_FAMILY_FIRST_STATUS_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: MY_FAMILY_FIRST_UPDATE_STATUS,
          payload: res.data.data.results,
          });
        }
        refreshFamilyFirstRequested()
      })
  };
};

export const familyFirstStartDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FAMILY_FIRST_START_DATE,
      payload: date,
    });
  };
};

export const familyFirstEndDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FAMILY_FIRST_END_DATE,
      payload: date,
    });
  };
};

export const pageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type:FAMILY_FIRST_BOOKING_PAGE_ID,
      payload: page,
    })
  
  };
};

export const setSearchTerm = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FAMILY_FIRST_SEARCH_TERM,
      payload: value
    })
  }
}