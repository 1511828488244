import {
    ADD_POPULAR_DESTINATION,
    GET_MEDIA_URL,
    EMPTY_URL,
    GET_POPULAR_DESTIONATIONS,
    STATES_COUNTRYS_LISTS
} from "../actions/popularDestinationAction";
  
    const initialState = {
   
    popularDestinations:false,
    mediaUrl:"",
    popularDestiantionsList:[],
    stateAndCountryLists:[]

    };
  
    const populatDestinationsReducer = (state = initialState, { type, payload }) => {
      switch (type) {
        case ADD_POPULAR_DESTINATION : {
          return {
            ...state,
            addPopularDestination: payload,
          };
        }

        case GET_MEDIA_URL : {
            return {
              ...state,
              mediaURL: payload,
            };
          }
          case STATES_COUNTRYS_LISTS : {
            return {
              ...state,
              stateAndCountryLists: payload,
            };
          }

          case EMPTY_URL: {
            return {
              ...state,
              mediaURL: "",
            };
          }

          case GET_POPULAR_DESTIONATIONS: {
            return {
              ...state,
              popularDestiantionsList: payload,
            };
          }
    
        default:
          return state; 
      }
    };
  
      export default populatDestinationsReducer