import React,{ useState } from 'react';
import DatePicker, { Calendar } from "react-multi-date-picker";
import '../../assets/css/partials/availabilityFlexiDatePopup.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { availabilityRecurringDate, RecurringModalOpenTrue } from '../../actions/experienceBookingAction';
import { useDispatch } from 'react-redux';
import { setDate } from 'date-fns';

const AvailabilityRecurring = ({ donedHandler, recurringAvailabilityData}) => {
    const recurringDate = useSelector(state => state.experienceBooking.recurringDate);
    const [ dateBool, setDateBool ] = useState(false)
    const dispatch = useDispatch();
    const [values, setValues] = useState([
        // moment(recurringAvailabilityData.schedule.from, 'DD-MM-YYYY')._d,
        // moment(recurringAvailabilityData.schedule.to, 'DD-MM-YYYY')._d
    ]);
    const [ value, setValue ] = useState({
        from:recurringAvailabilityData.schedule.from,
       to:recurringAvailabilityData.schedule.to
    })
    const handleDateChange = (date) => {
        setDateBool(true)
        dispatch(availabilityRecurringDate(date));
        setValue(date.format())
        setValue({
            from:date.format(),
            to:""
        })
       
        dispatch(RecurringModalOpenTrue(true));
    };
    return (
        <div className="calander-container">
         <Calendar
            mapDays={({ date }) => {
                let isWeekend = recurringAvailabilityData?.pack_schedule_data?.newSelectedDyas?.includes(date.weekDay.index)
                if (!isWeekend) return {
                    disabled: true,
                    style: { color: "#ccc" },
                }
            }}
            minDate={new Date()}
            // value={values} 
            onChange={date => handleDateChange(date)}
            format={'DD-MM-YYYY'}

         />
         <div style={{textAlign:"end", marginBottom:"25px"}}>
            <button onClick={() => donedHandler(value)}>Done</button>
         </div>
        </div>
    )
}

export default AvailabilityRecurring
