import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { Row, Col } from 'antd';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY  
const GOOGLE_API_KEY_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();
console.log(API_KEY);
class LocationFromGoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: (this.props.isEdit && this.props?.editLocation[0]?.address) ? this.props?.editLocation[0]?.address : '',
            city: (this.props.isEdit && this.props?.editLocation[0]?.city) ? this.props?.editLocation[0]?.city : '',
            state: (this.props.isEdit && this.props?.editLocation[0]?.state) ? this.props?.editLocation[0]?.state : '',
            country: (this.props.isEdit && this.props?.editLocation[0]?.country) ? this.props?.editLocation[0]?.country : '',
            zoom: 15,
            height: 400,
            mapPosition: {
                lat: (this.props.isEdit && parseFloat(this.props?.editLocation[0]?.latitude)) ? parseFloat(this.props?.editLocation[0]?.latitude) : 12.9697315,
                lng: (this.props.isEdit && parseFloat(this.props?.editLocation[0]?.longitude)) ? parseFloat(this.props?.editLocation[0]?.longitude) : 77.6364809,
            },
            markerPosition: {
                lat: (this.props.isEdit && parseFloat(this.props?.editLocation[0]?.latitude)) ? parseFloat(this.props?.editLocation[0]?.latitude) : 12.9697315,
                lng: (this.props.isEdit && parseFloat(this.props?.editLocation[0]?.longitude)) ? parseFloat(this.props?.editLocation[0]?.longitude) : 77.6364809,
            }
        }
    }

    handleClose = () => {
        this.props.mapOpen(false);
    };


    // to get the city 
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    // to get the state 
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    // to get the country 
    getCountry = (addressArray) => {
        let country = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                    country = addressArray[i].long_name;
                    return country;
                }
            }
        }
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onInfoWindowClose = (event) => { };

    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    state = this.getState(addressArray),
                    country = this.getCountry(addressArray);
                this.setState({
                    address: (address) ? address : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                })
            },
            error => {
                console.error(error);
            }
        );
    };

    onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            state = this.getState(addressArray),
            country = this.getCountry(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
        this.setState({
            address: (address) ? address : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            country: (country) ? country : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
    };


    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap
                        defaultZoom={this.state.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }} >

                        {/*Marker*/}
                        <Marker
                            google={this.props.google}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                        />
                        <InfoWindow
                            onClose={this.onInfoWindowClose}
                            position={{ lat: (this.state.markerPosition.lat + 0.0018), lng: this.state.markerPosition.lng }}
                        >
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                            </div>
                        </InfoWindow>
                        <Marker />


                        {/* For Auto complete Search Box */}
                        <div className="autocomplete-input">
                            <Autocomplete
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    paddingLeft: '16px',
                                    marginTop: '2px',
                                    marginBottom: '2rem',
                                }}
                                onPlaceSelected={this.onPlaceSelected}
                                types={[]}
                            />
                        </div>
                    </GoogleMap>
                )
            )
        );

        return (
            <div style={{
                padding: '1rem', margin: '0 auto', maxWidth: 1000,
            }}>

                <Row>
                    <Col span={16}>
                        <AsyncMap
                            googleMapURL= {GOOGLE_API_KEY_URL}
                            loadingElement={
                                <div className="test" style={{ height: `100%` }} />
                            }
                            containerElement={
                                <div className="test1" style={{ height: this.state.height }} />
                            }
                            mapElement={
                                <div className="test2" style={{ height: `100%` }} />
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <div className="location-details">
                            {
                                this.state.address ? <div className="address-holder" >
                                    <p>{this.state.address}</p>
                                </div> : null
                            }
                            {
                                this.props.isEdit ? 
                                <button className="map-btn" onClick={() => { this.props.editMap(this.state.address, this.state.mapPosition.lat, this.state.mapPosition.lng, this.state.city, this.state.state, this.state.country); this.props.closeMap(); }}> Update </button> : 
                                <button className="map-btn" onClick={() => { this.props.getLocation(this.state.address, this.state.mapPosition.lat, this.state.mapPosition.lng, this.state.city, this.state.state, this.state.country); this.props.closeMap(); }}> Done </button>
                            }
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }

}

export default LocationFromGoogleMap;
