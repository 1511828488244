import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as GiftVoucherAction from "../../../actions/giftVoucherActions";

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: "16px",
        backgroundColor: "#fff",
        padding: 6,
        borderRadius: 3,
    },
}));

const SearchBar = ({ status }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const startDate = useSelector(
        (state) => state.voucherListing.filterStartDate
    );
    const endDate = useSelector((state) => state.voucherListing.filterEndDate);

    useEffect(() => {
        // console.log("I am called");
        // console.log(status, "I am called");
        dispatch(GiftVoucherAction.searchVoucher("", status));

    }, [startDate, endDate]);

    const handleChange = (value) => {
        search(value);
    };

    const sendQuery = async (value) => {
        dispatch(GiftVoucherAction.searchVoucher(value.target.value, status));
        // console.log(status)
    };

    const search = useCallback(_.debounce(sendQuery, 500), []);

    return (
        <TextField
            className={classes.input}
            defaultValue={""}
            size="small"
            placeholder={"Title, Email, Mobile"}
            fullWidth={true}
            onChange={handleChange}
            InputProps={{ disableUnderline: true }}
        />
    );
};

export default SearchBar;
