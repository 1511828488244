import React,{ useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar'
import { experiencesConcelledStatusValues } from '../../../resources/status';
import { experienceSearchValue, pageId, getExperienceBookingList, experienceBookingsSearch, experienceBookingStatusUpdate } from '../../../actions/experienceBookingAction';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index';


const Cancelled = () => {

    const [ showMoreInfo, setShowMoreInfo ] = useState(false);
    const [ moreInfoData, setMoreInfoData ] = useState({});
    const[averageBookingAmount,setAverageBookingAmount]=useState()
    const[totalbookingAmount,setTotalbookingAmount]=useState()
    // const [ searchVal, setSearchVal ] = useState('');
    const searchVal = useSelector((state) => state.experienceBooking.experiencelCancelledSearchVal);
    const [ pageVal, setPageVal ] = useState(1);
    const ffStartDate = useSelector((state) => state.experienceBooking.experienceBookingStartDate);
    const ffEndDate = useSelector((state) => state.experienceBooking.experienceBookingEndDate);
    const experienceCancelled = useSelector(state => state.experienceBooking.experienceCancelled.results);
    console.log(experienceCancelled);
    const totapPage = useSelector(state => state.experienceBooking.experienceCancelled.total_pages);
    const pageNum = useSelector(state => state.experienceBooking.pageNum);
    const sectionID = useSelector(state => state.experienceBooking.experienceBookingsSecId);
    const experienceCancelledNxtPgeId = useSelector(state => state.experienceBooking.experienceCancelledNxtPgeId);
    const experienceCancelledCurrentPgeId = useSelector(state => state.experienceBooking.experienceCancelledCurrentPgeId);

    const dispatch = useDispatch();
    useEffect(()=>{
        const data=experienceCancelled?.map((elem)=>{
            return (parseInt(elem.amount_paid==0?elem.amount==null?0:elem.amount:elem.amount_paid))
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(Math.round(totalamount/experienceCancelled?.length))

    },[experienceCancelled])
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMoreInfo]);

    useEffect(() => {
        if (experienceCancelledNxtPgeId === null && experienceCancelledCurrentPgeId > 1 && experienceCancelled?.length === 0) {
            dispatch(getExperienceBookingList(1, null, "cancelled"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [experienceCancelled, pageVal])

    const handlePagination = (e, value) => {
        if(searchVal !== "" || ffStartDate !== null && ffEndDate !== null){
            dispatch(experienceBookingsSearch(value, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        }else{
            dispatch(getExperienceBookingList(value, sectionID, "cancelled"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };

    const handleChange = (event) => {
        let input = event.target.value
        // setSearchVal(event.target.value);
        dispatch(experienceBookingsSearch(pageNum, 'cancelled', event.target.value, sectionID, ffStartDate, ffEndDate));
        dispatch(experienceSearchValue(input, "cancelled"));
        dispatch(pageId(1));
    };

    // const refreshExperienceBooking = () => {
    //     if(searchVal !== '') {
    //         dispatch(experienceBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
    //         dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"))
    //     } else if(ffStartDate !== null && ffEndDate !== null){
    //         dispatch(experienceBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
    //         dispatch(experienceBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
    //     } else {
    //         dispatch(getExperienceBookingList(pageNum, sectionID, "booked"))
    //         dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"))
    //         dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"))
    //     }
    // };

    // const statusChangeHandler = (event, id, corporate) => {
    //     let status = event.target.value;
    //     dispatch(experienceBookingStatusUpdate(status, id, corporate,sectionID, refreshExperienceBooking))
    //     // dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"));
    // };

    const experienceBookingDisplayData = experienceCancelled && experienceCancelled.map(data => (
        <AdminListing 
        packTitle={data.pack_title}
        address={data.address}
        amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
        schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
            if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
              return scheduleDate
            } else {
              return `${scheduleDate}, `
            }
          }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.from} ${data.schedule.to==false?"":"to" } ${data.schedule.to==false?"": data.schedule.to}` : (data.schedule ===  null ? "N/A" : data.schedule))}
        noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
        messageTitle={data.special_request ? data.special_request : 'No Message'}
        specialRequest={data.special_request?data.special_request:"N/A"}
        isExperienceBooking={true}
        value={data.booking_status}
        data={data}
        id={data.id}
        refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
        corporate={data.corporate}
        statusValues={experiencesConcelledStatusValues}
        onClick={() => moreInfoHandler(data)}
        isCancelled={true}
        voucher_info={data?.voucher_info}
        amount_data={data?.amount}

        />
    ));

    return (
        <>
        <SearchBar
            searchVal={searchVal}
            handleChange={handleChange}
        />

      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
        {experienceBookingDisplayData}
        <Pagination 
            totalPage={totapPage} 
            page={pageNum} 
            onChange={(event, value) => handlePagination(event, value)}
        />
        {showMoreInfo && 
            <CommonMoreInfoModel 
              open={showMoreInfo}
              modalClose={() => setShowMoreInfo(false)}
              moreInfoData={moreInfoData}
              isCancellationMessage={true}
            />
          }
        </>
    );
};

export default Cancelled;
