import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  myExperienceBookingStartDate,
  myExperienceBookingEndDate,
  experienceBookingsSearch,
  getExperienceBookingList,
  getExperiencesStatus,
  experienceSearchValue,
  pageId
} from '../../../actions/experienceBookingAction.js';
import { Tabs } from "antd";
import Bookings from './bookings';
import Confirmed from './confirmed';
import Cancelled from './canceled';
import Availability from './availability';
import FollowUp from './followUp.js'
import Datefilter from '../../../common/commonDateFIlter'
import Refunded from "./refunded.js"
import DropdownFilter from "./dropDownFilter";
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
const { TabPane } = Tabs;

const ExperienceBookingIndex = () => {
  const [status, setStatus] = useState("requested");
  const ffStartDate = useSelector((state) => state.experienceBooking.experienceBookingStartDate);
  const ffEndDate = useSelector((state) => state.experienceBooking.experienceBookingEndDate);
  const pageNum = useSelector(state => state.experienceBooking.page);
  const bookLength = useSelector(state => state.experienceBooking.experienceBookingDataLength);

  const refundlength = useSelector(state => state.experienceBooking.experienceRefundDataLength);

  const confirmLength = useSelector(state => state.experienceBooking.experienceConfirmedDataLength);
  const cancelledLength = useSelector(state => state.experienceBooking.experienceCancelledDataLength);
  const sectionID = useSelector(state => state.experienceBooking.experienceBookingsSecId);
  const getAvailabilityTotalResults = useSelector(state => state.experienceBooking.experienceAvailabilityList.total_results);
  const searchValBooked = useSelector((state) => state.experienceBooking.experiencelBookedSearchVal);
  const searchValConfirmed = useSelector((state) => state.experienceBooking.experiencelConfirmedSearchVal);
  const searchValCancelled = useSelector((state) => state.experienceBooking.experiencelCancelledSearchVal);
  const searchValAvailability= useSelector((state) => state.experienceBooking.experiencelAvailabilitySearchVal);
  const getAvailabilityList = useSelector(state => state.experienceBooking.experienceAvailabilityList);
  const getFollowuptotalResult = useSelector((state) => state.experienceBooking.experienceFollowup?.total_results);
  const getFollowup = useSelector((state) => state.experienceBooking.experienceFollowup);
  console.log(getFollowup);


  const isDataLoading = useSelector(
    (state) => state.experiencesListing.isDataLoading
  );
  const dispatch = useDispatch();
  const totalExperienceBookings = bookLength + confirmLength + cancelledLength + getAvailabilityList.total_results;
  
  useEffect(() => {
    if (sectionID === undefined) {
      dispatch(getExperienceBookingList(pageNum, null, "booked"));
      dispatch(getExperienceBookingList(pageNum, null, "confirmed"));
      dispatch(getExperienceBookingList(pageNum, null, "cancelled"))
      dispatch(getExperienceBookingList(pageNum, null, "requested"))
      dispatch(getExperienceBookingList(pageNum, null, "follow_up"))
      dispatch(getExperienceBookingList(pageNum, null, "refunded"))
    };
  }, [sectionID])
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  useEffect(() => {
    dispatch(getExperienceBookingList(pageNum, null, "requested"));
    dispatch(getExperienceBookingList(pageNum, null, "booked"));
    dispatch(getExperienceBookingList(pageNum, null, "confirmed"));
    dispatch(getExperienceBookingList(pageNum, null, "cancelled"));
    dispatch(getExperienceBookingList(pageNum, null, "follow_up"));
    dispatch(getExperienceBookingList(pageNum, null, "refunded"));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(myExperienceBookingStartDate(null));
      dispatch(myExperienceBookingEndDate(null))
    }
  }, []);


  const handleTabChange = (key) => {
    console.log("key", key)
    if (key === '2') {
      setStatus('confirmed');
      dispatch(pageId(1));
      dispatch(getExperiencesStatus("confirmed"));
      if (sectionID !== null && searchValConfirmed===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'confirmed'));
      else if (ffStartDate !== null || ffEndDate !== null) {
        dispatch(experienceBookingsSearch(pageNum, "confirmed", searchValConfirmed, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "confirmed",searchValConfirmed,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getExperienceBookingList(1, sectionID, 'confirmed'))
      }
    }
    else if (key === '5') {
      setStatus('follow_up');
      dispatch(pageId(1));
      dispatch(getExperiencesStatus("follow_up"));
      if (sectionID !== null && searchValCancelled===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'follow_up'));
      else if (ffStartDate !== null || ffEndDate !== null) {
        dispatch(experienceBookingsSearch(pageNum, "follow_up", searchValCancelled, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "follow_up",searchValCancelled,sectionID, ffStartDate, ffEndDate));
      }
      else{
        dispatch(getExperienceBookingList(1, sectionID, 'follow_up'))
      }
    }
    else if (key === '6') {
      setStatus('refunded');
      dispatch(pageId(1));
      dispatch(getExperiencesStatus("refunded"));
      if (sectionID !== null && searchValCancelled===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'refunded'));
      else if (ffStartDate !== null || ffEndDate !== null) {
        dispatch(experienceBookingsSearch(pageNum, "refunded", searchValCancelled, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "refunded",searchValCancelled,sectionID, ffStartDate, ffEndDate));
      }
      else{
        dispatch(getExperienceBookingList(1, sectionID, 'refunded'))
      }
    }
    else if (key === '3') {
      setStatus('cancelled');
      dispatch(pageId(1));
      dispatch(getExperiencesStatus("cancelled"));
      if (sectionID !== null && searchValCancelled===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'cancelled'));
      else if (ffStartDate !== null || ffEndDate !== null) {
        dispatch(experienceBookingsSearch(pageNum, "cancelled", searchValCancelled, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "cancelled",searchValCancelled,sectionID, ffStartDate, ffEndDate));
      }
      else{
        dispatch(getExperienceBookingList(1, sectionID, 'cancelled'))
      }
    }
    else if (key === '4') {
      dispatch(pageId(1));
      setStatus('requested');
      dispatch(getExperiencesStatus("requested"));
      if (sectionID !== null && searchValAvailability===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'requested'));
      else if (ffStartDate !== null || ffEndDate !== null || sectionID !== null || searchValAvailability !== undefined) {
        dispatch(experienceBookingsSearch(pageNum, "requested", searchValAvailability, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "requested",searchValAvailability,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getExperienceBookingList(pageNum, sectionID, 'requested'));
      }
    }
    else {
      setStatus('booked');
      dispatch(pageId(1));
      dispatch(getExperiencesStatus("booked"));
      if (sectionID !== null && searchValBooked===""&& ffStartDate === null && ffEndDate === null) dispatch(getExperienceBookingList(pageNum, sectionID, 'booked'));
      else if (ffStartDate !== null || ffEndDate !== null) {
        dispatch(experienceBookingsSearch(pageNum, "booked", searchValBooked, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(experienceBookingsSearch(pageNum, "booked",searchValBooked,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getExperienceBookingList(1, sectionID, 'booked'))
      }
    }
  };
  const searchValue = () => {
    if(status === "booked") return searchValBooked
    else if(status === "confirmed") return searchValConfirmed
    else if(status === "cancelled") return searchValCancelled
    else if(status === "requested") return searchValAvailability
  }
  const handleStartDateChange = (date) => {
    dispatch(myExperienceBookingStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(experienceBookingsSearch(pageNum, status, searchValue, sectionID, moment(date).format("YYYY-MM-DD"), ffEndDate));
  };

  const handleEndDateChange = (date) => {
    dispatch(myExperienceBookingEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(experienceBookingsSearch(pageNum, status, searchValue, sectionID, ffStartDate, moment(date).format("YYYY-MM-DD")));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myExperienceBookingStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myExperienceBookingEndDate(null));
    dispatch(experienceBookingsSearch(pageNum, "booked", searchValBooked, sectionID, ffStartDate, ffEndDate));
    dispatch(experienceBookingsSearch(pageNum, "confirmed", searchValConfirmed, sectionID, ffStartDate, ffEndDate));
    dispatch(experienceBookingsSearch(pageNum, "cancelled", searchValCancelled, sectionID, ffStartDate, ffEndDate));
    dispatch(experienceBookingsSearch(pageNum, "requested", searchValAvailability, sectionID, ffStartDate, ffEndDate));
    dispatch(experienceBookingsSearch(pageNum, "follow_up", searchValAvailability, sectionID, ffStartDate, ffEndDate));
    dispatch(experienceBookingsSearch(pageNum, "refunded", searchValAvailability, sectionID, ffStartDate, ffEndDate));
  };

  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter status={status}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          ffStartDate={ffStartDate}
          ffEndDate={ffEndDate}
          handleStartClear={handleStartClear}
          handleEndClear={handleEndClear}
        />
      </div>
      <div className="enquiry-tabs-holder">
        <div style={{ display: 'flex' }}>
          <p className="title-holder">{totalExperienceBookings} Experience Bookings</p>
          <div>
            <DropdownFilter />
          </div>
        </div>
        <Tabs defaultActiveKey="4" onChange={handleTabChange}>
          <TabPane tab={ `${getAvailabilityTotalResults} Requests`} key="4">
            <Availability />
          </TabPane>
          <TabPane tab={ `${getFollowuptotalResult}  Follow-Up`} key="5">
            <FollowUp/>
          </TabPane>
          <TabPane tab={`${bookLength} Booked`} key="1">
            <Bookings />
          </TabPane>
          <TabPane tab={`${confirmLength} Confirmed`} key="2">
            <Confirmed />
          </TabPane>
          <TabPane tab={`${cancelledLength} Cancelled`} key="3">
            <Cancelled />
          </TabPane>
          <TabPane tab = {`${refundlength} Refunded`} key="6">
          <Refunded/> 
        </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
        <div>
        </div>
      </div>
    </>
  );
};

export default ExperienceBookingIndex;