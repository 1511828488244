import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import EditHolidayPackageDetails from "./editingHolidayPackageDetails";
import AddHolidayPackageDetails from "./addHolidayPackageDetails";
import Pagination from "@material-ui/lab/Pagination";
import Listing from "./holidaylisting";
import DropdownFilter from "../../holidays/listings/dropDownFilter";
import * as holidayListingAction from "../../../actions/holidaysAction";
import ManageFeaturedPackage from "../../../common/manageFeaturedPackage";
import CsvIcon from "../../../assets/images/csvIcon.svg";
import { CSVLink } from "react-csv";
import { API } from "../../../constants/api";
import "../../../assets/css/_common.scss";
import "../../../assets/css/partials/events/events.scss";
import { Spinner } from "react-bootstrap";

export default function HolidaysListings({ data }) {
  const [page, setPage] = useState(1);
  const featuredList_data = useSelector(
    (state) => state.holidayListing.featured
  );
  const dropDownvalue = useSelector(
    (state) => state.holidayListing.holidayListingDropDownvalue
  );
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const isListingDataLoading = useSelector(
    (state) => state.holidayListing.isListingDataLoading
  );
  const addHolidayPackage = useSelector(
    (state) => state.holidayListing.addHolidayPackage
  );
  const holidayList = useSelector(
    (state) => state.holidayListing.holidayList.data
  );
  const pageID = useSelector((state) => state.holidayListing.pageId);
  const holidayListingDropDownVal = useSelector(
    (state) => state.holidayListing.holidayListingDropDownvalue
  );
  console.log(holidayListingDropDownVal?.name);
  const [
    showManageFeaturePackageButton,
    setShowManageFeaturePackageButton,
  ] = useState(false);
  const packageDetails = useSelector(
    (state) => state.holidayListing.packageInfoHoliday
  );
  
  const search = useSelector(
    (state) => state.holidayListing.holidaylistSearchVal
  );
  const totalListItems = holidayList?.total_results;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(holidayListingAction.getHolidayList(page));
  }, []);

  useEffect(() => {
    if (holidayList && holidayList?.results?.length === 0) {
      dispatch(holidayListingAction.handleAllImages([]));
    }
  }, [holidayList && holidayList?.results]);

  useEffect(() => {
    if (
      holidayList &&
      holidayList?.results?.length === 0 &&
      holidayList &&
      holidayList?.current_page > 1 &&
      holidayList &&
      holidayList?.next_page_id === null
    ) {
      dispatch(
        holidayListingAction.getHolidayList(1, holidayListingDropDownVal?.id)
      );
      dispatch(holidayListingAction.pageId(1));
    }
  }, [holidayList && holidayList?.results]);
  const downloadCSV = () => {
    const api = `${API.HOLIDAYS_CSV_DOWNLOAD}`
    dispatch(holidayListingAction.downloadCSV(api, holidayListingDropDownVal?.id, holidayListingDropDownVal?.name))
  };
  useEffect(() => {
    return () => {
      dispatch(holidayListingAction.getHolidayList(page));
      dispatch(holidayListingAction.pageId(page));
      dispatch(holidayListingAction.holidayListingDropDownVal(null));
    };
  }, []);

  const handleResetPage = () => {
    setPage(1);
  };

  const handleEdit = (details) => {
    dispatch(holidayListingAction.editHolidayListItem(details));
    dispatch(holidayListingAction.setAddHolidayPackage(false));
  };

  const handleAdd = () => {
    dispatch(holidayListingAction.setAddHolidayPackage(true));
  };

  const handlePagination = (e, value) => {
    setPage(value);
    if (search !== "") {
      dispatch(
        holidayListingAction.searchHolidayListing(
          search,
          holidayListingDropDownVal?.id,
          value
        )
      );
    } else {
      dispatch(holidayListingAction.pageId(value));
      dispatch(
        holidayListingAction.getHolidayList(
          value,
          holidayListingDropDownVal?.id
        )
      );
    }
  };
  const [openManageFeaturePackage, setOpenManageFeaturePackage] = useState(
    false
  );
  const updateFeatureOrder = (list) => {
    dispatch(holidayListingAction.updateFeaturedList(list, holidayListingDropDownVal?.id));
  };
  return (
    <>
    <div>
      <div style={{ position: "absolute", right: 50 }}></div>
      <div className="enquiry-tabs-holder">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="col-md-8" style={{ display: "flex", padding:"0px" }}>
            <h1 className="title-holder">{totalListItems} Holiday Listings</h1>
            <div>
              <DropdownFilter  disabled={isListingDataLoading} />
            </div>
          </div>
        
        <div
        type="button"
        value="CSV"
          className="col-md-4"
          style={{
            display: "flex",
            justifyContent: "center",
              // width: "668px",
              // marginRight: "170px",
          }}
        >
          <div 
          style={{
            display: "flex",
            justifyContent: "center",
            width: "160px",
            height: "42px",
            boxSizing: "border-box",
            alignItems: "center",
            position: "static",
          }}
          >
          {holidayListingDropDownVal?.name && (
          <div
            type="button"
            style={{
              border: "1px solid #C4C4C4",
              display: "flex",
              justifyContent: "center",
              width: "138px",
                height: "42px",
              left: "0px",
              border: "1px solid #C4C4C4",
              boxSizing: "border-box",
              borderRadius: "4px",
              lineHeight: "36px",
              alignItems: "center",
              position: "static",
              color: "#000000",
              margin: "0px 9px",
            }}
            onClick={() => setOpenManageFeaturePackage(true)}
          >
            <div
              style={{
                color: "#000000",
                width: "112px",
                height: "16px",
                left: "13px",
                paddingLeft: "3px",
                paddingRight: "0px",
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                fontFamily: "DM Sans",
                fontSize: "13px",
                fontStyle: "normal",
              }}
            >
              Manage Featured
            </div>
          </div>
          )}
          </div>
          <div className="Input" style={{ marginTop: "-7px" }}>
              <button
                className="btn btn-md btn-primary p-2"
                style={{ margin: 7 }}
                onClick={downloadCSV}
              >
                <img src={CsvIcon} /> Export to CSV
              </button>
            </div>
        </div>
      </div>
      </div>
      <Row>
        <Col
          style={{ paddingRight: "20px", marginRight: "-20px" }}
          className="gutter-row"
          xs={24}
          lg={8}
        >
          <Row className="gutter-row">
            <Listing
              holidayListDetails={holidayList}
              handleEdit={handleEdit}
              handleAdd={handleAdd}
              currentPage={page}
              handleResetPage={handleResetPage}
            />
          </Row>
{search&&isListingDataLoading?null:
          <Row style={{ paddingTop: `${addHolidayPackage ? "85%" : "48%"}` }}>
            <Pagination
              count={holidayList?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, value) => handlePagination(event, value)}
            />
          </Row>
}

        </Col>
        <Col
          className="gutter-row"
          style={{ paddingRight: "10px", marginRight: "-20px" }}
          xs={24}
          lg={16}
        >
            {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
          {addHolidayPackage ? (
            <div>
              <AddHolidayPackageDetails />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {holidayList?.results && holidayList?.results.length > 0 ? (
                <EditHolidayPackageDetails
                  data={
                    Object.keys(packageDetails).length === 0
                      ? holidayList?.results[0]
                      : packageDetails
                  }
                  WholeData={packageDetails}

                />
              ) : (
                <AddHolidayPackageDetails />
              )}
            </div>
          )}
        </Col>
      </Row>
      {openManageFeaturePackage && (
        <ManageFeaturedPackage
          showModal={openManageFeaturePackage}
          listingAction={holidayListingAction}
          featuredList_data={featuredList_data}
          dropDownvalue={dropDownvalue}
          toggleFunc={setOpenManageFeaturePackage}
          updateOrder={updateFeatureOrder}

        />
      )}
    </div>
   
    </>
  );
}
