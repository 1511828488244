import { combineReducers } from "redux";
import authReducer from "./authReducer";
import corpListingReducer from "./corpListingReducer";
import enquiryListingReducer from "./enquiryReducer";
import bookingListingReducer from "./bookingReducer";
import sectionReducer from './sectionReducer';
import accountListReducer from "./accountsReducer";
import myFamilyFirst from './myFamilyFirstReducer';
import eventSectionReducer from './eventSectionReducer'
import experienceSectionReducer from './experienceSectionReducer'
import experienceListingReducer from './experienceListingReducer'
import experienceBookingReducer from './experienceBookingReducer'
import eventsReducer from './eventsReducer'
import giftVoucherReducer from "./giftVoucherReducer";
import holidaySectionReducer from './holidaySectionReducer'
import holidayRequestReducer from './holidayRequestReducer'
import holidayBookingReducer from './holidayBookingReducer'
import holidayListingReducer from './holidayListingReducer'
import partnersListReducer from './partnerReducer'
import holidayReviewReducer from "./holidayReviewReducer";
import experienceReviewReducer from "./experienceReviewReducer";
import corporateReviewReducer from "./corporateReviewReducer";
import weddingReducer from './weddingReducer'
import honeymoonReducer from './honeymoonReducer'
import anniversaryReducer from './anniversaryReducer'
import paymentLinkBookingReducer from './paymentLinkBookingReducer'
import webStoriesReducer from "./webStoriesReducer";
import populatDestinationsReducer from"./popularDestinationReducer"
import callBackReducer from "./callBackReducer"

const rootReducers = combineReducers({
  auth: authReducer,
  corpListing: corpListingReducer,
  enquiryListing: enquiryListingReducer,
  bookingListing: bookingListingReducer,
  section:sectionReducer,
  myFamilyFirst:myFamilyFirst,
  eventSection:eventSectionReducer,
  experienceSection:experienceSectionReducer,
  experiencesListing:experienceListingReducer,
  section: sectionReducer,
  accountListing: accountListReducer,
  eventsListing: eventsReducer,
  voucherListing: giftVoucherReducer,
  holidaySection: holidaySectionReducer,
  holidayRequest:holidayRequestReducer,
  holidayBooking:holidayBookingReducer,
  holidayListing:holidayListingReducer,
  holidayReviews:holidayReviewReducer,
  experienceReviews:experienceReviewReducer,
  experienceBooking:experienceBookingReducer,
  partnerListing: partnersListReducer,
  wedding: weddingReducer,
  honeymoon: honeymoonReducer,
  anniversary: anniversaryReducer,
  corporateReviews:corporateReviewReducer,
  paymentLinkBookings: paymentLinkBookingReducer,
  webStories:webStoriesReducer,
  popularDestions:populatDestinationsReducer,
  callBack:callBackReducer
});

export default rootReducers;
