import React, {useEffect, useState} from 'react'
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDown from "../../assets/images/arrowDown.svg";
import { Theme, useTheme } from '@material-ui/core/styles';

const Dropdown = ({options, setState, state, label, value, setIsError}) => {
    const [select, setSelect] = useState(false)
    const [dropDownVal, setDropDownVal] = useState(value)
    
    const handleSelect = (item) => {
        setDropDownVal(item.label)
        if(label == "activity_type"){
            setState({
                ...state,
                "activity_type": item.name
            })
            setIsError({ error:false, msg:'', name: "" })
        }else if(label == "activity_level"){
            setState({
                ...state,
                "activity_level": item.id
            })
            setIsError({ error:false, msg:'', name: "" })
        }
        setSelect(null)
    }
    const handleClickSelect = (event) => {
        setSelect(event.currentTarget);
    } 

    useEffect(() => {
        setDropDownVal(value)
    }, [value])
    
  return (
    <div
        className="activity-dropdown"
        style={{
            position: "relative",
            cursor: "pointer",
            width: "100%",
        }}
        >
        <div>
            <Button
            style={{ paddingRight: "32px", width: "100%", height: "40px", textAlign: "left" }}
            aria-controls="section-menu"
            aria-haspopup="true"
            onClick={handleClickSelect}
            >
                {dropDownVal}
            <img
                alt="arrow"
                src={ArrowDown}
                style={{
                position: "absolute",
                right: "12px",
                top: "12px",
                float: "right"
                }}
            />
            </Button>
            <Menu
            id="section-menu"
            anchorEl={select}
            keepMounted
            open={Boolean(select)}
            onClose={() => setSelect(false)}
            >
            {options &&
                options.map((item) => {
                return (
                    <MenuItem
                    onClick={() => handleSelect(item)}
                    key={item.name}
                    >
                    {item.label}
                    </MenuItem>
                );
                })}
            </Menu>
        </div>
        </div>
  )
}

export default Dropdown