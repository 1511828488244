import React,{ useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { ImCross } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';
import '../../../assets/css/partials/closeRequestModal.scss';
import moment from 'moment';
import {checkNumberOfGuest } from '../../../utils/index';


const ClosedModal = ({
    handleClose, 
    closeRequestHandler, 
    open, 
    isclosingMessage,
    data,
  
}) => {
    const { 
        contact_name, 
        special_request, 
        created, 
        request_type, 
        locations_preferred ,
        adults,
        children,
        infants,
        schedule
    }= data;

    console.log(schedule);
    const [inputVal, setInputVal] = useState();
    const dispatch = useDispatch();
    
    const onHandleChange = (event) => {
        let value = event.target.value;
        setInputVal(value);
       
        
        dispatch(closeRequestInputVal(value));
       
        
    };

    return (
        <Dialog
            PaperProps={{
                style: {
                    height: "auto",
                    maxWidth: "710px"
                },
            }}
            open={open}
        >
            <div style={{padding:"10px"}}>
            <div style={{margin:"10px 0px", padding:"10px"}}>
                <h5>You are cancelling the request</h5>
                <span className="close-icon"
                    onClick={handleClose}>
                    <ImCross />
                </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{ flex: 0.5, position: "relative" }}
                    className="form-group form-custom"
                >
                    <div className="close-request-modal-rt-container">
                        <div className="close-request-modal-rt-content">
                            <div style={{ padding: "15px" }}>
                                <h6>{locations_preferred}</h6>
                                <p>{moment(created).format("Do MMMM, YYYY")}</p>
                                <span style={{color:"#2196F3"}}>{request_type}</span>
                                <span style={{marginLeft:"5px"}}>{contact_name}</span>
                            </div>
                        </div>
                        {request_type !== "Visa" &&
                        <div>
                            <div style={{ marginTop: "20px" }}>
                                <label className="label">Requested for</label>
                                <div className="close-request-modal-rt-content" style={{ padding: "15px" }} >
                                <p>
                                <span>
                                    {Array.isArray(schedule) ? schedule.map((scheduleDate, index) => {
                                        if (schedule[index] === schedule[schedule.length - 1]) {
                                        return scheduleDate
                                        } else {
                                        return `${scheduleDate}, `
                                        }
                                    }) : (typeof schedule === 'object' && schedule !== null ? `${moment(schedule.from,"DD-MM-YYYY").format("DD-MM-YYYY")} ${ schedule.to ? `to ${schedule.to}`: ""}` : (schedule ===  null ? "N/A" : schedule))}
                                    </span>
                                    </p>
                                    <p >{checkNumberOfGuest(adults, children, infants)}</p>
                             </div>
                           </div>
                        </div>
                      
                        
                      
                   
                        }
                        {/* <div style={{ marginTop: "20px" }}>
                            <label className="label">Message</label>
                            <div className="close-request-modal-rt-content">
                                <p style={{ padding: "15px 10px 25p" }} className="message-limit" title={special_request}>{special_request?special_request:"N/A"}</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div
                    style={{ flex: 0.5, position: "relative" }}>
                    <label for="">Please write a message for all the registered users explaining the reason of cancel request</label>
                    <textarea
                        placeholder="Please mention the reason for cancellation of this event"
                        value={inputVal}
                        type="text"
                        className="form-control"
                        onChange={onHandleChange}
                    />
                    {isclosingMessage && <p style={{ color: "red" }}>Please write reason for cancel this request</p>}
                </div>
            </div>
            <div style={{display:"flex",float:"right"}}>
                <Button onClick={handleClose} color="black" style={{ backgroundColor: "#EDEDED", textTransform: "none", margin: "0px 25px" , width: "155px"}}>
                    Don't Cancel
                    </Button>
                <Button onClick={closeRequestHandler} color="primary" style={{ backgroundColor: "black", color: "white", textTransform: "none", marginLeft: "10px" , width: "155px" }}>
                    Cancel request
                 </Button>
            </div>
            </div>
        </Dialog>
    );
};

export default ClosedModal;
