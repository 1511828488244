export const statusValues = [
    {
        name: 'Enquiry',
        value: 'enquiry'
    },
    {
        name: 'Follow-Up',
        value: 'follow-up'
    },
    {
        name: 'Resolved',
        value: 'resolved'
    }
]

export const corpEnqFollowupStatueValue =[
    {
        name: 'Follow-Up',
        value: 'follow-up'
    },
    {
        name: 'Resolved',
        value: 'resolved'
    }
]