import React from 'react'


const CallBackCard = ({details}) => {
       
        const fullTimestamp = details.modified;
        const dateTime = new Date(fullTimestamp);
        const year = dateTime.getFullYear();
        
        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const monthIndex = dateTime.getMonth();
        const month = monthNames[monthIndex];
        
        const day = String(dateTime.getDate()).padStart(2, '0');
        const formattedDate = `${month} ${day}th, ${year}`;


  return (
   <>
   <div className='CallBack-card'>
    <div className='name-request'>
        <p className='name'>{details.name}</p>
        <span className='request-date'>Requested on {" "} {formattedDate} </span>

    </div>
    <div className='contact-box' style={{marginLeft:details?.location?"5%":"5%"}}>
            <p >Contact</p>
            <input  type="text" className='number' value={details.mobile}  readOnly={true}></input>
    </div>

    <div className='email-box' style={{marginLeft:details?.location?"10%":"10%"}}>
            <p >Email</p>
            <input  type="email" className='email' value={details.email} readOnly={true}></input>
    </div>
    {details?.location?<div className='location-box'>
            <p >Location</p>
            <input  type="text" className='location' value={details.location} readOnly={true}></input>
    </div>
    :<></>}

   </div>
   </>
  )
}

export default CallBackCard