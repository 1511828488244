import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getEventSection,
  deleteEventSection,
  eventSelectedImageUrl,
  imageUpload,
  updateEventSection,
  getSectionPackages
} from '../../../actions/eventSectionAction';

import SectionImageItem  from '../../../common/sectionImageItem';
import AddNewSection from '../../../common/addNewSection';
import { CANCEL, UPDATE, DELETE, DELETE_CONFIRMATION_MESSAGE } from '../../../utils/constants';
import DeleteConfirmationModal from '../../../common/deleteConfirmationModal';
import { useSnackbar } from "notistack";

const Sections = () => {

  const [ showEventUpdateModal, setShowEventUpdateModal ] = useState(false);
  const [ showEventSectionDeleteModal, setShowEventSectionDeleteModal ] = useState(false);
  const [ eventSectionDeleteData, setEventSectionDeleteData ] = useState({});
  const [ sectionUpdateData, setSectionUpdateData ] = useState({});
  const [ updateImageBool, setUpdateImageBool] = useState(false);
  const [ updateSectionName, setUpdateSectionName ] = useState("");
  const [ updateSectionNameBool, setUpdateSectionNameBool ] = useState(false);
  const [ updateSectionDesc, setUpdateSectionDesc ] = useState("");
  const [ updateSectionDescBool, setUpdateSectionDescBool ] = useState(false);
  const [ updateSectionPriority, setUpdateSectionPriority ] = useState("")
  const [ updateSectionPriorityBool, setUpdateSectionPriorityBool ] = useState(false);
  const [ refCodeValue, setRefCodeValue] = useState("");
  const [ refCodeBool, setRefCodeBool ] = useState(false);
  const [ updateEventSectionPriorityBool, setUpdateEventSectionPriorityBool ] = useState(false);
  const [ updateEventSectionNameBool, setUpdateEventSectionNameBool ] = useState(null);
  const [ updateEventSectionDescBool, setUpdateEventSectionDescBool ] = useState(null);
  const [ updateEventSectionPriorityEmptyBool, setUpdateEventSectionPriorityEmptyBool ] = useState(null);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ isCodeMatch, setIsCodeMatch ] = useState(false);
  const [ descMoreError, setDescMoreError ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const eventSelectedImgUrl = useSelector(state => state.eventSection.eventSelectedImageUrl);
  const eventSectionData = useSelector(state => state.eventSection?.getEventSection);
  const sectionTotalPack = useSelector(state => state.eventSection.sectionPackages);

  useEffect(() => {
    dispatch(getEventSection())
  }, []);

  useEffect(() => {
    if(refCodeBool && refCodeValue.length === 2){
      setCodeLessErrorr(false);
      setCodeMoreError(false);
    }
   }, [refCodeBool,refCodeValue]);

  const updatePic = (data) => {
    dispatch(eventSelectedImageUrl(data.url));
  };

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(imageUpload(croppedFile, updatePic));
    }
    setUpdateImageBool(true);
  };

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
  };

  const updateHandleSuccess = () => {
    dispatch(getEventSection());
    enqueueSnackbar("Updated successfully" ,{
      variant: "success",
    });
  };

  const eventSectionUpdateHandler = (data) => {
    setShowEventUpdateModal(true)
    setSectionUpdateData(data)
  };

  const openDeleteConfirmationModal = (data) => {
    setShowEventSectionDeleteModal(true);
    setEventSectionDeleteData(data);
    dispatch(getSectionPackages(data))
  };


  const eventSectionDeleteHandler = () => {
    dispatch(deleteEventSection(eventSectionDeleteData, handleSuccess));
    setShowEventSectionDeleteModal(false);
  };

  const updateEventSectionNamehandler = (e) => {
    setUpdateSectionNameBool(true);
    setUpdateSectionName(e.target.value);
  };
  const updateEventSectionDeschandler = (e) => {
    setUpdateSectionDescBool(true);
    setUpdateSectionDesc(e.target.value);
  };

  const updateEventSectionPriorityhandler = (e) => {
    setUpdateEventSectionPriorityBool(false)
    setUpdateSectionPriorityBool(true);
    setUpdateSectionPriority(e.target.value)
    if(e.target.value <= -1)setUpdateEventSectionPriorityBool(true)
  };

  const refrenceCodeHandler = (e) => {
    setRefCodeBool(true)
    setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredEventSection = eventSectionData.filter(item => {
      return code === item.code
    })
    if(filteredEventSection.length){
      setIsCodeMatch(true)
    }else{
      setIsCodeMatch(false)
    }
    setRefCodeValue(code)

  };

  const handleClose = () => {
    setRefCodeBool(false)
    setRefCodeValue("")
    setUpdateSectionPriorityBool(false)
    setUpdateSectionPriority("")
    setUpdateImageBool(false)
    setUpdateSectionNameBool(false);
    setUpdateSectionName("")
    setUpdateSectionDescBool(false);
    setUpdateSectionDesc("")
    dispatch(eventSelectedImageUrl(null));
    setShowEventUpdateModal(false)
  }

  const updateSectionHandleSubmit = (e) => {
    e.preventDefault();
    setUpdateEventSectionNameBool(false);
    setUpdateEventSectionDescBool(false);
    setUpdateEventSectionPriorityBool(false);
    setUpdateEventSectionPriorityEmptyBool(false);
    setCodeLessErrorr(false);
    setIscodeError(false);
    setCodeMoreError(false);
    const data = {
      image: updateImageBool && eventSelectedImgUrl !== null ? eventSelectedImgUrl : sectionUpdateData.image,
      name: updateSectionNameBool ? updateSectionName : sectionUpdateData.name,
      description: updateSectionDescBool ? updateSectionDesc : sectionUpdateData.description,
      priority: updateSectionPriorityBool ? updateSectionPriority : sectionUpdateData.priority,
      code:refCodeBool ? refCodeValue : sectionUpdateData.code
    };
    if(updateSectionNameBool && updateSectionName === "" || sectionUpdateData.name === "")setUpdateEventSectionNameBool(true);
    else if(updateSectionDesc === "" || sectionUpdateData.description === "")setUpdateEventSectionDescBool(true);
    else if(updateSectionDescBool && updateSectionDesc.length > 200 )setDescMoreError(true);
    else if(updateSectionPriorityBool && updateSectionPriority === "" || sectionUpdateData.priority === "")setUpdateEventSectionPriorityEmptyBool(true);
    else if(updateSectionPriority <= -1)setUpdateEventSectionPriorityBool(true);
    else if(refCodeBool && refCodeValue.length === 0) setIscodeError(true)
    else if(refCodeBool && refCodeValue.length < 2) setCodeLessErrorr(true)
    else if(refCodeBool && refCodeValue.length > 2) setCodeMoreError(true)
    else if(refCodeBool && refCodeValue.length == 2 && isCodeMatch) setCodeMatchError(true)
    else{
      dispatch(updateEventSection(data, sectionUpdateData, updateHandleSuccess));
      setShowEventUpdateModal(false);
      setUpdateSectionNameBool(false);
      setUpdateSectionDescBool(false);
      setDescMoreError(false)
      setUpdateSectionPriorityBool(false);
      setUpdateImageBool(false);
      setRefCodeBool(false)
      dispatch(eventSelectedImageUrl(null));
    }
  };
  
  const eventSectionListingData = eventSectionData && eventSectionData.map(data => (
    <SectionImageItem 
      sectionText={data.name}
      sectionImage={data.image}
      priority={data.priority}
      sectionImageUpdateHandlere={() => eventSectionUpdateHandler(data)}
      sectionImageDeleteHandler={() => openDeleteConfirmationModal(data)}
    />
  ));

  return(
    <>     
    {eventSectionListingData}
    {showEventUpdateModal && 
      <AddNewSection 
       showMyModal={showEventUpdateModal}
      //  closeHandle={() => setShowEventUpdateModal(false)}
       heading="Update Section"
       labelTextOne="Section name"
       labelTextThree="Description"
       labelTextTwo="priority"
       codeRefrence="Code"
       valueOne={updateSectionNameBool ? updateSectionName : sectionUpdateData.name}
       valueThree={updateSectionDescBool ? updateSectionDesc : sectionUpdateData.description}
       valueTwo={updateSectionPriorityBool ? updateSectionPriority : sectionUpdateData.priority}
       image={updateImageBool && eventSelectedImgUrl !== null ? eventSelectedImgUrl : sectionUpdateData.image}
       btnTextOne={CANCEL}
       btnTextTwo={UPDATE}
       onChangeOne={updateEventSectionNamehandler}
       onChangeThree={updateEventSectionDeschandler}
       onChangeTwo={updateEventSectionPriorityhandler}
       refCodeValue={refCodeBool? refCodeValue :sectionUpdateData.code }
       refrenceCodeHandler={refrenceCodeHandler}
       handleSubmit={updateSectionHandleSubmit}
       closeModal={() => setShowEventUpdateModal(false)}
       isEventsAddNewSection={false}
       isEventsUpdateSection={true}
       closeHandle={() => handleClose()}
       handleFilePickChange={handleImageChange}
       isEventSection={true}
       isPriorityError={updateEventSectionPriorityBool}
       isSectionNameError={updateEventSectionNameBool}
       isSectionDescError={updateEventSectionDescBool}
       isPriorityEmptyError={updateEventSectionPriorityEmptyBool}
       iscodeError={iscodeError}
       codeLessError={codeLessError}
       codeMoreError={codeMoreError}
       codeMatchError={codeMatchError}
       disabled={updateImageBool && eventSelectedImgUrl !== null ||  updateSectionPriority || updateSectionName || updateSectionDesc || refCodeValue ? false : true}
       isUpdateSec={true}
       descMoreError={descMoreError}
      isDescription={true}
      />
    }
    <DeleteConfirmationModal 
      open={showEventSectionDeleteModal}
      btnTextOne={CANCEL}
      btnTextTwo={DELETE}
      onClick={() =>setShowEventSectionDeleteModal(false)}
      deleteImage={eventSectionDeleteHandler}
      disable={sectionTotalPack > 0 ? true : false}
      fontSize={sectionTotalPack > 0 ?"20px":""}
      deleteConfirmationMsg={
        sectionTotalPack > 0 ?
        `You have 
        ${sectionTotalPack} ${sectionTotalPack > 1 ? "packages": "package"} 
        in this section. Please shift or delete ${sectionTotalPack > 1 ? "these packages": "this packge"} to delete the section.`:
        DELETE_CONFIRMATION_MESSAGE
      }
    />
    </>
  );
};

export default Sections;
