import React, {  useState } from "react";
import { Provider, useSelector} from "react-redux";
import './App.scss';
import { SnackbarProvider } from "notistack";
import Collapse from '@material-ui/core/Collapse';
import LoginPage from './components/auth/login';
import SideNav from './layouts/sidenav';
import store from "./store";

const ProviderConfig = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const [path, setPath] = useState(window.location.pathname);
}

function App() {
  const isToken = localStorage.getItem("token");
  return (
    <SnackbarProvider
    autoHideDuration={1500}
    maxSnack={1}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    TransitionComponent={Collapse}
  >
    <Provider store={store}>
    {
      // TODO - We have to remove this later
      isToken === null ? <LoginPage /> : <SideNav />
    }
    </Provider>
    </SnackbarProvider>

  );
}

export default App;
