

import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
  axiosDelete,
  axiosPut,
  axiosMediaUpload
} from "../services/apiServices";
import { 
  GET_EVENT_SECTION,
  EVENT_CONFIRM_ADD_BOOL,
  EVENT_CONFIRM_UPDATE_BOOL,
  EVENT_SELECTED_IMAGE_URL,
  EVENT_ADD_IMAGE,
  EVENT_SECTION_PACKAGES
} from '../actions/types'

export const getEventSection = () => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      axiosGetWithToken(`${API.GET_EVENT_SECTION}`, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: GET_EVENT_SECTION,
              payload: res.data.data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };

  export const handleEventAddConfirmBool = (val) => {
    return async (dispatch) => {
      dispatch({
        type: EVENT_CONFIRM_ADD_BOOL,
        payload: val
      });
    };
  };
  
  export const handleEventUpdateConfirmBool = (val) => {
    return async (dispatch) => {
      dispatch({
        type: EVENT_CONFIRM_UPDATE_BOOL,
        payload: val
      });
    };
  };

  export const eventSelectedImageUrl = (url) => {
    return async (dispatch) => {
      dispatch({
        type: EVENT_SELECTED_IMAGE_URL,
        payload: url
      });
    };
  };

export const imageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;

    axiosMediaUpload(API.EVENT_POST_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        console.log("RESPO IMAGE", res);
        dispatch({
          type: EVENT_ADD_IMAGE,
          payload: res.data,
        });
        addImage(res.data.data);
        console.log("RESP2", res.data.data);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};


export const addEventSection = (eventSectionSubmitData,refreshAddEventSectionData, addedSuccessHandle) => {
  return async (dispatch,getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_EVENT_SECTION_LIST_POST}`, eventSectionSubmitData, token)
      .then((res) => {
        if(res.data.status_code === 200){
          dispatch({
            type:GET_EVENT_SECTION,
            payload:res.data.data
          })
          refreshAddEventSectionData()
          addedSuccessHandle()
        }
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const deleteEventSection = (item,handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const sectionEventData = getState().eventSection.getEventSection;
    let resDeleteData = sectionEventData.filter(itm => itm.id !== item.id);
    
    axiosDelete(`${API.EVENT_SECTION_DELETE}${item.id}`, token)
    .then((res) => {
      if(res.status === 204){
        handleSuccess("Deleted successfully");
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
    dispatch({
      type: GET_EVENT_SECTION,
      payload: resDeleteData
    });
  };
};

export const updateEventSection = (data, sectionUpdateData, updateHandleSuccess,) => {
  return async (dispatch,getState) => {
    let id = sectionUpdateData.id
    const token = getState().auth.token;
    axiosPut(`${API.EVENT_SECTION_DELETE}${id}/`, data, token)
      .then((res) => {
        if(res.data.status_code === 200){
          updateHandleSuccess()
        }
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const getSectionPackages = (itemNeedToBeDeleted) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;  
    let id = itemNeedToBeDeleted.id      
    axiosGetWithToken(`${API.EVENT_SECTION_PACKAGES}${id}/`, token)
        .then((res) => {
          console.log("jhkjhjkhkjhk", res)
          dispatch({
            type:EVENT_SECTION_PACKAGES,
            payload:res.data.data.total_pack
          })
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
};