import React from 'react'
import { Dialog } from '@material-ui/core';
import { ImCross } from 'react-icons/im';
import '../../../assets/css/partials/contactInfo.scss';
const ContactInfo = ({open, modalClose, contactInfoData,isCancellationMessage}) => {
    const { contact_name, contact_number, contact_email,cancelling_message } = contactInfoData
    return (
        <Dialog open={open}
        PaperProps={{
            style: {
                height:"auto",
                maxWidth:"350px",
                backgroundColour:"#F5F5F5"
            },
       }}
        >
            <div className="contact-info-container">
               <h5 className="contact-info-heading">
                    <span>Contact Info</span>
                    <span className="contact-info-close-icon"
                        onClick={modalClose}>
                        <ImCross />

                    </span>
                </h5> 
                <div className="contact-info-details">
                <div className="contact-info-content">
                    <div className="content" >
                        <label>Requister Name</label>
                        <p>{contact_name}</p>
                    </div>
                </div>
                <div className="contact-info-content">
                    <div className="content">
                        <label>Contact Number</label>
                        <p> {contact_number}</p>
                    </div>
                </div>
                <div className="contact-info-content">
                    <div className="content">
                        <label>Contact Email</label>
                        <p>{contact_email}</p>
                    </div>
                </div>
                {isCancellationMessage?<div className="contact-info-content">
                    <div className="content">
                        <label> Cancellation Message</label>
                        <p>{cancelling_message}</p>
                    </div>
                </div>:""}
                </div>
            </div>
        </Dialog>
    );
};

export default ContactInfo;
