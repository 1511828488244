import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";

import AccountEditDetails from "./AccountEditDetails";
import AccountAddNewDetails from "./AccountAddNewDetails";
import AccountListing from "./AccountListing";
import Pagination from '@material-ui/lab/Pagination';
import * as AccountAction from "../../../actions/accountsAction";
import * as ListAction from "../../../actions/corpListingAction";
import { Spinner } from "react-bootstrap";

export default function Accounts() {
    const dispatch = useDispatch();
    const addAccount = useSelector((state) => state.accountListing.addNewAccount);
    const getAccountDetails = useSelector(
        (state) => state.accountListing.accountDetails.user_accounts
    );
    const isDataLoading = useSelector(
        (state) => state.corpListing.isDataLoading
      );
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1)

    const accountList = useSelector(
        (state) => state.accountListing.accountsList.data
    );
    const corpListItemDetails = useSelector(
        (state) => state.corpListing.corpListItemDetails
    );
    const orgSearchTerm = useSelector(state => state.accountListing?.org_search_term)
    console.log(orgSearchTerm, "orgSearchTerm")


    useEffect(() => {
        dispatch(AccountAction.accountsList(handleLoader, page));
    }, [page]);

    useEffect(() => {
        if(accountList?.results[0]?.id){
        const id = accountList?.results[0]?.id
        dispatch(AccountAction.getAccountInfo(id));
        // dispatch(AccountAction.getEmployeeList(id))
        }
    }, [accountList?.results[0]?.id]);

    // useEffect(() => {
    //     dispatch(AccountAction.getAccountInfo(getAccountDetails));
    // }, [getAccountDetails]);

    const handleLoader = () => {
        setLoader(false);
    };

    const handleResetPage = () => {
             setPage(1)
      }
    const handleResetSearchPage = () => {
        if(orgSearchTerm){
             setPage(1)
        }
      }

    const handleEdit = (id) => {
        dispatch(AccountAction.getAccountInfo(id));
        dispatch(AccountAction.setAddAccount(false));
    };

    const handleAdd = () => {
        dispatch(AccountAction.setAddAccount(true));
    };
    // const setIsEmpty = () => {
    //     setIsEmpty(true)
    // }

    const totalAccountItems = accountList?.total_results;
    

    return (
        <div>
            <h1 className="title-holder">{totalAccountItems} Corporate Organisations</h1>
            <Row>
                <Col className="gutter-row" xs={24} lg={8} style={{ paddingRight: '20px', marginRight: '-20px' }}>
                    <Row  className="gutter-row" >
                        <AccountListing
                            accountDetails={accountList}
                            handleEdit={handleEdit}
                            handleAdd={handleAdd}
                            page={page}
                            onResetpage={handleResetSearchPage}
                        // setIsEmpty={setIsEmpty}
                        />
                    </Row>
                    <Row style={{ paddingTop: '12px',}}>
                        <Pagination
                            count={accountList?.total_pages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </Row>
                </Col>

                <Col className="gutter-row" xs={24} lg={16}>
                {isDataLoading&&
              <div className="blured-backround">
                <p>
                  <Spinner animation="border" variant="primary" />
                </p>
              </div>
            }
                    {addAccount ? (
                        <div>
                            <AccountAddNewDetails noRecordFound={false}/>
                        </div>
                    ) : null}
                    {loader ? null : (
                        <div>
                            {/* {accountList?.results?.length > 0 && !addAccount ? <AccountEditDetails page={page} onResetpage={handleResetPage}/> : null} */}
                            {accountList?.results?.length > 0 && !addAccount ? 
                            <AccountEditDetails page={page} onResetpage={handleResetSearchPage}/> : !addAccount ?
                            <AccountAddNewDetails noRecordFound={true}/> : 
                            null}
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}
