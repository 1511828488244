import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../common/commonSearchBar'
import MyFamilyFirstBooking from '../../../common/myFamilyFirstBookings'
import MyFamilyFirstMoreInfoModal from '../../gifts/myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../common/pagination';
import * as WeddingAction from '../../../actions/weddingAction'
import ConfirmModal from './confirmModal';

const Cancelled = ({status}) => {
    
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false);
    const [ moreInfoModalData, setMoreInfoModalData ] = useState({});
    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    
    const [ id, setId ] = useState(null);

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")
    const [page, setPage] = useState(1);
    const weddingCancelledData = useSelector(state => state.wedding?.weddingCancelled?.results);

    const totalPage = useSelector(state => state.wedding?.weddingCancelled?.total_pages);
    const START_DATE = useSelector(state => state.wedding.startDate);
    const END_DATE = useSelector(state => state.wedding.endDate);
    const SEARCH_TERM = useSelector(state => state.wedding.searchTerm);
    const PAGE_NO = useSelector(state => state.wedding.pageNo);
    const [averageOrderValue,setAverageOrderValue]=useState()
    const[totalOrderValue,setTotalOrderValue]=useState()

    useEffect(() => {
      window.scrollTo(0, 0);
      return ()=> document.body.style.overflow = 'unset';
  }, [showModal]);

      useEffect(() => {
     
      let value = [];
      weddingCancelledData?.map((elem) => {
        if (elem.price) {
          value.push(elem.price)
       
        }
        else{
          value.push(0)
        }
      
      },);
         
      const valueSum=value.reduce((acc,elem)=>{
          return acc+elem
  
      },0)
      console.log(value);
      console.log(valueSum/value?.length);
      setTotalOrderValue(valueSum)
      setAverageOrderValue(Math.round(valueSum/weddingCancelledData?.length));
         }, [weddingCancelledData]);
    

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);
    useEffect(() => {
        setPage(1)
      }, [status])
    const handleMoreInfoClick  = (data) => {
        setShowModal(true);
        setMoreInfoModalData(data);
    };
    
    const requestStatusChange = (event, id,data) => {
        setId(id);
        setConfirmedRequestModalData(data)
          let status = event.target.value;
    
          if(status === "confirmed")setRequestModalConfirm(true);
          else{
            dispatch(WeddingAction.weddingRequestStatusChange(status, id, refereshList))
  
          }
          
      };
      const confirmRequestHandler=()=>{
        
        
        setIsconfirmAmountBool(false)
       if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
        else {
        dispatch(WeddingAction.weddingRequestStatusChangeConfirmed("confirmed", id,confirmedRequestAmount, refereshList))
          setRequestModalConfirm(false)
        }
        // dispatch(confirmRequestInputVal(''));
       
        
    
      }


      const notToConfirm=()=>{
     
        setRequestModalConfirm(false)
        setIsconfirmAmountBool(false);
      
      }
    const refereshList = () => {
        dispatch(WeddingAction.getWeddingRequested(1));
        dispatch(WeddingAction.getWeddingPlanning(1));
        dispatch(WeddingAction.getWeddingConfirmed(1));
        dispatch(WeddingAction.getWeddingCancelled(page));
    };

    const handlePagination = (e, value) => {
        setPage(value);
        dispatch(WeddingAction.setPageNo(value))
        if (SEARCH_TERM || START_DATE || END_DATE) {
            dispatch(WeddingAction.searchWeddingRequests(SEARCH_TERM, START_DATE, END_DATE, "cancelled", value));
        } else {
            dispatch(WeddingAction.getWeddingCancelled(value));
        }
    };

    const handleChange = (event) => {
        if (event.target.value) {
            dispatch(WeddingAction.searchWeddingRequests(event.target.value, START_DATE, END_DATE, "cancelled", PAGE_NO));
        } else {
            dispatch(WeddingAction.getWeddingCancelled(1));
            setPage(1);
        }
        dispatch(WeddingAction.setSearchTerm(event.target.value))
    };

    const weddingCancelledDisplayData = weddingCancelledData && weddingCancelledData.map(data => (
        <MyFamilyFirstBooking 
          locationsPreferred={data.locations_preferred}
          occasion={data.occasion}
          requestedBy={data.contact_name}
          email={data.contact_email}
          contactNum={data.contact_number}
          messageTitle={data.specific_request ? data.specific_request : 'No Message'}
          specialRequest={data.specific_request? data.specific_request:"N/A"}
          onClick={() => handleMoreInfoClick(data)}
          myFamilyFirstStatusChange={requestStatusChange}
          data={data}
          id={data.id}
          refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
          value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {weddingCancelledData && weddingCancelledData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
          <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalOrderValue} </b> </span>

<span style={{marginLeft:"80px"}}><b> 
Average Order Value: {" "} {averageOrderValue}
</b>
  </span></p>
          {weddingCancelledDisplayData}
          <MyFamilyFirstMoreInfoModal
              open={showModal}
              close={() => setShowModal(false)}
              modalData={moreInfoModalData}
              section={"Wedding Requested"}
              isCancellationMessage={true}
            />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
        {requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
        </>
    )
}

export default Cancelled;
