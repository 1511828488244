import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHolidayReviewPendingList,
  getHolidayReviewPublishedList,
  getHolidayReviewRejectedList,
  getHolidayReviewsearch,
  holidayReviewStatusUpdate,
  reviewPageId,
} from "../../../actions/holidayReviewAction";

import SearchBar from "../../../common/commonSearchBar";
import CustomerReviews from "../../../common/holidayReviews";

import Pagination from "../../../common/pagination";

const Pending = () => {
  const [amountValue, setAmountValue] = useState("");
 
  const dispatch = useDispatch();
  const totalHolidayReviews = useSelector(
    (state) => state.holidayReviews.holidayReviewList
  );
  const PendingReviewList =
    totalHolidayReviews && totalHolidayReviews?.review_data?.results;
  const totalPage = useSelector(
    (state) => state.holidayReviews.holidayReviewList.review_data?.total_pages
  );
  const pageNum = useSelector((state) => state.holidayReviews.page);
  const ffEndDate = useSelector((state) => state.holidayReviews.holidayReviewEndDate);
  const ffStartDate = useSelector((state) => state.holidayReviews.holidayReviewStartDate);
  useEffect(() => {
    if(ffEndDate===null&&ffStartDate===null){
      dispatch(getHolidayReviewPendingList(1));
      dispatch(reviewPageId(1));
    }
 
  }, [ffEndDate,ffStartDate]);
 
  const refreshData = () => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(
        getHolidayReviewsearch(pageNum, "pending", ffStartDate, ffEndDate)
      );
    } else {
      dispatch(getHolidayReviewRejectedList(pageNum));
      dispatch(getHolidayReviewPublishedList(pageNum));
      dispatch(getHolidayReviewPendingList(pageNum));
    }
  };
  const handlePagination = (e, value) => {
    e.preventDefault();
    if(ffEndDate===null&&ffStartDate===null){
      dispatch(getHolidayReviewPendingList(value));
      dispatch(reviewPageId(value));
    }
    else{
      dispatch(getHolidayReviewsearch(value,"pending",ffStartDate,ffEndDate));
      dispatch(reviewPageId(value));
    }
  };
  const publishReview = (e,review_id,pack_id,ref_id) => {
    if(e.target.value=="rejected"||e.target.value=="approved"){
    dispatch(holidayReviewStatusUpdate(e.target.value,pack_id, review_id,ref_id, refreshData));
    }
  };
  return (
    <>
       {PendingReviewList && PendingReviewList?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {PendingReviewList &&
        PendingReviewList.map((data, i) => (
          <CustomerReviews
            key={i}
            title={data?.holiday_pack?.pack_title}
            address={`${(data?.holiday_pack.location_city,
            data?.holiday_pack?.location_country)}`}
            status={"pending"}
            main_pack_id={data?.main_pack_id}
            price={data?.holiday_pack?.revised_price ? data?.holiday_pack?.revised_price : data?.holiday_pack?.price}
            comment={data?.review_comment}
            review_id={data?.id}
            publishReview={publishReview}
            ref_id={data?.ref_id}
            review_star={data?.review_star}
          />
        ))}
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
    </>
  );
};
export default Pending;
