import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
  axiosDelete,
  axiosPut,
  axiosMediaUpload
} from "../services/apiServices";
import { 
  GET_HOLIDAY_SECTION, 
  HOLIDAY_SELECTED_IMAGE_URL, 
  HOLIDAY_ADD_IMAGE,HOLIDAY_CONFIRM_ADD_BOOL, 
  HOLIDAY_CONFIRM_UPDATE_BOOL, 
  CLOSE_HOLIDAY_ADD_NEW_SECTION ,
  HOLIDAY_SECTION_PACKAGES,
  IS_DATA_LOADING
} from '../actions/types'

export const getHolidaySectionList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    // dispatch({
    //   type: IS_DATA_LOADING,
    //   payload: true,
    // });
    axiosGetWithToken(`${API.GET_HOLIDAY_SECTION_LIST}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_HOLIDAY_SECTION,
            payload: res.data.data,
          });
          // dispatch({
          //   type: IS_DATA_LOADING,
          //   payload: false,
          // });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const getSectionPackages = (data) => {
  return async (dispatch, getState) => {
    const id = data.id
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_HOLIDAY_SECTION_PACKAGES}${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_SECTION_PACKAGES,
            payload: res.data.data.total_pack,
          });
        }
      })
      .catch((err) => {
        dispatch({
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const handleHolidayAddNewConfirmBool = (val) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_CONFIRM_ADD_BOOL,
      payload: val
    });
  };
};

export const handleHolidayUpdateConfirmBool = (val) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_CONFIRM_UPDATE_BOOL,
      payload: val
    });
  };
};

export const holidayImageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;

    axiosMediaUpload(API.HOLIDAY_IMAGE_UPLOAD, "image", file, token)
    .then((res) => {
      dispatch({
        type: HOLIDAY_ADD_IMAGE,
        payload: res.data,
      });
      addImage(res.data.data);
    })
    .catch((err) => {
      console.log("ERROR_ALERT", err);
    });
  };
};

export const holidaySelectedImageUrl = (url) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_SELECTED_IMAGE_URL,
      payload: url
    });
  };
};

export const closeHolidayAddNew = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE_HOLIDAY_ADD_NEW_SECTION,
      payload: bool
    });
  };
};

export const addHolidaySection = (holidaySectionAddData,refreshAddEventHolidayData) => {
  return async (dispatch,getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_HOLIDAY_SECTION_LIST_POST}`, holidaySectionAddData, token)
    .then((res) => {
      if(res.data.status_code === 200){
        dispatch({
          type:GET_HOLIDAY_SECTION,
          payload:res.data.data
        })
        refreshAddEventHolidayData()
      }
    })
    .catch((err) => {
      console.log("ERROR_ALERT", err);
    });
  };
};

export const deleteHolidaySection = (data, handleSuccess) => {
  return async (dispatch, getState) => {

    const token = getState().auth.token;
    const holidaySecData = getState().holidaySection.getHolidaySectionList;
    let resDeleteData = holidaySecData.filter(itm => itm.id !== data.id);

    axiosDelete(`${API.DELETE_HOLIDAY_SECTION}${data.id}/`, token)
    .then((res) => {
      if(res.status === 204){
        handleSuccess("Deleted successfully");
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
    dispatch({
      type: GET_HOLIDAY_SECTION,
      payload: resDeleteData
    });
  };
};
  
export const updateHolidaySection = (data, id, updateHandleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.DELETE_HOLIDAY_SECTION}${id}/`, data, token)
      .then((res) => {
        if(res.data.status_code === 200){
          updateHandleSuccess()
        }
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};