import React, { useState, useEffect } from 'react';
import '../../assets/css/partials/availabilityDateRange.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    dateRangeItem
} from '../../actions/experienceBookingAction';
import { useDispatch } from 'react-redux';

const AvailibilityDateRangePopup = ({ dateRangeAvailabilityData, DoneHandler }) => {
    const [indexValue, setIndexValue] = useState(null);
    const [filtersortedDate, setFiltersortedDate] = useState([])
    
    const dispatch = useDispatch();
    useEffect(() => {
        let resDate = dateRangeAvailabilityData?.pack_schedule_data?.selectedDates.filter(d => {
            return moment(d.from, 'DD-MM-YYYY').isAfter(); ;
        });
        resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
        setFiltersortedDate(resDate)
    }, [dateRangeAvailabilityData]);

    const dateRangeClickHandler = (item, index) => {
        setIndexValue(index)
        dispatch(dateRangeItem(item));
    };
    
    return (
        <div className="card-box card-box-index guest-modal" style={{
            width: "370px", backgroundColor: "#fff", top: "30%", right: "5%", padding: "12px", boxShadow: "2px 2px 2px 2px ghostwhite",
            borderRadius: "15px", border: "2px solid #e9e7dd"
        }} >

            {filtersortedDate && filtersortedDate?.map((item, index) => (
                <div className="col-md-12 corp-content ">
                    <div className={`d-flex justify-content-between w-100 align-items-center ${indexValue === index ? 'date-rande-active': 'date-range-date'}`} style={{ marginTop: "8px" }} onClick={() => dateRangeClickHandler(item, index)}>
                        <div >
                            <p> <span>{moment(item?.from, 'DD-MM-YYYY').format('Do MMM')}</span> </p>
                            <p className="grey-text"> <span>{moment(item?.from, 'DD-MM-YYYY').format('YYYY, ddd')}</span> </p>
                        </div>
                        <div> - </div>
                        <div>
                            <p><span>{moment(item?.to, 'DD-MM-YYYY').format('Do MMM')}</span> </p>
                            <p className="grey-text"> <span>{moment(item?.to, 'DD-MM-YYYY').format('YYYY, ddd')}</span> </p>
                        </div>
                    </div>
                </div>
            ))
            }
            <div className="bnt-container">
                <button type="button" onClick={() => DoneHandler(filtersortedDate[indexValue])}>Done</button>
            </div>
        </div>
    );
};

export default AvailibilityDateRangePopup;
