import React, { useState,useEffect } from 'react'
import EditDiv from './editDiv';
import * as popularDestination from "../../actions/popularDestinationAction"
import {  useDispatch } from "react-redux";

const EditCard = ({PopularList,locationType}) => {
  const dispatch = useDispatch();
  
  const[statelist,setStateist]=useState([])
  const[countryList,setCountryList]=useState()
  
  useEffect(()=>{
    setStateist(PopularList?.filter((elem)=>{
      if( elem.location_type=="state"){
        
        return elem
      }
    }))
    setCountryList(PopularList?.filter((elem)=>{
      if( elem.location_type=="country"){
        return elem
      }
    }))

},[PopularList])
useEffect(()=>{ 
  dispatch(popularDestination.handleStateAndCountryLists())
 },[])
return (
  <>
 <EditDiv popularlists={statelist?.length>0?statelist:countryList} locationType={locationType}  />
  </>

  )
}

export default EditCard