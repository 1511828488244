import { useDispatch } from "react-redux";
import { API } from "../constants/api";
import { axiosPostWithToken, axiosPut } from "../services/apiServices";

import {
  HOLIDAY_PENDING_REVIEW_LIST,
 
  HOLIDAY_REJECTED_REVIEW_LIST,
  HOLIDAY_REVIEW_END_DATE,
  HOLIDAY_REVIEW_PAGE_ID,
  HOLIDAY_REVIEW_PUBLISHED_LIST,
  HOLIDAY_REVIEW_START_DATE,
  IS_DATA_LOADING,
} from "./types";

export const getHolidayReviewPendingList = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"pending"}],
      "sort":{"field":"modified", "order":"D"}
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.GET_HOLIDAY_REVIEW_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_PENDING_REVIEW_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getHolidayReviewRejectedList = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"rejected"}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_HOLIDAY_REVIEW_LIST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_REJECTED_REVIEW_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getHolidayReviewPublishedList = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "page_size":10,
      "filters":[{"field":"status", "value":"approved"}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_HOLIDAY_REVIEW_LIST}`, body, token)
      .then((res) => {
        console.log(res);
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_REVIEW_PUBLISHED_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getHolidayReviewsearch = (page, status, startDate, endDate) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": 1,
      "page_size":10,
      "filters":[{"field":"status", "value":status},{"field":"from", "value":startDate},{"field":"to", "value":endDate}],
      "sort":{"field":"modified", "order":"D"}
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.GET_HOLIDAY_REVIEW_LIST}`, body, token)
      .then((res) => {
        console.log(res);
        if (res.data.status_code === 200) {
          if (status == "approved") {
            dispatch({
              type: HOLIDAY_REVIEW_PUBLISHED_LIST,
              payload: res.data.data,
            });
          }else if(status=="rejected"){
            dispatch({
            type:HOLIDAY_REJECTED_REVIEW_LIST,
            payload:res.data.data

        })  } else {
            dispatch({
              type: HOLIDAY_PENDING_REVIEW_LIST,
              payload: res.data.data,
            });
          }
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const holidayReviewStatusUpdate = (
  status,
  main_pack_id,
  review_id,
  ref_id,
  refreshData
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      status: status,
      main_pack_id: main_pack_id,
      review_id: review_id,
      ref_id: ref_id,
    };
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPut(`${API.HOLIDAY_REVIEW_STATUS_UPDATE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshData();
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const myHolidayReviewStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REVIEW_START_DATE,
      payload: date,
    });
  };
};

export const myHolidayReviewEndDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REVIEW_END_DATE,
      payload: date,
    });
  };
};
export const reviewPageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REVIEW_PAGE_ID,
      payload: page,
    });
  };
};
