export const FEATURED_AMENITIES_LIST = [
    {id: 1, label: "Television", name: "television", value: false },
    {id: 2, label: "Wifi", name: "wifi", value: false },
    {id: 3, label: "Safe Locker", name: "safe_locker", value: false },
    {id: 4, label: "Restaurants", name: "restaurants", value: false },
    {id: 5, label: "Swiming Pool", name: "swimming_pool", value: false },
    {id: 6, label: "Air Conditioning", name: "air_conditioning", value: false },
    {id: 7, label: "Tea/Coffee Kettle", name: "tea_coffee_kettle", value: false },
    {id: 8, label: "Doctor on Call", name: "doctor_on_call", value: false },
  ]
  export const ROOM_AMENITIES_LIST = [
    {id: 1, label: "Conference room", name: "conference_room", value: false },
    {id: 2, label: "Nature walks", name: "nature_walks", value: false },
    {id: 3, label: "Spa and Fitness center", name: "spa_and_fitness_center", value: false },
    {id: 4, label: "Indoor/Outdoor games", name: "indoor_outdoor_games", value: false },
    {id: 5, label: "Wildlife area", name: "wildlife_area", value: false },
  ]
  export const RESORT_FACILITIES_LIST = [
    {id: 1, label: "Sit-out/Balcony", name: "sit_out_balcony", value: false },
    {id: 2, label: "Room Service", name: "room_service", value: false },
    {id: 3, label: "Tolietries, hair dryer", name: "toiletries_hair_dryier", value: false },
  ]
  export const ACTIVITY_OPTIONS = [
    {id: 1, name: "walks_or_hikes" , label: "Walks/Hikes"}, 
    {id: 2, name:"treks" , label: "Treks"}, 
    {id: 3, name:"mountaineering" , label: "Mountaineering"}, 
    {id: 4, name:"camping" , label: "Camping"}, 
    {id: 5, name:"cycling" , label: "Cycling"}, 
    {id: 6, name:"kayaking" , label: "Kayaking"}, 
    {id: 7, name:"rafting(river/WW)" , label: "Rafting (River/WW)"}, 
    {id: 8, name:"leisure_or_nature" , label: "Leisure / Nature"}, 
    {id: 9, name:"scuba_diving" , label: "Scuba Diving"}, 
    {id: 10, name:"biking" , label: "Biking"}, 
    {id: 11, name:"pilgrimage" , label: "Pilgrimage"}, 
    {id: 12, name:"water_sports" , label: "Water Sports"}, 
    {id: 13, name:"adventure" , label: "Adventure"}, 
  ]
    
  export const ACTIVITY_LEVEL_OPTIONS = [
    {id: 1, name: "Leisurely" , label: "Leisurely"},
    {id: 2, name: "Moderate" , label: "Moderate"},
    {id: 3, name: "Challenging" , label: "Challenging"},
    {id: 4, name: "Strenuous" , label: "Strenuous"}
  ]