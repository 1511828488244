import {
    GET_CALLBACK_LIST
 } from "../actions/callBackAction";
  
    const initialState = {
   call_back_list:[],

    };
  
    const callBackReducer = (state = initialState, { type, payload }) => {
      if (type === GET_CALLBACK_LIST) {
        return {
          ...state,
          call_back_list: payload,
        };
      } else {
        return state; // Return the state as is for other action types
      }
    };
  
      export default callBackReducer