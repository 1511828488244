import React, { useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing';
import SearchBar from '../../../common/commonSearchBar';
import { holidaysConfirmedStatusValues } from '../../../resources/status';
import { useDispatch, useSelector } from 'react-redux';
import { getHolidayBookingList, holidayBookingsSearch ,holidayBookingStatusUpdate, pageId, holidayBookingSearchValue} from '../../../actions/holidayBookingAction';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index';
import ClosedModal from '../requests/closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';
const Confirmed = () => {
    const [ showMoreInfo, setShowMoreInfo ] = useState(false);
    const [ moreInfoData, setMoreInfoData ] = useState({});
    const [ pageVal, setPageVal ] = useState(1);
    const [status, setStatus] = useState('');
    const [id, setId] = useState(null);
    const[iscopr,setIscorp]=useState()
    const [ statusChangeBool, setStatusChangeBool ] = useState(false);
    const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);

    const holidayConfirmed = useSelector(state => state.holidayBooking.holidayConfirmed.results);
    const totapPage = useSelector(state => state.holidayBooking.holidayConfirmed.total_pages);
    const pageNum = useSelector(state => state.holidayBooking.page);
    const sectionID = useSelector(state => state.holidayBooking.bookingsSecId);
    const holidayConfirmNxtPgeId = useSelector(state => state.holidayBooking.holidayConfirmNxtPgeId);
    const holidayConfirmCurrentPgeId = useSelector(state => state.holidayBooking.holidayConfirmCurrentPgeId);
    const searchVal = useSelector(state => state.holidayBooking.holidayConfirmedSearchVal);
    const ffStartDate = useSelector((state) => state.holidayBooking.holidayBookingStartDate);
    const ffEndDate = useSelector((state) => state.holidayBooking.holidayookingEndDate);
    const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    const guestOpen = useSelector((state) => state.experienceBooking.guestModalOpenTrue);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMoreInfo]);


    useEffect(()=>{
        const data=holidayConfirmed?.map((elem)=>{
            return (parseInt(elem.amount_paid))
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(Math.round(totalamount/holidayConfirmed?.length))

    },[holidayConfirmed])

    useEffect(() => {
        if(holidayConfirmNxtPgeId === null && holidayConfirmCurrentPgeId > 1 && holidayConfirmed?.length === 0) {
            dispatch(getHolidayBookingList(1, sectionID, "confirmed"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [holidayConfirmed, pageVal])
    
    const handlePagination = (e, value) => {
        // setPageVal(value);
        if(searchVal !=="" || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayBookingsSearch(value, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(pageId(value));
        }else{
            dispatch(getHolidayBookingList(value, sectionID, "confirmed"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };

    const handleChange = (event) => {
        dispatch(holidayBookingsSearch(1, 'confirmed', event.target.value, sectionID, ffStartDate, ffEndDate));
        dispatch(holidayBookingSearchValue(event.target.value, "confirmed"))
        dispatch(pageId(1));
    };

    const refreshHolidayConfirmed = () => {
        if(searchVal !== '') {
            dispatch(holidayBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
        }else if(ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(holidayBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
        } else {
            dispatch(getHolidayBookingList(pageNum, sectionID, "booked"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"))
        }
    };

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;
        setStatusChangeBool(true)
        setStatus(status);
        setId(id);
        setIscorp(corporate)
        if(status === "cancelled")setRequestModal(true);
        else{

            dispatch(holidayBookingStatusUpdate(status, id, corporate, sectionID,refreshHolidayConfirmed));
            // dispatch(getHolidayBookingList(pageNum, sectionID, "cancelled"));
        }

    };

    const holidayBookingDisplayData = holidayConfirmed && holidayConfirmed.map(data => (
        <AdminListing 
            packTitle={data.pack_title}
            address={data?.sec_name  && data?.sec_name == "International" ? data.location_country : data.address}
            amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.from} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            isHolidayBooking={true}
            value={data.booking_status}
            corporate={data.corporate}
            id={data.id}
            data={data}
            messageTitle={data.special_request}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusChangeHandler={statusChangeHandler}
            statusValues={holidaysConfirmedStatusValues}
            onClick={() => moreInfoHandler(data)}
            voucher_info={data?.voucher_info}
        />
    ));
    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
            dispatch(holidayBookingStatusUpdate(status, id, iscopr, sectionID, closeRequestMessage,refreshHolidayConfirmed))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      
      };

    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    }
    
    return (
        <>
        <SearchBar
            searchVal={searchVal}
            handleChange={handleChange}
        />
        {holidayConfirmed && holidayConfirmed?.length === 0 && 
             <div className="enquiry-card-holder">No Record Found!</div>
        }

      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
        {holidayBookingDisplayData}
        {showMoreInfo && 
          <CommonMoreInfoModel 
          open={showMoreInfo}
          modalClose={() => setShowMoreInfo(false)}
          moreInfoData={moreInfoData}
          isCancellationMessage={false}
          />
        }
        <Pagination 
            totalPage={totapPage} 
            page={pageNum} 
            onChange={(event, value) => handlePagination(event, value)}
        />
                {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Confirmed;
