import React,{useEffect, useState} from 'react'
import CommonConfirmationPopup from '../commonConfirmationPopup'

const PackTypeButtons = ({state, setState, label, data, fromAdd, isFlexiSchedule, disable}) => {

    const [showModal, setShowModal] = useState(false)
    const [packType, setPackType] = useState("")
    
    useEffect(() => {
        if(state.package_type == "staybased"){
            setPackType("itinerary")
        }else{
            setPackType("staybased")
        }
    }, [state])
    
    const handlePackageType = (val) => {
        if(state?.flexi_schedule || state?.schedule_type == "recurring" || state?.schedule_type == "specific_dates"){
            setState({
                ...state,
                package_type: val,
                schedule_type: "",
                schedule_data: "",
                flexi_schedule: false
            })
        }else{ 
            setState({
                ...state,
                package_type: val
            })
        }

        if(showModal){
            setShowModal(false)
        }
    }

    
  return (
    <div className="pack-category">
        {label &&<p>{state?.package_type ? "Package type" : "Please select package type"}</p>}
        <div className="pack-category-buttons" style={disable ? {display: "block"} : {}}>
        <div
            className={state?.package_type == "staybased" ? "active" : ""}
            style={state?.package_type == "itinerary" && disable ? {display: 'none'} : {}}
            onClick={state?.package_type && state?.package_type !== "staybased" && !fromAdd ? () => setShowModal(true) : () => handlePackageType("staybased")}>Stay Based
        </div>
        <div
            className={state?.package_type == "itinerary" ? "active" : ""}
            style={state?.package_type == "staybased" && disable ? {display: 'none'} : {}}
            onClick={state?.package_type && state?.package_type !== "itinerary" && !fromAdd   ? () => setShowModal(true) : () => handlePackageType("itinerary")}>Itinerary Based
        </div>
        </div>
        <CommonConfirmationPopup 
        open={showModal}
        heading="Confirm"
        content={`${`Are you sure you want to move this package to`} ${packType}`}
        btnOneText="Cancel"
        btnTwoText="Confirm"
        handleClose={() => setShowModal(false)}
        handleClick={() => handlePackageType(packType)}
        />
    </div>
  )
}

export default PackTypeButtons