import React, { useEffect, useState } from "react";

import Datefilter from "../../../common/commonDateFIlter";
import DropdownFilter from "./dropDownFilter";
import { Tabs } from "antd";
import Pending from "./pending";
import Published from "./published";

import { useDispatch, useSelector } from "react-redux";
import {
  getHolidayReviewPublishedList,
  getHolidayReviewRejectedList,
  getHolidayReviewsearch,
  myHolidayReviewEndDate,
  myHolidayReviewStartDate,
} from "../../../actions/holidayReviewAction";
import moment from "moment";
import Rejected from "./rejected";
import { Spinner } from "react-bootstrap";

const { TabPane } = Tabs;
const HolidayReviewsIndex = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const HolidayPendingReviews = useSelector(
    (state) => state.holidayReviews?.holidayReviewList
  );
  const HolidayReviewsPublished = useSelector(
    (state) => state.holidayReviews?.holidayPublishedReviewList
  );
  const HolidayRejectedReviews = useSelector(
    (state) => state.holidayReviews?.holidayRejectedReviewList
  );
  const totalHolidayPendingReviews =
    HolidayPendingReviews && HolidayPendingReviews?.review_data?.total_results;
  const totalHolidayRejectedReviews =
    HolidayRejectedReviews &&
    HolidayRejectedReviews?.review_data?.total_results;
  const ffEndDate = useSelector(
    (state) => state.holidayReviews.holidayReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.holidayReviews.holidayReviewStartDate
  );
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const totalHolidayPublishedReviews =
    HolidayReviewsPublished &&
    HolidayReviewsPublished?.review_data?.total_results;
  const handleStartDateChange = (date) => {
    dispatch(myHolidayReviewStartDate(moment(date).format("YYYY-MM-DD")));
  };
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);
  const handleEndDateChange = (date) => {
    dispatch(myHolidayReviewEndDate(moment(date).format("YYYY-MM-DD")));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayReviewStartDate(null));
  };

  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayReviewEndDate(null));
  };
  useEffect(() => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(getHolidayReviewsearch(1, status, ffStartDate, ffEndDate));
    } else if (ffEndDate === null && ffStartDate === null) {
      dispatch(getHolidayReviewPublishedList(1));
      dispatch(getHolidayReviewRejectedList(1));
    }
  }, [ffEndDate, ffEndDate, status]);

  const handleTabChange = (key) => {
    if (key === "2") {
      setStatus("approved");
    } else if (key === "3") {
      setStatus("rejected");
    } else {
      setStatus("pending");
    }
  };
  return (
    <>
      <div style={{ position: "absolute", right: 50 }}>
        <Datefilter
          status={status}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          ffStartDate={ffStartDate}
          ffEndDate={ffEndDate}
          handleStartClear={handleStartClear}
          handleEndClear={handleEndClear}
        />
      </div>
      <div className="enquiry-tabs-holder">
        <div style={{ display: "flex" }}>
          <p className="title-holder">Reviews</p>
        </div>
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <TabPane tab={`${totalHolidayPendingReviews} Pending`} key="1">
            <Pending />
          </TabPane>
          <TabPane tab={`${totalHolidayPublishedReviews} Published`} key="2">
            <Published />
          </TabPane>
          <TabPane tab={`${totalHolidayRejectedReviews} Rejected`} key="3">
            <Rejected />
          </TabPane>
        </Tabs>
        {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      </div>
    </>
  );
};

export default HolidayReviewsIndex;
