import StoryForm from '../../gifts/myFamilyFirst/storyForm';
import { useDispatch } from 'react-redux';
import * as weddingAction from '../../../actions/weddingAction'
import {useState} from 'react';
import { useSnackbar } from "notistack";

const NewStory = ({setIsAdd})=>{
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const initErrors = {
        title: {
            hasError:false,
            message:''
        },
        media: {
            hasError:false,
            message:''
        },
        description: {
            hasError:false,
            message:''
        }
    }
    const [errors, setErrors] = useState(initErrors);

    const handleSuccess = ()=>{
        
        enqueueSnackbar("Story Added Successfully", {
            variant: "success",
          });
        setIsAdd(false);
        dispatch(weddingAction.weddingStoryList());
    };
    const changeError = (name, hasError, message)=>{
        setErrors({
            ...errors,
            [name]: {
                hasError,
                message
            }
        });
    };
    const resetErrors = ()=>{
        setErrors(initErrors);
    };
    const handleForm = (formDetails)=>{ 
        console.log(formDetails);
        if(!formDetails.title){ 
            
            changeError('title', true, 'Please enter Title');
            return false;
        }
        console.log(formDetails.title.length);
        if(formDetails.title.length>75){ 
            
            changeError('title', true, 'Package title should be less than 75 characters');
            return false;
        }
        if(!formDetails.media.length){ 
            
            changeError('media', true, 'Please select atleast one image');
            return false;
        }
       
        
        if(!formDetails.description){ 
            
            changeError('description', true, 'Please enter Description');
            return false;
        }
        let submitData = {
            title:formDetails.title,
            description:formDetails.description,
            media:formDetails.media.map((img, index)=>{
                return {
                    image:img.image || '',
                    video:img.video || '',
                    thumbnail:img.thumbnail,
                    priority:index+1,
                    media_type:img.media_type,
                    alt_text:img.alt_txt,
                    title_text:img.title_txt
                }
            })
        }
        dispatch(weddingAction.newStory(submitData, handleSuccess))

    }
    return (
        <div>
            <StoryForm type="Add" errors={errors} resetErrors={resetErrors} handleForm={handleForm} fromWeddings={true}/>
        </div>
    )
};

export default NewStory;
