import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageItem from "./imageItem";
import "react-image-crop/lib/ReactCrop.scss";
import ImageCropper from "../../components/utilities/ImageCropper";
import * as ExperiencesAction from "../../actions/experienceListingAction";
import * as myFamilyFirstAction from '../../actions/myFamilyFirstAction'
import * as EventsAction from "../../actions/eventsAction";
import * as HolidaysAction from "../../actions/holidaysAction";
import * as corpListingAction from "../../actions/corpListingAction";
import * as WeddingAction from "../../actions/weddingAction";
import * as HoneymoonAction from "../../actions/honeymoonAction";
import * as AnniversaryAction from "../../actions/anniversaryAction";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AltTitleModal from "../../components/utilities/ImageCropper/AltTitleModal";

export default function ImageMultiSelect({
  open,
  toggleModal,
  images,
  title,
  city,
  handleCloseDone,
  handleUpdatePackageImage,
  isAddNewEvent,
  isUpdateEvent,
  moduleType,
  sectionName,
  fromMyFamilyFirst,
  fromWeddings,
  fromHoneyMoon,
  fromAnniversary
}) {
  const handleClose = () => {
    const uploadingVideos = imageArray.filter(img => img.media_type === 'uploading');

    if (uploadingVideos.length) {
      setVideoUploadingError(true);
      return false;
    }
    toggleModal(false);
  };
  const dispatch = useDispatch();

  const eventImages = useSelector((state) => state.eventsListing.allImagesEventsData);
  const experienceImages = useSelector((state) => state.experiencesListing.allExperiencesImagesData);
  const holidayImages = useSelector((state) => state.holidayListing.allImagesHolidayData);
  const corporateImages = useSelector((state) => state.corpListing.allImagesData);
  const isAddAnotherPackage = useSelector((state) => state.holidayListing.isAddanotherPackage);


  const [imageArray, setImageArray] = useState(images);
  const [newThumbnailError, setNewThumbnailError] = useState(false);
  const [linkType, setLinkType] = useState(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkError, setLinkError] = useState({ hasError: false, msg: '' });
  const [uploadProgress, setUploadProgress] = useState({});
  const [hasOneImageError, setHasOneImageError] = useState(false);
  const [videoUploadingError, setVideoUploadingError] = useState(false);
  const [draggingId, setDraggingId] = useState(null);
  const [imageTitle, setImageTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [addImage, setAddImage] = useState([]);
  const[alt_title_modal,set_Alt_title_modal]=useState(false)
  const[imageset,setImageSet]=useState(false)

  useEffect(() => {
    // handleUpdatePackageImage(addImage);
    if (imageArray.length === 1) {
      // imageArray[0].thumbnail = true;
    }
    if (moduleType === 'event') {
      setAddImage(eventImages)
    } else if (moduleType === 'experience') {
      setAddImage(experienceImages)
    } else if (moduleType === 'holidays') {
      setAddImage(holidayImages)
    } else if (moduleType === 'corporate') {
      setAddImage(corporateImages)
    }
  }, [eventImages, experienceImages, holidayImages, corporateImages]);

  useEffect(() => {
    setImageArray(images);
  }, [images]);

  if (imageArray.length === 1) {
    // imageArray[0].thumbnail = true;
  }

  const handleThumbnail = (id) => {
    let tempArrayOfImages = imageArray;
    let currThumbIndex = tempArrayOfImages.findIndex(
      (item) => item.thumbnail === true
    );
    if (currThumbIndex !== -1) {
      tempArrayOfImages[currThumbIndex].thumbnail = false;
      let objIndex = tempArrayOfImages.findIndex((obj) => obj.id === id);
      tempArrayOfImages[objIndex].thumbnail = true;
      setImageArray([...tempArrayOfImages]);
    } else {
      let objIndex = tempArrayOfImages.findIndex((obj) => obj.id === id);
      tempArrayOfImages[objIndex].thumbnail = true;
      setImageArray([...tempArrayOfImages]);
    }
  };

  const handleDeleteImage = (id) => {
    
    resetErrors();
    // debugger;
    if (isAddNewEvent || imageArray.filter((item) => item.id === id)[0].newEntry) {
      let tempArrayOfImages = imageArray;
      let filteredArray = tempArrayOfImages.filter((item) => item.id !== id);
      setImageArray([...filteredArray]);

      uploadProgress[id] && uploadProgress[id].cancelTokenSource.cancel();

      dispatch(EventsAction.handleAllEventsImages(filteredArray));
    } else if (isUpdateEvent) {
      let res = imageArray.filter((item) => item.id !== id);
      setImageArray(res);
      if (moduleType === 'event') {
        if(fromMyFamilyFirst){
          dispatch(EventsAction.handleAllEventsImages(res));
          dispatch(myFamilyFirstAction.deleteListingStoryEditImg(id));
        }else if(fromWeddings){
          dispatch(EventsAction.handleAllEventsImages(res));
          dispatch(WeddingAction.deleteWeddingImage(id));
        }else if(fromHoneyMoon){
          dispatch(EventsAction.handleAllEventsImages(res));
          dispatch(HoneymoonAction.deleteHoneymoonImage(id));
        }else if(fromAnniversary){
          dispatch(EventsAction.handleAllEventsImages(res));
          dispatch(AnniversaryAction.deleteAnniversaryImage(id));
        }else{
          dispatch(EventsAction.handleAllEventsImages(res));
          dispatch(EventsAction.deleteListingEventEditImg(id));
        }
      } else if (moduleType === 'experience') {
        dispatch(ExperiencesAction.handleAllImages(res));
        dispatch(ExperiencesAction.imageDelete(id));
      } else if (moduleType === 'holidays') {
        dispatch(HolidaysAction.handleAllImages(res));
        dispatch(HolidaysAction.deleteListingHolidayEditImg(id));
      } else if (moduleType === 'corporate') {
        dispatch(corpListingAction.handleAllImages(res));
        dispatch(corpListingAction.imageDelete(id));
      }
    }
  };

  const [file, setFile] = useState("");
  const fileRef = useRef();
  const cancelUpload = (cancelTokenSource) => {
    cancelTokenSource.cancel();
  };
  const getUploadProgress = (id, uploadProgress, cancelTokenSource) => {
    // console.log(cancelTokenSource);
    // console.log(uploadProgress)
    // console.log(cancelTokenSource);
    // debugger;
    // console.log({...uploadProgress, ...cancelTokenSource});
    setUploadProgress({ [id]: { uploadProgress, cancelTokenSource }, });

  };
  const resetErrors = () => {
    setLinkUrl('');
    setLinkType('');
    setLinkError({
      hasError: false,
      msg: ''
    });
    setNewThumbnailError(false);
    setHasOneImageError(false);
    setVideoUploadingError(false);
  };
  const handleAddImageChange = (croppedFile) => {
    setFile(croppedFile);
    console.log(imageArray);
    if (imageArray.filter((imgFile) => imgFile.media_type === 'uploading').length) {
      setVideoUploadingError(true);
      return;
    }

    // return ;
    console.log(croppedFile);
    resetErrors();
    if (croppedFile && croppedFile.type.split('/')[0] === 'image') {


      if (moduleType === 'event') {
        if(fromWeddings){
          dispatch(WeddingAction.weddingImageUpload(croppedFile, addNewPick));
        }else if(fromHoneyMoon){
          dispatch(HoneymoonAction.honeymoonImageUpload(croppedFile, addNewPick));
        }else if(fromAnniversary){
          dispatch(AnniversaryAction.anniversaryImageUpload(croppedFile, addNewPick));
        }else{
          dispatch(EventsAction.imageUploadEvents(croppedFile, addNewPick));
        }
      } else if (moduleType === 'experience') {
        dispatch(ExperiencesAction.imageUpload(croppedFile, addNewPick));
      } else if (moduleType === 'holidays') {
        dispatch(HolidaysAction.imageUploadHolidays(croppedFile, addNewPick));
      } else if (moduleType === 'corporate') {
        dispatch(corpListingAction.imageUpload(croppedFile, addNewPick));
      }
    } else if (croppedFile && croppedFile.type.split('/')[0] === 'video') {
      console.log('this is video file');
      if (isAddNewEvent) {
        // let newID = imageArray?.length === 0 ? 1 : imageArray.length + 1;
        let newID = new Date().getTime();
        let obj = {
          id: newID,
          name: croppedFile?.name || "anonymus",
          image: "",
          video: "",
          title_txt:imageTitle,
          alt_txt:altText,
          media_type: 'uploading',
          thumbnail: false,
          progress: null,
          priority: new Date().getTime()
        };
        setImageArray([...imageArray, obj]);
        let imgArr = [...imageArray, obj];
        if (moduleType === 'event') {
          if(fromWeddings){
            dispatch(WeddingAction.videoUploadWedding(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else if(fromHoneyMoon){
            dispatch(HoneymoonAction.videoUploadHoneymoon(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else if(fromAnniversary){
            dispatch(AnniversaryAction.videoUploadAnniversary(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else{
            dispatch(EventsAction.videoUploadEvents(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }
        } else if (moduleType === 'experience') {
          dispatch(ExperiencesAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        } else if (moduleType === 'holidays') {
          dispatch(HolidaysAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        } else if (moduleType === 'corporate') {
          dispatch(corpListingAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        }

      }
      if (isUpdateEvent) {
        // let newID = imageArray?.length === 0 ? 1 : imageArray.length + 1;
        let newID = new Date().getTime();
        let obj = {
          id: newID,
          name: croppedFile?.name || "anonymus",
          image: "",
          video: "",
          media_type: 'uploading',
          thumbnail: false,
          isUpdate: true,
          progress: null,
          newEntry: true,
          title_txt:imageTitle,
          alt_txt:altText,
        };
        console.log(obj)
        // return ;
        setImageArray([...imageArray, obj]);

        // dispatch(EventsAction.handleAllEventsImages([[...imageArray, obj], obj]));
        console.log(getUploadProgress);
        let imgArr = [...imageArray, obj];
        if (moduleType === 'event') {
          if(fromWeddings){
            dispatch(WeddingAction.videoUploadWedding(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else if(fromHoneyMoon){
            dispatch(HoneymoonAction.videoUploadHoneymoon(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else if(fromAnniversary){
            dispatch(AnniversaryAction.videoUploadAnniversary(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }else{
            dispatch(EventsAction.videoUploadEvents(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
          }
        } else if (moduleType === 'experience') {
          dispatch(ExperiencesAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        } else if (moduleType === 'holidays') {
          dispatch(HolidaysAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        } else if (moduleType === 'corporate') {
          dispatch(corpListingAction.videoUpload(croppedFile, addVideo, obj, imgArr, getUploadProgress, newID));
        }

      }
    }
  }
  const addVideo = (data, newObj, newArr) => {
    // return;
    let nObj = newObj;
    nObj.video = data?.url;
    nObj.media_type = 'video';
    let tempImgArray = [...newArr];
    console.log('object');
    setVideoUploadingError(false);
    tempImgArray[tempImgArray.findIndex(ar => ar.id === nObj.id)].video = data?.url;
    tempImgArray[tempImgArray.findIndex(ar => ar.id === nObj.id)].isUploading = false;
    tempImgArray[tempImgArray.findIndex(ar => ar.id === nObj.id)].priority = nObj.id + 1;

    setImageArray(tempImgArray);
    if (moduleType === 'event') {
      dispatch(EventsAction.handleAllEventsImages(tempImgArray));
    } else if (moduleType === 'experience') {
      dispatch(ExperiencesAction.handleAllImages(tempImgArray));
    } else if (moduleType === 'holidays') {
      dispatch(HolidaysAction.handleAllImages(tempImgArray));
    } else if (moduleType === 'corporate') {
      dispatch(corpListingAction.handleAllImages(tempImgArray));
    }

  };
  const addNewPick = (data) => {
    if (isAddNewEvent) {
      let obj = {
        // id: imageArray?.length === 0 ? 1 : imageArray.length + 1,
        id: new Date().getTime(),
        name: file?.name || "anonymus",
        image: data?.url,
        title_txt:imageTitle,
        alt_txt:altText,
        media_type: 'image',
        thumbnail: false,
        priority: new Date().getTime()
      };
      setImageArray([...imageArray, obj]);

      if (moduleType === 'event') {
        dispatch(EventsAction.handleAllEventsImages([...imageArray, obj]));
      } else if (moduleType === 'experience') {
        dispatch(ExperiencesAction.handleAllImages([...imageArray, obj]));
      } else if (moduleType === 'holidays') {
        dispatch(HolidaysAction.handleAllImages([...imageArray, obj]));
      } else if (moduleType === 'corporate') {
        dispatch(corpListingAction.handleAllImages([...imageArray, obj]));
      }
    } if (isUpdateEvent) {
      let obj = {
        // id: imageArray?.length === 0 ? 1 : imageArray.length + 1,
        id: new Date().getTime(),
        name: file?.name || "anonymus",
        image: data?.url,
        thumbnail: false,
        media_type: 'image',
        isUpdate: true,
        newEntry: true,
        title_txt:imageTitle,
        alt_txt:altText,
        priority: new Date().getTime()
      };
      console.log(imageArray);
      setImageArray([...imageArray, obj]);
      if (moduleType === 'event') {
        dispatch(EventsAction.handleAllEventsImages([...imageArray, obj]));
      } else if (moduleType === 'experience') {
        dispatch(ExperiencesAction.handleAllImages([...imageArray, obj]));
      } else if (moduleType === 'holidays') {
        dispatch(HolidaysAction.handleAllImages([...imageArray, obj]));
      } else if (moduleType === 'corporate') {
        dispatch(corpListingAction.handleAllImages([...imageArray, obj]));
      }
    }
  };

  const thumbnailError = () => {
    setVideoUploadingError(false);
    setNewThumbnailError(false);
    setHasOneImageError(false);
    console.log(imageArray);
    const uploadingVideos = imageArray.filter(img => img.media_type === 'uploading');
    console.log(uploadingVideos);
    // return;
    if (uploadingVideos.length) {
      setVideoUploadingError(true);
      return false;
    }
    let count = 0;
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i].thumbnail === false) {
        count++;
      }
    }
    if (!imageArray.filter(img => img.image !== '').length) {
      setHasOneImageError(true);
      return;
    }
    if (count === imageArray.length) {
      setNewThumbnailError(true);
      return false;
    } else {
      handleClose();
      setNewThumbnailError(false);
    }
    handleUpdatePackageImage(imageArray);
    if (moduleType === 'event') {
      dispatch(EventsAction.handleAllEventsImages([...imageArray]));
    } else if (moduleType === 'experience') {
      dispatch(ExperiencesAction.handleAllImages([...imageArray]));
    } else if (moduleType === 'holidays') {
      dispatch(HolidaysAction.handleAllImages([...imageArray]));
    } else if (moduleType === 'corporate') {
      dispatch(corpListingAction.handleAllImages([...imageArray]));
    }

  }
  const changeLinkeError = (hasError, msg) => {
    setLinkError({
      hasError,
      msg
    });
  }
  const imageURLFunction=()=>{
    const uploadingVideos = imageArray.filter(img => img.media_type === 'uploading');

    if (uploadingVideos.length) {
      setVideoUploadingError(true);
      return false;
    }
    setLinkError({
      hasError: false,
      msg: ''
    });

    if (!linkType) {
      setLinkError({
        hasError: true,
        msg: 'Pleae Select type'
      });
      return;
    } else if (!linkUrl) {
      setLinkError({
        hasError: true,
        msg: 'Pleae Enter URL'
      });
      return;
    }

    try {
      const checkurl = new URL(linkUrl);
      console.log(checkurl);
      if (linkType === "youtube" && (checkurl.host !== "youtube.com" && checkurl.host !== "www.youtube.com")) {
        changeLinkeError(true, "Invalid Youtube URL");
        return false;
      } else if (linkType === "other" && (checkurl.host !== "vimeo.com" && checkurl.host !== "www.vimeo.com")) {
        changeLinkeError(true, "Invalid Vimeo URL");
        return false;
      }
      let mediaObj = {
        "id": new Date().getTime(),
        "image": "",
        "video": "",
        "thumbnail": false,
        "media_type": linkType,
        "isUpdate": true,
        "title_txt":imageTitle,
        "alt_txt":altText,
        "priority": new Date().getTime()
      };
      if (linkType === "image") {
        mediaObj.image = linkUrl;
      } else {
        mediaObj.video = linkUrl;
      }
      console.log(mediaObj)
      setLinkUrl('');
      setLinkType('');
      if (moduleType === 'event') {
        dispatch(EventsAction.handleAllEventsImages([...imageArray, mediaObj]));
      } else if (moduleType === 'experience') {
        dispatch(ExperiencesAction.handleAllImages([...imageArray, mediaObj]));
      } else if (moduleType === 'holidays') {
        dispatch(HolidaysAction.handleAllImages([...imageArray, mediaObj]));
      } else if (moduleType === 'corporate') {
        dispatch(corpListingAction.handleAllImages([...imageArray, mediaObj]));
      }
      setImageArray([...imageArray, mediaObj]);


    } catch (_) {
      console.error('Invalid URL');

      changeLinkeError(true, "Invalid URL");
      return false;
    }

  }
  const addFromLink = () => {
    set_Alt_title_modal(true)
};
useEffect(()=>{
  if(imageset===true){
    imageURLFunction()
  }
},[imageset])

  const dragOverFun = (ev) => {
    ev.preventDefault();

  };
  const handleDrop = (ev) => {
    console.log(ev.currentTarget.id);
    console.log(`dragging from ${draggingId} to ${ev.currentTarget.id}`);
    console.log(imageArray);
    let tmpimageArray = [...imageArray];
    let selectedObj = tmpimageArray[draggingId - 1];
    tmpimageArray.splice(draggingId - 1, 1);
    tmpimageArray.splice(ev.currentTarget.id - 1, 0, selectedObj);
    // priority
    tmpimageArray.map((item, index) => { item.priority = index + 1 });
    console.log(tmpimageArray);
    setImageArray(tmpimageArray);

    console.log(draggingId)
    console.log(ev.currentTarget.id);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {imageArray?.length} Images{" "}
          <span style={{ color: "gray" }}>for {title}</span>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: "3%",
            }}
            onClick={handleCloseDone}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ color: "gray" }}>
            {/* <span style={{ fontSize: 12, paddingRight: 15 }}>Maximum 5 images/ 4 videos & 1 Image</span> */}
            <span style={{ fontSize: 12, paddingRight: 15 }}>{sectionName!=="Gift Voucher" ? 'Maximum 5 images/ 4 videos & 1 Image': 'Maximum 5 images'}</span>
            {newThumbnailError && <p style={{ color: "red", fontSize: 12 }}>Please select a thumbnail</p>}
            {hasOneImageError && <p style={{ color: "red", fontSize: 12 }}>Please add atleast one image</p>}
            {videoUploadingError && <p style={{ color: "red", fontSize: 12 }}>Please wait... video is uploading...</p>}
          </div>
        </DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {imageArray &&
              imageArray.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority)).map((item, index) => {
                return (
                  <div
                    draggable={true}
                    id={index + 1}
                    onDragOver={dragOverFun}
                    onDragStart={(val) => { setDraggingId(val.currentTarget.id) }}
                    onDrop={handleDrop}
                    key={`image-${index}`}
                    style={{ width: '33%' }}
                  >
                    {item.media_type !== 'uploading' && <span className="draggable-icon"><DragHandleIcon /></span>}
                    <ImageItem
                      isAddAnotherPackage={isAddAnotherPackage}
                      index={index}
                      data={item}
                      handleThumbnail={handleThumbnail}
                      uploadProgress={uploadProgress}
                      handleDeleteImage={() => handleDeleteImage(item.id)}
                    />
                  </div>

                );
              })}
            {imageArray.length < 5 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                  marginBottom: 10,
                  marginTop: 24,
                  height: 200,
                  width: "32%",
                  borderRadius: 10,
                  background: "#ccc",
                  paddingTop: "50px"
                }}
              >
                <div className="row">
                  <ImageCropper
                    imageArray={imageArray.length}
                    fileRef={fileRef}
                    altText={altText}
                    setAltText={setAltText}
                    imageTitle={imageTitle}
                    setImageTitle={setImageTitle}
                    onChange={handleAddImageChange}
                    cropOptions={{ aspect: 5 / 3, maxZoom: 10 }}
                    outputOptions={{ maxWidth: 1800, maxHeight: 1800 }}
                    previewOptions={{ width: 1500, height: 900 }}
                    moduleType={moduleType}
                    sectionName={sectionName}
                    buttonStyle={{
                      width: 90,
                      height: 20,
                      backgroundColor: "lightgrey",
                      fontSize: 12,
                      textTransform: "capitalize",
                    }}
                  />
                </div>

                {/* {sectionName!=="International" && sectionName!=="Gift Voucher" &&  */}
                {sectionName!=="Gift Voucher" && 
                <div className="row m-2 justify-content-center align-items-center" >
                  <div style={{ fontSize: 10 }}>Or Public file URL/Youtube/Vimeo Link </div>
                  <div className="row px-4">
                    <div className="col  p-0" style={{ flex: 2 }}>
                      <select className="form-control px-1" value={linkType} onChange={(e) => setLinkType(e.target.value)} style={{ fontSize: 10 }}>
                        <option value="">Type</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="youtube">Youtube</option>
                        <option value="other">Vimeo</option>
                      </select>
                    </div>
                    <div className="col  p-0" style={{ flex: 5 }}>
                      <input type="text" placeholder="Image/Video URL" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} className="form-control" style={{ fontSize: 10 }} />
                    </div>
                    <div className="col p-0 pt-1" style={{ flex: 1 }}>
                      <button className="btn btn-primary p-0" style={{ fontSize: 10 }} onClick={addFromLink}><ArrowForwardIcon style={{ fontSize: 20 }} /></button>
                    </div>
                  </div>
                  <p style={{ color: 'red', fontSize: 10, paddingTop: 5 }}>{linkError.hasError && linkError.msg}</p>
                </div>
                 } 
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={thumbnailError}
            handleThumbnail={handleThumbnail}
            style={{
              backgroundColor: "#18a0fb",
              color: "#fff",
              textTransform: "none",
            }}
          >
            Done
          </Button>
        </DialogActions>
        <AltTitleModal
          show={alt_title_modal}
          set_Alt_title_modal={set_Alt_title_modal}
          altText={altText}
          setAltText={setAltText}
          imageTitle={imageTitle}
          setImageTitle={setImageTitle}
          setImageSet={setImageSet}
        />
      </Dialog>
    </div>
  );
}
