import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperWidthMd: {
        // maxWidth: '500px',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
// const Dialog = withStyles((theme) => ({
//     paperWidthXs: {
//         maxWidth: '400px',
//     },
// }))(MuiDialogPaperWidthXs);
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function PointsPopup({ updatePointsData, currentPoint, handleClose, userName, openPopup }) {
    const [open, setOpen] = React.useState(false);
    const [points, setPoints] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handlePointsChange = (event) => {
        const points = event.target.value
        setPoints(points)
    }
    useEffect(() => {
        setPoints("")
    }, [openPopup])

    return (
        <div >
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openPopup}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    style={{ width: '90%' }}
                >
                    Update Points for {userName}
                </DialogTitle>
                <div style={{ backgroundColor: 'white', marginTop: '10px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                    <label for="">Current Balance</label>
                    <h6>{currentPoint}&nbsp;pts</h6>
                    <label style={{ marginTop: '16px', fontSize: '12px', fontWeight: 600 }}>New Points</label>
                    <input
                        type="number"
                        placeholder="Points"
                        value={points}
                        min='0'
                        max='99999'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) || points?.length === 5) {
                                event.preventDefault();
                            }
                        }}
                        className="form-control"
                        onChange={handlePointsChange}
                    />
                </div>
                <DialogActions onClose style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
                    <button
                        type="button"
                        className="btn btn-primary btn-save"
                        onClick={(onClose) => updatePointsData(points, onClose)}
                    >
                        Update
                </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
