import React,{ useState, useEffect } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { holidaysRequestClosedStatusValues } from '../../../resources/status';
import { 
  closedHolidayList, 
  holidayRequestsSearch, 
  holidayRequestStatusUpdate, 
  getHolidayRequestList, 
  followUpHolidayList,
  confirmedHolidayList, 
  holidayReqSearchValue,
  pageId
} from '../../../actions/holidayRequestAction';
import { useSelector, useDispatch } from 'react-redux';
import ContactInfo from './contactInfo';
import Pagination from '../../../common/pagination';
import moment from 'moment';
import {checkNumberOfGuest } from '../../../utils/index';
 
const Closed = () => {
    const [openContactInfoModal, setOpenContactInfoModal] = useState(false)
    const [contactInfoData, setContactInfoData] = useState({});
    console.log(contactInfoData);
    const[averageBookingAmount,setAverageBookingAmount]=useState()
    const[totalbookingAmount,setTotalbookingAmount]=useState()
    const closeHoliday = useSelector(state => state.holidayRequest.closeHolidayList);
    console.log(closeHoliday);
    const totapPage = useSelector(state => state.holidayRequest.closeHolidayList.total_pages);
    const sectionName = useSelector(state => state.holidayRequest.holidayRequestSecName);
    const searchVal = useSelector(state => state.holidayRequest.holidayClosedSearchValue);
    const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
    const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
    const pageNum = useSelector(state => state.holidayRequest.page);
    const dispatch = useDispatch();

    useEffect(()=>{
        const data=closeHoliday?.results?.map((elem)=>{
            return (parseInt(elem.price===null?0:elem.price))
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(Math.round(totalamount/closeHoliday?.results.length))

    },[closeHoliday])
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [openContactInfoModal]);

    useEffect(() => {
        if(sectionName.name === undefined && 
            searchVal === "" && ffStartDate === null &&  
            ffEndDate === null && closeHoliday?.current_page > 1 && 
            closeHoliday?.next_page_id === null && closeHoliday?.results.length === 0) {
            dispatch(closedHolidayList(1, sectionName?.id));
            dispatch(pageId(1))
        }else if(sectionName.name !== undefined && closeHoliday?.current_page > 1 && 
            closeHoliday?.next_page_id === null && 
            closeHoliday?.results.length === 0){
                dispatch(holidayRequestsSearch(1, "closed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
                dispatch(pageId(1));
            }else if(searchVal !== "" && closeHoliday?.current_page > 1 && 
                closeHoliday?.next_page_id === null && 
                closeHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "closed", searchVal ,ffStartDate, ffEndDate,sectionName.name ))
            }else if(ffStartDate !== null && ffEndDate !== null && closeHoliday?.current_page > 1 && 
                closeHoliday?.next_page_id === null && 
                closeHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "closed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }else if(sectionName.name !== undefined && 
                sectionName.name !== undefined && 
                ffStartDate !== null && ffEndDate !== null && 
                closeHoliday?.current_page > 1 && 
                closeHoliday?.next_page_id === null && 
                closeHoliday?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "closed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }
    }, [closeHoliday, pageNum])


    const moreInfoModalOpenHandler = (data) => {
        setContactInfoData(data)
        setOpenContactInfoModal(true);
    };

    const handlePagination = (e,value) => {
        dispatch(pageId(value))
        if(searchVal !== "" || sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(value, "closed", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
        }else{
        dispatch(closedHolidayList(value, sectionName?.id));
        }
    };

    const handleChange = (event) => {
        dispatch(holidayRequestsSearch(1,'closed', event.target.value, ffStartDate, ffEndDate, sectionName.name))
        dispatch(holidayReqSearchValue(event.target.value, 'closed'))
    };
    
    const refreshData = () => {
        if(searchVal !== ""){
            dispatch(holidayRequestsSearch(pageNum, 'closed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(followUpHolidayList(pageNum));
        }else if( sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(pageNum, 'closed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'confirmed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'followup', searchVal, ffStartDate, ffEndDate, sectionName.name));
        }else{
            dispatch(getHolidayRequestList(pageNum));
            dispatch(followUpHolidayList(pageNum));
             dispatch(confirmedHolidayList(pageNum))
            dispatch(closedHolidayList(pageNum));
        }
    };
   
    const statusChangeHandler = (event, id, corporate, requestsType) => {
        let status = event.target.value;
        dispatch(holidayRequestStatusUpdate(status, id, requestsType,null, refreshData));
    };
 
    
    const displayCloseHolidayList = closeHoliday && closeHoliday?.results.map(data => (
        <AdminListing 
            locationsPreferred={data.locations_preferred}
            bookedOn={moment(data.created).format("Do MMMM, YYYY")}
            requestType={data.request_type}
            requestedBy={`Requested by ${data.contact_name}`}
            schedule={Array.isArray(data.schedule) ? data.schedule.map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.to} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            firstTimeTraveller={data.first_time_traveller === true? "First time traveller": ""}
            messageTitle={data.special_request}
            value={data.status}
            statusValues={holidaysRequestClosedStatusValues}
            isHolidayRequest={true}
            id={data.id}
            visaRequestType={data.request_type}
            requestsType={data.request_type}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusChangeHandler={statusChangeHandler}
           contactInfoOnClick={() => moreInfoModalOpenHandler(data)}
           isShowMessage={true}
        />
    ));

    return (
        <>
        <SearchBar
           searchVal={searchVal} 
           handleChange={handleChange}
        />
         {closeHoliday && closeHoliday?.results.length === 0 && 
             <div className="enquiry-card-holder">No Record Found!</div>
         }
                  
      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
            {displayCloseHolidayList}
            {openContactInfoModal && 
                <ContactInfo 
                open={openContactInfoModal}
                modalClose={() => setOpenContactInfoModal(false)}
                contactInfoData={contactInfoData}
                isCancellationMessage={true}
                />
            }
            <Pagination 
                totalPage={totapPage} 
                page={pageNum} 
                onChange={(event, value) => handlePagination(event, value)}
            />
        </>
    );
};

export default Closed;
