import React, { useState,useEffect } from 'react'
import AdminListing from '../../../common/adminListing'
import SearchBar from '../../../common/commonSearchBar';
import { useSelector,useDispatch } from 'react-redux';
import { holidaysRequestFollowUpStatusValues } from '../../../resources/status';
import ConfirmRequestModal from './confirmRequestModal';
import { 
    followUpHolidayList,
    confirmRequestInputVal,
    getHolidayRequestList ,
    confirmedHolidayList, 
    closedHolidayList,
    holidayRequestStatusUpdate, 
    holidayRequestStatusUpdateConfirm,
    holidayRequestsSearch,
    pageId,
    holidayReqSearchValue
} from '../../../actions/holidayRequestAction';
import moment from 'moment';
import ContactInfo from './contactInfo';
import Pagination from '../../../common/pagination';
import { checkNumberOfGuest } from '../../../utils/index';
import ClosedModal from './closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const FollowUp = () => {
    const [openContactInfoModal, setOpenContactInfoModal] = useState(false)
    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    const [requestModal, setRequestModal] = useState(false);
    const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
    const [contactInfoData, setContactInfoData] = useState({});
    const [closeRequestModalData, setCloseRequestModalData] = useState({});
    console.log(closeRequestModalData);
    const [ status, setStatus ] = useState('');
    // const [pageNum, setPageNum] = useState(1);
    const [ id, setId ] = useState(null);
    const [ requestType, setRequestType ] = useState(null);
    const holidayFollowUp = useSelector(state => state.holidayRequest.holidayFollowUpList);
    const totapPage = useSelector(state => state.holidayRequest.holidayFollowUpList.total_pages);
    const sectionName = useSelector(state => state.holidayRequest.holidayRequestSecName);
    const pageNum = useSelector(state => state.holidayRequest.page);
   const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
   const searchVal = useSelector(state => state.holidayRequest.holidayFollowupSearchValue);
   const ffEndDate = useSelector((state) => state.holidayRequest.holidayRequestEndDate);
   const ffStartDate = useSelector((state) => state.holidayRequest.holidayRequestStartDate);
   const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
   const closeRequestMessag = useSelector(state => state.holidayRequest);
   const confirm_message="as discussed on call for confirm"
   const confirmedRequestAmount=useSelector(state => state.holidayRequest.confirmedRequestAmount);
   const dispatch = useDispatch();
   
   
    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [openContactInfoModal]);

    useEffect(() => {
        if(sectionName.name === undefined && 
            searchVal === "" && ffStartDate === null &&  
            ffEndDate === null && holidayFollowUp?.current_page > 1 && 
            holidayFollowUp?.next_page_id === null && 
            holidayFollowUp?.results.length === 0) {
            dispatch(followUpHolidayList(1));
            dispatch(pageId(1));
        }else if(sectionName.name !== undefined && holidayFollowUp?.current_page > 1 && 
            holidayFollowUp?.next_page_id === null && 
            holidayFollowUp?.results.length === 0){
                dispatch(holidayRequestsSearch(1, "followup", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
                dispatch(pageId(1));
            }else if(searchVal !== "" && holidayFollowUp?.current_page > 1 && 
                holidayFollowUp?.next_page_id === null && 
                holidayFollowUp?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "followup", searchVal ,ffStartDate, ffEndDate,sectionName.name ))
            }else if(ffStartDate !== null && ffEndDate !== null && holidayFollowUp?.current_page > 1 && 
                holidayFollowUp?.next_page_id === null && 
                holidayFollowUp?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "followup", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }else if(sectionName.name !== undefined && 
                sectionName.name !== undefined && 
                ffStartDate !== null && ffEndDate !== null && 
                holidayFollowUp?.current_page > 1 && 
                holidayFollowUp?.next_page_id === null && 
                holidayFollowUp?.results.length === 0){
                dispatch(pageId(1));
                dispatch(holidayRequestsSearch(1, "followup", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            }
    }, [holidayFollowUp ]);

    const moreInfoModalOpenHandler = (data) => {
        setContactInfoData(data)
        setOpenContactInfoModal(true);
    };

    const handlePagination = (e, value) => {
        // setPageNum(value);
        if(searchVal !== "" || sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(value, "followup", searchVal ,ffStartDate, ffEndDate,sectionName.name ));
            dispatch(pageId(value));
        }else{
         dispatch(followUpHolidayList(value));
         dispatch(pageId(value));
        }
    };

    const handleChange = (event) => {
        dispatch(holidayRequestsSearch(1,'followup', event.target.value, ffStartDate, ffEndDate, sectionName.name));
        dispatch(holidayReqSearchValue(event.target.value, 'followup'));
    };

    const refreshData = () => {
        if(searchVal !== "" ){
            dispatch(holidayRequestsSearch(pageNum, 'followup', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(closedHolidayList(pageNum))
        }else if(sectionName.name !== undefined || ffStartDate !== null && ffEndDate !== null){
            dispatch(holidayRequestsSearch(pageNum, 'followup', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'confirmed', searchVal, ffStartDate, ffEndDate, sectionName.name));
            dispatch(holidayRequestsSearch(pageNum, 'closed', searchVal, ffStartDate, ffEndDate, sectionName.name));
        }else{
            dispatch(getHolidayRequestList(pageNum))
            dispatch(followUpHolidayList(pageNum))
            dispatch(confirmedHolidayList(pageNum))
            dispatch(closedHolidayList(pageNum))
        }
    };

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        console.log(data,"statuschange");
        
        setCloseRequestModalData(data)
        setConfirmedRequestModalData(data)
        let status = event.target.value;
        
        setStatus(status);
        setId(id);
        setRequestType(requestsType)
        if(status === "closed")setRequestModal(true);
        else if(status==="confirmed")setRequestModalConfirm(true)
        else{
          dispatch(holidayRequestStatusUpdate(status, id, requestsType, null, refreshData))
        }
    };  
    const confirmRequestHandler=()=>{
        setIsconfirmAmountBool(false)
       if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
        else {
          dispatch(holidayRequestStatusUpdateConfirm(status, id, requestType,confirm_message,confirmedRequestAmount, refreshData));
          setRequestModalConfirm(false)
        }
        dispatch(confirmRequestInputVal(''));
       
        
    
      }

    const closeRequestHandler = () => {
        setIsclosingMessageBool(false);
        if(closeRequestMessage === "")setIsclosingMessageBool(true);
        else {
          dispatch(holidayRequestStatusUpdate(status, id, requestType,closeRequestMessage, refreshData));
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
    };
    const notToCancel=()=>{
        setRequestModal(false)
        setRequestModalConfirm(false)
        setIsclosingMessageBool(false);

    }
    const notToConfirm=()=>{
        setRequestModal(false)
        setRequestModalConfirm(false)
        setIsconfirmAmountBool(false);
      
      }

    const displayHolidayFollowUpList  = holidayFollowUp.results && holidayFollowUp.results.map(data => (
        <AdminListing 
            locationsPreferred={data.locations_preferred}
            // bookedOn={data.created}
            bookedOn={moment(data.created).format("Do MMMM, YYYY")}
            requestType={data.request_type}
            requestedBy={`Requested by ${data.contact_name}`}
            schedule={Array.isArray(data.schedule) ? data.schedule.map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.to} ${data.schedule.to ? `to ${data.schedule.to}` : ""}` : (data.schedule ===  null ? "N/A" : data.schedule.days ))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            specialRequest={data.special_request?data.special_request:"N/A"}
            messageTitle={data.special_request}
            value={data.status}
            firstTimeTraveller={data.first_time_traveller === true? "First time traveller": ""}
            statusValues={holidaysRequestFollowUpStatusValues}
            isHolidayRequest={true}
            id={data.id}
            data={data}
            visaRequestType={data.request_type}
            requestsType={data.request_type}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusChangeHandler={statusChangeHandler}
           contactInfoOnClick={() => moreInfoModalOpenHandler(data)}
           isShowMessage={true}
        />
    ))
    return (
        <>
            <SearchBar
            //    value={searchVal} 
              searchVal={searchVal} 
               handleChange={handleChange}
            />
            {holidayFollowUp.results && holidayFollowUp.results?.length === 0 && 
              <div className="enquiry-card-holder">No Record Found!</div>
            }
            {displayHolidayFollowUpList}
            {openContactInfoModal && 
                <ContactInfo 
                    open={openContactInfoModal}
                    modalClose={() => setOpenContactInfoModal(false)}
                    contactInfoData={contactInfoData}
                    isCancellationMessage={false}
                />
            }
            <Pagination 
                totalPage={totapPage} 
                page={pageNum} 
                onChange={(event, value) => handlePagination(event, value)}
            />
            {requestModal && 
               <ClosedModal 
                    open={requestModal}
                    handleClose={notToCancel}
                    data={closeRequestModalData}
                    isclosingMessage={isclosingMessageBool}
                    closeRequestHandler={closeRequestHandler}
               />
            }
               {requestModalConfirm &&
        <ConfirmRequestModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
        </>
    );
};

export default FollowUp;
