import {
  LOGIN,
  LOGOUT
} from "../actions/types";

const initialState = {
  didTryAutoLogin: false,
  token: localStorage.getItem("token"),
  userData: localStorage.getItem("userData"),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        token: action.payload.token,
        userData: action.payload.user,
      };
    }
    case LOGOUT:
      return {
        token: action.token,
        userData: action.user,
      };
 
    default:
      return state;
  }
};

export default authReducer;
