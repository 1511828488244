import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from "../../../assets/images/rightArrow.svg";
import {
  searchHolidayListing,
  holidaySearchValue,
} from "../../../actions/holidaysAction";
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import * as holidayListingAction from "../../../actions/holidaysAction";
import { pageId } from "../../../actions/experienceListingAction";

import {countActivePackages, countExpiredPackages} from "../../../utils/index"
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  rootBorder: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "2px solid #000000",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  location: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 12,
    color: "#797979",
  },
  rightArrow: {
    position: "absolute",
    right: "16px",
    top: "22px",
  },
}));

export default function Listing({
  holidayListDetails,
  handleEdit,
  handleAdd,
  currentPage,
  handleResetPage
}) {
  const classes = useStyles();
  const [searchBool, setsearchBool] = useState(false);
  const [addFlowLogo, setAddFlowLogo] = useState();
  const [show, setShow] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(1);
  const [editDetailsId, setEditDetailsId] = useState("");
  const [chosen, setChosen] = useState(
    holidayListDetails?.results && holidayListDetails?.results[0]?.id
  );
  const addHolidayPackage = useSelector(
    (state) => state.holidayListing.addHolidayPackage
  );
  const updateHolidayListingBool = useSelector(
    (state) => state.holidayListing.holidatUpdateBoolVal
  );
  const listingEditTrueVal = useSelector(
    (state) => state.holidayListing.listingEditTrue
  );
  const search = useSelector(
    (state) => state.holidayListing.holidaylistSearchVal
  );
  const selectedChosenPckId = useSelector(
    (state) => state.holidayListing.selectedChosenId
  );
  const pageID = useSelector((state) => state.holidayListing.pageId);
  const holidayListingDropDownVal = useSelector(
    (state) => state.holidayListing.holidayListingDropDownvalue
  );
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isListingDataLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setChosen(
      holidayListDetails?.results && holidayListDetails?.results[0]?.id
    );
    if (updateHolidayListingBool) {
      // dispatch(holidayListingAction.getHolidayPackageInfo(editDetailsId));
      setChosen(editDetailsId);
    } else {
      if(holidayListDetails?.results && holidayListDetails?.results[0]?.id){
      dispatch(
        holidayListingAction.getHolidayPackageInfo(
          holidayListDetails?.results && holidayListDetails?.results[0]?.id
        )
      );
      setChosen(
        holidayListDetails?.results && holidayListDetails?.results[0]?.id
      );
      }
    }
  }, [
    currentPage,
    holidayListDetails?.results && holidayListDetails?.results[0]?.id,
  ]);

  // useEffect(() => {
  //   return dispatch(holidayListingAction.getHolidayPackageInfo(holidayListDetails?.results[0]?.id));

  // } ,[]);

  useEffect(() => {
    dispatch(holidayListingAction.holidayListsUpdateBool(false));
  }, [currentPage]);

  useEffect(() => {
    if (selectedChosenPckId) {
      setChosen(holidayListDetails?.results[0]?.id);
      dispatch(
        holidayListingAction.getHolidayPackageInfo(
          holidayListDetails?.results[0]?.id
        )
      );
      dispatch(holidayListingAction.setChosenPackIDBool(false))
    }
  }, [selectedChosenPckId]);

  useEffect(() => {
    return () => {
      dispatch(holidayListingAction.setAddHolidayPackage(false));
      setShow(false);
    };
  }, []);
useEffect(()=>{
  if(search==''){
setSearchVal('')
  }
},[search])
  const setEditDetails = (id) => {
    setEditDetailsId(id);
    addHolidayPackage &&
      dispatch(holidayListingAction.setAddHolidayPackage(false));
    setChosen(id);
    setShow(false);
    handleEdit(id);
    dispatch(holidayListingAction.getHolidayPackageInfo(id));
    dispatch(holidayListingAction.editTrue(true));
  };

  const setNewDetails = (id) => {
    setAddFlowLogo(id);
    setShow(true);
    dispatch(holidayListingAction.setAddHolidayPackage(true));
    dispatch(holidayListingAction.handleAllImages([]));
    handleAdd();
    setSearchVal('')
  };

  // const holidaySearchlisting = (event) => {
  //   let input = event.target.value;
  //   dispatch(searchHolidayListing(input, holidayListingDropDownVal?.id, 1));
  //   dispatch(holidaySearchValue(input));
  // };
  const handleChange=(val)=>{
    setSearchVal(val)
    if(val===''){
      dispatch(holidayListingAction.getHolidayList(1,holidayListingDropDownVal?.id));
      dispatch(holidayListingAction.holidaySearchValue(''));
    }
  }
  useEffect(() => {
    handleResetPage()
    if(searchVal){
    const getData = setTimeout(() => {
      dispatch(
        holidayListingAction.searchHolidayListing(
          searchVal,
          holidayListingDropDownVal?.id,
          1
        )
      );
      dispatch(holidayListingAction.pageId(1));
      dispatch(holidayListingAction.holidaySearchValue(searchVal));
    }, 350)
    return () => clearTimeout(getData)
    }
  }, [searchVal]);
  return (
    <div style={{ width: "100%" }}>
      <Row className={classes.row} style={{ alignItems: "center" }}>
        <Col className="w-100" style={{ marginLeft: "2px" }}>
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Col>
        <Col style={{ marginRight: "2px" }}>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div
        className="overflow--scroll"
        style={{ paddingRight: "20px", marginRight: "-20px" }}
      >
        {addHolidayPackage && (
          <Card className={addFlowLogo ? classes.rootBorder : classes.root}>
            <h6 className={classes.title}>Adding New</h6>
            <p className={classes.location}>No location provided yet</p>
            {addFlowLogo ? (
              <img className={classes.rightArrow} src={rightArrow} />
            ) : null}
          </Card>
        )}
        {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}>
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {searchBool
          ? holidayListDetails &&
            holidayListDetails.length > 0 &&
            holidayListDetails.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
                // onLoad={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {item.city}
                  {item.city !== "" && item.state !== ""
                    ? " "
                    : "No location provided yet"}
                  {item.state}
                </p>
                <h6 className={classes.title}>{item.section.name}</h6>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))
          : holidayListDetails?.results &&
            holidayListDetails?.results?.length > 0 &&
            holidayListDetails?.results?.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {item.section.name === "International" ? "" : item?.city}
                  {(item?.city === "" || item?.city === null) &&
                  (item.state === "" || item.state === null) ? (
                    <p className="no-location">No location provided yet </p>
                  ) : (
                    " "
                  )}
                  {item.section.name === "International" ? item?.packages?.[0]?.location_country : item?.state}
                </p>
                <p style={{ marginBottom: "10px"}}>{item.section === null ? "" : item.section.name}</p>
                <p style={{marginBottom: "0px", fontSize: "13px"}}><span style={{color: "green"}}> {countActivePackages(item)}</span><span style={{color: "#CD1414"}}> {countExpiredPackages(item) ? ", " + countExpiredPackages(item) : null}</span></p>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))}
        {holidayListDetails?.results?.length === 0 && (
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card>
        )}
      </div>
    </div>
  );
}
