
import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Edit from '../../../assets/images/edit.svg'
import ArrowDown from '../../../assets/images/arrowDown.svg'
import Popup from "./popup";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import "../../../assets/css/partials/packageDetails.scss";
// import '../../../assets/css/listingScrollbar.scss'
import { makeStyles } from "@material-ui/core/styles";
import { handleAddNewData } from '../../../actions/accountsAction';
import EmployeeListing from './EmployeeList'
import * as corpListingAction from "../../../actions/corpListingAction";
import * as AccountAction from "../../../actions/accountsAction";
import { useSnackbar } from "notistack";
import {
  ORGANISATION_NAME, ORGANISATION_NAME_LENGTH, ADDRESS, ORGANISATION_DOMAIN, LOGO
} from '../../../utils/constants'


const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  showHide: {
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
  show: {
    opacity: 1,
    '&:hover': {
      opacity: 1
    }
  }
}));


export default function AccountAddNewDetails({ data, noRecordFound }) {
  const classes = useStyles();
  // const addImageUrl = useSelector(state => state.corpListing.allImagesData)
  const addImageUrl = useSelector(state => state.corpListing.addImage)


  const [state, setState] = useState({
    // id: null,
    name: "",
    logo: '',
    email_domain: '',
    location: ""
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isOrgName, setIsOrgName] = useState(null);
  const [isOrgNameLength, setIsOrgNameLength] = useState(null);
  const [isLogoValidation, setLogoValidation] = useState(null);
  const [isAddress, setIsAddress] = useState(null);
  const [isOrgDomain, setIsOrgDomain] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [isLogoUpload, setIsLogoUpload] = useState(false);


  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const handleChangeEmail = (key, value) => {
    var mailformat = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
    let inputText = value.target.value
    if(inputText.value.match(mailformat)) {
      alert("Enter Valid Email");
    }else{
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  }
  };


  const listingData = useSelector(state => state.corpListing.listDataPost)
  // const [dropdown, setDropdown] = useState(false);

  const addImage = useSelector(state => state.corpListing.allImagesData)

  const getAccountDetails = useSelector(state => state.accountListing.getAccountList)

  
  const inputFile = useRef(null);

  const addImages = (url) => {
    
    setState(prevState => ({
      ...prevState,
      logo: url.url
    }))
  }

  const handleFilePickChange = (event) => {
    const file = event.target.files[0];
    event.target.value = "";
    //upload file and replace reponse_url for url from response
    setIsLogoUpload(true)
    dispatch(corpListingAction.imageUpload(file, addImages))
  };

  const handleAddNewImage = () => {
    inputFile.current.click();
  };
  // const handlePostValidation = () => {
    
  //   if (state?.name === "" || state?.name === undefined) {
  //     enqueueSnackbar("Please select Organisation name", {
  //       variant: "error",
  //     });
  //     return false;
  //   }
  //   if (addImageUrl?.data?.url === "") {
  //     enqueueSnackbar("Please add image", {
  //       variant: "error",
  //     });
  //     return false;
  //   }
  //   if (state?.email_domain === "" || state?.email_domain === undefined) {
  //     enqueueSnackbar("Please enter email domain", {
  //       variant: "error",
  //     });
  //     return false;
  //   }
  //   if (
  //     state?.location === "" ||
  //     state?.location === undefined
  //   ) {
  //     enqueueSnackbar("Please enter location", {
  //       variant: "error",
  //     });
  //     return false;
  //   }

  //   return true;
  // };

  const newDataSubmithandler = (e) => {
    e.preventDefault();
    setIsOrgName(false)
    setIsOrgNameLength(false)
    setIsAddress(false)
    setLogoValidation(false)
    setIsOrgDomain(false)
    const addNewData = {
      // "id": state?.id,
      "name": state?.name,
      "logo": addImageUrl?.data?.url,
      "email_domain": state?.email_domain,
      "location": state?.location
    }
    // if (handlePostValidation()) {
    // const addNewData = {
    //   // "id": state?.id,
    //   "name": state?.name,
    //   "logo": addImageUrl?.data?.url,
    //   "email_domain": state?.email_domain,
    // }
    if(state.name ==='' || state.name === null)setIsOrgName(true)
    else if(state.name.length>50)setIsOrgNameLength(true)
    else if(!addImageUrl?.data?.url)setLogoValidation(true)
    else if(state.location ==='' || state.location === null)setIsAddress(true)
    else if(state.email_domain ==='' || !/^[A-Z0-9._%+-]+\.[A-Z]{2,3}$/i.test(state.email_domain))setIsOrgDomain(true)
    else{
    dispatch(handleAddNewData(addNewData, handleRefreshList, handleError))
  }
  }


  const handleError = (message) => {
    // dispatch(AccountAction.setAddAccount(false))
    enqueueSnackbar(message, {
      variant: "error",
    });
  }

  const handleRefreshList = (message) => {
    setState({
      name: "",
      logo: '',
      email_domain: '',
      location: ""
    })
    dispatch(AccountAction.accountsList());
    dispatch(AccountAction.setAddAccount(false))
    enqueueSnackbar('Updated Successfully', {
      variant: "success",
    });
    window.location.reload()
  };

  const addSectionImages = () => {
    inputFile.current.click();
  };

  // const userSearch = (event) => {
  //   setsearchBool(true);
  //   const id = getCorpDetails?.id

  //   let search = event.target.value;
  //   dispatch(searchUser(search, id));
  //   // if (event.charCode === 13) {
  //   //     dispatch(searchUser(search, id));
  //   // }
  // };

  return (
    <div>
      <div className="packageDetails-holder">
        <form onSubmit={newDataSubmithandler}>
          <div className="form-options">
            <div>
              <h2>{noRecordFound ? `Add New Organisation` : 'Adding Organisation'}</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div>
                <button type="submit" className="btn btn-primary btn-save" >
                  Save
                </button>

              </div>

            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: "relative", padding: 0 }} className="form-group form-custom">
              {/* <img src={addImageUrl.data?.url} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />*/}
              {isLogoUpload && <img src={addImageUrl?.data?.url} style={{ height: '100%', width: '100%', borderRadius: '5px' }} />}
              <div>
                <div
                  onClick={handleAddNewImage}
                  style={{
                    padding: "6px 10px",
                    color: "black",
                    cursor: "pointer",
                    position: 'absolute',
                    left: '0px',
                    bottom: '23px',
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: 'small'
                  }}
                >
                  {<span className={!isLogoUpload ? classes.show : classes.showHide}>Upload Logo here</span>}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFilePickChange}
                  // onChange={onSelectFile}
                  />
                </div>
                {isLogoValidation && <p style={{color:"red", width: 'max-content'}}>{LOGO}</p>}
              </div>
            </div>
            <div
              style={{ flex: 2, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
              <label for="">Organisaton Name</label>
              <input
                value={state?.data?.name}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("name", value)}
              />
              {isOrgName ? <p style={{color:"red"}}>{ORGANISATION_NAME}</p>
              : isOrgNameLength ? <p style={{color:"red"}}>{ORGANISATION_NAME_LENGTH}</p> : ''
            }


            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
              {/*<img src={ArrowDown} style={{ position: 'absolute', right: '58px', bottom: '34px' }} />*/}

            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 0.5, position: 'relative' }} className="form-group form-custom">
              <label for="">Address</label>
              <input
                value={state?.data?.location}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("location", value)}
              />
              { /*<img src={Edit} style={{ position: 'absolute', right: '35px', top: '54px' }} />*/}
              {isAddress && <p style={{color:"red"}}>{ADDRESS}</p>}

            </div>
            <div
              style={{ flex: 0.5, marginLeft: 10, position: 'relative' }}
              className="form-group form-custom"
            >
              <div>
                <label for="">Organisation Domain</label>
                <h6 style={{ position: 'absolute', left: '23px', top: '53px', fontWeight: '400', opacity: '0.4' }}>employee@</h6>
                <input style={{ paddingLeft: '96px' }}
                  value={state?.data?.email_domain}
                  type="text"
                  className="form-control"
                  onChange={(value) => handleChange("email_domain", value)}
                />
                {/*<img src={Edit} style={{ position: 'absolute', right: '35px', top: '54px' }} />*/}

              </div>
              {isOrgDomain && <p style={{color:"red"}}>{ORGANISATION_DOMAIN}</p>}
            </div>
          </div>
          <hr />
          <div style={{ overflowY: 'scroll', height: '500px' }}>
            {/*<EmployeeListing />*/}
            <Row className={classes.row}>
              <Col className="w-100" style={{ maxWidth: '71%' }}>
                <input
                  type="search"
                  className="form-control search-list"
                  placeholder="Search Accounts"
                // onKeyPress={userSearch}
                // onChange={userSearch}
                />
              </Col>
              <Col>
                <button
                  style={{
                    width: "max-content",
                    border: "1px solid #EDEDED",
                    color: "#000000",
                    height: "42px",
                    background: "white",
                    fontSize: "13px",
                    fontWeight: "500",
                    padding: "20px",
                  }}
                  type="button"
                  className="btn btn-primary btn-save"
                // onClick={handleClickOpen}
                >
                  Add New Employee&nbsp;&nbsp;
            <AddSharpIcon />
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </div >
  );
}
