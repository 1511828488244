import React, {useState} from 'react';
import { Dialog } from '@material-ui/core';
import { ImCross } from 'react-icons/im';
import moment from 'moment';
// import '../assets/css/partials/commonMoreInfoModal.scss';
import { isCorporate, checkNumberOfGuest, BookedWithPointAndRs } from '../../../utils/index';
import { useDispatch } from 'react-redux';
import { generateEmailForAdvanceAmt, generateEmailForBalanceAmt, getPendingPayments, packAmountUpdate } from '../../../actions/paymentLinkAction';
import { useSnackbar } from "notistack";
import Edit from "../../../assets/images/edit.svg";
import Input from '../../../common/uiElements/input';

const MoreInfoModal = ({open, modalClose, data}) => {
    const { enqueueSnackbar } = useSnackbar(); 
    const { 
     contact_email, 
     contact_name,      
     contact_number, 
     total_points, 
     created, 
     corporate, 
     amount_paid,
     amount,
     pack_title,
     status,
     pack_booking_slug
    } = data;
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const [userAmount, setUserAmount] = useState(data?.balance_amount)
    const handleClick = (isEmail) => {
        const data = {
            "pack_slug": pack_booking_slug,
            "request_type": isEmail ? "generate_email" : "generate_link"
        }
        dispatch(generateEmailForBalanceAmt(data, isEmail ? handleSuccess : handleCopySuccess))
    }
    const handleAdvanceClick=(isEmail)=>{
        const data = {
            "pack_slug": pack_booking_slug,
            "request_type": isEmail ? "generate_email" : "generate_link"
        }
        dispatch(generateEmailForAdvanceAmt(data, isEmail ? handleSuccess : handleCopySuccess))
    }
    const handleCopySuccess = (url) => {
        navigator.clipboard.writeText(url);
        enqueueSnackbar('Link Copied', {
          variant: "success",
        });
        
    }
    const handleSuccess = (url, message) => {
        enqueueSnackbar(message, {
          variant: "success",
        });
        
    }
    const RECEIVED_TAG = <span className='success-status'>Received</span>
    const OFFLINE = <span className='success-status'>Offline</span>
    const ONLINE = <span className='success-status'>Online</span>
    const GST = <span className='success-status'>(including GST)</span>
    const PENDING_TAG = <span className='pending-status'>Pending</span>
    const isPending = data?.booking_status == "pending";
    const isBooked = data?.booking_status == "booked";



    const calcAdvanceAmount = (status) => {
        if(data?.is_advance){
            if(data?.is_advance_paid){
                if(isPending){
                    return <p>{BookedWithPointAndRs(data?.advance_points, data?.amount_paid )} {RECEIVED_TAG} </p>
                }else{
                    return <p>{BookedWithPointAndRs(data?.advance_points, data?.advance_amount - data?.advance_points)}  </p> 
                }
            }else{
                return <p>Rs {data?.advance_amount} </p>
            }
        }else{
            return <p>-</p>
        } 
    }   

    const calcBalanceAmount = (status) => {
        if(isPending){
                return <span>Rs. {data?.balance_amount} {PENDING_TAG}</span>
            // if(data?.is_advance){
            //     return <p>Rs. {data?.amount - data?.advance_amount} {PENDING_TAG}</p>
            // }else{
            //     return <p>Rs. {data?.balance_amount} {PENDING_TAG}</p>
            // }
        }else if(isBooked){
            if(true){
                return <span>{BookedWithPointAndRs(data?.total_points, data?.amount_paid )} {data?.amount_paid ? GST: null}</span>
            }else{
                return <span>Rs {data?.amount_paid_offline} </span>
            }
        }else{
            return <p>Rs {data?.balance_amount} </p>
        }
    }
    const handleUpdateAmount = () => {
        const amount = Number(data?.advance_amount) + Number(userAmount)
        dispatch(packAmountUpdate(data?.pack_booking_slug, Number(userAmount), handleSuccess))
        modalClose()
        setTimeout(() => {
            dispatch(getPendingPayments(1))
        }, 1000)
        setIsEdit(false)
    }
   
    const handleCancel = () => {
        setIsEdit(false)
        setUserAmount(data?.balance_amount)
    }
    const handelEdit = () => {
        setIsEdit(true)
        setTimeout(()=>{
            document.querySelector(`#balanceInput`).focus()
        });
    }
    
    return (
        
        <Dialog open={open} 
            PaperProps={{
                style: {
                    height:"auto",
                    maxWidth:"450px"
                },
           }}
        >
            <div className="common-more-info-container">
                <h5 className="common-more-info-heading">{pack_title}{" "}
                    <span style={{color:'grey'}}>Booking</span>
                </h5>
                    <span className="common-more-info-close-icon cursor-pointer"
                        onClick={() => {
                            modalClose(); 
                            setIsEdit(false)
                            setUserAmount(data?.balance_amount)
                            }}>
                        <ImCross />
                    </span>
                <p className="more-info-booked-on">Booked on {moment(created).format("Do MMMM, YYYY")}</p>
                {/* <p className="more-info-booked-on">Booked on 25th Oct 2020</p> */}
                <div className="row common-more-info-details">
                    

                    <div class="col-md-12 common-more-info-right-contain">
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right">
                                <p>Package price</p>
                                <p>Rs {data?.amount}</p>
                                {/* <p>{isCorporate(corporate, total_points, amount_paid)}</p> */}
                            </div>
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right"style={{paddingBottom:'10px'}}>
                                <p>Advance amount</p>
                                 {calcAdvanceAmount(data?.booking_status)}
                                 {(data?.is_advance && !data.is_advance_paid && !isEdit) &&<div className='d-flex justify-content-around' style={{marginBottom: "10px"}}>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 50px"}}
                                        onClick={() => handleAdvanceClick(true)}
                                    >
                                        Share link via Email
                                    </button>
                                    <p></p>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 0px", width: "140px"}}
                                        onClick={() => handleAdvanceClick(false)}
                                    >
                                        Copy link
                                    </button>
                                </div>}
                            </div>
                        
                        </div>
                        <div className="right-details-input">
                            <div className="common-more-info-booked-for-details-right" style={{paddingBottom: "10px", position: "relative"}}>
                                <p>{isBooked ? "Total amount paid" : 'Balance amount'}</p>
                                {isEdit ? 
                                <p>Rs 
                                <input 
                                    id="balanceInput"
                                    type="text" 
                                    name="balance" 
                                    value={userAmount}
                                    onChange={(e) => setUserAmount(e.target.value)}
                                    style={{borderWidth: 0, border: "1px solid #eee", borderRadius: "5px", padding: "5px", marginLeft: "7px"}}
                                /> 
                                </p>
                                : <p>{calcBalanceAmount(data?.booking_status)}</p>
                                }
                                
                                {data?.is_advance_paid && data?.booking_status == "pending" && <img
                                    src={Edit}
                                    style={{ position: "absolute", right: "20px", top: "36px", padding: "0px 10px"}}
                                    onClick={() => handelEdit(true)}
                                    className="cursor-pointer"
                                />}
                                {(data?.is_advance_paid && data?.booking_status == "pending" && !isEdit) &&<div className='d-flex justify-content-around' style={{marginBottom: "10px"}}>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 50px"}}
                                        onClick={() => handleClick(true)}
                                    >
                                        Share link via Email
                                    </button>
                                    <p></p>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 0px", width: "140px"}}
                                        onClick={() => handleClick(false)}
                                    >
                                        Copy link
                                    </button>
                                </div>}
                                {(!data?.is_advance && !data.is_advance_paid &&data?.booking_status == "pending"&& !isEdit) &&<div className='d-flex justify-content-around' style={{marginBottom: "10px"}}>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 50px"}}
                                        onClick={() => handleAdvanceClick(true)}
                                    >
                                        Share link via Email
                                    </button>
                                    <p></p>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 0px", width: "140px"}}
                                        onClick={() => handleAdvanceClick(false)}
                                    >
                                        Copy link
                                    </button>
                                </div>}
                                {isEdit && 
                                <div className='d-flex justify-content-around' style={{marginBottom: "10px"}}>
                                    <button 
                                        type="button" 
                                        className='btn btn-danger'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 0px", width: "140px"}}
                                        onClick={() => handleCancel()}
                                    >
                                        Cancel
                                    </button>
                                    <p></p>
                                    <button 
                                        type="button" 
                                        className='btn btn-primary'
                                        style={{fontSize: "13px", margin: "0px, 0, 0, 0px", width: "140px"}}
                                        onClick={() => handleUpdateAmount()}
                                        disabled={data?.balance_amount == userAmount}
                                    >
                                        Update
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 common-more-info-left-contain" >
                        
                    </div>
                </div>  
            </div>
        </Dialog>
        
    );
};

export default MoreInfoModal; 