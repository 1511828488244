import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import rightArrow from "../../../assets/images/rightArrow.svg";
import { searchListing, searchValue } from "../../../actions/corpListingAction";
import "../../../assets/css/partials/listing.scss";
import { useDispatch, useSelector } from "react-redux";
import * as CorporateListingAction from "../../../actions/corpListingAction";
import {countActivePackages, countExpiredPackages} from "../../../utils/index"
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  rootBorder: {
    position: "relative",
    marginTop: 10,
    boxShadow: "none",
    background: "#FAFAFA",
    border: "2px solid #000000",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "18px 20px",
    minHeight: 78,
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  title: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: 500,
    color: "#000000",
  },
  location: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 12,
    color: "#797979",
  },
  rightArrow: {
    position: "absolute",
    right: "16px",
    top: "22px",
  },
}));

export default function Listing({
  corporateDetails,
  handleEdit,
  handleAdd,
  currentPage,
  handleResetPage
}) {
  const selectedId =
    corporateDetails?.results && corporateDetails?.results[0]?.id;
  const classes = useStyles();
  const [add, setAdd] = useState(false);
  // const [search, setSearch] = useState("");
  const [searchBool, setsearchBool] = useState(false);
  const [chosen, setChosen] = useState(selectedId);
  const [searchVal, setSearchVal] = useState("");
  const [addFlowLogo, setAddFlowLogo] = useState();
  const [corpEditDetailsId, setCorpEditDetailsId] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const dropDownFilterSecId = useSelector(
    (state) => state.corpListing?.corpDropDownFilteredSecId
  );
  const corpListingUpdateBool = useSelector(
    (state) => state.corpListing.corpListingUpdatedBool
  );
  const addCorpPackage = useSelector(
    (state) => state.corpListing.addCorporatePackage
  );
  const isDataLoading = useSelector(
    (state) => state.corpListing.isListingDataLoading
  );
  const corpSearchListData = useSelector(
    (state) => state.corpListing.searchCorpListData.data
  );
  const search = useSelector((state) => state.corpListing?.corpListSearchValue);
  const deletePackageBool = useSelector(
    (state) => state.corpListing?.deletePackageItemBool
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setChosen(selectedId);
    if (corpListingUpdateBool) {
      // dispatch(CorporateListingAction.getPackageInfo(corpEditDetailsId));
      setChosen(corpEditDetailsId);
    } else {
      if(selectedId){
      dispatch(
        CorporateListingAction.getPackageInfo(corporateDetails?.results[0]?.id)
      );
      setChosen(corporateDetails?.results[0]?.id);
      }
    }
  }, [currentPage, selectedId]);

  useEffect(() => {
    if (deletePackageBool) {
      setChosen(corporateDetails?.results[0]?.id);
      dispatch(
        CorporateListingAction.getPackageInfo(corporateDetails?.results[0]?.id)
      );
      dispatch(CorporateListingAction.deletePackageItemBool(false))
    }
  }, [deletePackageBool]);

  useEffect(() => {
    return () => {
      dispatch(CorporateListingAction.setAddPackage(false));
    };
  }, []);

  const setEditDetails = (id) => {
    setCorpEditDetailsId(id);
    addCorpPackage && dispatch(CorporateListingAction.setAddPackage(false));
    setChosen(id);
    setShow(false);
    handleEdit(id);
    dispatch(CorporateListingAction.getPackageInfo(id));
  };

  const setNewDetails = (id) => {
    setAddFlowLogo(id);
    setShow(true);
    dispatch(CorporateListingAction.setAddPackage(true));
    dispatch(CorporateListingAction.handleAllImages([]));
    handleAdd();
    setSearchVal('')
  };

  // const corpSearchlisting = (event) => {
  //   let input = event.target.value;
  //   dispatch(searchListing(input, 1))
  //   dispatch(searchValue(input))
  // };
  useEffect(()=>{
    if(search==''){
  setSearchVal('')
    }
  },[search])
  const handleChange=(val)=>{
    setSearchVal(val)
    if(val===''){
      dispatch(CorporateListingAction.getCorpListing(1,dropDownFilterSecId?.id));
      dispatch(CorporateListingAction.searchValue(''));
    }
  }
  useEffect(() => {
    handleResetPage()
    if(searchVal){
    const getData = setTimeout(() => {
      dispatch(CorporateListingAction.searchListing(searchVal, 1));
      dispatch(CorporateListingAction.corpPageId(1));
      dispatch(CorporateListingAction.searchValue(searchVal));
    }, 350)
    return () => clearTimeout(getData)
    }
  }, [searchVal]);
  return (
    <div style={{ width: "100%" }}>
      <Row className={classes.row} style={{ alignItems: "center" }}>
        <Col className="w-100">
          <input
            type="search"
            className="form-control search-list"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Col>
        <Col>
          <button onClick={setNewDetails} className="btn add-new">
            Add New <AddSharpIcon />
          </button>
        </Col>
      </Row>
      <div
        className="overflow--scroll"
        style={{ paddingRight: "20px", marginRight: "-20px" }}
      >
        {addCorpPackage && (
          <Card className={addFlowLogo ? classes.rootBorder : classes.root}>
            <h6 className={classes.title}>Adding New</h6>
            <p className={classes.location}>No location provided yet</p>
            {addFlowLogo ? (
              <img className={classes.rightArrow} src={rightArrow} />
            ) : null}
          </Card>
        )}
        {
          isDataLoading &&
          <div className="blured-backround" style={{margin:"15% auto 0"}}> 
            <p style={{ paddingTop: "40%" }}>
              <Spinner animation="border" variant="primary" />
            </p>
          </div>
        }
        {searchBool
          ? corpSearchListData &&
            corpSearchListData.length > 0 &&
            corpSearchListData.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {item.city}
                  {item.city !== "" && item.state !== ""
                    ? ", "
                    : "No location provided yet"}
                  {item.state}
                </p>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))
          : corporateDetails?.results &&
            corporateDetails?.results.length > 0 &&
            corporateDetails?.results.map((item) => (
              <Card
                className={
                  item.id == chosen && !show ? classes.rootBorder : classes.root
                }
                onClick={() => setEditDetails(item.id)}
              >
                <h6 className={classes.title}>{item.title}</h6>
                <p className={classes.location}>
                  {/* {item?.city} */}
                  {/* {item?.section?.gift_voucher === true || item.section.name === "International" ? "" : item?.city} */}
                  {(item?.city === "" || item?.city === null) &&
                  (item.state === "" || item.state === null) ? (
                    <p className="no-location">No location provided yet </p>
                  ) : (
                    " "
                  )}
                  {/* {item?.state} */}
                  {item?.section?.gift_voucher === true || item.section.name === "International" ? item?.packages?.[0]?.location_country : item?.city}
                </p>
                <p>{item.section === null ? "" : item.section.name}</p>
                <p style={{marginBottom: "0px", fontSize: "13px"}}><span style={{color: "green"}}> {countActivePackages(item)}</span><span style={{color: "#CD1414"}}> {countExpiredPackages(item) ? ", " + countExpiredPackages(item) : null}</span></p>
                {item.id === chosen && !show ? (
                  <img className={classes.rightArrow} src={rightArrow} />
                ) : null}
              </Card>
            ))}
        {corporateDetails?.results?.length == 0 ? (
          <Card className={classes.root}>
            <p>No Records Found!</p>
          </Card>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
