import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CorporateReviewStatusUpdate,
  getCorporateReviewPendingList,
  getCorporateReviewPublishedList,
  getCorporateReviewRejectedList,
  getCorporateReviewsearch,
  reviewPageId,
} from "../../../actions/corporateReviewAction";

import SearchBar from "../../../common/commonSearchBar";
import CustomerReviews from "../../../common/holidayReviews";

import Pagination from "../../../common/pagination";

const Pending = () => {
  const [amountValue, setAmountValue] = useState("");
  const dispatch = useDispatch();
  const totalCorporateReviews = useSelector(
    (state) => state.corporateReviews?.corporateReviewList
  );
  const PendingReviewList =
    totalCorporateReviews && totalCorporateReviews?.review_data?.results;
    console.log(PendingReviewList);

  const totalPage = useSelector(
    (state) =>
      state.corporateReviews?.corporateReviewList?.review_data?.total_pages
  );
  const pageNum = useSelector((state) => state.corporateReviews?.page);

  const ffEndDate = useSelector(
    (state) => state.corporateReviews.corporateReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.corporateReviews.corporateReviewStartDate
  );
  useEffect(() => {
    if (ffEndDate === null && ffStartDate === null) {
      dispatch(getCorporateReviewPendingList(1));
      dispatch(reviewPageId(1));
    }
  }, [ffEndDate, ffStartDate]);
  const refreshData = () => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(
        getCorporateReviewsearch(pageNum, "pending", ffStartDate, ffEndDate)
      );
    } else {
      dispatch(getCorporateReviewRejectedList(pageNum));
      dispatch(getCorporateReviewPendingList(pageNum));
      dispatch(getCorporateReviewPublishedList(pageNum));
    }
  };
  const handlePagination = (e, value) => {
    if (ffEndDate === null && ffStartDate === null) {
      dispatch(getCorporateReviewPendingList(value));
      dispatch(reviewPageId(value));
    } else {
      dispatch(
        getCorporateReviewsearch(value, "pending", ffStartDate, ffEndDate)
      );
      dispatch(reviewPageId(value));
    }
  };
  const publishReview = (e, review_id, main_pack_id, ref_id) => {
    if (e.target.value == "rejected" || e.target.value == "approved") {
      dispatch(
        CorporateReviewStatusUpdate(
          e.target.value,
          main_pack_id,
          review_id,
          ref_id,
          refreshData
        )
      );
    }
  };
  return (
    <>
     {PendingReviewList && PendingReviewList?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {PendingReviewList &&
        PendingReviewList.map((data, i) => (
          <CustomerReviews
            key={i}
            title={data?.holiday_pack?.pack_title}
            address={`${(data?.holiday_pack.location_city,
            data?.holiday_pack?.location_country)}`}
            status={"pending"}
            main_pack_id={data?.main_pack_id}
            price={data?.holiday_pack?.points}
            comment={data?.review_comment}
            review_id={data?.id}
            publishReview={publishReview}
            ref_id={data?.ref_id}
            review_star={data?.review_star}
          />
        ))}
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
    </>
  );
};
export default Pending;
