import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHolidayReviewPendingList,
  getHolidayReviewPublishedList,
  getHolidayReviewRejectedList,
  getHolidayReviewsearch,
  holidayReviewStatusUpdate,
  reviewPageId,
} from "../../../actions/holidayReviewAction";

import CustomerReviews from "../../../common/holidayReviews";

import Pagination from "../../../common/pagination";

const Published = () => {
  const dispatch = useDispatch();
  const totalHolidayReviews = useSelector(
    (state) => state?.holidayReviews?.holidayPublishedReviewList
  );
  const PublishedReviewList =
    totalHolidayReviews && totalHolidayReviews?.review_data?.results;
  const totalPage = useSelector(
    (state) =>
      state.holidayReviews.holidayPublishedReviewList.review_data?.total_pages
  );
  const pageNum = useSelector((state) => state.holidayReviews.page);
  const ffEndDate = useSelector((state) => state.holidayReviews.holidayReviewEndDate);
  const ffStartDate = useSelector((state) => state.holidayReviews.holidayReviewStartDate);
  useEffect(() => {
   
      dispatch(reviewPageId(1));
    
  }, []);

  const handlePagination = (e, value) => {
    e.preventDefault();
    if(ffEndDate===null&&ffStartDate===null){
      dispatch(getHolidayReviewPublishedList(value));
      dispatch(reviewPageId(value));
    }else{
      dispatch(getHolidayReviewsearch(value,"approved",ffStartDate,ffEndDate));
      dispatch(reviewPageId(value));
    }
  };
  const refreshData = () => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(
        getHolidayReviewsearch(pageNum, "approved", ffStartDate, ffEndDate)
      );
    } else {
      dispatch(getHolidayReviewRejectedList(pageNum));
      dispatch(getHolidayReviewPublishedList(pageNum));
      dispatch(getHolidayReviewPendingList(pageNum));
    }
  };
  const publishReview = (e, review_id, main_pack_id, ref_id) => {
    dispatch(
      holidayReviewStatusUpdate(
        "deleted",
        main_pack_id,
        review_id,
        ref_id,
        refreshData
      )
    );
  };
 
  return (
    <>
     {PublishedReviewList && PublishedReviewList?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
        
      {PublishedReviewList &&
        PublishedReviewList.map((data, i) => (
          
          <CustomerReviews
            key={i}
            title={data?.holiday_pack?.pack_title}
            address={`${(data?.holiday_pack.location_city,
            data?.holiday_pack?.location_country)}`}
            status={"Published"}
            price={data?.holiday_pack?.revised_price ? data?.holiday_pack?.revised_price : data?.holiday_pack?.price}
            comment={data?.review_comment}
            review_id={data?.id}
            main_pack_id={data?.main_pack_id}
            publishReview={publishReview}
            ref_id={data?.ref_id}
            review_star={data?.review_star}
          />
        ))}
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
    </>
  );
};
export default Published;
