
import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut,
  axiosGetWithToken,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress
} from "../services/apiServices";
import { 
  ANNIVERSARY_STORY_LIST,
  ANNIVERSARY_STORY_DETAILS,
  ANNIVERSARY_REQUESTED,
  ANNIVERSARY_REQUESTED_TOTAL_PAGE,
  ANNIVERSARY_REQUESTED_TOTAL_RESULTS,
  ANNIVERSARY_CONFIRMED,
  ANNIVERSARY_PLANNING,
  ANNIVERSARY_CANCELLED,
  ANNIVERSARY_SEARCH_TERM,
  ANNIVERSARY_START_DATE,
  ANNIVERSARY_END_DATE,
  ANNIVERSARY_PAGE_NO,
  ANNIVERSARY_DESTINATION_LIST,
  ANNIVERSARY_IMAGE_UPLOAD,
  ANNIVERSARY_DESTINATION_CREATE,
  IS_LIST_DATA_LOADING,
  IS_DATA_LOADING
} 
from './types';

export const anniversaryStoryList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{"field":"request_type", "value":"anniversary"},{"field":"active", "value":"true"}],
       "page": 1, 
       "list_per_page": 50
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.ANNIVERSARY_STORY_LIST}`, body, token)
      .then((res) => {
      
        if (res.data.status_code === 200) {
       
          dispatch({
            type: ANNIVERSARY_STORY_LIST,
            payload: res.data.data.results,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const searchStory = (searchVal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    let body = {
      "filters":[{
        "field":"search_term", 
        "value": searchVal
      },
      {"field":"request_type", "value":"anniversary"}
    ]
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.ANNIVERSARY_STORY_SEARCH}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: ANNIVERSARY_STORY_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};
// newStory

export const newStory = (formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPostWithToken(`${API.ANNIVERSARY_STORY_ADD}`, formData,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};

export const deleteStory = (storyId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosDelete(`${API.ANNIVERSARY_STORY_DELETE}${storyId}/`,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};
// deleteStory

export const anniversaryStoryDetails = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.ANNIVERSARY_STORY_DETAILS}${id}/`, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: ANNIVERSARY_STORY_DETAILS,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const updateStory = (id, formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPut(`${API.ANNIVERSARY_STORY_UPDATE}${id}/`, formData, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          handleSuccess(id);
        }
      })
  };
};

// ***********Request action***********
export const getAnniversaryRequested = (page) => {
  console.log("page", page)
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
         "field":"status",
         "value":"requested"
        },
        {"field":"request_type", "value":"anniversary"}
      ],
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.ANNIVERSARY_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ANNIVERSARY_REQUESTED,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const getAnniversaryPlanning = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
        "field":"status",
        "value":"planning"
       },
       {"field":"request_type", "value":"anniversary"}
      ],
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.ANNIVERSARY_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ANNIVERSARY_PLANNING,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

        }
        
      })
  };
};

export const getAnniversaryConfirmed = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"confirmed"
     },
     {"field":"request_type", "value":"anniversary"}
    ],
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.ANNIVERSARY_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
        type: ANNIVERSARY_CONFIRMED,
        payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: true,
        });

        
      }
      })
  };
};

export const getAnniversaryCancelled = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"cancelled"
     },
     {"field":"request_type", "value":"anniversary"}
    ],
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.ANNIVERSARY_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: ANNIVERSARY_CANCELLED,
          payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

          
        }
      })
  };
};

export const searchAnniversaryRequests = ( searchTerm, startDate, endDate, status,  page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[
        {"field":"search_term", "value": searchTerm},
        {"field":"status", "value": status},
        {"field":"from", "value": startDate},
        {"field":"to", "value": endDate},
        {"field":"request_type", "value":"anniversary"}
      ],
      "page": page, 
      "list_per_page": 50
  
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.ANNIVERSARY_REQUEST_SEARCH}`, body, token)
    .then((res) => {
      if(res?.data?.status_code === 200){
        if(status == "requested"){
          dispatch({
            type: ANNIVERSARY_REQUESTED,
            payload: res?.data?.data
          })
        }else if(status == "planning"){
          dispatch({
            type: ANNIVERSARY_PLANNING,
            payload: res?.data?.data
          })
        }else if(status == "confirmed"){
          dispatch({
            type: ANNIVERSARY_CONFIRMED,
            payload: res?.data?.data
          })
        }else{
          dispatch({
            type: ANNIVERSARY_CANCELLED,
            payload: res?.data?.data
          })
        }
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
       
      }
    })
  }
}

export const setSearchTerm = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ANNIVERSARY_SEARCH_TERM,
      payload: value
    })
  }
}

export const setStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: ANNIVERSARY_START_DATE,
      payload: date,
    });
  };
};
  
export const setEndDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: ANNIVERSARY_END_DATE,
      payload: date,
    });
  };
};

export const setPageNo = (page) => {
  return async (dispatch) => {
    dispatch({
      type: ANNIVERSARY_PAGE_NO,
      payload: page,
    });
  };
};

export const anniversaryRequestStatusChange = (status, id,cancelling_message, refereshList) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    cancelling_message:cancelling_message
  };
  axiosPut(`${API.ANNIVERSARY_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};
export const anniversaryRequestStatusChangeConfirm = (status, id,amount, refereshList) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    "price":amount
  };
  axiosPut(`${API.ANNIVERSARY_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};

//************  Destination **********/
export const getAnniversaryDestinationList = () => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{"field":"active", "value":"true"},{"field":"request_type", "value":"anniversary"}],
    "sort":{"field":"priority", "order":"A"},
    "page": 1, 
    "list_per_page": 50 
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.ANNIVERSARY_DESTINATION_LIST}`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
            type: ANNIVERSARY_DESTINATION_LIST,
            payload: res?.data?.data?.results
          })
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
        
      })
  };
};

export const addAnniversaryDestination = (data, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "alt_text":data?.alt_text,
    "title_text":data?.title_text,
    "request_type":"anniversary"
}
  axiosPostWithToken(`${API.ANNIVERSARY_DESTINATION_CREATE}`, body, token)
    .then((res) => {
      if (res.data.status_code === 201) {
          success()
        }
        
      })
  };
};

export const updateAnniversaryDestination = (data, id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "alt_text":data?.alt_text,
    "title_text":data?.title_text,
    "request_type":"anniversary"
}
  axiosPut(`${API.ANNIVERSARY_DESTINATION_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          success()
        }
        
      })
  };
};

export const deleteAnniversaryDestination = (id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  
  axiosDelete(`${API.ANNIVERSARY_DESTINATION_DELETE}${id}/`, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          success()
        }
        
      })
  };
};

export const anniversaryImageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    function blob2file(blobData) {
      const entries = new FormData();
      entries.set('file', blobData, "fileName.jpeg");
      return entries.get('file');
    }

    axiosMediaUpload(API.ANNIVERSARY_IMAGE_VIDEO_UPLOAD, "anniversary-image", blob2file(file), token)
    .then((res) => {
      dispatch({
        type: ANNIVERSARY_IMAGE_UPLOAD,
        payload: res.data,
      });
      addImage(res.data.data);
    })
    .catch((err) => {
      console.log("ERROR_ALERT", err);
    });
  };
};

export const videoUploadAnniversary = (file, addVideo, obj, newArr, getUploadProgress, id) => {
    
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.ANNIVERSARY_IMAGE_VIDEO_UPLOAD, "anniversary-video", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const deleteAnniversaryImage = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ANNIVERSARY_IMAGE_VIDEO_DELETE}${id}/`,{"type" : "anniversary"}, token)
      .then((res) => {
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};