import { GET_CORPORATE_LIST, ADD_IMAGE, 
  GET_LOCATION_CITY_LIST, SEARCH_CORPORATE_LIST_DATA, 
  ALL_IMAGES_DATA, GET_PACKAGE_INFO, CORP_LISTING_UPDATED_BOOL, 
  UPDATE_CORPORATES, GET_SELECT_DROPDOWN, POST_CORP_LISTING_DATA, MOVED_PACK_SEC_ID,
  PACKAGE_DELETE, CORPORATE_DELETE, IMAGE_DELETE, 
  CORP_LISTING_PAGE_ID, CORP_DROPDOWN_FILTER_SEC_ID ,CORP_LISTING_SEARCH_VALUE,DELETE_PACKAGE_ITEM_BOOL,
  TESTIMONIAL_CORPORATE_LIST,GET_CORPORATE_FEATURED_LIST, GET_SUBSCRIBERS_COUNT, CORPORATE_PACK_REVIEWS_LIST, IS_CORP_DATA_LOADING, IS_CORP_LIST_DATA_LOADING
} from "../actions/types";
import {
  SET_EDIT_CORPORATE_LIST_DETAILS,
  SET_ADD_CORPORATE_PACKAGE,
  SET_ADD_PACKAGE
} from "../actions/corpListingAction";

const initialState = {
  corporateList: [],
  corpListItemDetails: {},
  addCorporatePackage: false,
  addNewPackage: {},
  addImage: {},
  listingData: {},
  searchCorpListData: [],
  allImagesData: [],
  imageUpload: '',
  selectedDropdown: [],
  postCorpListingData: {},
  deletedPackage: [],
  deletedCorporate: [],
  deleteImage: [],
  packageInfo: [],
  locationCityList:[],
  corpListingUpdatedBool:false,
  featured: [],
  corpPageId:1,
  corpDropDownFilteredSecId:null,
  corpListSearchValue:"",
  deletePackageItemBool:false,
  corpTestimonial:[],
  subscribeCount: null,
  movedPackSec: {},
  corporatePackReviewList: [],
  isDataLoading:false,
  isListingDataLoading:false
};

const corpListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CORPORATE_LIST: {
      return {
        ...state,
        corporateList: payload,
      };
    }
    case SET_EDIT_CORPORATE_LIST_DETAILS: {
      return {
        ...state,
        corpListItemDetails: payload,
      };
    }
    case SET_ADD_CORPORATE_PACKAGE: {
      return {
        ...state,
        addCorporatePackage: payload,
      };
    }
    case GET_LOCATION_CITY_LIST: {
      return {
        ...state,
        locationCityList: payload,
      };
    }
    case SET_ADD_PACKAGE: {
      return {
        ...state,
        addNewPackage: payload,
      }
    }
    case ADD_IMAGE: {
      return {
        ...state,
        addImage: payload,
      };
    }
    case UPDATE_CORPORATES: {
      return {
        ...state,
        listingData: payload
      }
    }
    case SEARCH_CORPORATE_LIST_DATA: {
      return {
        ...state,
        searchCorpListData: payload
      }
    }
    case ALL_IMAGES_DATA: {
      return {
        ...state,
        allImagesData: payload
      }
    }case GET_CORPORATE_FEATURED_LIST: {
      return {
        ...state,
        featured: payload
      }
    }
    case GET_SELECT_DROPDOWN: {
      return {
        ...state,
        selectedDropdown: payload
      }
    }
    case MOVED_PACK_SEC_ID: {
      return {
        ...state,
        movedPackSec: payload
      }
    }
    case POST_CORP_LISTING_DATA: {
      return {
        ...state,
        postCorpListingData: payload
      }
    }
    case GET_PACKAGE_INFO: {
      return {
        ...state,
        packageInfo: payload
      }
    }
    case PACKAGE_DELETE: {
      return {
        ...state,
        deletedPackage: payload
      }
    }
    case CORPORATE_DELETE: {
      return {
        ...state,
        deletedCorporate: payload
      }
    }
    case IMAGE_DELETE: {
      return {
        ...state,
        deleteImage: payload
      }
    }
    case CORP_LISTING_UPDATED_BOOL: {
      return {
        ...state,
        corpListingUpdatedBool: payload
      }
    }
    case CORP_LISTING_PAGE_ID: {
      return {
        ...state,
        corpPageId: payload
      }
    }
    case CORP_DROPDOWN_FILTER_SEC_ID: {
      return {
        ...state,
        corpDropDownFilteredSecId: payload
      }
    }
    case CORP_LISTING_SEARCH_VALUE: {
      return {
        ...state,
        corpListSearchValue: payload
      }
    }
    case DELETE_PACKAGE_ITEM_BOOL: {
      return {
        ...state,
        deletePackageItemBool: payload
      }
    }
    case TESTIMONIAL_CORPORATE_LIST: {
      return {
        ...state,
        corpTestimonial: payload
      }
    }
    case GET_SUBSCRIBERS_COUNT: {
      return {
        ...state,
        subscribeCount: payload
      }
    }
    case CORPORATE_PACK_REVIEWS_LIST: {
      return {
        ...state,
        corporatePackReviewList: payload
      }
    }
    case IS_CORP_DATA_LOADING: {
      return {
        ...state,
        isDataLoading: payload
      }
    }
    case IS_CORP_LIST_DATA_LOADING: {
      return {
        ...state,
        isListingDataLoading: payload
      }
    }
    
    default:
      return state;
  }
};


export default corpListingReducer;
