import React, {useEffect, useState} from 'react'
import ToggleButton from '../../components/holidays/listings/toggle';

const ToggleGroup = ({state, setState, options, label, optionsKey, setIsError}) => {
    // console.log(optionsKey, "optionsKey")
    const [disable, setDisable] = useState(false)
    
    const handleToggleChange = (event, index) => {
        let arr = options;
        arr[index].value = event.target.checked
        if(optionsKey == "featured_amenities"){
            setState({ ...state, featured_amenities: arr });
            handleFeaturedAmenities(arr)
        }
        if(optionsKey == "room_amenities"){
            setState({ ...state, room_amenities: arr });
        }
        if(optionsKey == "hotel_resort_facilities"){
            setState({...state, hotel_resort_facilities: arr})
        }

    }
    const handleFeaturedAmenities = (data) => {
        const filteredArr =  data.filter((item) => {
            return item.value
        })
        if(filteredArr.length >= 4){
            setDisable(true)
            setIsError({ error:false, msg:'', name: "" })
        }else{
            setDisable(false)
        }
    }

    useEffect(() => {
        if(optionsKey == "featured_amenities"){
            handleFeaturedAmenities(options)
        }

    }, [options])
    

  return (
    <div>
        <label for="">{label}</label>
        <div className="featured-amenities" >
            {options && options?.length > 0 &&
            options?.map((item, index) => (
            <div className="box">
                <ToggleButton
                    label={item?.label}
                    name={item?.name}
                    checkValue={state?.[optionsKey]?.[index]?.value}
                    handleToggleChange={(event) => handleToggleChange(event, index)}
                    disabled={item.value ? false : disable}
                />
            </div>
            ))
            }
        </div>
    </div>
  )
}

export default ToggleGroup