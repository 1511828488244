import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosMediaUpload,
  axiosPatchWithToken,
  axiosPost,
  axiosPostWithToken,
  axiosPut,
} from "../services/apiServices";
import {
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
} from "./commonAction";

import { LOGIN, LOGOUT } from './types';


export const login = (emailId, password, redirectUser, handleErrors) => {
  const body = {
    email: emailId,
    password: password,
  };
  return async (dispatch) => {
    axiosPost(API.LOGIN, body).then((res) => {
      if (res.data.status_code === 200) {
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        localStorage.setItem("token", res.data.data.token);
        dispatch({
          type: LOGIN,
          payload: res.data,
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Logged in Successfully",
        });
        redirectUser();
      } else {
        handleErrors(res.data.data.message);
      }
    });
  };
};

export const checkSession = (handleSessionExpired) => {
  return async (dispatch, getState) => {
    const expToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhaSI6ImdBQUFBQUJpelQ2YURRTWZVbTdjTFFIUXp5MlVCM3NwTDRfVXQyb1RrdmRrakhJWWRCeDhGa0I0b21LQkd6ejhaaEdGSmxodktIcHE4SVpJcG5lUzJNejZsYTE4d2cwYzl3PT0iLCJiaSI6ImdBQUFBQUJpelQ2YWJCVGkwSHJDUmtpdEFqQWZEcWtTOE85TWQwbWdUc0VHZjFlNWREa1A0VHNrdHJvZ0hQNGRUUlRLdENsOFQ2OVlIYVhwNWh6SGlLR1hTQi1YaGFPWDJtZnRNYTk3UmJiN3Y1NHFFcXhpenVBPSJ9.aDfDpDyPlI-0sgL4gH8W2e4mKxXfSlw5UGVpmvbV_vY"
    const token = getState().auth.token;
    axiosPostWithToken(API.SESSION_CHECK, " ", token).then((res) => {
      if (res.data.status_code === 200) {
        if(res.data.data.session_expired){
          handleSessionExpired()
        }
      }
    });
  };
};
