import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import DelIcon from "../../assets/images/bin.svg";

import "../../assets/css/partials/featuredPackage.scss";
const PurpleSwitch = withStyles({
    switchBase: {
        color: "white",
        "&$checked": {
            color: "#2196F3",
        },
        "&$checked + $track": {
            backgroundColor: "#2196F3",
            opacity: 1,
        },
    },
    checked: {},
    track: {},
})(Switch);

const Featured = ({handleWebStories,modalCard,update}) => {
    return (
        <div style={{ display: "flex" }}>
            <button
                type="button"
                class="btn btn-primary btn-sm  mt-2 px-4"
                onClick={handleWebStories}
                disabled={modalCard ? true : false}
            >
                {update ? "Update" : "Done"}
            </button>
            {/* {update ? (
                <button
                    class="btn  btn-sm  mt-2  px-4 border border-dark "
                    style={{
                        background: "whitesmoke",
                        borderStyle: "solid 12px",
                        marginLeft: "4px",
                        cursor: "pointer",
                    }}
                    onClick={handelDeleteWebstories}
                >
                    <img src={DelIcon} alt="deleteIcon" />
                </button>
            ) : (
                ""
            )} */}
        </div>
    );
};

export default Featured;
