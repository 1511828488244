import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  
  myHolidayBookingStartDate, 
  myHolidayBookingEndDate, 
  holidayBookingsSearch,
  getHolidayBookingList,
  getHolidaysStatus,
  pageId
} from '../../../actions/holidayBookingAction';
import { Tabs } from "antd";
import Bookings from './bookings';
import Confirmed from './confirmed';
import Cancelled from './canceled';
import Availability from './availability';
import Refunded from './refunded';
import FollowUp from './followUp';
import Datefilter from '../../../common/commonDateFIlter'
import DropdownFilter from '../../holidays/bookings/dropDownFilter';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
const { TabPane } = Tabs;

const HolidaysBookingIndex = () => {
  const [ status, setStatus ] = useState("requested");
  const ffStartDate = useSelector((state) => state.holidayBooking.holidayBookingStartDate);
  const ffEndDate = useSelector((state) => state.holidayBooking.holidayookingEndDate);
  const pageNum = useSelector(state => state.holidayBooking.page);
  const bookLength = useSelector(state => state.holidayBooking.holidayBookingDataLength);
  const confirmLength = useSelector(state => state.holidayBooking.holidayConfirmedDataLength);
  const refundlength = useSelector(state => state.holidayBooking.holidayRefundDataLength);
  const abc = useSelector(state => state.holidayBooking);
  console.log(abc);
  const cancelledLength = useSelector(state => state.holidayBooking.holidayCancelledDataLength);
  const follow_upLength=useSelector(state => state.holidayBooking.holidayFollowup?.total_results);
  const sectionID = useSelector(state => state.holidayBooking.bookingsSecId);
  const holidayBookingSearchVal = useSelector(state => state.holidayBooking.holidayBookingSearchVal);
  const holidayConfirmedSearchVal = useSelector(state => state.holidayBooking.holidayConfirmedSearchVal);
  const holidayCancelledSearchVal = useSelector(state => state.holidayBooking.holidayCancelledSearchVal);
  const holidayAvailabilitySearchVal = useSelector(state => state.holidayBooking.holidayAvailabilitySearchVal);
  const holidayFollow_upSearchVal=useSelector(state => state.holidayBooking.holidayFollowupSearchVal);
  const availabilityReqList = useSelector(state => state.holidayBooking.availabilityReqList);

  const data = useSelector(state => state.holidayBooking);
  console.log(data);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  const dispatch = useDispatch();
  const totalHolidayBookings = bookLength + confirmLength + cancelledLength + availabilityReqList.total_results;
   
  
  useEffect(() => {
    if(sectionID === undefined){
      dispatch(getHolidayBookingList(pageNum, null, "booked"));
      dispatch(getHolidayBookingList(pageNum, null, "confirmed"));
      dispatch(getHolidayBookingList(pageNum, null, "cancelled"))
      dispatch(getHolidayBookingList(pageNum, null, "requested"))
      dispatch(getHolidayBookingList(pageNum, null, "follow_up"))
      dispatch(getHolidayBookingList(pageNum, null, "refunded"))

    };
  }, [sectionID]);

  useEffect(() => {

    dispatch(getHolidayBookingList(pageNum, null, "booked"));
    dispatch(getHolidayBookingList(pageNum, null, "confirmed"));
    dispatch(getHolidayBookingList(pageNum, null, "cancelled"));
    dispatch(getHolidayBookingList(pageNum, null, "follow_up"))
    dispatch(getHolidayBookingList(pageNum, null, "refunded"))
    
  }, []);

  useEffect(() => {
    return () => {
      dispatch(myHolidayBookingStartDate(null));
      dispatch(myHolidayBookingEndDate(null))
    }
  }, []);
  useEffect(() => {
    if (isDataLoading) {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector('.enquiry-card-holder')?.classList.add('pe-none')
    } else {
      document.querySelector("html").style.overflow = "scroll";
      document.querySelector('.enquiry-card-holder')?.classList.remove('pe-none')
    }
  }, [isDataLoading]);

  const handleTabChange = (key) => {
    console.log(key);
    if(key === '2') {
      setStatus('confirmed');
      dispatch(getHolidaysStatus("confirmed"));
      dispatch(pageId(1));
      if(sectionID !== null && holidayConfirmedSearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'confirmed'));
      else if(ffStartDate !== null || ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "confirmed",holidayBookingSearchVal, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "confirmed",holidayBookingSearchVal,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getHolidayBookingList(1, sectionID, 'confirmed'))
      }
    }
    else if(key === '3') {
      dispatch(pageId(1));
      setStatus('cancelled');
      dispatch(getHolidaysStatus("cancelled"));
      if(sectionID !== null && holidayCancelledSearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'cancelled'));
      else if(ffStartDate !== null || ffEndDate !== null){
       dispatch(holidayBookingsSearch(pageNum, "cancelled",holidayBookingSearchVal, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "cancelled",holidayBookingSearchVal,sectionID, ffStartDate, ffEndDate));
      }
      else{
        dispatch(getHolidayBookingList(1, sectionID, 'cancelled'))
      }
    }else if(key === '4'){
      dispatch(pageId(1));
      setStatus('requested');
      dispatch(getHolidaysStatus("requested"));
      if(sectionID !== null && holidayAvailabilitySearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'requested'));
      else if(ffStartDate !== null || ffEndDate !== null){
       dispatch(holidayBookingsSearch(pageNum, "requested",holidayAvailabilitySearchVal, sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "requested",holidayAvailabilitySearchVal,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getHolidayBookingList(1, sectionID, 'requested'));
      }
    }
    else if(key === "1"){
      setStatus('booked');
      dispatch(getHolidaysStatus("booked"));
      dispatch(pageId(1));
      
      if(sectionID !== null && holidayBookingSearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'booked'));
      else if(ffStartDate !== null || ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "booked",holidayBookingSearchVal,sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        dispatch(holidayBookingsSearch(pageNum, "booked",holidayBookingSearchVal,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getHolidayBookingList(1, sectionID, 'booked'))
      }
      
    }
    else if(key === "5"){
      setStatus('follow_up');
      dispatch(getHolidaysStatus("follow_up"));
      dispatch(pageId(1));
      
      if(sectionID !== null && holidayFollow_upSearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'follow_up'));
      else if(ffStartDate !== null || ffEndDate !== null){
        console.log("hit1");
        dispatch(holidayBookingsSearch(pageNum, "follow_up",holidayFollow_upSearchVal,sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        console.log("hit2");
        dispatch(holidayBookingsSearch(pageNum, "follow_up",holidayFollow_upSearchVal,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getHolidayBookingList(1, sectionID, 'follow_up'))
      }
      
    }

    else if(key === "6"){
      setStatus('refunded');
      dispatch(getHolidaysStatus("refunded"));
      dispatch(pageId(1));
      
      if(sectionID !== null && holidayFollow_upSearchVal === "" && ffStartDate === null && ffEndDate === null)dispatch(getHolidayBookingList(pageNum, sectionID, 'refunded'));
      else if(ffStartDate !== null || ffEndDate !== null){
        console.log("hit1");
        dispatch(holidayBookingsSearch(pageNum, "refunded",holidayFollow_upSearchVal,sectionID, ffStartDate, ffEndDate));
      }else if(sectionID !== null && ffStartDate !== null && ffEndDate !== null){
        console.log("hit2");
        dispatch(holidayBookingsSearch(pageNum, "refunded",holidayFollow_upSearchVal,sectionID, ffStartDate, ffEndDate));
      }else{
        dispatch(getHolidayBookingList(1, sectionID, 'refunded'))
      }
      
    }

  };
  
  const handleStartDateChange = (date) => {
    dispatch(myHolidayBookingStartDate(moment(date).format("YYYY-MM-DD")));
    dispatch(holidayBookingsSearch(pageNum, status,holidayBookingSearchVal, sectionID, moment(date).format("YYYY-MM-DD"), ffEndDate));
  };

  const handleEndDateChange = (date) => {
    dispatch(myHolidayBookingEndDate(moment(date).format("YYYY-MM-DD")));
    dispatch(holidayBookingsSearch(pageNum, status, holidayBookingSearchVal, sectionID, ffStartDate, moment(date).format("YYYY-MM-DD")));
  };

  const handleStartClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayBookingStartDate(null));
  };
  
  const handleEndClear = (e) => {
    e.stopPropagation();
    dispatch(myHolidayBookingEndDate(null));
    dispatch(getHolidayBookingList(pageNum, null, "booked"));
    dispatch(getHolidayBookingList(pageNum, null, "confirmed"));
    dispatch(getHolidayBookingList(pageNum, null, "cancelled"));
    dispatch(getHolidayBookingList(pageNum, null, "follow_up"));
    dispatch(getHolidayBookingList(pageNum, null, "refunded"));
  };

  return (
    <>
    <div style={{ position: "absolute", right: 50 }}>
      <Datefilter status={status}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        ffStartDate={ffStartDate}
        ffEndDate={ffEndDate}
        handleStartClear={handleStartClear}
        handleEndClear={handleEndClear}
      />
    </div>
    <div className="enquiry-tabs-holder">
        <div style={{display:'flex'}}>
          <p className="title-holder">{totalHolidayBookings} Holidays Bookings</p>
          <div>
            <DropdownFilter />
          </div>
        </div>
      <Tabs defaultActiveKey="4" onChange={handleTabChange}>
      <TabPane tab = {`${availabilityReqList.total_results} Requests`} key="4">
          <Availability/> 
        </TabPane>
        <TabPane tab = {`${follow_upLength} Follow-Up`} key="5">
          <FollowUp/> 
        </TabPane>
        <TabPane tab = {`${bookLength} Booked`} key="1">
          <Bookings/> 
        </TabPane>
        <TabPane  tab = {`${confirmLength} Confirmed`} key="2">
          <Confirmed/> 
        </TabPane>
        <TabPane tab = {`${cancelledLength} Cancelled`} key="3">
          <Cancelled/> 
        </TabPane>
        <TabPane tab = {`${refundlength} Refunded`} key="6">
          <Refunded/> 
        </TabPane>
      </Tabs>
      {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      <div>
    </div>
    </div>
  </> 
  );
};

export default HolidaysBookingIndex;