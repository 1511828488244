//Gifts: My Family First Constants
export const GIFT_REQUESTED = 'Gift Requested';
export const PREFERRED_LOCATIONS = 'Preferred Locations';
export const BOOK_FOR = 'Booked For';
export const MESSAGE = 'Message';
export const REQUESTED_BY = 'Requested By';
export const CONTACT_NUMBER = 'Contact Number';
export const CONTACT_EMAIL = 'Contact Email';
export const CONTACT_INFO = 'Contact Info';
export const MORE_INFO = 'More Info';
export const FAMILY_REQUEST = 'My Family First Requests';
export const SCHEDULE_MY_FAMILY = 'Schedule';

//button text constants
export const CANCEL = 'Cancel';
export const UPDATE = 'Update';
export const DONE = 'Done';
export const DELETE = 'Delete';

// status Change message
export const UPDATE_SUCCESS = 'Udated successfully.';
export const VARIENT = 'success';

//delete confirmation message
export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete...?'
// export const SECTION_PACK_EXIST_MESSAGE = 'You have ?'

// error validation text
export const PACKAGE_TITLE_EMPTY_ERROR_VALIDATION = 'Please add package title'
export const SECTION_NAME = 'Please select section'
export const PACKAGE_TITLE_LENGTH_ERROR_VALIDATION = 'Package title should be less than 75 characters'
export const IMAGE = 'Please add atlest one image'
export const LOCATION_EMPTY = 'Please enter location'
export const LOCATION_EMPTY_LENGTH = 'location should be less than 200 characters'
export const POINTS_EMPTY = 'Please add points'
export const POINTS_EMPTY_LENGTH = 'points should be less than six digits'
export const SCHEDULE = 'Please add schedule'
export const BRIEF_DESCRIPTION_EMPTY = 'Please add brief description'
export const BRIEF_DESCRIPTION_EMPTY_LENGTH = 'Brief description should be less than 300 characters'
export const VALID_TILL = 'Please add valid till date'
export const DESCRIPTION = 'Please add some description'
export const TITLE = 'Please add title'
export const TITLE_LENGTH = 'Title should be less than 75 characters'
export const PRICE = 'Please add original rate'
export const PRICE_LENGTH = 'Original rate should not be greater than six digits'
export const MEETING_ID = 'Please add meeting id'
export const REVISED_PRICE = 'Please add Discounted Rate'
export const REVISED_PRICE_LENGTH = 'Discounted Rate should not be greater than six digits'
export const REVISED_PRICE_ERROR = 'Discounted Rate should not be same or greater than original original rate'
export const LOCATION_TYPE_EMPTY = 'Please add location type'
export const LOCATION_TYPE_CHAR_LIMIT = 'You have exceeded 20 characters limit'
export const GUEST_EMPTY = 'Please add min and max'
export const GUEST_ERROR = 'Min should not be greater than max'
export const ACTIVITY_TYPE_EMPTY = 'Please add activity type'
export const ACTIVITY_LEVEL_EMPTY = 'Please add activity level'
export const AGES_EMPTY = 'Please add age'
export const ITINERARY_EMPTY = 'Please add itinerary desc'
export const ACCOMODATION_EMPTY = 'Please add accomodations'
export const ESSENTIAL_INFO_EMPTY = 'Please add essential info'
export const FAQ = 'Please add FAQ'
export const FEATURED_AMENITIES_ERROR = 'Please select 4 featured amenities'
export const COUNTRY_ERROR = 'Please add country'
export const CITY_ERROR = 'Please add city'


export const EMPLOYEE_NAME = 'Please add employee name'
export const EMPLOYEE_MOBILE = 'Please add employee mobile number'
export const EMPLOYEE_EMAIL = 'Please add employee email id'
export const ORGANISATION_NAME ='Please add organisation name'
export const ORGANISATION_NAME_LENGTH = 'You have exceeded 50 characters'
export const ADDRESS ='Please add address'
export const ORGANISATION_DOMAIN ='Please add valid organisation domain'
export const LOGO = 'Please add a logo'


export const THUMBNAIL_IMAGE_ERROR = 'Please selest thumbnail image'
export const PARTNER_NAME ='Please add partner name'
export const PARTNER_DOMAIN ='Please add valid email'
export const PARTNER_NAME_LENGTH = 'You have exceeded 50 characters'

