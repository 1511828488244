import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import * as EnquiryAction from "../../../actions/enquiryAction";
import { statusValues } from "../../../resources/enquiryStatus";
import SearchBar from "./searchBar";
import { useSnackbar } from "notistack";


export default function Enquiry({ enquiryData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const getPageNum = useSelector((state) => state?.enquiryListing?.page_num);
  const enquiriesSearchVal = useSelector((state) => state?.enquiryListing?.corpEnqSearchValue);
  const startDate = useSelector((state) => state.enquiryListing.filterStartDate);
  const endDate = useSelector((state) => state.enquiryListing.filterEndDate);
  const [state, setState] = React.useState({
    status: undefined,
  });

  useEffect(() => {
    if(enquiriesSearchVal === "" && 
      startDate === null && endDate === null && 
      enquiryData?.next_page_id === null && 
      enquiryData?.current_page > 1 && 
      enquiryData?.results.length === 0) 
    {
      dispatch(EnquiryAction.getEnquiriesList(1));
      dispatch(EnquiryAction.setPageNum(1, "enquiry"));
    }else if(enquiriesSearchVal !== "" && 
      enquiryData?.next_page_id === null && 
      enquiryData?.current_page > 1 && 
      enquiryData?.results.length === 0)
    {
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "enquiry", 1))
      dispatch(EnquiryAction.setPageNum(1, "enquiry"));
    }else if(startDate !== null && endDate !== null && 
      enquiryData?.next_page_id === null && 
      enquiryData?.current_page > 1 && 
      enquiryData?.results.length === 0)
    {
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "enquiry", 1));
      dispatch(EnquiryAction.setPageNum(1, "enquiry"));
    }else if(enquiriesSearchVal !== "" && startDate !== null && endDate !== null && 
    enquiryData?.next_page_id === null && 
    enquiryData?.current_page > 1 && 
    enquiryData?.results.length === 0){
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "enquiry", getPageNum));
      dispatch(EnquiryAction.setPageNum(1, "enquiry"));
    }
  }, [enquiryData]);
  
  const enquiryStatusChange = (id, status) => {
    setState({ ...state, status });
    dispatch(EnquiryAction.updateEnquiryStatus(id, status, refreshEnquiries));
    enqueueSnackbar("Updated successfully.", {
      variant: "success",
    });
    dispatch(EnquiryAction.getEnquiriesList(getPageNum));
    dispatch(EnquiryAction.getEnquiryFollowuplist(getPageNum));
    dispatch(EnquiryAction.getResolvedEnquirylist(getPageNum));
  };
  const refreshEnquiries = () => {
    if(enquiriesSearchVal !== ""){
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "enquiry", getPageNum));
      dispatch(EnquiryAction.getEnquiryFollowuplist(getPageNum));
      dispatch(EnquiryAction.getResolvedEnquirylist(getPageNum));
    }else if(startDate !== null && endDate !== null){
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "enquiry", getPageNum));
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "follow-up", getPageNum));
      dispatch(EnquiryAction.searchEnquiry(enquiriesSearchVal, "resolved", getPageNum));
    }
    else{
      dispatch(EnquiryAction.getEnquiriesList(getPageNum));
      dispatch(EnquiryAction.getEnquiryFollowuplist(getPageNum));
      dispatch(EnquiryAction.getResolvedEnquirylist(getPageNum));
    }
  };

  return (
    <div>
      <SearchBar status="enquiry" searchValue={enquiriesSearchVal}/>
      {enquiryData?.results.length === 0 && 
       <div className="enquiry-card-holder">No Record Found!</div>
      }
      <div className="overflow--scroll" style={{ marginBottom: '10px' }}>
        {enquiryData?.results &&
          enquiryData?.results.length > 0 &&
          enquiryData?.results.map((item) => (
            <div className="enquiry-card-holder">
              <div className="organization-details" style={{width: "450px"}}>
              <span style={{display:"block", color: "#2196F3"}}>{item?.ref_id? `#${item.ref_id}`: "No ref id"}</span>
                <h1>{item.organisation}</h1>
                <div className="info">
                  <p>
                    Enquired on {moment(item.created).format("Do MMMM, YYYY, h:mm a")}
                  </p>
                  <p>Enquired by {item.emp_name}</p>
                </div>
              </div>
              <div className="contact-info">
                <div className="title">
                  <p>Contact info</p>
                </div>
                <div className="contact-details">
                  <p className="email">{item.email}</p>
                  <p>{item.mobile}</p>
                </div>
              </div>
              <div className="contact-info">
                <div className="title">
                  <p>Message</p>
                </div>
                <div className="contact-details">
                  <p className="message-limit" title={item.message}>{item.message}</p>
                </div>
              </div>
              <div>
                <select
                  className="status-dropdown"
                  value={item?.status}
                  onChange={(event) =>
                    enquiryStatusChange(item?.id, event.target.value)
                  }  >
                  {statusValues.length > 0 &&
                    statusValues.map((options) => (
                      <option value={options?.value}>{options?.name}</option>
                    ))}
                </select>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
