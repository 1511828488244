import StoryForm from '../../gifts/myFamilyFirst/storyForm';
import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import * as weddingAction from '../../../actions/weddingAction';
import { useSnackbar } from "notistack";

const EditStory = ({storyDetails, storyDetailsId,setSearchVal})=>{
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const initErrors = {
        title: {
            hasError:false,
            message:''
        },
        media: {
            hasError:false,
            message:''
        },
        description: {
            hasError:false,
            message:''
        }
    };
    const [errors, setErrors] = useState(initErrors);

    const changeError = (name, hasError, message)=>{
        setErrors({
            ...errors,
            [name]: {
                hasError,
                message
            }
        });
    };
    const resetErrors = ()=>{
        setErrors(initErrors);
    };
    const[toDisable,setToDisable]=useState(false)
    const handleSuccess = (id)=>{
        console.log('successfully updated');
        enqueueSnackbar("Updated Successfully", {
            variant: "success",
          });
        setSearchVal('')  
        dispatch(weddingAction.weddingStoryList());
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
    const handleForm = (formDetails)=>{
        setToDisable(true)
        const isDescEmpty = () => {
            const createTag = document.createElement('div')
            createTag.innerHTML = formDetails.description
            return createTag.innerText.length < 5
        }  
        console.log(isDescEmpty(), "desc")
        
        if(!formDetails.title){ 
            
            changeError('title', true, 'Please enter Title');
            return false;
        }
        if(formDetails.title.length>75){ 
            
            changeError('title', true, 'Package title should be less than 75 characters');
            return false;
        }
        if(!formDetails.media.length){ 
            
            changeError('media', true, 'Please select atleast one image');
            return false;
        }
        if(isDescEmpty()){ 
            
            changeError('description', true, 'Please enter Description');
            return false;
        }

        let submitData = {
            title:formDetails.title,
            description:formDetails.description,
            media:formDetails.media.map((img, index)=>{
                if(img.modified){
                    return {
                        ...img,
                        priority:index+1
                    };
                } else {
                    return {
                        image:img.image || '',
                        video:img.video || '',
                        thumbnail:img.thumbnail,
                        priority:index+1,
                        media_type:img.media_type,
                        alt_text:img.alt_txt,
                        title_text:img.title_txt
                    }
                }
            })
        }
        dispatch(weddingAction.updateStory(storyDetailsId, submitData, handleSuccess));
        setTimeout(()=>{setToDisable(false)},[2000])
    };
    
    const handleDelSuccess = ()=>{
        setSearchVal('')  
        dispatch(weddingAction.weddingStoryList());
    }
    const deleteStory = ()=>{
        if (window.confirm("Are you sure to delete the story!") == true) {
            dispatch(weddingAction.deleteStory(storyDetailsId, handleDelSuccess));
          } else {
            
          }
        
    };
    return (
        <div>
            <StoryForm errors={errors} type="Edit" resetErrors={resetErrors} storyDetails={storyDetails} handleForm={handleForm} storyDetailsId={storyDetailsId} deleteStory={deleteStory} fromWeddings={true} toDisable={toDisable}/>
        </div>
    )
};

export default EditStory;
