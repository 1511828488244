
import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut,
  axiosGetWithToken,
  axiosDelete,
  axiosMediaUpload,
  axiosMediaUploadWithProgress
} from "../services/apiServices";
import { 
  WEDDING_STORY_LIST,
  WEDDING_STORY_DETAILS,
  WEDDING_REQUESTED,
  WEDDING_REQUESTED_TOTAL_PAGE,
  WEDDING_REQUESTED_TOTAL_RESULTS,
  WEDDING_CONFIRMED,
  WEDDING_PLANNING,
  WEDDING_CANCELLED,
  WEDDING_SEARCH_TERM,
  WEDDING_START_DATE,
  WEDDING_END_DATE,
  WEDDING_PAGE_NO,
  WEDDING_DESTINATION_LIST,
  WEDDING_IMAGE_UPLOAD,
  WEDDING_DESTINATION_CREATE,
  IS_LIST_DATA_LOADING,
  IS_DATA_LOADING
} 
from '../actions/types';
import { message } from "antd";

export const weddingStoryList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
       "page": 1, 
       "list_per_page": 50
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.WEDDING_STORY_LIST}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: WEDDING_STORY_LIST,
            payload: res.data.data.results,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const searchStory = (searchVal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    let body = {
      "filters":[{
        "field":"search_term", 
        "value": searchVal
      }]
    }
    dispatch({
      type: IS_LIST_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.WEDDING_STORY_SEARCH}`, body, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: WEDDING_STORY_LIST,
            payload: res.data.data,
          });
          dispatch({
            type: IS_LIST_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};
// newStory

export const newStory = (formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPostWithToken(`${API.WEDDING_STORY_ADD}`, formData,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};

export const deleteStory = (storyId, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosDelete(`${API.WEDDING_STORY_DELETE}${storyId}/`,  token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200 || res.data.status_code === 201) {
          handleSuccess();
        }
      })
  };
};
// deleteStory

export const weddingStoryDetails = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosGetWithToken(`${API.WEDDING_STORY_DETAILS}${id}/`, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          dispatch({
            type: WEDDING_STORY_DETAILS,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const updateStory = (id, formData, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    console.log('test');
    axiosPut(`${API.WEDDING_STORY_UPDATE}${id}/`, formData, token)
      .then((res) => {
        console.log('res',res);
        if (res.data.status_code === 200) {
          console.log(res);
          handleSuccess(id);
        }
      })
  };
};

// ***********Request action***********
export const getWeddingRequested = (page) => {
  console.log("page", page)
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
         "field":"status",
         "value":"requested"
        }],
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.WEDDING_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: WEDDING_REQUESTED,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
      })
  };
};

export const getWeddingPlanning = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[{
        "field":"status",
        "value":"planning"
       }],
       "sort":{"field":"modified", "order":"D"}, 
      "page": page, 
      "list_per_page": 50
    }
    dispatch({
      type: IS_DATA_LOADING,
      payload: true,
    });
    axiosPostWithToken(`${API.WEDDING_REQUEST}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: WEDDING_PLANNING,
            payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

        }
        
      })
  };
};

export const getWeddingConfirmed = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"confirmed"
     }],
    "sort":{"field":"modified", "order":"D"},
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.WEDDING_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
        type: WEDDING_CONFIRMED,
        payload: res.data.data,
        });
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });

        
      }
      })
  };
};

export const getWeddingCancelled = (page) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "filters":[{
      "field":"status",
      "value":"cancelled"
     }],
     "sort":{"field":"modified", "order":"D"}, 
    "page": page, 
    "list_per_page": 50
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.WEDDING_REQUEST}`, body, token)
      .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
          type: WEDDING_CANCELLED,
          payload: res.data.data,
          });
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });

          
        }
      })
  };
};

export const searchWeddingRequests = ( searchTerm, startDate, endDate, status,  page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "filters":[
        {"field":"search_term", "value": searchTerm},
        {"field":"status", "value": status},
        {"field":"from", "value": startDate},
        {"field":"to", "value": endDate}
      ],
      "sort":{"field":"modified", "order":"D"},
      "page": page, 
      "list_per_page": 50
  
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
    axiosPostWithToken(`${API.WEDDING_REQUEST_SEARCH}`, body, token)
    .then((res) => {
      if(res?.data?.status_code === 200){
        if(status == "requested"){
          dispatch({
            type: WEDDING_REQUESTED,
            payload: res?.data?.data
          })
        }else if(status == "planning"){
          dispatch({
            type: WEDDING_PLANNING,
            payload: res?.data?.data
          })
        }else if(status == "confirmed"){
          dispatch({
            type: WEDDING_CONFIRMED,
            payload: res?.data?.data
          })
        }else{
          dispatch({
            type: WEDDING_CANCELLED,
            payload: res?.data?.data
          })
        }
        dispatch({
          type: IS_DATA_LOADING,
          payload: false,
        });
       
      }
    })
  }
}

export const setSearchTerm = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WEDDING_SEARCH_TERM,
      payload: value
    })
  }
}

export const setStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: WEDDING_START_DATE,
      payload: date,
    });
  };
};
  
export const setEndDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: WEDDING_END_DATE,
      payload: date,
    });
  };
};

export const setPageNo = (page) => {
  return async (dispatch) => {
    dispatch({
      type: WEDDING_PAGE_NO,
      payload: page,
    });
  };
};

export const weddingRequestStatusChange = (status, id,cancelling_message, refereshList) => {

  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    cancelling_message:cancelling_message,
    
  };
  axiosPut(`${API.WEDDING_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};
export const weddingRequestStatusChangeConfirmed = (status, id,amount, refereshList) => {
 
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "status": status,
    "price":amount
    
  };
  axiosPut(`${API.WEDDING_REQUEST_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
        refereshList()
        }
        
      })
  };
};

//************  Destination **********/
export const getWeddingDestinationList = () => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "sort":{"field":"priority", "order":"A"},
    "page": 1, 
    "list_per_page": 50 
  }
  dispatch({
    type: IS_DATA_LOADING,
    payload: true,
  });
  axiosPostWithToken(`${API.WEDDING_DESTINATION_LIST}`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          dispatch({
            type: WEDDING_DESTINATION_LIST,
            payload: res?.data?.data?.results
          })
          dispatch({
            type: IS_DATA_LOADING,
            payload: false,
          });
        }
        
      })
  };
};

export const addWeddingDestination = (data, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "title_text":data?.title_text,
    "alt_text":data?.alt_text
}
  axiosPostWithToken(`${API.WEDDING_DESTINATION_CREATE}`, body, token)
    .then((res) => {
      if (res.data.status_code === 201) {
          success()
        }
        
      })
  };
};

export const updateWeddingDestination = (data, id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  const body = {
    "location": data?.location,
    "image": data?.image,
    "priority": data?.priority,
    "title_text":data?.title_text,
    "alt_text":data?.alt_text
}
  axiosPut(`${API.WEDDING_DESTINATION_UPDATE}${id}/`, body, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          success()
        }
        
      })
  };
};

export const deleteWeddingDestination = (id, success) => {
  return async (dispatch, getState) => {
  const token = getState().auth.token;
  
  axiosDelete(`${API.WEDDING_DESTINATION_DELETE}${id}/`, token)
    .then((res) => {
      if (res.data.status_code === 200) {
          success()
        }
        
      })
  };
};

export const weddingImageUpload = (file, addImage) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    function blob2file(blobData) {
      const entries = new FormData();
      entries.set('file', blobData, "fileName.jpeg");
      return entries.get('file');
    }

    axiosMediaUpload(API.WEDDING_IMAGE_VIDEO_UPLOAD, "image", blob2file(file), token)
    .then((res) => {
      dispatch({
        type: WEDDING_IMAGE_UPLOAD,
        payload: res.data,
      });
      addImage(res.data.data);
    })
    .catch((err) => {
      console.log("ERROR_ALERT", err);
    });
  };
};

export const videoUploadWedding = (file, addVideo, obj, newArr, getUploadProgress, id) => {
    
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);

    const token = getState().auth.token;
    console.log(getUploadProgress);
    axiosMediaUploadWithProgress(API.WEDDING_IMAGE_VIDEO_UPLOAD, "video", file, token, getUploadProgress, id)
      .then((res) => {
        addVideo(res.data.data, obj, newArr);
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};

export const deleteWeddingImage = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.WEDDING_IMAGE_VIDEO_DELETE}${id}/`,{"type" : "wedding"}, token)
      .then((res) => {
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};