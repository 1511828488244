import React, { useState } from "react";
import PlusIcon from "../../assets/images/plusIconSquare.svg";
import PopulardestinationCad from "./populardestinationCad";



const PopularDestinations = () => {

    const[addNew,setAddNew]=useState(false)
    const[loc_type,setLoc_type]=useState("state")
    const handleAddNew=()=>{
        setAddNew(true)
    }

  


    return (
        <>
            <div className="main-popular-detinations">
                <div className="popular-destinations">
                    <h3>Popular Destinations</h3>
                    <div style={{position:"relative"}}>
                    <select onChange={(e)=>{setLoc_type(e.target.value)}} className="selectLoc">
                        <option value="state">State</option>
                        <option value="country">Country</option>
                    </select>
                </div>

                    </div>
                   
                
                <button className="add-destination-button" onClick={handleAddNew}>
                    Add new destination{" "}
                    <img
                        src={PlusIcon}
                        className="plusIcon"
                        alt="plusIcon"
                    ></img>{" "}
                </button>
                
                <PopulardestinationCad addNew={addNew} setAddNew={setAddNew} loc_type={loc_type}/>
            </div>
            <div>
               
            </div>
        </>
    );
};

export default PopularDestinations;
