import React from "react";
import Button from "@material-ui/core/Button";
import { Box, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

export default function AltTitleModal({
  altText,
  setAltText,
  imageTitle,
  setImageTitle,
  show,
  set_Alt_title_modal,
  setImageSet,
  displayOptions = {}, 

}) {
const {
  
    removeButtonText = "Remove",
    confirmButtonText = "Confirm",
    showRemoveButton = true,
    showConfirmButton = true,
  } = displayOptions;

function handleConfirm() {
    set_Alt_title_modal(false)
    setImageSet(true)
 
  }
function handleRemove(){
    setImageTitle("")
    setAltText("")
    set_Alt_title_modal(false)

  }
   
  return (
    <Modal open={show} >
      <div
        className="card-box card-box-index"
        style={{ 
          width: "40%", 
          backgroundColor: "white",
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center",
          marginTop:"5px",
          height:"55%",
          marginLeft:"25%%",
          position:"absolute",
          top:"15%",
          left:"30%"
        }}
      >
    <Box style={{marginTop:"10px"}}>
              <TextField
                label="Image Title"
                variant="outlined"
                fullWidth
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
                style={{ marginBottom: 10 }}
              />
              <TextField
                label="Alt Text"
                variant="outlined"
                fullWidth
                value={altText}
                onChange={(e) => setAltText(e.target.value)}
              />
            </Box>



        <Box style={{display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",marginBottom:"10px"}}>
          {showRemoveButton && (
            <Button variant="secondary" onClick={handleRemove} style={{ backgroundColor:"#e2e2e2",
              width: "49%",
              height: "38px",
              borderRadius: "2px",
              opacity: "0.8",
              color: "#fff",
              fontWeight: "600",
              padding:"0 10px",
              color:"black",
              fontSize: "larger"}}>
              {removeButtonText}
            </Button>
          )}
          {showConfirmButton && (
            <Button variant="primary" onClick={handleConfirm} style={{ backgroundColor:"#007BFF",
            width: "49%",
            height: "38px",
            borderRadius: "2px",
            opacity: "0.8",
            color: "#fff",
            fontWeight: "600",
            fontSize: "larger"}}>
            {confirmButtonText}
            </Button>
          )}
        </Box>
      </div>
    </Modal>
  );
}
