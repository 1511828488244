import { API } from "../constants/api";
import axios from "axios";
import {
  axiosPostWithToken,
  axiosMediaUpload,
  axiosPatchWithToken,
} from "../services/apiServices";

export const SET_ADD_WEB_STORIES = "SET_ADD_WEB_STORIES"
export const ADD_WEBSTORIES_IMAGE="ADD_WEBSTORIES_MEDIA"
export const ADD_WEBSTORIES="ADD_WEBSTORIES"
export const GET_WEB_STORIES_LISTS="GET_WEB_STORIES_LISTS"


export const setAddWebStories = (value) => {
    return async (dispatch) => {
      dispatch({
        type: "SET_ADD_WEB_STORIES",
        payload: value,
      });
    };
  };

  export const editDetails = (value) => {
    return async (dispatch) => {
      dispatch({
        type: "EDITABLE_DETAILS",
        payload: value,
      });
    };
  };
  export const editData=(data)=>{
    return async (dispatch) => {
      dispatch({
        type: "UPDATED_PAYLOAD",
        payload: data,
      });
     };

  }

export const mediaWebstories = (file,setVisibleMedia,setLoading) => {
  return async (dispatch, getState) => {
    const entries = new FormData();
    entries.append("media", file);
    const token = getState().auth.token;
    axiosMediaUpload(API.HOLIDAY_IMAGE_UPLOAD, "image", file, token)
      .then((res) => {
        setVisibleMedia(true)
        setLoading(false)
        dispatch({
          type: "ADD_WEBSTORIES_IMAGE",
          payload: res.data.data,
        });
        
      })
      .catch((err) => {
        console.log("ERROR_ALERT", err);
      });
  };
};
export const emptyMedia=()=>{
  return async (dispatch, getState) => {
  dispatch({
    type: "EMPTY_MEDIA",
    payload: "",
  });
}

}

export const handleAddNewWebStories = (data,refreshReload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_WEBSTORIES}`, data, token)
      .then((res) => {
        if (res.data.status_code === 201) {
          dispatch({
            type: "ADD_WEBSTORIES",
            payload: res.data.data,
          });
          refreshReload()
         
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const handleUpdatewWebStories = (data,refreshReload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPatchWithToken(`${API.UPDATE_WEBSTORIES}`, data, token)
      .then((res) => {
        if (res.data.status_code === 201) {
          dispatch({
            type: "UPDATE_WEBSTORIES",
            payload: res.data.data,
          });
          refreshReload()

         
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getWebStoreisLists = (pageNo) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = {
      Authorization: `${token}`,
    };

    const queryParams = {
      rec_per_page: 8,
      page_no: pageNo,
    };
    
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    try {
      const response = await fetch(`${API.WEB_STORIES_LISTS}?params=${encodeURIComponent(JSON.stringify(queryParams))}`, requestOptions);
      if (response.status === 200) {
        const responseData = await response.json();
        dispatch({
          type: "GET_WEB_STORIES_LISTS",
          payload: responseData.data,
        });
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const getSearchStory = (searchKey) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = {
      Authorization: `${token}`,
    };

    const queryParams = {
      rec_per_page: 8,
      page_no: 1,
      title:searchKey,
    };
    
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    try {
      const response = await fetch(`${API.WEB_STORIES_LISTS}?params=${encodeURIComponent(JSON.stringify(queryParams))}`, requestOptions);
      if (response.status === 200) {
        const responseData = await response.json();
        dispatch({
          type: "GET_WEB_STORIES_LISTS",
          payload: responseData.data,
        });
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const deleteStorySlide = (mainId,slideIds,refreshReload) => {
 
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = {
      "Content-Type": "application/json" ,
      Authorization: `${token}`,
    };

    const requestBody = JSON.stringify({
      id: mainId,
      story_detail: slideIds,
    });
  
    
    const requestOptions = {
      method: 'DELETE',
      headers: headers,
      body:requestBody

    
    };

    try {
      const response = await fetch(`${API.WEB_STORIES_LISTS}`, requestOptions);
      if (response.status === 200) {
        const responseData = await response.json();
        refreshReload()
        dispatch({
          type: "DELETE_SLIDE",
          payload: responseData.data,
        });
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};









