import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";



const StructureDialog = ({isDialogOpen, handleClose, type, module, id, editData,cid,allTestimonialList,setStructureData,structureData})=>{
    const [dataInput,setDataInput]=useState()
    const submitStructureData=()=>{
    setStructureData(dataInput)
    handleClose()
    }

    return (
        <div>
            <Dialog open={isDialogOpen} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title" >
                    {allTestimonialList?'Editt':'Add new'}  structure data
                    
                    <button type="button" onClick={handleClose} className="btn btn-link" style={{position:'absolute', right:'3%'}}>Close</button>
                </DialogTitle>
                <DialogContent>
                   <div>
                  
                    <div className="row">
                    <div className="col">
                        <div className="form-group form-custom mt-3">
                            <label for="">Description </label>
                        </div>
                        <textarea style={{width:"100%",height:"300px",overflow:"auto",resize:"none"}} onChange={(e)=>setDataInput(e.target.value)}>{structureData}</textarea>
                        </div>
                    </div>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                         <button onClick={submitStructureData} className="btn btn-primary btn-save mr-3">
                            Done 
                        </button>
                </DialogActions>
            </Dialog>
            
        </div>
    );
};

export default StructureDialog;