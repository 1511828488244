import React, { useEffect, useState } from 'react'
import AdminListing from '../../../common/adminListing';
import SearchBar from '../../../common/commonSearchBar';
import { experiencesConfirmedStatusValues } from '../../../resources/status';
import { useDispatch, useSelector } from 'react-redux';
import { experienceSearchValue, getExperienceBookingList, experienceBookingsSearch ,experienceBookingStatusUpdate, pageId} from '../../../actions/experienceBookingAction';
import Pagination from '../../../common/pagination';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index';
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Confirmed = () => {
    const [ showMoreInfo, setShowMoreInfo ] = useState(false);
    const [ moreInfoData, setMoreInfoData ] = useState({});
    const[averageBookingAmount,setAverageBookingAmount]=useState()
  const[totalbookingAmount,setTotalbookingAmount]=useState()
  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
    // const [ searchVal, setSearchVal ] = useState('');
    const [id, setId] = useState(null);
    const[iscopr,setIscorp]=useState()
    const[status,setStatus]=useState()

    const searchVal = useSelector((state) => state.experienceBooking.experiencelConfirmedSearchVal);
    const [ pageVal, setPageVal ] = useState(1);
    const ffStartDate = useSelector((state) => state.experienceBooking.experienceBookingStartDate);
    const ffEndDate = useSelector((state) => state.experienceBooking.experienceBookingEndDate);
    const experienceConfirmed = useSelector(state => state.experienceBooking.experienceConfirmed.results);
    const totapPage = useSelector(state => state.experienceBooking.experienceConfirmed.total_pages);
    const pageNum = useSelector(state => state.experienceBooking.pageNum);
    const sectionID = useSelector(state => state.experienceBooking.experienceBookingsSecId);
    const experienceConfirmNxtPgeId = useSelector(state => state.experienceBooking.experienceConfirmNxtPgeId);
    const experienceConfirmCurrentPgeId = useSelector(state => state.experienceBooking.experienceConfirmCurrentPgeId);

    const dispatch = useDispatch();

    useEffect(()=>{
        const data=experienceConfirmed?.map((elem)=>{
            return (parseInt(elem.amount_paid))
        })
        const totalamount=data?.reduce((acc,elem)=>{
            return acc+elem
        },0)
        setTotalbookingAmount(totalamount)
        setAverageBookingAmount(Math.round(totalamount/experienceConfirmed?.length))

    },[experienceConfirmed])


    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showMoreInfo]);

    useEffect(() => {
        if (experienceConfirmNxtPgeId === null && experienceConfirmCurrentPgeId > 1 && experienceConfirmed?.length === 0) {
            dispatch(getExperienceBookingList(1, sectionID, "confirmed"));
            dispatch(pageId(1));
            setPageVal(1);
        }
    }, [experienceConfirmed, pageVal])
    
    const handlePagination = (e, value) => {
        if(searchVal !== "" || ffStartDate !== null && ffEndDate !== null){
            dispatch(experienceBookingsSearch(value, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate))
            dispatch(pageId(value));
        }else{
            dispatch(getExperienceBookingList(value, sectionID, "confirmed"));
            dispatch(pageId(value));
        }
    };

    const moreInfoHandler = (data) => {
        setMoreInfoData(data);
        setShowMoreInfo(true);
    };

    const handleChange = (event) => {
        let input = event.target.value
        // setSearchVal(event.target.value);
        dispatch(experienceBookingsSearch(pageNum, 'confirmed', event.target.value, sectionID, ffStartDate, ffEndDate));
        dispatch(experienceSearchValue(input, "confirmed"));
        dispatch(pageId(1));
    };

    const refreshExperienceConfirmed = () => {
        if (searchVal !== '') {
            dispatch(experienceBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"))
        } else if(ffStartDate !== null && ffEndDate !== null){
            dispatch(experienceBookingsSearch(pageNum, 'cancelled', searchVal, sectionID, ffStartDate, ffEndDate));
            dispatch(experienceBookingsSearch(pageNum, 'confirmed', searchVal, sectionID, ffStartDate, ffEndDate));
        } else {
            dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"))
            dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"))
            dispatch(getExperienceBookingList(pageNum, sectionID, "booked"))
        }
    }

    const statusChangeHandler = (event, id, corporate, requestsType, data) => {
        setCloseRequestModalData(data)
        let status = event.target.value;

        setStatus(status)
        setId(id)
        setIscorp(corporate)
        if(status === "cancelled")setRequestModal(true);
        
        else {
            dispatch(experienceBookingStatusUpdate(status, id, corporate,sectionID,null, refreshExperienceConfirmed))
        // refreshExperienceConfirmed();
        // dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"));
    }
}
    const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
  dispatch(experienceBookingStatusUpdate(status, id,iscopr,sectionID,closeRequestMessage, refreshExperienceConfirmed))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    const experienceBookingDisplayData = experienceConfirmed && experienceConfirmed.map(data => (
        <AdminListing 
            packTitle={data.pack_title}
            address={data.address}
            amount={isCorporate(data.corporate, data.total_points, data.amount_paid)}
            schedule={Array.isArray(data.schedule) ? data.schedule.sort().map((scheduleDate, index) => {
                if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
                  return scheduleDate
                } else {
                  return `${scheduleDate}, `
                }
              }) : (typeof data.schedule === 'object' && data.schedule !== null ? `${data.schedule.from} ${data.schedule.to==false?"":"to" } ${data.schedule.to==false?"": data.schedule.to}` : (data.schedule ===  null ? "N/A" : data.schedule))}
            noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
            messageTitle={data.special_request ? data.special_request : 'No Message'}
            specialRequest={data.special_request?data.special_request:"N/A"}
            isExperienceBooking={true}
            value={data.booking_status}
            corporate={data.corporate}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            statusChangeHandler={statusChangeHandler}
            statusValues={experiencesConfirmedStatusValues}
            onClick={() => moreInfoHandler(data)}
            voucher_info={data?.voucher_info}
        />
    ));

    return (
        <>
        <SearchBar
            searchVal={searchVal}
            handleChange={handleChange}
        />
       
      <p style={{display:"flex",}}><span><b>Total Order Value: {" "} {totalbookingAmount?Math.round(totalbookingAmount):""}</b> </span>

 <span style={{marginLeft:"80px"}}><b> 
 Average Order Value: {" "} {averageBookingAmount?Math.round(averageBookingAmount):""}
 </b>
  </span></p>
        {experienceBookingDisplayData}
        {showMoreInfo && 
          <CommonMoreInfoModel 
          open={showMoreInfo}
          modalClose={() => setShowMoreInfo(false)}
          moreInfoData={moreInfoData}
          isCancellationMessage={false}
          />
        }
        <Pagination 
            totalPage={totapPage} 
            page={pageNum} 
            onChange={(event, value) => handlePagination(event, value)}
        />
           {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
        </>
    );
};

export default Confirmed;
