import React from 'react'
import Pagination from '@material-ui/lab/Pagination';

const CommonPagination = ({totalPage, onChange, page}) => {
    return (
        <Pagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={onChange}
        />
    )
};

export default CommonPagination;
