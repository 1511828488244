import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { familyFirstSearchRequested } from '../actions/myFamilyFirstAction';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    padding: 6,
    borderRadius: 3,
  },
}));

const SearchBar = ({ status , parentCallback}) => {
  const [userInput, setUserInput] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    // const newVal = (e.target.value);
    // setUserInput(newVal);
    // parentCallback(newVal);
    setUserInput(e.target.value);
    dispatch(familyFirstSearchRequested(e.target.value, status));
  };
  
  return (
    <TextField
      className={classes.input}
      defaultValue={""}
      size="small"
      value={userInput}
      placeholder={"Search"}
      fullWidth={true}
      onChange={handleChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default SearchBar;