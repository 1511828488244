import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getEventSection } from '../../../actions/eventSectionAction';
import { getEventList, eventsListingDropDownVal, pageId, eventSearchValue } from '../../../actions/eventsAction';
import {setAddPackage} from '../../../actions/corpListingAction'
const DropdownFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("Showing All");
  const eventSectionLists = useSelector(state => state.eventSection.getEventSection);
  const pageID = useSelector((state) => state.eventsListing.pageId);
  const dispatch = useDispatch();

  const handleClose = (item) => {
    setDropdownValue(item?.name);
    setAnchorEl(null);
    dispatch(getEventList(1, item?.id ));
    dispatch(eventsListingDropDownVal(item));
    dispatch(pageId(1));
    dispatch(eventSearchValue(""));;
  };

  const handleEventClick = (event) => {
    dispatch(getEventSection());
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
       <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" 
         onClick={handleEventClick} 
         className="dropdown-filter"
           >
           {dropdownValue ? dropdownValue : 'Showing All'}
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem onClick={handleClose}>Show All</MenuItem>
           {eventSectionLists && eventSectionLists.map(item => {
              return (
                <MenuItem
                  onClick={() => handleClose(item)}
                  key={item.id} 
                  >
                  {item.name}
                </MenuItem>
              );
              })}
         </Menu>
      </div> 
    </div>
  )
}

export default DropdownFilter