import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Edit from "../../../assets/images/edit.svg";
import { FaFileUpload } from "react-icons/fa";
// import ImageMultiSelect from "./imageMultiSelect/imageMultiSelect";
import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import * as EventListingAction from "../../../actions/eventsAction";

import Menu from "@material-ui/core/Menu";
import JoditEditor from "jodit-react";
import ShedulePopup from "./addNewSchedulePopUp";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "../../../assets/css/partials/addCorpPackageDetails.scss";
import "../../../assets/css/partials/events/listingAddNewEvent.scss";
import "../../../assets/css/partials/packageDetails.scss";
import { useSnackbar } from "notistack";
import { removeSpaces } from "../../../utils";
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  SECTION_NAME,
  TITLE,
  TITLE_LENGTH,
  IMAGE,
  MEETING_ID,
  PRICE,
  PRICE_LENGTH,
  SCHEDULE,
  BRIEF_DESCRIPTION_EMPTY,
  BRIEF_DESCRIPTION_EMPTY_LENGTH,
  VALID_TILL,
  DESCRIPTION,
  THUMBNAIL_IMAGE_ERROR
} from "../../../utils/constants";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function AddNewEvent() {
  const [imageSelect, setImageSelect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [locationSelect, setLocationSelect] = useState(false);

  const [popupSelect, setPopupSelect] = useState(false);
  const [content, setContent] = useState("");
  const [select, setSelect] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isGiftVoucher, setIsGiftVoucher] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [savingNewEvent, setSavingNewEvent] = useState(false);
  const [isSectionNameErrorValidation, setIsSectionNameErrorValidation] =
    useState(null);
  const [
    isAddNewEventErrorEmptyValidation,
    setIsAddNewEventErrorEmptyValidation,
  ] = useState(null);
  const [isAddEventLengthErrorValidation, setIsAddEventLengthErrorValidation] =
    useState(null);
  const [isAddEventImageErrorValidation, setIsAddEventImageErrorValidation] =
    useState(null);
  const [isAddEventThumbnailImageErrorValidation, setIsAddEventThumbnailImageErrorValidation] =
    useState(null);
  const [
    isAddNewEventPriceErrorValidation,
    setIsAddNewEventPriceErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventPriceLengthErrorValidation,
    setIsAddNewEventPriceLengthErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventBriefDesErrorValidation,
    setIsAddNewEventBriefDesErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventBriefDesLengthErrorValidation,
    setIsAddNewEventBriefDesLengthErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventMeetingIdErrorValidation,
    setIsAddNewEventMeetingIdErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventScheduleErrorValidation,
    setIsAddNewEventScheduleErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventValidErrorValidation,
    setIsAddNewEventValidErrorValidation,
  ] = useState(null);
  const [
    isAddNewEventDescriptionErrorValidation,
    setIsAddNewEventDescriptionErrorValidation,
  ] = useState(null);

  const addImage = useSelector(
    (state) => state.eventsListing.allImagesEventsData
  );
  const selectedMenuDropdown = useSelector(
    (state) => state.eventsListing?.selectedEventsDropdown
  );
  const imagesWithoutId = addImage;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(EventListingAction.getEventList());
  // }, []);

  const handleClickSelect = (event) => {
    dispatch(EventListingAction.selectEventsListDropdown());
    setSelect(event.currentTarget);
  };

  const handleCloseSelect = (item) => {
    setDropdownValue(item);
    setSelect(null);
    if (item?.gift_voucher === true) {
      setIsGiftVoucher(true);
    } else {
      setIsGiftVoucher(false);
    }
  };

  const [state, setState] = useState({
    section: null,
    corp_id: null,
    pack_id: null,
    title: "",
    pack_title: "",
    brief_description: "",
    note: null,
    images: [],
    stream_in: "",
    meeting_id: "",
    schedule_type: "",
    schedule_data: {},
    valid_till: "",
    price: null,
    best_seller: false,
    description: "",
  });

  const meetingIdhandleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const priceHandler = (key, e) => {
    setState(() => ({
      ...state,
      [key]: e.target.value,
    }));
  };
  const noteHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const briefDescriptionHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const validTillHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const dateSheduleHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const newDataSubmithandler = (e) => {
    e.preventDefault();
    setIsSectionNameErrorValidation(false);
    setIsAddNewEventErrorEmptyValidation(false);
    setIsAddEventLengthErrorValidation(false);
    setIsAddEventImageErrorValidation(false);
    setIsAddEventThumbnailImageErrorValidation(false);
    setIsAddNewEventPriceErrorValidation(false);
    setIsAddNewEventPriceLengthErrorValidation(false);
    setIsAddNewEventBriefDesErrorValidation(false);
    setIsAddNewEventBriefDesLengthErrorValidation(false);
    setIsAddNewEventMeetingIdErrorValidation(false);
    setIsAddNewEventScheduleErrorValidation(false);
    setIsAddNewEventValidErrorValidation(false);
    setIsAddNewEventDescriptionErrorValidation(false);
    let finalImages = imagesWithoutId.map((item, index) => {
        let sImg = {
        image: item.image,
        video:item.video,
        thumbnail: item.thumbnail,
        priority:index+1,
        media_type:item.media_type
      };
      return sImg;
    });
    const addNewData = {
      section: dropdownValue?.id,
      pack_title: state.pack_title,
      brief_description: state.brief_description,
      note: state.note,
      images: finalImages,
      meeting_id: removeSpaces(state.meeting_id),
      schedule_type: "specific_dates",
      schedule_data: {
        to: state?.schedule_data?.to,
        date: state?.schedule_data?.date,
        from: state?.schedule_data?.from,
      },
      valid_till: state.valid_till,
      price: state.price,
      best_seller: state.best_seller,
      workshop: state.workshop,
      description: content,
    };
    const htmltoText = (html) => {
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
      text = text.replace(/<\/div>/gi, "");
      text = text.replace(/<\/li>/gi, "");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "");
      text = text.replace(/<\/p>/gi, "");
      text = text.replace(/<br\s*[\/]?>/gi, "");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, "");
      text = text.replace(/ +/gi, "");
      text = text.replace(/\n+/gi, "");
      text = text.replace('&nbsp;', "");
      return text;
    };
  if(htmltoText(content).replaceAll(' ', '').trim().length===0){
    setIsAddNewEventDescriptionErrorValidation(true);
      return;
  }
    if (dropdownValue === null) setIsSectionNameErrorValidation(true);
    else if (state.pack_title === "")
      setIsAddNewEventErrorEmptyValidation(true);
    else if (state.pack_title.length > 75)
      setIsAddEventLengthErrorValidation(true);
    else if (imagesWithoutId.length === 0)
      setIsAddEventImageErrorValidation(true);
    else if (!imageThubnail)
    setIsAddEventThumbnailImageErrorValidation(true);
    else if (state.price === "" || state.price === null)
      setIsAddNewEventPriceErrorValidation(true);
    else if (state.price.length > 6)
      setIsAddNewEventPriceLengthErrorValidation(true);
    else if (state.brief_description === "")
      setIsAddNewEventBriefDesErrorValidation(true);
    else if (state.brief_description.length > 300)
      setIsAddNewEventBriefDesLengthErrorValidation(true);
    else if (state.meeting_id === "")
      setIsAddNewEventMeetingIdErrorValidation(true);
    else if (
      Object.entries(state.schedule_data).length === 0 ||
      state.schedule_data === undefined  ||
      state.schedule_data?.date.length===0
    )
      setIsAddNewEventScheduleErrorValidation(true);
    else if (state.valid_till === "")
      setIsAddNewEventValidErrorValidation(true);
    // else if (content === "" || content === undefined)
    //   setIsAddNewEventDescriptionErrorValidation(true);
    else {
      setSavingNewEvent(true);
      dispatch(EventListingAction.handleAddNewEvent(addNewData, refreshData));
    }
    dispatch(EventListingAction.eventListsAddBool(true));
    // dispatch(EventListingAction.getEventList());
  };

  const refreshData = () => {
    setState({
      pack_title: "",
      brief_description: "",
      note: null,
      images: [],
      location: [],
      schedule_type: "",
      schedule_data: {},
      valid_till: "",
      price: "",
      best_seller: false,
      description: "",
    });
    // setSavingNewEvent(false);
    // dispatch(EventListingAction.getEventList());
    // dispatch(EventListingAction.setAddEventPackage(false));
    enqueueSnackbar("Package added successfully", {
      variant: "success",
    });
    window.location.reload()
  };

  const editor = useRef(null);

  // all options from https://xdsoft.net/jodit/doc/
  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
    controls: {
      font: false,
    }
  };


  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

  const setSchedules = (date, from, to) => {
    const startTimeString = `${from.hour}:${from.minute}`;
    const endTimeString = `${to.hour}:${to.minute}`;
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: { to: to, date: date, from: from },
      };
    });
    setPopupSelect(false);
  };

  const handleBsetSeller = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleUpdatePackageImage = (data) => {
    setState({
      ...state,
      images: data,
    });
  };

  const imageThubnail = addImage.find((img) => img.thumbnail === true);

  return (
    <div>
      {savingNewEvent&&<div style={{marginLeft:10, marginBottom:-3}}><LinearProgress  /></div> }
      <div className="packageDetails-holder">
      
        <form onSubmit={newDataSubmithandler}>
          <div className="form-options">
            <div>
              <h2>Add Event</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
              <div
                className="section-dropdown"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "auto",
                }}
              >
                <div>
                  <Button
                    style={{ paddingRight: "32px" }}
                    aria-controls="section-menu"
                    aria-haspopup="true"
                    onClick={handleClickSelect}
                  >
                    {dropdownValue?.name?  dropdownValue?.name : "Select section"}
                    <img
                      alt="arrow"
                      src={ArrowDown}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "12px",
                      }}
                    />
                  </Button>
                  <Menu
                    id="section-menu"
                    anchorEl={select}
                    keepMounted
                    open={Boolean(select)}
                    onClose={() => setSelect(null)}
                  >
                    {selectedMenuDropdown &&
                      selectedMenuDropdown.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => handleCloseSelect(item)}
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              </div>
              <div>
                <button type="submit" disabled={savingNewEvent} className="btn btn-primary btn-save">
                  Save
                </button>
              </div>
            </div>
          </div>
          {isSectionNameErrorValidation && (
            <p style={{ textAlign: "right", marginRight: "15%", color: "red" }}>
              {SECTION_NAME}
            </p>
          )}
          <div>
            <div className="form-group form-custom">
              <label for=""> Title</label>
              <input
                value={state?.pack_title}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("pack_title", value)}
              />
              <img
                src={Edit}
                style={{
                  position: "absolute",
                  right: "46px",
                  top: `${isSectionNameErrorValidation ? "141px" : "124px"}`,
                }}
              />
              {isAddNewEventErrorEmptyValidation ? (
                <p style={{ color: "red" }}>{TITLE}</p>
              ) : isAddEventLengthErrorValidation ? (
                <p style={{ color: "red" }}>{TITLE_LENGTH}</p>
              ) : (
                ""
              )}
            </div>
            {/*Image UI Section*/}
            <div style={{ display: "flex", justifyContent: "space-between",marginBottom: "100px" }}>
              <div style={{ flexDirection: "column" }}>
                <div
                  onClick={() => setImageSelect(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                  }}
                >
                  <img
                    alt=""
                    src={imageThubnail?imageThubnail.image:DefaultImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    style={{
                      color: "white",
                      position: "absolute",
                      bottom: 20,
                      left: 80,
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "#525252",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    {addImage.length > 0 ? addImage.length : 0} images / videos
                  </h6>
                  {isAddEventImageErrorValidation ?
                    <p style={{ color: "red" }}>{IMAGE}</p>: isAddEventThumbnailImageErrorValidation?<p style={{ color: "red", marginBottom: "15px" }}>{THUMBNAIL_IMAGE_ERROR}</p>:""
                }
                </div>
                <div
                  style={{ flex: 0.5, position: "relative", marginTop:'23px' }}
                  className="form-group form-custom"
                >
                  <label for="">Price</label>
                  <h6
                    style={{
                      position: "absolute",
                      left: "38px",
                      top: "53px",
                      fontWeight: "400",
                      opacity: 0.4,
                    }}
                  >
                    Rs
                  </h6>
                  <div
                    style={{
                      position: "absolute",
                      paddingBottom: "36px",
                      height: "17%",
                      width: "1px",
                      left: "80px",
                      backgroundColor: "rgb(202 194 194)",
                    }}
                  />
                  <input
                    style={{ paddingLeft: "75px" }}
                    value={state?.price}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="text"
                    className="form-control"
                    onChange={(value) => priceHandler("price", value)}
                  />
                  {isAddNewEventPriceErrorValidation ? (
                    <p style={{ color: "red" }}>{PRICE}</p>
                  ) : isAddNewEventPriceLengthErrorValidation ? (
                    <p style={{ color: "red" }}>{PRICE_LENGTH}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{alignItems:"flex-end"}}>
                  <label for="" style={{ marginLeft:"17px"}}>Note</label>
                  <p style={{ marginLeft:"17px", fontSize:"12px", fontWeight:"400", lineHeight:"14px", opacity:"0.4",color:"#000000"}}>{state?.note != null ? state?.note.trim().length : 0}/100</p>
                  </div>
                  <textarea
                    style={{ height: "98px" }}
                    maxlength="100"
                    value={state?.note}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      noteHandler("note", value)
                    }
                  />
                </div>
                <div
                  style={{ flex: 0.5, height: "35%" }}
                  className="form-group form-custom"
                >
                  <label for="">Brief Description</label>
                  <textarea
                    style={{ height: "70%" }}
                    value={state?.brief_description}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      briefDescriptionHandler("brief_description", value)
                    }
                  />
                  {isAddNewEventBriefDesErrorValidation ? (
                    <p style={{ color: "red" }}>{BRIEF_DESCRIPTION_EMPTY}</p>
                  ) : isAddNewEventBriefDesLengthErrorValidation ? (
                    <p style={{ color: "red" }}>
                      {BRIEF_DESCRIPTION_EMPTY_LENGTH}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {/*end of col 1*/}
              </div>
              {/* Second Column */}
              <div style={{ flexDirection: "column", flex: "2" }}>
                <div
                  className="form-group form-custom"
                  style={{ position: "relative", flex: 1, marginLeft: 10 }}
                >
                  <label for="">Meeting ID</label>
                  <input
                    value={removeSpaces(state.meeting_id)}
                    type="text"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(value) =>
                      meetingIdhandleChange("meeting_id", value)
                    }
                  />
                  <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "55px" }}
                  />
                  {isAddNewEventMeetingIdErrorValidation && (
                    <p style={{ color: "red" }}>{MEETING_ID}</p>
                  )}
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                  className="form-group form-custom"
                >
                  <label for="">Schedule</label>
                  <input
                    value={
                      (state?.schedule_data?.date
                        ? `${state?.schedule_data?.date} ,  `
                        : " ") +
                      (state?.schedule_data?.from
                        ? `${tConvert(state?.schedule_data?.from)} -`
                        : "") +
                      (state?.schedule_data?.to
                        ? `${tConvert(state?.schedule_data?.to)} `
                        : "")
                    }
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      dateSheduleHandler("schedule_data", value)
                    }
                  />
                  <img
                    src={Edit}
                    style={{ position: "absolute", right: "35px", top: "55px" }}
                    onClick={() => setPopupSelect(true)}
                  />
                  {isAddNewEventScheduleErrorValidation && (
                    <p style={{ color: "red" }}>{SCHEDULE}</p>
                  )}
                  {popupSelect && (
                    <ShedulePopup
                      openPopup={popupSelect}
                      closeModal={setPopupSelect}
                      updatePopupData={setSchedules}
                    />
                  )}
                </div>
                <div
                  style={{ flex: 0.5, marginLeft: 10 }}
                  className="form-group form-custom"
                >
                  <div>
                    <label for="">Valid till</label>
                    <input
                      value={state?.valid_till}
                      type="date"
                      className="form-control"
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(value) =>
                        validTillHandler("valid_till", value)
                      }
                    />
                    {isAddNewEventValidErrorValidation && (
                      <p style={{ color: "red" }}>{VALID_TILL}</p>
                    )}
                  </div>
                  </div>
                  <div className="form-group toggle-container" style={{ marginTop: "10px", marginLeft: "10px"}}>
                    <label for="" style={{ fontSize: "12px", opacity: 0.4, fontWeight: 400 }}>Highlighting</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="start"
                          control={
                            <PurpleSwitch
                              checked={state?.workshop}
                              size="small"
                              color="primary"
                              name="workshop"
                              onChange={handleBsetSeller}
                            />
                          }
                          label="Is this a Workshop?"
                          labelPlacement="start"
                        />
                      </FormControl>
                    </div>
                  </div>
                
              </div>{" "}
              {/*last line of col 2*/}
            </div>
            <div className="form-group form-custom mt-3">
              <label for="">Description</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={configJoditEditor}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
              {isAddNewEventDescriptionErrorValidation && (
                <p style={{ color: "red" }}>{DESCRIPTION}</p>
              )}
            </div>
          </div>
        </form>
      </div>
      {imageSelect && (
        <ImageMultiSelect
          title={state?.pack_title}
          images={addImage || []}
          open={imageSelect}
          toggleModal={setImageSelect}
          handleCloseDone={() => setImageSelect(false)}
          handleUpdatePackageImage={handleUpdatePackageImage}
          isAddNewEvent={true}
          isUpdateEvent={false}
          moduleType={'event'}
        />
      )}
    </div>
  );
}
