import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBookedPayments, getPaymentBookings } from '../../../actions/paymentLinkAction';
import Pagination from '../../../common/pagination';
import Bookings from './bookings';
import { BookedWithPointAndRs, checkNumberOfGuest } from '../../../utils/index';

const Booked = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1)
    const BOOKING_LIST = useSelector(state => state.paymentLinkBookings.bookedPaymentList?.results)
    const TOTAL_PAGES = useSelector(state => state.paymentLinkBookings.bookedPaymentList?.total_pages)

    const handlePagination = (e,value) => {
        setPage(value);
        dispatch(getBookedPayments(value))
      };
  return (
    <>
        {BOOKING_LIST && BOOKING_LIST?.length > 0 && 
          BOOKING_LIST?.map((data) =>  (
            <Bookings 
              pack_title={data?.pack_title}
              contact_name = {data?.contact_name}
              contact_email = {data?.email}
              data={data}
              amount={`Booked with ${BookedWithPointAndRs(data.total_points, data.amount_paid)}`}
              statusValue={[{
                name: 'Booked',
                value: 'booked'
            },]}
            />
            
          ))
          }
          <Pagination 
              totalPage={TOTAL_PAGES} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />
    </>
  )
}

export default Booked