import React,{ useEffect, useState } from 'react';
import MyFamilyFirstBooking from '../../../../common/myFamilyFirstBookings';
import SearchBar from '../../../../common/commonSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import MyFamilyFirstMoreInfoModal from '../../../gifts/myFamilyFirst/myFamilyFirstMoreInfoModal';
import Pagination from '../../../../common/pagination';
import * as HoneymoonAction from '../../../../actions/honeymoonAction'
import ConfirmModal from '../../../wedding/requests/confirmModal';
import ClosedModal from '../../closeRequestModal';
import { closeRequestInputVal } from '../../../../actions/holidayRequestAction';

const Requested = ({status}) => {

    const [ showModal, setShowModal ] = useState(false);
    const [ requestedMoreInfoModalData, setRequestedMoreInfoModalData ] = useState({});
    const [ page, setPage ] = useState(1);

    const[ confirmedRequestModalData,setConfirmedRequestModalData]=useState({})
    const [isconfirmAmountBool,setIsconfirmAmountBool]=useState(null)
    
    const [ id, setId ] = useState(null);
    const[statuschange,setStatuschange]=useState()

    const[requestModalConfirm,setRequestModalConfirm]=useState(false)
    const[confirmedRequestAmount,setConfirmedRequestAmount]=useState("")

    const [ searchVal, setSearchVal ] = useState('');
    const honeymoonRequestedData = useSelector(state => state.honeymoon?.honeymoonRequested?.results);
    const totalPage = useSelector(state => state.honeymoon?.honeymoonRequested?.total_pages);
    const dispatch = useDispatch();

  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);

    const START_DATE = useSelector(state => state.honeymoon.startDate);
    const END_DATE = useSelector(state => state.honeymoon.endDate);
    const SEARCH_TERM = useSelector(state => state.honeymoon.searchTerm);
    const PAGE_NO = useSelector(state => state.honeymoon.pageNo);

    useEffect(() => {
        window.scrollTo(0, 0);
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);
    
    const handleMoreInfoClick = (data) => {
        setShowModal(true);
        setRequestedMoreInfoModalData(data);
    };
    useEffect(() => {
      setPage(1)
    }, [status])

    const refereshList = () => {
        dispatch(HoneymoonAction.getHoneymoonRequested(page));
        dispatch(HoneymoonAction.getHoneymoonPlanning(1));
        dispatch(HoneymoonAction.getHoneymoonConfirmed(1));
        dispatch(HoneymoonAction.getHoneymoonCancelled(1));
    };

  

    const requestStatusChange = (event, id,data) => {
      setCloseRequestModalData(data)
      setId(id);
      setConfirmedRequestModalData(data)
        let status = event.target.value;
        setStatuschange(status)
  
        if(status === "confirmed")setRequestModalConfirm(true);
        else if(status === "cancelled")setRequestModal(true);
        else{
          dispatch(HoneymoonAction.honeymoonRequestStatusChange(status, id,null, refereshList))

        }
        
    };

    const confirmRequestHandler=()=>{
        
        
      setIsconfirmAmountBool(false)
     if(confirmedRequestAmount==="")setIsconfirmAmountBool(true)
      else {
      dispatch(HoneymoonAction.honeymoonRequestStatusChangeConfirm("confirmed", id,confirmedRequestAmount, refereshList))
        setRequestModalConfirm(false)
      }
      // dispatch(confirmRequestInputVal(''));
     
      
  
    }


    const notToConfirm=()=>{
   
      setRequestModalConfirm(false)
      setIsconfirmAmountBool(false);
    
    }

    const handlePagination = (e,value) => {
        setPage(value);
        dispatch(HoneymoonAction.setPageNo(value))
        if(SEARCH_TERM || START_DATE || END_DATE){
          dispatch(HoneymoonAction.searchHoneymoonRequests(SEARCH_TERM, START_DATE, END_DATE, "requested", value));
        }else{
          dispatch(HoneymoonAction.getHoneymoonRequested(value));
        }
    };

    const handleChange = (event) => {
      if(event.target.value){
        dispatch(HoneymoonAction.searchHoneymoonRequests(event.target.value, START_DATE, END_DATE, "requested", PAGE_NO));
      }else{
        dispatch(HoneymoonAction.getHoneymoonRequested(1));
        setPage(1);
      }
      dispatch(HoneymoonAction.setSearchTerm(event.target.value))
      };
      const closeRequestHandler = () => {
 
        setIsclosingMessageBool(false)
        if(closeRequestMessage === "")setIsclosingMessageBool(true)
        else {
          dispatch(HoneymoonAction.honeymoonRequestStatusChange(statuschange, id,closeRequestMessage, refereshList))
          setRequestModal(false)
        }
        dispatch(closeRequestInputVal(''));
      };
    
    const notToCancel=()=>{
        setRequestModal(false)
        setIsclosingMessageBool(false);
    
    }

    const honeymoonRequestedDisplayData = honeymoonRequestedData && honeymoonRequestedData.map((data) => (
        <MyFamilyFirstBooking 
            locationsPreferred={data.locations_preferred}
            occasion={data.occasion}
            requestedBy={`Requested by ${data.contact_name}`}
            email={data.contact_email}
            contactNum={data.contact_number}
            messageTitle={data.specific_request ? data.specific_request : 'No Message'}
            specialRequest={data.specific_request? data.specific_request:"N/A"}
            onClick={() => handleMoreInfoClick(data)}
            myFamilyFirstStatusChange={requestStatusChange}
            data={data}
            id={data.id}
            refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
            value={data.status}
        />
    ));

    return (
        <>
          <SearchBar
            searchVal={SEARCH_TERM}
            handleChange={handleChange}
          />
          {honeymoonRequestedData && honeymoonRequestedData?.length === 0 &&
                <div className="enquiry-card-holder">No Record Found!</div>
            }
          {honeymoonRequestedDisplayData}
          <MyFamilyFirstMoreInfoModal
            open={showModal}
            close={() => setShowModal(false)}
            modalData={requestedMoreInfoModalData}
            section={"Honeymoon Requested"}
            isCancellationMessage={false}
          />
            <Pagination 
              totalPage={totalPage} 
              page={page} 
              onChange={(event, value) => handlePagination(event, value)}
            />

{requestModalConfirm &&
        <ConfirmModal
        open={requestModalConfirm}
        handleClose={notToConfirm}
        data={confirmedRequestModalData}
        isconfirmAmountBool={isconfirmAmountBool}
        setConfirmedRequestAmount={setConfirmedRequestAmount}
        confirmRequestHandler={confirmRequestHandler}
        />

      }
         {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }


        </>
    );
};

export default Requested;
