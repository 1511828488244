import { API } from "../constants/api";
import {
    axiosGetWithToken,
    axiosPostWithToken,
    axiosPut,
    axiosDelete,
    axiosMediaUpload,
    axiosMediaUploadWithProgress
} from "../services/apiServices";

import {
    EVENT_LIST,
    GET_EVENT_INFO,
    EVENT_UPDATE,
    EVENTS_IMAGE_UPLOAD,
    ALL_IMAGES_EVENTS_DATA,
    EVENT_LIST_DROPDOWN,
    CANCEL_EVENT_INPUT_MESSAGE,
    SEARCH_EVENT_LISTING,
    PAGE_ID,
    EVENT_LISTINGS_UPDATE_BOOL,
    EVENT_LISTINGS_ADD_BOOL,
    EVENTS_LISTING_DROP_DOWN_VALUE,
    EVENT_LISTING_SEARCH_VALUE,
    SET_SELECTED_EVENTS_CHOSEN_ID,
    EVENT_STATUS_VALUE,
    EVENT_BOOKINGS_SECTION_ID,
    EVENT_UPCOMING_SEARCH_VALUE,
    EVENT_PAST_SEARCH_VALUE,
    EVENT_CANCELLED_SEARCH_VALUE
} from "./types";

export const SET_ADD_EVENT = "SET_ADD_EVENT";
export const SET_EDIT_EVENT_LIST_DETAILS= "SET_EDIT_EVENT_LIST_DETAILS";
export const UPCOMING_BOOKINGS_LIST = "UPCOMING_BOOKINGS_LIST";
export const PAST_BOOKINGS_LIST = "PAST_BOOKINGS_LIST";
export const CANCELLED_BOOKINGS_LIST ="CANCELLED_BOOKINGS_LIST";
export const EVENT_CATEGORY_ID="EVENT_CATEGORY_ID";
export const PAST_EVENT_CATEGORY_ID="PAST_EVENT_CATEGORY_ID";
export const CANCELLED_EVENT_CATEGORY_ID="CANCELLED_EVENT_CATEGORY_ID";
export const TRANSACTION_DETAILS="TRANSACTION_DETAILS";
export const UPCOMING_SEARCH_RESULTS="UPCOMING_SEARCH_RESULTS";
export const PAST_SEARCH_RESULTS= "PAST_SEARCH_RESULTS";
export const CANCELLED_SEARCH_RESULTS="CANCELLED_SEARCH_RESULTS";
export const EVENT_FILTER_START_DATE="EVENT_FILTER_START_DATE";
export const EVENT_FILTER_END_DATE="EVENT_FILTER_END_DATE";
export const SEC_ID="SEC_ID";
export const EVENT_PAGE_NUM="EVENT_PAGE_NUM";
export const BOOKINGS_EVENTS_LIST="BOOKINGS_EVENTS_LIST";
export const MOD_USER_BOOKINGS = "MOD_USER_BOOKINGS";
export const USER_SEARCH_BOOKINGS = "USER_SEARCH_BOOKINGS";
export const TESTIMONIAL_EVENTS_LIST = "TESTIMONIAL_EVENTS_LIST";

//EVENTS ADD NEW LISTING EVENT



  //ADD NEW EVENT LIST PANEL 
export const setAddEventPackage = (value) => {
    return async (dispatch) => {
        dispatch({
            type: SET_ADD_EVENT,
            payload: value,
        });
        // console.log("value", value);
        // showData()
    };
};

export const editEventListItem = (data) => {
    return async (dispatch) => {
      dispatch({
        type: SET_EDIT_EVENT_LIST_DETAILS,
        payload: data,
      });
    };
};
export const pageId = (page) => {
  return async (dispatch) => {
    dispatch({
      type:PAGE_ID,
      payload: page,
    })
  
  };
};

export const getEventList = (value, secId, loader) => {
    return async (dispatch, getState) => {
        const body = {
          next_page_id: value,
          sec_id: secId
        };
        const token = getState().auth.token;
        axiosPostWithToken(`${API.EVENTS_LIST}`, body, token)
            .then((res) => {
                if (res.data.status_code === 200) {
                  dispatch({
                    type: EVENT_LIST,
                    payload: res.data,
                  });
                  loader();
                }
            })
            .catch((err) => {
              // loader();
              // console.log("error", err);
            });
    };
};
 
export const handleAddNewEvent = (data, refreshList) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.EVENT_ADD_LISTING}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type:EVENT_LIST,
            payload: res.data,
          })
        }
        refreshList();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
// canceled event
export const cancelEvent = (id, inputVal, handleCancelSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body ={ 
      "event_id":id,
      "message":inputVal
    }
    axiosPostWithToken(`${API.CANCEL_EVENT}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EVENT_LIST,
            payload: res.data,
          });
        }
        handleCancelSuccess("Event Cancelled Successfully")
        //window.location.reload()
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};

export const  cancelEventInputVal = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANCEL_EVENT_INPUT_MESSAGE,
      payload: value,
    });
  }
}

export const deleteListingEventEditImg = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.DELETE_UPDATE_IMAGE}${id}/`, token)
      .then((res) => {
        //   if (res.data.status_code === 200) {
        //     dispatch({
        //       type: EVENT_LIST,
        //     });
        // }
        console.log("res", res)
      })
      .catch((err) => {
        console.log("error", err);
      });   
  };
};

//EVENTS_GET_EDIT

export const getEventsEditInfo = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        axiosGetWithToken(`${API.EVENTS_GET_EDIT}${id}`, token)
            .then((res) => {
                if (res.data.status_code === 200) {
                    dispatch({
                        type: GET_EVENT_INFO,
                        payload: res.data.data,
                    });
                } else {
                    //   dispatch({
                    //     type: ERROR_ALERT,
                    //     payload: res.data.data.message,
                    //   });
                }
            })
            .catch((err) => {
              
            });
    };
};

//EDIT flow Update
export const eventListUpdate = (data, loader, handleSuccess, urlId) => {
  console.log("data", data)
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const body = data;

        axiosPut(`${API.EVENTS_UPDATE_EDIT}${urlId}/`, body, token).then(
            (res) => {
                if (res.data.status_code === 200) {
                    console.log("UPDATE RESP", res);
                    // loader()
                    loader(data.event_id);
                    handleSuccess()

                    // dispatch({
                    //   type: POST_CORPORATES,
                    //   payload: res.data
                    // })
                    dispatch({
                        type: EVENT_LIST,
                        payload: res.data,
                    });
                    dispatch({
                        type: EVENT_UPDATE,
                        payload: res.data,
                    });
                } else if (res.data.status_code === 400) {
                    // handleError()
                }
            }
        );
    };
};

export const handleAllEventsImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ALL_IMAGES_EVENTS_DATA,
      payload: data,
    });
  };
};

export const imageUploadEvents = (file, addImage) => {
    return async (dispatch, getState) => {
      const entries = new FormData();
      entries.append("media", file);
  
      const token = getState().auth.token;
  
      axiosMediaUpload(API.EVENTS_IMAGE_UPLOAD, "image", file, token)
        .then((res) => {
          dispatch({
            type: EVENTS_IMAGE_UPLOAD,
            payload: res.data,
          });
          addImage(res.data.data);
        })
        .catch((err) => {
          console.log("ERROR_ALERT", err);
        });
    };
  };

  export const videoUploadEvents = (file, addVideo, obj, newArr, getUploadProgress, id) => {
    console.log(getUploadProgress);
    return async (dispatch, getState) => {
      const entries = new FormData();
      entries.append("media", file);
  
      const token = getState().auth.token;
      console.log(getUploadProgress);
      axiosMediaUploadWithProgress(API.EVENTS_VIDEO_UPLOAD, "image", file, token, getUploadProgress, id)
        .then((res) => {
          addVideo(res.data.data, obj, newArr);
        })
        .catch((err) => {
          console.log("ERROR_ALERT", err);
        });
    };
  };

  export const eventListingSearch = (input, secId, page) => {
    return async (dispatch, getState) => {
      const secId = getState().eventsListing.eventsListingDropDownvalue?.id
      const token = getState().auth.token;
      const pageId = getState().section.pageId;
      const body = {
        search_term:input,
        "next_page_id":page,
        sec_id:secId
      }
      axiosPostWithToken(`${API.EVENT_LISTING_SEARCH}`, body, token)
        .then((res) => {
          if(res.data.status_code === 200){
            dispatch({
              type: EVENT_LIST,
              payload: res.data,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR_ALERT", err);
        });
    };
  };
  //EVENTS LISTING SECTION DROPDOWN
  export const selectEventsListDropdown = () => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      axiosGetWithToken(`${API.EVENT_LIST_DROPDOWN}`, token).then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EVENT_LIST_DROPDOWN,
            payload: res.data.data,
          });
        }
      });
    };
  };

  //SHOWALL IN TOP EVENTS LIST

  export const getEventsFilteredList = (id) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        next_page_id: "1",
        sec_id: id
      }
      axiosPostWithToken(`${API.EVENTS_LIST}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch({
              type: EVENT_LIST,
              payload: res.data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            // type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    };
  };


// upcoming events bookings
export const getUpcomingBookingsList = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "sec_id": secId
  }
    axiosPostWithToken(`${API.UPCOMING_BOOKINGS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SEC_ID,
            payload: secId || null
          });
          dispatch({
            type: UPCOMING_BOOKINGS_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// past events bookings
export const getPastBookingsList = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "sec_id": secId
  }
    axiosPostWithToken(`${API.PAST_BOOKINGS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: PAST_BOOKINGS_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// cancelled events bookings
export const getCancelledBookingsList = (page, secId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "sec_id": secId
  }
    axiosPostWithToken(`${API.CANCELLED_BOOKINGS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: CANCELLED_BOOKINGS_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getUserBookingList = (page, id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page,
      "sec_id": ""
  }
    axiosPostWithToken(`${API.USER_BOOKINGS}${id}/`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: MOD_USER_BOOKINGS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};


export const userSearchBookingList = (search, id, status, page) => {
  return async (dispatch, getState) => {
    const body = {
      search_term: search,
      "next_page_id":page?page:1,
      "event_id": id,
      "status": status,
    };
    const token = getState().auth.token;
    axiosPostWithToken(`${API.USER_SEARCH_BOOKINGS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: MOD_USER_BOOKINGS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
//  events bookings dropdown filter

export const getEventBookingsFilteredList = (id, status, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "next_page_id": page || 1,
      "sec_id": id,
      "event_type": status
     }
     axiosPostWithToken(`${API.SEARCH_EVENTS_BOOKINGS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
           dispatch({
             type: SEC_ID,
             payload: id
           })
          if(status === "upcoming"){
            dispatch({
              type: UPCOMING_SEARCH_RESULTS,
              payload: res.data,
            });
          }
         else if(status === "past"){
            dispatch({
              type: PAST_SEARCH_RESULTS,
              payload: res.data,
            });
          }
          else if(status === "cancelled"){
            dispatch({
              type: CANCELLED_SEARCH_RESULTS,
              payload: res.data,
            });
          }
          // dispatch({
          //   type: CANCELLED_BOOKINGS_LIST,
          //   payload: res.data,
          // });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
}


// POST call to get transaction details 

export const getTransactionDetails = (id, pay_mode) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "txnid": id,
      "pay_mode": pay_mode
  }
    axiosPostWithToken(`${API.VERIFY_BOOKING}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: TRANSACTION_DETAILS,
            payload: res.data?.data?.transaction_details?.[id],
          });
        }
      })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
}

// PUT call to update the bookings status

export const updateBookingStatus = (id, value, status, refreshData, amount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "booking_id": id,
      "corporate": status,
      "status": value,
      "amount": amount ? amount : 0.00
  }
  axiosPut(`${API.UPDATE_EVENT_BOOKING_STATUS}`, body, token)
      .then((res) => {
        console.log(res);
        if (res.data.status_code === 200) {
          refreshData();
        }
        if (res.data.status_code === 400) {
          refreshData({hasError:true, message:res?.data?.data?.message});
        }
      })
      .catch((err) => {
         console.log("err",err)
      });
  };
}

//

export const searchBooking = (text, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const startDate = getState().eventsListing.filterStartDate;
    const endDate = getState().eventsListing.filterEndDate;
    const secId =  getState().eventsListing.getSecId
    let body = {
      search_term: text,
      from: startDate,
      to: endDate,
      sec_id: secId ? secId : null,
      next_page_id: 1,
      event_type: status
    };

    axiosPostWithToken(API.SEARCH_EVENTS_BOOKINGS, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if(status === "upcoming"){
          dispatch({
            type: UPCOMING_SEARCH_RESULTS,
            payload: res.data,
          });
        }
       else if(status === "past"){
          dispatch({
            type: PAST_SEARCH_RESULTS,
            payload: res.data,
          });
        }
        else if(status === "cancelled"){
          dispatch({
            type: CANCELLED_SEARCH_RESULTS,
            payload: res.data,
          });
        }
        
      }
       else {
        // console.log("res", res);
      }
    });
  };
};

// get list of bookings for individual event 

export const getBookingList = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.EVENTS_BOOKING_LIST}${id}`, token)
    .then((res) => {
        if (res.data.status_code === 200) {
            dispatch({
                type: BOOKINGS_EVENTS_LIST,
                payload: res.data.data,
            });
        }
    })
    .catch((err) => {
       console.log(err);
    });
  }
}

// set upcoming category id
export const  setCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_CATEGORY_ID,
      payload: id,
    });
  }
}

// set page number
export const  setPageNum = (id) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_PAGE_NUM,
      payload: id,
    });
  }
}


// set past category id
export const  setPastCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: PAST_EVENT_CATEGORY_ID,
      payload: id,
    });
  }
}

// set cancelled category id
export const  setCancelledCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CANCELLED_EVENT_CATEGORY_ID,
      payload: id,
    });
  }
}

// date filters

export const filterStartDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EVENT_FILTER_START_DATE,
      payload: date,
    });
  };
};

export const filterEndDate = (date) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EVENT_FILTER_END_DATE,
      payload: date,
    });
  };
};
export const eventListsUpdateBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_LISTINGS_UPDATE_BOOL,
      payload: bool,
    });
  };
};
export const eventListsAddBool = (bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EVENT_LISTINGS_ADD_BOOL,
      payload: bool,
    });
  };
};

export const eventsListingDropDownVal = (data) => {
  return async (dispatch) => {
    dispatch({
      type: EVENTS_LISTING_DROP_DOWN_VALUE,
      payload: data,
    });
    // showData()
  };
};

export const eventSearchValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_LISTING_SEARCH_VALUE,
      payload: value,
    });
  };
};

export const setChosenPackIDBool = (bool) => {
  return async (dispatch) => {
    dispatch({
      type:SET_SELECTED_EVENTS_CHOSEN_ID,
      payload: bool,
    })
  };
};

export const getEventStatus = (statusVal) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_STATUS_VALUE,
      payload: statusVal,
    });
  };
};

export const bookingsSectionId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: EVENT_BOOKINGS_SECTION_ID,
      payload: id,
    });
  };
};

export const eventBookingSearchValue = (value, status) => {
  return async (dispatch) => {
    if(status === "upcoming"){
      dispatch({
        type: EVENT_UPCOMING_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "past"){
      dispatch({
        type: EVENT_PAST_SEARCH_VALUE,
        payload: value,
      });
    }else if(status === "cancelled"){
      dispatch({
        type: EVENT_CANCELLED_SEARCH_VALUE,
        payload: value,
      });
    }
  };
};


export const getEventTestimonials = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_TESTIMONIAL_LIST}${id}`, token)
    .then((res) => {
        if (res.data.status_code === 200) {
            dispatch({
                type: TESTIMONIAL_EVENTS_LIST,
                payload: res.data.data,
            });
        }
    })
    .catch((err) => {
       console.log(err);
    });
  }
}

// POST call to add new testimonial 

export const postNewTestimonial = (data, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = data;
    axiosPostWithToken(`${API.POST_TESTIMONIAL}`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
}

//Update call to edit Testimonal

export const updateTestimonial = (data, tid, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "guest_name": data.guest_name,
      "guest_image": data.guest_image,
      "message": data.message
  };
    axiosPut(`${API.UPDATE_TESTIMONIAL}${tid}/`, body, token)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleError();
      });
  };
}

export const deleteTestimonials = (id, handleSuccess, handleError) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.DELETE_TESTIMONIAL}${id}`, token)
    .then((res) => {
      
        handleSuccess();
    })
    .catch((err) => {
        handleError();
    });
  }
}



