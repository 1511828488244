import React from 'react';


import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import '../assets/css/partials/corporate/sectionsImageItems.scss'

const SectionImageItem = ({ 
  sectionImage, 
  sectionText, 
  sectionImageUpdateHandlere, 
  sectionImageDeleteHandler,  
  priority, isDisableDelete 
}) => {
    return (
      <div className="section-container">
      <div className="section-content">
        <h7>{sectionText}</h7>
      </div>
      <img
        alt="img"
        src={sectionImage}
      />
      <span class="badge proirty">{priority}</span>
      <div className="section-icons">
        <div className="section-iconc-edit"
           onClick={sectionImageUpdateHandlere}
        >
          <EditOutlinedIcon 
          />
        </div>
        {isDisableDelete ? "" :
        <div className="section-icons-delete"
          onClick={sectionImageDeleteHandler}
        >
          <DeleteOutlineOutlinedIcon />
        </div>
        }
      </div>
    </div>
    )
}

export default SectionImageItem;
