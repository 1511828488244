import React from 'react'
import Modal from "@material-ui/core/Modal";
import { ImCross } from "react-icons/im";

const commonConfirmationPopup = ({open, content, heading, btnOneText, btnTwoText, handleClick, handleClose}) => {
  return (
    <div>
        <Modal
        className={`modal-wrapper ${true ? "show" : "hide"}`}
        open={open}
        // onClose={closeHandle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="card-box card-box-index" style={{ width: "400px" }}>
          <div className="add-new-section-heading">
            <h5>{heading}</h5>
            <span
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "35px",
                right: "25px",
              }}
            >
              <ImCross />
            </span>
          </div>
          <div style={{backgroundColor: "#Fff", textAlign: "center", padding: "30px 30px"}}>
            <p style={{fontSize: "16px", fontWeight: "5000", marginBottom: "0px"}}>{content}</p>
          </div>
          <div className="form-group-container">
            <div className="add-new-section-btn-container">
              <button
                type="button"
                className="add-new-section-cancel-btn"
                onClick={handleClose}
              >
                {btnOneText}
              </button>
              <button 
                type="submit" 
                className={`add-new-section-done-btn ${false ? 'disable-btn' : ''}`} 
                disabled={false}
                onClick={handleClick}
                >
                {btnTwoText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default commonConfirmationPopup