
import {
  SET_ADD_WEB_STORIES,
 
    ADD_WEBSTORIES_IMAGE,
    ADD_WEBSTORIES,
   
    GET_WEB_STORIES_LISTS,
   
    
  } from "../actions/webStoriesAction";

  const initialState = {
    addWebStories:false,
    webStoriesImageUrl:"",
    webStories:[],
    webStoriesLists:[],
    editAbleDetails:[],
    updateDetails:[],
    updatedPayload:[],
    paginationDetails:[]
  
  };

  const webStoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "SET_ADD_WEB_STORIES" : {
        return {
          ...state,
          addWebStories: payload,
        };
      }

      case "EMPTY_MEDIA": {
        return {
          ...state,
          webStoriesImageUrl: "",
        };
      }
      case "ADD_WEBSTORIES_IMAGE": {
        return {
          ...state,
          webStoriesImageUrl: payload,
        };
      }
      case "ADD_WEBSTORIES": {
        return {
          ...state,
          webStoriesImageUrl: payload,
        };
      }
      case "GET_WEB_STORIES_LISTS": {
        return {
          ...state,
          webStoriesLists: payload,
        };
      }
      case "EDITABLE_DETAILS": {
        return {
          ...state,
          editAbleDetails: payload,
        };
      }
      case "UPDATED_PAYLOAD": {
        return {
          ...state,
          updatedPayload: payload,
        };
      }
      
      case "UPDATE_WEBSTORIES": {
        return {
          ...state,
          updateDetails: payload,
        };
      }
      case "PAGINATION_WEBSTORIES": {
        return {
          ...state,
          paginationDetails: payload,
        };
      }
      
      

      default:
        return state; // Return the state as is for other action types
    }
  };

    export default webStoriesReducer;
