import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CorporateReviewStatusUpdate, getCorporateReviewPendingList, getCorporateReviewPublishedList, getCorporateReviewRejectedList, getCorporateReviewsearch, reviewPageId } from "../../../actions/corporateReviewAction";


import CustomerReviews from "../../../common/holidayReviews";

import Pagination from "../../../common/pagination";

const Rejected = () => {
  const dispatch = useDispatch();
  const totalCorporateReviews = useSelector(
    (state) => state?.corporateReviews?.corporateRejectedReviewList
  );
  const RejectedReviewList =
    totalCorporateReviews && totalCorporateReviews?.review_data?.results;
  const totalPage = useSelector(
    (state) =>
      state.corporateReviews.corporateRejectedReviewList.review_data
        ?.total_pages
  );
  const pageNum = useSelector((state) => state.corporateReviews.page);
  const ffEndDate = useSelector(
    (state) => state.corporateReviews.corporateReviewEndDate
  );
  const ffStartDate = useSelector(
    (state) => state.corporateReviews.corporateReviewStartDate
  );
  useEffect(() => {
    dispatch(reviewPageId(1));
  }, []);

  const handlePagination = (e, value) => {
    e.preventDefault();
    if (ffEndDate === null && ffStartDate === null) {
      dispatch(getCorporateReviewRejectedList(value));
      dispatch(reviewPageId(value));
    } else {
      dispatch(
        getCorporateReviewsearch(value, "rejected", ffStartDate, ffEndDate)
      );
      dispatch(reviewPageId(value));
    }
  };
  const refreshData = () => {
    if (ffEndDate !== null && ffStartDate !== null) {
      dispatch(
        getCorporateReviewsearch(pageNum, "rejected", ffStartDate, ffEndDate)
      );
    } else {
      dispatch(getCorporateReviewRejectedList(pageNum));
      dispatch(getCorporateReviewPendingList(pageNum));
      dispatch(getCorporateReviewPublishedList(pageNum));
    }
  };
  const publishReview = (e, review_id, main_pack_id, ref_id) => {
    if (e.target.value == "deleted" || e.target.value == "approved") {
      dispatch(
        CorporateReviewStatusUpdate(
          e.target.value,
          main_pack_id,
          review_id,
          ref_id,
          refreshData
        )
      );
    }
  };
  return (
    <>
     {RejectedReviewList && RejectedReviewList?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {RejectedReviewList &&
        RejectedReviewList.map((data, i) => (
          <CustomerReviews
            key={i}
            title={data?.holiday_pack?.pack_title}
            address={`${(data?.holiday_pack.location_city,
            data?.holiday_pack?.location_country)}`}
            status={"rejected"}
            price={data?.holiday_pack?.points}
            comment={data?.review_comment}
            review_id={data?.id}
            main_pack_id={data?.main_pack_id}
            publishReview={publishReview}
            ref_id={data?.ref_id}
            review_star={data?.review_star}
          />
        ))}
      <Pagination
        totalPage={totalPage}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
    </>
  );
};
export default Rejected;
