import React, { useEffect, useState } from 'react'

import CallBackCard from './callBackCard'
import Pagination from "@material-ui/lab/Pagination";
import * as callBackAction from "../../actions/callBackAction";
import { useSelector, useDispatch } from "react-redux";

const CallBack = () => {
  const dispatch=useDispatch()
  const[page,setPage]=useState(1)
  const CallBack = useSelector((state) => state.callBack.call_back_list);
  const CallBack_Lists = useSelector((state) => state.callBack.call_back_list.results);

  const handlePagination = (event,pageNo) => {
  
    setPage(pageNo)
    dispatch(callBackAction.get_callback_list(pageNo))
    
};
  const handleSearch=(value)=>{
   
    if(value==""){
    
      
        dispatch(callBackAction.get_callback_list(1))
  
      

    }
    else{
      
      dispatch(callBackAction.search(value))

    }
   
  }
  useEffect(()=>{
    dispatch(callBackAction.get_callback_list(1))
    
  
  },[])
  return (
    <div className='call-back-main'>
      <div className='heading-date'>
          <p className='text'>Call back request</p>
          <div style={{position:"relative"}}>
         

          </div>
         

      </div>
      <input type='text' placeholder='Search' className='search' onChange={(e)=>handleSearch(e.target.value)}></input>
      {CallBack_Lists?.map((details)=>{
        return(
          <>
             <CallBackCard details={details} />
          </>
        )
      })}
   
     {CallBack?.total_pages>0 && <Pagination
              count={CallBack?.total_pages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event,value) =>
                  handlePagination(event,value)
              }
              style={{marginTop:"10px",marginBottom:"100px"}}
        />
            }
     
    </div>
  )
}

export default CallBack