import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getExperienceSection,
  deleteExperienceSection,
  experienceSelectedImageUrl,
  imageUpload,
  updateExperienceSection,
  getSectionPackages
} from '../../../actions/experienceSectionAction';

import SectionImageItem  from '../../../common/sectionImageItem';
import AddNewSection from '../../../common/addNewSection';
import { CANCEL, UPDATE, DELETE, DELETE_CONFIRMATION_MESSAGE } from '../../../utils/constants';
import DeleteConfirmationModal from '../../../common/deleteConfirmationModal';
import { useSnackbar } from "notistack";

const ExperienceSections = () => {

  const [ showExperienceUpdateModal, setShowExperienceUpdateModal ] = useState(false);
  const [ showExperienceSectionDeleteModal, setShowExperienceSectionDeleteModal ] = useState(false);
  const [ experienceSectionDeleteData, setExperienceSectionDeleteData ] = useState({});
  const [ sectionUpdateData, setSectionUpdateData ] = useState({});
  const [ updateImageBool, setUpdateImageBool] = useState(false);
  const [ updateSectionName, setUpdateSectionName ] = useState("");
  const [ updateSectionNameBool, setUpdateSectionNameBool ] = useState(false);
  const [ updateSectionDesc, setUpdateSectionDesc ] = useState("");
  const [ updateSectionDescBool, setUpdateSectionDescBool ] = useState(false);
  const [ updateSectionPriority, setUpdateSectionPriority ] = useState("")
  const [ updateSectionPriorityBool, setUpdateSectionPriorityBool ] = useState(false);
  const [ refCodeValue, setRefCodeValue] = useState("");
  const [ refCodeBool, setRefCodeBool ] = useState(false);
  const [ updateExperienceSectionPriorityBool, setUpdateExperienceSectionPriorityBool ] = useState(false);
  const [ updateExperienceSectionNameBool, setUpdateExperienceSectionNameBool ] = useState(null);
  const [ updateExperienceSectionDescBool, setUpdateExperienceSectionDescBool ] = useState(null);
  const [ updateExperienceSectionPriorityEmptyBool, setUpdateExperienceSectionPriorityEmptyBool ] = useState(null);
  const [ iscodeError, setIscodeError ] = useState(null);
  const [ codeLessError, setCodeLessErrorr ] = useState(null);
  const [ codeMoreError, setCodeMoreError ] = useState(null);
  const [ codeMatchError, setCodeMatchError ] = useState(null);
  const [ descMoreError, setDescMoreError ] = useState(null);
  const [ isCodeMatch, setIsCodeMatch ] = useState(false);
  const[imageTitle,setImageTitle]=useState("")
  const[altText,setAltText]=useState("")
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const experienceSelectedImgUrl = useSelector(state => state.experienceSection.experienceSelectedImageUrl);
  const experienceSectionData = useSelector(state => state.experienceSection?.getExperienceSection);
  const sectionTotalPack = useSelector(state => state.experienceSection.sectionPackages);

  useEffect(() => {
    // dispatch(getExperienceSection())
  }, []);

  useEffect(() => {
    if(refCodeBool && refCodeValue.length === 2){
      setCodeLessErrorr(false);
      setCodeMoreError(false);
    }
   }, [refCodeBool,refCodeValue]);

  const updatePic = (data) => {
    dispatch(experienceSelectedImageUrl(data.url));
  };

  const handleImageChange = (croppedFile) => {
    if (croppedFile) {
      dispatch(imageUpload(croppedFile, updatePic));
    }
    setUpdateImageBool(true);
  };

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
  };

  const updateHandleSuccess = () => {
    dispatch(getExperienceSection());
    enqueueSnackbar("Updated successfully" ,{
      variant: "success",
    });
  };

  const experienceSectionUpdateHandler = (data) => {
    setShowExperienceUpdateModal(true)
    setSectionUpdateData(data)
  };


  const refrenceCodeHandler = (e) => {
    setRefCodeBool(true)
    setCodeMatchError(false)
    let code = e.target.value.toLocaleUpperCase()
    const filteredExpSection = experienceSectionData.filter(item => {
      return code === item.code
    })
    if(filteredExpSection.length){
      setIsCodeMatch(true)
    }else{
      setIsCodeMatch(false)
    }
    setRefCodeValue(code)
  };

  const openDeleteConfirmationModal = (data) => {
    setShowExperienceSectionDeleteModal(true);
    setExperienceSectionDeleteData(data);
    dispatch(getSectionPackages(data))
  };


  const experienceSectionDeleteHandler = () => {
    dispatch(deleteExperienceSection(experienceSectionDeleteData, handleSuccess));
    setShowExperienceSectionDeleteModal(false);
  };

  const updateExperienceSectionDeschandler = (e) => {
    setUpdateSectionDescBool(true);
    setUpdateSectionDesc(e.target.value);
  };

  const updateExperienceSectionNamehandler = (e) => {
    setUpdateSectionNameBool(true);
    setUpdateSectionName(e.target.value);
  };

  const updateExperienceSectionPriorityhandler = (e) => {
    setUpdateExperienceSectionPriorityBool(false)
    setUpdateSectionPriorityBool(true);
    setUpdateSectionPriority(e.target.value)
    if(e.target.value <= -1)setUpdateExperienceSectionPriorityBool(true)
  };

  const handleClose = () => {
    setRefCodeBool(false)
    setRefCodeValue("")
    setUpdateSectionPriorityBool(false)
    setUpdateSectionPriority("")
    setUpdateImageBool(false)
    setUpdateSectionNameBool(false);
    setUpdateSectionName("")
    setUpdateSectionDescBool(false);
    setUpdateSectionDesc("")
    dispatch(experienceSelectedImageUrl(null));
    setShowExperienceUpdateModal(false)
  }

  const updateSectionHandleSubmit = (e) => {
    e.preventDefault();
    setUpdateExperienceSectionNameBool(false);
    setUpdateExperienceSectionDescBool(false);
    setUpdateExperienceSectionPriorityBool(false);
    setUpdateExperienceSectionPriorityEmptyBool(false);
    setCodeLessErrorr(false);
    setIscodeError(false);
    setCodeMoreError(false);
    const data = {
      image: updateImageBool && experienceSelectedImgUrl !== null ? experienceSelectedImgUrl : sectionUpdateData.image,
      name: updateSectionNameBool ? updateSectionName : sectionUpdateData.name,
      description: updateSectionDescBool ? updateSectionDesc : sectionUpdateData.description,
      priority: updateSectionPriorityBool ? updateSectionPriority : sectionUpdateData.priority,
      code:refCodeBool? refCodeValue : sectionUpdateData.code ,
      alt_text:altText,
      title_text:imageTitle

    };
    if(updateSectionNameBool && updateSectionName === "" || sectionUpdateData.name === "")setUpdateExperienceSectionNameBool(true);
    else if(updateSectionDescBool && updateSectionDesc === "" || sectionUpdateData.description === "")setUpdateExperienceSectionDescBool(true);
    else if(updateSectionDescBool && updateSectionDesc.length > 200 )setDescMoreError(true);
    else if(updateSectionPriorityBool && updateSectionPriority === "" || sectionUpdateData.priority === "")setUpdateExperienceSectionPriorityEmptyBool(true);
    else if(updateSectionPriority <= -1)setUpdateExperienceSectionPriorityBool(true);
    else if(refCodeBool && refCodeValue === "") setIscodeError(true)
    else if(refCodeBool && refCodeValue.length < 2) setCodeLessErrorr(true)
    else if(refCodeBool && refCodeValue.length > 2) setCodeMoreError(true)
    else if(refCodeBool && refCodeValue.length == 2 && isCodeMatch) setCodeMatchError(true)
    else{
      dispatch(updateExperienceSection(data, sectionUpdateData, updateHandleSuccess));
      setShowExperienceUpdateModal(false);
      setUpdateSectionNameBool(false);
      setUpdateSectionDescBool(false);
      setUpdateSectionPriorityBool(false);
      setDescMoreError(false)
      setUpdateImageBool(false);
      setRefCodeBool(false);
      setRefCodeValue("")
      dispatch(experienceSelectedImageUrl(null));
    }
  };
  const experienceSectionListingData = experienceSectionData && experienceSectionData.map(data => (
    <SectionImageItem 
      sectionText={data.name}
      sectionImage={data.image}
      priority={data.priority}
      sectionImageUpdateHandlere={() => experienceSectionUpdateHandler(data)}
      sectionImageDeleteHandler={() => openDeleteConfirmationModal(data)}
      isDisableDelete = {
        data.name === "Experiential Stays" && true
      }
    />
  ));

  return(
    <>
    {experienceSectionListingData}
    {showExperienceUpdateModal && 
      <AddNewSection 
       showMyModal={showExperienceUpdateModal}
      //  closeHandle={() => setShowExperienceUpdateModal(false)}
       heading="Update Section"
       labelTextOne="Section name"
       labelTextTwo="priority"
       labelTextThree="Description"
       codeRefrence="Code"
       valueOne={updateSectionNameBool ? updateSectionName : sectionUpdateData.name}
       valueTwo={updateSectionPriorityBool ? updateSectionPriority : sectionUpdateData.priority}
       valueThree={updateSectionDescBool ? updateSectionDesc : sectionUpdateData.description}
       image={updateImageBool && experienceSelectedImgUrl !== null ? experienceSelectedImgUrl : sectionUpdateData.image}
       refCodeValue={refCodeBool? refCodeValue :sectionUpdateData.code }
       btnTextOne={CANCEL}
       btnTextTwo={UPDATE}
       onChangeOne={updateExperienceSectionNamehandler}
       onChangeThree={updateExperienceSectionDeschandler}
       onChangeTwo={updateExperienceSectionPriorityhandler}
       refrenceCodeHandler={refrenceCodeHandler}
       handleSubmit={updateSectionHandleSubmit}
       closeModal={() => setShowExperienceUpdateModal(false)}
       isExperiencesAddNewSection={false}
       isExperiencesUpdateSection={true}
       closeHandle={() => handleClose()}
       handleFilePickChange={handleImageChange}
       isExperienceSection={true}
       isUpdateSec={true}
       isPriorityError={updateExperienceSectionPriorityBool}
       isSectionNameError={updateExperienceSectionNameBool}
       isSectionDescError={updateExperienceSectionDescBool}
       isPriorityEmptyError={updateExperienceSectionPriorityEmptyBool}
       imageTitle={imageTitle}
       setImageTitle={setImageTitle}
       altText={altText}
       setAltText={setAltText}
       iscodeError={iscodeError}
        codeLessError={codeLessError}
        codeMoreError={codeMoreError}
        codeMatchError={codeMatchError}
        descMoreError={descMoreError}
        isDescription={true}
       disabled={updateImageBool && experienceSelectedImgUrl !== null ||  updateSectionPriority || updateSectionName || updateSectionDesc || refCodeValue ? false : true}
       pointerEvents={
        (sectionUpdateData.name === "Experiential Stays") && "none"
       }
      />
    }
    <DeleteConfirmationModal 
      open={showExperienceSectionDeleteModal}
      btnTextOne={CANCEL}
      btnTextTwo={DELETE}
      onClick={() =>setShowExperienceSectionDeleteModal(false)}
      deleteImage={experienceSectionDeleteHandler}
      disable={sectionTotalPack > 0 ? true : false}
      fontSize={sectionTotalPack > 0 ?"20px":""}
      deleteConfirmationMsg={
        sectionTotalPack > 0 ?
        `You have 
        ${sectionTotalPack} ${sectionTotalPack > 1 ? "packages": "package"} 
        in this section. Please shift or delete ${sectionTotalPack > 1 ? "these packages": "this packge"} to delete the section.`:
        DELETE_CONFIRMATION_MESSAGE
      }
    />
    </>
  );
};

export default ExperienceSections;
