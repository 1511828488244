import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosPut
} from "../services/apiServices";

import { 
  GET_EXPERIENCE_BOOKING_LIST,
  EXPERIENCE_BOOKING_PAGE_ID,
  EXPERIENCE_BOOKING_START_DATE ,
  EXPERIENCE_BOOKING_END_DATE,
  GET_EXPERIENCE_CONFIRMED_TOTAL,
  GET_EXPERIENCE_BOOKED_TOTAL,
  GET_EXPERIENCE_CANCELLED_TOTAL,
  EXPERIENCE_STATUS_VALUE,
  EXPERIENCE_BOOKINGS_SECTION_ID,
  GET_EXPERIENCE_CONFIRMED_LIST,
  GET_EXPERIENCE_CANCELLED_LIST,
  EXPERIENCE_REFUNDED_SEARCH_VALUE,
  EXPERIENCE_BOOKING_NEXT_PAGE_ID,
  EXPERIENCE_CONFIRMED_NEXT_PAGE_ID,
  EXPERIENCE_CANCELLED_NEXT_PAGE_ID,
  EXPERIENCE_BOOKING_CURRENT_PAGE_ID,
  EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID,
  EXPERIENCE_CANCELLED_CURRENT_PAGE_ID,
  EXPERIENCE_BOOKED_SEARCH_VALUE,
  EXPERIENCE_CONFIRMED_SEARCH_VALUE,
  EXPERIENCE_CANCELLED_SEARCH_VALUE,
  EXPERIENCE_REQUESTED_SEARCH_VALUE,
  GET_EXPERIENCE_REFUNDED_TOTAL,
  GET_EXPERIENCE_REFUNDED_LIST,
  EXPERIENCE_REFUNDED_NEXT_PAGE_ID,
  EXPERIENCE_REFUNDED_CURRENT_PAGE_ID,
  GET_AVAILABILITY_LIST,
  EXPERIENCE_AVAILABILITY_SINGLE_DATA,
  GUEST_MODAL_OPEN_TRUE,
  DATE_RANGE_ITEM,
  ADULTS_GUEST,
  CHILDRENS_GUEST,
  INFANTS_GUEST,
  RECURRING_DATE,
  RECURRING_MODAL_OPENINI_TRUE,
  SCHEDULE_MODAL_OPEN_TRUE,
  PACKAGE_START_DATE,
  PACKAGE_END_DATE,
  SPECIFIC_DATE_VALUE,
  FLEXI_DATE_ITEM,
  IS_EXP_DATA_LOADING,
  GET_EXPERIENCE_FOLLOWUP_TOTAL,
  GET_EXPERIENCE_FOLLOWUP_LIST,
  EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID,
  EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID,
  
  GET_FOLLOWUP_BOOKED_TOTAL,
  EXPERIENCE_FOLLOWUP_SEARCH_VALUE
} from '../actions/types'

export const getExperienceBookingList = (pageId, secId, status) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
        "page_size":50,
        "next_page_id":pageId,
        "sec_id":secId,
        "status":status
      };
      dispatch({
        type: IS_EXP_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.GET_EXPERIENCE_BOOKING}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            if(status === 'confirmed') {
              dispatch({
                type: GET_EXPERIENCE_CONFIRMED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_CONFIRMED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:EXPERIENCE_CONFIRMED_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:EXPERIENCE_CONFIRMED_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }
            else if(status === 'follow_up') {
              dispatch({
                type: GET_EXPERIENCE_FOLLOWUP_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_FOLLOWUP_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:EXPERIENCE_FOLLOWUP_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:EXPERIENCE_FOLLOWUP_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }
            else if(status === 'refunded') {
              dispatch({
                type: GET_EXPERIENCE_REFUNDED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_REFUNDED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:EXPERIENCE_REFUNDED_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:EXPERIENCE_REFUNDED_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }
           
            else if(status === 'booked') {
              dispatch({
                type: GET_EXPERIENCE_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_BOOKING_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:EXPERIENCE_BOOKING_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:EXPERIENCE_BOOKING_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
            }
            else if(status === 'cancelled') {
              dispatch({
                type: GET_EXPERIENCE_CANCELLED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_CANCELLED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type:EXPERIENCE_CANCELLED_NEXT_PAGE_ID,
                payload:res.data.data.next_page_id
              })
              dispatch({
                type:EXPERIENCE_CANCELLED_CURRENT_PAGE_ID,
                payload:res.data.data.current_page
              })
              
            }else if(status === "requested"){
              dispatch({
                type:GET_AVAILABILITY_LIST,
                payload:res.data.data
              })
            }else{

            // dispatch({
            //   type: GET_EXPERIENCE_BOOKING_LIST,
            //   payload: res.data.data,
            // });
            }
            dispatch({
              type: IS_EXP_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
          dispatch({
            type: IS_EXP_DATA_LOADING,
            payload: false,
          });
        });
    };
  };

export const experienceBookingsSearch = (pageID, status, searchTerm, secId, startDate, endDate) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      const body = {
      
        "next_page_id": pageID,
        "status": status,
        "sec_id":secId,
        "search_term": searchTerm,
        "from": startDate,
        "to": endDate,
        "page_size":50,
      }
      dispatch({
        type: IS_EXP_DATA_LOADING,
        payload: true,
      });
      axiosPostWithToken(`${API.EXPERIENCE_BOOKING_SEARCH}`,body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            if(status === "booked"){
              dispatch({
                type: GET_EXPERIENCE_BOOKING_LIST,
                payload: res.data.data,
              })
              dispatch({
                type: GET_EXPERIENCE_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
           else if(status === "follow_up"){
              dispatch({
                type: GET_EXPERIENCE_FOLLOWUP_LIST,
                payload: res.data.data,
              })
              dispatch({
                type: GET_FOLLOWUP_BOOKED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "confirmed"){
              dispatch({
                type: GET_EXPERIENCE_CONFIRMED_LIST,
                payload: res.data.data,
              });
              dispatch({
                type: GET_EXPERIENCE_CONFIRMED_TOTAL,
                payload: res.data.data.total_results,
              });
            }
            else if(status === "cancelled"){
              dispatch({
                type: GET_EXPERIENCE_CANCELLED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_CANCELLED_LIST,
                payload: res.data.data,
              });
            }
            else if(status === "refunded"){
              dispatch({
                type: GET_EXPERIENCE_REFUNDED_TOTAL,
                payload: res.data.data.total_results,
              });
              dispatch({
                type: GET_EXPERIENCE_REFUNDED_LIST,
                payload: res.data.data,
              });
            }
            else if(status === "requested"){
              dispatch({
                type:GET_AVAILABILITY_LIST,
                payload:res.data.data
              });
            }
            dispatch({
              type: IS_EXP_DATA_LOADING,
              payload: false,
            });
          }
        })
        .catch((err) => {
          dispatch({
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
          dispatch({
            type: IS_EXP_DATA_LOADING,
            payload: false,
          });
        });
    };
};

// export const experienceBookingStatusUpdate = (status, id, corporate, secId, refreshFuction ) => {
export const experienceBookingStatusUpdate = (status, id, corporate, secId,cancelling_message,refreshFuction, data, guestData, amountValue ) => {
  return async (dispatch, getState) => {
  
    const statusVal = getState().experienceBooking.experienceStatusVal;
    const token = getState().auth.token;
    const bodyForRequested = {
      "status": status,
      "booking_id": id,
      "corporate": corporate,
      sec_id:secId,
      schedule:data?.schedule,
      adults:guestData?.adults,
      children:guestData?.children,
      infants:guestData?.infants,
      offline_payment:true,
      amount_paid:parseInt(amountValue)
    }
    
    const bodyForAll = {
      "status": status,
      "booking_id": id,
      "corporate": corporate,
      sec_id:secId,
      cancelling_message:cancelling_message,
    }

    axiosPut(`${API.EXPERIENCE_BOOKING_STATUS_UPDATE}`,(statusVal === "requested" || statusVal==="follow_up") && status === "confirmed" ?  bodyForRequested : bodyForAll, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          refreshFuction()
        }
      })
      .catch((err) => {
        // dispatch({
        //   payload:
        //     (err && err.response && err.response.data) ||
        //     "Something went wrong.",
        // });
      });
  };
};

  export const pageId = (page) => {
    return async (dispatch) => {
      dispatch({
        type:EXPERIENCE_BOOKING_PAGE_ID,
        payload: page,
      })
    
    };
  };

  export const myExperienceBookingStartDate = (date) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_BOOKING_START_DATE,
        payload: date,
      });
    };
  };
    
  export const myExperienceBookingEndDate = (date) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_BOOKING_END_DATE,
        payload: date,
      });
    };
  };

  export const getExperiencesStatus = (statusVal) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_STATUS_VALUE,
        payload: statusVal,
      });
    };
  };

  export const bookingsSectionId = (id) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_BOOKINGS_SECTION_ID,
        payload: id,
      });
    };
  };
 
  // new Action
  export const availabilitySinleData = (data) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_AVAILABILITY_SINGLE_DATA,
        payload: data,
      });
    };
  };
  export const guestModalOpentrue = (bool) => {
    return async (dispatch) => {
      dispatch({
        type: GUEST_MODAL_OPEN_TRUE,
        payload: bool,
      });
    };
  };
  export const dateRangeItem = (item) => {
    return async (dispatch) => {
      dispatch({
        type: DATE_RANGE_ITEM,
        payload: item,
      });
    };
  };
  export const availabilityRecurringDate = (item) => {
    return async (dispatch) => {
      dispatch({
        type: RECURRING_DATE,
        payload: item,
      });
    };
  };
  export const RecurringModalOpenTrue = (bool) => {
    return async (dispatch) => {
      dispatch({
        type: RECURRING_MODAL_OPENINI_TRUE,
        payload: bool,
      });
    };
  };
  export const scheduleModalOpenTrue = (bool) => {
    return async (dispatch) => {
      dispatch({
        type: SCHEDULE_MODAL_OPEN_TRUE,
        payload: bool,
      });
    };
  };

  export const packageDateItem = (start, end) => {
    return async (dispatch) => {
      dispatch({
        type: PACKAGE_START_DATE,
        payload: start,
      });
      dispatch({
        type: PACKAGE_END_DATE,
        payload: end,
      });
    };
  };
  export const specificDateValue = (val) => {
    return async (dispatch) => {
      dispatch({
        type: SPECIFIC_DATE_VALUE,
        payload: val,
      });
    };
  };
  export const flexiDateItem = (val) => {
    return async (dispatch) => {
      dispatch({
        type: FLEXI_DATE_ITEM,
        payload: val,
      });
    };
  };

  export const guestData = (adult, children, infant) => {
    return async (dispatch) => {
      dispatch({
        type: ADULTS_GUEST,
        payload: adult,
      });
      dispatch({
        type: CHILDRENS_GUEST,
        payload: children,
      });
      dispatch({
        type: INFANTS_GUEST,
        payload: infant,
      });
    };
  };
// end of new Action

  export const experienceSearchValue = (value, status) => {
    return async (dispatch) => {
      if(status === "booked"){
        dispatch({
          type: EXPERIENCE_BOOKED_SEARCH_VALUE,
          payload: value,
        });
      }else if(status === "confirmed"){
        dispatch({
          type: EXPERIENCE_CONFIRMED_SEARCH_VALUE,
          payload: value,
        });
      } else if(status === "follow_up"){
        dispatch({
          type: EXPERIENCE_FOLLOWUP_SEARCH_VALUE,
          payload: value,
        });
      }
      else if(status === "cancelled"){
        dispatch({
          type: EXPERIENCE_CANCELLED_SEARCH_VALUE,
          payload: value,
        });
      }   else if(status === "refunded"){
        dispatch({
          type: EXPERIENCE_REFUNDED_SEARCH_VALUE,
          payload: value,
        });
      }
      else if(status === "requested"){
        dispatch({
          type: EXPERIENCE_REQUESTED_SEARCH_VALUE,
          payload: value,
        });
      }
    };
  };

