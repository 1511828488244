import React, { useState, useEffect } from "react";

import closeIcon from "../../assets/images/close.svg";
import * as popular_destination_action from "../../actions/popularDestinationAction";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import DeleteIconWhite from "../../assets/images/delete.svg";
import AddImage from "./addImage";
import { API } from "../../constants/api";

import * as popularDestination from "../../actions/popularDestinationAction"

const AddCard = ({ setAddNew }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const mediaUrl = useSelector(
        (state) => state.popularDestions.mediaURL?.url
    );
    const stateAndCountryLists = useSelector(
        (state) => state.popularDestions.stateAndCountryLists
    )

  
  
    const [name, setName] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [description, setDescription] = useState(null);
    const [priority, setPriority] = useState(null);
    const [locationType, setLocationType] = useState("state");
    const[stateLists,setStateLists]=useState()
    const[countryLists,setCountryLists]=useState()
    const[suggestion,setSuggestions]=useState()
    const[imageTitle,setImageTitle]=useState("")
    const[altText,setAltText]=useState("")

 

    const handleCancelAdd = () => {
        setAddNew(false);
        dispatch(popular_destination_action.emptyMedia());
    };
    const handleSelectChange = (event) => {
        setLocationType(event.target.value);
    };
 

    const handleAddSuccess = () => {
        enqueueSnackbar("Destination Added Successfully", {
            variant: "success",
        });
    };
    const handleErrorMsg=(msg)=>{
        enqueueSnackbar(`${msg}`, {
            variant: "error",
        });

    }
    const refreshReload=()=>{
        setTimeout(() => {
            window.location.reload();
            
        }, 1000);
    
    }

    const handlePublish = () => {
        const payload = {
            name: name,
            background_image: backgroundImage,
            corporate_availability: true,
            description: description,
            priority: priority,
            homepage_availability: true,
            experience_availability: true,
            holiday_availability: true,
            location_type: locationType,
            alt_text:altText,
            title_text:imageTitle
        };

        if(!payload?.name){
            handleErrorMsg("Please add name ")

        }
        else if(!payload.priority){
            handleErrorMsg("Please add priority")

        }
        else if(!payload.background_image){
            handleErrorMsg("Please add image")

        }
     
        else{
            dispatch(
                popular_destination_action.handleAddNewPopularDestination(
                    payload,
                    handleAddSuccess,
                    refreshReload,
                    handleErrorMsg
                )
            );


        }
    
    };
    useEffect(() => {
        setBackgroundImage(mediaUrl);
    }, [mediaUrl]);


    const [inputValue, setInputValue] = useState('');
    
  
    const handleChange = async(e) => {
      const value = e.target.value;
      setName(value);
 
    if(locationType=="state"){
        const filteredSuggestions = stateLists?.filter(suggestion =>
            suggestion.toLowerCase().includes(value.toLowerCase())
          );
        
    
      
          setSuggestions(filteredSuggestions);

      }
      else{
        const filteredSuggestions = countryLists?.filter(suggestion =>
            suggestion.toLowerCase().includes(value.toLowerCase())
          );
    
      
          setSuggestions(filteredSuggestions);

      }
   
    };
  
    const handleSuggestionClick = (value) => {
      setName(value);
      setSuggestions([]);
    };
    useEffect(()=>{
      
      
        if (stateAndCountryLists?.data?.countries_list_for_corporate &&
          stateAndCountryLists?.data?.countries_list_for_holiday&&
          stateAndCountryLists?.data?.countries_list_for_experience&&
          stateAndCountryLists?.data?.states_list_for_corporate &&
          stateAndCountryLists?.data?.states_list_for_holiday &&
          stateAndCountryLists?.data?.states_list_for_experience) 
          {
        
                const all_country_lists = [
                ...stateAndCountryLists.data.countries_list_for_corporate?.map((elem)=>elem.location),
                ...stateAndCountryLists.data.countries_list_for_holiday?.map((elem)=>elem.location),
                ...stateAndCountryLists.data.countries_list_for_experience?.map((elem)=>elem.location)
                ];
            
                const all_state_lists = [
                ...stateAndCountryLists.data.states_list_for_corporate?.map((elem)=>elem.location),
                ...stateAndCountryLists.data.states_list_for_holiday?.map((elem)=>elem.location),
                ...stateAndCountryLists.data.states_list_for_experience?.map((elem)=>elem.location)
                ];

                function removeDuplicatesManually(array) {
                    let uniqueArray = [];
                    
                    for (let i = 0; i < array.length; i++) {
                        let isDuplicate = false;
                        
                        // Check if the current element is already in the uniqueArray
                        for (let j = 0; j < uniqueArray.length; j++) {
                            if (array[i] === uniqueArray[j]) {
                                isDuplicate = true;
                                break;
                            }
                        }
                        
                        // If the element is not a duplicate, add it to uniqueArray
                        if (!isDuplicate) {
                            uniqueArray.push(array[i]);
                        }
                    }
                    
                    return uniqueArray;
                }
                const filterd_duplicacy_all_state_lists=removeDuplicatesManually(all_state_lists)
                const filterd_duplicacy_all_country_lists=removeDuplicatesManually(all_country_lists)
      
                setStateLists(filterd_duplicacy_all_state_lists)
                setCountryLists(filterd_duplicacy_all_country_lists)
        
     
      }

    },[stateAndCountryLists])


    useEffect(()=>{ 
                 dispatch(popularDestination.handleStateAndCountryLists())
                },[])
   

    return (
        <div className="add-card-main">
            <div className="state-serach">
                <select className="select-state" onChange={handleSelectChange}>
                    <option value="state">State</option>
                    <option value="country">Country</option>
                </select>
                <input
                    placeholder={
                        locationType == "state"
                            ? "Enter state name"
                            : "Enter country name"
                    }
                    className="search"
                    style={{fontSize:"15px",padding:"4px"}}
                  
                    // onChange={(e) => setName(e.target.value)}
                    onChange={handleChange}
                    value={name}
                ></input>
              
                <ul style={{ listStyle: 'none', height: "300px",overflowY: "auto", width: "270px", zIndex: "99999", position: "absolute", top: "275px", left: "285px" }}>
  {suggestion?.map((suggestion, index) => (
    <li key={index} onClick={() => handleSuggestionClick(suggestion)} style={{ padding: "12px", backgroundColor: "#e6e6e6", textAlign: "center", fontSize: "18px", cursor: 'pointer', borderBottom: '1px solid #ccc', borderRadius: "2px" }} onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'} onMouseLeave={(e) => e.target.style.backgroundColor = "#e6e6e6"}>
      {suggestion}
    </li>
  ))}
</ul>

 

            </div>
            <div className="description">
                <p>Description</p>
                <textarea
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>
            </div>
            <div className="priority">
                <p>Priority</p>
                <input
                    type="number"
                    onWheel={(e) => {
                        // Prevent scroll behavior only when the input is focused
                        if (document.activeElement === e.currentTarget) {
                          e.preventDefault();
                        }
                      }}
                      onFocus={(e) => {
                        // Disable scrolling when the input is focused
                        e.currentTarget.addEventListener('wheel', (wheelEvent) => {
                          wheelEvent.preventDefault();
                        }, { passive: false });
                      }}
                    onChange={(e) => setPriority(e.target.value)}
                    onKeyPress={(e) => {
                     
                        const isValidInput = /^[0-9\b]+$/.test(e.key);
                        if (!isValidInput) {
                          e.preventDefault();
                        }
                      }}
                    
                ></input>
            </div>

            {backgroundImage ? (
                <div className="edit-image">
                    <img
                        src={backgroundImage}
                        alt="image"
                        className="image"
                    ></img>
                    <div className="delete-image-icon">
                        <img src={DeleteIconWhite} alt="deleteIcon" onClick={()=>setBackgroundImage(false)}></img>
                    </div>
                </div>
            ) : <AddImage altText={altText} setAltText={setAltText} imageTitle={imageTitle} setImageTitle={setImageTitle}/>}

            <div className="publish_close">
                <img
                    src={closeIcon}
                    alt="closeIcon"
                    className="closeIcon"
                    onClick={handleCancelAdd}
                ></img>
                <button className="publish-button" onClick={handlePublish}>
                    Publish
                </button>
            </div>
        </div>
    );
};

export default AddCard;
