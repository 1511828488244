import React, { useState, useEffect, useMemo } from "react";
import { Row, Col } from "antd";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import rightArrow from "../../assets/images/rightArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import * as webStoriesAction from "../../actions/webStoriesAction";
import { countActivePackages, countExpiredPackages } from "../../utils/index";
import { Spinner } from "react-bootstrap";

const PurpleSwitch = withStyles({
    switchBase: {
        color: "white",
        "&$checked": {
            color: "#2196F3",
        },
        "&$checked + $track": {
            backgroundColor: "#2196F3",
            opacity: 1,
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        marginTop: 10,
        boxShadow: "none",
        background: "#FAFAFA",
        border: "1px solid #EDEDED",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "18px 20px",
        minHeight: 78,
        cursor: "pointer",
    },
    rootBorder: {
        position: "relative",
        marginTop: 10,
        boxShadow: "none",
        background: "#FAFAFA",
        border: "2px solid #000000",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "18px 20px",
        minHeight: 78,
        cursor: "pointer",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
    },
    title: {
        fontSize: 16,
        marginBottom: 4,
        fontWeight: 500,
        color: "#000000",
    },
    location: {
        marginBottom: 0,
        fontWeight: 400,
        fontSize: 12,
        color: "#797979",
    },
    rightArrow: {
        position: "absolute",
        right: "16px",
        top: "22px",
    },
}));

const Listing = ({
    stotyList,
    handleEdit,
    handleAddWebStories,
    currentPage,
    handleResetPage,
    setDetailsId,
    setSelectStoriesDetails,
    handleStoriesActive,
    setModalCard
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchBool, setsearchBool] = useState(false);
    const [addFlowLogo, setAddFlowLogo] = useState();
    const [show, setShow] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [page, setPage] = useState(1);
    const [editDetailsId, setEditDetailsId] = useState("");
    const [chosen, setChosen] = useState();
   const addWebStories = useSelector(
        (state) => state?.webStories?.addWebStories
    );

    const updateHolidayListingBool = useSelector(
        (state) => state.holidayListing.holidatUpdateBoolVal
    );
    const listingEditTrueVal = useSelector(
        (state) => state.holidayListing.listingEditTrue
    );
    const search = useSelector(
        (state) => state.holidayListing.holidaylistSearchVal
    );
    const selectedChosenPckId = useSelector(
        (state) => state.holidayListing.selectedChosenId
    );
    const pageID = useSelector((state) => state.holidayListing.pageId);
    const holidayListingDropDownVal = useSelector(
        (state) => state.holidayListing.holidayListingDropDownvalue
    );
    const isDataLoading = useSelector(
        (state) => state.holidayListing.isListingDataLoading
    );

    useEffect(() => {
        setChosen(stotyList && stotyList?.[0]?.id);
        if (updateHolidayListingBool) {
            setChosen(editDetailsId);
        } else {
            if (stotyList && stotyList?.[0]?.id) {
                setChosen(stotyList && stotyList?.[0]?.id);
            }
        }
    }, [currentPage, stotyList && stotyList?.[0]?.id]);

    useEffect(() => {}, [currentPage]);

    useEffect(() => {
        if (selectedChosenPckId) {
            setChosen(stotyList?.[0]?.id);
            dispatch();
        }
    }, [selectedChosenPckId]);

    useEffect(() => {
        return () => {
            dispatch(webStoriesAction.setAddWebStories(false));
            setShow(false);
        };
    }, []);
    useEffect(() => {
        if (search == "") {
            setSearchVal("");
        }
    }, [search]);
    const setEditDetails = (id) => {
        setDetailsId(id);
        setEditDetailsId(id);

        setChosen(id);
        setShow(false);
        handleEdit(id);
    };

    const setNewDetails = (id) => {
        setSelectStoriesDetails(false);
        setShow(true);
        setChosen("")
        setDetailsId("");
        setEditDetailsId("");

        setChosen("");
        setShow(false);
        handleEdit("");
    };

    const handleChange = (val) => {
        setSearchVal(val);
        if (val === "") {
        }
    };
    const handleSearch=(searchKey)=>{
        setSearchVal(searchKey)
      


    }

    const handleClick=(id)=>{
        setEditDetails(id)   
        setModalCard(false)

    }
    useEffect(()=>{
        if(searchVal){
            dispatch(webStoriesAction.getSearchStory(searchVal))
        }
        else{
            dispatch(webStoriesAction.getWebStoreisLists(1))

        }

    },[searchVal])
    useEffect(() => {
        handleResetPage();
        if (searchVal) {
            const getData = setTimeout(() => {}, 350);
            return () => clearTimeout(getData);
        }
    }, [searchVal]);
    return (
        <div style={{ width: "100%" }}>
            <Row className={classes.row} style={{ alignItems: "center" }}>
                <Col className="w-100" style={{ marginLeft: "2px" }}>
                    <input
                        type="search"
                        className="form-control search-list"
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </Col>
                <Col style={{ marginRight: "2px" }}>
                    <button onClick={setNewDetails} className="btn add-new">
                        Add New <AddSharpIcon />
                    </button>
                </Col>
            </Row>
            <div
                className="overflow--scroll"
                style={{ paddingRight: "20px", marginRight: "-20px" }}
            >
                {addWebStories && (
                    <>
                        <Card
                            className={
                                addFlowLogo ? classes.rootBorder : classes.root
                            }
                        >
                            <h6 className={classes.title}>Adding New</h6>
                            <p className={classes.location}>
                                No location provided yet
                            </p>
                            {addFlowLogo ? (
                                <img
                                    className={classes.rightArrow}
                                    src={rightArrow}
                                />
                            ) : null}
                        </Card>
                    </>
                )}

                {searchBool
                    ? stotyList &&
                      stotyList.length > 0 &&
                      stotyList.map((item) => (
                       
                          <>
                        
                          
                              <Card
                                  className={
                                      item.id == chosen && !show
                                          ? classes.rootBorder
                                          : classes.root
                                  }
                                  onClick={() => setEditDetails(item.id)}
                              >
                                  <h6 className={classes.title}>
                                      {item.title}
                                  </h6>
                              </Card>
                          </>
                      ))
                    : stotyList &&
                      stotyList.length > 0 &&
                      stotyList.map((item) => (
                        <>
                           <Card
                              className={
                                  item.id == chosen 
                                      ? classes.rootBorder
                                      : classes.root
                              }
                              onClick={() =>{handleClick(item.id)}}
                          >
                              <h6 className={classes.title}>{item.title}</h6>

                              <div style={{ marginLeft: "280px" ,opacity:item.id == chosen?1:0.6}} >
                                  <PurpleSwitch
                                      size="small"
                                      width="17.5px"
                                      height="10px"
                                      top="11px"
                                      left="27px"
                                      color="#2196F3"
                                      name="featured"
                                      value="featured"
                                     
                                      disabled={item.id == chosen?false:true}
                                      checked={item?.is_active?true:false}
                                      onChange={()=>handleStoriesActive(item.id,item?.is_active?true:false)}
                                  />
                              </div>
                          </Card>
                        </>
                       
                      ))}
                {stotyList?.results?.length === 0 && (
                    <Card className={classes.root}>
                        <p>No Records Found!</p>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default Listing;
