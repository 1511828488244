import React, { useState } from 'react';

function TransactionDetailsModal({ transDetails }) {
    return (
        <div className="modal-details">
            <div className="d-flex justify-content-between">
                <p>Mihpayid </p>
                <p> {transDetails?.mihpayid}</p>
            </div>
            <div className="d-flex justify-content-between">
                <p>Transaction ID</p>
                <p> {transDetails?.txnid}</p>
            </div>
            <div className="d-flex justify-content-between">
                <p>Net Amount Debit</p>
                <p>{transDetails?.net_amount_debit}</p>
            </div>
            <div className="d-flex justify-content-between">
                <p>Bank Reference number</p>
                <p>{transDetails?.bank_ref_num}</p>
            </div>
            <div className="d-flex justify-content-between">
                <p>Error Message</p>
                <p style={{maxWidth:'300px'}}>{transDetails?.error_Message}</p>
            </div>
        </div>
    )
}

export default TransactionDetailsModal
