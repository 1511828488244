import { EVENT_SECTION_PACKAGES, GET_EVENT_SECTION, EVENT_CONFIRM_ADD_BOOL,  EVENT_CONFIRM_UPDATE_BOOL, EVENT_SELECTED_IMAGE_URL,
    EVENT_ADD_IMAGE
} from '../actions/types'
const initialState = {
    getEventSection:[],
    eventConfirmAddBoolVal: false,
    eventConfirmUpdateBoolVal: false,
    eventSelectedImageUrl: null,
    eventAddImage: {},
    sectionPackages:null
};

const eventSectionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_EVENT_SECTION: {
            return {
                ...state,
                getEventSection: payload,
            }
        }
        case EVENT_CONFIRM_ADD_BOOL: {
            return {
              ...state,
              eventConfirmAddBoolVal: payload
            }
          }
        case EVENT_CONFIRM_UPDATE_BOOL: {
            return {
              ...state,
              eventConfirmUpdateBoolVal: payload
            }
          }
          case EVENT_SELECTED_IMAGE_URL: {
            return {
              ...state,
              eventSelectedImageUrl: payload
            }
          }
          case EVENT_ADD_IMAGE: {
            return {
              ...state,
              eventAddImage: payload,
            };
          }
          case EVENT_SECTION_PACKAGES: {
            return {
              ...state,
              sectionPackages: payload
            }
          }
        default:
            return state;
    }
};

export default eventSectionReducer;